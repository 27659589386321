import { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { HelpOutlined } from "@mui/icons-material";
import {
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { CustomDatePicker } from "components/atoms/custom-date-picker";
import { Flash } from "components/atoms/flash";
import { ClientBlock } from "components/molecules/client-block";
import { CustomModal } from "components/molecules/custom-modal";
import {
  BillingCreateRequest,
  initialBillingCreateRequest,
} from "data-access/repositories/billing/billing.dto";
import { billingRepository } from "data-access/repositories/billing/billing.repository";
import { Project, ProjectId } from "data-access/repositories/project/project.dto";
import { ProjectBilling } from "data-access/repositories/project/project_billing/project_billing.dto";
import { theme } from "extensions/theme";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { mainOperations } from "store/main/operations";
import { selectMain } from "store/main/slice";
import useSWR, { mutate, useSWRConfig } from "swr";
import { API_PATHS } from "utils/apiPaths";
import { MEIHOU_ID } from "utils/constant";
import { downloadBlob } from "utils/downloadBlob";
import { handleStateError } from "utils/errorHandler";
import { mailingAddress } from "utils/mailingAddress";
import { mailingName } from "utils/mailingName";
import { salesTaxCalculator } from "utils/salesTaxCalculator";
import { ProjectDetailCard } from "./project-detail-card";

const CustomTypographyTitle = styled(Typography)`
  font-size: 14px;
  font-weight: bold;
`;

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  projectId: ProjectId;
}

export const BillingFormModal = (props: Props) => {
  const dispatch = useAppDispatch();
  const mainState = useAppSelector(selectMain);
  const [formState, setFormState] = useState<BillingCreateRequest>(initialBillingCreateRequest);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const { cache } = useSWRConfig();

  const { data: project } = useSWR(props.isOpen ? API_PATHS.getProject(props.projectId) : null);
  const projectBillings = useMemo(() => {
    return cache.get(API_PATHS.getProjectBillings(props.projectId))?.data;
  }, [cache, props.isOpen]);

  // モーダルが開かれたらフォームを初期化する
  useEffect(() => {
    if (!props.isOpen) return;
    const calculateAmount = (salesAmount: number) => {
      if (!projectBillings) return salesAmount;
      const totalBilled = projectBillings.reduce(
        (total: number, item: ProjectBilling) => total + item.amount,
        0,
      );
      return salesAmount - totalBilled;
    };

    const calculatedTax = async (amount: number): Promise<number> => {
      const res = await salesTaxCalculator(amount);
      return amount === project.salesAmount ? project.salesTax : res.tax;
    };

    const fetchData = async () => {
      dispatch(mainOperations.updateIsLoading(true));
      try {
        const amount = calculateAmount(project.salesAmount);
        const tax: number = await calculatedTax(amount);
        const projectBilling = [
          {
            projectId: project.id,
            amount,
            tax,
          },
        ];

        setFormState({
          ...formState,
          projectBillings: projectBilling,
          billingDate: new Date().toString(),
          subject: project.name,
          mailingName: mailingName({
            name: project.client?.name || "",
            billingPersonName: project.client?.billingPersonName || "",
            honorificTitle: project.client?.honorificTitle || "",
            billingPersonSection: project.client?.billingPersonSection || "",
            billingPersonPosition: project.client?.billingPersonPosition || "",
          }),
          mailingAddress: mailingAddress({
            postalCode: project.client?.postalCode || "",
            address: project.client?.address || "",
            addressSecond: project.client?.addressSecond || "",
          }),
          code:
            // TODO: default_billing_code_formatを適用する
            mainState.hub.uuid === MEIHOU_ID
              ? `${project.code}---`
              : `${project.code}-${projectBillings.length + 1}`,
          statusType: "before_billed",
        });
      } catch (error) {
        handleStateError(error, setErrorMessage, "案件情報の取得に失敗しました");
      } finally {
        dispatch(mainOperations.updateIsLoading(false));
      }
    };

    fetchData();
  }, [props.isOpen]);

  const handleChangeAmount = (projectBillingToUpdate: {
    projectId: ProjectId;
    amount: number;
    tax: number;
  }) => {
    setFormState((prevState) => ({
      ...prevState,
      projectBillings: prevState.projectBillings.map((projectBilling) =>
        projectBilling.projectId === projectBillingToUpdate.projectId
          ? {
              ...projectBilling,
              amount: projectBillingToUpdate.amount,
              tax: projectBillingToUpdate.tax,
            }
          : projectBilling,
      ),
    }));
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFormState({ ...formState, [e.target.name]: e.target.value });
  };

  const handleRadio = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isWarrantyAttach = e.target.value === "true";
    setFormState({ ...formState, isWarrantyAttach });
  };

  const handleCheck = (
    _: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    checked: boolean,
  ) => {
    switch (checked) {
      case true:
        return setFormState({ ...formState, statusType: "billed" });
      case false:
        return setFormState({ ...formState, statusType: "before_billed" });
    }
  };

  const handleDateChange = (date: Date) => {
    setFormState({ ...formState, billingDate: date.toString() });
  };

  const handleClickEndOfMonth = () => {
    const date = new Date(formState.billingDate);
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    setFormState({ ...formState, billingDate: lastDay.toString() });
  };

  const handleClose = () => {
    props.setIsOpen(false);
    setFormState(initialBillingCreateRequest);
    setErrorMessage("");
  };

  const findProjectBillingAmount = (currentProject: Project) => {
    const foundProjectBilling = formState.projectBillings.find(
      (projectBilling) => projectBilling.projectId === currentProject.id,
    );
    return foundProjectBilling || { id: 0, amount: 0, tax: 0 };
  };

  const sumAmount = useMemo((): number => {
    return formState.projectBillings.reduce((total, item) => total + item.amount, 0);
  }, [formState.projectBillings]);

  const createBilling = async () => {
    try {
      await billingRepository.create(formState);
      mutate(API_PATHS.getProjectBillings(props.projectId));
      dispatch(mainOperations.updateSuccessMessage("請求書を作成しました"));
      handleClose();
    } catch (error) {
      setErrorMessage(error.response.data.message);
    }
  };

  const createBillingAndDownload = async () => {
    try {
      const createdBilling = await billingRepository.create(formState);
      const blob: Blob = await billingRepository.downloadTemplate(createdBilling.id);
      downloadBlob(blob, `【請求書】${formState.code} ${formState.subject}.xlsx`);
      mutate(API_PATHS.getProjectBillings(props.projectId));
      dispatch(mainOperations.updateSuccessMessage("請求書を作成しました"));
      props.setIsOpen(false);
      setFormState(initialBillingCreateRequest);
    } catch (error) {
      setErrorMessage(error.response.data.message);
    }
  };

  const scrollTopRef = useRef<HTMLDivElement>(null);
  useLayoutEffect(() => {
    if (errorMessage !== "") {
      scrollTopRef?.current?.scrollIntoView();
    }
  }, [errorMessage]);

  return (
    <CustomModal
      open={props.isOpen}
      onClose={handleClose}
      title="請求を新規作成"
      footer={
        <>
          <Button variant="outlined" onClick={handleClose} sx={{ width: "10rem" }}>
            キャンセル
          </Button>
          <Button onClick={createBilling} variant="contained" sx={{ width: "10rem", ml: "1rem" }}>
            作成する
          </Button>
          <Button
            onClick={createBillingAndDownload}
            variant="contained"
            sx={{ width: "15rem", ml: "1rem", textTransform: "none" }}
          >
            作成＋請求書 Excelダウンロード
          </Button>
        </>
      }
    >
      <div ref={scrollTopRef} />
      {errorMessage && (
        <Flash title="エラー" severity="error" message={errorMessage} sx={{ mt: "1rem" }} />
      )}
      <div
        style={{
          display: "flex",
          alignItems: "end",
          backgroundColor: theme.palette.customPrimary[50],
          padding: "12px",
          marginBottom: "12px",
          borderRadius: "4px",
          gap: "8px",
        }}
      >
        <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>請求金額合計</Typography>
        <Typography sx={{ fontSize: "18px", fontWeight: "bold" }}>
          ¥{sumAmount.toLocaleString()}
        </Typography>
        <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>（税別）</Typography>
      </div>
      <div>
        <CustomTypographyTitle sx={{ mb: "12px" }}>案件</CustomTypographyTitle>
        {project && (
          <ProjectDetailCard
            project={project}
            stateAmount={findProjectBillingAmount(project).amount}
            stateTax={findProjectBillingAmount(project).tax}
            onChange={handleChangeAmount}
          />
        )}
      </div>
      <Divider sx={{ my: "20px" }} />
      <div>
        <CustomTypographyTitle sx={{ mb: "12px" }}>顧客</CustomTypographyTitle>
        <ClientBlock client={project?.client} isEditable={false} isDeselectButtonVisible={false} />
      </div>
      <Divider sx={{ my: "20px" }} />
      <div style={{ marginBottom: "20px" }}>
        <CustomTypographyTitle sx={{ mb: "12px" }}>顧客宛名</CustomTypographyTitle>
        <TextField
          id="mailingName"
          name="mailingName"
          placeholder="顧客宛名を記載"
          value={formState.mailingName}
          onChange={handleChange}
          multiline
          rows={4}
          sx={{ width: "100%" }}
        />
      </div>
      <div style={{ marginBottom: "20px" }}>
        <div style={{ display: "flex" }}>
          <CustomTypographyTitle sx={{ mb: "12px" }}>顧客宛先住所</CustomTypographyTitle>
          <Tooltip
            title="住所などの追記・修正ができます。会社住所が変わる場合は顧客を編集してください。"
            placement="right"
          >
            <HelpOutlined sx={{ ml: "1rem", verticalAlign: "middle" }} color="primary" />
          </Tooltip>
        </div>
        <TextField
          id="mailingAddress"
          name="mailingAddress"
          placeholder="顧客住所を記載"
          value={formState.mailingAddress}
          onChange={handleChange}
          multiline
          maxRows={3}
          sx={{ width: "100%" }}
        />
      </div>
      <div style={{ marginBottom: "20px" }}>
        <CustomTypographyTitle sx={{ mb: "12px" }}>請求番号</CustomTypographyTitle>
        <TextField
          id="code"
          name="code"
          value={formState.code}
          onChange={handleChange}
          sx={{ width: "40%" }}
        />
      </div>
      <div style={{ marginBottom: "20px" }}>
        <CustomTypographyTitle sx={{ mb: "12px" }}>請求書件名</CustomTypographyTitle>
        <TextField
          id="subject"
          name="subject"
          value={formState.subject}
          onChange={handleChange}
          sx={{ width: "40%" }}
        />
      </div>
      <div style={{ marginBottom: "20px" }}>
        <CustomTypographyTitle sx={{ mb: "12px" }}>請求日</CustomTypographyTitle>
        <div style={{ display: "flex", alignItems: "center", gap: "16px" }}>
          <CustomDatePicker
            id="billingDate"
            name="billingDate"
            date={formState.billingDate ? new Date(formState.billingDate) : null}
            onChange={handleDateChange}
            popperPlacement="right"
          />
          <Button onClick={handleClickEndOfMonth} variant="outlined" sx={{ height: "32px" }}>
            月末
          </Button>
        </div>
      </div>
      <div style={{ marginBottom: "20px" }}>
        <CustomTypographyTitle sx={{ mb: "12px" }}>請求メモ</CustomTypographyTitle>
        <TextField
          id="note"
          name="note"
          value={formState.note}
          onChange={handleChange}
          multiline
          rows={4}
          sx={{ width: "100%" }}
        />
      </div>
      {mainState.hubSetting.is_warranty_use && (
        <div>
          <CustomTypographyTitle sx={{ mb: "12px" }}>保証書</CustomTypographyTitle>
          <RadioGroup name="isWarrantyAttach" row onChange={handleRadio}>
            <FormControlLabel
              control={<Radio size="small" checked={formState.isWarrantyAttach} />}
              value={true}
              sx={{ "& .MuiFormControlLabel-label": { fontWeight: "bold", fontSize: "14px" } }}
              label="付与する"
            />
            <FormControlLabel
              control={<Radio size="small" checked={!formState.isWarrantyAttach} />}
              value={false}
              sx={{ "& .MuiFormControlLabel-label": { fontWeight: "bold", fontSize: "14px" } }}
              label="付与しない"
            />
          </RadioGroup>
        </div>
      )}
      {mainState.hubSetting.is_billing_status_type_use && (
        <>
          <Divider sx={{ my: "20px" }} />
          <div style={{ textAlign: "center" }}>
            <FormControlLabel
              control={
                <Checkbox
                  name="isBilled"
                  onChange={handleCheck}
                  size="small"
                  checked={formState.statusType === "billed"}
                />
              }
              label="送付ステータスを「送付済み」にする"
              labelPlacement="start"
              sx={{ "& .MuiFormControlLabel-label": { fontSize: "14px", userSelect: "none" } }}
            />
          </div>
        </>
      )}
    </CustomModal>
  );
};

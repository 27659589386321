import React from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {
  Autocomplete,
  Box,
  FormControl,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { theme } from "extensions/theme";
import { CsvImportScheduleRequest } from "features/hub-settings/types/csv-import/csv_import_schedule.dto";

interface Props {
  formState: CsvImportScheduleRequest;
  csvHeaders: string[];
  onChange: (e: SelectChangeEvent<string>) => void;
}
export const MappingTable = (props: Props) => {
  return (
    <Box sx={{ my: "40px", maxWidth: "1200px" }}>
      <Grid container spacing={2} alignContent="center">
        <Grid size={5}>
          <Typography fontWeight="bold" fontSize={14}>
            CSVファイルの項目
          </Typography>
        </Grid>
        <Grid size={2} />
        <Grid size={5}>
          <Typography fontWeight="bold" fontSize={14}>
            現場Hubの項目
          </Typography>
        </Grid>

        {columnMapFields.map((field) => (
          <React.Fragment key={field.name}>
            <Grid size={5} sx={{ display: "flex", alignItems: "center" }}>
              <FormControl size="small" fullWidth>
                <Autocomplete
                  size="small"
                  value={props.formState.columnMap[field.name]}
                  onChange={(_, newValue) => {
                    props.onChange({
                      target: { name: field.name, value: newValue || "" },
                    } as SelectChangeEvent<string>);
                  }}
                  options={props.csvHeaders}
                  renderInput={(params) => <TextField {...params} label="項目を選択" />}
                  getOptionDisabled={(option) =>
                    Object.values(props.formState.columnMap).includes(option) &&
                    props.formState.columnMap[field.name] !== option
                  }
                />
              </FormControl>
            </Grid>
            <Grid size={2} display="flex" alignItems="center" justifyContent="center">
              <ArrowForwardIcon />
            </Grid>
            <Grid
              size={5}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                gap: "4px",
              }}
            >
              <Typography fontWeight="bold">{field.label}</Typography>
              <Typography fontSize={14} color={theme.palette.grayScale[700]} whiteSpace="pre-line">
                {field.explanation}
              </Typography>
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
    </Box>
  );
};

interface ColumnMapField {
  name: keyof CsvImportScheduleRequest["columnMap"];
  label: string;
  explanation: string;
}
const columnMapFields: ColumnMapField[] = [
  { name: "name", label: "予定名", explanation: "" },
  {
    name: "scheduleTypeName",
    label: "予定タイプ名",
    explanation:
      "現場Hubで設定している案件タイプ名と一致している必要があります\n未入力の場合は、マスタで一番上に設定されている予定タイプになります",
  },
  {
    name: "startTime",
    label: "開始日時",
    explanation: "yyyy/MM/dd hh:mmの形式で半角空白である必要があります\n例: 2024/02/04 08:00",
  },
  {
    name: "endTime",
    label: "終了日時",
    explanation: "yyyy/MM/dd hh:mmの形式で半角空白である必要があります\n例: 2024/02/04 17:00",
  },
  {
    name: "siteManagerName",
    label: "現場責任者",
    explanation: "現場Hubで設定しているメンバー名と一致している必要があります",
  },
  {
    name: "assignmentUserNames",
    label: "参加者",
    explanation:
      "現場Hubで設定しているメンバー名と一致している必要があります\n参加メンバーは一つのセルで改行で入力してください",
  },
  {
    name: "confirmed",
    label: "予定の確定/未確定",
    explanation:
      "「確定」「未確定」で記載してください\nCSVの項目が未設定の場合、すべての予定が「未確定」になります",
  },
  {
    name: "projectCode",
    label: "予定に紐づく現場Hubの案件番号",
    explanation: "現場Hubで設定している案件番号と一致している必要があります",
  },
  {
    name: "visibility",
    label: "予定の公開 / 非公開",
    explanation:
      "「公開」「非公開」で記載してください\nCSVの項目が未設定の場合、すべての予定が「公開」になります",
  },
];

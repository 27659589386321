import { useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Divider } from "@mui/material";
import { BillingDetails } from "components/organisms/billing-details";
import { BillingSidebarActivity } from "components/organisms/billing-sidebar-activity";
import { BillingSidebarClientInfo } from "components/organisms/billing-sidebar-client-info";
import { BillingSidebarDepositBlock } from "components/organisms/billing-sidebar-deposit-block";
import { BillingSidebarHeader } from "components/organisms/billing-sidebar-header";
import { BillingSidebarInvoicesBlock } from "components/organisms/billing-sidebar-invoices-block";
import { BillingSidebarWarrantiesBlock } from "components/organisms/billing-sidebar-warranties-block";
import { DetailSidebar } from "components/templates/detail-sidebar";
import { ResponseSnackbar } from "components/templates/response-snackbar";
import { projectBillingRepository } from "data-access/repositories/billing/project_billing/project_billing.repository";
import { billingSidebarOperations } from "store/billing-sidebar/operations";
import { selectBillingSidebar } from "store/billing-sidebar/slice";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { selectMain } from "store/main/slice";
import useSWR from "swr";

export const BillingSidebar = () => {
  const dispatch = useAppDispatch();
  const state = useAppSelector(selectBillingSidebar);
  const mainState = useAppSelector(selectMain);
  const navigate = useNavigate();
  const location = useLocation();

  const { data: projectBillings } = useSWR(
    state.billing.id ? `/api/v1/billings/${state.billing.id}/project_billings` : null,
    () => projectBillingRepository.index(state.billing.id),
  );

  const sumTaxIncludedAmount = useMemo(() => {
    if (projectBillings) {
      return projectBillings.reduce((total, item) => total + item.amount + item.tax, 0);
    }
    return 0;
  }, [projectBillings]);

  useEffect(() => {
    if (!location.pathname.includes("billings")) {
      dispatch(billingSidebarOperations.close());
    }
  }, [location]);

  const handleOnCloseDetailsSidebar = () => {
    if (window.location.pathname.includes("billings")) {
      navigate("/billings");
    }
  };

  const handleCloseSuccessMessage = () => {
    dispatch(billingSidebarOperations.updateSuccessMessage(""));
  };
  const handleCloseErrorMessage = () => {
    dispatch(billingSidebarOperations.updateErrorMessage(""));
  };

  return (
    <DetailSidebar objectName="billing" onClose={handleOnCloseDetailsSidebar}>
      <ResponseSnackbar
        successMessage={mainState.successMessage || state.successMessage}
        errorMessage={mainState.errorMessage || state.errorMessage}
        handleCloseSuccess={handleCloseSuccessMessage}
        handleCloseError={handleCloseErrorMessage}
      />
      <BillingSidebarHeader
        billingForm={state.form}
        onClose={handleOnCloseDetailsSidebar}
        isBillingStatusTypeUse={mainState.hubSetting.is_billing_status_type_use}
      />

      <div style={{ display: "flex", marginTop: "16px", marginBottom: "32px" }}>
        <div style={{ width: "670px" }}>
          {projectBillings && (
            <BillingDetails
              billingId={state.billing.id}
              projectBillings={projectBillings}
              sumAmount={sumTaxIncludedAmount}
            />
          )}
          <Divider sx={{ my: "24px" }} />
          {projectBillings && (
            <BillingSidebarClientInfo projectBillings={projectBillings} state={state} />
          )}
          <Divider sx={{ my: "24px" }} />
          <BillingSidebarActivity state={state} />
        </div>

        <Divider orientation="vertical" variant="middle" sx={{ mx: "16px" }} />

        <div style={{ width: "350px" }}>
          <BillingSidebarDepositBlock state={state} sumAmount={sumTaxIncludedAmount} />
          <Divider sx={{ my: "32px" }} />
          <BillingSidebarInvoicesBlock />
          <Divider sx={{ my: "32px" }} />
          {mainState.hubSetting.is_warranty_use && <BillingSidebarWarrantiesBlock />}
        </div>
      </div>
    </DetailSidebar>
  );
};

import { useMemo } from "react";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { Button } from "@mui/material";
import { csvImportProjectRepository } from "features/hub-settings/api/csv-import/csv_import_project.repository";
import { csvImportScheduleRepository } from "features/hub-settings/api/csv-import/csv_import_schedule.repository";
import {
  CsvImportProjectId,
  CsvImportStatus,
} from "features/hub-settings/types/csv-import/csv_import_project.dto";
import { CsvImportScheduleId } from "features/hub-settings/types/csv-import/csv_import_schedule.dto";
import { useAppDispatch } from "store/hooks";
import { mainOperations } from "store/main/operations";
import { mutate } from "swr";
import { API_PATHS } from "utils/apiPaths";
import { CSV_IMPORT_IN_CANCELED_STATUS, CSV_IMPORT_IN_PROGRESS_STATUS } from "utils/constant";
import { downloadBlob } from "utils/downloadBlob";
import { handleReduxError } from "utils/errorHandler";

interface Props {
  id: CsvImportProjectId | CsvImportScheduleId;
  moduleName: "project" | "schedule";
  fileName: string;
  status: CsvImportStatus;
  failedCount: number;
}
export const CsvImportStatusButton = (props: Props) => {
  const dispatch = useAppDispatch();

  const handleCancel = async () => {
    dispatch(mainOperations.updateIsLoading(true));
    try {
      if (props.moduleName === "project") {
        await csvImportProjectRepository.importCancel(props.id as CsvImportProjectId);
        mutate(API_PATHS.getProjectImportJobs());
      } else {
        await csvImportScheduleRepository.importCancel(props.id as CsvImportScheduleId);
        mutate(API_PATHS.getScheduleImportJobs());
      }
      dispatch(mainOperations.updateSuccessMessage("インポートを中止しました"));
    } catch (e) {
      handleReduxError(e, dispatch, "インポートの中止に失敗しました");
    } finally {
      dispatch(mainOperations.updateIsLoading(false));
    }
  };

  const handleDownloadCsv = async () => {
    dispatch(mainOperations.updateIsLoading(true));
    try {
      let res;
      if (props.moduleName === "project") {
        res = await csvImportProjectRepository.resultUrl(props.id as CsvImportProjectId);
      } else {
        res = await csvImportScheduleRepository.resultUrl(props.id as CsvImportScheduleId);
      }
      const response = await fetch(res.url);
      const blob = await response.blob();
      downloadBlob(blob, props.fileName);
    } catch (e) {
      handleReduxError(e, dispatch, "CSVのダウンロードに失敗しました");
    } finally {
      dispatch(mainOperations.updateIsLoading(false));
    }
  };

  const statusConfig = useMemo(() => {
    if (props.status === "succeeded") {
      return {
        title: "CSVをダウンロード",
        color: "primary" as const,
        variant: "outlined" as const,
        icon: <FileDownloadOutlinedIcon fontSize="small" />,
        onClick: handleDownloadCsv,
      };
    }
    if (CSV_IMPORT_IN_PROGRESS_STATUS.includes(props.status)) {
      return {
        title: "インポート中止",
        color: "error" as const,
        variant: "contained" as const,
        icon: <CancelOutlinedIcon fontSize="small" />,
        onClick: handleCancel,
      };
    }
    if (CSV_IMPORT_IN_CANCELED_STATUS.includes(props.status) && props.failedCount === 0) {
      return {
        title: "CSVをダウンロード",
        color: "primary" as const,
        variant: "outlined" as const,
        icon: <FileDownloadOutlinedIcon fontSize="small" />,
        onClick: handleDownloadCsv,
      };
    }
    if (CSV_IMPORT_IN_CANCELED_STATUS.includes(props.status) && props.failedCount > 0) {
      return {
        title: "エラー内容を含むCSV",
        color: "primary" as const,
        variant: "contained" as const,
        icon: <FileDownloadOutlinedIcon fontSize="small" />,
        onClick: handleDownloadCsv,
      };
    }
    if (props.status === "failed") {
      return {
        title: "エラー内容を含むCSV",
        color: "primary" as const,
        variant: "contained" as const,
        icon: <FileDownloadOutlinedIcon fontSize="small" />,
        onClick: handleDownloadCsv,
      };
    }
    return {
      title: "",
      color: "primary" as const,
      variant: "outlined" as const,
      icon: <FileDownloadOutlinedIcon fontSize="small" />,
      onClick: () => {},
    };
  }, [props.status]);

  return (
    <Button
      color={statusConfig.color}
      variant={statusConfig.variant}
      onClick={statusConfig.onClick}
      sx={{ gap: "4px", width: "160px", height: "32px", fontSize: "12px" }}
    >
      {statusConfig.icon}
      {statusConfig.title}
    </Button>
  );
};

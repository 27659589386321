import { ApiClient } from "data-access/ApiClient";
import {
  CsvImportSchedule,
  CsvImportScheduleAnalyzeRequest,
  CsvImportScheduleAnalyzeResponse,
  CsvImportScheduleId,
  CsvImportScheduleIndexResponse,
  CsvImportScheduleRequest,
} from "features/hub-settings/types/csv-import/csv_import_schedule.dto";
import { convertKeysToCamelCase } from "utils/convertObjectKeyCase";

export const csvImportScheduleRepository = {
  index(page: number): Promise<CsvImportScheduleIndexResponse> {
    return ApiClient.get(`/api/v1/schedule_import_jobs?limit=10&page=${page}`).then((res) => {
      return convertKeysToCamelCase(res.data);
    });
  },
  create(body: CsvImportScheduleRequest): Promise<{ id: CsvImportScheduleId }> {
    const config = { headers: { "content-type": "application/json" } };
    return ApiClient.post("/api/v1/schedule_import_jobs", createBody(body), config).then((res) => {
      return convertKeysToCamelCase(res.data);
    });
  },
  latest(): Promise<CsvImportSchedule> {
    return ApiClient.get("/api/v1/schedule_import_jobs/latest").then((res) => {
      return convertKeysToCamelCase(res.data);
    });
  },
  async putPresignedUrl(file: File): Promise<{ url: string; objectKey: string }> {
    try {
      const preSignedUrlResponse = await ApiClient.get(
        "/api/v1/schedule_import_jobs/put_presigned_url",
      );
      const preSignedUrlData = preSignedUrlResponse.data;

      if (preSignedUrlResponse.status !== 200 || !preSignedUrlData.url) {
        throw new Error("Failed to get presigned URL");
      }

      const uploadResponse = await fetch(preSignedUrlData.url, {
        method: "PUT",
        body: file,
        headers: {
          "Content-Type": file.type,
        },
      });

      if (uploadResponse.status !== 200) {
        throw new Error("Failed to upload file");
      }
      return { url: preSignedUrlData.url, objectKey: preSignedUrlData.object_key };
    } catch {
      throw new Error("Failed to upload file");
    }
  },
  analyze(body: CsvImportScheduleAnalyzeRequest): Promise<CsvImportScheduleAnalyzeResponse> {
    const config = { headers: { "content-type": "application/json" } };
    const bodyData = {
      object_key: body.objectKey,
    };
    return ApiClient.post("/api/v1/schedule_import_jobs/analyze_csv", bodyData, config).then(
      (res) => {
        return convertKeysToCamelCase(res.data);
      },
    );
  },
  importCancel(id: CsvImportScheduleId): Promise<CsvImportSchedule> {
    return ApiClient.post(`/api/v1/schedule_import_jobs/${id}/cancel`).then((res) => {
      return convertKeysToCamelCase(res.data);
    });
  },
  resultUrl(id: CsvImportScheduleId): Promise<{ url: string }> {
    return ApiClient.get(`/api/v1/schedule_import_jobs/${id}/result_url`).then((res) => {
      return res.data;
    });
  },
};

const createBody = (body: CsvImportScheduleRequest) => {
  return {
    object_key: body.objectKey,
    filename: body.filename,
    column_map: {
      name: body.columnMap.name,
      schedule_type_name: body.columnMap.scheduleTypeName,
      start_time: body.columnMap.startTime,
      end_time: body.columnMap.endTime,
      site_manager_name: body.columnMap.siteManagerName,
      assignment_user_names: body.columnMap.assignmentUserNames,
      confirmed: body.columnMap.confirmed,
      project_code: body.columnMap.projectCode,
      visibility: body.columnMap.visibility,
    },
  };
};

import { useEffect, useState } from "react";
import { Box, Button, Grid2, Stack, TextField, Typography } from "@mui/material";
import { Avatar } from "components/avatar";
import { GroupLabel } from "components/label/group-label";
import {
  AccountReference,
  initialAccountReference,
} from "data-access/repositories/account/account.dto";
import { theme } from "extensions/theme";
import { meRepository } from "features/user-settings/api/profile-setting/me.repository";
import { useAppDispatch } from "store/hooks";
import { mainOperations } from "store/main/operations";
import useSWR from "swr";
import { handleReduxError } from "utils/errorHandler";
import { styles } from "./styles";

export const ProfileSetting = () => {
  const dispatch = useAppDispatch();
  const { data, mutate } = useSWR("/api/v1/me", meRepository.index, {
    revalidateOnFocus: false,
  });
  const [formState, setFormState] = useState<AccountReference>(initialAccountReference);

  useEffect(() => {
    if (data) {
      setFormState(data);
    }
  }, [data]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormState({
      ...formState,
      [e.target.name]: e.target.value,
    });
  };

  const handleBlur = async (e: React.FocusEvent<HTMLInputElement>) => {
    const fieldName = e.target.name as keyof AccountReference;
    if (!data || data[fieldName] === e.target.value) {
      return;
    }
    dispatch(mainOperations.updateIsLoading(true));
    try {
      const res = await meRepository.update({
        [e.target.name]: e.target.value,
      });
      mutate(res);
      dispatch(mainOperations.updateSuccessMessage("保存しました"));
    } catch (error) {
      handleReduxError(error, dispatch, "保存に失敗しました");
    } finally {
      dispatch(mainOperations.updateIsLoading(false));
    }
  };

  const handleAvatarChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;
    dispatch(mainOperations.updateIsLoading(true));
    try {
      const res = await meRepository.update({
        profileImage: file,
      });
      mutate(res, false);
      dispatch(mainOperations.updateSuccessMessage("画像を変更しました"));
    } catch (error) {
      handleReduxError(error, dispatch, "画像の変更に失敗しました");
    } finally {
      dispatch(mainOperations.updateIsLoading(false));
    }
  };

  const handleAvatarDelete = async () => {
    dispatch(mainOperations.updateIsLoading(true));
    try {
      const res = await meRepository.update({
        deleteProfileImage: true,
      });
      mutate(res, false);
      dispatch(mainOperations.updateSuccessMessage("画像を削除しました"));
    } catch (error) {
      handleReduxError(error, dispatch, "画像の削除に失敗しました");
    } finally {
      dispatch(mainOperations.updateIsLoading(false));
    }
  };

  return (
    <Stack spacing={3} width="420px">
      <Grid2 container spacing={4}>
        <Grid2 size={2} sx={{ ...styles.avatar }}>
          <Avatar
            url={formState.profileImageUrl}
            name={formState.name}
            bgColor={formState.profileImageBackgroundColorNumber}
            size="medium"
            sx={{ borderRadius: "50%" }}
          />
          <Box className="overlay" sx={{ ...styles.avatarOverlay }} />
          <Box className="avatarButtons" sx={{ ...styles.avatarButtons }}>
            <Button variant="contained" component="label" sx={{ ...styles.avatarChangeButton }}>
              変更
              <input type="file" accept="image/*" hidden onChange={handleAvatarChange} />
            </Button>
            {formState.profileImageUrl && (
              <Button
                variant="text"
                onClick={handleAvatarDelete}
                sx={{ ...styles.avatarDeleteButton }}
              >
                削除
              </Button>
            )}
          </Box>
        </Grid2>
        <Grid2 size={9}>
          <Typography sx={{ ...styles.label }}>ユーザー名</Typography>
          <TextField
            name="name"
            value={formState.name}
            onChange={handleChange}
            onBlur={handleBlur}
            size="small"
            fullWidth
          />
        </Grid2>
      </Grid2>
      <div>
        <Typography sx={{ ...styles.label }}>グループ</Typography>
        <GroupLabel
          name={formState.group ? formState.group.name : "未設定"}
          colorNumber={formState.group ? formState.group.colorNumber : theme.palette.grayScale[0]}
          sx={{ border: formState.group ? "inherit" : `1px solid ${theme.palette.grayScale[900]}` }}
        />
      </div>
      <div>
        <Typography sx={{ ...styles.label }}>メールアドレス</Typography>
        <Typography>{formState.email}</Typography>
      </div>
      <div>
        <Typography sx={{ ...styles.label }}>電話番号</Typography>
        <TextField
          name="phoneNumber"
          value={formState.phoneNumber}
          onChange={handleChange}
          onBlur={handleBlur}
          size="small"
          fullWidth
        />
      </div>
    </Stack>
  );
};

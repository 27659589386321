import { Brand } from "../brand";

export type ScheduleTypeId = Brand<number, "ScheduleTypeId">;

export interface ScheduleType {
  id: ScheduleTypeId;
  name: string;
  colorNumber: string;
  isDefaultAllDay: boolean;
  isHoliday: boolean;
}

export interface ScheduleTypeRequest {
  name?: string;
  colorNumber?: string;
  displayOrderPosition?: number;
}

export const initialScheduleType: ScheduleType = {
  id: 0 as ScheduleTypeId,
  name: "",
  colorNumber: "",
  isDefaultAllDay: false,
  isHoliday: false,
};

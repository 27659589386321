import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ChevronLeft } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { Layout } from "components/templates/layout";
import { csvImportProjectRepository } from "features/hub-settings/api/csv-import/csv_import_project.repository";
import {
  ColumnMap,
  CsvImportProjectAnalyzeResponse,
  CsvImportProjectRequest,
  initialImportCsvProjectRequest,
} from "features/hub-settings/types/csv-import/csv_import_project.dto";
import { useAppDispatch } from "store/hooks";
import { mainOperations } from "store/main/operations";
import useSWR from "swr";
import { API_PATHS } from "utils/apiPaths";
import { handleReduxError } from "utils/errorHandler";
import { getFileUrl } from "utils/getFileUrl";
import { AnalyzeModal } from "./modal";
import { MappingTable } from "./table";

export const ProjectCsvImportMapping = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [csvHeaders, setCsvHeaders] = useState<string[]>([]);
  const [formState, setFormState] = useState<CsvImportProjectRequest>(
    initialImportCsvProjectRequest,
  );
  const [analyzeResult, setAnalyzeResult] = useState<CsvImportProjectAnalyzeResponse>({
    createCount: 0,
    updateCount: 0,
    estimatedTimeSeconds: 0,
  });
  const [isAnalyzeModalOpen, setIsAnalyzeModalOpen] = useState<boolean>(false);
  const fileUrl = getFileUrl();

  const { data: importLatest } = useSWR(
    API_PATHS.getProjectImportJobsLatest(),
    csvImportProjectRepository.latest,
    {
      revalidateOnFocus: false,
    },
  );

  useEffect(() => {
    const getCsvHeaders = async () => {
      const url = `${fileUrl}/${location.state.objectKey}`;
      const res = await fetch(url);
      const text = await res.text();
      const headers = text
        .replace(/^\ufeff/, "")
        .split(/\r?\n/)[0]
        .split(",");
      setCsvHeaders(headers);

      const columnMap = importLatest?.columnMap || formState.columnMap;
      (Object.keys(columnMap) as Array<keyof ColumnMap>).forEach((key) => {
        if (!headers.includes(columnMap[key])) {
          columnMap[key] = "";
        }
      });

      setFormState({
        ...formState,
        objectKey: location.state.objectKey,
        filename: location.state.fileName,
        columnMap,
      });
    };
    getCsvHeaders();
  }, [location.state.objectKey]);

  const handleChange = (e: SelectChangeEvent<string>) => {
    setFormState((prev) => ({
      ...prev,
      columnMap: {
        ...prev.columnMap,
        [e.target.name]: e.target.value,
      },
    }));
  };

  const handleSubmit = async () => {
    dispatch(mainOperations.updateIsLoading(true));
    try {
      const res = await csvImportProjectRepository.analyze({
        objectKey: formState.objectKey,
        importKey: formState.importKey,
        importKeyTitle:
          formState.importKey === "code"
            ? formState.columnMap.code
            : formState.columnMap.externalId,
      });
      setAnalyzeResult(res);
      setIsAnalyzeModalOpen(true);
    } catch (error) {
      handleReduxError(error, dispatch, "クイック検証に失敗しました");
    } finally {
      dispatch(mainOperations.updateIsLoading(false));
    }
  };

  const handleImportStart = async () => {
    try {
      await csvImportProjectRepository.create(formState);
    } catch (error) {
      handleReduxError(error, dispatch, "インポートに失敗しました");
    } finally {
      navigate("/hub-settings/csv-imports/project");
    }
  };

  const isValid = useMemo(() => {
    if (formState.importKey === "externalId") {
      return !!formState.columnMap.externalId;
    }
    return true;
  }, [formState.importKey, formState.columnMap.externalId]);

  return (
    <Layout>
      <AnalyzeModal
        isOpen={isAnalyzeModalOpen}
        onClose={() => setIsAnalyzeModalOpen(false)}
        state={analyzeResult}
        onSubmit={handleImportStart}
      />

      <Box>
        <div
          onClick={() => navigate(-1)}
          style={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          <ChevronLeft />
          <Typography sx={{ fontSize: "20px", fontWeight: "bold", marginLeft: "8px" }}>
            項目のマッピング
          </Typography>
        </div>
        <Typography sx={{ fontSize: "12px", mt: "8px" }}>
          現場Hubの案件項目と対応する、CSVファイルのカラムを選択してください。
        </Typography>
        <Alert
          severity="error"
          sx={{ my: "24px", color: "error.main", "&.MuiPaper-rounded": { alignItems: "center" } }}
        >
          現場Hubの案件番号を取り込みキーとした場合、インポートを開始すると案件の新規作成ができなくなります。
          <br />
          案件100件のインポートには、約10秒かかります。
          <br />
          インポート中にHub設定を変更すると、インポートが失敗する可能性があります。
        </Alert>
        <Typography fontWeight="bold">取り込みキー</Typography>
        <RadioGroup name="importKey" row sx={{ my: "16px" }}>
          <FormControlLabel
            control={<Radio size="small" checked={formState.importKey === "code"} />}
            value="code"
            onClick={() =>
              setFormState((prev) => ({
                ...prev,
                importKey: "code",
                columnMap: {
                  ...prev.columnMap,
                  externalId: "",
                },
              }))
            }
            sx={{ "& .MuiFormControlLabel-label": { fontWeight: "bold", fontSize: "14px" } }}
            label="現場Hubの案件番号"
          />
          <FormControlLabel
            control={<Radio size="small" checked={formState.importKey === "externalId"} />}
            value="externalId"
            onClick={() =>
              setFormState((prev) => ({
                ...prev,
                importKey: "externalId",
              }))
            }
            sx={{ "& .MuiFormControlLabel-label": { fontWeight: "bold", fontSize: "14px" } }}
            label="外部システムのID"
          />
        </RadioGroup>
        {formState.importKey === "code" ? (
          <Typography fontSize={12}>
            入力された案件番号が未登録の場合は新規作成され、既に登録済みの場合は案件情報が更新されます。
          </Typography>
        ) : (
          <Typography fontSize={12}>
            入力された外部システムのIDが未登録の場合は新規作成され、既に登録済みの場合は案件情報が更新されます。外部システムのIDは貴社の他システムで使用されているIDを入力してください。
          </Typography>
        )}

        <MappingTable formState={formState} csvHeaders={csvHeaders} onChange={handleChange} />

        <Box>
          <Button
            onClick={handleSubmit}
            variant="contained"
            sx={{ fontWeight: "bold", height: "36px", width: "160px", mr: "16px" }}
            disabled={!isValid}
          >
            クイック検証
          </Button>
          <Button
            onClick={() => navigate(-1)}
            variant="outlined"
            sx={{ height: "36px", width: "160px" }}
          >
            キャンセル
          </Button>
        </Box>
      </Box>
    </Layout>
  );
};

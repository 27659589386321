import { theme } from "extensions/theme";

export const styles = {
  label: {
    fontSize: "12px",
    fontWeight: "bold",
    marginBottom: "8px",
  },
  avatar: {
    position: "relative",
    width: "fit-content",
    "&:hover": {
      "& .overlay": {
        display: "block",
      },
      "& .avatarButtons": {
        display: "flex",
      },
    },
  },
  avatarOverlay: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    bgcolor: "rgba(0, 0, 0, 0.5)",
    display: "none",
  },
  avatarButtons: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    display: "none",
    flexDirection: "column",
    gap: "4px",
  },
  avatarChangeButton: {
    minWidth: "40px",
    width: "40px",
    height: "24px",
    lineHeight: 1,
    p: 0,
    bgcolor: theme.palette.grayScale[0],
    color: theme.palette.primary.main,
    fontSize: "11px",
    fontWeight: "bold",
    "&:hover": {
      bgcolor: theme.palette.grayScale[0],
      opacity: 0.8,
    },
  },
  avatarDeleteButton: {
    minWidth: "40px",
    width: "40px",
    height: "24px",
    lineHeight: 1,
    p: 0,
    color: theme.palette.grayScale[0],
    fontSize: "11px",
    fontWeight: "bold",
    "&:hover": {
      opacity: 0.8,
    },
  },
};

import { ApiClient } from "data-access/ApiClient";
import { convertKeysToCamelCase } from "utils/convertObjectKeyCase";
import { NoticeLog } from "./logs.dto";

export const noticeLogRepository = {
  index(limit: number): Promise<NoticeLog[]> {
    return ApiClient.get("/api/v1/notice/logs", { params: { limit } }).then((res) => {
      return convertKeysToCamelCase(res.data);
    });
  },
  countInfo(): Promise<{ allCount: number; unreadCount: number }> {
    return ApiClient.get("/api/v1/notice/logs/count_info").then((res) => {
      return convertKeysToCamelCase(res.data);
    });
  },
};

import { useState } from "react";
import { Box, Button, Typography, Checkbox, FormControlLabel, Link } from "@mui/material";
import { LoadingOverlay } from "components/loadingOverlay";
import {
  SignUpHubRequest,
  SignUpResponse,
  SignUpVerifyResponse,
} from "data-access/repositories/sign_up/sign_up.dto";
import { signUpRepository } from "data-access/repositories/sign_up/sign_up.repository";
import { styles } from "./styles";
import { PageState } from ".";

interface Props {
  data: SignUpVerifyResponse;
  setPageState: (pageState: PageState) => void;
  formStateHub: SignUpHubRequest;
  token: string;
  setResponseData: (responseData: SignUpResponse) => void;
}
export const Confirm = (props: Props) => {
  const [isAgreed, setIsAgreed] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const openInNewTab = (url: string) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      const res = await signUpRepository.createHub(props.formStateHub);
      props.setResponseData(res);
      props.setPageState("created");
    } catch (error) {
      setErrorMessage(error.response.data?.message || "作成に失敗しました");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <LoadingOverlay isLoading={isSubmitting} />
      <Box sx={{ my: "32px", display: "flex", flexDirection: "column", alignItems: "center" }}>
        <Typography
          sx={{
            fontSize: "24px",
            fontWeight: "bold",
            textAlign: "center",
            mb: "40px",
            whiteSpace: "pre-line",
          }}
        >
          {`以下の内容でHubを\n作成してよろしいですか？`}
        </Typography>
        <Box sx={{ width: "100%" }}>
          <Typography sx={styles.contentTitle}>ユーザー情報</Typography>
          <Box sx={{ mb: "24px" }}>
            <Typography sx={styles.title}>メールアドレス</Typography>
            <Typography sx={styles.content}>{props.data.destinationEmail}</Typography>
          </Box>
          <Box sx={{ mb: "24px" }}>
            <Typography sx={styles.title}>パスワード</Typography>
            <Typography sx={styles.content}>
              {"*".repeat(props.formStateHub.password.length)}
            </Typography>
          </Box>
          <Box sx={{ mb: "24px" }}>
            <Typography sx={styles.title}>氏名</Typography>
            <Typography sx={styles.content}>{props.formStateHub.me.name}</Typography>
          </Box>
          <Box sx={{ mb: "24px" }}>
            <Typography sx={styles.title}>連絡のつきやすい電話番号</Typography>
            <Typography sx={styles.content}>{props.formStateHub.me.phoneNumber}</Typography>
          </Box>

          <Typography sx={{ ...styles.contentTitle }}>事業所情報</Typography>
          <Box sx={{ mb: "24px" }}>
            <Typography sx={styles.title}>会社名</Typography>
            <Typography sx={styles.content}>{props.formStateHub.hub.name}</Typography>
          </Box>
          <Box sx={{ mb: "24px" }}>
            <Typography sx={styles.title}>事業所名</Typography>
            <Typography sx={styles.content}>{props.formStateHub.hub.officeName}</Typography>
          </Box>
          <Box sx={{ mb: "24px" }}>
            <Typography sx={styles.title}>郵便番号</Typography>
            <Typography sx={styles.content}>{props.formStateHub.hub.postalCode}</Typography>
          </Box>
          <Box sx={{ mb: "24px" }}>
            <Typography sx={styles.title}>都道府県・市区町村・番地</Typography>
            <Typography sx={styles.content}>{props.formStateHub.hub.address}</Typography>
          </Box>
          <Box sx={{ mb: "24px" }}>
            <Typography sx={styles.title}>建物名・階・号室</Typography>
            <Typography sx={styles.content}>{props.formStateHub.hub.addressSecond}</Typography>
          </Box>
        </Box>
        {errorMessage && (
          <Typography color="error" sx={{ mb: "8px" }}>
            {errorMessage}
          </Typography>
        )}
        <FormControlLabel
          sx={{ mb: "8px" }}
          control={<Checkbox checked={isAgreed} onChange={(e) => setIsAgreed(e.target.checked)} />}
          label={
            <Box sx={{ display: "flex", alignItems: "center", fontSize: "14px" }}>
              <Link
                component="button"
                onClick={(e) => {
                  e.stopPropagation();
                  openInNewTab("https://lp.gembahub.jp/terms");
                }}
                sx={{ textDecoration: "underline", cursor: "pointer" }}
              >
                利用規約
              </Link>
              と
              <Link
                component="button"
                onClick={(e) => {
                  e.stopPropagation();
                  openInNewTab("https://corp.gembahub.jp/privacy");
                }}
                sx={{ textDecoration: "underline", cursor: "pointer" }}
              >
                プライバシーポリシー
              </Link>
              に同意する
            </Box>
          }
        />
        <Button
          variant="contained"
          onClick={handleSubmit}
          sx={{ borderRadius: "24px", width: "240px", mb: "12px" }}
          disabled={!isAgreed}
        >
          作成
        </Button>
        <Button onClick={() => props.setPageState("hub")}>もどる</Button>
      </Box>
    </>
  );
};

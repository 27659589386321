import { useState } from "react";
import { Button, TextField, Typography } from "@mui/material";
import { PasswordInput } from "components/input/password-input";
import { cookieRepository } from "data-access/cookie/cookie.repository";
import { authRepository } from "data-access/repositories/auth/auth.repository";
import { handleStateError } from "utils/errorHandler";
import { Flash } from "../../../components/atoms/flash";
import { PageState } from ".";

interface Props {
  setPage: (page: PageState) => void;
}
export const SignInForm = (props: Props) => {
  const { setPage } = props;
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

  const handleSubmit = async (event: React.ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsSubmitted(true);
    try {
      const apiTokens = await authRepository.create({ email, password });
      if (apiTokens) {
        // XSS対策のため、accessTokenとrefreshTokenを除く
        const cookie = {
          id: apiTokens.data.id,
          name: apiTokens.data.name,
          email: apiTokens.data.email,
          hub_id: apiTokens.data.hub_id,
          hub_uuid: apiTokens.data.hub_uuid,
          hub_name: apiTokens.data.hub_name,
          permissions: apiTokens.data.permissions,
        };
        cookieRepository.set("artisan", cookie);
        location.reload();
      }
    } catch (error) {
      handleStateError(error, setErrorMessage, "ログインに失敗しました");
    } finally {
      setIsSubmitted(false);
    }
  };

  const updateForm = (e: any) => {
    switch (e.target.name) {
      case "email":
        setEmail(e.target.value);
        break;
      case "password":
        setPassword(e.target.value);
        break;
    }
  };
  return (
    <>
      <Typography sx={{ fontSize: "24px", fontWeight: "bold", textAlign: "center", mb: "64px" }}>
        現場Hubへようこそ
      </Typography>
      <form
        onSubmit={handleSubmit}
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        {errorMessage && (
          <Flash message={errorMessage} severity="error" title="エラー" sx={{ mt: "2rem" }} />
        )}
        <Typography
          sx={{ fontSize: "14px", fontWeight: "bold", mb: "8px", textAlign: "left", width: "100%" }}
        >
          メールアドレス
        </Typography>
        <TextField
          id="email"
          name="email"
          type="email"
          variant="outlined"
          onChange={updateForm}
          value={email}
          sx={{ mb: "24px" }}
          placeholder="taro.yamada@gembahub.jp"
        />
        <PasswordInput
          label="パスワード"
          password={password}
          onChange={updateForm}
          width="auto"
          helperTextItems={[]}
        />

        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={isSubmitted}
          sx={{ width: "240px", borderRadius: "24px", mt: "24px", mx: "auto" }}
        >
          ログイン
        </Button>
      </form>
      <Button sx={{ mt: "24px", width: "100%" }} onClick={() => setPage("sendResetCode")}>
        パスワードを忘れた場合
      </Button>
    </>
  );
};

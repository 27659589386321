import React, { ElementRef, useMemo, useRef, useState } from "react";
import { Box, Divider, Typography } from "@mui/material";
import { PrimaryButton } from "components/atoms/primary-button";
import { ExpandToggleLabel } from "components/label/expand-toggle-label";
import { CustomFormLabel } from "components/molecules/custom-form-label";
import { WorkReportBlock } from "components/molecules/work-report-block";
import { WorkReportDetailModal } from "components/organisms/work-report/detail-modal";
import { AsyncConfirmDialog } from "components/templates/async-confirm-dialog";
import { ProjectId } from "data-access/repositories/project/project.dto";
import { projectWorkReportRepository } from "data-access/repositories/project/work_report/work_report.repository";
import {
  WorkReport,
  WorkReportId,
  WorkReportState,
} from "data-access/repositories/work_report/work_report.dto";
import { workReportRepository } from "data-access/repositories/work_report/work_report.repository";
import { theme } from "extensions/theme";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { mainOperations } from "store/main/operations";
import { selectMain } from "store/main/slice";
import useSWR, { useSWRConfig } from "swr";
import { API_PATHS } from "utils/apiPaths";
import { ProjectSidebarWorkReportInitialDisplayNumber } from "utils/constant";
import { handleReduxError } from "utils/errorHandler";
import { styles } from "./styles";
import { WorkReportCreateModal } from "../../../../../components/organisms/work-report/create-modal";

interface Props {
  isCreateNew: boolean;
  projectId?: ProjectId;
}

export const ProjectSidebarWorkReportContent = (props: Props) => {
  const mainState = useAppSelector(selectMain);
  const dispatch = useAppDispatch();

  const fetchIndexKey = props.projectId ? API_PATHS.getProjectWorkReports(props.projectId) : "";
  const { data, mutate } = useSWR(fetchIndexKey, () => {
    return projectWorkReportRepository.index(props.projectId as ProjectId);
  });
  const { mutate: IndexMutate } = useSWRConfig();

  const [expanded, setExpanded] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [workReportDetailModal, setWorkReportDetailModal] = useState<{
    isOpen: boolean;
    id: WorkReportId | undefined;
    ownerType: "own" | "partner" | undefined;
  }>({
    isOpen: false,
    id: undefined,
    ownerType: undefined,
  });

  const deleteConfirmRef = useRef<ElementRef<typeof AsyncConfirmDialog>>(null);
  const handleDelete = async (id: WorkReportId) => {
    if (!deleteConfirmRef.current) return;
    const res = await deleteConfirmRef.current.confirm();

    if (res) {
      try {
        await workReportRepository.destroy(id as WorkReportId);
        mutate();
        setWorkReportDetailModal({ isOpen: false, id: undefined, ownerType: undefined });
        dispatch(mainOperations.updateSuccessMessage("作業日報を削除しました"));
      } catch (error) {
        dispatch(mainOperations.updateErrorMessage(error.response.data.message));
      }
    }
  };

  const displayNumber = useMemo(() => {
    if (expanded) {
      const ownHub = data?.find((hub) => hub.id === mainState.hub.id);
      return ownHub?.workReports?.length || 0;
    }
    return ProjectSidebarWorkReportInitialDisplayNumber;
  }, [expanded]);

  const handleCreate = async (body: WorkReportState): Promise<WorkReport> => {
    try {
      const res: WorkReport = await workReportRepository.create({
        projectId: props.projectId,
        ...body,
      });
      IndexMutate(fetchIndexKey);
      dispatch(mainOperations.updateSuccessMessage("作業日報を作成しました"));
      return res;
    } catch (error) {
      handleReduxError(error, dispatch, "作業日報の作成に失敗しました");
      throw error;
    }
  };

  return (
    <>
      <AsyncConfirmDialog ref={deleteConfirmRef} />
      {workReportDetailModal.ownerType === "own" && (
        <WorkReportDetailModal
          show={workReportDetailModal}
          onClose={() =>
            setWorkReportDetailModal({ isOpen: false, id: undefined, ownerType: undefined })
          }
          onDelete={handleDelete}
          fetchIndexKey={fetchIndexKey}
          isPartner={false}
        />
      )}
      {props.projectId && workReportDetailModal.ownerType === "partner" && (
        <WorkReportDetailModal
          projectId={props.projectId}
          show={workReportDetailModal}
          onClose={() =>
            setWorkReportDetailModal({ isOpen: false, id: undefined, ownerType: undefined })
          }
          isPartner={true}
        />
      )}
      {props.projectId && (
        <WorkReportCreateModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          handleSubmit={handleCreate}
          projectId={props.projectId}
          fetchIndexKeys={[fetchIndexKey]}
        />
      )}

      <Box sx={{ display: "flex", justifyContent: "space-between", mb: "8px" }}>
        <CustomFormLabel labelName="作業日報" labelColor={theme.palette.grayScale[700]} />
        <PrimaryButton
          name="作業日報を作成"
          onClick={() => setIsOpen(true)}
          disabled={props.isCreateNew}
        />
      </Box>
      {data?.length === 0 && <Typography>作業日報はありません</Typography>}
      {data
        ?.filter((hub) => hub.id === mainState.hub.id)
        .map((hub) => (
          <React.Fragment key={hub.id}>
            {hub.workReports.slice(0, displayNumber).map((workReport) => (
              <WorkReportBlock
                key={workReport.id}
                workReport={workReport}
                ownerType="own"
                setShow={setWorkReportDetailModal}
              />
            ))}
            {(hub.workReports.length || 0) > ProjectSidebarWorkReportInitialDisplayNumber && (
              <ExpandToggleLabel onClick={() => setExpanded(!expanded)} expanded={expanded} />
            )}
          </React.Fragment>
        ))}
      {data
        ?.filter((hub) => hub.id !== mainState.hub.id)
        .map((hub) => (
          <React.Fragment key={hub.id}>
            {mainState.hub.id !== hub.id && hub.workReports.length > 0 && (
              <Divider textAlign="left" sx={{ ...styles.dividerTitle }}>
                <Typography fontWeight="bold" fontSize="12px" color="textDisabled">
                  {hub.name}が作成
                </Typography>
              </Divider>
            )}
            {hub.workReports.map((workReport) => (
              <WorkReportBlock
                key={workReport.id}
                workReport={workReport}
                ownerType="partner"
                setShow={setWorkReportDetailModal}
              />
            ))}
          </React.Fragment>
        ))}
    </>
  );
};

import { useEffect, useMemo, useState } from "react";
import {
  Account,
  InvitableAccountForSchedule,
  UserId,
} from "data-access/repositories/account/account.dto";
import { accountRepository } from "data-access/repositories/account/account.repository";
import { ScheduleId } from "data-access/repositories/schedule/schedule.dto";
import { useAppSelector } from "store/hooks";
import { selectMain } from "store/main/slice";
import useSWR from "swr";

interface Props {
  isModalOpen: boolean;
  startTime: string;
  endTime: string;
  initialUserIds?: UserId[];
  excludeScheduleId?: ScheduleId;
  onMembersChange: (userIds: UserId[]) => void;
  onCreateSchedule: () => Promise<void>;
  disableOtherHubSelection?: boolean;
}

interface UseScheduleMembersReturn {
  selectedAccounts: InvitableAccountForSchedule[];
  inputValue: string;
  allOptions: InvitableAccountForSchedule[];
  selectableSiteManagers: Account[];
  isLoading: boolean;
  resetMembers: () => void;
  handleChangeUsers: (
    _: React.SyntheticEvent<Element, Event>,
    value: InvitableAccountForSchedule[],
  ) => void;
  handleClear: (userId: UserId) => void;
  setInputValue: (value: string) => void;
  filterOptions: (options: InvitableAccountForSchedule[]) => InvitableAccountForSchedule[];
  groupByFunction: (option: InvitableAccountForSchedule) => string;
  hasOverlappedMembers: boolean;
  isOverlapConfirmModalOpen: boolean;
  handleCreateWithOverlapCheck: () => Promise<void>;
  handleCancelOverlapConfirm: () => void;
}

export const useScheduleMembers = ({
  initialUserIds = [],
  ...props
}: Props): UseScheduleMembersReturn => {
  const mainState = useAppSelector(selectMain);
  const [selectedAccounts, setSelectedAccounts] = useState<InvitableAccountForSchedule[]>([]);
  const [inputValue, setInputValue] = useState<string>("");
  const [isOverlapConfirmModalOpen, setIsOverlapConfirmModalOpen] = useState<boolean>(false);

  const {
    data: invitableAccounts,
    isLoading,
    mutate,
  } = useSWR(
    props.isModalOpen ? "/api/v1/accounts/invitable/for_schedule" : null,
    () =>
      accountRepository.invitableForSchedule({
        startTime: props.startTime || "",
        endTime: props.endTime || "",
        excludeScheduleId: props.excludeScheduleId,
      }),
    {
      revalidateOnFocus: false,
    },
  );

  useEffect(() => {
    if (!invitableAccounts || initialUserIds.length === 0) return;

    const initialUsers = invitableAccounts.filter((user) => initialUserIds.includes(user.userId));

    if (initialUsers.length > 0) {
      setSelectedAccounts(initialUsers);
    }
  }, [invitableAccounts, initialUserIds]);

  useEffect(() => {
    if (props.isModalOpen && props.startTime && props.endTime) {
      mutate();
    }
  }, [props.startTime, props.endTime, props.isModalOpen]);

  const filterOptions = (options: InvitableAccountForSchedule[]) => {
    // 選択済みのユーザーは表示しない
    const unselectedOptions = options.filter(
      (option) => !selectedAccounts.some((user) => user.userId === option.userId),
    );

    // 繰り返し設定時は他Hubのメンバーを除外
    let filteredOptions = unselectedOptions;
    if (props.disableOtherHubSelection) {
      filteredOptions = filteredOptions.filter((option) => option.hub.uuid === mainState.hub.uuid);
    }

    // 検索フィルターを適用
    filteredOptions = filteredOptions.filter(
      (option) =>
        option.name.toLowerCase().includes(inputValue.toLowerCase()) ||
        (option.group?.name?.toLowerCase() || "").includes(inputValue.toLowerCase()) ||
        (option.hub.name.toLowerCase() || "").includes(inputValue.toLowerCase()),
    );

    if (inputValue && filteredOptions.length === 0) {
      return [{ name: "__NO_RESULT__" } as InvitableAccountForSchedule];
    }

    return filteredOptions;
  };

  const groupByFunction = (option: InvitableAccountForSchedule) => {
    // 検索結果なしの場合
    if (option.name === "__NO_RESULT__") return "検索結果";

    const isOwnCompanyMember = option.hub && option.hub.uuid === mainState.hub.uuid;

    return isOwnCompanyMember ? "自社メンバー" : "コネクトしているHub";
  };

  const resetMembers = () => {
    setSelectedAccounts([]);
    setInputValue("");
    props.onMembersChange([]);
  };

  const handleChangeUsers = (
    _: React.SyntheticEvent<Element, Event>,
    value: InvitableAccountForSchedule[],
  ) => {
    const userIds = value.map((account: InvitableAccountForSchedule) => account.userId);
    setSelectedAccounts(value);
    props.onMembersChange(userIds);
  };

  const handleClear = (userId: UserId) => {
    const newUsers = selectedAccounts.filter((account) => account.userId !== userId);
    setSelectedAccounts(newUsers);
    props.onMembersChange(newUsers.map((user) => user.userId));
  };

  const selectableSiteManagers = useMemo(() => {
    return mainState.accounts.filter((account) => {
      return selectedAccounts.some((u) => u.userId === account.userId);
    });
  }, [mainState.accounts, selectedAccounts]);

  // メンバーの重複チェック
  const hasOverlappedMembers: boolean = useMemo(() => {
    if (!invitableAccounts) return false;
    return invitableAccounts.some(
      (account) =>
        selectedAccounts.some((selected) => selected.userId === account.userId) &&
        account.isScheduleOverlapped &&
        account.scheduleOverlappingType.value !== "can",
    );
  }, [invitableAccounts, selectedAccounts]);

  // 参加メンバーの重複チェック後に作成処理
  const handleCreateWithOverlapCheck = async () => {
    if (hasOverlappedMembers) {
      setIsOverlapConfirmModalOpen(true);
      return;
    }
    await props.onCreateSchedule();
  };

  const handleCancelOverlapConfirm = () => {
    setIsOverlapConfirmModalOpen(false);
  };

  return {
    selectedAccounts,
    inputValue,
    allOptions: invitableAccounts || [],
    selectableSiteManagers,
    isLoading,
    resetMembers,
    handleChangeUsers,
    handleClear,
    setInputValue,
    filterOptions,
    groupByFunction,
    hasOverlappedMembers,
    isOverlapConfirmModalOpen,
    handleCreateWithOverlapCheck,
    handleCancelOverlapConfirm,
  };
};

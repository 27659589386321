import { HubSetting, HubSettingUpdateRequest } from "./hub_setting.dto";
import { ApiClient } from "../../ApiClient";

export const hubSettingRepository = {
  index(): Promise<HubSetting> {
    return ApiClient.get("/api/v1/hub_setting").then((res) => {
      return res.data;
    });
  },
  update(body: HubSettingUpdateRequest): Promise<void> {
    const config = { headers: { "content-type": "application/json" } };
    return ApiClient.put("/api/v1/hub_setting", requestBody(body), config).then((res) => res.data);
  },
};
const requestBody = (body: HubSettingUpdateRequest) => {
  return {
    schedule_note_template: body.scheduleNoteTemplate,
    work_report_content_template: body.workReportContentTemplate,
    project_note_template: body.projectNoteTemplate,
  };
};

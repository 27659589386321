import { ApiClient } from "data-access/ApiClient";
import { ConnectRequest } from "features/hub-settings/types/connect/connect_request.dto";
import { convertKeysToSnakeCase } from "utils/convertObjectKeyCase";

export const connectRequestRepository = {
  create(body: ConnectRequest): Promise<void> {
    return ApiClient.post("/api/v1/connect_requests", convertKeysToSnakeCase(body)).then(
      (res) => res.data,
    );
  },
};

import { ApiClient } from "data-access/ApiClient";
import { RelatedProject } from "data-access/repositories/project/project.dto";
import { convertKeysToCamelCase } from "utils/convertObjectKeyCase";
import { BuildingId } from "../building.dto";

export const buildingProjectRepository = {
  index(buildingId: BuildingId): Promise<RelatedProject[]> {
    return ApiClient.get(`/api/v1/buildings/${buildingId}/projects`).then((res) =>
      convertKeysToCamelCase(res.data),
    );
  },
};

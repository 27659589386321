import { HelpOutlined } from "@mui/icons-material";
import { Box, Tooltip, Typography } from "@mui/material";
import { theme } from "extensions/theme";

interface Props {
  labelName: string;
  toolTipTitle?: string;
  small?: boolean;
  labelSize?: string;
  labelColor?: string;
  labelWeight?: "bold" | 400;
  required?: boolean;
  requiredSize?: string;
  sx?: object;
}

export const CustomFormLabel = ({ small = false, required = false, ...props }: Props) => {
  return (
    <Box sx={{ mb: "0.5rem", display: "flex", alignItems: "center", ...props.sx }}>
      <Typography
        component="span"
        variant={small ? "body2" : "body1"}
        sx={{
          color: props.labelColor ? props.labelColor : theme.palette.grayScale[900],
          fontSize: props.labelSize ? props.labelSize : "16px",
        }}
        fontWeight={props.labelWeight ? props.labelWeight : "bold"}
      >
        {props.labelName}
      </Typography>
      {required && (
        <Typography
          component="span"
          sx={{
            ml: "12px",
            color: theme.palette.error.main,
            fontSize: props.requiredSize ? props.requiredSize : "16px",
            fontWeight: 400,
          }}
        >
          必須
        </Typography>
      )}
      {props.toolTipTitle && (
        <Tooltip title={props.toolTipTitle} placement="right">
          <HelpOutlined sx={{ ml: "1rem", verticalAlign: "middle" }} color="primary" />
        </Tooltip>
      )}
    </Box>
  );
};

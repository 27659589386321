import { useEffect, useState } from "react";
import { Autocomplete, Button, Checkbox, TextField } from "@mui/material";
import { UserId } from "data-access/repositories/account/account.dto";
import { ScheduleId } from "data-access/repositories/schedule/schedule.dto";
import { scheduleRepository } from "data-access/repositories/schedule/schedule.repository";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { mainOperations } from "store/main/operations";
import { selectMain } from "store/main/slice";
import { mutate } from "swr";
import { styles } from "./styles";
import { CustomModal } from "../custom-modal";

interface Props {
  isOpen: boolean;
  onClose: (
    isOpen: false,
    scheduleId: ScheduleId,
    assignments: { userId: UserId; name: string }[],
  ) => void;
  scheduleId: ScheduleId;
  assignments: { userId: UserId; name: string }[];
  fetchIndexKey: string;
}

export const SelectAssignmentsModal = (props: Props) => {
  const { isOpen, onClose, scheduleId, assignments, fetchIndexKey } = props;
  const classes = styles();
  const dispatch = useAppDispatch();
  const mainState = useAppSelector(selectMain);
  const [formState, setFormState] = useState<Array<{ userId: UserId; name: string }>>([]);
  const activeUsers = mainState.accounts.filter((account) => !account.isDeactivate);

  useEffect(() => {
    if (isOpen) {
      setFormState(assignments);
    }
  }, [isOpen]);

  const handleChange = (
    _: React.SyntheticEvent<Element, Event>,
    newValue: { userId: UserId; name: string }[],
  ) => {
    setFormState(newValue.map((user) => ({ userId: user.userId, name: user.name })));
  };

  const handleSubmit = async () => {
    try {
      const userIds = formState.map((user) => user.userId);
      await scheduleRepository.update(scheduleId, false, { userIds });
      mutate(fetchIndexKey);
      dispatch(mainOperations.updateSuccessMessage("予定の参加者を変更しました"));
    } catch {
      dispatch(mainOperations.updateErrorMessage("予定の参加者を変更できませんでした"));
    } finally {
      handleClose();
    }
  };

  const handleClose = () => {
    onClose(false, 0 as ScheduleId, []);
    setFormState([]);
  };

  return (
    <CustomModal
      title="参加者を編集"
      open={isOpen}
      onClose={handleClose}
      maxWidth="xs"
      footer={
        <>
          <Button variant="outlined" onClick={handleClose} sx={{ width: "160px" }}>
            キャンセル
          </Button>
          <Button onClick={handleSubmit} variant="contained" sx={{ width: "160px", ml: "16px" }}>
            変更
          </Button>
        </>
      }
    >
      <Autocomplete
        multiple
        options={activeUsers.map((user) => ({ userId: user.userId, name: user.name })) || []}
        value={formState || []}
        onChange={handleChange}
        disableCloseOnSelect
        disableClearable
        fullWidth
        getOptionLabel={(option) => option.name}
        isOptionEqualToValue={(option, value) => option.userId === value.userId}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox style={{ marginRight: 8 }} checked={selected} />
            {option.name}
          </li>
        )}
        renderInput={(params) => <TextField {...params} label="参加者を選択" variant="standard" />}
        className={classes.memberAutocomplete}
      />
    </CustomModal>
  );
};

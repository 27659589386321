import { theme } from "extensions/theme";

export const styles = {
  title: {
    fontSize: "16px",
    fontWeight: "bold",
    color: theme.palette.grayScale[900],
    position: "relative",
    p: "16px 24px",
  },
  closeButton: {
    position: "absolute",
    right: "16px",
    top: "8px",
    color: theme.palette.grayScale[700],
  },
  header: {
    position: "sticky",
    top: 0,
    backgroundColor: theme.palette.grayScale[0],
    zIndex: 9,
    pt: "16px",
  },
  siteName: {
    fontSize: "20px",
    fontWeight: "bold",
    wordBreak: "break-all",
    flex: 1,
    mr: "20px",
  },
  link: {
    display: "flex",
    alignItems: "center",
    fontWeight: "500",
    fontSize: "14px",
  },
  box: {
    lineHeight: "14px",
    letterSpacing: "0.2px",
    fontWeight: "500",
    padding: "1rem 0",
    mt: "24px",
  },
  selectAllButton: {
    fontSize: "14px",
    height: "100%",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
  },
  deselectButton: {
    fontSize: "14px",
    height: "100%",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
  },
  checkbox: {
    position: "absolute" as const,
    top: "8px",
    left: "8px",
    width: "20px",
    height: "20px",
    cursor: "pointer",
    accentColor: theme.palette.primary.main,
  },
};

interface ClientInfo {
  name: string;
  billingPersonName: string;
  honorificTitle: string;
  billingPersonSection: string;
  billingPersonPosition: string;
}
export const mailingName = (client: ClientInfo): string => {
  let here = `${client.name} ${
    client.billingPersonName === "" ? changeHonorificTitle(client.honorificTitle) : ""
  }`;
  if (client.billingPersonSection !== "") {
    here += `\n${client.billingPersonSection}`;
  }
  if (client.billingPersonPosition !== "") {
    here += `\n${client.billingPersonPosition}`;
  }
  if (client.billingPersonName !== "") {
    here += `\n${client.billingPersonName} 様`;
  }
  return here;
};

const changeHonorificTitle = (honorificTitle: string) => {
  switch (honorificTitle) {
    case "様":
    case "御中":
    case "なし":
      return honorificTitle;
    case "to_person":
      return "様";
    case "to_group":
      return "御中";
    case "nothing":
      return "";
  }
};

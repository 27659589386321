import { useState } from "react";
import { Close } from "@mui/icons-material";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { styles } from "./styles";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  nonFreeUsersCount: number;
}

export const AdditionalChargeDialog = (props: Props) => {
  const [isChecked, setIsChecked] = useState<boolean>(false);

  const handleClose = () => {
    setIsChecked(false);
    props.onClose();
  };

  return (
    <Dialog open={props.isOpen} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle sx={{ height: "64px" }}>
        <IconButton onClick={handleClose} sx={styles.closeButton}>
          <Close />
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ textAlign: "center" }}>
        <Typography sx={{ fontWeight: "bold", fontSize: "18px", mb: "16px" }}>
          追加料金が発生します
        </Typography>
        <Typography sx={{ mb: "16px" }}>
          新たに{props.nonFreeUsersCount}件の有料アカウントを追加しようとしています。
          <br />
          「確定」ボタンを押すと新たに課金されます。招待しますか？
        </Typography>

        <Typography color="textSecondary" sx={{ fontSize: "12px", mb: "16px" }}>
          招待されたメンバーが利用開始した日を起点に日割り計算し、 メールで請求書を発行致します。
        </Typography>

        <FormControlLabel
          control={
            <Checkbox checked={isChecked} onChange={(e) => setIsChecked(e.target.checked)} />
          }
          label="確認しました"
          sx={{ mb: "16px" }}
        />

        <Box sx={{ display: "flex", justifyContent: "center", gap: "16px" }}>
          <Button variant="outlined" onClick={handleClose} sx={{ width: "160px" }}>
            キャンセル
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={props.onConfirm}
            disabled={!isChecked}
            sx={{ width: "160px" }}
          >
            確定
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

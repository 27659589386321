export const styles = {
  container: {
    bgcolor: "customPrimary.50",
    borderRadius: "4px",
    border: "1px solid",
    borderColor: "grayScale.300",
    width: "100%",
    px: "12px",
    pt: "12px",
  },

  statusIconContainer: {
    position: "absolute",
    bottom: -2,
    right: -2,
    backgroundColor: "white",
    borderRadius: "50%",
    width: 16,
    height: 16,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  hubHeader: {
    display: "flex",
    alignItems: "center",
    mb: 1,
    gap: "4px",
  },

  participantsContainer: {
    ml: 3,
    mb: 1,
  },

  siteManagerItem: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    mb: "8px",
    whiteSpace: "nowrap",
  },

  participantsGrid: {
    display: "flex",
    flexWrap: "wrap",
    columnGap: "16px",
    rowGap: "8px",
  },

  participantItem: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
  },

  divider: {
    my: "16px",
  },

  avatarContainer: {
    position: "relative",
  },
};

import { ElementRef, useCallback, useMemo, useRef } from "react";
import { Tooltip, Select, MenuItem, SelectChangeEvent } from "@mui/material";
import {
  GridRowParams,
  GridActionsCellItem,
  GridRenderCellParams,
  GridRowModel,
} from "@mui/x-data-grid";
import { GridRowOrderChangeParams } from "@mui/x-data-grid-pro";
import { DeleteIcon } from "components/icon/delete-icon";
import { AsyncConfirmDialog } from "components/templates/async-confirm-dialog";
import { CustomizedDataGridPro } from "components/templates/customized-data-grid-pro";
import {
  AttachmentType,
  AttachmentTypeId,
} from "data-access/repositories/attachment_type/attachment_type.dto";
import { attachmentTypeRepository } from "data-access/repositories/attachment_type/attachment_type.repository";
import { theme } from "extensions/theme";
import { useAppDispatch } from "store/hooks";
import { mainOperations } from "store/main/operations";
import useSWR from "swr";

type SharingOption = "OWN_HUB" | "OTHER_HUBS" | "ALL";

const SHARING_OPTIONS = {
  OWN_HUB: "OWN_HUB" as const, // 自社の全員に共有
  OTHER_HUBS: "OTHER_HUBS" as const, // すべてのHubに共有
  ALL: "ALL" as const, // 自社とすべてのHubに共有
};

export const AttachmentTypeTable = () => {
  const dispatch = useAppDispatch();

  const {
    data: attachmentTypes,
    isValidating,
    mutate,
  } = useSWR("/api/v1/attachment_types", () => attachmentTypeRepository.index());

  const getSharingOption = (
    isSharedInOwnHub: boolean,
    isSharedWithOtherHubs: boolean,
  ): SharingOption => {
    if (isSharedInOwnHub && isSharedWithOtherHubs) {
      return SHARING_OPTIONS.ALL;
    } else if (isSharedInOwnHub) {
      return SHARING_OPTIONS.OWN_HUB;
    } else if (isSharedWithOtherHubs) {
      return SHARING_OPTIONS.OTHER_HUBS;
    }
    return SHARING_OPTIONS.OWN_HUB;
  };

  const columns = useMemo(() => {
    const headers = [];
    headers.push({
      field: "name",
      headerName: "名前",
      minWidth: 200,
      sortable: false,
      disableColumnMenu: true,
      editable: true,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Tooltip title="クリックで名前を編集できます" placement="top-start">
            <span>{params.value}</span>
          </Tooltip>
        );
      },
    });
    headers.push({
      field: "sharing",
      headerName: "デフォルトの共有範囲",
      minWidth: 240,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams) => {
        const sharingOption = getSharingOption(
          params.row.isSharedInOwnHub || false,
          params.row.isSharedWithOtherHubs || false,
        );

        return (
          <Select
            value={sharingOption}
            onChange={(e) => handleSharingOptionChange(e, params.row.id)}
            variant="standard"
            sx={{
              width: "100%",
              "&.MuiInput-underline:before": {
                borderBottom: "none",
              },
            }}
          >
            <MenuItem value={SHARING_OPTIONS.OWN_HUB}>自社の全員に共有</MenuItem>
            <MenuItem value={SHARING_OPTIONS.OTHER_HUBS}>すべてのHubに共有</MenuItem>
            <MenuItem value={SHARING_OPTIONS.ALL}>自社とすべてのHubに共有</MenuItem>
          </Select>
        );
      },
    });
    headers.push({
      field: "actions",
      type: "actions" as const,
      width: 80,
      getActions: (params: GridRowParams) => [
        <GridActionsCellItem
          key={params.row.id}
          icon={<DeleteIcon color={theme.palette.grayScale[700]} size={20} />}
          label="Delete"
          onClick={() => handleDelete(params.row.id as AttachmentTypeId)}
        />,
      ],
    });
    return headers;
  }, []);

  const simpleConfirmRef = useRef<ElementRef<typeof AsyncConfirmDialog>>(null);
  const handleDelete = async (id: AttachmentTypeId) => {
    if (!simpleConfirmRef.current) return;
    const res = await simpleConfirmRef.current.confirm();

    if (res) {
      try {
        await attachmentTypeRepository.destroy(id as AttachmentTypeId);
        attachmentTypes && mutate();
        dispatch(mainOperations.updateSuccessMessage("添付ファイルタイプを削除しました"));
      } catch (error) {
        dispatch(mainOperations.updateErrorMessage(error.response.data.message));
      }
    }
  };

  const handleSharingOptionChange = async (
    e: SelectChangeEvent<SharingOption>,
    id: AttachmentTypeId,
  ) => {
    const option = e.target.value as SharingOption;
    let isSharedInOwnHub = false;
    let isSharedWithOtherHubs = false;

    switch (option) {
      case SHARING_OPTIONS.OWN_HUB:
        isSharedInOwnHub = true;
        isSharedWithOtherHubs = false;
        break;
      case SHARING_OPTIONS.OTHER_HUBS:
        isSharedInOwnHub = false;
        isSharedWithOtherHubs = true;
        break;
      case SHARING_OPTIONS.ALL:
        isSharedInOwnHub = true;
        isSharedWithOtherHubs = true;
        break;
    }

    try {
      await attachmentTypeRepository.update(id, {
        isSharedInOwnHub,
        isSharedWithOtherHubs,
      });
      attachmentTypes && mutate();
      dispatch(mainOperations.updateSuccessMessage("共有範囲を変更しました"));
    } catch (error) {
      dispatch(mainOperations.updateErrorMessage(error.response.data.message));
    }
  };

  const handleRowOrderChange = async (params: GridRowOrderChangeParams) => {
    try {
      await attachmentTypeRepository.update(params.row.id, {
        displayOrderPosition: params.targetIndex,
      });
      attachmentTypeRepository && mutate();
      dispatch(mainOperations.updateSuccessMessage("予定タイプの順番を変更しました"));
    } catch (error) {
      dispatch(mainOperations.updateErrorMessage(error.response.data.message));
    }
  };

  const handleEditName = useCallback(async (params: GridRowModel) => {
    const { id, name } = params;
    try {
      await attachmentTypeRepository.update(id, {
        name,
      });
      attachmentTypes &&
        mutate(
          attachmentTypes.map((attachmentType: AttachmentType) => {
            if (attachmentType.id === id) {
              attachmentType.name = name;
            }
            return attachmentType;
          }),
        );
      dispatch(mainOperations.updateSuccessMessage("添付ファイルタイプ名を変更しました"));
    } catch (error) {
      dispatch(mainOperations.updateErrorMessage(error.response.data.message));
    }
    return params;
  }, []);

  return (
    <>
      <AsyncConfirmDialog ref={simpleConfirmRef} />
      <CustomizedDataGridPro
        columnHeaderHeight={40}
        columns={columns}
        rows={attachmentTypes || []}
        loading={isValidating}
        hideFooter
        rowReordering
        disableRowSelectionOnClick
        onRowOrderChange={handleRowOrderChange}
        processRowUpdate={handleEditName}
      />
    </>
  );
};

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Alert, Box, LinearProgress, Typography } from "@mui/material";
import { theme } from "extensions/theme";
import { CsvImportStatus } from "features/hub-settings/types/csv-import/csv_import_project.dto";

interface Props {
  totalCount: number;
  createdCount: number;
  estimatedTimeRemainingSeconds: number;
  status: CsvImportStatus;
  moduleName: "project" | "schedule";
}
export const CsvImportAlert = (props: Props) => {
  const moduleNameJa = props.moduleName === "project" ? "案件" : "スケジュール";

  return (
    <Alert
      icon={<InfoOutlinedIcon sx={{ fontSize: "20px" }} />}
      sx={{
        color: theme.palette.blue[500],
        bgcolor: theme.palette.blue[200],
        mb: "24px",
        "& .MuiAlert-icon": {
          color: theme.palette.blue[500],
        },
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
        {props.status === "preparing_validation" || props.status === "prepared_validation" ? (
          <Typography>{moduleNameJa}数を計算中です。リロードして確認してください。</Typography>
        ) : (
          <>
            <Typography>
              現在、{moduleNameJa}をインポート中です。
              {props.moduleName === "project" && (
                <>インポート完了までは、案件の新規作成ができません。</>
              )}
            </Typography>
            <LinearProgress
              sx={{ width: "280px" }}
              variant="determinate"
              value={(props.createdCount / props.totalCount) * 100}
            />
            {props.createdCount}/{props.totalCount}
          </>
        )}
      </Box>
    </Alert>
  );
};

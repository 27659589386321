import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Box, SelectChangeEvent, Typography } from "@mui/material";
import { GridRenderCellParams, GridRowParams, GridSortModel } from "@mui/x-data-grid";
import { CustomDataGrid } from "components/molecules/custom-data-grid";
import { BuildingIndexResponse } from "data-access/repositories/building/building.dto";
import { initialPageInfo } from "data-access/repositories/page_info/page_info.dto";
import { DATE_TIME_SLASH_FORMAT, formatDateUtil } from "utils/formatDateUtil";

interface Props {
  data: BuildingIndexResponse | undefined;
  page: number;
  setPage: (e: number) => void;
  isLoading: boolean;
  handleSortModelChange?: (sortItems: GridSortModel) => void;
}

export const BuildingTable = (props: Props) => {
  const navigate = useNavigate();

  // ページャーselect選択
  const handleSelect = (event: SelectChangeEvent<unknown>) => {
    props.setPage(Number(event.target.value));
  };

  // ページャー選択(next, prev)
  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    props.setPage(newPage + 1);
  };

  const handleRowClick = (params: GridRowParams) => {
    navigate(`/buildings/${params.row.id}`);
  };

  const columns = useMemo(() => {
    const headers = [];
    headers.push({
      field: "name",
      headerName: "物件名",
      minWidth: 240,
      sortable: true,
    });
    headers.push({
      field: "postalCode",
      headerName: "郵便番号",
      minWidth: 120,
      sortable: true,
    });
    headers.push({
      field: "address",
      headerName: "住所（番地まで）",
      minWidth: 450,
      sortable: true,
    });
    headers.push({
      field: "client_name",
      headerName: "デフォルトの顧客",
      sortable: true,
      minWidth: 240,
      renderCell: (params: GridRenderCellParams) => (
        <Typography sx={{ fontSize: "14px" }}>{params.row?.client?.name}</Typography>
      ),
    });
    headers.push({
      field: "updatedAt",
      headerName: "最終更新",
      minWidth: 150,
      sortable: true,
      renderCell: (params: GridRenderCellParams) => (
        <Typography sx={{ fontSize: "14px" }}>
          {formatDateUtil(params.value, DATE_TIME_SLASH_FORMAT)}
        </Typography>
      ),
    });
    return headers;
  }, []);

  return (
    <>
      <Box sx={{ mt: 2 }}>
        <CustomDataGrid
          columns={columns}
          rows={props.data?.data || []}
          loading={props.isLoading}
          pageInfo={props.data?.pagination || initialPageInfo}
          currentPage={props.page}
          handleSelect={handleSelect}
          handleChangePage={handleChangePage}
          handleSortModelChange={props.handleSortModelChange}
          handleRowClick={handleRowClick}
          tableHeight="80vh"
        />
      </Box>
    </>
  );
};

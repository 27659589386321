import { AuthClient } from "data-access/AuthClient";
import { convertKeysToCamelCase, convertKeysToSnakeCase } from "utils/convertObjectKeyCase";
import {
  SignUpAccountRequest,
  SignUpHubRequest,
  SignUpResponse,
  SignUpVerifyResponse,
} from "./sign_up.dto";

export const signUpRepository = {
  get(token: string): Promise<SignUpVerifyResponse> {
    return AuthClient.get(`/api/v1/signup/verify?signup_token=${token}`).then((res) =>
      convertKeysToCamelCase(res.data),
    );
  },
  createAccount(body: SignUpAccountRequest): Promise<SignUpResponse> {
    return AuthClient.post("/api/v1/signup/account", convertKeysToSnakeCase(body)).then((res) =>
      convertKeysToCamelCase(res.data),
    );
  },
  createHub(body: SignUpHubRequest): Promise<SignUpResponse> {
    return AuthClient.post("/api/v1/signup/hub", convertKeysToSnakeCase(body)).then((res) =>
      convertKeysToCamelCase(res.data),
    );
  },
  validateAccount(body: SignUpAccountRequest): Promise<void> {
    return AuthClient.post("/api/v1/signup/validate_account", convertKeysToSnakeCase(body)).then(
      (res) => convertKeysToCamelCase(res.data),
    );
  },
};

import { Brand } from "data-access/repositories/brand";
import { PageInfo } from "data-access/repositories/page_info/page_info.dto";

export type CsvImportProjectId = Brand<number, "CsvImportProjectId">;

export type CsvImportProjectImportKey = "code" | "externalId";

export interface CsvImportProjectIndexResponse {
  data: CsvImportProject[];
  pagination: PageInfo;
}

export interface CsvImportProject {
  id: CsvImportProjectId;
  filename: string;
  importKey: CsvImportProjectImportKey;
  columnMap: ColumnMap;
  status: CsvImportStatus;
  estimatedTimeRemainingSeconds: number;
  totalCount: number;
  createdCount: number;
  updatedCount: number;
  failedCount: number;
  importedUserName: string;
  createdAt: string;
}

export interface ColumnMap {
  code: string;
  externalId: string;
  salesAmount: string;
  projectStatusTypeName: string;
  projectTypeName: string;
  clientName: string;
  inquiryNumber: string;
  buildingName: string;
  inquiredByName: string;
  managerName: string;
  address: string;
  addressSecond: string;
  billingPrecaution: string;
  completedDate: string;
  email: string;
  expectedCompleteDate: string;
  faxNumber: string;
  inquiredDate: string;
  name: string;
  note: string;
  orderedDate: string;
  personName: string;
  phoneNumber: string;
  phoneNumberSecond: string;
  postalCode: string;
}

export interface CsvImportProjectAnalyzeRequest {
  objectKey: string;
  importKey: CsvImportProjectImportKey;
  importKeyTitle: string;
}

export interface CsvImportProjectAnalyzeResponse {
  createCount: number;
  updateCount: number;
  estimatedTimeSeconds: number;
}

export interface CsvImportProjectRequest {
  objectKey: string;
  importKey: CsvImportProjectImportKey;
  filename: string;
  columnMap: {
    code: string;
    externalId: string;
    salesAmount: string;
    projectStatusTypeName: string;
    projectTypeName: string;
    clientName: string;
    inquiryNumber: string;
    buildingName: string;
    inquiredByName: string;
    managerName: string;
    address: string;
    addressSecond: string;
    billingPrecaution: string;
    completedDate: string;
    email: string;
    expectedCompleteDate: string;
    faxNumber: string;
    inquiredDate: string;
    name: string;
    note: string;
    orderedDate: string;
    personName: string;
    phoneNumber: string;
    phoneNumberSecond: string;
    postalCode: string;
  };
}

export const initialImportCsvProjectRequest: CsvImportProjectRequest = {
  objectKey: "",
  importKey: "code",
  filename: "",
  columnMap: {
    code: "",
    externalId: "",
    salesAmount: "",
    projectStatusTypeName: "",
    projectTypeName: "",
    clientName: "",
    inquiryNumber: "",
    buildingName: "",
    inquiredByName: "",
    managerName: "",
    address: "",
    addressSecond: "",
    billingPrecaution: "",
    completedDate: "",
    email: "",
    expectedCompleteDate: "",
    faxNumber: "",
    inquiredDate: "",
    name: "",
    note: "",
    orderedDate: "",
    personName: "",
    phoneNumber: "",
    phoneNumberSecond: "",
    postalCode: "",
  },
};

export type CsvImportStatus =
  | "canceled_by_system"
  | "canceled_by_user"
  | "failed"
  | "finalized_validation"
  | "finalizing_validation"
  | "importing"
  | "pending"
  | "prepared_import"
  | "prepared_validation"
  | "preparing_import"
  | "preparing_validation"
  | "succeeded"
  | "validated"
  | "validating";

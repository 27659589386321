import { theme } from "extensions/theme";

export const styles = {
  downloadButton: {
    width: "160px",
    display: "flex",
    alignItems: "center",
    gap: "8px",
    p: 0,
    bgcolor: theme.palette.grayScale[900],
  },
};

import { ApiClient } from "data-access/ApiClient";
import {
  ConnectRequesting,
  ConnectRequestingCollection,
  ConnectRequestingId,
} from "features/hub-settings/types/connect/connect_requesting.dto";
import { convertKeysToCamelCase } from "utils/convertObjectKeyCase";

export const connectRequestingRepository = {
  index(): Promise<ConnectRequestingCollection[]> {
    return ApiClient.get("/api/v1/connect_requests/requesting").then((res) => {
      return convertKeysToCamelCase(res.data);
    });
  },
  show(id: ConnectRequestingId): Promise<ConnectRequesting> {
    return ApiClient.get(`/api/v1/connect_requests/requesting/${id}`).then((res) => {
      return convertKeysToCamelCase(res.data);
    });
  },
  resend(id: ConnectRequestingId): Promise<void> {
    return ApiClient.post(`/api/v1/connect_requests/requesting/${id}/resend`).then(
      (res) => res.data,
    );
  },
  cancel(id: ConnectRequestingId): Promise<void> {
    return ApiClient.put(`/api/v1/connect_requests/requesting/${id}/cancel`).then(
      (res) => res.data,
    );
  },
};

import { useEffect, useState } from "react";
import { Edit } from "@mui/icons-material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Box, Button, IconButton, Typography } from "@mui/material";
import { AmountTextField } from "components/atoms/amount-text-field";
import { CustomFormLabel } from "components/molecules/custom-form-label";
import { TaxChangeDialog } from "components/molecules/tax-change-dialog";
import {
  ProjectFormSalesState,
  ProjectFormUpdateRequest,
  ProjectId,
} from "data-access/repositories/project/project.dto";
import { projectRepository } from "data-access/repositories/project/project.repository";
import { theme } from "extensions/theme";
import { useAppDispatch } from "store/hooks";
import { mainOperations } from "store/main/operations";
import { mutate } from "swr";
import { API_PATHS } from "utils/apiPaths";
import { calculateGrossProfitRate } from "utils/calculateGrossProfit";
import { handleReduxError } from "utils/errorHandler";
import { halfWidthNumber } from "utils/halfWidthNumber";
import { styles } from "./styles";
import { CostBreakdownModal } from "../../../../cost/components/breakdown-modal";

interface Props {
  projectId?: ProjectId;
  isCreateNew: boolean;
  salesState: ProjectFormSalesState;
  setSalesState: React.Dispatch<React.SetStateAction<ProjectFormSalesState>>;
  lastSavedFormValue: React.MutableRefObject<ProjectFormUpdateRequest>;
  decidedEstimatesTotalAmount: number;
  onCreateProject: (targetName: string, value: any) => void;
  currentPage: number;
}

export const ProjectSidebarSalesContent = (props: Props) => {
  const dispatch = useAppDispatch();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [isTaxDisplayed, setIsTaxDisplayed] = useState<boolean>(true);
  const [grossProfitAmount, setGrossProfitAmount] = useState<number>(0);
  const [isCostModalOpen, setIsCostModalOpen] = useState<boolean>(false);

  useEffect(() => {
    setGrossProfitAmount(props.salesState.salesAmount - props.salesState.totalCostAmount);
  }, [props.salesState.salesAmount, props.salesState.totalCostAmount]);

  const handleClick = async () => {
    if (!props.projectId) return;
    dispatch(mainOperations.updateIsLoading(true));
    try {
      const res = await projectRepository.update(props.projectId, {
        salesAmount: props.decidedEstimatesTotalAmount,
      });
      props.setSalesState((prev) => ({
        ...prev,
        salesAmount: res.salesAmount,
        tax: res.salesTax,
      }));
      props.lastSavedFormValue.current = {
        ...props.lastSavedFormValue.current,
        salesAmount: res.salesAmount,
        tax: res.salesTax,
      };
      dispatch(mainOperations.updateSuccessMessage("売上金額を決定見積の金額に変更しました"));
      mutate([API_PATHS.getProjects(), props.currentPage]);
      mutate(API_PATHS.getProject(props.projectId), res);
    } catch (error) {
      handleReduxError(error, dispatch, "売上金額の変更に失敗しました");
      props.setSalesState((prev) => ({
        ...prev,
        salesAmount: props.lastSavedFormValue.current.salesAmount,
      }));
    } finally {
      dispatch(mainOperations.updateIsLoading(false));
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setIsTaxDisplayed(false);
    props.setSalesState((prev) => ({
      ...prev,
      salesAmount: isNaN(Number(halfWidthNumber(String(e.target.value))))
        ? 0
        : Number(halfWidthNumber(String(e.target.value))),
    }));
  };

  const handleChangeSalesTax = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    props.setSalesState((prev) => ({
      ...prev,
      tax: isNaN(Number(halfWidthNumber(String(e.target.value))))
        ? 0
        : Number(halfWidthNumber(String(e.target.value))),
    }));
  };

  const handleBlurAmount = async (_: React.FocusEvent<HTMLInputElement>, amount: number) => {
    if (!props.projectId) return;
    if (props.lastSavedFormValue.current.salesAmount === amount) return;
    setIsTaxDisplayed(true);
    dispatch(mainOperations.updateIsLoading(true));

    try {
      const res = await projectRepository.update(props.projectId, {
        salesAmount: amount,
      });
      dispatch(mainOperations.updateSuccessMessage("売上金額を変更しました"));
      props.setSalesState((prev) => ({
        ...prev,
        salesAmount: amount,
        tax: res.salesTax,
      }));
      props.lastSavedFormValue.current = {
        ...props.lastSavedFormValue.current,
        salesAmount: amount,
        tax: res.salesTax,
      };
      mutate([API_PATHS.getProjects(), props.currentPage]);
      mutate(API_PATHS.getProject(props.projectId), res);
    } catch (error) {
      handleReduxError(error, dispatch, "売上金額の変更に失敗しました");
      props.setSalesState((prev) => ({
        ...prev,
        salesAmount: props.lastSavedFormValue.current.salesAmount,
      }));
    } finally {
      dispatch(mainOperations.updateIsLoading(false));
    }
  };

  const handleBlurTax = async (e: React.FocusEvent<HTMLInputElement>, tax: number) => {
    if (!props.projectId) return;
    if (props.lastSavedFormValue.current.tax === tax) return;

    dispatch(mainOperations.updateIsLoading(true));
    try {
      const res = await projectRepository.update(props.projectId, {
        tax,
      });
      dispatch(mainOperations.updateSuccessMessage("消費税を変更しました"));
      props.lastSavedFormValue.current = {
        ...props.lastSavedFormValue.current,
        tax,
      };
      mutate([API_PATHS.getProjects(), props.currentPage]);
      mutate(API_PATHS.getProject(props.projectId), res);
    } catch (error) {
      handleReduxError(error, dispatch, "消費税の変更に失敗しました");
      props.setSalesState((prev) => ({
        ...prev,
        tax: props.lastSavedFormValue.current.tax,
      }));
    } finally {
      dispatch(mainOperations.updateIsLoading(false));
    }
  };

  return (
    <>
      <Box sx={{ my: "24px" }}>
        <CustomFormLabel
          labelName="売上情報"
          labelSize="14px"
          labelColor={theme.palette.grayScale[700]}
        />
        <div style={{ display: "flex", gap: "16px" }}>
          <div style={{ width: "33%" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                height: "36px",
              }}
            >
              <Typography sx={{ ...styles.typography }}>売上</Typography>
              <Button
                onClick={handleClick}
                size="small"
                sx={{ pl: "12px" }}
                disabled={props.decidedEstimatesTotalAmount === 0}
              >
                決定見積の金額を反映
              </Button>
            </div>
            <AmountTextField
              name="salesAmount"
              width="100%"
              value={props.salesState.salesAmount || 0}
              onChange={handleChange}
              blurFunc={(e, value) => {
                if (props.isCreateNew) {
                  setIsTaxDisplayed(true);
                  props.onCreateProject("salesAmount", value);
                } else {
                  handleBlurAmount(e, value);
                }
              }}
            />
            {!!props.salesState.salesAmount && (
              <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
                <p style={{ fontSize: "11px" }}>
                  税込: ¥
                  {isTaxDisplayed &&
                    ` ${(props.salesState.salesAmount + props.salesState.tax).toLocaleString()}`}
                </p>
                <IconButton
                  tabIndex={-1}
                  onClick={(e) => {
                    setAnchorEl(e.currentTarget);
                    setIsOpen(true);
                  }}
                >
                  <Edit color="primary" sx={{ width: "16px" }} />
                </IconButton>
              </div>
            )}
          </div>

          <div style={{ width: "33%" }}>
            <div style={{ display: "flex", alignItems: "center", marginBottom: "8px" }}>
              <Typography sx={{ ...styles.typography }}>原価</Typography>
              <IconButton
                onClick={() => setIsCostModalOpen(true)}
                color="primary"
                disabled={props.isCreateNew}
              >
                <AddCircleIcon fontSize="small" />
              </IconButton>
            </div>
            <Typography>¥{props.salesState.totalCostAmount?.toLocaleString() || 0}</Typography>
          </div>

          <div style={{ width: "33%" }}>
            <Typography sx={{ ...styles.typography, mb: "12px" }}>粗利</Typography>
            <div style={{ marginLeft: "8px" }}>
              <Typography
                sx={{
                  color:
                    grossProfitAmount >= 0 ? theme.palette.text.primary : theme.palette.error.main,
                }}
              >
                ¥ {grossProfitAmount.toLocaleString()}
              </Typography>
            </div>
            <p
              style={{
                color:
                  grossProfitAmount >= 0 ? theme.palette.text.primary : theme.palette.error.main,
                fontSize: "11px",
                marginTop: "4px",
              }}
            >
              粗利率:
              {calculateGrossProfitRate(
                props.salesState.salesAmount,
                props.salesState.totalCostAmount,
              )}
              %
            </p>
          </div>
        </div>
      </Box>

      <CostBreakdownModal
        isOpen={isCostModalOpen}
        onClose={() => setIsCostModalOpen(false)}
        totalCostAmount={props.salesState.totalCostAmount}
      />

      <TaxChangeDialog
        onClose={() => setIsOpen(false)}
        isOpen={isOpen}
        tax={props.salesState.tax}
        onChange={handleChangeSalesTax}
        onBlur={handleBlurTax}
        anchorEl={anchorEl}
      />
    </>
  );
};

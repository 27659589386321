import { Box } from "@mui/material";
import { theme } from "extensions/theme";

interface Props {
  name: string;
  sx?: object;
}

export const ConnectTypeLabel = (props: Props) => {
  const backgroundColor = () => {
    switch (props.name) {
      case "発注":
        return theme.palette.customPrimary[100];
      case "受注":
        return theme.palette.secondary.light;
      case "受発注":
      case "停止":
        return theme.palette.orange[200];
      default:
        return theme.palette.grayScale[200];
    }
  };
  const borderColor = () => {
    switch (props.name) {
      case "発注":
        return theme.palette.primary.main;
      case "受注":
        return theme.palette.secondary.main;
      case "受発注":
      case "停止":
        return theme.palette.orange[500];
      default:
        return theme.palette.grayScale[700];
    }
  };
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "68px",
        height: 24,
        px: "10px",
        fontWeight: "bold",
        fontSize: "14px",
        borderRadius: "4px",
        border: `1px solid ${borderColor()}`,
        backgroundColor: backgroundColor(),
        ...props.sx,
      }}
    >
      {props.name}
    </Box>
  );
};

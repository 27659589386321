import { useState } from "react";
import {
  AutocompleteChangeReason,
  Button,
  Checkbox,
  Collapse,
  FormControlLabel,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { CustomAutocomplete } from "components/atoms/custom-autocomplete";
import { Flash } from "components/atoms/flash";
import { CustomFormLabel } from "components/molecules/custom-form-label";
import { CustomModal } from "components/molecules/custom-modal";
import { Account } from "data-access/repositories/account/account.dto";
import { ProjectId } from "data-access/repositories/project/project.dto";
import { theme } from "extensions/theme";
import { outsourcingCostRepository } from "features/cost/api/outsourcing_cost.repository";
import {
  OutsourcingCostCreateRequest,
  initialOutSourcingCostCreateRequest,
} from "features/cost/types/outsourcing_cost.dto";
import { useAppSelector } from "store/hooks";
import { selectMain } from "store/main/slice";
import { mutate } from "swr";
import { API_PATHS } from "utils/apiPaths";
import { handleStateError } from "utils/errorHandler";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  projectId: ProjectId;
}

export const OutsourcingCostFormModal = (props: Props) => {
  const mainState = useAppSelector(selectMain);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isNewCreate, setIsNewCreate] = useState<boolean>(false);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

  const [formState, setFormState] = useState<OutsourcingCostCreateRequest>({
    employee: {
      name: "",
      permissionSetId: null,
      userEmail: "",
      unitPricePerDay: 0,
    },
  });

  const activeOutsourcedMembers = mainState.accounts.filter((account) => {
    return !account.isDeactivate && (account.isOutsourcedMember || !account.hasEmail);
  });

  const handleCheckNewCreate = () => {
    setIsNewCreate(!isNewCreate);
    setFormState({
      userId: formState.userId,
    });
  };

  const handleSelectOutsourcedMember = async (
    _: React.SyntheticEvent,
    value: Account,
    reason: AutocompleteChangeReason,
  ) => {
    if (reason !== "selectOption") return;
    setFormState({
      userId: value.userId,
      employee: {
        ...formState.employee,
      },
    });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFormState({
      ...formState,
      employee: {
        ...formState.employee,
        [e.target.name]: e.target.value,
      },
    });
  };

  const handleSubmit = async () => {
    setIsSubmitted(true);
    const convertedFormState = isNewCreate
      ? { employee: formState.employee }
      : { userId: formState.userId };

    try {
      await outsourcingCostRepository.create(props.projectId, convertedFormState);
      handleClose();
      mutate(API_PATHS.getProjectOutsourcingCosts(props.projectId));
    } catch (error) {
      handleStateError(error, setErrorMessage, "外注費の追加に失敗しました");
    } finally {
      setIsSubmitted(false);
    }
  };

  const handleClose = () => {
    props.onClose();
    setIsNewCreate(false);
    setFormState(initialOutSourcingCostCreateRequest);
    setErrorMessage("");
  };

  const isValid = (): boolean => {
    if (!isNewCreate && !formState.userId) return false;
    if (isNewCreate && !formState.employee?.name) return false;
    if (isNewCreate && !formState.employee?.unitPricePerDay) return false;
    return true;
  };

  return (
    <CustomModal
      open={props.isOpen}
      onClose={handleClose}
      title="協力会社の追加"
      maxWidth="sm"
      footer={
        <>
          <Button variant="outlined" onClick={handleClose} sx={{ width: "10rem" }}>
            キャンセル
          </Button>
          <Button
            disabled={!isValid() || isSubmitted}
            onClick={handleSubmit}
            variant="contained"
            sx={{ width: "10rem", ml: "1rem" }}
          >
            追加
          </Button>
        </>
      }
    >
      {errorMessage && (
        <Flash title="エラー" severity="error" message={errorMessage} sx={{ mt: "1rem" }} />
      )}

      <CustomAutocomplete
        variant="standard"
        data={activeOutsourcedMembers || []}
        value={formState.userId}
        onChange={handleSelectOutsourcedMember}
        entityName="協力会社"
        entityLabel="協力会社を検索または選択"
        disabled={isNewCreate}
      />

      <FormControlLabel
        sx={{ ml: 0, my: "12px" }}
        labelPlacement="end"
        label={
          <Typography sx={{ fontWeight: "500", fontSize: "14px", userSelect: "none" }}>
            協力会社を新規作成して追加する
          </Typography>
        }
        control={
          <Checkbox
            name="newCreateOutsourcing"
            sx={{ p: 0, pr: 1 }}
            onChange={handleCheckNewCreate}
            checked={isNewCreate}
          />
        }
      />

      <Collapse in={isNewCreate} timeout="auto">
        <div style={{ marginBottom: "8px" }}>
          <CustomFormLabel
            labelName="名前"
            labelColor={theme.palette.grayScale[900]}
            labelWeight="bold"
            required
            requiredSize="14px"
          />
          <TextField
            name="name"
            value={formState.employee?.name}
            onChange={handleChange}
            sx={{ width: "100%" }}
            placeholder="名前を入力"
          />
        </div>
        <div>
          <CustomFormLabel
            labelName="人工単価"
            labelColor={theme.palette.grayScale[900]}
            labelWeight="bold"
            required
            requiredSize="14px"
          />
          <TextField
            name="unitPricePerDay"
            type="number"
            value={formState.employee?.unitPricePerDay}
            onChange={handleChange}
            sx={{ width: "100%" }}
            placeholder="人工単価を入力"
            InputProps={{
              startAdornment: <InputAdornment position="start">¥</InputAdornment>,
            }}
          />
        </div>
      </Collapse>
    </CustomModal>
  );
};

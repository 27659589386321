import { Box, Button, InputAdornment, TextField, Typography } from "@mui/material";
import { CustomFormLabel } from "components/molecules/custom-form-label";
import {
  SignUpHubRequest,
  SignUpVerifyResponse,
} from "data-access/repositories/sign_up/sign_up.dto";
import { theme } from "extensions/theme";
import { styles } from "./styles";
import { PageState } from ".";

interface Props {
  data: SignUpVerifyResponse;
  token: string;
  setPageState: (pageState: PageState) => void;
  formStateHub: SignUpHubRequest;
  setFormStateHub: (formStateHub: SignUpHubRequest) => void;
}
export const Hub = (props: Props) => {
  // const [isLoadingAddress, setIsLoadingAddress] = useState<boolean>(false);
  // const [addressError, setAddressError] = useState<string>("");

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;

    props.setFormStateHub({
      ...props.formStateHub,
      hub: {
        ...props.formStateHub.hub,
        [name]: value,
      },
    });
  };

  const handlePostalCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const value = e.target.value.replace(/[^\d]/g, "");
    const postalCode = value.slice(0, 7);

    props.setFormStateHub({
      ...props.formStateHub,
      hub: {
        ...props.formStateHub.hub,
        postalCode: postalCode,
      },
    });

    // if (postalCode.length === 7) {
    //   fetchAddressFromPostalCode(postalCode);
    // }
  };

  // const fetchAddressFromPostalCode = async (postalCode: string) => {
  //   try {
  //     setIsLoadingAddress(true);
  //     setAddressError("");

  //     const timeoutPromise = new Promise<never>((_, reject) => {
  //       setTimeout(() => reject(new Error("住所取得がタイムアウトしました")), 5000);
  //     });

  //     const response = await Promise.race([addressRepository.index(postalCode), timeoutPromise]);

  //     if (response.data && response.data.length > 0) {
  //       const addressData = response.data[0];
  //       const formattedAddress = `${addressData.prefecture}${addressData.city}${addressData.town}`;

  //       props.setFormStateHub({
  //         ...props.formStateHub,
  //         hub: {
  //           ...props.formStateHub.hub,
  //           address: formattedAddress,
  //           postalCode: postalCode,
  //         },
  //       });
  //     } else {
  //       setAddressError("該当する住所が見つかりませんでした");
  //     }
  //   } catch (error) {
  //     if (error instanceof Error && error.message === "住所取得がタイムアウトしました") {
  //       setAddressError("住所取得がタイムアウトしました");
  //     } else {
  //       setAddressError("住所の取得に失敗しました");
  //     }
  //   } finally {
  //     setIsLoadingAddress(false);
  //   }
  // };

  const isFormValid = () => {
    return props.formStateHub.hub.name && props.formStateHub.hub.address;
  };

  // const getPostalCodeHelperText = () => {
  //   if (isLoadingAddress) return "住所を取得中...";
  //   if (addressError) return addressError;
  //   return "ハイフンなしで入力してください";
  // };

  return (
    <>
      <Box sx={{ mt: "32px", display: "flex", flexDirection: "column", alignItems: "center" }}>
        <Typography sx={{ fontSize: "24px", fontWeight: "bold", textAlign: "center", mb: "40px" }}>
          事業者情報を登録しましょう
        </Typography>
        <CustomFormLabel
          labelName="会社名"
          required
          small
          requiredSize="14px"
          sx={{ justifyContent: "flex-start", width: "100%" }}
        />
        <TextField
          name="name"
          value={props.formStateHub.hub.name}
          onChange={handleChange}
          fullWidth
          sx={{ ...styles.textField, mb: "8px" }}
          placeholder="現場Hub株式会社"
        />
        <Typography
          sx={{
            fontSize: "12px",
            color: theme.palette.grayScale[700],
            width: "100%",
            textAlign: "left",
            mb: "24px",
          }}
        >
          個人事業主の方は、屋号を記載してください
        </Typography>
        <CustomFormLabel
          labelName="事業所名"
          small
          sx={{ justifyContent: "flex-start", width: "100%" }}
        />
        <TextField
          name="officeName"
          value={props.formStateHub.hub.officeName}
          onChange={handleChange}
          fullWidth
          sx={styles.textField}
          placeholder="東京支店"
        />
        <CustomFormLabel
          labelName="郵便番号"
          small
          sx={{ justifyContent: "flex-start", width: "100%" }}
        />
        <TextField
          name="postalCode"
          value={props.formStateHub.hub.postalCode}
          onChange={handlePostalCodeChange}
          fullWidth
          sx={styles.textField}
          inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
          placeholder="1500045"
          // helperText={getPostalCodeHelperText()}
          // error={!!addressError}
          slotProps={{
            input: {
              startAdornment: <InputAdornment position="start">〒</InputAdornment>,
            },
          }}
        />
        <CustomFormLabel
          labelName="都道府県・市区町村・番地"
          required
          small
          requiredSize="14px"
          sx={{ justifyContent: "flex-start", width: "100%" }}
        />
        <TextField
          name="address"
          value={props.formStateHub.hub.address}
          onChange={handleChange}
          fullWidth
          sx={styles.textField}
          // disabled={isLoadingAddress}
        />
        <CustomFormLabel
          labelName="建物名・階・号室"
          small
          sx={{ justifyContent: "flex-start", width: "100%" }}
        />
        <TextField
          name="addressSecond"
          value={props.formStateHub.hub.addressSecond}
          onChange={handleChange}
          fullWidth
          sx={styles.textField}
        />

        <Button
          variant="contained"
          onClick={() => props.setPageState("confirm")}
          sx={{ borderRadius: "24px", width: "240px", mb: "12px" }}
          disabled={!isFormValid()}
        >
          登録情報の確認
        </Button>
        <Button onClick={() => props.setPageState("profile")}>もどる</Button>
      </Box>
    </>
  );
};

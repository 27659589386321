import { useCallback, useMemo } from "react";
import { Box } from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { GridRowOrderChangeParams } from "@mui/x-data-grid-pro";
import { StatusTypeLabel } from "components/label/status-type-label";
import { CustomizedDataGridPro } from "components/templates/customized-data-grid-pro";
import { Account } from "data-access/repositories/account/account.dto";
import { accountRepository } from "data-access/repositories/account/account.repository";
import { Group } from "data-access/repositories/group/group.dto";
import { groupRepository } from "data-access/repositories/group/group.repository";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { mainOperations } from "store/main/operations";
import { selectMain } from "store/main/slice";
import useSWR from "swr";

const menuItemStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: 24,
  width: "min-content",
  px: "10px",
  fontWeight: "bold",
  fontSize: "12px",
  borderRadius: "4px",
};

export const MemberOrderTable = () => {
  const dispatch = useAppDispatch();
  const mainState = useAppSelector(selectMain);

  const { data: groups } = useSWR("/api/v1/groups", groupRepository.index);

  const activeMembers = mainState.accounts.filter((member) => {
    return !member.isDeactivate;
  });

  const columns = useMemo(() => {
    const headers: GridColDef[] = [];
    headers.push({
      field: "name",
      headerName: "名前",
      minWidth: 250,
      sortable: true,
    });
    headers.push({
      field: "group_id",
      headerName: "グループ",
      minWidth: 200,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<Account>) => {
        const group = groups?.find((group: Group) => group.id === params.row.groupId);
        return (
          <>
            {group ? (
              <Box
                sx={{
                  ...menuItemStyles,
                  backgroundColor: `#${group.colorNumber}`,
                }}
              >
                {group.name}
              </Box>
            ) : null}
          </>
        );
      },
    });
    headers.push({
      field: "is_outsourced_member",
      headerName: "自社/協力会社",
      minWidth: 120,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<Account>) => (
        <StatusTypeLabel
          statusType={params.row.isOutsourcedMember ? "協力会社" : "自社"}
          sx={{ display: "inline" }}
        />
      ),
    });
    return headers;
  }, []);

  const handleRowOrderChange = useCallback(async (params: GridRowOrderChangeParams) => {
    try {
      await accountRepository.update(params.row.userId, {
        displayOrderPosition: params.targetIndex,
      });
      dispatch(mainOperations.updateSuccessMessage("順番を変更しました"));
      dispatch(mainOperations.getAccounts());
    } catch (error) {
      dispatch(mainOperations.updateErrorMessage(error.response.data.message));
    }
  }, []);

  return (
    <CustomizedDataGridPro
      columnHeaderHeight={40}
      columns={columns}
      rows={activeMembers || []}
      hideFooter
      rowReordering
      disableRowSelectionOnClick
      onRowOrderChange={handleRowOrderChange}
    />
  );
};

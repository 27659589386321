import { ElementRef, useRef, useState } from "react";
import { Edit, OpenInNew } from "@mui/icons-material";
import { FileDownloadOutlined } from "@mui/icons-material";
import { Box, Typography, Stack, Button, IconButton } from "@mui/material";
import { PrimaryButton } from "components/atoms/primary-button";
import { DeleteIcon } from "components/icon/delete-icon";
import { CustomFormLabel } from "components/molecules/custom-form-label";
import { AsyncConfirmDialog } from "components/templates/async-confirm-dialog";
import { ProjectId } from "data-access/repositories/project/project.dto";
import { theme } from "extensions/theme";
import { clientServiceReportRepository } from "features/client-service-report/api/client_service_report.repository";
import { UpdateModal } from "features/client-service-report/components/update/modal";
import {
  ClientServiceReportId,
  ClientServiceReportIndex,
} from "features/client-service-report/types/client_service_report.dto";
import ExcelIcon from "images/excel_icon.svg";
import PdfIcon from "images/pdf_icon.svg";
import { useAppDispatch } from "store/hooks";
import { mainOperations } from "store/main/operations";
import useSWR from "swr";
import { formatDateUtil } from "utils/formatDateUtil";
import { styles } from "./styles";
import { CreateModal } from "../../../../client-service-report/components/create/modal";

interface Props {
  isCreateNew: boolean;
  projectId?: ProjectId;
  onDownloadPdf: (clientServiceReport: ClientServiceReportIndex) => void;
  onDownloadExcel: (clientServiceReport: ClientServiceReportIndex) => void;
}

export const ProjectSidebarClientServiceReportContent = (props: Props) => {
  const dispatch = useAppDispatch();
  const [isCreateOpen, setIsCreateOpen] = useState<boolean>(false);
  const [updateModal, setUpdateModal] = useState<{ isOpen: boolean; id: ClientServiceReportId }>({
    isOpen: false,
    id: 0 as ClientServiceReportId,
  });
  const fetchIndexKey = `/api/v1/projects/${props.projectId}/client_service_reports`;
  const deleteConfirmRef = useRef<ElementRef<typeof AsyncConfirmDialog>>(null);

  const { data: clientServiceReports, mutate } = useSWR(
    props.projectId ? fetchIndexKey : null,
    () => clientServiceReportRepository.index(props.projectId as ProjectId),
  );

  const handleDelete = async (id: ClientServiceReportId) => {
    if (!deleteConfirmRef.current) return;
    const confirm = await deleteConfirmRef.current.confirm();
    if (confirm) {
      dispatch(mainOperations.updateIsLoading(true));
      try {
        await clientServiceReportRepository.destroy(id);
        mutate();
        dispatch(mainOperations.updateSuccessMessage("報告書を削除しました"));
      } catch (error) {
        dispatch(mainOperations.updateErrorMessage(error.response.data.message));
      } finally {
        dispatch(mainOperations.updateIsLoading(false));
      }
    }
  };

  return (
    <>
      <AsyncConfirmDialog ref={deleteConfirmRef} />
      {props.projectId && (
        <>
          <CreateModal
            isOpen={isCreateOpen}
            onClose={() => setIsCreateOpen(false)}
            projectId={props.projectId}
            fetchIndexKey={fetchIndexKey}
          />
          <UpdateModal
            isOpen={updateModal.isOpen}
            onClose={() => setUpdateModal({ isOpen: false, id: 0 as ClientServiceReportId })}
            projectId={props.projectId}
            clientServiceReportId={updateModal.id}
            fetchIndexKey={fetchIndexKey}
          />
        </>
      )}

      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <CustomFormLabel labelName="報告書" labelColor={theme.palette.grayScale[700]} />
        <PrimaryButton
          name="報告書を作成"
          onClick={() => setIsCreateOpen(true)}
          disabled={props.isCreateNew}
        />
      </div>
      {clientServiceReports && clientServiceReports.length > 0 ? (
        clientServiceReports.map((clientServiceReport, index) => (
          <Box key={index} sx={{ ...styles.block }}>
            <Box sx={{ mb: "12px" }}>
              <Stack spacing="12px">
                <div
                  style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
                >
                  <Typography sx={{ color: theme.palette.primary.main, fontWeight: "bold" }}>
                    {clientServiceReport.format.name}
                  </Typography>
                  <div>
                    <IconButton
                      onClick={() => setUpdateModal({ isOpen: true, id: clientServiceReport.id })}
                    >
                      <Edit fontSize="small" sx={{ color: theme.palette.grayScale[700] }} />
                    </IconButton>
                    <IconButton onClick={() => handleDelete(clientServiceReport.id)}>
                      <DeleteIcon color={theme.palette.grayScale[700]} size={20} />
                    </IconButton>
                  </div>
                </div>
                <Typography fontSize="12px">
                  {formatDateUtil(new Date(clientServiceReport.createdDate), "yyyy年MM月dd日")}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: "500",
                    color: theme.palette.grayScale[900],
                  }}
                >
                  {clientServiceReport.createdBy.name}
                </Typography>
              </Stack>
            </Box>
            <Button
              variant="outlined"
              onClick={() => props.onDownloadPdf(clientServiceReport)}
              sx={{
                ...styles.button,
                mb: "8px",
              }}
            >
              <Box sx={{ width: "20px" }} component="img" src={PdfIcon} />
              <Typography fontWeight="500" fontSize="14px">
                PDF
              </Typography>
              <OpenInNew fontSize="small" />
            </Button>
            <Button
              variant="outlined"
              onClick={() => props.onDownloadExcel(clientServiceReport)}
              sx={{ ...styles.button }}
            >
              <Box sx={{ width: "20px" }} component="img" src={ExcelIcon} />
              <Typography
                sx={{
                  fontWeight: "500",
                  fontSize: "14px",
                  textTransform: "none",
                }}
              >
                Excel
              </Typography>
              <FileDownloadOutlined fontSize="small" />
            </Button>
          </Box>
        ))
      ) : (
        <Typography>報告書はありません</Typography>
      )}
    </>
  );
};

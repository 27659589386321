import { Brand } from "data-access/repositories/brand";
import { PageInfo } from "data-access/repositories/page_info/page_info.dto";
import { CsvImportStatus } from "./csv_import_project.dto";

export type CsvImportScheduleId = Brand<number, "CsvImportScheduleId">;

export type CsvImportScheduleImportKey = "code" | "externalId";

export interface CsvImportScheduleIndexResponse {
  data: CsvImportSchedule[];
  pagination: PageInfo;
}

export interface CsvImportSchedule {
  id: CsvImportScheduleId;
  filename: string;
  columnMap: ColumnMap;
  status: CsvImportStatus;
  estimatedTimeRemainingSeconds: number;
  totalCount: number;
  createdCount: number;
  failedCount: number;
  importedUserName: string;
  createdAt: string;
}

export interface ColumnMap {
  name: string;
  scheduleTypeName: string;
  startTime: string;
  endTime: string;
  siteManagerName: string;
  assignmentUserNames: string;
  confirmed: string;
  projectCode: string;
  visibility: string;
}
export interface CsvImportScheduleAnalyzeRequest {
  objectKey: string;
}

export interface CsvImportScheduleAnalyzeResponse {
  createCount: number;
  estimatedTimeSeconds: number;
}

export interface CsvImportScheduleRequest {
  objectKey: string;
  filename: string;
  columnMap: {
    name: string;
    scheduleTypeName: string;
    startTime: string;
    endTime: string;
    siteManagerName: string;
    assignmentUserNames: string;
    confirmed: string;
    projectCode: string;
    visibility: string;
  };
}

export const initialImportCsvScheduleRequest: CsvImportScheduleRequest = {
  objectKey: "",
  filename: "",
  columnMap: {
    name: "",
    scheduleTypeName: "",
    startTime: "",
    endTime: "",
    siteManagerName: "",
    assignmentUserNames: "",
    confirmed: "",
    projectCode: "",
    visibility: "",
  },
};

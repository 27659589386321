import { Clear } from "@mui/icons-material";
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import CheckIcon from "@mui/icons-material/Check";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/styles";
import { CustomDatePicker } from "components/atoms/custom-date-picker";
import { theme } from "extensions/theme";
import { ClientServiceReportItemResponse } from "features/client-service-report/types/client_service_report_item.dto";

const CustomPhotoBox = styled(Box)({
  position: "relative",
  borderRadius: "4px",
  height: "160px",
  width: "160px",
  textAlign: "center",
  border: `1px solid ${theme.palette.grayScale[300]}`,
  "& .MuiIconButton-root": {
    position: "absolute",
    top: 3,
    right: 3,
  },
});

const CustomIconButton = styled(IconButton)({
  backgroundColor: `${theme.palette.grayScale[0]} !important`,
  color: `${theme.palette.text.primary} !important`,
  border: `1px solid ${theme.palette.grayScale[300]} !important`,
  width: "24px",
  height: "24px",
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
});

interface Props {
  state: ClientServiceReportItemResponse | undefined;
  onChange: (value?: string) => void;
  onClick: () => void;
  onPhotoDelete: () => void;
}

export const InputFieldSwitcher = (props: Props) => {
  const { state, onChange, onClick, onPhotoDelete } = props;
  if (!state) return null;

  const elementType = state.formatItem.inputType;
  const name = `input-${state.id}`;
  const textFieldWidth = "370px";
  const textAreaHeight = "40px";
  return (
    <div>
      {elementType === "text_field" && (
        <>
          <TextField
            name={name}
            value={state.value}
            onChange={(e) => onChange(e.target.value)}
            sx={{
              width: textFieldWidth,
              "& .MuiOutlinedInput-root": {
                height: textAreaHeight,
              },
            }}
          />
        </>
      )}
      {elementType === "text_area" && (
        <TextField
          name={name}
          value={state.value}
          onChange={(e) => onChange(e.target.value)}
          multiline
          sx={{
            width: "100%",
            "& .MuiOutlinedInput-root": {
              minHeight: "80px",
            },
          }}
          minRows={2}
        />
      )}
      {elementType === "date" && (
        <CustomDatePicker
          id={name}
          name={name}
          date={state.value ? new Date(state.value) : null}
          onChange={(date) => onChange(date?.toISOString())}
        />
      )}
      {elementType === "image" && (
        <>
          {state.itemPhoto || state.photo || state.workReportPhoto || state.value ? (
            <CustomPhotoBox>
              <img
                src={
                  state.itemPhoto?.thumbnailUrl ||
                  state.photo?.thumbnailUrl ||
                  state.workReportPhoto?.thumbnailUrl ||
                  state.value
                }
                style={{ height: "100%", maxHeight: "160px", maxWidth: "160px" }}
                loading="lazy"
              />
              <CustomIconButton onClick={onPhotoDelete}>
                <Clear fontSize="small" />
              </CustomIconButton>
            </CustomPhotoBox>
          ) : (
            <Button variant="outlined" onClick={onClick} sx={{ width: "200px" }}>
              <AddCircleOutlinedIcon fontSize="small" sx={{ mr: "8px" }} />
              写真を選択
            </Button>
          )}
        </>
      )}
      {elementType === "signature" && (
        <>
          {state.itemPhoto ? (
            <CustomPhotoBox>
              <img
                src={state.itemPhoto?.thumbnailUrl}
                style={{
                  objectFit: "contain",
                  height: "100%",
                  maxHeight: "160px",
                  maxWidth: "155px",
                }}
                loading="lazy"
              />
              <CustomIconButton onClick={onPhotoDelete}>
                <Clear fontSize="small" />
              </CustomIconButton>
            </CustomPhotoBox>
          ) : (
            <Button variant="outlined" onClick={onClick} sx={{ width: "200px" }} disabled>
              <AddCircleOutlinedIcon fontSize="small" sx={{ mr: "8px" }} />
              署名を追加
            </Button>
          )}
          <Typography fontSize={12} color={theme.palette.grayScale[700]} mt="8px">
            署名の追加は、スマートフォンアプリからのみ可能です
          </Typography>
        </>
      )}
      {elementType === "select_box" && (
        <>
          <Select
            name={name}
            value={state.value}
            onChange={(e) => onChange(e.target.value)}
            style={{
              height: textAreaHeight,
              width: textFieldWidth,
              backgroundColor: theme.palette.grayScale[0],
            }}
            renderValue={() => state.value}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: "500px",
                },
              },
            }}
          >
            <MenuItem key={`selectBox-unselect`} value="">
              {state.value == "" ? (
                <CheckIcon color="primary" sx={{ mr: 1 }} />
              ) : (
                <Box sx={{ px: 1.5, mr: 1 }} />
              )}
              <Box>未選択</Box>
            </MenuItem>
            {state.formatItem.selectBoxOptions &&
              state.formatItem.selectBoxOptions.map((option) => (
                <MenuItem key={`selectBox-${option}`} value={`${option}`}>
                  {state.value == option ? (
                    <CheckIcon color="primary" sx={{ mr: 1 }} />
                  ) : (
                    <Box sx={{ px: 1.5, mr: 1 }} />
                  )}
                  <Box>{option}</Box>
                </MenuItem>
              ))}
          </Select>
        </>
      )}
      {elementType === "check_box" && (
        <FormControlLabel
          control={
            <Checkbox
              name={name}
              checked={state.value === "true"}
              onChange={(e) => onChange(e.target.checked ? "true" : "false")}
              size="small"
            />
          }
          label={
            <Typography sx={{ fontSize: "14px", fontWeight: "500" }}>
              {state.formatItem.name}
            </Typography>
          }
        />
      )}
    </div>
  );
};

import { useState } from "react";
import { Box } from "@mui/material";
import { LoadingOverlay } from "components/loadingOverlay";
import { Header } from "components/templates/layout/header";
import { Sidebar } from "components/templates/layout/sidebar";
import { useAppSelector } from "store/hooks";
import { selectMain } from "store/main/slice";
import { ResponseSnackbar } from "../response-snackbar";

interface Props {
  isOverflowAllowed?: boolean;
}
export const Layout = ({
  isOverflowAllowed = false,
  children,
}: Props & { children: React.ReactNode }) => {
  const mainState = useAppSelector(selectMain);
  const [open, setOpen] = useState<boolean>(true);

  const headerProps = {
    open,
    setOpen,
  };

  const sidebarProps = { open, setOpen };

  return (
    <Box sx={{ display: "flex" }}>
      <Header {...headerProps} />
      <Sidebar {...sidebarProps} />
      <Box
        sx={{
          flexGrow: 1,
          py: 3,
          px: 2,
          mt: isOverflowAllowed ? 3 : 8,
          overflow: isOverflowAllowed ? "visible" : "hidden",
        }}
      >
        <ResponseSnackbar
          successMessage={mainState.successMessage}
          errorMessage={mainState.errorMessage}
        />
        <LoadingOverlay isLoading={mainState.isLoading} zIndex={9999} />
        {children}
      </Box>
    </Box>
  );
};

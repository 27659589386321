import { ElementRef, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";
import { Avatar } from "components/avatar";
import { ConnectTypeLabel } from "components/label/connect-type-label";
import { StatusTypeLabel } from "components/label/status-type-label";
import { LoadingOverlay } from "components/loadingOverlay";
import { AsyncConfirmDialog } from "components/templates/async-confirm-dialog";
import { theme } from "extensions/theme";
import { connectRequestingRepository } from "features/hub-settings/api/connect/connect_requesting.repository";
import { ConnectRequestingId } from "features/hub-settings/types/connect/connect_requesting.dto";
import { useAppDispatch } from "store/hooks";
import { mainOperations } from "store/main/operations";
import useSWR from "swr";
import { API_PATHS } from "utils/apiPaths";
import { handleReduxError } from "utils/errorHandler";
import { RequestDetailModal } from "./detail-modal";
import { styles } from "./styles";

export const ConnectRequesting = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [isDetailModalOpen, setIsDetailModalOpen] = useState<boolean>(false);

  const { data, isLoading } = useSWR(
    API_PATHS.getConnectRequestsRequesting(),
    connectRequestingRepository.index,
  );

  useEffect(() => {
    if (new RegExp(`^/hub-settings/connects/requesting/\\d+$`).test(location.pathname)) {
      setIsDetailModalOpen(true);
    }
  }, [location]);

  const handleClickDetail = (id: ConnectRequestingId) => {
    navigate(`/hub-settings/connects/requesting/${id}`, { state: { id } });
  };

  const mailResendConfirmRef = useRef<ElementRef<typeof AsyncConfirmDialog>>(null);

  const handleMailResend = async (id: ConnectRequestingId) => {
    if (!mailResendConfirmRef.current) return;
    const res = await mailResendConfirmRef.current.confirm();
    if (!res) return;
    dispatch(mainOperations.updateIsLoading(true));
    try {
      await connectRequestingRepository.resend(id);
      dispatch(mainOperations.updateSuccessMessage("メールを再送しました"));
    } catch (error) {
      handleReduxError(error, dispatch, "メールの再送に失敗しました");
    } finally {
      dispatch(mainOperations.updateIsLoading(false));
    }
  };

  const handleCloseRequestDetailModal = () => {
    navigate("/hub-settings/connects/requesting");
    setIsDetailModalOpen(false);
  };

  return (
    <>
      <AsyncConfirmDialog
        ref={mailResendConfirmRef}
        title="メールを再送しますか？"
        yesButtonText="再送"
        yesButtonColor="primary"
      />
      {isDetailModalOpen && (
        <RequestDetailModal isOpen={isDetailModalOpen} onClose={handleCloseRequestDetailModal} />
      )}
      <LoadingOverlay isLoading={isLoading} />

      <Box display="flex" flexWrap="wrap" gap="24px" width="100%">
        {data &&
          data.map((connectRequest) => (
            <Box key={connectRequest.id} sx={{ ...styles.hubContent }}>
              {connectRequest.partnerHub && (
                <Avatar
                  url={connectRequest.partnerHub.logoUrl}
                  name={connectRequest.partnerHub.name}
                  bgColor={connectRequest.partnerHub.logoBackgroundColorNumber}
                  size="medium"
                />
              )}

              <Box display="flex" flexDirection="column" flex={1} minWidth={0}>
                <Box
                  sx={{
                    flex: 1,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography sx={{ ...styles.hubName }}>
                    {connectRequest.partnerHub
                      ? connectRequest.partnerHub.name
                      : connectRequest.destinationEmail}
                  </Typography>
                  <StatusTypeLabel
                    statusType={connectRequest.isNew ? "新規" : "更新"}
                    sx={{ width: "fit-content" }}
                  />
                </Box>
                <Typography
                  sx={{ fontSize: "14px", color: theme.palette.grayScale[700], mb: "8px" }}
                >
                  {connectRequest.partnerHub?.officeName}
                </Typography>
                <Typography sx={{ fontSize: "14px", color: theme.palette.grayScale[700] }}>
                  {connectRequest.partnerHub?.address}
                  {connectRequest.partnerHub?.addressSecond}
                </Typography>
                <Box sx={{ display: "flex", alignItems: "center", mt: "16px", gap: "8px" }}>
                  <Typography fontSize="14px" fontWeight="bold" flex={1}>
                    {connectRequest.partnerHub ? connectRequest.partnerHub.name : "相手"}が
                  </Typography>
                  <ConnectTypeLabel
                    name={connectRequest.partnerHubRequestedConnectType.valueI18n}
                  />
                </Box>

                <Box sx={{ display: "flex", gap: "12px", mt: "16px" }}>
                  <Button
                    variant="contained"
                    onClick={() => handleClickDetail(connectRequest.id)}
                    sx={{ ...styles.button }}
                  >
                    詳細
                  </Button>
                  {connectRequest.destinationEmail && connectRequest.isNew && (
                    <Button
                      variant="contained"
                      onClick={() => handleMailResend(connectRequest.id)}
                      sx={{
                        ...styles.button,
                        bgcolor: theme.palette.grayScale[0],
                        color: theme.palette.primary.main,
                        border: `1px solid ${theme.palette.primary.main}`,
                      }}
                    >
                      メール再送
                    </Button>
                  )}
                </Box>
              </Box>
            </Box>
          ))}
      </Box>
    </>
  );
};

import { forwardRef, useState } from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Box, Button, Typography } from "@mui/material";
import { LoginDisabledMemberModal } from "./form-modal";
import { LoginDisabledMemberTable } from "./table";

const LoginDisabledMember = forwardRef<HTMLDivElement>((_, ref) => {
  const [isModalOpen, setModalOpen] = useState<boolean>(false);

  return (
    <>
      <LoginDisabledMemberModal onClose={() => setModalOpen(false)} isOpen={isModalOpen} />

      <Box
        ref={ref}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: "16px",
        }}
      >
        <Typography sx={{ fontSize: "14px", fontWeight: "bold" }}>ログイン不可メンバー</Typography>
        <Button
          variant="contained"
          startIcon={<AddCircleIcon />}
          onClick={() => setModalOpen(true)}
        >
          ログイン不可メンバーを作成
        </Button>
      </Box>
      <Box>
        <LoginDisabledMemberTable />
      </Box>
    </>
  );
});

LoginDisabledMember.displayName = "LoginDisabledMember";

export default LoginDisabledMember;

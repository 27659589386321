import { Box, Typography, Divider } from "@mui/material";
import { WorkReport, WorkReportId } from "data-access/repositories/work_report/work_report.dto";
import { theme } from "extensions/theme";
import { timeRange } from "utils/timeRange";
import { usersName } from "utils/usersName";
import { styles } from "./styles";

interface Props {
  workReport: WorkReport;
  ownerType: "own" | "partner" | undefined;
  setShow: (e: {
    isOpen: boolean;
    id: WorkReportId | undefined;
    ownerType: "own" | "partner" | undefined;
  }) => void;
}

export const WorkReportBlock = (props: Props) => {
  const handleClick = (ownerType: "own" | "partner" | undefined) => {
    props.setShow({ isOpen: true, id: props.workReport.id, ownerType });
  };

  return (
    <Box
      key={props.workReport.id}
      sx={{ ...styles.block }}
      onClick={() => handleClick(props.ownerType)}
    >
      <Box sx={{ width: "100%", flexDirection: "column", display: "flex" }}>
        <Box sx={{ width: "100%" }}>
          <Typography
            sx={{
              color: theme.palette.primary.main,
              fontWeight: "bold",
              height: "auto",
              lineHeight: "auto",
              mb: "6px",
            }}
          >
            {props.workReport.siteName}
          </Typography>
        </Box>
        <Box sx={{ width: "100%", flexDirection: "row", display: "flex" }}>
          <Box sx={{ width: "70%" }}>
            <Typography fontSize="0.8rem" sx={{ mb: "0.375rem" }}>
              {timeRange(
                "yyyy年MM月dd日 HH:mm",
                props.workReport.startTime.toString(),
                props.workReport.endTime.toString(),
              )}
            </Typography>
            <Typography fontSize="0.8rem" sx={{ mb: "0.375rem" }}>
              現場責任者：{props.workReport.siteManager?.name}
            </Typography>
            <Typography sx={{ ...styles.member }}>
              参加者({props.workReport.users.length}名)：
              {usersName(
                props.workReport.users.map((user) => ({
                  userId: user.userId,
                  name: user.name,
                })),
              )}
            </Typography>
            <Typography fontSize="0.8rem">
              作業の進捗：{props.workReport.workStatusTypeI18n}
            </Typography>
          </Box>
          {props.workReport.headerPhotos.length > 0 && (
            <Box
              sx={{
                flexDirection: "column",
                justifyContent: "flex-end",
                display: "flex",
              }}
            >
              <Box
                component="img"
                alt={`photo-${props.workReport.headerPhotos[0].id}`}
                src={props.workReport.headerPhotos[0].thumbnailUrl}
                sx={{ ...styles.image }}
              />
            </Box>
          )}
        </Box>
      </Box>
      {props.workReport.content !== "" && (
        <>
          <Divider sx={{ my: "0.75rem" }} />
          <Typography sx={{ ...styles.content }}>{props.workReport.content}</Typography>
        </>
      )}
    </Box>
  );
};

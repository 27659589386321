import { createAsyncThunk } from "@reduxjs/toolkit";
import { Account, AccountReference } from "data-access/repositories/account/account.dto";
import { accountRepository } from "data-access/repositories/account/account.repository";
import { HubSetting } from "data-access/repositories/hub_setting/hub_setting.dto";
import { hubSettingRepository } from "data-access/repositories/hub_setting/hub_setting.repository";
import { PersonalSettingShowResponse } from "data-access/repositories/personal_setting/personal_setting.dto";
import { personalSettingRepository } from "data-access/repositories/personal_setting/personal_setting.repository";
import { ProjectStatusType } from "data-access/repositories/project_status_type/project_status_type.dto";
import { projectStatusTypeRepository } from "data-access/repositories/project_status_type/project_status_type.repository";
import { ProjectType } from "data-access/repositories/project_type/project_type.dto";
import { projectTypeRepository } from "data-access/repositories/project_type/project_type.repository";
import { ScheduleType } from "data-access/repositories/schedule_type/schedule_type.dto";
import { scheduleTypeRepository } from "data-access/repositories/schedule_type/schedule_type.repository";
import { TodoTagType } from "data-access/repositories/todo_tag_type/todo/todo_tag_type.dto";
import { todoTagTypeRepository } from "data-access/repositories/todo_tag_type/todo/todo_tag_type.repository";
import { AsyncThunkConfig } from "extensions/redux/AsyncThunkConfig";
import { hubRepository } from "features/hub-settings/api/hub-info/hub_info.repository";
import { HubInfoData } from "features/hub-settings/types/hub-info/hub_info.dto";
import { meRepository } from "features/user-settings/api/profile-setting/me.repository";
import { AuthResponse } from "../../data-access/repositories/auth/auth.dto";
import { authRepository } from "../../data-access/repositories/auth/auth.repository";

export const getAuth = createAsyncThunk<AuthResponse, void, AsyncThunkConfig>(
  "main/getAuth",
  async (_, thunkApi) => {
    try {
      return await authRepository.show();
    } catch (error) {
      return thunkApi.rejectWithValue({ message: error.response.data.message });
    }
  },
);

export const getMe = createAsyncThunk<AccountReference, void, AsyncThunkConfig>(
  "main/getMe",
  async (_, thunkApi) => {
    try {
      return await meRepository.index();
    } catch (error) {
      return thunkApi.rejectWithValue({ message: error.response.data.message });
    }
  },
);

export const getHub = createAsyncThunk<HubInfoData, void, AsyncThunkConfig>(
  "main/getHub",
  async (_, thunkApi) => {
    try {
      return await hubRepository.index();
    } catch (error) {
      return thunkApi.rejectWithValue({ message: error.response.data.message });
    }
  },
);

export const getHubSetting = createAsyncThunk<HubSetting, void, AsyncThunkConfig>(
  "main/getHubSetting",
  async (_, thunkApi) => {
    try {
      return await hubSettingRepository.index();
    } catch (error) {
      return thunkApi.rejectWithValue({ message: error.response.data.message });
    }
  },
);

export const getProjectTypes = createAsyncThunk<ProjectType[], void, AsyncThunkConfig>(
  "main/getProjectTypes",
  async (_, thunkApi) => {
    try {
      return await projectTypeRepository.index();
    } catch (error) {
      return thunkApi.rejectWithValue({ message: error.response.data.message });
    }
  },
);

export const getProjectStatusTypes = createAsyncThunk<ProjectStatusType[], void, AsyncThunkConfig>(
  "main/getProjectStatusTypes",
  async (_, thunkApi) => {
    try {
      return await projectStatusTypeRepository.index();
    } catch (error) {
      return thunkApi.rejectWithValue({ message: error.response.data.message });
    }
  },
);

export const getAccounts = createAsyncThunk<Account[], void, AsyncThunkConfig>(
  "main/getAccounts",
  async (_, thunkApi) => {
    try {
      return await accountRepository.index();
    } catch (error) {
      return thunkApi.rejectWithValue({ message: error.response.data.message });
    }
  },
);

export const getProjectTodoTagTypes = createAsyncThunk<TodoTagType[], void, AsyncThunkConfig>(
  "main/getProjectTodoTagTypes",
  async (_, thunkApi) => {
    try {
      return await todoTagTypeRepository.index();
    } catch (error) {
      return thunkApi.rejectWithValue({ message: error.response.data.message });
    }
  },
);

export const getScheduleTypes = createAsyncThunk<ScheduleType[], void, AsyncThunkConfig>(
  "main/getScheduleTypes",
  async (_, thunkApi) => {
    try {
      return await scheduleTypeRepository.index();
    } catch (error) {
      return thunkApi.rejectWithValue({ message: error.response.data.message });
    }
  },
);

export const getPersonalSetting = createAsyncThunk<
  PersonalSettingShowResponse,
  void,
  AsyncThunkConfig
>("main/getPersonalSetting", async (_, thunkApi) => {
  try {
    return await personalSettingRepository.show();
  } catch (error) {
    return thunkApi.rejectWithValue({ message: error.response.data.message });
  }
});

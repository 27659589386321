import { DayOfWeek } from "data-access/repositories/account/account.dto";
import { Day } from "date-fns";

// DatePicker・calendarStartDayの曜日はDayOfWeekの数字とは異なる（日月火水木金土）
export const convertToCalendarStartDay = (n: DayOfWeek): Day => {
  switch (n) {
    case 6:
      return 0;
    default:
      return (n + 1) as Day;
  }
};

import { ApiClient } from "data-access/ApiClient";
import { convertKeysToCamelCase } from "utils/convertObjectKeyCase";
import { PermissionSet, PermissionSetId, PermissionSetUpdateRequest } from "./permission_set.dto";

export const permissionSetRepository = {
  index(): Promise<Array<PermissionSet>> {
    return ApiClient.get("/api/v1/permission_sets").then((res) => {
      return convertKeysToCamelCase(res.data);
    });
  },
  show(id: PermissionSetId): Promise<PermissionSet> {
    return ApiClient.get(`/api/v1/permission_sets/${id}`).then((res) => {
      return convertKeysToCamelCase(res.data);
    });
  },
  update(id: PermissionSetId, body: PermissionSetUpdateRequest): Promise<void> {
    const config = { headers: { "content-type": "application/json" } };
    return ApiClient.put(`/api/v1/permission_sets/${id}`, updateBody(body), config).then((res) => {
      return res.data;
    });
  },
  delete(id: PermissionSetId): Promise<void> {
    return ApiClient.delete(`/api/v1/permission_sets/${id}`).then((res) => {
      return res.data;
    });
  },
  duplicate(id: PermissionSetId): Promise<PermissionSet> {
    return ApiClient.post(`/api/v1/permission_sets/${id}/duplicate`).then((res) => {
      return convertKeysToCamelCase(res.data);
    });
  },
};

const updateBody = (body: PermissionSetUpdateRequest) => {
  return {
    name: body.name,
    display_order_position: body.displayOrder,
    project_write: body.projectWrite,
    project_read: body.projectRead,
    sales_write: body.salesWrite,
    sales_read: body.salesRead,
    cost_write: body.costWrite,
    cost_read: body.costRead,
    estimate_write: body.estimateWrite,
    estimate_read: body.estimateRead,
    attachment_read: body.attachmentRead,
    billing_write: body.billingWrite,
    billing_read: body.billingRead,
    schedule_write: body.scheduleWrite,
    schedule_read: body.scheduleRead,
    work_report_write: body.workReportWrite,
    work_report_read: body.workReportRead,
    client_write: body.clientWrite,
    client_read: body.clientRead,
    labor_write: body.laborWrite,
    labor_read: body.laborRead,
    master_write: body.masterWrite,
    master_read: body.masterRead,
  };
};

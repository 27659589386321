export const getFileUrl = () => {
  if (import.meta.env.VITE_APP_ENV === "local") {
    return import.meta.env.VITE_FILE_URL_LOCAL;
  } else {
    if (import.meta.env.VITE_APP_ENV === "production") {
      return import.meta.env.VITE_FILE_URL_PROD;
    } else {
      return import.meta.env.VITE_FILE_URL_STAGING;
    }
  }
};

import { useState } from "react";
import {
  Button,
  TextField,
  SelectChangeEvent,
  Box,
  FormControl,
  Switch,
  Typography,
} from "@mui/material";
import { Flash } from "components/atoms/flash";
import { CustomFormLabel } from "components/molecules/custom-form-label";
import { CustomModal } from "components/molecules/custom-modal";
import { attachmentTypeRepository } from "data-access/repositories/attachment_type/attachment_type.repository";
import { useAppDispatch } from "store/hooks";
import { mainOperations } from "store/main/operations";
import useSWR from "swr";

interface Props {
  open: (e: boolean) => void;
  isOpen: boolean;
}

interface State {
  name: string;
  displayOrderPosition: number;
  isSharedInOwnHub: boolean;
  isSharedWithOtherHubs: boolean;
}

export const AttachmentTypeFormModal = (props: Props) => {
  const dispatch = useAppDispatch();

  const [state, setState] = useState<State>({
    name: "",
    displayOrderPosition: 0,
    isSharedInOwnHub: true,
    isSharedWithOtherHubs: false,
  });
  const [errorMessage, setErrorMessage] = useState<string>("");

  const { mutate: attachmentTypesMutate } = useSWR("/api/v1/attachment_types", () =>
    attachmentTypeRepository.index(),
  );

  const handleChange = (
    e:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent<number | string | boolean>,
  ) => {
    switch (e.target.name) {
      case "name":
        return setState({ ...state, name: e.target.value as string });
      case "isSharedInOwnHub":
        return setState({ ...state, isSharedInOwnHub: (e.target as HTMLInputElement).checked });
      case "isSharedWithOtherHubs":
        return setState({
          ...state,
          isSharedWithOtherHubs: (e.target as HTMLInputElement).checked,
        });
    }
  };

  const handleClick = async () => {
    dispatch(mainOperations.updateIsLoading(true));
    try {
      await attachmentTypeRepository.create(state);
      attachmentTypesMutate();
      handleClose();
      dispatch(mainOperations.updateSuccessMessage("添付ファイルタイプを作成しました"));
    } catch (error) {
      setErrorMessage(error.response.data.message);
    } finally {
      dispatch(mainOperations.updateIsLoading(false));
    }
  };

  const handleClose = () => {
    props.open(false);
    setState({
      name: "",
      displayOrderPosition: 0,
      isSharedInOwnHub: true,
      isSharedWithOtherHubs: false,
    });
  };

  const isValid = (): boolean => {
    if (!state.name) return false;
    return true;
  };

  return (
    <CustomModal
      open={props.isOpen}
      onClose={() => handleClose()}
      title="添付ファイルタイプの新規作成"
      maxWidth="xs"
      footer={
        <>
          <Button variant="outlined" onClick={handleClose} sx={{ width: "10rem" }}>
            キャンセル
          </Button>
          <Button
            disabled={!isValid()}
            onClick={handleClick}
            variant="contained"
            sx={{ width: "10rem", ml: "1rem" }}
          >
            保存
          </Button>
        </>
      }
    >
      <Box>
        <Box sx={{ mt: "1rem" }}>
          <CustomFormLabel labelName="名前" required requiredSize="14px" />
          <TextField
            id="name"
            name="name"
            sx={{ width: "100%" }}
            value={state.name}
            onChange={handleChange}
          />
        </Box>
        <Box sx={{ mt: "2rem" }}>
          <CustomFormLabel labelName="共有範囲" />
          <FormControl sx={{ width: "100%" }}>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              <Typography component="span" fontSize="14px">
                自社の全員に共有
              </Typography>
              <Switch
                name="isSharedInOwnHub"
                onChange={handleChange}
                checked={state.isSharedInOwnHub}
              />
            </div>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              <Typography component="span" fontSize="14px">
                すべてのHubに共有
              </Typography>
              <Switch
                name="isSharedWithOtherHubs"
                onChange={handleChange}
                checked={state.isSharedWithOtherHubs}
              />
            </div>
          </FormControl>
        </Box>
      </Box>

      {errorMessage && (
        <Flash title="エラー" severity="error" message={errorMessage} sx={{ mt: "1rem" }} />
      )}
    </CustomModal>
  );
};

import { useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import { ArrowForwardIos } from "@mui/icons-material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { Box, Button, IconButton, Popover, Typography } from "@mui/material";
import { LoadingOverlay } from "components/loadingOverlay";
import ja from "date-fns/locale/ja";
import { attendanceEmployeeRepository } from "features/attendance/api/daily_record/attendance.employee.repository";
import { useAppSelector } from "store/hooks";
import { selectMain } from "store/main/slice";
import useSWR from "swr";
import { convertToCalendarStartDay } from "utils/convertToCalendarStartDay";
import { formatDateUtil } from "utils/formatDateUtil";
import { Card } from "./card";
registerLocale("ja", { ...ja, options: { ...ja.options, weekStartsOn: 1 } });

export const Daily = () => {
  const state = useAppSelector(selectMain);
  const [date, setDate] = useState<Date>(new Date());
  const [isDatePickerOpen, setIsDatePickerOpen] = useState<boolean>(false);

  const { data, isValidating } = useSWR(
    `/api/v1/attendances/daily_records/${date.toString()}/employees`,
    () => attendanceEmployeeRepository.index(date),
    { revalidateOnFocus: false },
  );

  const decrementDate = () => {
    setDate(new Date(date.setDate(date.getDate() - 1)));
  };

  const incrementDate = () => {
    setDate(new Date(date.setDate(date.getDate() + 1)));
  };

  return (
    <>
      <LoadingOverlay isLoading={isValidating} />

      <Popover
        open={isDatePickerOpen}
        onClose={() => setIsDatePickerOpen(false)}
        sx={{ position: "fixed", left: "550px", top: "100px" }}
      >
        <DatePicker
          locale="ja"
          selected={date}
          onChange={(date: Date | null) => {
            if (date) {
              setDate(date);
              setIsDatePickerOpen(false);
            }
          }}
          inline
          calendarStartDay={convertToCalendarStartDay(
            state.personalSetting.calendar_start_day_of_week,
          )}
        />
      </Popover>

      <div style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
        <IconButton onClick={decrementDate}>
          <ArrowBackIosNewIcon fontSize="small" />
        </IconButton>
        <Box sx={{ m: "0 8px", cursor: "default" }}>
          <Typography sx={{ fontSize: "20px", fontWeight: "bold", userSelect: "none" }}>
            {formatDateUtil(date, "yyyy年MM月dd日")}
          </Typography>
        </Box>

        <IconButton onClick={incrementDate}>
          <ArrowForwardIos fontSize="small" />
        </IconButton>
        <Button
          onClick={() => setDate(new Date())}
          variant="outlined"
          sx={{ height: "36px", mr: "8px" }}
        >
          今日
        </Button>
        <IconButton onClick={() => setIsDatePickerOpen(!isDatePickerOpen)}>
          <CalendarTodayIcon color="primary" />
        </IconButton>
      </div>

      {data &&
        data.map((attendanceEmployee, index) => (
          <Card key={index} currentDate={date} attendanceEmployee={attendanceEmployee} />
        ))}
    </>
  );
};

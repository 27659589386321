import { UserId } from "data-access/repositories/account/account.dto";
import { Brand } from "data-access/repositories/brand";
import { ConnectType } from "./connect.dto";
import { HubInfoData } from "../hub-info/hub_info.dto";

export type ConnectApprovableId = Brand<number, "ConnectApprovableId">;

export interface ConnectApprovableCollection {
  id: ConnectApprovableId;
  ownHubRequestedConnectType: ConnectType;
  partnerHubRequestedConnectType: ConnectType;
  isNew: boolean;
  partnerHub: HubInfoData;
}

export interface ConnectApprovable {
  id: ConnectApprovableId;
  ownHubOriginalConnectType: ConnectType;
  partnerHubOriginalConnectType: ConnectType;
  ownHubRequestedConnectType: ConnectType;
  partnerHubRequestedConnectType: ConnectType;
  isNew: boolean;
  partnerHub: HubInfoData;
  ownHubInvitableAccounts: ConnectPermissionAccount[];
  ownHubScheduleViewableAccounts: ConnectPermissionAccount[];
  partnerHubInvitableAccounts: ConnectPermissionAccount[];
  partnerHubScheduleViewableAccounts: ConnectPermissionAccount[];
}

export interface ConnectApproveRequest {
  addInvitableAccountUserIds: UserId[];
  removeInvitableAccountUserIds: UserId[];
  addScheduleViewableAccountUserIds: UserId[];
  removeScheduleViewableAccountUserIds: UserId[];
}

interface ConnectPermissionAccount {
  userId: UserId;
  name: string;
  requestType: "unchanged" | "add" | "remove";
}

export const initialConnectApproveRequest: ConnectApproveRequest = {
  addInvitableAccountUserIds: [],
  removeInvitableAccountUserIds: [],
  addScheduleViewableAccountUserIds: [],
  removeScheduleViewableAccountUserIds: [],
};

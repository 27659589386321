import { ApiClient } from "data-access/ApiClient";
import { convertKeysToCamelCase } from "utils/convertObjectKeyCase";
import {
  RecurrenceRuleOptionsRequest,
  RecurrenceRuleOptionsResponse,
} from "./recurrence_rule_options.dto";

export const RecurrenceRuleOptionsRepository = {
  index(body: RecurrenceRuleOptionsRequest): Promise<RecurrenceRuleOptionsResponse> {
    const params = {
      schedule_start_time: body.scheduleStartTime,
      schedule_id: body.scheduleId,
    };
    return ApiClient.get("/api/v1/recurrence_rule_options", { params }).then((res) => {
      return convertKeysToCamelCase(res.data);
    });
  },
};

import { convertKeysToCamelCase, convertKeysToSnakeCase } from "utils/convertObjectKeyCase";
import { GroupRequest, GroupId, Group } from "./group.dto";
import { ApiClient } from "../../ApiClient";

export const groupRepository = {
  index(): Promise<Group[]> {
    return ApiClient.get("/api/v1/groups").then((res) => convertKeysToCamelCase(res.data));
  },
  create(body: GroupRequest): Promise<void> {
    const config = { headers: { "content-type": "application/json" } };
    return ApiClient.post("/api/v1/groups", convertKeysToSnakeCase(body), config).then(
      (res) => res.data,
    );
  },
  update(id: GroupId, body: GroupRequest): Promise<void> {
    const config = { headers: { "content-type": "application/json" } };
    return ApiClient.put(`/api/v1/groups/${id}`, convertKeysToSnakeCase(body), config).then(
      (res) => res.data,
    );
  },
  destroy(id: GroupId): Promise<void> {
    return ApiClient.delete(`/api/v1/groups/${id}`).then((res) => res.data);
  },
};

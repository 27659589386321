import { useState } from "react";
import { SignUpVerifyResponse } from "data-access/repositories/sign_up/sign_up.dto";
import { First } from "./first";
import { Profile } from "./profile";

export type PageState = "first" | "profile";

interface Props {
  data: SignUpVerifyResponse;
  token: string;
}
export const ExistingHub = (props: Props) => {
  const [pageState, setPageState] = useState<PageState>("first");

  return (
    <>
      {pageState === "first" && <First data={props.data} setPageState={setPageState} />}
      {pageState === "profile" && (
        <Profile data={props.data} token={props.token} setPageState={setPageState} />
      )}
    </>
  );
};

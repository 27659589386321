import { Box, Stack, Typography } from "@mui/material";
import { styled } from "@mui/styles";
import { Project, ProjectCollection } from "data-access/repositories/project/project.dto";
import { SourceProject } from "data-access/repositories/project/source-project/source_project.dto";
import { theme } from "extensions/theme";
import { UnlinkButton } from "../unlink-button";

interface Props {
  project: ProjectCollection | Project | SourceProject;
  onClick?: () => void;
  onClickUnlink?: () => void;
}

const StyledLink = styled("a")({
  fontWeight: "500",
  color: theme.palette.primary.main,
  textDecoration: "none",
  "&:hover": {
    textDecoration: "underline",
  },
  flex: 1,
});

export const ProjectBlock = (props: Props) => {
  return (
    <Stack
      sx={{
        cursor: props.onClick ? "pointer" : "default",
        border: `1px solid ${theme.palette.primary.main}`,
        borderRadius: "4px",
        backgroundColor: theme.palette.customPrimary[100],
        p: "12px",
      }}
      onClick={props.onClick || undefined}
      spacing="16px"
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
        }}
      >
        <Typography sx={{ flex: 1, color: theme.palette.primary.main, fontWeight: "500" }}>
          {props.project.code}：{props.project.name}
        </Typography>
        {props.onClickUnlink && <UnlinkButton onUnlink={props.onClickUnlink} />}
      </Box>

      <div style={{ display: "flex", alignItems: "center" }}>
        <Typography sx={{ fontSize: "14px", fontWeight: "500", mr: "12px" }}>住所:</Typography>
        <StyledLink
          href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
            props.project.address,
          )}`}
          target="_blank"
          rel="noreferrer"
          onClick={(e) => e.stopPropagation()}
        >{`${props.project.address}${props.project.addressSecond}`}</StyledLink>
      </div>

      <div style={{ display: "flex", alignItems: "center" }}>
        <Typography sx={{ fontSize: "14px", fontWeight: "500", mr: "12px" }}>
          現場責任者:
        </Typography>
        <Typography sx={{ fontWeight: "bold" }}>{props.project.manager?.name}</Typography>
      </div>

      <Box sx={{ backgroundColor: theme.palette.grayScale[0], p: "12px", width: "100%" }}>
        <Typography
          sx={{
            fontSize: "14px",
            color: theme.palette.grayScale[900],
            mb: "12px",
            fontWeight: "500",
          }}
        >
          概要・メモ
        </Typography>
        <Typography sx={{ fontSize: "12px" }}>{props.project.note || "記入なし"}</Typography>
      </Box>
    </Stack>
  );
};

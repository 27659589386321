import React from "react";
import { Tooltip, Typography } from "@mui/material";

interface Props {
  value: string | null | undefined;
}

export const MultilineTooltip: React.FC<Props> = ({ value }) => {
  if (!value) {
    return <Typography sx={{ mr: "10px" }}>-</Typography>;
  }

  return (
    <Tooltip
      title={value.split("\n").map((str: string, index: number) => (
        <React.Fragment key={index}>
          <Typography fontSize="11px">
            {str}
            <br />
          </Typography>
        </React.Fragment>
      ))}
      placement="top"
      arrow
    >
      <Typography
        sx={{
          mr: "10px",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {value}
      </Typography>
    </Tooltip>
  );
};

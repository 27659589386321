import { ElementRef, useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { Close, EventOutlined } from "@mui/icons-material";
import Person2OutlinedIcon from "@mui/icons-material/Person2Outlined";
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Popper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Avatar } from "components/avatar";
import { CustomDivider } from "components/divider";
import { ConnectTypeLabel } from "components/label/connect-type-label";
import { SelectedMemberLabel } from "components/label/selected-member-label";
import { CustomFormLabel } from "components/molecules/custom-form-label";
import { AsyncConfirmDialog } from "components/templates/async-confirm-dialog";
import { UserId } from "data-access/repositories/account/account.dto";
import { connectApprovableRepository } from "features/hub-settings/api/connect/connect_approvable.repository";
import {
  ConnectApproveRequest,
  initialConnectApproveRequest,
} from "features/hub-settings/types/connect/connect_approvable.dto";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { mainOperations } from "store/main/operations";
import { selectMain } from "store/main/slice";
import useSWR, { mutate } from "swr";
import { API_PATHS } from "utils/apiPaths";
import { handleReduxError } from "utils/errorHandler";
import { styles } from "./styles";

interface FormState {
  initialInvitableAccountUserIds: UserId[];
  initialScheduleViewableAccountUserIds: UserId[];
  currentInvitableAccountUserIds: UserId[];
  currentScheduleViewableAccountUserIds: UserId[];
}

const initialFormState: FormState = {
  initialInvitableAccountUserIds: [],
  initialScheduleViewableAccountUserIds: [],
  currentInvitableAccountUserIds: [],
  currentScheduleViewableAccountUserIds: [],
};

interface Props {
  isOpen: boolean;
  onClose: () => void;
}
export const ApprovableDetailModal = (props: Props) => {
  const mainState = useAppSelector(selectMain);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const id = location?.state?.id;

  const { data } = useSWR(
    id ? API_PATHS.getConnectRequestApprovable(id) : null,
    () => connectApprovableRepository.show(id),
    {
      revalidateOnFocus: false,
    },
  );

  const [formState, setFormState] = useState<FormState>(initialFormState);

  useEffect(() => {
    if (!data) return;

    const initInvitableAccountUserIds = data.ownHubInvitableAccounts.map(
      (account) => account.userId,
    );
    const initScheduleViewableAccountUserIds = data.ownHubScheduleViewableAccounts.map(
      (account) => account.userId,
    );

    setFormState({
      ...initialConnectApproveRequest,
      initialInvitableAccountUserIds: initInvitableAccountUserIds,
      initialScheduleViewableAccountUserIds: initScheduleViewableAccountUserIds,
      currentInvitableAccountUserIds: initInvitableAccountUserIds,
      currentScheduleViewableAccountUserIds: initScheduleViewableAccountUserIds,
    });
  }, [data]);

  const filteredInvitableUsers = useMemo((): {
    selectedAccounts: { id: UserId; name: string }[];
    unselectedHubUsers: { id: UserId; name: string }[];
  } => {
    const activeAccounts = mainState.accounts.filter(
      (account) => !account.isDeactivate && account.hasEmail,
    );
    const selectedAccounts = activeAccounts
      .filter((hubUser) => formState.currentInvitableAccountUserIds.includes(hubUser.userId))
      .map((hubUser) => ({ id: hubUser.userId, name: hubUser.name }));
    const unselectedHubUsers = activeAccounts
      .filter((hubUser) => !formState.currentInvitableAccountUserIds.includes(hubUser.userId))
      .map((hubUser) => ({ id: hubUser.userId, name: hubUser.name }));
    return {
      selectedAccounts,
      unselectedHubUsers,
    };
  }, [formState.currentInvitableAccountUserIds]);

  const filteredScheduleViewableUsers = useMemo((): {
    selectedAccounts: { id: UserId; name: string }[];
    unselectedHubUsers: { id: UserId; name: string }[];
  } => {
    const selectedAccounts = filteredInvitableUsers.selectedAccounts
      .filter((hubUser) => formState.currentScheduleViewableAccountUserIds.includes(hubUser.id))
      .map((hubUser) => ({ id: hubUser.id, name: hubUser.name }));
    const unselectedHubUsers = filteredInvitableUsers.selectedAccounts
      .filter((hubUser) => !formState.currentScheduleViewableAccountUserIds.includes(hubUser.id))
      .map((hubUser) => ({ id: hubUser.id, name: hubUser.name }));
    return {
      selectedAccounts: selectedAccounts,
      unselectedHubUsers,
    };
  }, [formState.currentScheduleViewableAccountUserIds, filteredInvitableUsers]);

  const handleSelectInvitableUser = (selectedHubUserIds: UserId[]) => {
    setFormState((prev) => ({
      ...prev,
      currentInvitableAccountUserIds: selectedHubUserIds,
      currentScheduleViewableAccountUserIds: prev.currentScheduleViewableAccountUserIds.filter(
        (id) => selectedHubUserIds.includes(id),
      ),
    }));
  };

  const handleSelectScheduleViewableUser = (selectedHubUserIds: UserId[]) => {
    setFormState((prev) => ({
      ...prev,
      currentScheduleViewableAccountUserIds: selectedHubUserIds,
    }));
  };

  const handleClearInvitableUser = (hubUserId: UserId) => {
    setFormState((prev) => ({
      ...prev,
      currentInvitableAccountUserIds: prev.currentInvitableAccountUserIds.filter(
        (id) => id !== hubUserId,
      ),
      currentScheduleViewableAccountUserIds: prev.currentScheduleViewableAccountUserIds.filter(
        (id) => id !== hubUserId,
      ),
    }));
  };

  const handleClearScheduleViewableUser = (hubUserId: UserId) => {
    setFormState((prev) => ({
      ...prev,
      currentScheduleViewableAccountUserIds: prev.currentScheduleViewableAccountUserIds.filter(
        (id) => id !== hubUserId,
      ),
    }));
  };

  const rejectConfirmRef = useRef<ElementRef<typeof AsyncConfirmDialog>>(null);
  const handleReject = async () => {
    if (!rejectConfirmRef.current) return;
    const res = await rejectConfirmRef.current.confirm();
    if (!res) return;
    dispatch(mainOperations.updateIsLoading(true));
    try {
      await connectApprovableRepository.reject(id);
      dispatch(mainOperations.updateSuccessMessage("申請を拒否しました"));
      handleClose();
      mutate(API_PATHS.getConnectRequestsApprovable());
    } catch (error) {
      handleReduxError(error, dispatch, "申請の拒否に失敗しました");
    } finally {
      dispatch(mainOperations.updateIsLoading(false));
    }
  };

  const handleApprove = async () => {
    dispatch(mainOperations.updateIsLoading(true));
    try {
      const addInvitableAccountUserIds = formState.currentInvitableAccountUserIds.filter(
        (id) => !formState.initialInvitableAccountUserIds.includes(id),
      );
      const removeInvitableAccountUserIds = formState.initialInvitableAccountUserIds.filter(
        (id) => !formState.currentInvitableAccountUserIds.includes(id),
      );
      const addScheduleViewableAccountUserIds =
        formState.currentScheduleViewableAccountUserIds.filter(
          (id) => !formState.initialScheduleViewableAccountUserIds.includes(id),
        );
      const removeScheduleViewableAccountUserIds =
        formState.initialScheduleViewableAccountUserIds.filter(
          (id) => !formState.currentScheduleViewableAccountUserIds.includes(id),
        );

      const requestData: ConnectApproveRequest = {
        addInvitableAccountUserIds: addInvitableAccountUserIds,
        removeInvitableAccountUserIds: removeInvitableAccountUserIds,
        addScheduleViewableAccountUserIds: addScheduleViewableAccountUserIds,
        removeScheduleViewableAccountUserIds: removeScheduleViewableAccountUserIds,
      };

      await connectApprovableRepository.approve(id, requestData);
      dispatch(mainOperations.updateSuccessMessage("申請を承認しました"));
      handleClose();
      mutate(API_PATHS.getConnectRequestsApprovable());
    } catch (error) {
      handleReduxError(error, dispatch, "申請の承認に失敗しました");
    } finally {
      dispatch(mainOperations.updateIsLoading(false));
    }
  };

  const handleClose = () => {
    props.onClose();
  };

  const isApproveDisabled = useMemo((): boolean => {
    if (mainState.isLoading || !data) return true;
    if (data.ownHubRequestedConnectType.value === "order") return false;

    return filteredInvitableUsers.selectedAccounts.length === 0;
  }, [filteredInvitableUsers, data]);

  if (!data) return null;

  return (
    <>
      <AsyncConfirmDialog
        ref={rejectConfirmRef}
        title="コネクト申請を拒否しますか？"
        yesButtonText="拒否"
      />

      <Dialog open={props.isOpen} onClose={handleClose} fullWidth>
        <DialogTitle sx={styles.title}>
          {data.partnerHub.name}からの申請
          <IconButton onClick={handleClose} sx={styles.closeButton}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          {data.ownHubOriginalConnectType.value !== data.ownHubRequestedConnectType.value && (
            <Alert
              severity="info"
              sx={{ mb: "12px", "&.MuiPaper-rounded": { alignItems: "center" } }}
            >
              自社の立場が「{data.ownHubOriginalConnectType.valueI18n}」から「
              {data.ownHubRequestedConnectType.valueI18n}」に変更されました。
            </Alert>
          )}

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "12px",
              mb: "32px",
            }}
          >
            <Avatar
              url={data.partnerHub.logoUrl}
              name={data.partnerHub.name}
              bgColor={data.partnerHub.logoBackgroundColorNumber}
              size="small"
            />
            <Typography sx={{ fontWeight: "bold" }}>自社が</Typography>
            <ConnectTypeLabel
              name={data.ownHubRequestedConnectType.valueI18n}
              sx={{ height: "40px", width: "72px" }}
            />
          </Box>
          {data.ownHubRequestedConnectType.value === "order_and_receive" && (
            <CustomDivider title="自社が「受注」するとき" color="secondary" />
          )}
          {data.ownHubRequestedConnectType.value !== "order" && (
            <Stack spacing={3} mb={3}>
              <Box sx={{ display: "flex", alignItems: "start", gap: "16px" }}>
                <Person2OutlinedIcon sx={{ mt: "4px" }} />
                <Box sx={{ width: "100%" }}>
                  <CustomFormLabel
                    labelName="相手の案件と予定に招待を許可するメンバー"
                    required
                    labelWeight="bold"
                    small
                    requiredSize="12px"
                  />
                  <Autocomplete
                    options={filteredInvitableUsers.unselectedHubUsers}
                    value={filteredInvitableUsers.selectedAccounts}
                    onChange={(_, selectUsers) =>
                      handleSelectInvitableUser(selectUsers.map((selectUser) => selectUser.id))
                    }
                    renderInput={(params) => (
                      <TextField {...params} variant="standard" label="メンバーを選択" />
                    )}
                    getOptionLabel={(option) => option.name}
                    multiple
                    disableCloseOnSelect
                    disableClearable
                    renderTags={() => null}
                    slots={{
                      popper: (props) => (
                        <Popper {...props} placement="right-start" sx={{ maxWidth: "320px" }} />
                      ),
                    }}
                  />
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: "8px", mt: "12px" }}>
                    {filteredInvitableUsers.selectedAccounts.map((hubUser, index) => (
                      <SelectedMemberLabel
                        key={index}
                        userId={hubUser.id}
                        userName={hubUser.name}
                        onClear={handleClearInvitableUser}
                      />
                    ))}
                  </Box>
                </Box>
              </Box>
              <Box sx={{ display: "flex", alignItems: "start", gap: "16px" }}>
                <EventOutlined sx={{ mt: "4px" }} />
                <Box sx={{ width: "100%" }}>
                  <CustomFormLabel
                    labelName="相手のスケジュールに予定の時間帯を表示するメンバー"
                    labelWeight="bold"
                    small
                    requiredSize="12px"
                  />
                  <Autocomplete
                    options={filteredScheduleViewableUsers.unselectedHubUsers}
                    value={filteredScheduleViewableUsers.selectedAccounts}
                    onChange={(_, selectUsers) =>
                      handleSelectScheduleViewableUser(
                        selectUsers.map((selectUser) => selectUser.id),
                      )
                    }
                    renderInput={(params) => (
                      <TextField {...params} variant="standard" label="メンバーを選択" />
                    )}
                    getOptionLabel={(option) => option.name}
                    multiple
                    disableCloseOnSelect
                    disableClearable
                    renderTags={() => null}
                    slots={{
                      popper: (props) => (
                        <Popper {...props} placement="right-start" sx={{ maxWidth: "320px" }} />
                      ),
                    }}
                  />
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: "8px", mt: "12px" }}>
                    {filteredScheduleViewableUsers.selectedAccounts.map((hubUser, index) => (
                      <SelectedMemberLabel
                        key={index}
                        userId={hubUser.id}
                        userName={hubUser.name}
                        onClear={handleClearScheduleViewableUser}
                      />
                    ))}
                  </Box>
                </Box>
              </Box>
            </Stack>
          )}

          {data.ownHubRequestedConnectType.value === "order_and_receive" && (
            <CustomDivider title="自社が「発注」するとき" color="info" />
          )}
          {data.ownHubRequestedConnectType.value !== "receive" && (
            <Stack spacing={3} mb={3}>
              <Box sx={{ display: "flex", alignItems: "start", gap: "16px" }}>
                <Person2OutlinedIcon sx={{ mt: "4px" }} />
                <Box sx={{ width: "100%" }}>
                  <CustomFormLabel
                    labelName="自社の案件と予定に招待が許可されているメンバー"
                    labelWeight="bold"
                    small
                    requiredSize="12px"
                  />
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: "8px", mt: "12px" }}>
                    {data.partnerHubInvitableAccounts.map((partnerHubUser, index) => (
                      <SelectedMemberLabel
                        key={index}
                        userId={partnerHubUser.userId}
                        userName={partnerHubUser.name}
                        requestType={partnerHubUser.requestType}
                      />
                    ))}
                  </Box>
                </Box>
              </Box>
              <Box sx={{ display: "flex", alignItems: "start", gap: "16px" }}>
                <EventOutlined sx={{ mt: "4px" }} />
                <Box sx={{ width: "100%" }}>
                  <CustomFormLabel
                    labelName="自社のスケジュールに予定の時間帯を表示するメンバー"
                    labelWeight="bold"
                    small
                    requiredSize="12px"
                  />
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: "8px", mt: "12px" }}>
                    {data.partnerHubScheduleViewableAccounts.map((partnerHubUser, index) => (
                      <SelectedMemberLabel
                        key={index}
                        userId={partnerHubUser.userId}
                        userName={partnerHubUser.name}
                        requestType={partnerHubUser.requestType}
                      />
                    ))}
                  </Box>
                </Box>
              </Box>
            </Stack>
          )}
        </DialogContent>

        <Box sx={{ display: "flex", justifyContent: "right", p: "16px", gap: "16px" }}>
          <Button
            variant="contained"
            color="error"
            onClick={handleReject}
            sx={{ ...styles.button }}
          >
            拒否
          </Button>
          <Button
            variant="contained"
            onClick={handleApprove}
            sx={{ ...styles.button }}
            disabled={isApproveDisabled}
          >
            承認
          </Button>
        </Box>
      </Dialog>
    </>
  );
};

import { convertKeysToCamelCase } from "utils/convertObjectKeyCase";
import { ApiClient } from "../../../ApiClient";
import { ActivityIndexResponse } from "../../activity/activity.dto";
import { BillingId } from "../billing.dto";

export const billingActivityRepository = {
  index(id: BillingId, limit: number): Promise<ActivityIndexResponse> {
    return ApiClient.get(`/api/v1/billings/${id}/activities?limit=${limit}`).then((res) =>
      convertKeysToCamelCase(res.data),
    );
  },
};

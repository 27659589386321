import { convertKeysToCamelCase, convertKeysToSnakeCase } from "utils/convertObjectKeyCase";
import {
  ProjectStatusType,
  ProjectStatusTypeId,
  ProjectStatusTypeRequest,
} from "./project_status_type.dto";
import { ApiClient } from "../../ApiClient";

export const projectStatusTypeRepository = {
  index(): Promise<ProjectStatusType[]> {
    return ApiClient.get("/api/v2/project_status_types").then((res) =>
      convertKeysToCamelCase(res.data),
    );
  },
  create(body: ProjectStatusTypeRequest): Promise<void> {
    const config = { headers: { "content-type": "application/json" } };
    return ApiClient.post(
      "/api/v1/project_status_types",
      convertKeysToSnakeCase(body),
      config,
    ).then((res) => res.data);
  },
  update(id: ProjectStatusTypeId, body: ProjectStatusTypeRequest): Promise<void> {
    const config = { headers: { "content-type": "application/json" } };
    return ApiClient.put(
      `/api/v1/project_status_types/${id}`,
      convertKeysToSnakeCase(body),
      config,
    ).then((res) => res.data);
  },
  destroy(id: ProjectStatusTypeId): Promise<void> {
    return ApiClient.delete(`/api/v1/project_status_types/${id}`).then((res) => res.data);
  },
};

import React from "react";
import { ErrorOutline } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Popper,
  PopperPlacementType,
  PopperProps,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import { Avatar } from "components/avatar";
import { GroupLabel } from "components/label/group-label";
import { InvitableAccountForSchedule, UserId } from "data-access/repositories/account/account.dto";
import { theme } from "extensions/theme";
import { useAutocompleteScroll } from "hooks/useAutocompleteScroll";
import { useAppSelector } from "store/hooks";
import { selectMain } from "store/main/slice";
import { getOptionStyles, styles } from "./styles";

interface Props {
  allOptions: any[];
  selectedAccounts: InvitableAccountForSchedule[];
  selectedSiteManagerId: UserId;
  inputValue: string;
  groupByFunction: (option: InvitableAccountForSchedule) => string;
  filterOptions: (options: InvitableAccountForSchedule[]) => InvitableAccountForSchedule[];
  setInputValue: (value: string) => void;
  onChangeUsers: (e: any, value: any) => void;
  handleClear: (selectedAccount: any) => void;
  popperPlacement: PopperPlacementType;
  disableOtherHubSelection?: boolean;
  scrollKey: string;
}

export const SelectedMembers = (props: Props) => {
  const mainState = useAppSelector(selectMain);
  const currentHubUuid = mainState.hub.uuid;
  // スクロール位置を保持するためのフック
  const { getListboxProps, restoreScrollPosition } = useAutocompleteScroll(
    props.scrollKey || "members-selection",
  );

  // メンバーが選択不可かどうかを判定する関数
  const isOptionDisabled = (option: InvitableAccountForSchedule): boolean => {
    return option.isScheduleOverlapped && option.scheduleOverlappingType.value === "forbidden";
  };

  // 入力値変更時のハンドラ
  const handleInputChange = (_: any, newInputValue: string) => {
    props.setInputValue(newInputValue);
  };

  // メンバー選択変更時のハンドラ
  const handleChange = (_: any, value: any) => {
    props.onChangeUsers(_, value);

    // スクロール位置を復元
    restoreScrollPosition();

    // MUI Autocompleteは選択時に入力値をクリアする仕様のため、内部状態の更新後に入力値を再設定
    setTimeout(() => {
      if (props.inputValue) {
        props.setInputValue(props.inputValue);
      }
    }, 10);
  };

  // オプショングループのレンダリング
  const renderOptionGroup = (params: any) => {
    // 検索結果なしのケース
    if (params.group === "検索結果") {
      const children = React.Children.toArray(params.children);
      if (children.length === 0) {
        return (
          <Box key={`${params.group}-empty`}>
            <Typography sx={styles.optionGroupTitle}>{params.group}</Typography>
            <Typography sx={{ px: 2, py: 1 }}>検索結果がありません</Typography>
          </Box>
        );
      }
    }

    return (
      <Box key={params.group}>
        <Typography sx={styles.optionGroupTitle}>{params.group}</Typography>
        {params.children}
      </Box>
    );
  };

  // 各オプションのレンダリング
  const renderOption = (
    optionProps: any,
    option: InvitableAccountForSchedule,
    { selected }: { selected: boolean },
  ) => {
    if (option.name === "__NO_RESULT__") return null;

    const isSiteManager = option.userId === props.selectedSiteManagerId;
    const isOtherHub = option.hub.uuid !== currentHubUuid;

    return (
      <Box
        component="li"
        {...optionProps}
        key={option.userId}
        sx={getOptionStyles({ option, selected, isSiteManager })}
      >
        <Box sx={{ display: "flex", alignItems: "center", width: "120px", mr: "16px" }}>
          <Typography
            sx={{
              flex: 1,
              maxWidth: "180px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {option.name}
          </Typography>
        </Box>
        {isOtherHub && (
          <>
            <Avatar
              url={option.hub.logoUrl}
              bgColor={option.hub.logoBackgroundColorNumber}
              name={option.hub.name}
              size="small"
            />
            <Typography sx={{ mx: 0.5, fontSize: "12px", color: theme.palette.grayScale[500] }}>
              {option.hub.name}
            </Typography>
          </>
        )}
        {!isOtherHub && (
          <GroupLabel
            name={option.group ? option.group.name : ""}
            colorNumber={option.group ? option.group.colorNumber : ""}
            sx={{ mr: "16px" }}
          />
        )}
        {renderScheduleOverlapIndicator(option)}
      </Box>
    );
  };

  // スケジュール重複表示のレンダリング
  const renderScheduleOverlapIndicator = (option: InvitableAccountForSchedule) => {
    if (!option.isScheduleOverlapped) return null;

    const overlapColor =
      option.scheduleOverlappingType.value === "warning"
        ? theme.palette.mustard[500]
        : option.scheduleOverlappingType.value === "forbidden"
          ? theme.palette.error.main
          : "";

    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          color: overlapColor,
        }}
      >
        {option.scheduleOverlappingType.value !== "can" && <ErrorOutline sx={{ fontSize: 16 }} />}
        <Typography variant="caption">予定あり</Typography>
      </Box>
    );
  };

  return (
    <>
      <Autocomplete
        PopperComponent={(popperProps) => (
          <CustomPopper {...popperProps} popperPlacement={props.popperPlacement} />
        )}
        options={props.allOptions}
        value={props.selectedAccounts}
        inputValue={props.inputValue}
        onInputChange={handleInputChange}
        onChange={handleChange}
        renderInput={(params) => (
          <TextField {...params} label="メンバーを選択" variant="standard" />
        )}
        getOptionLabel={(option) => option.name}
        multiple
        disableCloseOnSelect
        disableClearable
        renderTags={() => null}
        groupBy={props.groupByFunction}
        filterOptions={props.filterOptions}
        getOptionDisabled={isOptionDisabled}
        renderGroup={renderOptionGroup}
        renderOption={renderOption}
        ListboxProps={getListboxProps()}
      />
    </>
  );
};

// カスタムポッパーコンポーネントの型定義
interface CustomPopperProps extends PopperProps {
  popperPlacement: PopperPlacementType;
}

// カスタムポッパーコンポーネント
const CustomPopper = styled((props: CustomPopperProps) => {
  const { popperPlacement, ...otherProps } = props;
  return (
    <Popper
      {...otherProps}
      placement={popperPlacement}
      style={{ width: "100%", maxWidth: "420px" }}
    />
  );
})({
  "& .MuiAutocomplete-paper": {
    marginLeft: "8px",
  },
});

import { createBrowserRouter } from "react-router-dom";
import SignUp from "pages/authentication/sign-up";
import { ErrorPage404 } from "pages/error/error-page-404";
import { ShareProjectPhotos } from "pages/share-project-photos";
import { ShareWorkReport } from "pages/share-work-report";

export const noAuthenticationRouter = createBrowserRouter([
  {
    path: "/photos/:id",
    element: <ShareProjectPhotos />,
    errorElement: <ErrorPage404 />,
  },
  {
    path: "/work-reports/:id/share",
    element: <ShareWorkReport />,
    errorElement: <ErrorPage404 />,
  },
  {
    path: "/signup/verify",
    element: <SignUp />,
    errorElement: <ErrorPage404 />,
  },
]);

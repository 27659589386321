import { Box, SelectChangeEvent } from "@mui/material";
import { accountNoticeSettingRepository } from "features/user-settings/api/notice-setting/notice_setting.repository";
import { AccountNoticeSetting } from "features/user-settings/types/notice-setting/notice_setting.dto";
import { useAppDispatch } from "store/hooks";
import { mainOperations } from "store/main/operations";
import { mutate } from "swr";
import { API_PATHS } from "utils/apiPaths";
import { handleReduxError } from "utils/errorHandler";
import { NotificationItem } from "../common/NotificationItem";

interface Props {
  data?: AccountNoticeSetting;
}

export const ConnectManagement = (props: Props) => {
  const dispatch = useAppDispatch();

  if (!props.data) return null;

  const handleChangeSubscriberType = async (
    event: SelectChangeEvent<string>,
    field: keyof AccountNoticeSetting,
  ) => {
    dispatch(mainOperations.updateIsLoading(true));
    try {
      await accountNoticeSettingRepository.update({
        [field]: event.target.value,
      });
      mutate(API_PATHS.getAccountNoticeSetting());
      dispatch(mainOperations.updateSuccessMessage("更新しました"));
    } catch (error) {
      handleReduxError(error, dispatch, "更新に失敗しました");
    } finally {
      dispatch(mainOperations.updateIsLoading(false));
    }
  };

  const handleChangeEnabled = async (field: keyof AccountNoticeSetting, checked: boolean) => {
    dispatch(mainOperations.updateIsLoading(true));
    try {
      await accountNoticeSettingRepository.update({
        [field]: checked,
      });
      mutate(API_PATHS.getAccountNoticeSetting());
      dispatch(mainOperations.updateSuccessMessage("更新しました"));
    } catch (error) {
      handleReduxError(error, dispatch, "更新に失敗しました");
    } finally {
      dispatch(mainOperations.updateIsLoading(false));
    }
  };

  return (
    <Box sx={{ mb: 4 }}>
      <NotificationItem
        title="新規のコネクト申請を受け取ったとき"
        subscriberTypeValue={props.data.newConnectRequestReceivedSubscriberType}
        subscriberTypeField="newConnectRequestReceivedSubscriberType"
        enabledValue={props.data.newConnectRequestReceivedEnabled}
        enabledField="newConnectRequestReceivedEnabled"
        onChangeSubscriberType={handleChangeSubscriberType}
        onChangeEnabled={handleChangeEnabled}
      />

      <NotificationItem
        title="既存コネクトから変更申請を受け取ったとき"
        subscriberTypeValue={props.data.connectRequestChangeReceivedSubscriberType}
        subscriberTypeField="connectRequestChangeReceivedSubscriberType"
        enabledValue={props.data.connectRequestChangeReceivedEnabled}
        enabledField="connectRequestChangeReceivedEnabled"
        onChangeSubscriberType={handleChangeSubscriberType}
        onChangeEnabled={handleChangeEnabled}
      />

      <NotificationItem
        title="コネクト申請が承認されたとき"
        subscriberTypeValue={props.data.connectRequestApprovedSubscriberType}
        subscriberTypeField="connectRequestApprovedSubscriberType"
        enabledValue={props.data.connectRequestApprovedEnabled}
        enabledField="connectRequestApprovedEnabled"
        onChangeSubscriberType={handleChangeSubscriberType}
        onChangeEnabled={handleChangeEnabled}
      />

      <NotificationItem
        title="コネクト申請が却下されたとき"
        subscriberTypeValue={props.data.connectRequestRejectedSubscriberType}
        subscriberTypeField="connectRequestRejectedSubscriberType"
        enabledValue={props.data.connectRequestRejectedEnabled}
        enabledField="connectRequestRejectedEnabled"
        onChangeSubscriberType={handleChangeSubscriberType}
        onChangeEnabled={handleChangeEnabled}
      />

      <NotificationItem
        title="コネクトが停止されたとき"
        subscriberTypeValue={props.data.connectStoppedSubscriberType}
        subscriberTypeField="connectStoppedSubscriberType"
        enabledValue={props.data.connectStoppedEnabled}
        enabledField="connectStoppedEnabled"
        onChangeSubscriberType={handleChangeSubscriberType}
        onChangeEnabled={handleChangeEnabled}
      />
    </Box>
  );
};

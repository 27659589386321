import { convertKeysToCamelCase, convertKeysToSnakeCase } from "utils/convertObjectKeyCase";
import { ScheduleTypeRequest, ScheduleTypeId, ScheduleType } from "./schedule_type.dto";
import { ApiClient } from "../../ApiClient";

export const scheduleTypeRepository = {
  index(): Promise<ScheduleType[]> {
    return ApiClient.get("/api/v1/schedule_types").then((res) => convertKeysToCamelCase(res.data));
  },
  create(body: ScheduleTypeRequest): Promise<void> {
    const config = { headers: { "content-type": "application/json" } };
    return ApiClient.post("/api/v1/schedule_types", convertKeysToSnakeCase(body), config).then(
      (res) => res.data,
    );
  },
  show(id: ScheduleTypeId): Promise<ScheduleType> {
    return ApiClient.get(`/api/v1/schedule_types/${id}`).then((res) =>
      convertKeysToCamelCase(res.data),
    );
  },
  update(id: ScheduleTypeId, body: ScheduleTypeRequest): Promise<void> {
    const config = { headers: { "content-type": "application/json" } };
    return ApiClient.put(`/api/v1/schedule_types/${id}`, convertKeysToSnakeCase(body), config).then(
      (res) => res.data,
    );
  },
  destroy(id: ScheduleTypeId): Promise<void> {
    return ApiClient.delete(`/api/v1/schedule_types/${id}`).then((res) => res.data);
  },
};

import { forwardRef, useState } from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Box, Button, Typography } from "@mui/material";
import { ProjectStatusTypeFormModal } from "./form-modal";
import { ProjectStatusTypeTable } from "./table";

const ProjectStatusType = forwardRef<HTMLDivElement>((_, ref) => {
  const [isModalOpen, setModalOpen] = useState<boolean>(false);

  return (
    <>
      <ProjectStatusTypeFormModal open={setModalOpen} isOpen={isModalOpen} />

      <Box
        ref={ref}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: "16px",
        }}
      >
        <Typography sx={{ fontSize: "14px", fontWeight: "700" }}>案件ステータス</Typography>
        <Button
          variant="contained"
          startIcon={<AddCircleIcon />}
          onClick={() => setModalOpen(true)}
          sx={{ minWidth: "12rem" }}
        >
          案件ステータスを新規作成
        </Button>
      </Box>

      <Box>
        <ProjectStatusTypeTable />
      </Box>
    </>
  );
});

ProjectStatusType.displayName = "ProjectStatusType";

export default ProjectStatusType;

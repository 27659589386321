export const styles = {
  tableSelectBox: {
    "&.MuiInput-underline:before": {
      borderBottom: "none",
    },
    "&:hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "none" },
  },
  menuItemStyles: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 24,
    width: "min-content",
    px: "10px",
    fontWeight: "bold",
    fontSize: "12px",
    borderRadius: "4px",
  },
};

import { ApiClient } from "data-access/ApiClient";
import {
  AccountNoticeSetting,
  AccountNoticeSettingUpdateRequest,
} from "features/user-settings/types/notice-setting/notice_setting.dto";
import { convertKeysToCamelCase, convertKeysToSnakeCase } from "utils/convertObjectKeyCase";

export const accountNoticeSettingRepository = {
  index(): Promise<AccountNoticeSetting> {
    return ApiClient.get("/api/v1/account_notice_setting").then((res) =>
      convertKeysToCamelCase(res.data),
    );
  },
  update(body: AccountNoticeSettingUpdateRequest): Promise<void> {
    return ApiClient.put("/api/v1/account_notice_setting", convertKeysToSnakeCase(body)).then(
      (res) => res.data,
    );
  },
};

import { forwardRef, useState } from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Box, Button, Typography } from "@mui/material";
import { ProjectTypeModal } from "./form-modal";
import { ProjectTypeTable } from "./table";

const ProjectType = forwardRef<HTMLDivElement>((_, ref) => {
  const [isNew, setNew] = useState<boolean>(false);

  return (
    <>
      <Box
        ref={ref}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: "16px",
        }}
      >
        <Typography sx={{ fontSize: "14px", fontWeight: "700" }}>案件タイプ</Typography>
        <Button
          variant="contained"
          startIcon={<AddCircleIcon />}
          onClick={() => setNew(true)}
          sx={{ minWidth: "12rem" }}
        >
          案件タイプを新規作成
        </Button>
      </Box>

      <Box>
        <ProjectTypeTable />
      </Box>

      <ProjectTypeModal open={setNew} isOpen={isNew} />
    </>
  );
});

ProjectType.displayName = "ProjectType";

export default ProjectType;

import { useEffect, useState } from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import { PasswordInput } from "components/input/password-input";
import { LoadingOverlay } from "components/loadingOverlay";
import { CustomFormLabel } from "components/molecules/custom-form-label";
import {
  SignUpAccountRequest,
  SignUpHubRequest,
  SignUpVerifyResponse,
} from "data-access/repositories/sign_up/sign_up.dto";
import { signUpRepository } from "data-access/repositories/sign_up/sign_up.repository";
import { styles } from "./styles";
import { PageState } from ".";

interface Props {
  data: SignUpVerifyResponse;
  token: string;
  setPageState: (pageState: PageState) => void;
  formStateAccount: SignUpAccountRequest;
  setFormStateAccount: (formStateAccount: SignUpAccountRequest) => void;
  formStateHub: SignUpHubRequest;
  setFormStateHub: (formStateHub: SignUpHubRequest) => void;
}
export const Profile = (props: Props) => {
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  useEffect(() => {
    props.setFormStateAccount({
      ...props.formStateAccount,
      signupToken: props.token,
    });
    props.setFormStateHub({
      ...props.formStateHub,
      signupToken: props.token,
    });
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;

    if (name === "name" || name === "phoneNumber") {
      props.setFormStateAccount({
        ...props.formStateAccount,
        me: {
          ...props.formStateAccount.me,
          [name]: value,
        },
      });
    } else {
      props.setFormStateAccount({
        ...props.formStateAccount,
        [name]: value,
      });
    }
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      await signUpRepository.validateAccount(props.formStateAccount);
      props.setFormStateHub({
        ...props.formStateHub,
        password: props.formStateAccount.password,
        me: {
          ...props.formStateHub.me,
          name: props.formStateAccount.me.name,
          phoneNumber: props.formStateAccount.me.phoneNumber,
        },
      });
      props.setPageState("hub");
    } catch (error) {
      setErrorMessage(error.response.data?.message || "入力形式が正しくありません");
    } finally {
      setIsSubmitting(false);
    }
  };

  const isFormValid = () => {
    return (
      props.formStateAccount.password !== "" &&
      props.formStateAccount.me.name !== "" &&
      props.formStateAccount.me.phoneNumber !== ""
    );
  };

  return (
    <>
      <LoadingOverlay isLoading={isSubmitting} />
      <Box sx={{ mt: 8, display: "flex", flexDirection: "column", alignItems: "center" }}>
        <Typography sx={{ fontSize: "24px", fontWeight: "bold", textAlign: "center", mb: "40px" }}>
          あなたについて教えてください
        </Typography>
        <Typography
          sx={{ fontSize: "14px", fontWeight: "bold", mb: "8px", textAlign: "left", width: "100%" }}
        >
          メールアドレス
        </Typography>
        <TextField
          name="destinationEmail"
          value={props.data.destinationEmail}
          disabled
          fullWidth
          sx={{ ...styles.textField }}
        />
        <CustomFormLabel
          labelName="パスワード"
          required
          small
          requiredSize="14px"
          sx={{ justifyContent: "flex-start", width: "100%" }}
        />
        <PasswordInput
          password={props.formStateAccount.password}
          onChange={handleChange}
          width="100%"
        />
        <CustomFormLabel
          labelName="氏名"
          required
          small
          requiredSize="14px"
          sx={{ justifyContent: "flex-start", width: "100%", mt: "16px" }}
        />
        <TextField
          name="name"
          value={props.formStateAccount.me.name}
          onChange={handleChange}
          fullWidth
          sx={{ ...styles.textField }}
        />
        <CustomFormLabel
          labelName="連絡のつきやすい電話番号"
          required
          small
          requiredSize="14px"
          sx={{ justifyContent: "flex-start", width: "100%" }}
        />
        <TextField
          name="phoneNumber"
          value={props.formStateAccount.me.phoneNumber}
          onChange={handleChange}
          fullWidth
          sx={{ ...styles.textField }}
        />

        <Button
          variant="contained"
          onClick={handleSubmit}
          disabled={!isFormValid()}
          sx={{ borderRadius: "24px", width: "240px" }}
        >
          事業者情報へ
        </Button>
      </Box>
      {errorMessage && (
        <Typography color="error" sx={{ ...styles.errorMessage }}>
          {errorMessage}
        </Typography>
      )}
    </>
  );
};

import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { CustomTabs } from "components/molecules/custom-tabs";
import { Layout } from "components/templates/layout";
import { connectApprovableRepository } from "features/hub-settings/api/connect/connect_approvable.repository";
import { connectRequestingRepository } from "features/hub-settings/api/connect/connect_requesting.repository";
import { ConnectApprovable } from "features/hub-settings/components/connect/approvable";
import { HubConnect } from "features/hub-settings/components/connect/connects";
import { ConnectRequesting } from "features/hub-settings/components/connect/requesting";
import useSWR from "swr";
import { API_PATHS } from "utils/apiPaths";

type TabType = "connects" | "requesting" | "approvable";

export const Connect = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [tab, setTab] = useState<TabType>("connects");
  const { data: requestingData } = useSWR(
    API_PATHS.getConnectRequestsRequesting(),
    connectRequestingRepository.index,
  );
  const { data: approvableData } = useSWR(
    API_PATHS.getConnectRequestsApprovable(),
    connectApprovableRepository.index,
  );

  useEffect(() => {
    const pathSegments = window.location.pathname.split("/");
    if (pathSegments.includes("requesting")) {
      setTab("requesting");
    }
    if (pathSegments.includes("approvable")) {
      setTab("approvable");
    }
  }, [location]);

  const handleChangeTab = (newValue: TabType) => {
    setTab(newValue);
    if (newValue === "connects") {
      navigate("/hub-settings/connects");
    } else {
      navigate(`/hub-settings/connects/${newValue}`);
    }
  };

  return (
    <Layout>
      <Box mb="20px">
        <Typography fontWeight="bold" fontSize="24px">
          コネクト管理
        </Typography>
      </Box>

      <Box mb={2}>
        <CustomTabs
          value={tab}
          onChange={(_: React.SyntheticEvent, newValue: TabType) => handleChangeTab(newValue)}
          tabs={[
            { value: "connects", label: "コネクト中" },
            { value: "requesting", label: `申請中 (${requestingData?.length || 0})` },
            { value: "approvable", label: `承認待ち (${approvableData?.length || 0})` },
          ]}
        />
      </Box>

      {tab === "connects" && <HubConnect />}
      {tab === "requesting" && <ConnectRequesting />}
      {tab === "approvable" && <ConnectApprovable />}
    </Layout>
  );
};

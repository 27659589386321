import { Divider, Typography } from "@mui/material";

interface Props {
  title: string;
  color: string;
}
export const CustomDivider = (props: Props) => {
  return (
    <Divider
      textAlign="left"
      sx={{
        mb: "8px",
        "& .MuiDivider-wrapper": {
          pl: 0,
        },
        "&::before": {
          width: 0,
        },
      }}
    >
      <Typography color={props.color} fontWeight="bold" fontSize="12px">
        {props.title}
      </Typography>
    </Divider>
  );
};

import { ApiClient } from "data-access/ApiClient";
import { convertKeysToCamelCase } from "utils/convertObjectKeyCase";
import { ScheduleId } from "../schedule.dto";
import { ScheduleWorkReport } from "./work_report.dto";

export const scheduleWorkReportRepository = {
  index(id: ScheduleId): Promise<ScheduleWorkReport[]> {
    return ApiClient.get(`/api/v1/schedules/${id}/work_reports`).then((res) =>
      convertKeysToCamelCase(res.data),
    );
  },
};

import { ElementRef, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";
import { Avatar } from "components/avatar";
import { ConnectTypeLabel } from "components/label/connect-type-label";
import { StatusTypeLabel } from "components/label/status-type-label";
import { LoadingOverlay } from "components/loadingOverlay";
import { AsyncConfirmDialog } from "components/templates/async-confirm-dialog";
import { theme } from "extensions/theme";
import { connectApprovableRepository } from "features/hub-settings/api/connect/connect_approvable.repository";
import { ConnectApprovableId } from "features/hub-settings/types/connect/connect_approvable.dto";
import { useAppDispatch } from "store/hooks";
import { mainOperations } from "store/main/operations";
import useSWR, { mutate } from "swr";
import { API_PATHS } from "utils/apiPaths";
import { handleReduxError } from "utils/errorHandler";
import { ApprovableDetailModal } from "./detail-modal";
import { styles } from "./styles";

export const ConnectApprovable = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [isDetailModalOpen, setIsDetailModalOpen] = useState<boolean>(false);

  const { data, isLoading } = useSWR(
    API_PATHS.getConnectRequestsApprovable(),
    connectApprovableRepository.index,
  );

  useEffect(() => {
    if (new RegExp(`^/hub-settings/connects/approvable/\\d+$`).test(location.pathname)) {
      setIsDetailModalOpen(true);
    }
  }, [location]);

  const handleClickDetail = (id: ConnectApprovableId) => {
    navigate(`/hub-settings/connects/approvable/${id}`, { state: { id } });
    setIsDetailModalOpen(true);
  };

  const rejectConfirmRef = useRef<ElementRef<typeof AsyncConfirmDialog>>(null);
  const handleReject = async (id: ConnectApprovableId) => {
    if (!rejectConfirmRef.current) return;
    const res = await rejectConfirmRef.current.confirm();
    if (!res) return;
    dispatch(mainOperations.updateIsLoading(true));
    try {
      await connectApprovableRepository.reject(id);
      dispatch(mainOperations.updateSuccessMessage("申請を拒否しました"));
      mutate(API_PATHS.getConnectRequestsApprovable());
    } catch (error) {
      handleReduxError(error, dispatch, "申請の拒否に失敗しました");
    } finally {
      dispatch(mainOperations.updateIsLoading(false));
    }
  };

  const handleCloseDetailModal = () => {
    navigate("/hub-settings/connects/approvable");
    setIsDetailModalOpen(false);
  };

  return (
    <>
      <AsyncConfirmDialog
        ref={rejectConfirmRef}
        title="コネクト申請を拒否しますか？"
        yesButtonText="拒否"
      />
      {isDetailModalOpen && (
        <ApprovableDetailModal isOpen={isDetailModalOpen} onClose={handleCloseDetailModal} />
      )}
      <LoadingOverlay isLoading={isLoading} />

      <Box display="flex" flexWrap="wrap" gap="24px" width="100%">
        {data &&
          data.map((connectApprovable) => (
            <Box key={connectApprovable.id} sx={{ ...styles.hubContent }}>
              <Avatar
                url={connectApprovable.partnerHub.logoUrl}
                name={connectApprovable.partnerHub.name}
                bgColor={connectApprovable.partnerHub.logoBackgroundColorNumber}
                size="medium"
              />
              <Box display="flex" flexDirection="column" flex={1} minWidth={0}>
                <Box
                  sx={{
                    flex: 1,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography sx={styles.hubName}>{connectApprovable.partnerHub.name}</Typography>
                  <StatusTypeLabel
                    statusType={connectApprovable.isNew ? "新規" : "更新"}
                    sx={{ width: "fit-content" }}
                  />
                </Box>
                <Typography
                  sx={{ fontSize: "14px", color: theme.palette.grayScale[700], mb: "8px" }}
                >
                  {connectApprovable.partnerHub.officeName}
                </Typography>
                <Typography sx={{ fontSize: "14px", color: theme.palette.grayScale[700] }}>
                  {connectApprovable.partnerHub.address}
                  {connectApprovable.partnerHub.addressSecond}
                </Typography>
                <Box sx={{ display: "flex", alignItems: "center", gap: "8px", mt: "16px" }}>
                  <Typography fontSize="14px" fontWeight="bold" flex={1}>
                    {connectApprovable.partnerHub.name}が
                  </Typography>
                  <ConnectTypeLabel
                    name={connectApprovable.partnerHubRequestedConnectType.valueI18n}
                    sx={{ width: "fit-content" }}
                  />
                </Box>

                <Box sx={{ display: "flex", gap: "12px", mt: "16px" }}>
                  <Button
                    variant="contained"
                    onClick={() => handleClickDetail(connectApprovable.id)}
                    sx={styles.button}
                  >
                    次へ
                  </Button>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => handleReject(connectApprovable.id)}
                    sx={styles.button}
                  >
                    拒否
                  </Button>
                </Box>
              </Box>
            </Box>
          ))}
      </Box>
    </>
  );
};

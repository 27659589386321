import { useEffect, useState } from "react";
import {
  initialProjectFormBillingState,
  initialProjectFormDatesState,
  initialProjectFormHeaderState,
  initialProjectFormIdentificationState,
  initialProjectFormNotesState,
  initialProjectFormReportContentsState,
  initialProjectFormSalesState,
  initialProjectFormSiteState,
  Project,
  ProjectFormBillingState,
  ProjectFormDatesState,
  ProjectFormHeaderState,
  ProjectFormIdentificationState,
  ProjectFormNotesState,
  ProjectFormReportContentsState,
  ProjectFormSalesState,
  ProjectFormSiteState,
  ProjectFormUpdateRequest,
} from "data-access/repositories/project/project.dto";

export const useProjectForm = (project: Project | undefined) => {
  const [headerState, setHeaderState] = useState<ProjectFormHeaderState>(
    initialProjectFormHeaderState,
  );
  const [identificationState, setIdentificationState] = useState<ProjectFormIdentificationState>(
    initialProjectFormIdentificationState,
  );
  const [datesState, setDatesState] = useState<ProjectFormDatesState>(initialProjectFormDatesState);
  const [salesState, setSalesState] = useState<ProjectFormSalesState>(initialProjectFormSalesState);
  const [notesState, setNotesState] = useState<ProjectFormNotesState>(initialProjectFormNotesState);
  const [siteState, setSiteState] = useState<ProjectFormSiteState>(initialProjectFormSiteState);
  const [billingsState, setBillingsState] = useState<ProjectFormBillingState>(
    initialProjectFormBillingState,
  );
  const [reportContentsState, setReportContentsState] = useState<ProjectFormReportContentsState>(
    initialProjectFormReportContentsState,
  );

  // projectが更新されたら状態を更新
  useEffect(() => {
    if (project) {
      setHeaderState({
        name: project.name,
        projectStatusTypeId: project.projectStatusType.id,
        clientId: project.client?.id || null,
        buildingId: project.building?.id || null,
      });
      setIdentificationState({
        code: project.code,
        inquiryNumber: project.inquiryNumber,
        managerId: project.manager?.userId || null,
        inquiredById: project.inquiredBy?.userId || null,
        projectTypeId: project.projectType.id,
      });
      setDatesState({
        inquiredDate: project.inquiredDate || "",
        orderedDate: project.orderedDate || "",
        expectedCompleteDate: project.expectedCompleteDate || "",
        completedDate: project.completedDate || "",
      });
      setSalesState({
        salesAmount: project.salesAmount || 0,
        tax: project.salesTax || 0,
        totalCostAmount: project.totalCostAmount || 0,
      });
      setNotesState({
        note: project.note,
        internalNote: project.internalNote,
        billingPrecaution: project.billingPrecaution,
      });
      setSiteState({
        personName: project.personName,
        postalCode: project.postalCode,
        address: project.address,
        addressSecond: project.addressSecond,
        phoneNumber: project.phoneNumber,
        phoneNumberSecond: project.phoneNumberSecond,
        faxNumber: project.faxNumber,
        email: project.email,
      });
      setBillingsState({
        isManageBillingOnAnotherSystem: project.isManageBillingOnAnotherSystem,
        isNoBill: project.isNoBill,
        isSupportedByMaker: project.isSupportedByMaker,
      });
      setReportContentsState({
        requester: project.requester,
        requestedByPersonName: project.requestedByPersonName,
        reportContent: project.reportContent,
      });
    }
  }, [project]);

  // フォーム全体の値を取得する関数
  const getAllState = (): ProjectFormUpdateRequest => {
    return {
      ...headerState,
      ...identificationState,
      ...datesState,
      ...salesState,
      ...notesState,
      ...siteState,
      ...billingsState,
      ...reportContentsState,
    };
  };

  return {
    headerState,
    setHeaderState,
    identificationState,
    setIdentificationState,
    datesState,
    setDatesState,
    salesState,
    setSalesState,
    notesState,
    setNotesState,
    siteState,
    setSiteState,
    billingsState,
    setBillingsState,
    reportContentsState,
    setReportContentsState,
    getAllState,
  };
};

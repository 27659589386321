import { convertKeysToCamelCase, convertKeysToSnakeCase } from "utils/convertObjectKeyCase";
import { ProjectTypeRequest, ProjectTypeId, ProjectType } from "./project_type.dto";
import { ApiClient } from "../../ApiClient";

export const projectTypeRepository = {
  index(): Promise<ProjectType[]> {
    return ApiClient.get("/api/v1/project_types").then((res) => convertKeysToCamelCase(res.data));
  },
  create(body: ProjectTypeRequest): Promise<void> {
    const config = { headers: { "content-type": "application/json" } };
    return ApiClient.post("/api/v1/project_types", convertKeysToSnakeCase(body), config).then(
      (res) => res.data,
    );
  },
  update(id: ProjectTypeId, body: ProjectTypeRequest): Promise<void> {
    const config = { headers: { "content-type": "application/json" } };
    return ApiClient.put(`/api/v1/project_types/${id}`, convertKeysToSnakeCase(body), config).then(
      (res) => res.data,
    );
  },
  destroy(id: ProjectTypeId): Promise<void> {
    return ApiClient.delete(`/api/v1/project_types/${id}`).then((res) => res.data);
  },
};

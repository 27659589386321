import { ApiClient } from "data-access/ApiClient";
import { convertKeysToCamelCase, convertKeysToSnakeCase } from "utils/convertObjectKeyCase";
import { ProjectTodoRequest, ProjectTodo, ProjectTodoId } from "./todo.dto";
import { ProjectId } from "../project.dto";

export const projectTodoRepository = {
  index(id: ProjectId): Promise<ProjectTodo[]> {
    return ApiClient.get(`/api/v1/projects/${id}/todos`).then((res) =>
      convertKeysToCamelCase(res.data),
    );
  },
  create(projectId: ProjectId, body: ProjectTodoRequest): Promise<void> {
    const config = { headers: { "content-type": "application/json" } };
    return ApiClient.post(
      `/api/v1/projects/${projectId}/todos`,
      convertKeysToSnakeCase(body),
      config,
    ).then((res) => res.data);
  },
  update(projectId: ProjectId, id: ProjectTodoId, body: ProjectTodoRequest): Promise<void> {
    const config = { headers: { "content-type": "application/json" } };
    return ApiClient.put(
      `/api/v1/projects/${projectId}/todos/${id}`,
      convertKeysToSnakeCase(body),
      config,
    ).then((res) => res.data);
  },
  destroy(projectId: ProjectId, id: ProjectTodoId): Promise<void> {
    return ApiClient.delete(`/api/v1/projects/${projectId}/todos/${id}`).then((res) => res.data);
  },
  done(projectId: ProjectId, id: ProjectTodoId): Promise<void> {
    return ApiClient.put(`/api/v1/projects/${projectId}/todos/${id}/done`).then((res) => res.data);
  },
  undone(projectId: ProjectId, id: ProjectTodoId): Promise<void> {
    return ApiClient.put(`/api/v1/projects/${projectId}/todos/${id}/undone`).then(
      (res) => res.data,
    );
  },
};

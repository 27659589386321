import { useMemo, useState } from "react";
import { Range } from "react-date-range";
import { useNavigate } from "react-router-dom";
import { ChevronLeft } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { ScheduleSearchResultTable } from "components/organisms/schedule-search-result-table";
import { ScheduleSearchResultTableHeader } from "components/organisms/schedule-search-result-table-header";
import { Layout } from "components/templates/layout";
import { getRowsPerPageFromLocalStorage } from "data-access/local_storage/table_filter/local_storage";
import { ScheduleSearchRequest } from "data-access/repositories/schedule/schedule.dto";
import { scheduleRepository } from "data-access/repositories/schedule/schedule.repository";
import useSWR from "swr";

export default function ScheduleSearchResult() {
  const navigate = useNavigate();

  const [keyword, setKeyword] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [scheduleDateRange, setScheduleDateRange] = useState<Range>({
    startDate: undefined,
    endDate: undefined,
    key: "selection",
  });
  const [rowsPerPage, setRowsPerPage] = useState(
    getRowsPerPageFromLocalStorage("scheduleSearchResultTableRowsPerPage"),
  );
  const [sortItem, setSortItem] = useState<{ field: string; sort: string } | undefined>({
    field: "start_time",
    sort: "asc",
  });

  const fetchKey = `api/v1/schedules/search?schedule_range_start=${scheduleDateRange.startDate?.toString()}&schedule_range_end=${scheduleDateRange.endDate?.toString()}&page=${currentPage}&rows_per_page=${rowsPerPage}&sort=${sortItem?.field}&direction=${sortItem?.sort}`;
  const searchQuery = useMemo(() => {
    return {
      scheduleRangeStart: scheduleDateRange.startDate?.toString(),
      scheduleRangeEnd: scheduleDateRange.endDate?.toString(),
      keyword,
      page: currentPage,
      rowsPerPage: rowsPerPage,
      sort: sortItem?.field,
      direction: sortItem?.sort,
    } as ScheduleSearchRequest;
  }, [scheduleDateRange, keyword, currentPage, rowsPerPage, sortItem]);

  const { data, isValidating: isLoading } = useSWR(
    fetchKey,
    () => scheduleRepository.search(searchQuery),
    {
      keepPreviousData: true,
    },
  );

  return (
    <Layout>
      <Box>
        <div style={{ display: "flex", alignItems: "center", marginBottom: "16px" }}>
          <div
            onClick={() => navigate("/schedules")}
            style={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <ChevronLeft />
            <Typography sx={{ fontSize: "20px", fontWeight: "bold", marginLeft: "8px" }}>
              予定検索
            </Typography>
          </div>
        </div>
        <ScheduleSearchResultTableHeader
          fetchKey={fetchKey}
          scheduleDateRange={scheduleDateRange}
          keyword={keyword}
          onSubmitKeyword={setKeyword}
          setCurrentPage={setCurrentPage}
          onUpdateScheduleDateRange={setScheduleDateRange}
        />
        <Box sx={{ mt: 2 }}>
          <ScheduleSearchResultTable
            currentPage={currentPage}
            scheduleDateRange={scheduleDateRange}
            keyword={keyword}
            searchQuery={searchQuery}
            setCurrentPage={setCurrentPage}
            onUpdateScheduleDateRange={setScheduleDateRange}
            setSortItem={setSortItem}
            setRowsPerPage={setRowsPerPage}
            schedulesObj={data}
            isLoading={isLoading}
          />
        </Box>
      </Box>
    </Layout>
  );
}

import { UserId } from "data-access/repositories/account/account.dto";
import { ConnectId, ConnectTypeValue } from "./connect.dto";
import { HubUuId } from "../hub-info/hub_info.dto";

export interface ConnectRequest {
  destinationHubUuid: HubUuId | null;
  destinationEmail: string | null;
  connectId: ConnectId | null;
  connectType: ConnectTypeValue;
  addInvitableAccountUserIds: UserId[];
  removeInvitableAccountUserIds: UserId[];
  addScheduleViewableAccountUserIds: UserId[];
  removeScheduleViewableAccountUserIds: UserId[];
}

export const initialConnectRequest: ConnectRequest = {
  destinationHubUuid: null,
  destinationEmail: "",
  connectId: null,
  connectType: "order",
  addInvitableAccountUserIds: [],
  removeInvitableAccountUserIds: [],
  addScheduleViewableAccountUserIds: [],
  removeScheduleViewableAccountUserIds: [],
};

import { ApiClient } from "data-access/ApiClient";
import { convertKeysToCamelCase } from "utils/convertObjectKeyCase";
import { ProjectId } from "../project.dto";
import { SourceProject } from "./source_project.dto";

export const sourceProjectRepository = {
  index(projectId: ProjectId): Promise<SourceProject> {
    return ApiClient.get(`/api/v1/projects/${projectId}/source_project`).then((res) => {
      return convertKeysToCamelCase(res.data);
    });
  },
};

export const styles = {
  itemTitle: {
    fontSize: "14px",
    marginBottom: "8px",
  },
  textField: {
    width: "100%",
    "& .MuiOutlinedInput-root": {
      height: "46px",
    },
  },
};

import { ApiClient } from "data-access/ApiClient";
import { convertKeysToCamelCase } from "utils/convertObjectKeyCase";
import { ProjectBilling } from "./project_billing.dto";

export const projectBillingRepository = {
  index(id: number): Promise<ProjectBilling[]> {
    return ApiClient.get(`/api/v1/projects/${id}/project_billings`).then((res) =>
      convertKeysToCamelCase(res.data),
    );
  },
};

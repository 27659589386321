import { ApiClient } from "data-access/ApiClient";
import { convertKeysToCamelCase } from "utils/convertObjectKeyCase";
import { ProjectId } from "../project.dto";
import { ProjectParticipantHub } from "./participant_hub.dto";

export const projectParticipantHubRepository = {
  index(projectId: ProjectId): Promise<ProjectParticipantHub[]> {
    return ApiClient.get(`/api/v1/projects/${projectId}/participant_hubs`).then((res) => {
      return convertKeysToCamelCase(res.data);
    });
  },
};

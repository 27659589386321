import { useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Dialog, Divider } from "@mui/material";
import { TitleLabel } from "components/label/title-label";
import { LoadingOverlay } from "components/loadingOverlay";
import { CustomTabs } from "components/molecules/custom-tabs";
import { DiscardChangesConfirmDialog } from "components/molecules/discard-changes-confirm-dialog";
import { DetailSidebar } from "components/templates/detail-sidebar";
import { BuildingId } from "data-access/repositories/building/building.dto";
import { buildingRepository } from "data-access/repositories/building/building.repository";
import { Client } from "data-access/repositories/client/client.dto";
import { clientRepository } from "data-access/repositories/client/client.repository";
import {
  initialProjectFormDatesState,
  initialProjectFormHeaderState,
  initialProjectFormIdentificationState,
  initialProjectFormNotesState,
  initialProjectFormReportContentsState,
  initialProjectFormSalesState,
  initialProjectFormSiteState,
  ProjectFormUpdateRequest,
  ProjectId,
} from "data-access/repositories/project/project.dto";
import { projectRepository } from "data-access/repositories/project/project.repository";
import { projectBillingRepository } from "data-access/repositories/project/project_billing/project_billing.repository";
import { ProjectStatusTypeId } from "data-access/repositories/project_status_type/project_status_type.dto";
import { ProjectTypeId } from "data-access/repositories/project_type/project_type.dto";
import { clientServiceReportRepository } from "features/client-service-report/api/client_service_report.repository";
import { ClientServiceReportIndex } from "features/client-service-report/types/client_service_report.dto";
import { ProjectSidebarActivityContent } from "features/project/components/sidebar/activity-content";
import { ProjectSidebarBillingsContent } from "features/project/components/sidebar/billings-content";
import { ProjectSidebarClientServiceReportContent } from "features/project/components/sidebar/client-service-report-content";
import { ProjectSidebarDateContent } from "features/project/components/sidebar/date-content";
import { ProjectSidebarIdentification } from "features/project/components/sidebar/identification";
import { ProjectSidebarNoteContent } from "features/project/components/sidebar/note-content";
import { ProjectSidebarPrintableReportContent } from "features/project/components/sidebar/printable-report-content";
import { ProjectSidebarSalesContent } from "features/project/components/sidebar/sales-content";
import { ProjectSidebarScheduleContent } from "features/project/components/sidebar/schedule-content";
import { ProjectSidebarSiteContent } from "features/project/components/sidebar/site-content";
import { ProjectSidebarTodoContent } from "features/project/components/sidebar/todo-content";
import { ProjectSidebarWorkReportContent } from "features/project/components/sidebar/work-report-content";
import { useProjectForm } from "hooks/project/useProjectForm";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { mainOperations } from "store/main/operations";
import { selectMain } from "store/main/slice";
import useSWR, { mutate } from "swr";
import { API_PATHS } from "utils/apiPaths";
import { downloadBlob } from "utils/downloadBlob";
import { handleReduxError } from "utils/errorHandler";
import { getBaseUrl } from "utils/getBaseUrl";
import { openURLInNewTab } from "utils/openURLInNewTab";
import { ProjectSidebarFileContent } from "./file-content";
import { ProjectSidebarHeader } from "./header";
import { ProjectSidebarParticipantContent } from "./participant-content";

type TabType = "infos" | "files" | "participants";
interface Props {
  isSidebarOpen: boolean;
  setIsSidebarOpen: (isOpen: boolean) => void;
  currentPage: number;
}
export const ProjectSidebar = (props: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const mainState = useAppSelector(selectMain);
  const location = useLocation();
  const parts = location.pathname.split("/");
  const projectId = parts[2];
  const isCreateNew = projectId === "new";
  const [tab, setTab] = useState<TabType>("infos");

  const { data: project, isValidating: isLoading } = useSWR(
    props.isSidebarOpen && projectId && projectId !== "new"
      ? API_PATHS.getProject(projectId as unknown as ProjectId)
      : null,
    () => {
      return projectRepository.show(projectId as unknown as ProjectId);
    },
  );
  const projectBillingFetchKey = useMemo(() => {
    return project ? `/api/v1/projects/${project.id}/project_billings` : null;
  }, [project]);

  const { data: projectBillings } = useSWR(projectBillingFetchKey, () =>
    projectBillingRepository.index(project?.id as ProjectId),
  );
  const { data: rawBuildingsData } = useSWR(
    props.isSidebarOpen ? "/api/v1/buildings/all" : null,
    () => buildingRepository.all({ orderBy: "recently_created" }),
  );
  const { data: rawClientsData } = useSWR(props.isSidebarOpen ? "/api/v1/clients/all" : null, () =>
    clientRepository.all({ orderBy: "recently_created" }),
  );

  const {
    headerState,
    identificationState,
    datesState,
    salesState,
    siteState,
    notesState,
    billingsState,
    reportContentsState,
    setHeaderState,
    setIdentificationState,
    setDatesState,
    setSalesState,
    setNotesState,
    setSiteState,
    setBillingsState,
    setReportContentsState,
    getAllState,
  } = useProjectForm(project);

  const initialFormValues: ProjectFormUpdateRequest = useMemo(() => {
    return {
      clientId: project?.client?.id || null,
      buildingId: project?.building?.id || null,
      managerId: project?.manager?.userId || null,
      inquiredById: project?.inquiredBy?.userId || null,
      inquiryNumber: project?.inquiryNumber || "0",
      isManageBillingOnAnotherSystem: project?.isManageBillingOnAnotherSystem || false,
      isNoBill: project?.isNoBill || false,
      name: project?.name || "",
      code: project?.code || "",
      projectTypeId: project?.projectType.id || (0 as ProjectTypeId),
      projectStatusTypeId: project?.projectStatusType.id || (0 as ProjectStatusTypeId),
      requester: project?.requester || "",
      requestedByPersonName: project?.requestedByPersonName || "",
      postalCode: project?.postalCode || "",
      address: project?.address || "",
      addressSecond: project?.addressSecond || "",
      phoneNumber: project?.phoneNumber || "",
      phoneNumberSecond: project?.phoneNumberSecond || "",
      faxNumber: project?.faxNumber || "",
      email: project?.email || "",
      billingPrecaution: project?.billingPrecaution || "",
      internalNote: project?.internalNote ?? "",
      note: project?.note || "",
      reportContent: project?.reportContent || "",
      inquiredDate: project?.inquiredDate || new Date().toString(),
      orderedDate: project?.orderedDate || "",
      expectedCompleteDate: project?.expectedCompleteDate || "",
      completedDate: project?.completedDate || "",
      isSupportedByMaker: project?.isSupportedByMaker || false,
      salesAmount: project?.salesAmount || 0,
      tax: project?.tax || 0,
      totalCostAmount: project?.totalCostAmount || 0,
      personName: project?.personName || "",
    };
  }, [project]);

  const buildingId = headerState.buildingId;
  const selectedBuilding = useMemo(() => {
    if (!rawBuildingsData || !buildingId) return [];
    return rawBuildingsData.filter((building) => building.id === buildingId);
  }, [buildingId, rawBuildingsData]);

  const clientId = headerState.clientId;
  const selectedClient = useMemo<Client[]>(() => {
    if (!rawClientsData || !clientId) return [];
    return rawClientsData.filter((client) => client.id === clientId);
  }, [clientId, rawClientsData]);

  // 一つ前の状態を保持
  const lastSavedFormValueRef = useRef<ProjectFormUpdateRequest>(initialFormValues);

  useEffect(() => {
    lastSavedFormValueRef.current = initialFormValues;
  }, [project]);

  useEffect(() => {
    if (!location.pathname.includes("projects")) {
      navigate("/projects");
      props.setIsSidebarOpen(false);
    }
  }, [location]);
  useEffect(() => {
    if (location.pathname.includes("projects") && projectId) {
      props.setIsSidebarOpen(true);
    }
  }, [location]);
  // 新規作成時に初期値を設定
  useEffect(() => {
    if (isCreateNew) {
      setIdentificationState((prev) => ({
        ...prev,
        inquiredById: mainState.me.userId,
        projectTypeId: mainState.projectTypes[0]?.id,
      }));
      setHeaderState((prev) => ({
        ...prev,
        projectStatusTypeId: mainState.projectStatusTypes[0]?.id,
      }));
      setDatesState((prev) => ({
        ...prev,
        inquiredDate: new Date().toString(),
      }));
      setNotesState((prev) => ({
        ...prev,
        note: mainState.hubSetting.project_note_template,
      }));
    }
  }, [
    isCreateNew,
    mainState.projectTypes,
    mainState.projectStatusTypes,
    mainState.hubSetting.project_note_template,
  ]);

  // 完了日の必須入力エラー時、完了日を入力されるようにする
  useEffect(() => {
    if (mainState.errorMessage === "ステータスを完了に変更する場合は完了日の入力が必須です") {
      const element = document.getElementById("completedDate");
      if (element) {
        element.click();
      }
    }
  }, [mainState.errorMessage]);

  const handleCreateProject = async (targetName: string, value: any) => {
    dispatch(mainOperations.updateIsLoading(true));
    try {
      const res = await projectRepository.create({ ...getAllState(), [targetName]: value });
      navigate("/projects/" + res.id);
      dispatch(mainOperations.updateSuccessMessage("案件を作成しました"));
      mutate([API_PATHS.getProjects(), props.currentPage]);
    } catch (error) {
      handleReduxError(error, dispatch, "案件の作成に失敗しました");
    } finally {
      dispatch(mainOperations.updateIsLoading(false));
    }
  };

  const handleCreateBuilding = async () => {
    if (isCreateNew) {
      openURLInNewTab("buildings/new");
      return;
    }
    dispatch(mainOperations.updateIsLoading(true));
    try {
      const result = await buildingRepository.create({
        clientId: headerState.clientId,
        name: headerState.name,
        personName: siteState.personName,
        postalCode: siteState.postalCode,
        address: siteState.address,
        email: siteState.email,
        phoneNumber: siteState.phoneNumber,
        phoneNumberSecond: siteState.phoneNumberSecond,
        faxNumber: siteState.faxNumber,
      });
      try {
        await projectRepository.update(project?.id as ProjectId, {
          buildingId: result.id,
        });
      } catch (error) {
        handleReduxError(error, dispatch, "案件の更新に失敗しました");
      }
      openURLInNewTab(`buildings/${result.id}`);
    } catch (error) {
      handleReduxError(error, dispatch, "物件の作成に失敗しました");
    } finally {
      dispatch(mainOperations.updateIsLoading(false));
    }
  };

  const handleChangeBuilding = async (
    _: React.SyntheticEvent,
    value: { id: BuildingId; name: string; createOption: boolean } | null,
  ) => {
    if (!rawBuildingsData) return;
    dispatch(mainOperations.updateIsLoading(true));
    try {
      if (value) {
        const selectedBuilding = rawBuildingsData.find((building) => building.id === value.id);
        if (!selectedBuilding) return;

        const baseData = {
          name: headerState.name || selectedBuilding.name,
          buildingId: selectedBuilding.id,
          clientId: headerState.clientId || selectedBuilding.client?.id || null,
          personName: selectedBuilding.personName || siteState.personName,
          postalCode: selectedBuilding.postalCode || siteState.postalCode,
          address: selectedBuilding.address || siteState.address,
          addressSecond: selectedBuilding.name || siteState.addressSecond,
          phoneNumber: selectedBuilding.phoneNumber || siteState.phoneNumber,
          phoneNumberSecond: selectedBuilding.phoneNumberSecond || siteState.phoneNumberSecond,
          faxNumber: selectedBuilding.faxNumber || siteState.faxNumber,
          email: selectedBuilding.email || siteState.email,
        };

        if (isCreateNew || !project) {
          const res = await projectRepository.create({
            ...getAllState(),
            ...baseData,
          });
          navigate("/projects/" + res.id);
          dispatch(mainOperations.updateSuccessMessage("案件を作成しました"));
        } else {
          await projectRepository.update(project.id, baseData);
          setHeaderState((prev) => {
            return {
              ...prev,
              ...baseData,
            };
          });
          setSiteState((prev) => {
            return {
              ...prev,
              ...baseData,
            };
          });
          dispatch(mainOperations.updateSuccessMessage("物件を変更しました"));
        }
        lastSavedFormValueRef.current = {
          ...lastSavedFormValueRef.current,
          ...baseData,
        };
      } else {
        if (!project) return;
        await projectRepository.update(project.id, {
          buildingId: null,
        });
        dispatch(mainOperations.updateSuccessMessage("物件を変更しました"));
        lastSavedFormValueRef.current = {
          ...lastSavedFormValueRef.current,
          buildingId: null,
        };
      }
      mutate([API_PATHS.getProjects(), props.currentPage]);
    } catch (error) {
      handleReduxError(error, dispatch, "物件の変更に失敗しました");
    } finally {
      dispatch(mainOperations.updateIsLoading(false));
    }
  };

  const handleCloseSidebar = () => {
    navigate("/projects");
    props.setIsSidebarOpen(false);
    setHeaderState(initialProjectFormHeaderState);
    setIdentificationState(initialProjectFormIdentificationState);
    setDatesState(initialProjectFormDatesState);
    setSalesState(initialProjectFormSalesState);
    setNotesState(initialProjectFormNotesState);
    setSiteState(initialProjectFormSiteState);
    setReportContentsState(initialProjectFormReportContentsState);
  };

  const handlePdfDownload = async (clientServiceReport: ClientServiceReportIndex) => {
    const envApiUrl = getBaseUrl();
    window.open(
      `${envApiUrl}/api/v1/client_service_reports/${clientServiceReport.urlKey}/download.pdf`,
      "_blank",
    );
  };

  const handleExcelDownload = async (clientServiceReport: ClientServiceReportIndex) => {
    dispatch(mainOperations.updateIsLoading(true));
    try {
      const blob = await clientServiceReportRepository.download(
        clientServiceReport.urlKey,
        ".xlsx",
      );
      downloadBlob(
        blob,
        `${identificationState.code}_${headerState.name}_${clientServiceReport.format.name}.xlsx`,
      );
      dispatch(mainOperations.updateSuccessMessage("ダウンロードしました"));
    } catch (error) {
      handleReduxError(error, dispatch, "ダウンロードに失敗しました");
    } finally {
      dispatch(mainOperations.updateIsLoading(false));
    }
  };

  return (
    <>
      <DiscardChangesConfirmDialog />
      {/* 新規作成時に案件ステータスの変更で案件の複数作成を防ぐために保存中ほか項目をさわれないようにする */}
      <Dialog open={isCreateNew && isLoading} sx={{ zIndex: 9999 }} />
      <LoadingOverlay isLoading={isLoading} zIndex={9999} />

      <DetailSidebar isOpen={props.isSidebarOpen} onClose={handleCloseSidebar}>
        <ProjectSidebarHeader
          isCreateNew={isCreateNew}
          isLoading={isLoading}
          identificationState={identificationState}
          notesState={notesState}
          reportContentState={reportContentsState}
          siteState={siteState}
          headerState={headerState}
          setHeaderState={setHeaderState}
          lastSavedFormValue={lastSavedFormValueRef}
          projectId={project?.id}
          currentPage={props.currentPage}
          onCreateProject={handleCreateProject}
          onCreateBuilding={handleCreateBuilding}
          onChangeBuilding={handleChangeBuilding}
          onClose={handleCloseSidebar}
          rawClientsData={rawClientsData || []}
          rawBuildingsData={rawBuildingsData || []}
          projectBillings={projectBillings || []}
          selectedClient={selectedClient[0]}
          selectedBuilding={selectedBuilding[0]}
        />
        <ProjectSidebarIdentification
          projectId={project?.id}
          identificationState={identificationState}
          setIdentificationState={setIdentificationState}
          lastSavedFormValue={lastSavedFormValueRef}
          isCreateNew={isCreateNew}
          photoInfo={
            project?.photoInfoV2 || {
              firstThreePhotos: [],
              totalCount: 0,
            }
          }
          photoUrlKey={project?.photoUrlKey || ""}
          onCreateProject={handleCreateProject}
          currentPage={props.currentPage}
        />
        <ProjectSidebarDateContent
          isCreateNew={isCreateNew}
          projectId={project?.id}
          datesState={datesState}
          setDatesState={setDatesState}
          lastSavedFormValue={lastSavedFormValueRef}
          onCreateProject={handleCreateProject}
          currentPage={props.currentPage}
        />

        <Box sx={{ my: 3 }}>
          <CustomTabs
            value={tab}
            onChange={(_: React.SyntheticEvent, newValue: TabType) => setTab(newValue)}
            tabs={
              isCreateNew
                ? [{ value: "infos", label: "基本情報" }]
                : [
                    { value: "infos", label: "基本情報" },
                    { value: "files", label: "ファイル" },
                    { value: "participants", label: "参加者" },
                  ]
            }
          />
        </Box>

        <Box sx={{ display: "flex" }}>
          {tab === "infos" && (
            <>
              <Box sx={{ width: "41rem" }}>
                <TitleLabel title="基本情報" />
                <ProjectSidebarSalesContent
                  projectId={project?.id}
                  isCreateNew={isCreateNew}
                  salesState={salesState}
                  setSalesState={setSalesState}
                  lastSavedFormValue={lastSavedFormValueRef}
                  decidedEstimatesTotalAmount={project?.decidedEstimatesTotalAmount || 0}
                  onCreateProject={handleCreateProject}
                  currentPage={props.currentPage}
                />
                <ProjectSidebarNoteContent
                  isCreateNew={isCreateNew}
                  projectId={project?.id}
                  notesState={notesState}
                  setNotesState={setNotesState}
                  lastSavedFormValue={lastSavedFormValueRef}
                  onCreateProject={handleCreateProject}
                />
                <Divider sx={{ my: "24px" }} />
                <ProjectSidebarSiteContent
                  projectId={project?.id}
                  isCreateNew={isCreateNew}
                  headerState={headerState}
                  setHeaderState={setHeaderState}
                  siteState={siteState}
                  setSiteState={setSiteState}
                  lastSavedFormValue={lastSavedFormValueRef}
                  onCreateBuilding={handleCreateBuilding}
                  onChangeBuilding={handleChangeBuilding}
                  rawBuildingsData={rawBuildingsData || []}
                  selectedBuilding={selectedBuilding[0]}
                  onCreateProject={handleCreateProject}
                />

                {projectBillings && (
                  <>
                    <Divider sx={{ my: "24px" }} />
                    <ProjectSidebarBillingsContent
                      projectId={project?.id}
                      salesState={salesState}
                      headerState={headerState}
                      billingsState={billingsState}
                      setHeaderState={setHeaderState}
                      setBillingsState={setBillingsState}
                      lastSavedFormValue={lastSavedFormValueRef}
                      projectBillings={projectBillings}
                      rawClientsData={rawClientsData || []}
                      selectedClient={selectedClient[0]}
                      currentPage={props.currentPage}
                    />
                  </>
                )}

                <Divider sx={{ mt: "24px" }} />

                {mainState.hubSetting.is_printable_report_use && (
                  <>
                    <ProjectSidebarPrintableReportContent
                      projectId={project?.id}
                      allState={getAllState()}
                      reportContentState={reportContentsState}
                      setReportContentState={setReportContentsState}
                      lastSavedFormValue={lastSavedFormValueRef}
                      selectedClient={selectedClient[0]}
                    />
                    <Divider />
                  </>
                )}
                {!isCreateNew && project && (
                  <ProjectSidebarActivityContent projectId={project.id} />
                )}
              </Box>

              <Divider orientation="vertical" variant="middle" sx={{ mx: "16px" }} />

              <Box sx={{ py: "22px", width: "20rem" }}>
                <ProjectSidebarTodoContent
                  isCreateNew={isCreateNew}
                  projectId={project?.id}
                  currentPage={props.currentPage}
                />
                <Divider sx={{ my: "2rem" }} />
                <ProjectSidebarScheduleContent
                  isCreateNew={isCreateNew}
                  projectId={project?.id}
                  projectName={headerState.name}
                />
                <Divider sx={{ my: "2rem" }} />
                <ProjectSidebarWorkReportContent
                  isCreateNew={isCreateNew}
                  projectId={project?.id}
                />
                <Divider sx={{ my: "2rem" }} />
                <ProjectSidebarClientServiceReportContent
                  isCreateNew={isCreateNew}
                  projectId={project?.id}
                  onDownloadPdf={handlePdfDownload}
                  onDownloadExcel={handleExcelDownload}
                />
              </Box>
            </>
          )}
          {!isCreateNew && tab === "files" && (
            <ProjectSidebarFileContent projectId={project?.id} isCreateNew={isCreateNew} />
          )}
          {!isCreateNew && tab === "participants" && (
            <div style={{ width: "100%" }}>
              <ProjectSidebarParticipantContent projectId={project?.id} />
            </div>
          )}
        </Box>
      </DetailSidebar>
    </>
  );
};

import {
  camelToSnakeCase,
  convertKeysToCamelCase,
  convertKeysToSnakeCase,
} from "utils/convertObjectKeyCase";
import {
  ProjectIndexRequest,
  ProjectIndexResponse,
  ProjectUpdateRequest,
  ProjectCreateRequest,
  ProjectId,
  ProjectCollection,
  ProjectDownloadRequest,
  Project,
} from "./project.dto";
import { ApiClient } from "../../ApiClient";

export const projectRepository = {
  index(query: ProjectIndexRequest): Promise<ProjectIndexResponse> {
    const params = {
      project_type_ids: query.projectTypeIds,
      project_status_type_ids: query.projectStatusTypeIds,
      start_inquired_date: query.startInquiredDate,
      end_inquired_date: query.endInquiredDate,
      start_ordered_date: query.startOrderedDate,
      end_ordered_date: query.endOrderedDate,
      start_completed_date: query.startCompletedDate,
      end_completed_date: query.endCompletedDate,
      keyword: query.keyword,
      billing_conditions: query.billingConditions,
      sort: query.sort ? camelToSnakeCase(query.sort) : undefined,
      direction: query.direction,
      page: query.page || 1,
      limit: query.rowsPerPage,
    };
    return ApiClient.get("/api/v1/projects", { params }).then((res) =>
      convertKeysToCamelCase(res.data),
    );
  },
  create(body: ProjectCreateRequest): Promise<Project> {
    const config = { headers: { "content-type": "application/json" } };
    return ApiClient.post("/api/v1/projects", createFormData(body), config).then((res) =>
      convertKeysToCamelCase(res.data),
    );
  },
  show(id: ProjectId): Promise<Project> {
    return ApiClient.get(`/api/v1/projects/${id}`).then((res) => convertKeysToCamelCase(res.data));
  },
  update(id: ProjectId, body: ProjectUpdateRequest): Promise<Project> {
    const config = { headers: { "content-type": "multipart/form-data" } };
    return ApiClient.put(`/api/v1/projects/${id}`, updateFormData(body), config).then((res) =>
      convertKeysToCamelCase(res.data),
    );
  },
  destroy(id: ProjectId): Promise<void> {
    return ApiClient.delete(`/api/v1/projects/${id}`).then((res) => res.data);
  },
  createPhotoUrlKey(id: ProjectId): Promise<string> {
    return ApiClient.post(`/api/v1/projects/${id}/photo_url_key`).then((res) => res.data);
  },
  deletePhotoUrlKey(id: ProjectId): Promise<string> {
    return ApiClient.delete(`/api/v1/projects/${id}/photo_url_key`).then((res) => res.data);
  },
  duplicate(id: ProjectId): Promise<Project> {
    return ApiClient.post(`/api/v1/projects/${id}/duplicate`).then((res) =>
      convertKeysToCamelCase(res.data),
    );
  },
  download(query: ProjectDownloadRequest): Promise<ProjectCollection[]> {
    return ApiClient.get("/api/v1/projects/all", { params: convertKeysToSnakeCase(query) }).then(
      (res) => convertKeysToCamelCase(res.data),
    );
  },
};

const createFormData = (body: ProjectCreateRequest) => {
  return {
    client_id: body.clientId,
    building_id: body.buildingId,
    billing_precaution: body.billingPrecaution,
    manager_id: body.managerId,
    inquired_by_id: body.inquiredById,
    name: body.name,
    person_name: body.personName,
    project_type_id: body.projectTypeId,
    requester: body.requester,
    requested_by_person_name: body.requestedByPersonName,
    project_status_type_id: body.projectStatusTypeId,
    postal_code: body.postalCode,
    address: body.address,
    address_second: body.addressSecond,
    phone_number: body.phoneNumber,
    phone_number_second: body.phoneNumberSecond,
    fax_number: body.faxNumber,
    email: body.email,
    internal_note: body.internalNote,
    note: body.note,
    report_content: body.reportContent,
    inquired_date: body.inquiredDate.toString(),
    ordered_date: body.orderedDate?.toString(),
    expected_complete_date: body.expectedCompleteDate?.toString(),
    completed_date: body.completedDate?.toString(),
    code: body.code,
    inquiry_number: body.inquiryNumber,
    is_manage_billing_on_another_system: body.isManageBillingOnAnotherSystem,
    is_no_bill: body.isNoBill,
    is_supported_by_maker: body.isSupportedByMaker,
    sales_amount: body.salesAmount,
  };
};

const updateFormData = (body: ProjectUpdateRequest) => {
  const formData = new FormData();
  if (body.clientId !== undefined) {
    if (body.clientId === null) {
      formData.append("client_id", "");
    } else {
      formData.append("client_id", body.clientId.toString());
    }
  }
  if (body.buildingId !== undefined) {
    if (body.buildingId === null) {
      formData.append("building_id", "");
    } else {
      formData.append("building_id", body.buildingId.toString());
    }
  }
  body.billingPrecaution !== undefined &&
    formData.append("billing_precaution", body.billingPrecaution);
  if (body.managerId && body.managerId > 0) {
    formData.append("manager_id", body.managerId.toString());
  }
  if (body.inquiredById && body.inquiredById > 0) {
    formData.append("inquired_by_id", body.inquiredById.toString());
  }
  body.name !== undefined && formData.append("name", body.name);
  body.personName !== undefined && formData.append("person_name", body.personName);
  body.projectTypeId && formData.append("project_type_id", body.projectTypeId.toString());
  body.requester !== undefined && formData.append("requester", body.requester!);
  body.requestedByPersonName !== undefined &&
    formData.append("requested_by_person_name", body.requestedByPersonName!);
  body.projectStatusTypeId &&
    formData.append("project_status_type_id", body.projectStatusTypeId.toString());
  body.postalCode !== undefined && formData.append("postal_code", body.postalCode);
  body.address !== undefined && formData.append("address", body.address);
  body.addressSecond !== undefined && formData.append("address_second", body.addressSecond);
  body.phoneNumber !== undefined && formData.append("phone_number", body.phoneNumber);
  body.phoneNumberSecond !== undefined &&
    formData.append("phone_number_second", body.phoneNumberSecond);
  body.faxNumber !== undefined && formData.append("fax_number", body.faxNumber);
  body.email !== undefined && formData.append("email", body.email);
  body.internalNote !== undefined && formData.append("internal_note", body.internalNote);
  body.note !== undefined && formData.append("note", body.note);
  body.reportContent !== undefined && formData.append("report_content", body.reportContent);
  body.inquiredDate !== undefined && formData.append("inquired_date", body.inquiredDate);
  body.orderedDate !== undefined && formData.append("ordered_date", body.orderedDate);
  body.expectedCompleteDate !== undefined &&
    formData.append("expected_complete_date", body.expectedCompleteDate);
  body.completedDate !== undefined && formData.append("completed_date", body.completedDate);
  body.code !== undefined && formData.append("code", body.code.toString());
  body.inquiryNumber !== undefined &&
    formData.append("inquiry_number", body.inquiryNumber.toString());
  body.isManageBillingOnAnotherSystem !== undefined &&
    formData.append(
      "is_manage_billing_on_another_system",
      body.isManageBillingOnAnotherSystem.toString(),
    );
  body.isNoBill !== undefined && formData.append("is_no_bill", body.isNoBill.toString());
  body.isSupportedByMaker !== undefined &&
    formData.append("is_supported_by_maker", body.isSupportedByMaker.toString());
  body.salesAmount !== undefined && formData.append("sales_amount", body.salesAmount.toString());
  body.tax !== undefined && formData.append("sales_tax", body.tax.toString());

  return formData;
};

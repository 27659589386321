import React from "react";
import { LinkItEmail, LinkItUrl } from "react-linkify-it";
import { Close } from "@mui/icons-material";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import { StatusTypeLabel } from "components/label/status-type-label";
import { TitleLabel } from "components/label/title-label";
import { SourceProject } from "data-access/repositories/project/source-project/source_project.dto";
import { theme } from "extensions/theme";
import { styles } from "./styles";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  sourceProject: SourceProject;
}
export const SourceProjectModal = (props: Props) => {
  return (
    <Dialog open={props.isOpen} onClose={props.onClose} fullWidth>
      <DialogTitle sx={{ ...styles.title }}>
        自社を招待したHubの情報
        <IconButton onClick={props.onClose} sx={{ ...styles.closeButton }}>
          <Close />
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ mt: "16px" }}>
        {(props.sourceProject.deletedAt || props.sourceProject.withdrawedAt) && (
          <Box
            color={theme.palette.red[500]}
            bgcolor={theme.palette.red[200]}
            sx={{
              display: "flex",
              alignItems: "center",
              pl: "24px",
              borderRadius: "4px",
              height: "52px",
              mb: "16px",
            }}
          >
            <Typography fontWeight="bold">
              {props.sourceProject.deletedAt
                ? `${props.sourceProject.hubName}が案件を削除しました`
                : `${props.sourceProject.hubName}が案件から退出しました`}
            </Typography>
          </Box>
        )}
        <Box sx={{ display: "flex", justifyContent: "space-between", mr: "24px" }}>
          <Box>
            <Typography sx={{ ...styles.label }}>案件名</Typography>
            <Typography fontWeight="bold">{props.sourceProject.name}</Typography>
          </Box>
          <Box>
            <Typography sx={{ ...styles.label }}>案件ステータス名</Typography>
            <StatusTypeLabel
              statusType={props.sourceProject.projectStatusType.name}
              color={props.sourceProject.projectStatusType.colorNumber}
            />
          </Box>
        </Box>
        <Divider sx={{ my: "16px" }} />
        <Box sx={{ display: "flex", gap: "28px", mb: "20px" }}>
          <Box>
            <Typography sx={{ ...styles.label }}>案件番号</Typography>
            <Typography fontWeight="bold">{props.sourceProject.code}</Typography>
          </Box>
          <Box>
            <Typography sx={{ ...styles.label }}>現場責任者</Typography>
            <Typography fontWeight="bold">{props.sourceProject.manager?.name}</Typography>
          </Box>
          <Box>
            <Typography sx={{ ...styles.label }}>案件タイプ</Typography>
            <Typography fontWeight="bold">{props.sourceProject.projectType.name}</Typography>
          </Box>
          <Box>
            <Typography sx={{ ...styles.label }}>完了予定日</Typography>
            <Typography fontWeight="bold">{props.sourceProject.expectedCompleteDate}</Typography>
          </Box>
          <Box>
            <Typography sx={{ ...styles.label }}>完了日</Typography>
            <Typography fontWeight="bold">{props.sourceProject.completedDate}</Typography>
          </Box>
        </Box>
        <Box>
          <TitleLabel title="基本情報" sx={{ mb: "8px" }} />
          <Typography sx={{ ...styles.label }}>概要メモ</Typography>
          <Typography
            sx={{
              wordWrap: "break-word",
              overflow: "auto",
            }}
          >
            {props.sourceProject.note.split("\n").map((str: string, index: number) => {
              return (
                <React.Fragment key={index}>
                  <LinkItUrl>
                    <LinkItEmail>
                      {str}
                      <br />
                    </LinkItEmail>
                  </LinkItUrl>
                </React.Fragment>
              );
            })}
          </Typography>
        </Box>
        <Divider sx={{ my: "16px" }} />
        <Box>
          <TitleLabel title="現場情報" sx={{ mb: "8px" }} />
          <Box mb="16px">
            <Typography sx={{ ...styles.label }}>物件</Typography>
            <Typography fontWeight="bold">{props.sourceProject.building?.name}</Typography>
          </Box>
          <Box sx={{ display: "flex", gap: "28px", mb: "16px" }}>
            <Box>
              <Typography sx={{ ...styles.label }}>郵便番号</Typography>
              <Typography>〒{props.sourceProject.postalCode}</Typography>
            </Box>
            <Box>
              <Typography sx={{ ...styles.label }}>都道府県・市区町村・番地</Typography>
              <Typography>{props.sourceProject.address}</Typography>
            </Box>
            <Box>
              <Typography sx={{ ...styles.label }}>建物名・階・号室</Typography>
              <Typography>{props.sourceProject.addressSecond}</Typography>
            </Box>
          </Box>
          <Box sx={{ display: "flex", gap: "28px", mb: "12px" }}>
            <Box>
              <Typography sx={{ ...styles.label }}>電話番号1</Typography>
              <Typography>{props.sourceProject.phoneNumber}</Typography>
            </Box>
            <Box>
              <Typography sx={{ ...styles.label }}>電話番号2</Typography>
              <Typography>{props.sourceProject.phoneNumberSecond}</Typography>
            </Box>
            <Box>
              <Typography sx={{ ...styles.label }}>メールアドレス</Typography>
              <Typography>{props.sourceProject.email}</Typography>
            </Box>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

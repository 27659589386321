import { useState } from "react";
import { Typography, Button, Box, CircularProgress } from "@mui/material";
import { PrimaryButton } from "components/atoms/primary-button";
import { TagLabel } from "components/label/tag-label";
import { CustomFormLabel } from "components/molecules/custom-form-label";
import { ProjectId } from "data-access/repositories/project/project.dto";
import { ProjectScheduleResponse } from "data-access/repositories/project/schedule/schedule.dto";
import { projectScheduleRepository } from "data-access/repositories/project/schedule/schedule.repository";
import { ScheduleList } from "data-access/repositories/schedule/schedule.dto";
import { theme } from "extensions/theme";
import useSWRInfinite from "swr/infinite";
import { API_PATHS } from "utils/apiPaths";
import { openURLInNewTab } from "utils/openURLInNewTab";
import { timeRange } from "utils/timeRange";
import { styles } from "./styles";
import { ProjectScheduleCreateModal } from "../../../../../components/organisms/project-schedule-create-modal";

interface Props {
  isCreateNew: boolean;
  projectId?: ProjectId;
  projectName: string;
}

export const ProjectSidebarScheduleContent = (props: Props) => {
  const getKey = (pageIndex: number, previousPageData: ProjectScheduleResponse | null) => {
    if (!props.projectId && !previousPageData) return null;
    return [API_PATHS.getProjectSchedules(props.projectId as ProjectId), pageIndex];
  };

  const fetcher = (v: [string, number]) => {
    return projectScheduleRepository.index({
      id: props.projectId as ProjectId,
      page: v[1] + 1,
      limit: 10,
    });
  };

  const { data, isValidating, size, setSize } = useSWRInfinite(getKey, fetcher, {
    initialSize: 1,
  });

  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <>
      {props.projectId && (
        <ProjectScheduleCreateModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          projectId={props.projectId}
          projectName={props.projectName}
          fetchKey={getKey}
        />
      )}

      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <CustomFormLabel labelName="予定" labelColor={theme.palette.grayScale[700]} />
        <PrimaryButton
          name="予定を作成"
          onClick={() => setIsOpen(true)}
          disabled={props.isCreateNew}
        />
      </Box>
      <Box sx={{ maxHeight: "440px", overflow: "scroll" }}>
        {data &&
          data.map((list: ProjectScheduleResponse) =>
            list.data.map((schedule: ScheduleList) => (
              <ScheduleBlock key={schedule.id} schedule={schedule} />
            )),
          )}
        {!data?.[0].data.length && <Typography>予定がありません</Typography>}

        {data && size < data?.[0].pagination.pages && (
          <div style={{ display: "flex", justifyContent: "center", marginTop: "8px" }}>
            {!isValidating ? (
              <Button
                onClick={() => {
                  setSize(size + 1);
                }}
              >
                さらに取得
              </Button>
            ) : (
              <CircularProgress />
            )}
          </div>
        )}
      </Box>
    </>
  );
};

const ScheduleBlock = ({ schedule }: { schedule: ScheduleList }) => {
  const handleClick = () => {
    openURLInNewTab(`schedules/${schedule.id}`);
  };

  return (
    <Box key={schedule.id} onClick={handleClick} sx={{ ...styles.scheduleBlock }}>
      <Box sx={{ display: "flex", alignItems: "center", mb: "8px", gap: "12px" }}>
        <TagLabel
          tagName={schedule.scheduleType.name}
          colorNumber={schedule.scheduleType.colorNumber}
        />
        <Typography sx={{ ...styles.scheduleName }}>{schedule.name}</Typography>
      </Box>
      {schedule.startTime && schedule.endTime ? (
        <>
          <Typography sx={{ fontSize: "16px", fontWeight: "500", mb: "8px" }}>
            {timeRange("yyyy年MM月dd日 HH:mm", schedule.startTime, schedule.endTime)}
          </Typography>
        </>
      ) : (
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: "500",
            color: theme.palette.grayScale[700],
            mb: "8px",
          }}
        >
          日付未設定
        </Typography>
      )}
      <Typography sx={{ fontSize: "14px", mb: "8px" }}>
        {schedule.participants?.map((participant) => participant.accountName).join(",")}
      </Typography>
      <Typography sx={{ ...styles.note }}>{schedule.note}</Typography>
    </Box>
  );
};

import { useEffect, useState } from "react";
import { Box, MenuItem, Select, SelectChangeEvent, Typography } from "@mui/material";
import { Divider } from "@mui/material";
import { Avatar } from "components/avatar";
import { attachmentRepository } from "data-access/repositories/project/attachment/attachment.repository";
import { projectParticipantHubRepository } from "data-access/repositories/project/participant_hub/participant_hub.repository";
import { Project, ProjectId } from "data-access/repositories/project/project.dto";
import { HubId } from "features/hub-settings/types/hub-info/hub_info.dto";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { mainOperations } from "store/main/operations";
import { selectMain } from "store/main/slice";
import useSWR from "swr";
import { API_PATHS } from "utils/apiPaths";
import { handleReduxError } from "utils/errorHandler";
import { ProjectSidebarAttachmentContent } from "../attachment-content";
import { ProjectSidebarEstimateContent } from "../estimate-content";

interface Props {
  projectId?: ProjectId;
  isCreateNew: boolean;
}
export const ProjectSidebarFileContent = (props: Props) => {
  const mainState = useAppSelector(selectMain);
  const dispatch = useAppDispatch();
  const [selectedHubId, setSelectedHubId] = useState<HubId>(0 as HubId);

  const { data: project } = useSWR<Project>(
    props.projectId ? API_PATHS.getProject(props.projectId) : null,
  );
  const { data } = useSWR(
    props.projectId ? `/api/v1/projects/${props.projectId}/participant_hubs` : null,
    () => projectParticipantHubRepository.index(props.projectId as ProjectId),
    { revalidateOnFocus: false },
  );

  const { data: attachments, mutate } = useSWR(
    props.projectId && selectedHubId
      ? API_PATHS.getProjectAttachmentsByAttachmentType(props.projectId, selectedHubId)
      : null,
    () => attachmentRepository.index(props.projectId as ProjectId, selectedHubId),
  );

  useEffect(() => {
    setSelectedHubId(mainState.hub.id);
  }, []);

  if (!props.projectId) return null;
  if (!data) return null;

  const handleSelectParticipantHub = async (e: SelectChangeEvent<HubId>) => {
    const id = e.target.value as HubId;
    dispatch(mainOperations.updateIsLoading(true));
    try {
      setSelectedHubId(id);
      mutate();
    } catch (error) {
      handleReduxError(error, dispatch, "ファイルの取得に失敗しました");
    } finally {
      dispatch(mainOperations.updateIsLoading(false));
    }
  };

  return (
    <div style={{ width: "100%" }}>
      {project && (project.isOrdering || project.isOrdered) && (
        <Box sx={{ mb: "24px" }}>
          <Select
            value={selectedHubId}
            onChange={handleSelectParticipantHub}
            sx={{ height: "40px" }}
          >
            {data.map((partner, index) => (
              <MenuItem key={index} value={partner.id}>
                <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
                  <Avatar
                    url={partner.logoUrl}
                    name={partner.name}
                    bgColor={partner.logoBackgroundColorNumber}
                    size="small"
                  />
                  <Typography sx={{ fontWeight: "bold", fontSize: "14px" }}>
                    {partner.name}
                  </Typography>
                </Box>
              </MenuItem>
            ))}
          </Select>
        </Box>
      )}

      {mainState.hub.id === selectedHubId && (
        <>
          <ProjectSidebarEstimateContent
            projectId={props.projectId}
            isCreateNew={props.isCreateNew}
          />
          <Divider sx={{ my: "24px" }} />
        </>
      )}
      <ProjectSidebarAttachmentContent
        projectId={props.projectId}
        attachmentTypes={attachments || []}
        selectedHubId={selectedHubId}
      />
    </div>
  );
};

import { UserId } from "data-access/repositories/account/account.dto";
import { HubInfoData } from "../features/hub-settings/types/hub-info/hub_info.dto";

/**
 * ユーザーをHubごとにグループ化する
 * @param users Hub情報を持つユーザーの配列
 * @returns ユーザーを含むHubグループの配列
 */
export const getHubGroups = <
  T extends {
    userId: UserId;
    name: string;
    hub: HubInfoData;
  },
>(
  users: T[],
) => {
  const hubMap = new Map<
    string,
    {
      hub: HubInfoData;
      users: Array<{ userId: UserId; name: string } & Omit<T, "hub">>;
    }
  >();

  users.forEach((user) => {
    if (!user.hub) return;
    const hubUuid = user.hub.uuid;
    if (!hubMap.has(hubUuid)) {
      hubMap.set(hubUuid, {
        hub: user.hub,
        users: [],
      });
    }

    const { ...userWithoutHub } = user;
    hubMap.get(hubUuid)?.users.push({
      ...userWithoutHub,
      userId: user.userId,
      name: user.name,
    });
  });

  return Array.from(hubMap.values());
};

import { Box, SelectChangeEvent } from "@mui/material";
import { accountNoticeSettingRepository } from "features/user-settings/api/notice-setting/notice_setting.repository";
import { AccountNoticeSetting } from "features/user-settings/types/notice-setting/notice_setting.dto";
import { useAppDispatch } from "store/hooks";
import { mainOperations } from "store/main/operations";
import { mutate } from "swr";
import { API_PATHS } from "utils/apiPaths";
import { handleReduxError } from "utils/errorHandler";
import { NotificationItem } from "../common/NotificationItem";

interface Props {
  data?: AccountNoticeSetting;
}

export const ScheduleManagement = (props: Props) => {
  const dispatch = useAppDispatch();

  if (!props.data) return null;

  const handleChangeSubscriberType = async (
    event: SelectChangeEvent<string>,
    field: keyof AccountNoticeSetting,
  ) => {
    dispatch(mainOperations.updateIsLoading(true));
    try {
      await accountNoticeSettingRepository.update({
        [field]: event.target.value,
      });
      mutate(API_PATHS.getAccountNoticeSetting());
      dispatch(mainOperations.updateSuccessMessage("更新しました"));
    } catch (error) {
      handleReduxError(error, dispatch, "更新に失敗しました");
    } finally {
      dispatch(mainOperations.updateIsLoading(false));
    }
  };

  const handleChangeEnabled = async (field: keyof AccountNoticeSetting, checked: boolean) => {
    dispatch(mainOperations.updateIsLoading(true));
    try {
      await accountNoticeSettingRepository.update({
        [field]: checked,
      });
      mutate(API_PATHS.getAccountNoticeSetting());
      dispatch(mainOperations.updateSuccessMessage("更新しました"));
    } catch (error) {
      handleReduxError(error, dispatch, "更新に失敗しました");
    } finally {
      dispatch(mainOperations.updateIsLoading(false));
    }
  };

  return (
    <Box sx={{ mb: 4 }}>
      <NotificationItem
        title="他Hubから予定に招待されたとき"
        subscriberTypeValue={props.data.scheduleInvitedByPartnerHubSubscriberType}
        subscriberTypeField="scheduleInvitedByPartnerHubSubscriberType"
        enabledValue={props.data.scheduleInvitedByPartnerHubEnabled}
        enabledField="scheduleInvitedByPartnerHubEnabled"
        onChangeSubscriberType={handleChangeSubscriberType}
        onChangeEnabled={handleChangeEnabled}
      />

      <NotificationItem
        title="他Hubの予定から退出させられたとき"
        subscriberTypeValue={props.data.scheduleRemovedByPartnerHubSubscriberType}
        subscriberTypeField="scheduleRemovedByPartnerHubSubscriberType"
        enabledValue={props.data.scheduleRemovedByPartnerHubEnabled}
        enabledField="scheduleRemovedByPartnerHubEnabled"
        onChangeSubscriberType={handleChangeSubscriberType}
        onChangeEnabled={handleChangeEnabled}
      />

      <NotificationItem
        title="主催者が予定の時間を変更したとき"
        subscriberTypeValue={props.data.scheduleTimeChangedByOrganizerSubscriberType}
        subscriberTypeField="scheduleTimeChangedByOrganizerSubscriberType"
        enabledValue={props.data.scheduleTimeChangedByOrganizerEnabled}
        enabledField="scheduleTimeChangedByOrganizerEnabled"
        onChangeSubscriberType={handleChangeSubscriberType}
        onChangeEnabled={handleChangeEnabled}
      />

      <NotificationItem
        title="参加者が参加・未定・不参加のいずれかを選択したとき"
        subscriberTypeValue={props.data.scheduleParticipationStatusChangedSubscriberType}
        subscriberTypeField="scheduleParticipationStatusChangedSubscriberType"
        enabledValue={props.data.scheduleParticipationStatusChangedEnabled}
        enabledField="scheduleParticipationStatusChangedEnabled"
        onChangeSubscriberType={handleChangeSubscriberType}
        onChangeEnabled={handleChangeEnabled}
      />

      <NotificationItem
        title="主催者が予定のタイトル・現場責任者・仕事内容メモを変更したとき"
        subscriberTypeValue={props.data.scheduleDetailsChangedByOrganizerSubscriberType}
        subscriberTypeField="scheduleDetailsChangedByOrganizerSubscriberType"
        enabledValue={props.data.scheduleDetailsChangedByOrganizerEnabled}
        enabledField="scheduleDetailsChangedByOrganizerEnabled"
        onChangeSubscriberType={handleChangeSubscriberType}
        onChangeEnabled={handleChangeEnabled}
      />

      <NotificationItem
        title="主催者が予定を削除したとき"
        subscriberTypeValue={props.data.scheduleDeletedByOrganizerSubscriberType}
        subscriberTypeField="scheduleDeletedByOrganizerSubscriberType"
        enabledValue={props.data.scheduleDeletedByOrganizerEnabled}
        enabledField="scheduleDeletedByOrganizerEnabled"
        onChangeSubscriberType={handleChangeSubscriberType}
        onChangeEnabled={handleChangeEnabled}
      />
    </Box>
  );
};

import { convertKeysToCamelCase, convertKeysToSnakeCase } from "utils/convertObjectKeyCase";
import {
  ClientIndexRequest,
  ClientRequest,
  ClientIndexResponse,
  ClientId,
  Client,
} from "./client.dto";
import { ApiClient } from "../../ApiClient";

export const clientRepository = {
  index(query: ClientIndexRequest): Promise<ClientIndexResponse> {
    const params = {
      ids: query.ids,
      keyword: query.keyword,
      page: query.page,
      limit: query.limit,
    };
    return ApiClient.get("/api/v1/clients", { params }).then((res) =>
      convertKeysToCamelCase(res.data),
    );
  },
  all(query: ClientIndexRequest): Promise<Client[]> {
    const params = {
      order_by: query.orderBy,
    };
    return ApiClient.get("/api/v1/clients/all", { params }).then((res) =>
      convertKeysToCamelCase(res.data),
    );
  },
  create(body: ClientRequest): Promise<Client> {
    const config = { headers: { "content-type": "application/json" } };
    return ApiClient.post("/api/v1/clients", convertKeysToSnakeCase(body), config).then((res) =>
      convertKeysToCamelCase(res.data),
    );
  },
  show(id: ClientId): Promise<Client> {
    return ApiClient.get(`/api/v1/clients/${id}`).then((res) => convertKeysToCamelCase(res.data));
  },
  update(id: ClientId, body: ClientRequest): Promise<Client> {
    const config = { headers: { "content-type": "application/json" } };
    return ApiClient.put(`/api/v1/clients/${id}`, convertKeysToSnakeCase(body), config).then(
      (res) => convertKeysToCamelCase(res.data),
    );
  },
  destroy(id: ClientId): Promise<void> {
    return ApiClient.delete(`/api/v1/clients/${id}`).then((res) => res.data);
  },
};

import React, { useState } from "react";
import { Box, Button, Divider, Typography } from "@mui/material";
import { Avatar } from "components/avatar";
import { SelectedMemberLabel } from "components/label/selected-member-label";
import { LoadingOverlay } from "components/loadingOverlay";
import { UserId } from "data-access/repositories/account/account.dto";
import { projectParticipantRepository } from "data-access/repositories/project/participant/participant.repository";
import { ProjectId } from "data-access/repositories/project/project.dto";
import { useAppDispatch } from "store/hooks";
import { mainOperations } from "store/main/operations";
import useSWR, { mutate } from "swr";
import { API_PATHS } from "utils/apiPaths";
import { handleReduxError } from "utils/errorHandler";
import { styles } from "./styles";
import { InvitationModal } from "../invitation-modal";

interface Props {
  projectId?: ProjectId;
}

export const ProjectSidebarParticipantContent = (props: Props) => {
  const dispatch = useAppDispatch();
  const [isOpenInvitationModal, setIsOpenInvitationModal] = useState<boolean>(false);

  const { data: participants, isValidating: isLoading } = useSWR(
    props.projectId ? API_PATHS.getProjectParticipants(props.projectId) : null,
    () => projectParticipantRepository.index(props.projectId as ProjectId),
    { revalidateOnFocus: false },
  );

  const handleExclusion = async (userId: UserId) => {
    dispatch(mainOperations.updateIsLoading(true));
    try {
      await projectParticipantRepository.delete(props.projectId as ProjectId, userId);
      dispatch(mainOperations.updateSuccessMessage("参加者を削除しました"));
      mutate(API_PATHS.getProjectParticipants(props.projectId as ProjectId));
      mutate(API_PATHS.getSourceProject(props.projectId as ProjectId));
    } catch (error) {
      handleReduxError(error, dispatch, "参加者の削除に失敗しました");
    } finally {
      dispatch(mainOperations.updateIsLoading(false));
    }
  };

  return (
    <>
      <LoadingOverlay isLoading={isLoading} />
      {isOpenInvitationModal && (
        <InvitationModal
          isOpen={isOpenInvitationModal}
          onClose={() => setIsOpenInvitationModal(false)}
        />
      )}
      <Box sx={{ width: "60%" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "16px",
            mb: "16px",
          }}
        >
          <Typography fontWeight="bold">参加者</Typography>
          <Button
            variant="contained"
            onClick={() => setIsOpenInvitationModal(true)}
            sx={{ height: "32px" }}
          >
            メンバーを招待
          </Button>
        </Box>

        {participants && participants.length !== 0 && (
          <Box mb="24px">
            <Box mb="16px">
              <Divider textAlign="left" sx={{ ...styles.dividerTitle }}>
                <Typography fontWeight="bold" fontSize="12px" color="textDisabled">
                  主催者
                </Typography>
              </Divider>
              {participants
                .filter((participant) => participant.isOrganizer)
                .map((organizerParticipant, index) => (
                  <React.Fragment key={index}>
                    <Box
                      key={organizerParticipant.id}
                      sx={{ display: "flex", alignItems: "center", gap: "8px", mb: "12px" }}
                    >
                      <Avatar
                        url={organizerParticipant.logoUrl}
                        bgColor={organizerParticipant.logoBackgroundColorNumber}
                        name={organizerParticipant.name}
                        size="small"
                      />
                      <Typography fontSize="14px">{organizerParticipant.name}</Typography>
                    </Box>
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: "8px", ml: "36px" }}>
                      {organizerParticipant.participants.map((participant) => (
                        <SelectedMemberLabel
                          key={participant.userId}
                          userId={participant.userId}
                          userName={participant.name}
                          requestType={participant.isDeleted ? "remove" : "unchanged"}
                          onClear={
                            participant.canDelete
                              ? () => handleExclusion(participant.userId)
                              : undefined
                          }
                        />
                      ))}
                    </Box>
                  </React.Fragment>
                ))}
            </Box>

            <Box mb="32px">
              <Divider textAlign="left" sx={{ ...styles.dividerTitle }}>
                <Typography fontWeight="bold" fontSize="12px" color="textDisabled">
                  ゲスト
                </Typography>
              </Divider>
              {participants
                .filter((participant) => !participant.isOrganizer)
                .filter((participant) =>
                  participant.participants.some((member) => !member.isDeleted),
                )
                .map((organizerParticipant, index) => (
                  <Box key={index} sx={{ mb: "12px" }}>
                    <Box
                      key={organizerParticipant.id}
                      sx={{ display: "flex", alignItems: "center", gap: "8px", mb: "12px" }}
                    >
                      <Avatar
                        url={organizerParticipant.logoUrl}
                        bgColor={organizerParticipant.logoBackgroundColorNumber}
                        name={organizerParticipant.name}
                        size="small"
                      />
                      <Typography fontSize="14px">{organizerParticipant.name}</Typography>
                    </Box>
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: "8px", ml: "36px" }}>
                      {organizerParticipant.participants.map((participant) => (
                        <SelectedMemberLabel
                          key={participant.userId}
                          userId={participant.userId}
                          userName={participant.name}
                          requestType={participant.isDeleted ? "remove" : "unchanged"}
                          onClear={
                            participant.canDelete || participant.isDeleted
                              ? () => handleExclusion(participant.userId)
                              : undefined
                          }
                        />
                      ))}
                    </Box>
                  </Box>
                ))}
            </Box>
            <Box>
              <Typography fontWeight="bold" mb="16px">
                退出済み
              </Typography>
              {participants
                .filter((participant) => participant.isWithdrawn)
                .map((organizerParticipant, index) => (
                  <Box key={index} sx={{ mb: "12px" }}>
                    <Box
                      key={organizerParticipant.id}
                      sx={{ display: "flex", alignItems: "center", gap: "8px", mb: "12px" }}
                    >
                      <Avatar
                        url={organizerParticipant.logoUrl}
                        bgColor={organizerParticipant.logoBackgroundColorNumber}
                        name={organizerParticipant.name}
                        size="small"
                      />
                      <Typography fontSize="14px">{organizerParticipant.name}</Typography>
                    </Box>
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: "8px", ml: "36px" }}>
                      {organizerParticipant.participants.map((participant) => (
                        <SelectedMemberLabel
                          key={participant.userId}
                          userId={participant.userId}
                          userName={participant.name}
                        />
                      ))}
                    </Box>
                  </Box>
                ))}
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};

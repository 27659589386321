import { UserId } from "data-access/repositories/account/account.dto";
import { HubId, HubUuId } from "features/hub-settings/types/hub-info/hub_info.dto";
import Cookies from "universal-cookie";
import { TokenCreateRequest } from "./token.dto";

const cookies = new Cookies();

export const cookieRepository = {
  get(): {
    id: UserId;
    name: string;
    email: string;
    hub_id: HubId;
    hub_uuid: HubUuId;
    hub_name: string;
    permissions: { [key: string]: boolean };
  } {
    return cookies.get("artisan");
  },
  set(name: string, body: TokenCreateRequest | boolean) {
    const exp = new Date();
    // 10年後に設定しても、GoogleChromeでは2025-05-30T13:17:47.107Zになる(2024/4/25 22:19時点)
    exp.setFullYear(exp.getFullYear() + 10);
    cookies.set(name, body, {
      path: "/",
      expires: exp,
    });
  },
  destroy(currentPath?: string): void {
    cookies.remove("artisan", { path: "/" });
    cookies.remove("access_token");
    cookies.remove("refresh_token");
    if (currentPath) {
      cookies.remove("artisan", { path: currentPath });
    }
  },
};

import { ElementRef, useRef } from "react";
import { useLocation } from "react-router-dom";
import { Close, EventOutlined } from "@mui/icons-material";
import Person2OutlinedIcon from "@mui/icons-material/Person2Outlined";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { ConnectTypeLabel } from "components/label/connect-type-label";
import { SelectedMemberLabel } from "components/label/selected-member-label";
import { CustomFormLabel } from "components/molecules/custom-form-label";
import { AsyncConfirmDialog } from "components/templates/async-confirm-dialog";
import { theme } from "extensions/theme";
import { connectRequestingRepository } from "features/hub-settings/api/connect/connect_requesting.repository";
import { useAppDispatch } from "store/hooks";
import { mainOperations } from "store/main/operations";
import useSWR, { mutate } from "swr";
import { API_PATHS } from "utils/apiPaths";
import { handleReduxError } from "utils/errorHandler";
import { styles } from "./styles";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}
export const RequestDetailModal = (props: Props) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const id = location?.state?.id;

  const { data } = useSWR(
    id ? API_PATHS.getConnectRequestRequesting(id) : null,
    () => connectRequestingRepository.show(id),
    {
      revalidateOnFocus: false,
    },
  );

  const requestCancelConfirmRef = useRef<ElementRef<typeof AsyncConfirmDialog>>(null);
  const handleRequestCancel = async () => {
    if (!requestCancelConfirmRef.current) return;
    const res = await requestCancelConfirmRef.current.confirm();
    if (!res) return;
    dispatch(mainOperations.updateIsLoading(true));
    try {
      await connectRequestingRepository.cancel(id);
      dispatch(mainOperations.updateSuccessMessage("申請をキャンセルしました"));
      mutate(API_PATHS.getConnectRequestsRequesting());
      handleClose();
    } catch (error) {
      handleReduxError(error, dispatch, "申請のキャンセルに失敗しました");
    } finally {
      dispatch(mainOperations.updateIsLoading(false));
    }
  };

  const handleClose = () => {
    props.onClose();
  };

  if (!data) return null;

  return (
    <>
      <AsyncConfirmDialog
        ref={requestCancelConfirmRef}
        title="申請をキャンセルしますか？"
        yesButtonText="申請をキャンセル"
      />
      <Dialog open={props.isOpen} onClose={handleClose} fullWidth>
        <DialogTitle sx={styles.title}>
          コネクトを申請中
          <IconButton onClick={handleClose} sx={styles.closeButton}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              mb: "24px",
            }}
          >
            <Typography sx={{ fontWeight: "bold" }}>コネクトタイプ</Typography>
            <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
              <Typography sx={{ fontWeight: "bold", fontSize: "14px" }}>自社が</Typography>
              <ConnectTypeLabel
                name={data.ownHubRequestedConnectType.valueI18n}
                sx={{ height: "40px", width: "72px" }}
              />
            </Box>
          </Box>
          {data.ownHubRequestedConnectType.value === "order_and_receive" && (
            <Divider textAlign="left" sx={styles.orderAndReceiveMessage}>
              <Typography color="secondary" fontWeight="bold" fontSize="12px">
                自社が「受注」するとき
              </Typography>
            </Divider>
          )}
          {data.ownHubRequestedConnectType.value !== "order" && (
            <Stack spacing={3} mb={3}>
              <Box sx={{ display: "flex", alignItems: "start", gap: "16px" }}>
                <Person2OutlinedIcon sx={{ mt: "4px" }} />
                <Box sx={{ width: "100%" }}>
                  <CustomFormLabel
                    labelName="相手の案件と予定に招待を許可するメンバー"
                    labelWeight="bold"
                    small
                    requiredSize="12px"
                  />
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: "8px", mt: "12px" }}>
                    {data.ownHubInvitableAccounts.map((hubUser, index) => (
                      <SelectedMemberLabel
                        key={index}
                        userId={hubUser.userId}
                        userName={hubUser.name}
                        requestType={hubUser.requestType}
                      />
                    ))}
                  </Box>
                </Box>
              </Box>
              <Box sx={{ display: "flex", alignItems: "start", gap: "16px" }}>
                <EventOutlined sx={{ mt: "4px" }} />
                <Box sx={{ width: "100%" }}>
                  <CustomFormLabel
                    labelName="相手のスケジュールに予定の時間帯を表示するメンバー"
                    labelWeight="bold"
                    small
                    requiredSize="12px"
                  />
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: "8px", mt: "12px" }}>
                    {data.ownHubScheduleViewableAccounts.map((hubUser, index) => (
                      <SelectedMemberLabel
                        key={index}
                        userId={hubUser.userId}
                        userName={hubUser.name}
                        requestType={hubUser.requestType}
                      />
                    ))}
                  </Box>
                </Box>
              </Box>
            </Stack>
          )}
          {data.ownHubRequestedConnectType.value === "order_and_receive" && (
            <Divider textAlign="left" sx={styles.orderAndReceiveMessage}>
              <Typography color="info" fontWeight="bold" fontSize="12px">
                自社が「発注」するとき
              </Typography>
            </Divider>
          )}
          {(data.ownHubRequestedConnectType.value === "order" ||
            data.ownHubRequestedConnectType.value === "order_and_receive") && (
            <Stack spacing={3} mb={3}>
              <Box sx={{ display: "flex", alignItems: "start", gap: "16px" }}>
                <Person2OutlinedIcon sx={{ mt: "4px" }} />
                <Box sx={{ width: "100%" }}>
                  <CustomFormLabel
                    labelName="自社の案件と予定に招待が許可されているメンバー"
                    labelWeight="bold"
                    small
                    requiredSize="12px"
                  />
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: "8px", mt: "12px" }}>
                    {data.partnerHubInvitableAccounts.map((partnerHubUser, index) => (
                      <SelectedMemberLabel
                        key={index}
                        userId={partnerHubUser.userId}
                        userName={partnerHubUser.name}
                        requestType={partnerHubUser.requestType}
                      />
                    ))}
                  </Box>
                </Box>
              </Box>
              <Box sx={{ display: "flex", alignItems: "start", gap: "16px" }}>
                <EventOutlined sx={{ mt: "4px" }} />
                <Box sx={{ width: "100%" }}>
                  <CustomFormLabel
                    labelName="自社のスケジュールに予定の時間帯を表示するメンバー"
                    labelWeight="bold"
                    small
                    requiredSize="12px"
                  />
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: "8px", mt: "12px" }}>
                    {data.partnerHubScheduleViewableAccounts.map((partnerHubUser, index) => (
                      <SelectedMemberLabel
                        key={index}
                        userId={partnerHubUser.userId}
                        userName={partnerHubUser.name}
                        requestType={partnerHubUser.requestType}
                      />
                    ))}
                  </Box>
                </Box>
              </Box>
            </Stack>
          )}
          <FormControl sx={{ mb: "16px" }}>
            <RadioGroup
              name="collaborationType"
              value={data.destinationEmail ? "email" : "partnerHub"}
              row
            >
              <FormControlLabel value="email" control={<Radio />} label="メールアドレスで連携" />
              <FormControlLabel
                value="partnerHub"
                control={<Radio />}
                label="現場Hub IDで連携 (既存Hub専用)"
              />
            </RadioGroup>
          </FormControl>
          {data.destinationEmail ? (
            <>
              <CustomFormLabel
                labelName="コネクト先のメールアドレス"
                labelWeight="bold"
                small
                requiredSize="12px"
              />
              <TextField
                name="destinationEmail"
                value={data.destinationEmail}
                fullWidth
                sx={{
                  "& .MuiInputBase-root": {
                    height: "48px",
                  },
                }}
                disabled
              />
              <Typography sx={{ fontSize: "11px", color: theme.palette.grayScale[700] }}>
                ・送信先のメールアドレスが現場Hubに登録されている場合、所属するHubにコネクト申請が送信されます
                <br />
                ・送信先のメールアドレスが現場Hubに登録されていない場合、受信者は招待メールに記載されているリンクから現場Hubに登録・コネクトができます
              </Typography>
            </>
          ) : (
            <>
              <CustomFormLabel
                labelName="コネクト先の現場Hub ID"
                labelWeight="bold"
                small
                requiredSize="12px"
              />
              <Box sx={{ display: "flex", gap: "8px" }}>
                <TextField
                  name="destinationHubUuid"
                  value={data.partnerHub?.uuid}
                  fullWidth
                  placeholder="xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx"
                  sx={{
                    "& .MuiInputBase-root": {
                      height: "48px",
                    },
                  }}
                  disabled
                />
              </Box>
            </>
          )}
        </DialogContent>

        <Box sx={{ display: "flex", justifyContent: "center", p: "16px", gap: "16px" }}>
          <Button variant="outlined" onClick={handleRequestCancel} sx={{ ...styles.button }}>
            申請をキャンセル
          </Button>
        </Box>
      </Dialog>
    </>
  );
};

import { ApiClient } from "data-access/ApiClient";
import {
  ConnectApprovable,
  ConnectApprovableCollection,
  ConnectApprovableId,
  ConnectApproveRequest,
} from "features/hub-settings/types/connect/connect_approvable.dto";
import { convertKeysToCamelCase, convertKeysToSnakeCase } from "utils/convertObjectKeyCase";

export const connectApprovableRepository = {
  index(): Promise<ConnectApprovableCollection[]> {
    return ApiClient.get("/api/v1/connect_requests/approvable").then((res) => {
      return convertKeysToCamelCase(res.data);
    });
  },
  show(id: ConnectApprovableId): Promise<ConnectApprovable> {
    return ApiClient.get(`/api/v1/connect_requests/approvable/${id}`).then((res) => {
      return convertKeysToCamelCase(res.data);
    });
  },
  approve(id: ConnectApprovableId, body: ConnectApproveRequest): Promise<void> {
    return ApiClient.post(
      `/api/v1/connect_requests/approvable/${id}/approve`,
      convertKeysToSnakeCase(body),
    ).then((res) => res.data);
  },
  reject(id: ConnectApprovableId): Promise<void> {
    return ApiClient.put(`/api/v1/connect_requests/approvable/${id}/reject`).then(
      (res) => res.data,
    );
  },
};

import { convertKeysToSnakeCase } from "utils/convertObjectKeyCase";
import { PersonalSettingShowResponse, PersonalSettingUpdateRequest } from "./personal_setting.dto";
import { ApiClient } from "../../ApiClient";

export const personalSettingRepository = {
  show(): Promise<PersonalSettingShowResponse> {
    return ApiClient.get("/api/v1/personal_setting").then((res) => res.data);
  },
  update(body: PersonalSettingUpdateRequest): Promise<void> {
    const config = { headers: { "content-type": "application/json" } };
    return ApiClient.put("/api/v1/personal_setting", convertKeysToSnakeCase(body), config).then(
      (res) => res.data,
    );
  },
};

import { Box, SelectChangeEvent, Switch, Typography } from "@mui/material";
import { AccountNoticeSetting } from "features/user-settings/types/notice-setting/notice_setting.dto";
import { styles } from "../styles";
import { NotificationSelect } from "./NotificationSelect";

type SubscriberTypes = "log_only" | "push" | "email" | "push_and_email";

interface NotificationItemProps {
  title: string;
  subscriberTypeValue: SubscriberTypes;
  subscriberTypeField: keyof AccountNoticeSetting;
  enabledValue: boolean;
  enabledField: keyof AccountNoticeSetting;
  onChangeSubscriberType: (
    event: SelectChangeEvent<string>,
    field: keyof AccountNoticeSetting,
  ) => void;
  onChangeEnabled: (field: keyof AccountNoticeSetting, checked: boolean) => void;
}

export const NotificationItem = ({
  title,
  subscriberTypeValue,
  subscriberTypeField,
  enabledValue,
  enabledField,
  onChangeSubscriberType,
  onChangeEnabled,
}: NotificationItemProps) => {
  return (
    <Box sx={{ mb: 3 }}>
      <Typography sx={styles.title}>{title}</Typography>
      <Box sx={{ display: "flex", alignItems: "flex-end", mb: 1 }}>
        <Box>
          <Typography sx={styles.subTitle}>通知方法</Typography>
          <NotificationSelect
            value={subscriberTypeValue}
            onChange={(e) => onChangeSubscriberType(e, subscriberTypeField)}
          />
        </Box>
        <Switch
          checked={enabledValue}
          onChange={(_, checked) => onChangeEnabled(enabledField, checked)}
        />
      </Box>
    </Box>
  );
};

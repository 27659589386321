import { ElementRef, useCallback, useMemo, useRef } from "react";
import { Tooltip } from "@mui/material";
import {
  GridColDef,
  GridRowModel,
  GridRowOrderChangeParams,
  GridRowParams,
  GridActionsCellItem,
  GridRenderCellParams,
} from "@mui/x-data-grid-pro";
import { DeleteIcon } from "components/icon/delete-icon";
import { AsyncConfirmDialog } from "components/templates/async-confirm-dialog";
import { CustomizedDataGridPro } from "components/templates/customized-data-grid-pro";
import { ProjectTypeId } from "data-access/repositories/project_type/project_type.dto";
import { projectTypeRepository } from "data-access/repositories/project_type/project_type.repository";
import { theme } from "extensions/theme";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { mainOperations } from "store/main/operations";
import { selectMain } from "store/main/slice";

export const ProjectTypeTable = () => {
  const dispatch = useAppDispatch();
  const mainState = useAppSelector(selectMain);

  const simpleConfirmRef = useRef<ElementRef<typeof AsyncConfirmDialog>>(null);

  const handleDelete = async (id: ProjectTypeId) => {
    if (!simpleConfirmRef.current) return;
    const res = await simpleConfirmRef.current.confirm();

    if (res) {
      try {
        await projectTypeRepository.destroy(id);
        dispatch(mainOperations.getProjectTypes());
        dispatch(mainOperations.updateSuccessMessage("案件タイプを削除しました"));
      } catch (error) {
        dispatch(mainOperations.updateErrorMessage(error.response.data.message));
      }
    }
  };

  const handleEditName = useCallback(async (params: GridRowModel) => {
    try {
      await projectTypeRepository.update(params.id, {
        name: params.name,
      });
      dispatch(mainOperations.getProjectTypes());
      dispatch(mainOperations.updateSuccessMessage("案件タイプ名を変更しました"));
    } catch (error) {
      dispatch(mainOperations.updateErrorMessage(error.response.data.message));
    }
  }, []);

  const handleRowOrderChange = async (params: GridRowOrderChangeParams) => {
    try {
      await projectTypeRepository.update(params.row.id, {
        displayOrderPosition: params.targetIndex,
      });
      dispatch(mainOperations.getProjectTypes());
      dispatch(mainOperations.updateSuccessMessage("案件タイプの順番を変更しました"));
    } catch (error) {
      dispatch(mainOperations.updateErrorMessage(error.response.data.message));
    }
  };

  const columns = useMemo(() => {
    const headers: GridColDef[] = [];
    headers.push({
      field: "name",
      headerName: "案件タイプ名",
      minWidth: 300,
      sortable: false,
      disableColumnMenu: true,
      editable: true,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Tooltip title="クリックで名前を編集できます" placement="top-start">
            <span>{params.value}</span>
          </Tooltip>
        );
      },
    });
    headers.push({
      field: "actions",
      type: "actions",
      width: 80,
      getActions: (params: GridRowParams) => [
        <GridActionsCellItem
          key={params.row.id}
          icon={<DeleteIcon color={theme.palette.grayScale[700]} size={20} />}
          label="Delete"
          onClick={() => handleDelete(Number(params.row.id) as ProjectTypeId)}
        />,
      ],
    });
    return headers;
  }, []);

  return (
    <>
      <AsyncConfirmDialog ref={simpleConfirmRef} />
      <CustomizedDataGridPro
        columnHeaderHeight={40}
        columns={columns}
        rows={mainState.projectTypes}
        hideFooter
        rowReordering
        disableRowSelectionOnClick
        onRowOrderChange={handleRowOrderChange}
        processRowUpdate={handleEditName}
      />
    </>
  );
};

import React from "react";
import { HandymanOutlined } from "@mui/icons-material";
import EventIcon from "@mui/icons-material/Event";
import HandshakeOutlinedIcon from "@mui/icons-material/HandshakeOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Box, MenuItem, Stack, Typography } from "@mui/material";
import { NoticeLog, NoticeType } from "data-access/repositories/notice/log/logs.dto";
import { theme } from "extensions/theme";
import { openURLInNewTab } from "utils/openURLInNewTab";
import { useStyles } from "./styles";

interface Props {
  data: NoticeLog;
}
export const NoticeLogBlock = ({ data }: Props) => {
  const isAlertNoticeType = (noticeType: NoticeType) => {
    return (
      noticeType === "overtime_records" ||
      noticeType === "project_import_failed" ||
      noticeType === "project_import_canceled" ||
      data.noticeType === "schedule_import_failed" ||
      data.noticeType === "schedule_import_canceled"
    );
  };

  const classes = useStyles({
    isRead: data.isRead,
    isAlertNoticeType: isAlertNoticeType(data.noticeType),
  });

  const handleClick = () => {
    switch (data.noticeType) {
      case "schedules":
      case "confirmed_schedule_changes":
      case "confirmed_schedule_creations":
      case "confirmed_schedule_assignment_changes":
      case "schedule_participation_status_changed":
      case "schedule_details_changed_by_organizer":
      case "schedule_participant_added":
      case "schedule_participant_removed":
      case "schedule_parent_deleted":
      case "schedule_time_changed_by_organizer":
        openURLInNewTab(`schedules/${data.payload.scheduleId}`);
        break;
      case "next_day_schedule_summaries":
        openURLInNewTab("schedules/");
        break;
      case "overtime_records":
        openURLInNewTab("attendances/");
        break;
      case "project_status_changes":
      case "project_participant_added":
      case "project_participant_removed":
        openURLInNewTab(`projects/${data.payload.projectId}`);
        break;
      case "confirmed_schedule_deletions":
        break;
      case "project_import_finished":
        openURLInNewTab("projects/");
        break;
      case "project_import_failed":
      case "project_import_canceled":
        openURLInNewTab(`hub-settings/csv-imports/project`);
        break;
      case "schedule_import_finished":
        openURLInNewTab(`schedules/`);
        break;
      case "schedule_import_failed":
      case "schedule_import_canceled":
        openURLInNewTab(`hub-settings/csv-imports/schedule`);
        break;
      case "connect_request_approved":
      case "connect_request_rejected":
      case "connect_stopped":
        openURLInNewTab("hub-settings/connects");
        break;
      case "connect_request_new":
      case "connect_request_update":
        openURLInNewTab(`hub-settings/connects/approvable/${data.payload.connectRequestId}`);
        break;
    }
  };

  const getIconColor = () => {
    if (
      data.noticeType === "overtime_records" ||
      data.noticeType === "project_import_failed" ||
      data.noticeType === "project_import_canceled" ||
      data.noticeType === "schedule_import_failed" ||
      data.noticeType === "schedule_import_canceled"
    ) {
      return data.isRead ? theme.palette.error.main : theme.palette.grayScale[0];
    }
    return data.isRead ? theme.palette.primary.main : theme.palette.grayScale[0];
  };

  const getIcon = () => {
    const iconProps = {
      fontSize: "small" as const,
      style: {
        color: getIconColor(),
      },
    };

    switch (data.noticeType) {
      case "project_status_changes":
      case "project_import_finished":
      case "project_participant_added":
      case "project_participant_removed":
        return <HandymanOutlined {...iconProps} />;
      case "overtime_records":
      case "project_import_failed":
      case "project_import_canceled":
      case "schedule_import_failed":
      case "schedule_import_canceled":
        return <InfoOutlinedIcon {...iconProps} />;
      case "connect_request_new":
      case "connect_request_update":
      case "connect_request_approved":
      case "connect_request_rejected":
        return <HandshakeOutlinedIcon {...iconProps} />;
      default:
        return <EventIcon {...iconProps} />;
    }
  };

  return (
    <MenuItem sx={{ alignItems: "start", p: "8px" }} onClick={handleClick}>
      <Box className={classes.icon}>{getIcon()}</Box>
      <Stack sx={{ width: "192px", overflow: "auto" }} spacing={1}>
        <Typography className={classes.title}>{data.title}</Typography>
        <Typography fontWeight="500" fontSize="12px" sx={{ overflowWrap: "break-word" }}>
          {data.body.split("\n").map((line, i) => (
            <React.Fragment key={i}>
              {line}
              <br />
            </React.Fragment>
          ))}
        </Typography>
        <Typography fontWeight="bold" fontSize="11px" color={theme.palette.grayScale[700]}>
          {data.relativeTime}
        </Typography>
      </Stack>
    </MenuItem>
  );
};

import { theme } from "extensions/theme";

export const styles = {
  title: {
    height: "64px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.grayScale[0],
  },
  closeButton: {
    position: "absolute",
    right: "16px",
    "& .MuiSvgIcon-root": {
      color: theme.palette.grayScale[0],
    },
  },
  memberRow: {
    mb: "16px",
  },
  addButton: {
    ml: "36px",
    mb: "16px",
    height: "40px",
  },
  actionButtons: {
    display: "flex",
    justifyContent: "center",
  },
  actionButton: {
    m: "0 16px",
    width: 160,
  },
  content: {
    display: "flex",
    flexDirection: "column",
    maxHeight: "calc(100vh - 200px)",
    overflow: "hidden",
  },
  scrollableArea: {
    flex: 1,
    overflowY: "auto",
    p: "16px",
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    p: "16px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
};

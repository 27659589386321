import { useMemo, useState } from "react";
import {
  AutocompleteChangeReason,
  Box,
  Checkbox,
  FormControlLabel,
  Tooltip,
  Typography,
} from "@mui/material";
import { CustomAutocomplete } from "components/atoms/custom-autocomplete";
import { PrimaryButton } from "components/atoms/primary-button";
import { ExpandToggleLabel } from "components/label/expand-toggle-label";
import { TitleLabel } from "components/label/title-label";
import { AccordionToggle } from "components/molecules/accordion-toggle";
import { ClientBlock } from "components/molecules/client-block";
import { CustomFormLabel } from "components/molecules/custom-form-label";
import { Client, ClientId } from "data-access/repositories/client/client.dto";
import {
  ProjectFormBillingState,
  ProjectFormHeaderState,
  ProjectFormSalesState,
  ProjectFormUpdateRequest,
  ProjectId,
} from "data-access/repositories/project/project.dto";
import { projectRepository } from "data-access/repositories/project/project.repository";
import { ProjectBilling } from "data-access/repositories/project/project_billing/project_billing.dto";
import { theme } from "extensions/theme";
import { BillingFormModal } from "features/project/components/sidebar/billings-content/billing-create-modal";
import { BillingCard } from "features/project/components/sidebar/billings-content/card";
import { BillingSummaryCard } from "features/project/components/sidebar/billings-content/summary-card";
import { useAppDispatch } from "store/hooks";
import { mainOperations } from "store/main/operations";
import { mutate } from "swr";
import { API_PATHS } from "utils/apiPaths";
import { ProjectBillingInitialDisplayNumber } from "utils/constant";
import { handleReduxError } from "utils/errorHandler";
import { openURLInNewTab } from "utils/openURLInNewTab";

interface Props {
  projectId?: ProjectId;
  salesState: ProjectFormSalesState;
  headerState: ProjectFormHeaderState;
  billingsState: ProjectFormBillingState;
  setHeaderState: React.Dispatch<React.SetStateAction<ProjectFormHeaderState>>;
  setBillingsState: React.Dispatch<React.SetStateAction<ProjectFormBillingState>>;
  lastSavedFormValue: React.MutableRefObject<ProjectFormUpdateRequest>;
  projectBillings: ProjectBilling[];
  rawClientsData: Client[];
  selectedClient: Client | undefined;
  currentPage: number;
}
export const ProjectSidebarBillingsContent = (props: Props) => {
  const dispatch = useAppDispatch();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [expanded, setExpanded] = useState<boolean>(false);
  const [isExpandedSetting, setIsExpandedSetting] = useState<boolean>(false);

  const clients = props.rawClientsData.map((client) => {
    return {
      id: client.id,
      name: client.name,
      phoneNumber: client.phoneNumber,
      phoneNumberSecond: client.phoneNumberSecond,
      createOption: false,
    };
  });

  const displayNumber = useMemo(() => {
    if (expanded) {
      return props.projectBillings.length || 0;
    }
    return ProjectBillingInitialDisplayNumber;
  }, [expanded]);

  const handleClickBillingCreate = () => {
    setIsOpen(true);
  };

  const handleChangeClient = async (
    _: React.SyntheticEvent,
    value: any,
    reason: AutocompleteChangeReason,
  ) => {
    if (reason !== "selectOption" || !props.projectId) return;
    dispatch(mainOperations.updateIsLoading(true));
    try {
      await projectRepository.update(props.projectId, {
        clientId: value.id,
      });
      props.setHeaderState((prev) => ({ ...prev, clientId: value.id }));
      dispatch(mainOperations.updateSuccessMessage("顧客を紐付けました"));
      props.lastSavedFormValue.current = {
        ...props.lastSavedFormValue.current,
        clientId: value.id,
      };
      mutate([API_PATHS.getProjects(), props.currentPage]);
    } catch (error) {
      handleReduxError(error, dispatch, "顧客の紐付けに失敗しました");
      props.setHeaderState((prev) => ({
        ...prev,
        clientId: props.lastSavedFormValue.current.clientId,
      }));
    } finally {
      dispatch(mainOperations.updateIsLoading(false));
    }
  };

  const handleUnselectClient = async () => {
    if (!props.projectId) return;
    dispatch(mainOperations.updateIsLoading(true));
    try {
      await projectRepository.update(props.projectId, {
        clientId: null,
      });
      props.setHeaderState((prev) => ({ ...prev, clientId: null }));
      dispatch(mainOperations.updateSuccessMessage("顧客の紐付けを解除しました"));
      props.lastSavedFormValue.current = {
        ...props.lastSavedFormValue.current,
        clientId: null,
      };
      mutate([API_PATHS.getProjects(), props.currentPage]);
    } catch (error) {
      handleReduxError(error, dispatch, "顧客の紐付けを解除できませんでした");
      props.setHeaderState((prev) => ({
        ...prev,
        clientId: props.lastSavedFormValue.current.clientId,
      }));
    } finally {
      dispatch(mainOperations.updateIsLoading(false));
    }
  };

  const handleCreate = () => {
    openURLInNewTab(`clients/new/project/${props.projectId}`);
  };

  const customFilterOptions = (
    options: {
      id: ClientId;
      name: string;
      phoneNumber: string;
      phoneNumberSecond: string;
      createOption: boolean;
    }[],
    { inputValue }: { inputValue: string },
  ) => {
    return options.filter(
      (option) =>
        option.name.toLowerCase().includes(inputValue.toLowerCase()) ||
        option.phoneNumber?.toLowerCase().includes(inputValue.toLowerCase()) ||
        option.phoneNumberSecond?.toLowerCase().includes(inputValue.toLowerCase()),
    );
  };

  type BillingCheckboxName = "isSupportedByMaker" | "isManageBillingOnAnotherSystem" | "isNoBill";
  const handleCheckEvent = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!props.projectId) return;
    const name = e.target.name as BillingCheckboxName;
    const { checked } = e.target;

    dispatch(mainOperations.updateIsLoading(true));
    try {
      await projectRepository.update(props.projectId, {
        [name]: checked,
      });
      dispatch(mainOperations.updateSuccessMessage("更新しました"));
      props.setBillingsState((prev) => ({ ...prev, [name]: checked }));
      props.lastSavedFormValue.current = {
        ...props.lastSavedFormValue.current,
        [name]: checked,
      };
      mutate([API_PATHS.getProjects(), props.currentPage]);
    } catch (error) {
      handleReduxError(error, dispatch, "更新に失敗しました");
      props.setBillingsState((prev) => ({ ...prev, [name]: !checked }));
    } finally {
      dispatch(mainOperations.updateIsLoading(false));
    }
  };

  return (
    <>
      {props.projectId && (
        <BillingFormModal isOpen={isOpen} setIsOpen={setIsOpen} projectId={props.projectId} />
      )}

      <div style={{ display: "flex", marginBottom: "16px" }}>
        <TitleLabel title="請求情報" />
        <Tooltip
          title="顧客を選択してください"
          placement="top"
          arrow
          disableFocusListener={!!props.headerState.clientId}
          disableHoverListener={!!props.headerState.clientId}
        >
          <span>
            <PrimaryButton
              name="請求を作成"
              onClick={handleClickBillingCreate}
              disabled={!props.headerState.clientId}
            />
          </span>
        </Tooltip>
      </div>

      <div style={{ marginBottom: "16px" }}>
        <Typography sx={{ fontSize: "14px", color: theme.palette.grayScale[700], mb: "8px" }}>
          請求概要
        </Typography>
        {props.projectBillings && (
          <BillingSummaryCard
            projectBillings={props.projectBillings}
            salesAmount={props.salesState.salesAmount}
          />
        )}
      </div>

      <div style={{ marginBottom: "16px" }}>
        <Typography sx={{ fontSize: "14px", color: theme.palette.grayScale[700], mb: "8px" }}>
          この案件に紐づく請求一覧
        </Typography>
        {props.projectBillings && props.projectBillings.length > 0 ? (
          <>
            {props.projectBillings.slice(0, displayNumber).map((projectBuilding) => {
              return <BillingCard key={projectBuilding.id} billing={projectBuilding} />;
            })}
            {props.projectBillings.length > ProjectBillingInitialDisplayNumber && (
              <ExpandToggleLabel onClick={() => setExpanded(!expanded)} expanded={expanded} />
            )}
          </>
        ) : (
          <Typography sx={{ fontWeight: "bold", ml: "8px" }}>請求はありません</Typography>
        )}
      </div>

      <div style={{ marginBottom: "24px" }}>
        <CustomFormLabel
          labelName="顧客"
          labelSize="14px"
          labelColor={theme.palette.grayScale[700]}
        />
        {!props.selectedClient ? (
          <CustomAutocomplete
            data={clients}
            entityName="顧客"
            onChange={handleChangeClient}
            onClick={handleCreate}
            isCreate
            entityLabel="顧客を検索または選択"
            filterOptions={customFilterOptions}
          />
        ) : (
          <ClientBlock
            projectId={props.projectId}
            client={props.selectedClient || null}
            isDeselectButtonVisible={props.projectBillings.length === 0}
            onClearClient={handleUnselectClient}
          />
        )}
      </div>

      <div>
        <AccordionToggle
          title="詳細設定"
          isExpanded={isExpandedSetting}
          setIsExpanded={setIsExpandedSetting}
          titleStyle={{ fontSize: "14px", fontWeight: "bold", color: theme.palette.grayScale[700] }}
        />
        {isExpandedSetting && (
          <Box sx={{ display: "flex", flexDirection: "column", width: "35%" }}>
            <FormControlLabel
              control={
                <Checkbox
                  name="isSupportedByMaker"
                  checked={props.billingsState.isSupportedByMaker}
                  onChange={handleCheckEvent}
                  size="small"
                />
              }
              label={<Typography fontSize={12}>メーカー対応</Typography>}
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="isManageBillingOnAnotherSystem"
                  checked={props.billingsState.isManageBillingOnAnotherSystem}
                  onChange={handleCheckEvent}
                  size="small"
                />
              }
              label={<Typography fontSize={12}>請求は別システムで管理する</Typography>}
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="isNoBill"
                  checked={props.billingsState.isNoBill}
                  onChange={handleCheckEvent}
                  size="small"
                />
              }
              label={<Typography fontSize={12}>請求しない</Typography>}
            />
          </Box>
        )}
      </div>
    </>
  );
};

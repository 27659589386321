import { ApiClient } from "data-access/ApiClient";
import { RelatedProject } from "data-access/repositories/project/project.dto";
import { convertKeysToCamelCase } from "utils/convertObjectKeyCase";
import { ClientId } from "../client.dto";

export const clientProjectRepository = {
  index(clientId: ClientId): Promise<RelatedProject[]> {
    return ApiClient.get(`/api/v1/clients/${clientId}/projects`).then((res) =>
      convertKeysToCamelCase(res.data),
    );
  },
};

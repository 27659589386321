import React from "react";
import { LinkItEmail, LinkItUrl } from "react-linkify-it";
import { Close } from "@mui/icons-material";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import Person2OutlinedIcon from "@mui/icons-material/Person2Outlined";
import ScheduleIcon from "@mui/icons-material/Schedule";
import { Dialog, Box, Divider, IconButton, Typography, Stack, Chip } from "@mui/material";
import { OutlookIcon } from "components/icon/outlook-icon";
import { UserId } from "data-access/repositories/account/account.dto";
import { timeRange } from "utils/timeRange";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  state: {
    title: string;
    startTime: string;
    endTime: string;
    note: string;
    users: { userId: UserId; name: string }[];
  };
}

export const ExternalScheduleDetailModal = (props: Props) => {
  return (
    <Dialog open={props.isOpen} onClose={props.onClose}>
      <Box
        sx={{
          p: "0 16px 16px",
          width: "480px",
        }}
      >
        <Box>
          <Box sx={{ display: "flex", pt: "16px", justifyContent: "flex-end" }}>
            <div>
              <IconButton onClick={props.onClose}>
                <Close />
              </IconButton>
              <Typography sx={{ fontSize: "11px" }} align="center">
                とじる
              </Typography>
            </div>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", gap: "12px" }}>
            <Typography sx={{ fontSize: "18px", fontWeight: "bold" }}>
              {props.state.title}
            </Typography>
            <OutlookIcon />
          </Box>
          <Divider sx={{ mt: 2, mb: 3 }} />
        </Box>

        <div style={{ display: "flex", alignItems: "center", marginBottom: "28px", gap: "16px" }}>
          <ScheduleIcon fontSize="small" />
          <Typography sx={{ fontWeight: "500" }}>
            {timeRange("yyyy年MM月dd日 HH:mm", props.state.startTime, props.state.endTime)}
          </Typography>
        </div>
        <div style={{ display: "flex", alignItems: "center", marginBottom: "28px", gap: "16px" }}>
          <Person2OutlinedIcon fontSize="small" />
          <Stack spacing={2}>
            <Box sx={{ display: "flex", gap: "4px", flexWrap: "wrap" }}>
              {props.state.users.length > 0
                ? props.state.users.map((user, index) => {
                    return (
                      <Chip
                        key={index}
                        label={user.name}
                        variant="outlined"
                        color="primary"
                        sx={{ fontWeight: "500" }}
                      />
                    );
                  })
                : "参加者なし"}
            </Box>
          </Stack>
        </div>

        <div style={{ display: "flex", marginBottom: "28px", gap: "16px" }}>
          <FormatAlignLeftIcon fontSize="small" />
          <Typography sx={{ wordWrap: "break-word", width: "90%" }}>
            {props.state.note ? (
              props.state.note.split("\n").map((str: string, index: number) => {
                return (
                  <React.Fragment key={index}>
                    <LinkItUrl>
                      <LinkItEmail>
                        {str}
                        <br />
                      </LinkItEmail>
                    </LinkItUrl>
                  </React.Fragment>
                );
              })
            ) : (
              <Typography sx={{ fontWeight: "500", lineHeight: "16px" }}>メモなし</Typography>
            )}
          </Typography>
        </div>
      </Box>
    </Dialog>
  );
};

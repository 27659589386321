import { useRef } from "react";
import { RefObject } from "@fullcalendar/core/preact";
import { Box, Stack, Typography } from "@mui/material";
import { LoadingOverlay } from "components/loadingOverlay";
import { accountNoticeSettingRepository } from "features/user-settings/api/notice-setting/notice_setting.repository";
import useSWR from "swr";
import { API_PATHS } from "utils/apiPaths";
import { ConnectManagement } from "./connect-management";
import { ProjectManagement } from "./project-management";
import { ScheduleManagement } from "./schedule-management";
import { styles } from "./styles";

export const AccountNoticeSetting = () => {
  const { data: accountNoticeSettings, isLoading } = useSWR(
    API_PATHS.getAccountNoticeSetting(),
    accountNoticeSettingRepository.index,
    {
      revalidateOnFocus: false,
    },
  );

  const connectManagementHeaderRef = useRef<HTMLDivElement>(null);
  const projectManagementHeaderRef = useRef<HTMLDivElement>(null);
  const scheduleManagementHeaderRef = useRef<HTMLDivElement>(null);

  // スクロールを実行する関数
  const scrollToSection = (ref: RefObject<HTMLElement>) => {
    if (ref.current) {
      ref.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  return (
    <>
      <LoadingOverlay isLoading={isLoading} />

      <div style={{ display: "flex" }}>
        <div style={{ width: "180px" }}>
          <Stack spacing={2} sx={{ mr: "20px" }}>
            <Typography
              sx={styles.menuItem}
              onClick={() => scrollToSection(connectManagementHeaderRef)}
              fontWeight="500"
            >
              コネクト管理
            </Typography>
            <Typography
              sx={styles.menuItem}
              onClick={() => scrollToSection(projectManagementHeaderRef)}
              fontWeight="500"
            >
              案件
            </Typography>
            <Typography
              sx={styles.menuItem}
              onClick={() => scrollToSection(scheduleManagementHeaderRef)}
              fontWeight="500"
            >
              予定
            </Typography>
          </Stack>
        </div>
        <div style={{ overflow: "scroll", height: "75vh", flex: 1 }}>
          <Box ref={connectManagementHeaderRef}>
            <div style={{ marginBottom: "16px" }}>
              <Typography fontWeight="bold" fontSize="18px" color="primary">
                コネクト管理
              </Typography>
            </div>
            <ConnectManagement data={accountNoticeSettings} />
          </Box>
          <Box ref={projectManagementHeaderRef}>
            <div style={{ marginBottom: "16px" }}>
              <Typography fontWeight="bold" fontSize="18px" color="primary">
                案件
              </Typography>
            </div>
            <ProjectManagement data={accountNoticeSettings} />
          </Box>
          <Box ref={scheduleManagementHeaderRef}>
            <div style={{ marginBottom: "16px" }}>
              <Typography fontWeight="bold" fontSize="18px" color="primary">
                予定
              </Typography>
            </div>
            <ScheduleManagement data={accountNoticeSettings} />
          </Box>
        </div>
      </div>
    </>
  );
};

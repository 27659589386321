import { useState } from "react";
import { SignUpResponse, SignUpVerifyResponse } from "data-access/repositories/sign_up/sign_up.dto";
import { First } from "./first";
import { Profile } from "./profile";
import { Created } from "../created";

export type PageState = "first" | "profile" | "created";

interface Props {
  data: SignUpVerifyResponse;
  token: string;
}
export const ExistingHub = (props: Props) => {
  const [pageState, setPageState] = useState<PageState>("first");
  const [responseData, setResponseData] = useState<SignUpResponse | null>(null);

  return (
    <>
      {pageState === "first" && <First data={props.data} setPageState={setPageState} />}
      {pageState === "profile" && (
        <Profile
          data={props.data}
          token={props.token}
          setPageState={setPageState}
          setResponseData={setResponseData}
        />
      )}
      {pageState === "created" && responseData && (
        <Created data={responseData} flow="existingHub" />
      )}
    </>
  );
};

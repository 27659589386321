import { DetailSidebar } from "components/templates/detail-sidebar";
import { UserId } from "data-access/repositories/account/account.dto";
import { attendanceEmployeeRepository } from "features/attendance/api/monthly_record/attendance.employee.repository";
import useSWR from "swr";
import { formatDateUtil } from "utils/formatDateUtil";
import { Header } from "./header";
import { Infos } from "./infos";
import { List } from "./list";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  id: UserId;
  date: Date;
}

export const Sidebar = (props: Props) => {
  const { isOpen, onClose, id, date } = props;
  const yearMonth = formatDateUtil(date.toString(), "yyyy-MM");

  const { data } = useSWR(
    id !== 0 ? `/api/v1/attendances/daily_records/${yearMonth}/employees/${id}` : null,
    () => attendanceEmployeeRepository.show(yearMonth, id),
    {
      revalidateOnFocus: false,
    },
  );

  return (
    <DetailSidebar isOpen={isOpen} onClose={onClose}>
      <Header onClose={onClose} />
      {data && (
        <>
          <Infos attendanceEmployee={data} />
          <List attendanceEmployee={data} />
        </>
      )}
    </DetailSidebar>
  );
};

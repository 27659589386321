import { ViewType } from "components/organisms/schedule";
import { UserId } from "data-access/repositories/account/account.dto";
import { ScheduleList } from "data-access/repositories/schedule/schedule.dto";
import { theme } from "extensions/theme";
import { CalendarEvent } from "types/calendarEvent";
import { usersName } from "./usersName";

export const scheduleTitle = (
  scheduleName: string,
  displayName: string,
  users: { userId: UserId; name: string }[],
  viewType: ViewType,
): string => {
  if (viewType !== "dayGridMonth") {
    // 月表示以外: 予定名が空なら（予定なし）と表示
    return scheduleName === "" ? `${displayName} (予定名なし)` : displayName;
  }

  // 月表示: 予定名がある場合はdisplayNameを表示、ない場合は参加者名を表示
  return scheduleName ? displayName : `${displayName} ${usersName(users)}`;
};

export const convertScheduleToEvent = (
  schedule: ScheduleList,
  viewType: ViewType,
): CalendarEvent => {
  return {
    id: schedule.id.toString(),
    resourceIds: !schedule.participants.length
      ? ["0"]
      : schedule.participants.map((participant) => {
          return participant.userId.toString();
        }),
    title: scheduleTitle(
      schedule.name,
      schedule.displayName,
      schedule.participants.map((participant) => ({
        userId: participant.userId,
        name: participant.accountName,
      })),
      viewType,
    ),
    note: schedule.note,
    start: schedule.startTime,
    end: schedule.endTime,
    textColor: schedule.isConfirmed
      ? theme.palette.grayScale[0]
      : `#${schedule.scheduleType.colorNumber}`,
    display: "block",
    isConfirmed: schedule.isConfirmed,
    backgroundColor: schedule.isConfirmed
      ? `#${schedule.scheduleType.colorNumber}`
      : theme.palette.grayScale[0],
    borderColor: `#${schedule.scheduleType.colorNumber}`,
    isHoliday: schedule.scheduleType.isHoliday,
    recurrenceRule: schedule.recurrenceRule,
    isAccessible: schedule.isAccessible,
    isInvited: schedule.isInvited,
  };
};

import { convertKeysToCamelCase, convertKeysToSnakeCase } from "utils/convertObjectKeyCase";
import {
  AccountUpdateRequest,
  AccountCreateRequest,
  AccountIndexForScheduleRequest,
  InvitableAccount,
  InvitableAccountForSchedule,
  Account,
  UserId,
} from "./account.dto";
import { ApiClient } from "../../ApiClient";

export const accountRepository = {
  index(): Promise<Account[]> {
    return ApiClient.get("/api/v1/accounts").then((res) => {
      return convertKeysToCamelCase(res.data);
    });
  },
  create(body: AccountCreateRequest): Promise<void> {
    const config = { headers: { "content-type": "application/json" } };
    return ApiClient.post("/api/v1/accounts", convertKeysToSnakeCase(body), config).then((res) => {
      return res.data;
    });
  },
  update(userId: UserId, body: AccountUpdateRequest): Promise<void> {
    const config = { headers: { "content-type": "application/json" } };
    return ApiClient.put(`/api/v1/accounts/${userId}`, updateBody(body), config).then((res) => {
      return res.data;
    });
  },
  invitable(): Promise<InvitableAccount[]> {
    return ApiClient.get("/api/v1/accounts/invitable").then((res) => {
      return convertKeysToCamelCase(res.data);
    });
  },
  invitableForSchedule(
    query: AccountIndexForScheduleRequest,
  ): Promise<InvitableAccountForSchedule[]> {
    return ApiClient.get("/api/v1/accounts/invitable/for_schedule", {
      params: convertKeysToSnakeCase(query),
    }).then((res) => {
      return convertKeysToCamelCase(res.data);
    });
  },
};

const updateBody = (body: AccountUpdateRequest) => {
  return {
    name: body.name,
    group_id: body.groupId !== 0 ? body.groupId : null,
    unit_price_per_day: body.unitPricePerDay,
    display_order_position: body.displayOrderPosition && body.displayOrderPosition,
    is_deactivate: body.isDeactivate,
    schedule_overlapping_type: body.scheduleOverlappingType,
    attendance_rule_id: body.attendanceRuleId,
    permission_set_id: body.permissionSetId,
  };
};

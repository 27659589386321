import React, {
  ChangeEvent,
  ReactElement,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
  useMemo,
} from "react";
import { autoPlacement, offset } from "@floating-ui/dom";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import LockIcon from "@mui/icons-material/LockOutlined";
import Person2OutlinedIcon from "@mui/icons-material/Person2Outlined";
import ScheduleIcon from "@mui/icons-material/Schedule";
import {
  Button,
  TextField,
  Select,
  MenuItem,
  Checkbox,
  SelectChangeEvent,
  Divider,
  FormControlLabel,
  FormControl,
  InputLabel,
  Typography,
  Tooltip,
  Box,
} from "@mui/material";
import { CustomDatePicker } from "components/atoms/custom-date-picker";
import { Flash } from "components/atoms/flash";
import { Avatar } from "components/avatar";
import { CustomDivider } from "components/divider";
import { CloseIcon } from "components/icon/close-icon";
import { SiteManagerIcon } from "components/icon/site-manager-icon";
import { SelectedMemberLabel } from "components/label/selected-member-label";
import { TagLabel } from "components/label/tag-label";
import { LoadingOverlay } from "components/loadingOverlay";
import { ConfirmDialog } from "components/molecules/confirm-dialog";
import { CustomModal } from "components/molecules/custom-modal";
import { RecurrenceRuleSettingModal } from "components/molecules/recurrence-rule-setting-modal";
import { SelectedMembers } from "components/molecules/selected-members";
import { ProjectId } from "data-access/repositories/project/project.dto";
import {
  KeyName,
  RecurrenceRule,
  RecurrenceRuleParams,
} from "data-access/repositories/recurrence_rule/recurrence_rule.dto";
import { RecurrenceRuleOptionsRepository } from "data-access/repositories/recurrence_rule_options/recurrence_rule_options.repository";
import {
  ScheduleCreateRequest,
  initialRecurrenceRule,
  initialScheduleCreateRequest,
} from "data-access/repositories/schedule/schedule.dto";
import { scheduleRepository } from "data-access/repositories/schedule/schedule.repository";
import { theme } from "extensions/theme";
import { useScheduleMembers } from "hooks/schedule/useScheduleMembers";
import { useAppSelector, useAppDispatch } from "store/hooks";
import { mainOperations } from "store/main/operations";
import { selectMain } from "store/main/slice";
import useSWR, { mutate } from "swr";
import { SWRInfiniteKeyLoader, unstable_serialize } from "swr/infinite";
import { calculatePeriodFromDate, calculatePeriodFromTime } from "utils/calculatePeriod";
import { handleStateError } from "utils/errorHandler";
import { DATE_TIME_SLASH_FORMAT } from "utils/formatDateUtil";
import { getHubGroups } from "utils/hubGrouping";

const startDatePopperPosition = [
  autoPlacement({
    alignment: "start",
    autoAlignment: false,
  }),
  offset({ mainAxis: -200, crossAxis: 280 }),
];
const endDatePopperPosition = [
  autoPlacement({
    alignment: "start",
    autoAlignment: false,
  }),
  offset({ mainAxis: -340, crossAxis: 0 }),
];

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  projectId: ProjectId;
  projectName: string;
  fetchKey: SWRInfiniteKeyLoader;
}

export const ProjectScheduleCreateModal = (props: Props) => {
  const { isOpen, setIsOpen, projectId, projectName, fetchKey } = props;
  const dispatch = useAppDispatch();
  const mainState = useAppSelector(selectMain);

  const [isChange, setIsChange] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [formState, setFormState] = useState<ScheduleCreateRequest>(initialScheduleCreateRequest);
  const [selectedRecurrenceRule, setSelectedRecurrenceRule] = useState<{
    keyName: KeyName;
    label: string;
  }>({ keyName: "none", label: "" });
  const [recurrenceSettingModalState, setRecurrenceSettingModalState] = useState<{
    isOpen: boolean;
    recurrenceRule?: RecurrenceRule;
  }>({
    isOpen: false,
  });

  const {
    data: recurrenceRuleOptions,
    mutate: recurrenceRuleOptionsMutate,
    isValidating: isLoadingRecurrenceRule,
  } = useSWR(
    isOpen && formState.startTime ? "/api/v1/recurrence_rule_options" : null,
    () =>
      RecurrenceRuleOptionsRepository.index({
        scheduleStartTime: formState.startTime ? formState.startTime : new Date().toString(),
      }),
    { revalidateOnFocus: false },
  );

  useEffect(() => {
    if (!isOpen) return;

    setFormState({
      ...formState,
      projectId,
      name: projectName,
      scheduleTypeId: mainState.scheduleTypes[0].id,
      note: mainState.hubSetting.schedule_note_template,
    });
    setSelectedRecurrenceRule({ keyName: "none", label: "" });
  }, [isOpen]);

  // 開始日時を変更したら繰り返しプルダウンリストを更新する
  useEffect(() => {
    recurrenceRuleOptionsMutate();
  }, [formState.startTime]);

  // 繰り返し設定モーダルで設定した値を反映
  useEffect(() => {
    if (!recurrenceSettingModalState.recurrenceRule) return;
    setFormState({
      ...formState,
      recurrenceRule: {
        ...recurrenceSettingModalState.recurrenceRule.params,
        endDate: recurrenceSettingModalState.recurrenceRule.params.endDate || null,
      },
    });
    setSelectedRecurrenceRule({
      keyName: recurrenceSettingModalState.recurrenceRule.keyName,
      label: recurrenceSettingModalState.recurrenceRule.label,
    });
  }, [recurrenceSettingModalState.recurrenceRule]);

  const createSchedule = async () => {
    setIsLoading(true);
    try {
      await scheduleRepository.create(formState);
      handleClose();
      dispatch(mainOperations.updateSuccessMessage("予定を作成しました"));
      mutate(unstable_serialize(fetchKey));
      setErrorMessage("");
    } catch (error) {
      handleStateError(error, setErrorMessage, "予定の作成に失敗しました");
    } finally {
      setIsLoading(false);
    }
  };

  const {
    selectedAccounts,
    inputValue,
    allOptions,
    selectableSiteManagers,
    isLoading: isLoadingMembers,
    resetMembers,
    handleChangeUsers,
    handleClear,
    setInputValue,
    filterOptions,
    groupByFunction,
    isOverlapConfirmModalOpen,
    handleCreateWithOverlapCheck,
    handleCancelOverlapConfirm,
  } = useScheduleMembers({
    isModalOpen: props.isOpen,
    startTime: formState.startTime,
    endTime: formState.endTime,
    onMembersChange: (newUserIds) => {
      setFormState({ ...formState, userIds: newUserIds });
      setIsChange(true);
    },
    onCreateSchedule: createSchedule,
  });

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<number | null>,
  ) => {
    setIsChange(true);
    setFormState({ ...formState, [e.target.name]: e.target.value });
  };

  const handleSelectChange = (e: SelectChangeEvent<string>) => {
    setIsChange(true);
    setFormState({ ...formState, [e.target.name]: e.target.value });
  };

  const handleChangeDateTime = (
    date: Date,
    e: React.SyntheticEvent<any> | undefined,
    name: string,
  ) => {
    if (date === null) return;
    setIsChange(true);
    switch (name) {
      case "startTime": {
        // 日付を変更したときはeが存在する
        const { startDateTime, endDateTime } = (
          e ? calculatePeriodFromDate : calculatePeriodFromTime
        )(
          date,
          formState.startTime ? new Date(formState.startTime) : null,
          formState.endTime ? new Date(formState.endTime) : null,
        );

        setFormState({
          ...formState,
          startTime: startDateTime?.toString() || "",
          endTime: endDateTime.toString(),
        });
        break;
      }
      case "endTime":
        return setFormState({ ...formState, endTime: date.toString() });
    }
  };

  const handleChangeRadioButton = (e: ChangeEvent<HTMLInputElement>) => {
    setIsChange(true);
    setFormState({ ...formState, [e.target.name]: e.target.checked });
  };

  const handleSubmit = async () => {
    handleCreateWithOverlapCheck();
  };

  const handleClose = () => {
    setIsOpen(false);
    resetMembers();
    setErrorMessage("");
    setFormState(initialScheduleCreateRequest);
    setIsChange(false);
    setIsDeleteModalOpen(false);
    setSelectedRecurrenceRule({ keyName: "none", label: "" });
  };

  const handleChangeRecurrence = (e: SelectChangeEvent<string>, child: React.ReactNode) => {
    setIsChange(true);
    const { value } = e.target;
    if (value === "custom") {
      setRecurrenceSettingModalState({
        isOpen: true,
        recurrenceRule: {
          keyName: selectedRecurrenceRule.keyName,
          label: selectedRecurrenceRule.label,
          params: formState.recurrenceRule || initialRecurrenceRule,
        },
      });
      return;
    }

    switch (value) {
      case "none":
        setFormState({ ...formState, recurrenceRule: undefined });
        break;
      default: {
        const dataParams: RecurrenceRuleParams = (child as ReactElement).props["data-params"];
        setFormState({
          ...formState,
          recurrenceRule: { ...dataParams, endDate: dataParams.endDate || "" },
        });
        break;
      }
    }
    setSelectedRecurrenceRule({
      keyName: value as KeyName,
      label: (child as ReactElement).props.children,
    });
  };

  const scrollBottomRef = useRef<HTMLDivElement>(null);
  useLayoutEffect(() => {
    if (errorMessage !== "") {
      scrollBottomRef?.current?.scrollIntoView();
    }
  }, [errorMessage]);

  // 他のHubユーザーグループ化
  const hubGroups = useMemo(() => {
    return getHubGroups(selectedAccounts);
  }, [selectedAccounts]);

  // 他のHubユーザーが含まれているかをチェックする関数
  const hasOtherHubUsers = useMemo(() => {
    return hubGroups.some((hubGroup) => hubGroup.hub.uuid !== mainState.hub.uuid);
  }, [hubGroups]);

  // ユーザー選択変更時に他Hubがあれば繰り返し設定をクリア
  useEffect(() => {
    if (hasOtherHubUsers && formState.recurrenceRule) {
      setFormState({
        ...formState,
        recurrenceRule: undefined,
      });
      setSelectedRecurrenceRule({ keyName: "none", label: "" });
    }
  }, [hasOtherHubUsers]);

  return (
    <>
      <ConfirmDialog
        isOpen={isDeleteModalOpen}
        handleYes={handleClose}
        handleNo={() => setIsDeleteModalOpen(false)}
        content="作成せず破棄しますか？"
        yesButtonText="破棄する"
        noButtonText="キャンセル"
        yesButtonColor="error"
      />

      <ConfirmDialog
        isOpen={isOverlapConfirmModalOpen}
        handleYes={async () => {
          handleCancelOverlapConfirm();
          await createSchedule();
        }}
        handleNo={handleCancelOverlapConfirm}
        content={`他の予定と時間が重複しているメンバーがいますが、予定を保存しますか？`}
        yesButtonText="保存"
      />

      <RecurrenceRuleSettingModal
        isOpen={recurrenceSettingModalState.isOpen}
        recurrenceRule={formState.recurrenceRule || undefined}
        setRecurrenceSettingModalState={(isOpen, recurrenceRule) =>
          setRecurrenceSettingModalState({ isOpen, recurrenceRule })
        }
        onClose={() =>
          setRecurrenceSettingModalState({
            isOpen: false,
            recurrenceRule: recurrenceSettingModalState.recurrenceRule,
          })
        }
        startDate={formState.startTime || ""}
      />

      <CustomModal
        title="予定の新規作成"
        open={isOpen}
        onClose={() => (isChange ? setIsDeleteModalOpen(true) : handleClose())}
        maxWidth="sm"
        footer={
          <>
            <Button
              variant="outlined"
              onClick={() => (isChange ? setIsDeleteModalOpen(true) : handleClose())}
              sx={{ width: "160px" }}
            >
              キャンセル
            </Button>
            <Button
              disabled={isLoading}
              onClick={handleSubmit}
              variant="contained"
              sx={{ width: "160px", ml: "16px" }}
            >
              作成
            </Button>
          </>
        }
      >
        <LoadingOverlay isLoading={isLoadingRecurrenceRule || isLoadingMembers} />
        <div style={{ display: "flex", marginBottom: "16px", gap: "16px" }}>
          <TextField
            id="name"
            name="name"
            label="予定名"
            variant="standard"
            value={formState.name}
            onChange={handleChange}
            sx={{ width: "400px" }}
          />
          <Select
            id="scheduleTypeId"
            name="scheduleTypeId"
            variant="standard"
            value={formState.scheduleTypeId.toString()}
            onChange={handleSelectChange}
            sx={{ minWidth: "100px" }}
          >
            {mainState.scheduleTypes.map((scheduleType) => (
              <MenuItem key={scheduleType.id} value={scheduleType.id}>
                <TagLabel tagName={scheduleType.name} colorNumber={scheduleType.colorNumber} />
              </MenuItem>
            ))}
          </Select>
        </div>

        <div>
          <div style={{ display: "flex", alignItems: "center", marginBottom: "20px", gap: "12px" }}>
            <ScheduleIcon />
            <div>
              <div style={{ display: "flex" }}>
                <CustomDatePicker
                  id="startTime"
                  name="startTime"
                  date={formState.startTime ? new Date(formState.startTime) : null}
                  onChange={handleChangeDateTime}
                  showTimeSelect
                  timeIntervals={30}
                  dateFormat={DATE_TIME_SLASH_FORMAT}
                  popperPlacement="right-start"
                  popperModifiers={startDatePopperPosition}
                  placeholder="開始日時"
                />
                <p>〜</p>
                <CustomDatePicker
                  id="endTime"
                  name="endTime"
                  date={formState.endTime ? new Date(formState.endTime) : null}
                  onChange={handleChangeDateTime}
                  showTimeSelect
                  timeIntervals={30}
                  dateFormat={DATE_TIME_SLASH_FORMAT}
                  popperPlacement="right-start"
                  popperModifiers={endDatePopperPosition}
                  placeholder="終了日時"
                />
              </div>
              <div style={{ display: "flex", alignItems: "center", gap: "16px" }}>
                <Typography sx={{ fontSize: "12px", fontWeight: "500" }}>
                  繰り返し・長期予定
                </Typography>
                <Select
                  value={selectedRecurrenceRule.keyName}
                  variant="standard"
                  onChange={handleChangeRecurrence}
                  disabled={!formState.startTime || !formState.endTime || hasOtherHubUsers}
                  sx={{ minWidth: "70px", maxWidth: "70%" }}
                >
                  {!hasOtherHubUsers &&
                    recurrenceRuleOptions?.map((option: RecurrenceRule, index) => (
                      <MenuItem key={index} value={option.keyName} data-params={option.params}>
                        {option.label}
                      </MenuItem>
                    ))}
                  {!hasOtherHubUsers &&
                    recurrenceSettingModalState.recurrenceRule &&
                    recurrenceSettingModalState.recurrenceRule.label !== "" && (
                      <MenuItem
                        value="customized"
                        data-params={recurrenceSettingModalState.recurrenceRule.params}
                      >
                        {recurrenceSettingModalState.recurrenceRule.label}
                      </MenuItem>
                    )}
                </Select>
                {hasOtherHubUsers && (
                  <Tooltip title="他社Hubのメンバーが含まれる場合、繰り返し設定はできません">
                    <HelpOutlineIcon
                      sx={{ fontSize: "17px", color: theme.palette.grayScale[500] }}
                    />
                  </Tooltip>
                )}
              </div>
            </div>
          </div>
        </div>

        <Divider sx={{ my: "24px" }} />

        <div style={{ display: "flex", gap: "12px" }}>
          <Person2OutlinedIcon sx={{ mt: "20px" }} />
          <div style={{ width: "100%" }}>
            <div style={{ marginBottom: "12px" }}>
              <SelectedMembers
                allOptions={allOptions}
                selectedAccounts={selectedAccounts}
                selectedSiteManagerId={formState.siteManagerId}
                inputValue={inputValue}
                setInputValue={setInputValue}
                onChangeUsers={handleChangeUsers}
                handleClear={handleClear}
                filterOptions={filterOptions}
                groupByFunction={groupByFunction}
                popperPlacement="right-start"
                scrollKey="project-schedule-create-modal-members"
              />
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: "8px", mt: "12px" }}>
                {selectedAccounts.map(
                  (selectedAccount, index) =>
                    selectedAccount.hub.uuid === mainState.hub.uuid && (
                      <SelectedMemberLabel
                        key={index}
                        userId={selectedAccount.userId}
                        userName={selectedAccount.name}
                        isSiteManager={selectedAccount.userId === formState.siteManagerId}
                        isOverlapped={selectedAccount.isScheduleOverlapped}
                        overlapType={selectedAccount.scheduleOverlappingType}
                        onClear={
                          selectedAccount.userId !== formState.siteManagerId
                            ? () => handleClear(selectedAccount.userId)
                            : undefined
                        }
                        icon={
                          selectedAccount.userId === formState.siteManagerId ? (
                            <SiteManagerIcon />
                          ) : undefined
                        }
                      />
                    ),
                )}
              </Box>
            </div>

            <FormControl variant="standard" fullWidth>
              <InputLabel id="site-manager-select-label">現場責任者を選択</InputLabel>
              <Select
                labelId="site-manager-select-label"
                id="siteManagerId"
                name="siteManagerId"
                value={formState.siteManagerId || 0}
                onChange={handleChange}
              >
                <MenuItem value={0}>未設定</MenuItem>
                {selectableSiteManagers?.map((user) => (
                  <MenuItem key={user.userId} value={user.userId}>
                    {user.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControlLabel
              label="予定を確定"
              control={<Checkbox name="isConfirmed" onChange={handleChangeRadioButton} />}
              checked={formState.isConfirmed}
              sx={{
                mb: "12px",
                mt: "8px",
                "& .MuiFormControlLabel-label": { fontSize: "14px", fontWeight: "bold" },
              }}
            />

            <div>
              {hasOtherHubUsers && (
                <CustomDivider title="自社が招待したHub" color={theme.palette.grayScale[700]} />
              )}
              {hubGroups.map(
                (hubGroup) =>
                  hubGroup.hub.uuid !== mainState.hub.uuid && (
                    <Box key={hubGroup.hub.uuid} sx={{ mb: "16px" }}>
                      <Box display="flex" alignItems="center" gap="8px">
                        <Avatar
                          url={hubGroup.hub.logoUrl}
                          bgColor={hubGroup.hub.logoBackgroundColorNumber}
                          name={hubGroup.hub.name}
                          size="small"
                        />
                        <Typography sx={{ fontSize: "14px", mb: "4px" }}>
                          {hubGroup.hub.name}
                        </Typography>
                        <Button
                          startIcon={<CloseIcon size={18} />}
                          onClick={(e) => {
                            const updatedAccounts = selectedAccounts.filter(
                              (account) => account.hub?.uuid !== hubGroup.hub.uuid,
                            );
                            handleChangeUsers(e, updatedAccounts);
                          }}
                          color="primary"
                          sx={{ p: 0, minWidth: "auto", "& .MuiButton-icon": { m: 0 } }}
                        />
                      </Box>
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: "8px", mt: "12px" }}>
                        {hubGroup.users.map((user) => (
                          <SelectedMemberLabel
                            key={user.userId}
                            userId={user.userId}
                            userName={user.name}
                            onClear={() => handleClear(user.userId)}
                          />
                        ))}
                      </Box>
                    </Box>
                  ),
              )}
            </div>
          </div>
        </div>

        <Divider sx={{ my: "24px" }} />

        <div style={{ display: "flex", gap: "16px" }}>
          <FormatAlignLeftIcon />
          <div style={{ width: "100%" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "8px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: "bold",
                  color: theme.palette.grayScale[700],
                }}
              >
                作業内容・予定メモ
              </Typography>
              <Button
                variant="outlined"
                onClick={() => setFormState({ ...formState, note: "" })}
                sx={{ height: "32px", width: "98px", fontSize: "11px", fontWeight: "bold" }}
              >
                内容をクリア
              </Button>
            </div>
            <TextField
              id="note"
              name="note"
              multiline
              rows={4}
              value={formState.note}
              onChange={handleChange}
              fullWidth
              sx={{ mb: "8px" }}
            />
          </div>
        </div>

        <div style={{ display: "flex", alignItems: "center" }}>
          <LockIcon fontSize="small" sx={{ mr: "16px" }} />
          <FormControlLabel
            label="予定を非公開にする"
            control={<Checkbox name="isPrivate" onChange={handleChangeRadioButton} />}
            checked={formState.isPrivate}
            sx={{
              "& .MuiFormControlLabel-label": {
                fontSize: "14px",
                fontWeight: "bold",
                mr: "0px",
              },
            }}
          />
          <Tooltip
            title="予定を非公開にすると、予定の作成者と参加者以外が予定の詳細を見ることができなくなります。"
            placement="top"
          >
            <HelpOutlineIcon
              sx={{ fontSize: "17px", mt: "2px", color: theme.palette.primary.main }}
            />
          </Tooltip>
        </div>

        {errorMessage && (
          <Flash title="エラー" severity="error" message={errorMessage} sx={{ mt: "1rem" }} />
        )}
        <div ref={scrollBottomRef} />
      </CustomModal>
    </>
  );
};

import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ChevronLeft } from "@mui/icons-material";
import { Alert, Box, Button, SelectChangeEvent, Typography } from "@mui/material";
import { Layout } from "components/templates/layout";
import { csvImportScheduleRepository } from "features/hub-settings/api/csv-import/csv_import_schedule.repository";
import {
  ColumnMap,
  CsvImportScheduleAnalyzeResponse,
  CsvImportScheduleRequest,
  initialImportCsvScheduleRequest,
} from "features/hub-settings/types/csv-import/csv_import_schedule.dto";
import { useAppDispatch } from "store/hooks";
import { mainOperations } from "store/main/operations";
import useSWR from "swr";
import { API_PATHS } from "utils/apiPaths";
import { handleReduxError } from "utils/errorHandler";
import { getFileUrl } from "utils/getFileUrl";
import { AnalyzeModal } from "./modal";
import { MappingTable } from "./table";

export const ScheduleCsvImportMapping = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [csvHeaders, setCsvHeaders] = useState<string[]>([]);
  const [formState, setFormState] = useState<CsvImportScheduleRequest>(
    initialImportCsvScheduleRequest,
  );
  const [analyzeResult, setAnalyzeResult] = useState<CsvImportScheduleAnalyzeResponse>({
    createCount: 0,
    estimatedTimeSeconds: 0,
  });
  const [isAnalyzeModalOpen, setIsAnalyzeModalOpen] = useState<boolean>(false);
  const fileUrl = getFileUrl();

  const { data: importLatest } = useSWR(
    API_PATHS.getScheduleImportJobsLatest(),
    csvImportScheduleRepository.latest,
    {
      revalidateOnFocus: false,
    },
  );

  useEffect(() => {
    const getCsvHeaders = async () => {
      const url = `${fileUrl}/${location.state.objectKey}`;
      const res = await fetch(url);
      const text = await res.text();
      const headers = text
        .replace(/^\ufeff/, "")
        .split(/\r?\n/)[0]
        .split(",");
      setCsvHeaders(headers);

      const columnMap = importLatest?.columnMap || formState.columnMap;
      (Object.keys(columnMap) as Array<keyof ColumnMap>).forEach((key) => {
        if (!headers.includes(columnMap[key])) {
          columnMap[key] = "";
        }
      });

      setFormState({
        ...formState,
        objectKey: location.state.objectKey,
        filename: location.state.fileName,
        columnMap,
      });
    };
    getCsvHeaders();
  }, [location.state.objectKey]);

  const handleChange = (e: SelectChangeEvent<string>) => {
    setFormState((prev) => ({
      ...prev,
      columnMap: {
        ...prev.columnMap,
        [e.target.name]: e.target.value,
      },
    }));
  };

  const handleSubmit = async () => {
    dispatch(mainOperations.updateIsLoading(true));
    try {
      const res = await csvImportScheduleRepository.analyze({
        objectKey: formState.objectKey,
      });
      setAnalyzeResult(res);
      setIsAnalyzeModalOpen(true);
    } catch (error) {
      handleReduxError(error, dispatch, "クイック検証に失敗しました");
    } finally {
      dispatch(mainOperations.updateIsLoading(false));
    }
  };

  const handleImportStart = async () => {
    try {
      await csvImportScheduleRepository.create(formState);
    } catch (error) {
      handleReduxError(error, dispatch, "インポートに失敗しました");
    } finally {
      navigate("/hub-settings/csv-imports/schedule");
    }
  };

  return (
    <Layout>
      <AnalyzeModal
        isOpen={isAnalyzeModalOpen}
        onClose={() => setIsAnalyzeModalOpen(false)}
        state={analyzeResult}
        onSubmit={handleImportStart}
      />

      <Box>
        <div
          onClick={() => navigate(-1)}
          style={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          <ChevronLeft />
          <Typography sx={{ fontSize: "20px", fontWeight: "bold", marginLeft: "8px" }}>
            項目のマッピング
          </Typography>
        </div>
        <Typography sx={{ fontSize: "12px", mt: "8px" }}>
          現場Hubの予定項目と対応する、CSVファイルのカラムを選択してください。
        </Typography>
        <Alert severity="info" sx={{ "&.MuiPaper-rounded": { alignItems: "center", my: "20px" } }}>
          インポート中も予定の新規作成が可能です。
          <br />
          予定100件のインポートには、約10秒かかります。
        </Alert>

        <MappingTable formState={formState} csvHeaders={csvHeaders} onChange={handleChange} />

        <Box>
          <Button
            onClick={handleSubmit}
            variant="contained"
            sx={{ fontWeight: "bold", height: "36px", width: "160px", mr: "16px" }}
          >
            クイック検証
          </Button>
          <Button
            onClick={() => navigate(-1)}
            variant="outlined"
            sx={{ height: "36px", width: "160px" }}
          >
            キャンセル
          </Button>
        </Box>
      </Box>
    </Layout>
  );
};

import { convertKeysToCamelCase, convertKeysToSnakeCase } from "utils/convertObjectKeyCase";
import {
  AccountInvitationRequest,
  AccountInvitationResponse,
  AccountInvitationId,
} from "./account_invitation.dto";
import { ApiClient } from "../../ApiClient";

export const accountInvitationRepository = {
  index(): Promise<AccountInvitationResponse> {
    const params = {
      token_type: "account_invitation",
      is_used: false,
    };
    return ApiClient.get("/api/v1/account_invitations", { params }).then((res) =>
      convertKeysToCamelCase(res.data),
    );
  },
  invite(body: AccountInvitationRequest): Promise<void> {
    const config = { headers: { "content-type": "application/json" } };
    const params = {
      accounts: body.accounts.map((account) => ({
        ...account,
        group_id: account.groupId === 0 ? null : account.groupId,
      })),
    };
    return ApiClient.post(
      "/api/v1/account_invitations",
      convertKeysToSnakeCase(params),
      config,
    ).then((res) => res.data);
  },
  resend(id: AccountInvitationId): Promise<void> {
    return ApiClient.put(`/api/v1/account_invitations/${id}`).then((res) => res.data);
  },
};

import { convertKeysToCamelCase } from "utils/convertObjectKeyCase";
import { WarrantyResponse, WarrantyCreateRequest } from "./warranty.dto";
import { ApiClient } from "../../../ApiClient";
import { BillingId } from "../billing.dto";

export const warrantyRepository = {
  show(billingId: BillingId): Promise<WarrantyResponse[]> {
    return ApiClient.get(`/api/v1/billings/${billingId}/warranties`).then((res) =>
      convertKeysToCamelCase(res.data),
    );
  },
  async create(billingId: BillingId, body: WarrantyCreateRequest) {
    await Promise.all(
      body.files.map(async (file) => {
        try {
          const preSignedUrlResponse = await ApiClient.get(
            `/api/v1/billings/${billingId}/warranties/put_presigned_url?file_name=${encodeURIComponent(
              file.name,
            )}`,
          );

          const preSignedUrlData = preSignedUrlResponse.data;
          if (preSignedUrlResponse.status !== 200 || !preSignedUrlData.url) {
            throw new Error("Failed to get presigned URL");
          }

          const uploadResponse = await fetch(preSignedUrlData.url, {
            method: "PUT",
            body: file,
            headers: {
              "Content-Type": file.type,
            },
          });

          if (uploadResponse.status !== 200) {
            throw new Error("Failed to upload file");
          }

          await ApiClient.post(`/api/v1/billings/${billingId}/warranties`, {
            file_name: file.name,
            object_key: preSignedUrlData.object_key,
          });
        } catch (error) {
          throw new Error(error.response.data.message);
        }
      }),
    );
  },
  destroy(billingId: BillingId, id: number): Promise<void> {
    return ApiClient.delete(`/api/v1/billings/${billingId}/warranties/${id}`).then(
      (res) => res.data,
    );
  },
  excelFilePreview(billingId: BillingId, id: number): Promise<{ url: string }> {
    return ApiClient.get(`/api/v1/billings/${billingId}/warranties/${id}/excel_file_preview`).then(
      (res) => res.data,
    );
  },
};

import { useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { Close, OpenInNew } from "@mui/icons-material";
import {
  Autocomplete,
  AutocompleteRenderGroupParams,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Popper,
  TextField,
  Typography,
} from "@mui/material";
import { Avatar } from "components/avatar";
import { GroupLabel } from "components/label/group-label";
import { SelectedMemberLabel } from "components/label/selected-member-label";
import { UserId } from "data-access/repositories/account/account.dto";
import { accountRepository } from "data-access/repositories/account/account.repository";
import { GroupId } from "data-access/repositories/group/group.dto";
import { projectParticipantRepository } from "data-access/repositories/project/participant/participant.repository";
import { ProjectId } from "data-access/repositories/project/project.dto";
import { HubInfoData } from "features/hub-settings/types/hub-info/hub_info.dto";
import { useAutocompleteScroll } from "hooks/useAutocompleteScroll";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { mainOperations } from "store/main/operations";
import { selectMain } from "store/main/slice";
import useSWR, { mutate } from "swr";
import { API_PATHS } from "utils/apiPaths";
import { handleReduxError } from "utils/errorHandler";
import { getHubGroups } from "utils/hubGrouping";
import { styles } from "./styles";

interface FormStateAccount {
  name: string;
  userId: UserId;
  source: string;
  hub: HubInfoData;
  group?: {
    id: GroupId;
    name: string;
    colorNumber: string;
  } | null;
}

interface Props {
  isOpen: boolean;
  onClose: () => void;
}
export const InvitationModal = (props: Props) => {
  const mainState = useAppSelector(selectMain);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const projectId: ProjectId = Number(location.pathname.split("/")[2]) as ProjectId;
  const [formState, setFormState] = useState<FormStateAccount[]>([]);

  const { getListboxProps, restoreScrollPosition } = useAutocompleteScroll("invitation-modal");

  const { data: invitableAccounts } = useSWR(
    props.isOpen ? "/api/v1/accounts/invitable" : null,
    accountRepository.invitable,
    { revalidateOnFocus: false },
  );
  const { data: participants } = useSWR(
    props.isOpen ? `/api/v1/projects/${projectId}/participants` : null,
    () => projectParticipantRepository.index(projectId),
    { revalidateOnFocus: false },
  );

  const selectedUserIds = useMemo(() => {
    return formState.map((account) => account.userId);
  }, [formState]);

  // 既に参加しているユーザーのIDリスト（削除済みユーザーを除く）
  const participantUserIds = useMemo(() => {
    return (
      participants?.flatMap((participant) =>
        participant.participants
          .filter((account) => !account.isDeleted)
          .map((account) => account.userId),
      ) || []
    );
  }, [participants]);

  // 自社のHubアカウントから、まだ参加していないアカウント
  const selectedOwnHubAccounts = useMemo(() => {
    return (
      invitableAccounts?.filter(
        (account) =>
          account.hub.uuid === mainState.hub.uuid && !participantUserIds.includes(account.userId),
      ) || []
    );
  }, [invitableAccounts, participantUserIds]);

  // 連携しているHubアカウントから、まだ参加していないアカウント
  const filteredConnectedHubAccounts = useMemo(() => {
    return (
      invitableAccounts?.filter(
        (account) =>
          account.hub.uuid !== mainState.hub.uuid && !participantUserIds.includes(account.userId),
      ) || []
    );
  }, [invitableAccounts, participantUserIds]);

  // 両方のアカウントを結合して表示用のオプション
  const autocompleteOptions = useMemo<FormStateAccount[]>(() => {
    const ownOptions: FormStateAccount[] = selectedOwnHubAccounts
      .filter((account) => !selectedUserIds.includes(account.userId))
      .map((account) => ({
        ...account,
        source: "自社メンバー",
      }));

    const connectedOptions: FormStateAccount[] = filteredConnectedHubAccounts
      .filter((account) => !selectedUserIds.includes(account.userId))
      .map((account) => ({
        ...account,
        source: "コネクトしているHub",
      }));

    return [...ownOptions, ...connectedOptions];
  }, [selectedUserIds, selectedOwnHubAccounts, filteredConnectedHubAccounts]);

  const handleSelectAccount = (selectedAccounts: FormStateAccount[]) => {
    const formattedAccounts = selectedAccounts.map((account) => ({
      name: account.name,
      userId: account.userId,
      source: account.source,
      hub: account.hub || null,
      group: account.group || null,
    }));
    setFormState(formattedAccounts);

    restoreScrollPosition();
  };

  const handleClickOpenNewTab = () => {
    window.open("/hub-settings/connects", "_blank");
  };

  const handleSubmit = async () => {
    dispatch(mainOperations.updateIsLoading(true));
    try {
      // 既存の参加者（自hub、招待したhub両方）
      const existingUserIds =
        participants?.flatMap((participant) =>
          participant.participants
            .filter((account) => !account.isDeleted)
            .filter((account) => account.canDelete)
            .map((account) => account.userId),
        ) || [];

      const newUserIds = formState.map((form) => form.userId);
      const allUserIds = [...new Set([...existingUserIds, ...newUserIds])];

      await projectParticipantRepository.invitation(projectId, {
        user_ids: allUserIds,
      });
      mutate(API_PATHS.getProjectParticipants(projectId));
      handleClose();
      dispatch(mainOperations.updateSuccessMessage("招待しました"));
    } catch (error) {
      handleReduxError(error, dispatch, "招待に失敗しました");
    } finally {
      dispatch(mainOperations.updateIsLoading(false));
    }
  };

  const handleClose = () => {
    props.onClose();
    setFormState([]);
  };

  const filterOptions = (options: FormStateAccount[], { inputValue }: { inputValue: string }) => {
    const filterValue = inputValue.toLowerCase().trim();

    if (!filterValue) {
      return options;
    }

    return options.filter(
      (option) =>
        option.name.toLowerCase().includes(filterValue) ||
        (option.group?.name && option.group.name.toLowerCase().includes(filterValue)) ||
        (option.hub?.name && option.hub.name.toLowerCase().includes(filterValue)),
    );
  };

  const getHubGroupsData = useMemo(() => {
    return getHubGroups(
      formState.map((account) => ({
        userId: account.userId,
        name: account.name,
        hub: account.hub,
      })),
    );
  }, [formState]);

  return (
    <Dialog open={props.isOpen} onClose={handleClose} fullWidth>
      <DialogTitle sx={{ ...styles.title }}>
        案件に招待
        <IconButton onClick={handleClose} sx={{ ...styles.closeButton }}>
          <Close />
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ mt: "24px" }}>
        <Box sx={{ display: "flex", gap: "16px", alignItems: "center", mb: "20px" }}>
          <Autocomplete
            options={autocompleteOptions}
            value={formState}
            isOptionEqualToValue={(option, value) => option.userId === value.userId}
            groupBy={(option) => option.source}
            filterOptions={filterOptions}
            renderGroup={renderGroup}
            onChange={(_, selectUsers) => handleSelectAccount(selectUsers)}
            renderInput={(params) => <TextField {...params} variant="standard" label="Hubを選択" />}
            getOptionLabel={(option) => option.name}
            renderOption={(props, option) => {
              const { key, ...otherProps } = props;
              return (
                <Box
                  component="li"
                  key={key}
                  {...otherProps}
                  sx={{ display: "flex", alignItems: "center", gap: 1 }}
                >
                  <Typography
                    sx={{
                      flex: 1,
                      maxWidth: "180px",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {option.name}
                  </Typography>
                  {option.hub.uuid !== mainState.hub.uuid ? (
                    <Avatar
                      url={option.hub.logoUrl}
                      bgColor={option.hub.logoBackgroundColorNumber}
                      name={option.hub.name}
                      size="small"
                    />
                  ) : (
                    <GroupLabel
                      name={option.group?.name || ""}
                      colorNumber={option.group?.colorNumber || ""}
                    />
                  )}
                  {option.hub.uuid !== mainState.hub.uuid && (
                    <Typography variant="caption">{option.hub.name}</Typography>
                  )}
                </Box>
              );
            }}
            multiple
            disableCloseOnSelect
            disableClearable
            renderTags={() => null}
            ListboxProps={getListboxProps()}
            slots={{
              popper: (props) => (
                <Popper {...props} placement="top-end" sx={{ maxWidth: "320px" }} />
              ),
            }}
            fullWidth
          />
          <Button
            variant="contained"
            onClick={handleSubmit}
            sx={{ height: "40px", width: "80px" }}
            disabled={!formState.length}
          >
            招待
          </Button>
        </Box>

        <Box sx={{ display: "flex", flexWrap: "wrap", gap: "8px", mb: "12px" }}>
          {formState.map(
            (state) =>
              state.hub.uuid === mainState.hub.uuid && (
                <SelectedMemberLabel
                  key={state.userId}
                  userId={state.userId}
                  userName={state.name}
                  onClear={() =>
                    setFormState((currents) =>
                      currents.filter((current) => current.userId !== state.userId),
                    )
                  }
                />
              ),
          )}
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", mb: "20px" }}>
          {getHubGroupsData.map(
            (hubGroup) =>
              hubGroup.hub.uuid !== mainState.hub.uuid && (
                <Box
                  key={hubGroup.hub.uuid}
                  sx={{ display: "flex", flexDirection: "column", mb: "16px" }}
                >
                  <Box
                    key={hubGroup.hub.uuid}
                    display="flex"
                    alignItems="center"
                    gap="8px"
                    mb="8px"
                  >
                    <Avatar
                      key={hubGroup.hub.uuid}
                      url={hubGroup.hub.logoUrl}
                      name={hubGroup.hub.name}
                      bgColor={hubGroup.hub.logoBackgroundColorNumber}
                      size="small"
                    />
                    <Typography fontSize="14px">{hubGroup.hub.name}</Typography>
                  </Box>
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: "8px", ml: "36px" }}>
                    {hubGroup.users.map((user) => (
                      <SelectedMemberLabel
                        key={user.userId}
                        userId={user.userId}
                        userName={user.name}
                        onClear={() =>
                          setFormState((currents) =>
                            currents.filter((current) => current.userId !== user.userId),
                          )
                        }
                      />
                    ))}
                  </Box>
                </Box>
              ),
          )}
        </Box>

        <Button
          onClick={handleClickOpenNewTab}
          sx={{ display: "flex", alignItems: "center", gap: "8px" }}
        >
          <Typography fontSize="14px" fontWeight="bold" color="primary">
            コネクト管理へ
          </Typography>
          <OpenInNew color="primary" fontSize="small" />
        </Button>
      </DialogContent>
    </Dialog>
  );
};
const renderGroup = (params: AutocompleteRenderGroupParams) => (
  <div key={params.key}>
    <Typography variant="caption" fontWeight="bold" fontSize="12px" sx={{ p: 1, display: "block" }}>
      {params.group}
    </Typography>
    {params.children}
  </div>
);

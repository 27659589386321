import { convertKeysToCamelCase } from "utils/convertObjectKeyCase";
import { ApiClient } from "../../../ApiClient";
import { ActivityIndexResponse } from "../../activity/activity.dto";
import { ClientId } from "../client.dto";

export const clientActivityRepository = {
  index(id: ClientId, limit: number): Promise<ActivityIndexResponse> {
    return ApiClient.get(`/api/v1/clients/${id}/activities?limit=${limit}`).then((res) =>
      convertKeysToCamelCase(res.data),
    );
  },
};

import { useEffect, useState } from "react";
import { Box, TextField, Typography } from "@mui/material";
import { personalSettingRepository } from "data-access/repositories/personal_setting/personal_setting.repository";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { mainOperations } from "store/main/operations";
import { selectMain } from "store/main/slice";

export const Project = () => {
  const dispatch = useAppDispatch();
  const mainState = useAppSelector(selectMain);
  const [value, setValue] = useState<number>(1);

  useEffect(() => {
    if (!mainState.personalSetting) return;
    setValue(mainState.personalSetting.number_of_project_note_lines_to_display);
  }, [mainState.personalSetting.number_of_project_note_lines_to_display]);

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(Number(e.target.value));
  };

  const handleBlur = async (e: React.FocusEvent<HTMLInputElement>) => {
    try {
      await personalSettingRepository.update({
        numberOfProjectNoteLinesToDisplay: Number(e.target.value),
      });
      dispatch(mainOperations.getPersonalSetting());
      dispatch(mainOperations.updateSuccessMessage("表示行数を変更しました"));
    } catch (error) {
      dispatch(mainOperations.updateErrorMessage(error.response.data.message));
    }
  };

  return (
    <>
      <Box sx={{ mb: "40px" }}>
        <Typography fontWeight="700" fontSize="14px" lineHeight="16.8px" marginBottom="12px">
          案件の概要・メモの表示行数
        </Typography>
        <TextField
          type="number"
          value={value}
          onChange={handleChange}
          onBlur={handleBlur}
          helperText="1-50まで入力できます"
        />
      </Box>
    </>
  );
};

import { useRef, useState } from "react";
import React from "react";
import { LinkItEmail, LinkItUrl } from "react-linkify-it";
import { HelpOutlineOutlined } from "@mui/icons-material";
import { Box, TextField, Tooltip, Typography } from "@mui/material";
import { CustomFormLabel } from "components/molecules/custom-form-label";
import {
  ProjectFormNotesState,
  ProjectFormUpdateRequest,
  ProjectId,
} from "data-access/repositories/project/project.dto";
import { projectRepository } from "data-access/repositories/project/project.repository";
import { theme } from "extensions/theme";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { mainOperations } from "store/main/operations";
import { selectMain } from "store/main/slice";
import { handleReduxError } from "utils/errorHandler";

interface FormValues {
  note: string;
  internalNote: string;
  billingPrecaution: string;
}

interface Props {
  isCreateNew: boolean;
  projectId?: ProjectId;
  notesState: ProjectFormNotesState;
  setNotesState: React.Dispatch<React.SetStateAction<ProjectFormNotesState>>;
  lastSavedFormValue: React.MutableRefObject<ProjectFormUpdateRequest>;
  onCreateProject: (targetName: string, value: any) => void;
}

export const ProjectSidebarNoteContent = (props: Props) => {
  const mainState = useAppSelector(selectMain);
  const dispatch = useAppDispatch();

  const handleUpdate = async (currentValue: string | number | null, target: keyof FormValues) => {
    if (!props.projectId) return;
    if (currentValue !== props.lastSavedFormValue.current[target]) {
      dispatch(mainOperations.updateIsLoading(true));
      try {
        await projectRepository.update(props.projectId, {
          [target]: currentValue,
        });
        dispatch(mainOperations.updateSuccessMessage("更新しました"));
        props.lastSavedFormValue.current = {
          ...props.lastSavedFormValue.current,
          [target]: currentValue,
        };
      } catch (error) {
        handleReduxError(error, dispatch, "更新に失敗しました");
        props.setNotesState((prev) => ({
          ...prev,
          [target]: props.lastSavedFormValue.current[target],
        }));
      } finally {
        dispatch(mainOperations.updateIsLoading(false));
      }
    }
  };
  return (
    <>
      <Box sx={{ my: "1.7rem" }}>
        <CustomFormLabel
          labelName="概要・メモ"
          labelSize="14px"
          labelColor={theme.palette.grayScale[700]}
        />
        <NoteTextField
          note={props.notesState.note}
          linesToDisplay={mainState.personalSetting.number_of_project_note_lines_to_display}
          setNotesState={props.setNotesState}
          onUpdate={(value) => {
            if (props.isCreateNew) {
              props.onCreateProject("note", value);
            } else {
              handleUpdate(value, "note");
            }
          }}
        />
      </Box>
      <Box sx={{ my: "1.7rem" }}>
        <div style={{ display: "flex", justifyContent: "left", gap: "4px" }}>
          <CustomFormLabel
            labelName="社内用メモ"
            labelSize="14px"
            labelColor={theme.palette.grayScale[700]}
          />
          <Tooltip title="自社のメンバーにのみ表示されます" placement="right">
            <HelpOutlineOutlined fontSize="small" color="primary" />
          </Tooltip>
        </div>
        <TextField
          name="internalNote"
          type="string"
          value={props.notesState.internalNote}
          onChange={(e) => {
            props.setNotesState((prev) => ({ ...prev, internalNote: e.target.value }));
          }}
          onBlur={(e) => {
            const value = e.target.value;
            if (props.isCreateNew) {
              props.onCreateProject("internalNote", value);
            } else {
              handleUpdate(value, "internalNote");
            }
          }}
          placeholder="クリックして文章を入力"
          sx={{ width: "100%" }}
          multiline
          maxRows={4}
        />
      </Box>
      <Box sx={{ my: "1.7rem" }}>
        <CustomFormLabel
          labelName="請求に関する連絡事項"
          labelSize="14px"
          labelColor={theme.palette.grayScale[700]}
        />
        <TextField
          name="billingPrecaution"
          type="string"
          value={props.notesState.billingPrecaution}
          onChange={(e) => {
            props.setNotesState((prev) => ({ ...prev, billingPrecaution: e.target.value }));
          }}
          onBlur={(e) => {
            const value = e.target.value;
            if (props.isCreateNew) {
              props.onCreateProject("billingPrecaution", value);
            } else {
              handleUpdate(value, "billingPrecaution");
            }
          }}
          placeholder="クリックして文章を入力"
          sx={{ width: "100%" }}
          multiline
          maxRows={4}
        />
      </Box>
    </>
  );
};

interface NoteTextFieldProps {
  note: string;
  linesToDisplay: number;
  onUpdate: (value: string, target: keyof FormValues) => void;
  setNotesState: React.Dispatch<React.SetStateAction<ProjectFormNotesState>>;
}

const NoteTextField = (props: NoteTextFieldProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isDisplayEditField, setIsDisplayEditField] = useState<boolean>(false);

  const handleFocus = () => {
    setIsDisplayEditField(true);
    // 表示用のTexFieldをクリックしたとき編集用のTextFieldにフォーカスを当てる
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };
  return (
    <>
      {/* 編集用のTextField */}
      {isDisplayEditField ? (
        <TextField
          name="note"
          value={props.note}
          onChange={(e) => {
            props.setNotesState((prev) => ({ ...prev, note: e.target.value }));
          }}
          onBlur={(e) => {
            setIsDisplayEditField(false);
            const value = e.target.value;
            props.onUpdate(value, "note");
          }}
          placeholder="クリックして文章を入力"
          sx={{ width: "100%" }}
          multiline
          inputRef={inputRef}
        />
      ) : (
        <div
          style={{
            border: `1px solid ${theme.palette.grayScale[300]}`,
            borderRadius: "5px",
          }}
        >
          <Typography
            onClick={handleFocus}
            sx={{
              p: "14px",
              maxHeight: `${props.linesToDisplay * 24}px`,
              lineHeight: "24px",
              borderRadius: "5px",
              wordWrap: "break-word",
              overflow: "auto",
              color: props.note ? "inherit" : theme.palette.grayScale[500],
            }}
          >
            {props.note
              ? props.note.split("\n").map((str: string, index: number) => {
                  return (
                    <React.Fragment key={index}>
                      <LinkItUrl>
                        <LinkItEmail>
                          {str}
                          <br />
                        </LinkItEmail>
                      </LinkItUrl>
                    </React.Fragment>
                  );
                })
              : "クリックして文章を入力"}
          </Typography>
        </div>
      )}
    </>
  );
};

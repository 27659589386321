import { theme } from "extensions/theme";

export const styles = {
  scheduleName: {
    fontSize: "20px",
    fontWeight: "bold",
    wordBreak: "break-all",
    flex: 1,
  },
  headerFixed: {
    position: "sticky",
    top: 0,
    backgroundColor: theme.palette.grayScale[0],
    zIndex: 9,
  },
  sectionContainer: {
    display: "flex",
    marginBottom: "28px",
    gap: "16px",
  },
  sectionWithIcon: {
    display: "flex",
    alignItems: "center",
    marginBottom: "28px",
    gap: "16px",
  },
  flexRowCenter: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
  },
  flexColumn: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
  },
  sourceScheduleContainer: {
    display: "flex",
    alignItems: "center",
    p: "6px 8px",
    borderRadius: "4px",
    border: `1px solid ${theme.palette.grayScale[300]}`,
    gap: "8px",
    mb: "32px",
  },
  flexWrap: {
    display: "flex",
    flexWrap: "wrap",
    gap: "8px",
  },
  iconText: {
    display: "flex",
    gap: "4px",
    color: theme.palette.grayScale[700],
  },
  participantContainer: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    marginBottom: "8px",
  },
  dividerTitle: {
    mb: "8px",
    "& .MuiDivider-wrapper": {
      pl: 0,
    },
    "&::before": {
      width: 0,
    },
  },
  textWrap: {
    wordWrap: "break-word",
    width: "90%",
  },
  infoSection: {
    marginTop: "24px",
  },
  infoLabel: {
    marginBottom: "8px",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    gap: "12px",
    width: "100%",
    paddingTop: "16px",
  },
  hubSection: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  },
  participantsSection: {
    paddingLeft: "24px",
    display: "flex",
    flexWrap: "wrap",
    gap: "8px",
  },
  siteManagerItem: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    mb: "8px",
    whiteSpace: "nowrap",
  },

  participantsGrid: {
    display: "flex",
    flexWrap: "wrap",
    columnGap: "16px",
    rowGap: "8px",
  },
  participantItem: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
  },
  footerTitle: {
    fontSize: "12px",
    mb: "8px",
    fontWeight: "medium",
    color: theme.palette.grayScale[700],
  },
  footerButtons: {
    display: "flex",
    justifyContent: "space-between",
    gap: "16px",
  },
  footerContainer: {
    borderTop: `1px solid ${theme.palette.grayScale[200]}`,
    padding: "12px 16px",
  },
  participationButton: {
    width: "140px",
    height: "32px",
    borderColor: theme.palette.grayScale[300],
    color: theme.palette.grayScale[700],
  },
  modalContainer: {
    width: "30rem",
    display: "flex",
    flexDirection: "column",
    maxHeight: "80vh",
    position: "relative",
  },
  scrollableContent: {
    p: "0 1rem",
    flex: 1,
    overflowY: "auto",
  },
};

import { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { AddCircle } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { AttachmentTypeId } from "data-access/repositories/attachment_type/attachment_type.dto";
import { BillingId } from "data-access/repositories/billing/billing.dto";
import { ProjectId } from "data-access/repositories/project/project.dto";
import * as Encoding from "encoding-japanese";
import { theme } from "extensions/theme";
import { styles } from "./styles";

interface Props {
  onDrop: (files: File[], attachmentTypeId?: AttachmentTypeId) => void;
  attachmentTypeId?: AttachmentTypeId;
  moduleId?: ProjectId | BillingId;
  isDisable?: boolean;
  allowMultiple?: boolean;
  accept?: Record<string, string[]>;
  validateEncoding?: boolean;
  sx?: object;
}

export const FileDropzone = ({
  onDrop,
  attachmentTypeId,
  moduleId,
  accept,
  isDisable = false,
  allowMultiple = true,
  validateEncoding = false,
  sx,
}: Props) => {
  const classes = styles();
  const [errors, setErrors] = useState<string[]>([]);

  const validateFile = async (file: File): Promise<boolean> => {
    if (!validateEncoding) return true;

    try {
      const buffer = await file.arrayBuffer();
      const bytes = new Uint8Array(buffer);

      // エンコーディングを検出
      const detected = Encoding.detect(bytes);

      // UTF-8のみを許可
      return detected === "UTF8";
    } catch {
      return false;
    }
  };

  const handleDrop = useCallback(
    async (acceptedFiles: File[]) => {
      if (validateEncoding) {
        const validFiles: File[] = [];
        const invalidFiles: File[] = [];

        for (const file of acceptedFiles) {
          const isValid = await validateFile(file);
          if (isValid) {
            validFiles.push(file);
          } else {
            invalidFiles.push(file);
          }
        }

        if (invalidFiles.length > 0) {
          setErrors((prev) => [...prev, "invalid-encoding"]);
          return;
        }

        onDrop(validFiles, attachmentTypeId);
      } else {
        onDrop(acceptedFiles, attachmentTypeId);
      }
    },
    [attachmentTypeId, moduleId, validateEncoding],
  );

  const { fileRejections, getRootProps, getInputProps, isDragActive } = useDropzone({
    disabled: isDisable,
    onDrop: handleDrop,
    maxSize: 1024 * 1024 * 500,
    multiple: allowMultiple,
    maxFiles: allowMultiple ? undefined : 1,
    accept,
  });

  // エラーの種類を取得
  useEffect(() => {
    if (fileRejections.length === 0) {
      setErrors((prev) =>
        prev.filter((error) => error !== "file-too-large" && error !== "too-many-files"),
      );
      return;
    }
    const rejectionErrorCodes = fileRejections.map((rejection) => {
      return rejection.errors.map((error) => {
        return error.code;
      });
    });
    setErrors((prev) => [...new Set([...prev, ...rejectionErrorCodes.flat()])]);
  }, [fileRejections]);

  return (
    <Box sx={{ my: "16px", ...sx }}>
      <div {...getRootProps({ className: classes.dropzone })}>
        {!isDisable && <input {...getInputProps()} />}
        {isDragActive && (
          <Box className={classes.draggingOverlayContainer}>
            <AddCircle />
            <Typography sx={{ fontWeight: "bold" }}>ドラッグ＆ドロップでファイル追加</Typography>
          </Box>
        )}
        <AddFileZone isDisable={isDisable} />
      </div>

      <ErrorContainer errors={errors} />
    </Box>
  );
};

const AddFileZone = ({ isDisable }: { isDisable?: boolean }) => {
  return (
    <div style={{ padding: "28px 0" }}>
      <Box
        sx={{
          textAlign: "center",
          cursor: isDisable ? "not-allowed" : "pointer",
        }}
      >
        {!isDisable ? (
          <>
            <Typography sx={{ fontWeight: "bold" }}>クリックしてファイルを選択</Typography>
            <Typography sx={{ fontWeight: "bold" }}>ドラッグ＆ドロップでアップロード</Typography>
          </>
        ) : (
          <Typography sx={{ fontWeight: "bold", color: theme.palette.grey[400] }}>
            利用できません
          </Typography>
        )}
      </Box>
    </div>
  );
};

const ErrorContainer = ({ errors }: { errors: string[] }) => {
  return (
    <>
      {errors.some((error) => error === "file-too-large") && (
        <Typography sx={{ color: theme.palette.error.main, fontWeight: "bold" }}>
          ファイルサイズが500MBを超えています
        </Typography>
      )}
      {errors.some((error) => error === "too-many-files") && (
        <Typography sx={{ color: theme.palette.error.main, fontWeight: "bold" }}>
          ファイルは1つだけ選択してください
        </Typography>
      )}
      {errors.some((error) => error === "file-invalid-type") && (
        <Typography sx={{ color: theme.palette.error.main, fontWeight: "bold" }}>
          CSVファイルのみアップロード可能です
        </Typography>
      )}
      {errors.some((error) => error === "invalid-encoding") && (
        <Typography sx={{ color: theme.palette.error.main, fontWeight: "bold" }}>
          UTF-8でエンコードされたファイルのみアップロード可能です
        </Typography>
      )}
    </>
  );
};

import { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { Close, EventOutlined } from "@mui/icons-material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import Person2OutlinedIcon from "@mui/icons-material/Person2Outlined";
import SearchIcon from "@mui/icons-material/Search";
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  MenuItem,
  Popper,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Avatar } from "components/avatar";
import { ConnectTypeLabel } from "components/label/connect-type-label";
import { SelectedMemberLabel } from "components/label/selected-member-label";
import { CustomFormLabel } from "components/molecules/custom-form-label";
import { UserId } from "data-access/repositories/account/account.dto";
import { theme } from "extensions/theme";
import { connectRequestRepository } from "features/hub-settings/api/connect/connect_request.repository";
import { hubRepository } from "features/hub-settings/api/hub-info/hub_info.repository";
import { ConnectTypeValue } from "features/hub-settings/types/connect/connect.dto";
import {
  ConnectRequest,
  initialConnectRequest,
} from "features/hub-settings/types/connect/connect_request.dto";
import { HubInfoData } from "features/hub-settings/types/hub-info/hub_info.dto";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { mainOperations } from "store/main/operations";
import { selectMain } from "store/main/slice";
import { mutate } from "swr";
import { API_PATHS } from "utils/apiPaths";
import { handleReduxError } from "utils/errorHandler";
import { styles } from "./styles";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}
export const ConnectCreateModal = (props: Props) => {
  const mainState = useAppSelector(selectMain);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [collaborationType, setCollaborationType] = useState<"email" | "hubId">("email");
  const [formState, setFormState] = useState<ConnectRequest>(initialConnectRequest);

  const filteredInvitableUsers = useMemo((): {
    selectedHubUsers: { id: UserId; name: string }[];
    unselectedHubUsers: { id: UserId; name: string }[];
  } => {
    const activeHubUsers = mainState.accounts.filter(
      (hubUser) => !hubUser.isDeactivate && hubUser.hasEmail,
    );
    const selectedHubUsers = activeHubUsers
      .filter((hubUser) => formState.addInvitableAccountUserIds.includes(hubUser.userId))
      .map((hubUser) => ({ id: hubUser.userId, name: hubUser.name }));
    const unselectedHubUsers = activeHubUsers
      .filter((hubUser) => !formState.addInvitableAccountUserIds.includes(hubUser.userId))
      .map((hubUser) => ({ id: hubUser.userId, name: hubUser.name }));
    return {
      selectedHubUsers,
      unselectedHubUsers,
    };
  }, [formState.addInvitableAccountUserIds]);

  const filteredScheduleViewableUsers = useMemo((): {
    selectedHubUsers: { id: UserId; name: string }[];
    unselectedHubUsers: { id: UserId; name: string }[];
  } => {
    const selectedHubUsers = filteredInvitableUsers.selectedHubUsers
      .filter((hubUser) => formState.addScheduleViewableAccountUserIds.includes(hubUser.id))
      .map((hubUser) => ({ id: hubUser.id, name: hubUser.name }));
    const unselectedHubUsers = filteredInvitableUsers.selectedHubUsers
      .filter((hubUser) => !formState.addScheduleViewableAccountUserIds.includes(hubUser.id))
      .map((hubUser) => ({ id: hubUser.id, name: hubUser.name }));
    return {
      selectedHubUsers,
      unselectedHubUsers,
    };
  }, [formState.addScheduleViewableAccountUserIds, filteredInvitableUsers]);

  useEffect(() => {
    if (location.state) {
      setFormState((prev) => ({
        ...prev,
        connectId: location.state.id,
      }));
    }
  }, [location]);

  const handleChangeConnectType = (e: SelectChangeEvent<ConnectTypeValue>) => {
    setFormState((prev) => ({
      ...prev,
      connectType: e.target.value as ConnectRequest["connectType"],
    }));
  };

  const handleSelectInvitableUser = (selectedHubUserIds: UserId[]) => {
    setFormState((prev) => ({
      ...prev,
      addInvitableAccountUserIds: selectedHubUserIds,
    }));
  };
  const handleSelectScheduleViewableUser = (selectedHubUserIds: UserId[]) => {
    setFormState((prev) => ({
      ...prev,
      addScheduleViewableAccountUserIds: selectedHubUserIds,
    }));
  };

  const handleClearInvitableUser = (hubUserId: UserId) => {
    setFormState((prev) => ({
      ...prev,
      addInvitableAccountUserIds: prev.addInvitableAccountUserIds.filter(
        (userId) => userId !== hubUserId,
      ),
      addScheduleViewableAccountUserIds: prev.addScheduleViewableAccountUserIds.filter(
        (userId) => userId !== hubUserId,
      ),
    }));
  };

  const handleClearScheduleViewableUser = (hubUserId: UserId) => {
    setFormState((prev) => ({
      ...prev,
      addScheduleViewableAccountUserIds: prev.addScheduleViewableAccountUserIds.filter(
        (accountId) => accountId !== hubUserId,
      ),
    }));
  };

  const handleChangeCollaborationType = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newType = e.target.value as "email" | "hubId";
    setCollaborationType(newType);

    setFormState((prev) => ({
      ...prev,
      destinationEmail: newType === "email" ? prev.destinationEmail : null,
      destinationHubUuid: newType === "hubId" ? prev.destinationHubUuid : null,
    }));
    setSearchedHub(null);
    setErrorMessage("");
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormState((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [searchedHub, setSearchedHub] = useState<HubInfoData | null>(null);
  const scrollBottomRef = useRef<HTMLDivElement>(null);

  const handleSearch = async () => {
    if (!formState.destinationHubUuid) return;
    setErrorMessage("");
    setSearchedHub(null);
    setIsSearching(true);
    try {
      const hubInfo = await hubRepository.show(formState.destinationHubUuid);
      setSearchedHub(hubInfo);
    } catch {
      setErrorMessage("Hubが見つかりませんでした");
    } finally {
      setIsSearching(false);
    }
  };

  useLayoutEffect(() => {
    if (!isSearching) {
      scrollBottomRef?.current?.scrollIntoView();
    }
  }, [isSearching]);

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const handleSubmit = async () => {
    setIsSubmitting(true);
    dispatch(mainOperations.updateIsLoading(true));
    try {
      await connectRequestRepository.create(formState);
      dispatch(mainOperations.updateSuccessMessage("コネクト申請が完了しました"));
      mutate(API_PATHS.getConnectRequestsRequesting());
      handleClose();
    } catch (error) {
      handleReduxError(error, dispatch, "コネクトの申請に失敗しました");
    } finally {
      setIsSubmitting(false);
      dispatch(mainOperations.updateIsLoading(false));
    }
  };

  const handleClose = () => {
    setFormState(initialConnectRequest);
    setCollaborationType("email");
    setErrorMessage("");
    setSearchedHub(null);
    props.onClose();
  };

  const isSubmitDisabled = useMemo(() => {
    // 送信中は無効化
    if (isSubmitting) return true;

    const needsInvitableMembers =
      (formState.connectType === "order_and_receive" || formState.connectType === "receive") &&
      formState.addInvitableAccountUserIds.length === 0;

    const needsEmail = collaborationType === "email" && !formState.destinationEmail;
    const needsHubSearchResult = collaborationType === "hubId" && !searchedHub;

    return needsInvitableMembers || needsEmail || needsHubSearchResult;
  }, [
    collaborationType,
    formState.connectType,
    formState.destinationEmail,
    searchedHub,
    isSubmitting,
    formState.addInvitableAccountUserIds,
  ]);

  return (
    <Dialog open={props.isOpen} onClose={handleClose} fullWidth>
      <DialogTitle sx={{ ...styles.title }}>
        コネクトを申請
        <IconButton onClick={handleClose} sx={{ ...styles.closeButton }}>
          <Close />
        </IconButton>
      </DialogTitle>

      <DialogContent dividers>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            mb: "32px",
          }}
        >
          <Typography sx={{ fontWeight: "bold" }}>コネクトタイプ</Typography>
          <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <Typography sx={{ fontWeight: "bold", fontSize: "14px" }}>自社が</Typography>
            <Select
              variant="standard"
              value={formState.connectType}
              onChange={handleChangeConnectType}
            >
              <MenuItem value="order">
                <ConnectTypeLabel name="発注" sx={{ height: "36px", width: "50px" }} />
              </MenuItem>
              <MenuItem value="receive">
                <ConnectTypeLabel name="受注" sx={{ height: "36px", width: "50px" }} />
              </MenuItem>
              <MenuItem value="order_and_receive">
                <ConnectTypeLabel name="受発注" sx={{ height: "36px", width: "50px" }} />
              </MenuItem>
            </Select>
          </Box>
        </Box>

        {formState.connectType === "order_and_receive" && (
          <Divider textAlign="left" sx={{ ...styles.orderAndReceiveMessage }}>
            <Typography color="secondary" fontWeight="bold" fontSize="12px">
              自社が「受注」するとき
            </Typography>
          </Divider>
        )}
        {formState.connectType !== "order" && (
          <Stack spacing={3}>
            <Box sx={{ display: "flex", alignItems: "start", gap: "16px" }}>
              <Person2OutlinedIcon sx={{ mt: "4px" }} />
              <Box sx={{ width: "100%" }}>
                <CustomFormLabel
                  labelName="相手の案件と予定に招待を許可するメンバー"
                  required
                  labelWeight="bold"
                  small
                  requiredSize="12px"
                />
                <Autocomplete
                  options={filteredInvitableUsers.unselectedHubUsers}
                  value={filteredInvitableUsers.selectedHubUsers}
                  onChange={(_, selectUsers) =>
                    handleSelectInvitableUser(selectUsers.map((selectUser) => selectUser.id))
                  }
                  renderInput={(params) => (
                    <TextField {...params} variant="standard" label="メンバーを選択" />
                  )}
                  getOptionLabel={(option) => option.name}
                  multiple
                  disableCloseOnSelect
                  disableClearable
                  renderTags={() => null}
                  slots={{
                    popper: (props) => (
                      <Popper {...props} placement="right-start" sx={{ maxWidth: "320px" }} />
                    ),
                  }}
                />
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: "8px", mt: "12px" }}>
                  {filteredInvitableUsers.selectedHubUsers.map((hubUser, index) => (
                    <SelectedMemberLabel
                      key={index}
                      userId={hubUser.id}
                      userName={hubUser.name}
                      onClear={handleClearInvitableUser}
                    />
                  ))}
                </Box>
              </Box>
            </Box>
            <Box sx={{ display: "flex", alignItems: "start", gap: "16px" }}>
              <EventOutlined sx={{ mt: "4px" }} />
              <Box sx={{ width: "100%" }}>
                <Typography sx={{ fontWeight: "bold", fontSize: "14px" }}>
                  相手のスケジュールに予定の時間帯を表示するメンバー
                </Typography>
                <Autocomplete
                  options={filteredScheduleViewableUsers.unselectedHubUsers}
                  value={filteredScheduleViewableUsers.selectedHubUsers}
                  onChange={(_, selectUsers) =>
                    handleSelectScheduleViewableUser(selectUsers.map((selectUser) => selectUser.id))
                  }
                  renderInput={(params) => (
                    <TextField {...params} variant="standard" label="メンバーを選択" />
                  )}
                  getOptionLabel={(option) => option.name}
                  multiple
                  disableCloseOnSelect
                  disableClearable
                  renderTags={() => null}
                  slots={{
                    popper: (props) => (
                      <Popper {...props} placement="right-start" sx={{ maxWidth: "320px" }} />
                    ),
                  }}
                />
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: "8px", mt: "12px" }}>
                  {filteredScheduleViewableUsers.selectedHubUsers.map((hubUser, index) => (
                    <SelectedMemberLabel
                      key={index}
                      userId={hubUser.id}
                      userName={hubUser.name}
                      onClear={handleClearScheduleViewableUser}
                    />
                  ))}
                </Box>
              </Box>
            </Box>
          </Stack>
        )}
        <Divider sx={{ my: "28px" }} />
        <Box>
          <FormControl sx={{ mb: "16px" }}>
            <RadioGroup
              name="collaborationType"
              value={collaborationType}
              onChange={handleChangeCollaborationType}
              row
            >
              <FormControlLabel value="email" control={<Radio />} label="メールアドレスで連携" />
              <FormControlLabel
                value="hubId"
                control={<Radio />}
                label="現場Hub IDで連携 (既存Hub専用)"
              />
            </RadioGroup>
          </FormControl>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
            {collaborationType === "email" ? (
              <>
                <CustomFormLabel
                  labelName="コネクト先のメールアドレス"
                  required
                  labelWeight="bold"
                  small
                  requiredSize="12px"
                  sx={{ mb: "0" }}
                />
                <TextField
                  name="destinationEmail"
                  value={formState.destinationEmail}
                  onChange={handleChange}
                  fullWidth
                  sx={{
                    "& .MuiInputBase-root": {
                      height: "48px",
                    },
                  }}
                />
                <Typography sx={{ fontSize: "11px", color: theme.palette.grayScale[700] }}>
                  ・送信先のメールアドレスが現場Hubに登録されている場合、所属するHubにコネクト申請が送信されます
                  <br />
                  ・送信先のメールアドレスが現場Hubに登録されていない場合、受信者は招待メールに記載されているリンクから現場Hubに登録・コネクトができます
                </Typography>
              </>
            ) : (
              <>
                <CustomFormLabel
                  labelName="コネクト先の現場Hub ID"
                  required
                  labelWeight="bold"
                  small
                  requiredSize="12px"
                  sx={{ mb: "0" }}
                />
                <Box sx={{ display: "flex", gap: "8px" }}>
                  <TextField
                    name="destinationHubUuid"
                    value={formState.destinationHubUuid}
                    onChange={handleChange}
                    fullWidth
                    placeholder="xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx"
                    sx={{
                      "& .MuiInputBase-root": {
                        height: "48px",
                      },
                    }}
                  />
                  <Button
                    onClick={handleSearch}
                    variant="contained"
                    sx={{ width: "100px", gap: "4px" }}
                    disabled={!formState.destinationHubUuid || isSearching}
                  >
                    <SearchIcon fontSize="small" />
                    検索
                  </Button>
                </Box>
                <Typography sx={{ fontSize: "11px", color: theme.palette.grayScale[700] }}>
                  自社の現場Hub ID: {mainState.hub.uuid}
                </Typography>
                <Box display="flex" alignItems="center" gap="4px" mt="16px">
                  {isSearching && <Typography>検索中...</Typography>}
                  {!isSearching && searchedHub && (
                    <>
                      <CheckCircleOutlineIcon fontSize="small" color="primary" />
                      <Typography color="primary" fontSize="14px">
                        以下のHubが見つかりました
                      </Typography>
                    </>
                  )}
                  {!isSearching && errorMessage && (
                    <>
                      <ErrorOutlineIcon fontSize="small" color="error" />
                      <Typography color="error">{errorMessage}</Typography>
                    </>
                  )}
                </Box>
                {!isSearching && searchedHub && (
                  <Box
                    sx={{
                      bgcolor: theme.palette.customPrimary[50],
                      borderRadius: "8px",
                      display: "flex",
                      p: "16px",
                    }}
                  >
                    <Avatar
                      url={searchedHub.logoUrl}
                      name={searchedHub.name}
                      bgColor={searchedHub.logoBackgroundColorNumber}
                      size="medium"
                    />
                    <Stack spacing="8px" sx={{ ml: "24px" }}>
                      <Typography fontWeight="bold">{searchedHub.name}</Typography>
                      <Typography fontSize="14px">{searchedHub.officeName}</Typography>
                      <Typography fontSize="14px" color="textDisabled">
                        {searchedHub.address + searchedHub.addressSecond}
                      </Typography>
                    </Stack>
                  </Box>
                )}
              </>
            )}
          </Box>
        </Box>
        <div ref={scrollBottomRef} />
      </DialogContent>

      <Box sx={{ display: "flex", justifyContent: "center", p: "16px", gap: "16px" }}>
        <Button variant="outlined" onClick={handleClose} sx={{ ...styles.button }}>
          キャンセル
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          sx={{ ...styles.button }}
          disabled={isSubmitDisabled}
        >
          コネクトを申請
        </Button>
      </Box>
    </Dialog>
  );
};

import {
  getAuth,
  getProjectTypes,
  getProjectStatusTypes,
  getProjectTodoTagTypes,
  getPersonalSetting,
  getScheduleTypes,
  getAccounts,
  getHub,
  getHubSetting,
  getMe,
} from "./actions";
import { mainSlice } from "./slice";
import { AppDispatch } from "../store";

export const mainOperations = {
  getAuth: () => (dispatch: AppDispatch) => {
    dispatch(getAuth());
  },
  getMe: () => (dispatch: AppDispatch) => {
    dispatch(getMe());
  },
  getHub: () => (dispatch: AppDispatch) => {
    dispatch(getHub());
  },
  getHubSetting: () => (dispatch: AppDispatch) => {
    dispatch(getHubSetting());
  },
  getProjectTypes: () => (dispatch: AppDispatch) => {
    dispatch(getProjectTypes());
  },
  getProjectStatusTypes: () => (dispatch: AppDispatch) => {
    dispatch(getProjectStatusTypes());
  },
  getAccounts: () => (dispatch: AppDispatch) => {
    dispatch(getAccounts());
  },
  getProjectTodoTagTypes: () => (dispatch: AppDispatch) => {
    dispatch(getProjectTodoTagTypes());
  },
  getScheduleTypes: () => (dispatch: AppDispatch) => {
    dispatch(getScheduleTypes());
  },
  getPersonalSetting: () => (dispatch: AppDispatch) => {
    dispatch(getPersonalSetting());
  },
  updateSuccessMessage: (value: string) => (dispatch: AppDispatch) => {
    dispatch(mainSlice.actions.updateSuccessMessage({ value }));
  },
  updateErrorMessage: (value: string) => (dispatch: AppDispatch) => {
    dispatch(mainSlice.actions.updateErrorMessage({ value }));
  },
  updateIsLoading: (value: boolean) => (dispatch: AppDispatch) => {
    dispatch(mainSlice.actions.updateIsLoading({ value }));
  },
  updateIsCompanySettingToNavBar: (value: boolean) => (dispatch: AppDispatch) => {
    dispatch(mainSlice.actions.updateIsCompanySettingToNavBar({ value }));
  },
};

import { Close } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { CsvImportProjectAnalyzeResponse } from "features/hub-settings/types/csv-import/csv_import_project.dto";
import { getTimeFromSeconds } from "utils/helpers";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  state: CsvImportProjectAnalyzeResponse;
  onSubmit: () => void;
}
export const AnalyzeModal = (props: Props) => {
  return (
    <Dialog
      open={props.isOpen}
      onClose={props.onClose}
      maxWidth="sm"
      sx={{
        "& .MuiDialogContent-root": {
          border: "none",
        },
      }}
    >
      <DialogTitle sx={{ textAlign: "right", p: "10px 0" }}>
        <IconButton onClick={props.onClose}>
          <Close />
        </IconButton>
      </DialogTitle>

      <DialogContent dividers sx={{ display: "flex", flexDirection: "column" }}>
        <Typography fontWeight="bold" align="center" mb="16px">
          {props.state.createCount + props.state.updateCount}件の案件をインポートしますか？
        </Typography>
        <Typography fontSize={14}>
          合計{props.state.createCount + props.state.updateCount}件の案件が見つかりました。
          <br />
          ・新規: {props.state.createCount}
          <br />
          ・更新: {props.state.updateCount}
          <br />
          インポートしますか？
        </Typography>
        <Typography color="error" fontSize={14} my={2}>
          インポート中にエラーが見つかった場合は、
          <br />
          全件インポート失敗になります。インポー
          <br />
          ト失敗後にエラー内容を含むCSVファイル
          <br />
          をダウンロードすることができます。
        </Typography>
        <Typography fontSize={14}>
          想定所要時間: {getTimeFromSeconds(props.state.estimatedTimeSeconds)}
        </Typography>
      </DialogContent>

      <DialogActions sx={{ justifyContent: "space-between", gap: "20px" }}>
        <Button variant="outlined" onClick={props.onClose} sx={{ width: "140px" }}>
          キャンセル
        </Button>
        <Button onClick={props.onSubmit} variant="contained" sx={{ width: "140px" }}>
          インポート開始
        </Button>
      </DialogActions>
    </Dialog>
  );
};

import { theme } from "extensions/theme";

export const styles = {
  menuItem: {
    width: "fit-content",
    borderBottom: `2px solid ${theme.palette.grayScale[0]}`,
    "&:hover": {
      cursor: "pointer",
      borderBottom: `2px solid ${theme.palette.primary.main}`,
    },
  },
  title: {
    fontWeight: "bold",
    marginBottom: "16px",
  },
  subTitle: {
    fontSize: "14px",
    fontWeight: "bold",
    marginBottom: "8px",
  },
};

import React from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import EmergencyIcon from "@mui/icons-material/Emergency";
import {
  Autocomplete,
  Box,
  FormControl,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { theme } from "extensions/theme";
import { CsvImportProjectRequest } from "features/hub-settings/types/csv-import/csv_import_project.dto";

interface Props {
  formState: CsvImportProjectRequest;
  csvHeaders: string[];
  onChange: (e: SelectChangeEvent<string>) => void;
}
export const MappingTable = (props: Props) => {
  return (
    <Box sx={{ my: "40px", maxWidth: "1200px" }}>
      <Grid container spacing={2} alignContent="center">
        <Grid size={5}>
          <Typography fontWeight="bold" fontSize={14}>
            CSVファイルの項目
          </Typography>
        </Grid>
        <Grid size={2} />
        <Grid size={5}>
          <Typography fontWeight="bold" fontSize={14}>
            現場Hubの項目
          </Typography>
        </Grid>

        {props.formState.importKey === "externalId" && (
          <>
            <Grid size={5}>
              <FormControl size="small" fullWidth>
                <Autocomplete
                  size="small"
                  value={props.formState.columnMap.externalId}
                  onChange={(_, newValue) => {
                    props.onChange({
                      target: { name: "externalId", value: newValue || "" },
                    } as SelectChangeEvent<string>);
                  }}
                  options={props.csvHeaders}
                  renderInput={(params) => <TextField {...params} label="項目を選択" />}
                  getOptionDisabled={(option) =>
                    Object.values(props.formState.columnMap).includes(option) &&
                    props.formState.columnMap.externalId !== option
                  }
                />
              </FormControl>
            </Grid>
            <Grid size={2} display="flex" alignItems="center" justifyContent="center">
              <ArrowForwardIcon />
            </Grid>
            <Grid size={5} display="flex" alignItems="center">
              <Typography fontWeight="bold">外部システムID</Typography>
              <EmergencyIcon color="error" sx={{ fontSize: "11px" }} />
            </Grid>
          </>
        )}
        <Grid size={5}>
          <FormControl size="small" fullWidth>
            <Autocomplete
              size="small"
              value={props.formState.columnMap.code}
              onChange={(_, newValue) => {
                props.onChange({
                  target: { name: "code", value: newValue || "" },
                } as SelectChangeEvent<string>);
              }}
              options={props.csvHeaders}
              renderInput={(params) => <TextField {...params} label="項目を選択" />}
              getOptionDisabled={(option) =>
                Object.values(props.formState.columnMap).includes(option) &&
                props.formState.columnMap.code !== option
              }
            />
          </FormControl>
        </Grid>
        <Grid size={2} display="flex" alignItems="center" justifyContent="center">
          <ArrowForwardIcon />
        </Grid>
        <Grid size={5} display="flex" alignItems="center">
          <Typography fontWeight="bold">案件番号</Typography>
        </Grid>

        {columnMapFields.map((field) => (
          <React.Fragment key={field.name}>
            <Grid size={5} sx={{ display: "flex", alignItems: "center" }}>
              <FormControl size="small" fullWidth>
                <Autocomplete
                  size="small"
                  value={props.formState.columnMap[field.name]}
                  onChange={(_, newValue) => {
                    props.onChange({
                      target: { name: field.name, value: newValue || "" },
                    } as SelectChangeEvent<string>);
                  }}
                  options={props.csvHeaders}
                  renderInput={(params) => <TextField {...params} label="項目を選択" />}
                  getOptionDisabled={(option) =>
                    Object.values(props.formState.columnMap).includes(option) &&
                    props.formState.columnMap[field.name] !== option
                  }
                />
              </FormControl>
            </Grid>
            <Grid size={2} display="flex" alignItems="center" justifyContent="center">
              <ArrowForwardIcon />
            </Grid>
            <Grid
              size={5}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                gap: "4px",
              }}
            >
              <Typography fontWeight="bold">{field.label}</Typography>
              <Typography fontSize={14} color={theme.palette.grayScale[700]} whiteSpace="pre-line">
                {field.explanation}
              </Typography>
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
    </Box>
  );
};

interface ColumnMapField {
  name: keyof CsvImportProjectRequest["columnMap"];
  label: string;
  explanation: string;
}
const columnMapFields: ColumnMapField[] = [
  { name: "name", label: "案件名", explanation: "" },
  {
    name: "projectStatusTypeName",
    label: "案件ステータス名",
    explanation: "現場Hubで設定している案件ステータス名と一致している必要があります",
  },
  {
    name: "projectTypeName",
    label: "案件タイプ名",
    explanation: "現場Hubで設定している案件タイプ名と一致している必要があります",
  },
  {
    name: "buildingName",
    label: "物件名",
    explanation: "現場Hubで設定している物件名と一致している必要があります",
  },
  { name: "inquiryNumber", label: "先方注文番号", explanation: "" },
  {
    name: "clientName",
    label: "顧客名",
    explanation: "現場Hubで設定している顧客名と一致している必要があります",
  },
  {
    name: "managerName",
    label: "現場責任者名",
    explanation: "現場Hubで設定しているメンバー名と一致している必要があります",
  },
  {
    name: "inquiredByName",
    label: "受付担当者名",
    explanation: "現場Hubで設定しているメンバー名と一致している必要があります",
  },
  {
    name: "inquiredDate",
    label: "受付日",
    explanation: "yyyy/MM/ddの形式である必要があります\n例: 2024/02/04",
  },
  {
    name: "orderedDate",
    label: "受注日",
    explanation: "yyyy/MM/ddの形式である必要があります\n例: 2024/02/04",
  },
  {
    name: "expectedCompleteDate",
    label: "完了予定日",
    explanation: "yyyy/MM/ddの形式である必要があります\n例: 2024/02/04",
  },
  {
    name: "completedDate",
    label: "完了日",
    explanation: "yyyy/MM/ddの形式である必要があります\n例: 2024/02/04",
  },
  { name: "salesAmount", label: "売上", explanation: "" },
  { name: "note", label: "概要・メモ", explanation: "" },
  { name: "billingPrecaution", label: "請求に関する連絡事項", explanation: "" },
  { name: "personName", label: "先方担当者名", explanation: "" },
  { name: "postalCode", label: "郵便番号", explanation: "" },
  { name: "address", label: "都道府県・市区町村・番地", explanation: "" },
  { name: "addressSecond", label: "建物名・階・号室", explanation: "" },
  { name: "phoneNumber", label: "電話番号1", explanation: "" },
  { name: "phoneNumberSecond", label: "電話番号2", explanation: "" },
  { name: "faxNumber", label: "FAX番号", explanation: "" },
  { name: "email", label: "メールアドレス", explanation: "" },
];

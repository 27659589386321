import { Close } from "@mui/icons-material";
import { Box, Dialog, DialogContent, DialogTitle, IconButton, Typography } from "@mui/material";
import SignIn1 from "images/sign_in_1.svg";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}
export const CampaignDialog = (props: Props) => {
  return (
    <Dialog open={props.isOpen}>
      <DialogTitle color="primary" sx={{ height: "48px" }}>
        <IconButton onClick={props.onClose} sx={{ position: "absolute", right: "8px" }}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ m: "15px", textAlign: "center" }}>
        <Box></Box>
        <Typography color="primary" sx={{ fontSize: "20px", fontWeight: "bold" }}>
          全ての機能が無料で使えて、利用人数・権限も無制限の
        </Typography>
        <Typography color="primary" sx={{ fontSize: "32px", fontWeight: "bold" }}>
          お試しキャンペーン中
        </Typography>
        <img src={SignIn1} alt="logo" style={{ width: "400px", margin: "16px 0" }} />
        <Typography fontSize="14px" mb="8px">
          キャンペーン終了時にプランのアップグレードしていただくか、
          <br />
          フリープランへの移行をお選びいただけます。
        </Typography>
        <Typography fontSize="12px" color="text.secondary">
          キャンペーンの期間は未定ですが、2025年5月頃の終了を予定しております。
          <br />
          期間が決まり次第、メールでご案内致します。
        </Typography>
      </DialogContent>
    </Dialog>
  );
};

import React, { ElementRef, ReactNode, useEffect, useRef, useState } from "react";
import { Range } from "react-date-range";
import { useNavigate } from "react-router-dom";
import { Box, SelectChangeEvent } from "@mui/material";
import { WorkReportTable } from "components/organisms/work-report/table";
import { AsyncConfirmDialog } from "components/templates/async-confirm-dialog";
import { getFilterItem, setFilterItem } from "data-access/local_storage/table_filter/local_storage";
import { DefaultWorkReport } from "data-access/local_storage/table_filter/local_storage.dto";
import { UserId } from "data-access/repositories/account/account.dto";
import { WorkReportId } from "data-access/repositories/work_report/work_report.dto";
import { workReportRepository } from "data-access/repositories/work_report/work_report.repository";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { mainOperations } from "store/main/operations";
import { selectMain } from "store/main/slice";
import useSWR from "swr";
import { ALL_USERS_VALUE, DEFAULT_ROWS_PER_PAGE } from "utils/constant";
import { formatDateUtil } from "utils/formatDateUtil";
import { WorkReportDetailModal } from "./detail-modal";

export const WorkReport = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const mainState = useAppSelector(selectMain);
  const filterItem = getFilterItem("workReport");

  const [show, setShow] = useState<{ isOpen: boolean; id: WorkReportId | undefined }>({
    isOpen: false,
    id: undefined,
  });

  const [dateRange, setDateRange] = useState<Range>(DefaultWorkReport.date.range);
  const [dateLabel, setDateLabel] = useState<string>(DefaultWorkReport.date.label);
  const [keyword, setKeyword] = useState(DefaultWorkReport.keyword);
  const [filterUserIds, setFilterUserIds] = useState<UserId[]>(DefaultWorkReport.userIds);
  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(DEFAULT_ROWS_PER_PAGE);

  const fetchIndexKey = `/api/v1/work_reports?page=${page}&items=${rowsPerPage}&startDate=${dateRange?.startDate}&endDate=${dateRange?.endDate}`;
  const { data, isValidating, mutate } = useSWR(fetchIndexKey, () => {
    return workReportRepository.index({
      page,
      rowsPerPage,
      keyword,
      startedDate: filterItem.date.range.startDate
        ? formatDateUtil(filterItem.date.range.startDate, "yyyy-MM-dd")
        : undefined,
      endedDate: filterItem.date.range.endDate
        ? formatDateUtil(filterItem.date.range.endDate, "yyyy-MM-dd")
        : undefined,
      userIds: filterItem.userIds || undefined,
    });
  });

  const filterStorageKey = "workReportTableFilter";
  const rowsPerPageLocalStorageKey = "workReportTableRowsPerPage";
  useEffect(() => {
    // 検索条件を常にLocalStorageに保存するため
    if (localStorage.getItem(filterStorageKey) === null) {
      localStorage.setItem(filterStorageKey, JSON.stringify(DefaultWorkReport));
    } else {
      setDateRange(filterItem.date.range);
      setDateLabel(filterItem.date.label);
      setFilterUserIds(filterItem.userIds);
      setKeyword(filterItem.keyword);
    }

    if (localStorage.getItem(rowsPerPageLocalStorageKey) === null) {
      localStorage.setItem(rowsPerPageLocalStorageKey, DEFAULT_ROWS_PER_PAGE.toString());
    } else {
      setRowsPerPage(Number(localStorage.getItem(rowsPerPageLocalStorageKey)));
    }
  }, []);

  const simpleConfirmRef = useRef<ElementRef<typeof AsyncConfirmDialog>>(null);
  const handleDelete = async (id: WorkReportId) => {
    if (!simpleConfirmRef.current) return;
    const res = await simpleConfirmRef.current.confirm();

    if (res) {
      try {
        await workReportRepository.destroy(id as WorkReportId);
        mutate();
        dispatch(mainOperations.updateSuccessMessage("作業日報を削除しました"));
        navigate("/work-reports");
        setShow({ isOpen: false, id: undefined });
      } catch (error) {
        dispatch(mainOperations.updateErrorMessage(error.response.data.message));
      }
    }
  };

  const handleChangeDateRange = (range: Range) => {
    setDateRange(range);
    filterItem.date.range = range;
    if (range.startDate && range.endDate) {
      const label =
        "作業日時:" +
        formatDateUtil(range.startDate, "yyyy年MM月dd日") +
        "-" +
        formatDateUtil(range.endDate, "yyyy年MM月dd日");
      setDateLabel(label);
      filterItem.date.label = label;
    } else {
      setDateLabel("すべての作業日時");
      filterItem.date.label = "すべての作業日時";
    }
    setFilterItem("workReport", filterItem);
  };

  const handleChangeForUserIds = (e: SelectChangeEvent<UserId[]>, child: ReactNode) => {
    const selectedValues = e.target.value as UserId[];
    // 現在選択された要素の値を取得
    const currentValue =
      child && typeof child === "object" && React.isValidElement(child) ? child.props.value : null;

    if (!selectedValues.length) {
      return;
    }

    // すべてのユーザーが選択されたかどうかを判定
    const isAllSelected =
      selectedValues.includes(ALL_USERS_VALUE) ||
      mainState.accounts.length === selectedValues.length;
    // 以前にすべてのユーザーが選択されていたかどうかを判定
    const isAllPreviouslySelected = filterUserIds[0] === 0;

    if (isAllSelected) {
      // すべてのユーザーが選択された場合は、0を設定
      updateFilterUserIds([ALL_USERS_VALUE]);
    } else if (isAllPreviouslySelected && currentValue) {
      // 以前にすべてのユーザーが選択されていて、現在の値が存在する場合は、現在の値を設定
      updateFilterUserIds([currentValue]);
    } else {
      // それ以外の場合は、選択された値をそのまま設定
      updateFilterUserIds(selectedValues);
    }
  };

  const updateFilterUserIds = (userIds: UserId[]) => {
    setFilterUserIds(userIds);
    filterItem.userIds = userIds;
    setFilterItem("workReport", filterItem);
  };

  const handleChangeKeyword = (keyword: string) => {
    const filterItem = getFilterItem("workReport");
    setKeyword(keyword);
    filterItem.keyword = keyword;
    setFilterItem("workReport", filterItem);
  };

  const handleSubmit = () => {
    mutate();
  };

  return (
    <Box>
      <AsyncConfirmDialog ref={simpleConfirmRef} />
      <WorkReportDetailModal
        show={show}
        onClose={() => setShow({ isOpen: false, id: undefined })}
        onDelete={handleDelete}
        fetchIndexKey={fetchIndexKey}
      />
      <WorkReportTable
        isLoading={isValidating}
        setShow={setShow}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        workReports={data}
        keyword={keyword}
        setKeyword={handleChangeKeyword}
        dateRange={dateRange}
        dateLabel={dateLabel}
        setDateRange={handleChangeDateRange}
        userIds={filterUserIds}
        onChangeForUserIds={handleChangeForUserIds}
        onSubmit={handleSubmit}
        fetchIndexKey={fetchIndexKey}
        rowsPerPageLocalStorageKey={rowsPerPageLocalStorageKey}
      />
    </Box>
  );
};

import { useCallback } from "react";
import { Box, MenuItem, Select, SelectChangeEvent, Tooltip, Typography } from "@mui/material";
import { GridColDef, GridRenderCellParams, GridRowModel } from "@mui/x-data-grid-pro";
import { CustomizedDataGridPro } from "components/templates/customized-data-grid-pro";
import {
  Account,
  ScheduleOverlappingType,
  scheduleOverlappingTypes,
  ScheduleOverlappingTypeValue,
  UserId,
} from "data-access/repositories/account/account.dto";
import { accountRepository } from "data-access/repositories/account/account.repository";
import { Group, GroupId } from "data-access/repositories/group/group.dto";
import { groupRepository } from "data-access/repositories/group/group.repository";
import { theme } from "extensions/theme";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { mainOperations } from "store/main/operations";
import { selectMain } from "store/main/slice";
import useSWR from "swr";
import { handleReduxError } from "utils/errorHandler";
import { styles } from "./styles";

const IsDeactivateValue = {
  Activate: 0,
  Deactivate: 1,
};

export const LoginDisabledMemberTable = () => {
  const mainState = useAppSelector(selectMain);
  const dispatch = useAppDispatch();

  const { mutate: groupsMutate, data: groups } = useSWR("/api/v1/groups", groupRepository.index);

  const loginDisabledMembers: Account[] = mainState.accounts
    ? mainState.accounts
        .filter((account) => !account.hasEmail)
        .sort((a, b) => (a.isDeactivate === b.isDeactivate ? 0 : a.isDeactivate ? 1 : -1))
    : [];

  const handleChangeGroup = async (e: SelectChangeEvent<number | GroupId>, userId: UserId) => {
    const { value: group_id } = e.target;

    try {
      await accountRepository.update(userId, {
        groupId: Number(group_id) as GroupId,
      });
      groups && groupsMutate();
      dispatch(mainOperations.getAccounts());
      dispatch(mainOperations.updateSuccessMessage("グループを変更しました"));
    } catch (error) {
      dispatch(mainOperations.updateErrorMessage(error.response.data.message));
    }
  };

  const handleChangeIsDeactivate = async (e: SelectChangeEvent<number>, userId: UserId) => {
    try {
      const isDeactivate = e.target.value === IsDeactivateValue.Deactivate;
      await accountRepository.update(userId, {
        isDeactivate: isDeactivate,
      });
      dispatch(mainOperations.getAccounts());
      groups && groupsMutate();
      dispatch(mainOperations.updateSuccessMessage("利用状況を変更しました"));
    } catch (error) {
      dispatch(mainOperations.updateErrorMessage(error.response.data.message));
    }
  };

  const handleEdit = useCallback(async (params: GridRowModel) => {
    try {
      await accountRepository.update(params.userId, {
        name: params.name,
        unitPricePerDay: params.unitPricePerDay,
      });
      dispatch(mainOperations.updateSuccessMessage("協力会社を更新しました"));
      dispatch(mainOperations.getAccounts());
    } catch (error) {
      dispatch(mainOperations.updateErrorMessage(error.response.data.message));
    }
    return params;
  }, []);

  const handleChangeScheduleOverlappingType = async (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>,
    userId: UserId,
  ) => {
    const value = e.target.value;
    try {
      await accountRepository.update(userId, {
        scheduleOverlappingType: value as ScheduleOverlappingTypeValue,
      });
      dispatch(mainOperations.getAccounts());
      dispatch(mainOperations.updateSuccessMessage("予定重複時の設定を変更しました"));
    } catch (error) {
      handleReduxError(error, dispatch, "予定重複時の設定の変更に失敗しました");
    }
  };

  const columns = () => {
    const headers: GridColDef[] = [];
    headers.push({
      field: "name",
      headerName: "名前",
      minWidth: 240,
      sortable: false,
      disableColumnMenu: true,
      editable: true,
      renderCell: (params: GridRenderCellParams<Account>) => {
        return (
          <Tooltip title="クリックで名前を編集できます" placement="top-start">
            <span>{params.value}</span>
          </Tooltip>
        );
      },
    });
    headers.push({
      field: "group_id",
      headerName: "グループ",
      minWidth: 160,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<Account>) => {
        return (
          <>
            <Select
              id="group"
              name="group"
              value={params.row.groupId || 0}
              variant="standard"
              onChange={(e) => handleChangeGroup(e, params.row.userId)}
              displayEmpty
              sx={styles.tableSelectBox}
            >
              <MenuItem value={0}>
                <Box
                  sx={{
                    ...styles.menuItemStyles,
                    border: `1px solid ${theme.palette.grayScale[900]} `,
                  }}
                >
                  未設定
                </Box>
              </MenuItem>
              {groups &&
                groups.map((group: Group, index: number) => (
                  <MenuItem value={group.id} key={index}>
                    <Box
                      sx={{
                        ...styles.menuItemStyles,
                        backgroundColor: `#${group.colorNumber}`,
                      }}
                    >
                      {group.name}
                    </Box>
                  </MenuItem>
                ))}
            </Select>
          </>
        );
      },
    });
    headers.push({
      field: "unit_price_per_day",
      headerName: "人工単価",
      minWidth: 100,
      editable: true,
      sortable: false,
      align: "right",
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<Account>) => (
        <>
          <Typography sx={{ fontSize: "14px", mr: "2px" }}>¥</Typography>
          <Typography fontSize="14px">{params.row.unitPricePerDay.toLocaleString()}</Typography>
        </>
      ),
    });
    headers.push({
      field: "is_deactivate",
      headerName: "利用状況",
      minWidth: 100,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<Account>) => {
        return (
          <>
            {params.row.isOutsourcedMember ? (
              <Select
                value={
                  params.row.isDeactivate
                    ? IsDeactivateValue.Deactivate
                    : IsDeactivateValue.Activate
                }
                variant="standard"
                onChange={(e) => handleChangeIsDeactivate(e, params.row.userId)}
                displayEmpty
                sx={styles.tableSelectBox}
              >
                <MenuItem value={IsDeactivateValue.Activate}>
                  <Box
                    sx={{
                      ...styles.menuItemStyles,
                      backgroundColor: `${theme.palette.secondary.light}`,
                      border: `1px solid ${theme.palette.secondary.main} `,
                    }}
                  >
                    利用中
                  </Box>
                </MenuItem>
                <MenuItem value={IsDeactivateValue.Deactivate}>
                  <Box
                    sx={{
                      ...styles.menuItemStyles,
                      backgroundColor: `${theme.palette.grayScale[200]}`,
                      border: `1px solid ${theme.palette.grayScale[700]} `,
                    }}
                  >
                    停止中
                  </Box>
                </MenuItem>
              </Select>
            ) : (
              <Box
                sx={{
                  ...styles.menuItemStyles,
                  backgroundColor: `${theme.palette.secondary.light}`,
                  border: `1px solid ${theme.palette.secondary.main} `,
                }}
              >
                利用中
              </Box>
            )}
          </>
        );
      },
    });
    headers.push({
      field: "schedule_overlapping_type",
      headerName: "予定重複時",
      minWidth: 200,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<Account>) => {
        return (
          <Select
            id="schedule_overlapping_type"
            name="schedule_overlapping_type"
            value={params.row.scheduleOverlappingType.value || ""}
            variant="standard"
            onChange={(e) => handleChangeScheduleOverlappingType(e, params.row.userId)}
            displayEmpty
            sx={styles.tableSelectBox}
          >
            {scheduleOverlappingTypes.map(
              (scheduleOverlappingType: ScheduleOverlappingType, index: number) => (
                <MenuItem value={scheduleOverlappingType.value} key={index}>
                  <Box sx={{ ...styles.menuItemStyles }}>{scheduleOverlappingType.valueI18n}</Box>
                </MenuItem>
              ),
            )}
          </Select>
        );
      },
    });
    return headers;
  };

  return (
    <>
      <CustomizedDataGridPro
        columnHeaderHeight={40}
        columns={columns()}
        rows={loginDisabledMembers || []}
        hideFooter
        disableRowSelectionOnClick
        processRowUpdate={handleEdit}
      />
    </>
  );
};

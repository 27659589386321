import React, {
  ChangeEvent,
  ElementRef,
  ReactElement,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
  useMemo,
} from "react";
import { useLocation } from "react-router-dom";
import { autoPlacement, offset } from "@floating-ui/dom";
import { HandymanOutlined } from "@mui/icons-material";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import LockIcon from "@mui/icons-material/LockOutlined";
import Person2OutlinedIcon from "@mui/icons-material/Person2Outlined";
import ScheduleIcon from "@mui/icons-material/Schedule";
import {
  Button,
  TextField,
  Select,
  MenuItem,
  Checkbox,
  SelectChangeEvent,
  Divider,
  FormControlLabel,
  Autocomplete,
  CircularProgress,
  AutocompleteInputChangeReason,
  FormControl,
  InputLabel,
  Typography,
  Tooltip,
  Box,
} from "@mui/material";
import { CustomList } from "components/atoms/custom-autocomplete";
import { CustomDatePicker } from "components/atoms/custom-date-picker";
import { Flash } from "components/atoms/flash";
import { Avatar } from "components/avatar";
import { CustomDivider } from "components/divider";
import { CloseIcon } from "components/icon/close-icon";
import { SiteManagerIcon } from "components/icon/site-manager-icon";
import { SelectedMemberLabel } from "components/label/selected-member-label";
import { TagLabel } from "components/label/tag-label";
import { LoadingOverlay } from "components/loadingOverlay";
import { ConfirmDialog } from "components/molecules/confirm-dialog";
import { CustomFormLabel } from "components/molecules/custom-form-label";
import { CustomModal } from "components/molecules/custom-modal";
import { ProjectBlock } from "components/molecules/project-block";
import { RecurrenceRuleSettingModal } from "components/molecules/recurrence-rule-setting-modal";
import { ScheduleParticipantHubsBlock } from "components/molecules/schedule-participant-hubs-block";
import { SelectedMembers } from "components/molecules/selected-members";
import { AsyncConfirmDialog } from "components/templates/async-confirm-dialog";
import { UserId } from "data-access/repositories/account/account.dto";
import { confirmedScheduleChangeRepository } from "data-access/repositories/notice/schedule/confirmed-schedule-change.repository";
import {
  Project,
  ProjectCollection,
  ProjectId,
} from "data-access/repositories/project/project.dto";
import { projectRepository } from "data-access/repositories/project/project.repository";
import {
  KeyName,
  RecurrenceRule,
  RecurrenceRuleParams,
  RequestRecurrenceRule,
} from "data-access/repositories/recurrence_rule/recurrence_rule.dto";
import { RecurrenceRuleOptionsRepository } from "data-access/repositories/recurrence_rule_options/recurrence_rule_options.repository";
import {
  ScheduleId,
  ScheduleUpdateRequest,
  initialRecurrenceRule,
  initialScheduleUpdateRequest,
} from "data-access/repositories/schedule/schedule.dto";
import { scheduleRepository } from "data-access/repositories/schedule/schedule.repository";
import { theme } from "extensions/theme";
import { useScheduleMembers } from "hooks/schedule/useScheduleMembers";
import { useScrollToPosition } from "hooks/useScrollToPosition";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { mainOperations } from "store/main/operations";
import { selectMain } from "store/main/slice";
import useSWR, { mutate } from "swr";
import { calculatePeriodFromDate, calculatePeriodFromTime } from "utils/calculatePeriod";
import { convertKeysToCamelCase } from "utils/convertObjectKeyCase";
import { handleStateError } from "utils/errorHandler";
import { DATE_TIME_SLASH_FORMAT } from "utils/formatDateUtil";
import { getHubGroups } from "utils/hubGrouping";
import { isRecurrenceRuleChanged } from "utils/isRecurrenceRuleChanged";
import { openURLInNewTab } from "utils/openURLInNewTab";

const startDatePopperPosition = [
  autoPlacement({
    alignment: "start",
    autoAlignment: false,
  }),
  offset({ mainAxis: -200, crossAxis: 250 }),
];
const endDatePopperPosition = [
  autoPlacement({
    alignment: "start",
    autoAlignment: false,
  }),
  offset({ mainAxis: -310, crossAxis: 0 }),
];

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  setIsDetail: (isOpen: boolean) => void;
  fetchIndexKey: string;
  fetchNoDatedEventKey: string;
  setIsLoading: (isLoading: boolean) => void;
  setRecurrenceDialog: (
    isOpen: boolean,
    id: ScheduleId,
    type: "delete" | "update",
    onUpdateFunc?: (withFutures: boolean) => Promise<void>,
    onCancelFunc?: () => void,
  ) => void;
}
export const ScheduleEditModal = (props: Props) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { scrollToSavedPosition } = useScrollToPosition("calendar");
  const scheduleId: ScheduleId = Number(location.pathname.split("/")[2]) as ScheduleId;
  const mainState = useAppSelector(selectMain);
  const myselfId: UserId = mainState.me.userId;
  // 案件のcomboBoxで検索時に入力された値
  const [inputProjectValue, setInputProjectValue] = useState<string>("");
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);

  const [formState, setFormState] = useState<ScheduleUpdateRequest>(initialScheduleUpdateRequest);
  const [selectedRecurrenceRule, setSelectedRecurrenceRule] = useState<{
    keyName: KeyName;
    label: string;
  }>({ keyName: "none", label: "" });
  const [recurrenceSettingModalState, setRecurrenceSettingModalState] = useState<{
    isOpen: boolean;
    recurrenceRule?: RecurrenceRule;
  }>({
    isOpen: false,
  });
  const noticeScheduleDialogRef = useRef<ElementRef<typeof AsyncConfirmDialog>>(null);

  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isChange, setIsChange] = useState<boolean>(false);

  const [selectedProject, setSelectedProject] = useState<ProjectCollection | Project | undefined>(
    undefined,
  );

  const { data: recurrenceRuleOptions, isValidating: isLoadingRecurrenceRule } = useSWR(
    props.isOpen && formState.startTime
      ? `/api/v1/recurrence_rule_options?schedule_id=${scheduleId}`
      : null,
    () =>
      RecurrenceRuleOptionsRepository.index({
        scheduleStartTime: formState.startTime ? formState.startTime : new Date().toString(),
        scheduleId: scheduleId,
      }),
    { revalidateOnFocus: false },
  );

  const {
    data: targetSchedule,
    isLoading: isLoadingSchedule,
    mutate: showMutate,
  } = useSWR(props.isOpen && scheduleId ? `/api/v1/schedules/${scheduleId}` : null, () =>
    scheduleRepository.show(scheduleId),
  );

  const { data: rawProjectsData, isValidating } = useSWR(
    props.isOpen ? `/api/v1/projects?query=${inputProjectValue}` : null,
    () => projectRepository.index({ keyword: inputProjectValue }),
  );
  const projects = rawProjectsData
    ? rawProjectsData.data.map((project) => {
        return {
          id: project.id,
          name: project.name,
          code: project.code,
          createOption: false,
        };
      })
    : [];

  const projectFilterOptions = (
    options: {
      id: ProjectId;
      name: string;
      code: string;
      createOption: boolean;
    }[],
  ) => {
    return options;
  };

  // 編集中の予定が繰り返し設定を持っているかどうかを判定
  const hasRecurrenceRule = useMemo(() => {
    return !!formState.recurrenceRule;
  }, [formState.recurrenceRule]);

  useEffect(() => {
    if (!props.isOpen || !targetSchedule) return;
    setFormState({
      name: targetSchedule.name,
      scheduleTypeId: targetSchedule.scheduleType.id,
      startTime: targetSchedule.startTime,
      endTime: targetSchedule.endTime,
      note: targetSchedule.note,
      isConfirmed: targetSchedule.isConfirmed,
      isPrivate: targetSchedule.isPrivate,
      siteManagerId: targetSchedule.siteManager?.userId,
      projectId: targetSchedule.project?.id,
      userIds: targetSchedule.participantHubs
        .filter(
          (participant) => participant.isOwnHubInvitation || participant.id === mainState.hub.id,
        )
        .flatMap((participant) =>
          participant.participants.map((participant) => participant.userId),
        ),
      recurrenceRuleId: targetSchedule.recurrenceRule?.id,
      recurrenceRule: targetSchedule.recurrenceRule
        ? (convertKeysToCamelCase(targetSchedule.recurrenceRule) as RequestRecurrenceRule)
        : undefined,
    });
    setSelectedProject(targetSchedule.project ?? undefined);

    if (!targetSchedule.recurrenceRule)
      return setSelectedRecurrenceRule({ keyName: "none", label: "" });

    setSelectedRecurrenceRule({
      keyName: "customized",
      label: targetSchedule.recurrenceRule.label,
    });
  }, [targetSchedule]);

  // 繰り返し設定モーダルで設定した値を反映
  useEffect(() => {
    if (!recurrenceSettingModalState.recurrenceRule) return;
    setFormState({
      ...formState,
      recurrenceRule: {
        ...recurrenceSettingModalState.recurrenceRule.params,
        endDate: recurrenceSettingModalState.recurrenceRule.params.endDate || "",
      },
    });
    setSelectedRecurrenceRule({
      keyName: recurrenceSettingModalState.recurrenceRule.keyName,
      label: recurrenceSettingModalState.recurrenceRule.label,
    });
  }, [recurrenceSettingModalState.recurrenceRule]);

  const updateSchedule = async () => {
    let newFormState = { ...formState };
    if (
      !isRecurrenceRuleChanged(
        convertKeysToCamelCase(targetSchedule?.recurrenceRule),
        formState.recurrenceRule,
      )
    ) {
      newFormState = { ...formState, recurrenceRule: undefined };
    }
    // 予定を通知するかどうか
    const setIsNotifyToFormState = async (): Promise<void> => {
      let isNotify: boolean = false;
      // 通知確認ダイアログの表示
      if (!noticeScheduleDialogRef.current) return;
      isNotify = await noticeScheduleDialogRef.current?.confirm();
      newFormState = { ...formState, isNotify };
    };
    const updateSchedule = async (withFutures: boolean) => {
      try {
        props.setIsLoading(true);
        await scheduleRepository.update(scheduleId, withFutures, newFormState);
        handleClose();
        mutate(props.fetchIndexKey);
        showMutate();
        dispatch(mainOperations.updateSuccessMessage("予定を更新しました"));
      } catch (error) {
        handleStateError(error, setErrorMessage, "予定の保存に失敗しました");
      } finally {
        props.setIsLoading(false);
        mutate(props.fetchNoDatedEventKey);
        scrollToSavedPosition();
      }
    };

    if (targetSchedule?.recurrenceRule) {
      props.setRecurrenceDialog(true, scheduleId, "update", updateSchedule);
    } else {
      // 通知のダイアログ表示
      const isRequiredToDisplayDialog = await isRequiredToDisplayScheduleNoticeDialog();
      if (isRequiredToDisplayDialog) await setIsNotifyToFormState();
      await updateSchedule(false);
    }
  };

  const {
    selectedAccounts,
    inputValue,
    allOptions,
    selectableSiteManagers,
    isLoading: isLoadingMembers,
    resetMembers,
    handleChangeUsers,
    handleClear,
    setInputValue,
    filterOptions,
    groupByFunction,
    isOverlapConfirmModalOpen,
    handleCreateWithOverlapCheck,
    handleCancelOverlapConfirm,
  } = useScheduleMembers({
    isModalOpen: props.isOpen,
    startTime: formState.startTime || "",
    endTime: formState.endTime || "",
    initialUserIds: formState.userIds || [],
    excludeScheduleId: scheduleId,
    onMembersChange: (newUserIds) => {
      setFormState({ ...formState, userIds: newUserIds });
      setIsChange(true);
    },
    onCreateSchedule: updateSchedule,
    // 繰り返し予定の場合は他Hubのメンバー選択を制限
    disableOtherHubSelection: hasRecurrenceRule,
  });

  // Hubグループを取得
  const hubGroups = useMemo(() => {
    return getHubGroups(selectedAccounts);
  }, [selectedAccounts]);

  // 他のHubユーザーが含まれているかをチェック
  const hasOtherHubUsers = useMemo((): boolean => {
    return hubGroups.some((hubGroup) => hubGroup.hub.uuid !== mainState.hub.uuid);
  }, [hubGroups]);

  // 他Hub選択時に繰り返し設定をクリア（新規に繰り返し設定する場合）
  useEffect(() => {
    if (hasOtherHubUsers && formState.recurrenceRule && !hasRecurrenceRule) {
      setFormState({
        ...formState,
        recurrenceRule: undefined,
      });
      setSelectedRecurrenceRule({ keyName: "none", label: "" });
    }
  }, [hasOtherHubUsers, hasRecurrenceRule]);

  const handleChangeProjectSearchInputValue = (
    _: React.SyntheticEvent,
    value: string,
    reason: AutocompleteInputChangeReason,
  ) => {
    if (reason !== "input") return;
    setInputProjectValue(value);
  };

  const handleSelectProject = (
    _: React.SyntheticEvent,
    value: {
      id: ProjectId;
      name: string;
      createOption: boolean;
    } | null,
  ) => {
    if (!rawProjectsData) return;
    setIsChange(true);
    const projectId = value ? value.id : 0;
    const project = rawProjectsData.data.filter((project) => project.id === projectId)[0];
    setInputProjectValue("");
    setFormState({ ...formState, projectId });
    setSelectedProject(project);
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<number | null>,
  ) => {
    setIsChange(true);
    setFormState({ ...formState, [e.target.name]: e.target.value });
  };

  const handleSelectChange = (e: SelectChangeEvent<string>) => {
    setIsChange(true);
    setFormState({ ...formState, [e.target.name]: e.target.value });
  };

  const handleChangeDateTime = (
    date: Date,
    e: React.SyntheticEvent<any> | undefined,
    name: string,
  ) => {
    if (date === null) return;
    setIsChange(true);
    switch (name) {
      case "startTime": {
        // 日付を変更したときはeが存在する
        const { startDateTime, endDateTime } = (
          e ? calculatePeriodFromDate : calculatePeriodFromTime
        )(
          date,
          formState.startTime ? new Date(formState.startTime) : null,
          formState.endTime ? new Date(formState.endTime) : null,
        );

        setFormState({
          ...formState,
          startTime: startDateTime?.toString() || "",
          endTime: endDateTime.toString(),
        });
        break;
      }
      case "endTime":
        return setFormState({ ...formState, endTime: date.toString() });
    }
  };

  const handleChangeRadioButton = (e: ChangeEvent<HTMLInputElement>) => {
    setIsChange(true);
    setFormState({ ...formState, [e.target.name]: e.target.checked });
  };

  const handleClickProject = () => {
    if (!formState.projectId) return;
    openURLInNewTab(`projects/${formState.projectId}`);
  };

  const handleClickUnselect = () => {
    setSelectedProject(undefined);
    setFormState({ ...formState, projectId: null });
  };

  // 通知確認ダイアログの表示条件
  const isRequiredToDisplayScheduleNoticeDialog = async (): Promise<boolean> => {
    if (!targetSchedule) return false;
    if (!formState.isConfirmed) return false;
    if (!formState.userIds) return false;

    // 自分ではないユーザーが参加、予定名、日付（開始、終了）、現場責任者、メモのいずれかが変更されているか（現時点でのトリガー項目）
    const isEitherOfTargetParametersChanged =
      formState.userIds.length > 0 &&
      !(formState.userIds.length === 1 && formState.userIds[0] === myselfId) &&
      (formState.name !== targetSchedule.name ||
        formState.note !== targetSchedule.note ||
        formState.startTime !== targetSchedule.startTime ||
        formState.endTime !== targetSchedule.endTime ||
        formState.siteManagerId !== targetSchedule.siteManager?.userId);
    if (!isEitherOfTargetParametersChanged) return false;

    const response = await confirmedScheduleChangeRepository.show();
    return response.isEnable;
  };

  const handleSubmit = async () => {
    handleCreateWithOverlapCheck();
  };

  const handleClose = () => {
    setIsChange(false);
    resetMembers();
    setIsDeleteModalOpen(false);
    props.setIsOpen(false);
    setErrorMessage("");
    setFormState(initialScheduleUpdateRequest);
    setSelectedRecurrenceRule({ keyName: "none", label: "" });
  };

  const handleCreateProject = () => {
    openURLInNewTab("projects/new");
  };

  const scrollBottomRef = useRef<HTMLDivElement>(null);
  useLayoutEffect(() => {
    if (errorMessage !== "") {
      scrollBottomRef?.current?.scrollIntoView();
    }
  }, [errorMessage]);

  const handleChangeRecurrence = (e: SelectChangeEvent<string>, child: React.ReactNode) => {
    setIsChange(true);
    const { value } = e.target;
    if (value === "custom") {
      setRecurrenceSettingModalState({
        isOpen: true,
        recurrenceRule: {
          keyName: selectedRecurrenceRule.keyName,
          label: selectedRecurrenceRule.label,
          params: formState.recurrenceRule || initialRecurrenceRule,
        },
      });
      return;
    }

    switch (value) {
      case "none":
        setFormState({ ...formState, recurrenceRuleId: null, recurrenceRule: undefined });
        break;
      default: {
        const dataParams: RecurrenceRuleParams = (child as ReactElement).props["data-params"];
        setFormState({
          ...formState,
          recurrenceRule: { ...dataParams, endDate: dataParams.endDate || null },
        });
        break;
      }
    }
    setSelectedRecurrenceRule({
      keyName: value as KeyName,
      label: (child as ReactElement).props.children,
    });
  };

  return (
    <>
      <AsyncConfirmDialog
        ref={noticeScheduleDialogRef}
        title="予定の変更を通知しますか？"
        yesButtonColor="primary"
        yesButtonText="はい"
      />

      <ConfirmDialog
        isOpen={isDeleteModalOpen}
        handleYes={handleClose}
        handleNo={() => setIsDeleteModalOpen(false)}
        content="保存せず破棄しますか？"
        yesButtonText="破棄する"
        noButtonText="キャンセル"
        yesButtonColor="error"
      />

      <ConfirmDialog
        isOpen={isOverlapConfirmModalOpen}
        handleYes={async () => {
          handleCancelOverlapConfirm();
          await updateSchedule();
        }}
        handleNo={handleCancelOverlapConfirm}
        content={`他の予定と時間が重複しているメンバーがいますが、予定を保存しますか？`}
        yesButtonText="保存"
      />

      <RecurrenceRuleSettingModal
        isOpen={recurrenceSettingModalState.isOpen}
        recurrenceRule={formState.recurrenceRule || undefined}
        setRecurrenceSettingModalState={(isOpen, recurrenceRule) =>
          setRecurrenceSettingModalState({ isOpen, recurrenceRule })
        }
        onClose={() =>
          setRecurrenceSettingModalState({
            isOpen: false,
            recurrenceRule: recurrenceSettingModalState.recurrenceRule,
          })
        }
        startDate={formState.startTime || ""}
      />

      <CustomModal
        title="予定の編集"
        open={props.isOpen}
        onClose={() => (isChange ? setIsDeleteModalOpen(true) : handleClose())}
        maxWidth="sm"
        footer={
          <>
            <Button
              variant="outlined"
              onClick={() => (isChange ? setIsDeleteModalOpen(true) : handleClose())}
              sx={{ width: "10rem" }}
            >
              キャンセル
            </Button>
            <Button onClick={handleSubmit} variant="contained" sx={{ width: "10rem", ml: "1rem" }}>
              保存
            </Button>
          </>
        }
      >
        <LoadingOverlay
          isLoading={
            isValidating || isLoadingSchedule || isLoadingRecurrenceRule || isLoadingMembers
          }
        />
        <div style={{ display: "flex", marginBottom: "16px", gap: "16px" }}>
          <TextField
            id="name"
            name="name"
            label="予定名"
            variant="standard"
            value={formState.name}
            onChange={handleChange}
            sx={{ width: "400px" }}
          />

          <Select
            id="scheduleTypeId"
            name="scheduleTypeId"
            variant="standard"
            value={formState.scheduleTypeId?.toString()}
            onChange={handleSelectChange}
            sx={{ minWidth: "100px" }}
          >
            {mainState.scheduleTypes.map((scheduleType) => (
              <MenuItem key={scheduleType.id} value={scheduleType.id}>
                <TagLabel tagName={scheduleType.name} colorNumber={scheduleType.colorNumber} />
              </MenuItem>
            ))}
          </Select>
        </div>

        <div>
          <div style={{ display: "flex", alignItems: "center", marginBottom: "20px", gap: "12px" }}>
            <ScheduleIcon />
            <div>
              <div style={{ display: "flex" }}>
                <CustomDatePicker
                  id="startTime"
                  name="startTime"
                  date={formState.startTime ? new Date(formState.startTime) : null}
                  onChange={handleChangeDateTime}
                  showTimeSelect
                  timeIntervals={30}
                  dateFormat={DATE_TIME_SLASH_FORMAT}
                  popperPlacement="right-start"
                  popperModifiers={startDatePopperPosition}
                  placeholder="開始日時"
                />
                <p>〜</p>
                <CustomDatePicker
                  id="endTime"
                  name="endTime"
                  date={formState.endTime ? new Date(formState.endTime) : null}
                  onChange={handleChangeDateTime}
                  showTimeSelect
                  timeIntervals={30}
                  dateFormat={DATE_TIME_SLASH_FORMAT}
                  popperPlacement="right-start"
                  popperModifiers={endDatePopperPosition}
                  placeholder="終了日時"
                />
              </div>
              <div style={{ display: "flex", alignItems: "center", gap: "16px" }}>
                <Typography sx={{ fontSize: "12px", fontWeight: "500" }}>
                  繰り返し・長期予定
                </Typography>
                <Select
                  value={selectedRecurrenceRule.keyName}
                  variant="standard"
                  onChange={handleChangeRecurrence}
                  disabled={
                    !formState.startTime ||
                    !formState.endTime ||
                    (hasOtherHubUsers && !hasRecurrenceRule)
                  }
                  sx={{ minWidth: "70px", maxWidth: "70%" }}
                >
                  {/* 他Hub選択時で繰り返し設定がない場合は選択肢を表示しない */}
                  {(!hasOtherHubUsers || hasRecurrenceRule) &&
                    recurrenceRuleOptions?.map((option: RecurrenceRule, index) => (
                      <MenuItem key={index} value={option.keyName} data-params={option.params}>
                        {option.label}
                      </MenuItem>
                    ))}
                  {/* 繰り返し設定済み予定更新時、カスタムから繰り返し設定を新たに作成したら元々のrecurrenceRuleOptionを非表示にする */}
                  {recurrenceSettingModalState.recurrenceRule &&
                    recurrenceSettingModalState.recurrenceRule.label !== "" && (
                      <MenuItem
                        value="customized"
                        data-params={recurrenceSettingModalState.recurrenceRule.params}
                      >
                        {recurrenceSettingModalState.recurrenceRule.label}
                      </MenuItem>
                    )}
                </Select>
                {hasOtherHubUsers && !hasRecurrenceRule && (
                  <Tooltip
                    title="他Hubのメンバーが含まれている場合、繰り返し設定はできません"
                    placement="top"
                  >
                    <HelpOutlineIcon
                      sx={{ fontSize: "17px", mt: "2px", color: theme.palette.primary.main }}
                    />
                  </Tooltip>
                )}
              </div>
            </div>
          </div>
        </div>

        <Divider sx={{ my: "24px" }} />

        <div style={{ display: "flex", gap: "12px" }}>
          <Person2OutlinedIcon sx={{ mt: "16px" }} />
          <div style={{ width: "100%", overflow: "hidden" }}>
            <div style={{ marginBottom: "12px" }}>
              <SelectedMembers
                allOptions={allOptions}
                selectedAccounts={selectedAccounts}
                selectedSiteManagerId={formState.siteManagerId || (0 as UserId)}
                inputValue={inputValue}
                setInputValue={setInputValue}
                onChangeUsers={handleChangeUsers}
                handleClear={handleClear}
                filterOptions={filterOptions}
                groupByFunction={groupByFunction}
                disableOtherHubSelection={hasRecurrenceRule}
                popperPlacement="right-start"
                scrollKey="schedule-edit-modal-members"
              />
            </div>
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: "8px", my: "12px" }}>
              {selectedAccounts.map(
                (selectedAccount, index) =>
                  selectedAccount.hub.uuid === mainState.hub.uuid && (
                    <SelectedMemberLabel
                      key={index}
                      userId={selectedAccount.userId}
                      userName={selectedAccount.name}
                      isSiteManager={selectedAccount.userId === formState.siteManagerId}
                      isOverlapped={selectedAccount.isScheduleOverlapped}
                      overlapType={selectedAccount.scheduleOverlappingType}
                      onClear={
                        selectedAccount.userId !== formState.siteManagerId
                          ? () => handleClear(selectedAccount.userId)
                          : undefined
                      }
                      icon={
                        selectedAccount.userId === formState.siteManagerId ? (
                          <SiteManagerIcon />
                        ) : undefined
                      }
                    />
                  ),
              )}
            </Box>

            <FormControl variant="standard" fullWidth>
              <InputLabel id="site-manager-select-label">現場責任者を選択</InputLabel>
              <Select
                labelId="site-manager-select-label"
                id="siteManagerId"
                name="siteManagerId"
                value={formState.siteManagerId || 0}
                onChange={(event) => handleChange(event)}
              >
                <MenuItem value={0}>未設定</MenuItem>
                {selectableSiteManagers.map((account) => (
                  <MenuItem key={account.userId} value={account.userId}>
                    {account.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControlLabel
              label="予定を確定"
              control={
                <Checkbox
                  name="isConfirmed"
                  onChange={handleChangeRadioButton}
                  sx={{
                    "& input": { zIndex: 0 },
                  }}
                />
              }
              checked={formState.isConfirmed}
              sx={{
                mb: "12px",
                "& .MuiFormControlLabel-label": { fontSize: "14px", fontWeight: "bold" },
              }}
            />

            <div>
              {hasOtherHubUsers && (
                <CustomDivider title="自社が招待したHub" color={theme.palette.grayScale[700]} />
              )}
              {hubGroups.map(
                (hubGroup) =>
                  hubGroup.hub.uuid !== mainState.hub.uuid && (
                    <Box key={hubGroup.hub.uuid} sx={{ mb: "16px" }}>
                      <Box display="flex" alignItems="center" gap="8px">
                        <Avatar
                          url={hubGroup.hub.logoUrl}
                          bgColor={hubGroup.hub.logoBackgroundColorNumber}
                          name={hubGroup.hub.name}
                          size="small"
                        />
                        <Typography sx={{ fontSize: "14px", mb: "4px" }}>
                          {hubGroup.hub.name}
                        </Typography>
                        <Button
                          startIcon={<CloseIcon size={18} />}
                          onClick={(e) => {
                            const updatedAccounts = selectedAccounts.filter(
                              (account) => account.hub?.uuid !== hubGroup.hub.uuid,
                            );
                            handleChangeUsers(e, updatedAccounts);
                          }}
                          color="primary"
                          sx={{ p: 0, minWidth: "auto", "& .MuiButton-icon": { m: 0 } }}
                        />
                      </Box>
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: "8px", mt: "12px" }}>
                        {hubGroup.users.map((user) => (
                          <SelectedMemberLabel
                            key={user.userId}
                            userId={user.userId}
                            userName={user.name}
                            onClear={() => handleClear(user.userId)}
                          />
                        ))}
                      </Box>
                    </Box>
                  ),
              )}
            </div>
            <ScheduleParticipantHubsBlock participantHubs={targetSchedule?.participantHubs || []} />
          </div>
        </div>

        <Divider sx={{ my: "24px" }} />

        <div style={{ display: "flex", gap: "16px" }}>
          <FormatAlignLeftIcon />
          <div style={{ width: "100%" }}>
            <CustomFormLabel
              labelName="作業内容・予定メモ"
              labelColor={theme.palette.grayScale[700]}
            />
            <TextField
              id="note"
              name="note"
              multiline
              rows={4}
              value={formState.note}
              onChange={handleChange}
              fullWidth
              sx={{ mb: "8px" }}
            />
          </div>
        </div>

        <div style={{ display: "flex", alignItems: "center" }}>
          <LockIcon fontSize="small" sx={{ mr: "16px" }} />
          <FormControlLabel
            label="予定を非公開にする"
            control={<Checkbox name="isPrivate" onChange={handleChangeRadioButton} />}
            checked={formState.isPrivate}
            sx={{
              "& .MuiFormControlLabel-label": { fontSize: "14px", fontWeight: "bold", mr: "0px" },
            }}
          />
          <Tooltip
            title="予定を非公開にすると、予定の作成者と参加者以外が予定の詳細を見ることができなくなります。"
            placement="top"
          >
            <HelpOutlineIcon
              sx={{ fontSize: "17px", mt: "2px", color: theme.palette.primary.main }}
            />
          </Tooltip>
        </div>

        <Divider sx={{ my: "24px" }} />

        <div style={{ display: "flex", gap: "12px", marginBottom: "24px" }}>
          <HandymanOutlined />
          <div style={{ width: "100%" }}>
            <CustomFormLabel
              labelName="案件"
              sx={{ mb: "12px" }}
              labelColor={theme.palette.grayScale[700]}
            />
            {selectedProject ? (
              <ProjectBlock
                project={selectedProject}
                onClick={handleClickProject}
                onClickUnlink={!targetSchedule?.isInvited ? handleClickUnselect : undefined}
              />
            ) : (
              <Autocomplete
                options={projects}
                onChange={handleSelectProject}
                onBlur={() => setInputProjectValue("")}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="案件名を入力して検索"
                    variant="standard"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {isValidating ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
                PopperComponent={(props: any) => (
                  <CustomList {...props} handleCreate={handleCreateProject} entityName="案件" />
                )}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.id}>
                      {option.name}
                    </li>
                  );
                }}
                filterOptions={projectFilterOptions}
                onInputChange={handleChangeProjectSearchInputValue}
                loading={isValidating}
                noOptionsText={
                  inputProjectValue ? "案件は見つかりませんでした" : "検索結果が表示されます"
                }
                sx={{
                  width: 330,
                  mb: "14px",
                  "& .MuiAutocomplete-popupIndicator": {
                    display: "none",
                  },
                }}
              />
            )}
          </div>
        </div>

        {errorMessage && (
          <Flash title="エラー" severity="error" message={errorMessage} sx={{ mt: "1rem" }} />
        )}
        <div ref={scrollBottomRef} />
      </CustomModal>
    </>
  );
};

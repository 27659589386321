import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { signUpRepository } from "data-access/repositories/sign_up/sign_up.repository";
import useSWR from "swr";
import { ExistingHub } from "./existing-hub";
import { NewHub } from "./new-hub";

export const MobileSignUp = () => {
  const [searchParams] = useSearchParams();
  const [token, setToken] = useState<string | null>(null);

  useEffect(() => {
    const tokenParam = searchParams.get("token");
    if (tokenParam) {
      setToken(tokenParam);
    }
  }, [searchParams]);

  const { data, error } = useSWR(
    token ? `/api/v1/signup/verify?signup_token=${token}` : null,
    () => signUpRepository.get(token as string),
    { revalidateOnFocus: false },
  );

  return (
    <Box sx={{ maxWidth: "363px" }}>
      {error && (
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <Typography fontSize="20px" fontWeight="bold" mb="16px">
            申し訳ありませんが、このリンクは使用できません
          </Typography>
          <Typography>{error.response.data?.message}</Typography>
        </Box>
      )}
      {data && token && (
        <>
          {data.tokenType === "account_invitation" && <ExistingHub data={data} token={token} />}
          {data.tokenType === "connect_request" && <NewHub data={data} token={token} />}
        </>
      )}
    </Box>
  );
};

import { AccountReference, initialAccountReference, UserId } from "../account/account.dto";
import { Brand } from "../brand";
import { BuildingId } from "../building/building.dto";
import { ClientId } from "../client/client.dto";
import { PageInfo } from "../page_info/page_info.dto";
import {
  Participant,
  ParticipationType,
  ScheduleParticipant,
} from "../project/participant/participant.dto";
import { initialProjectResponse, Project, ProjectId } from "../project/project.dto";
import { ProjectTypeId } from "../project_type/project_type.dto";
import {
  EndType,
  FrequencyType,
  RequestRecurrenceRule,
} from "../recurrence_rule/recurrence_rule.dto";
import {
  initialScheduleType,
  ScheduleType,
  ScheduleTypeId,
} from "../schedule_type/schedule_type.dto";

export type ScheduleId = Brand<number, "ScheduleId">;

export interface ScheduleList {
  id: ScheduleId;
  name: string;
  displayName: string;
  startTime: string;
  endTime: string;
  note: string;
  isConfirmed: boolean;
  isPrivate: boolean;
  isAccessible: boolean;
  project: Project | null;
  scheduleType: ScheduleType;
  siteManager: AccountReference | null;
  isInvited: boolean;
  isAllParticipantsAbsent: boolean;
  participants: Participant[];
  recurrenceRule: ResponseRecurrenceRule;
}

export interface Schedule {
  id: ScheduleId;
  name: string;
  startTime: string | null;
  endTime: string | null;
  note: string;
  isConfirmed: boolean;
  isPrivate: boolean;
  isAccessible: boolean;
  createdAt: string;
  updatedAt: string;
  createdBy: AccountReference;
  updatedBy: AccountReference;
  project: Project | null;
  siteManager: AccountReference | null;
  scheduleType: ScheduleType;
  recurrenceRule: ResponseRecurrenceRule | null;
  isInvited: boolean;
  participantHubs: ScheduleParticipant[];
  participationType: ParticipationType;
}

export interface ResponseRecurrenceRule {
  id: number;
  label: string;
  originalStartTime: string;
  originalEndTime: string;
  interval: number;
  frequencyType: FrequencyType;
  daysOfWeek: number[];
  endType: EndType;
  endDate?: string;
  count: number;
}

export interface ScheduleIndexRequest {
  scheduleRangeStart?: string;
  scheduleRangeEnd?: string;
  userIds?: number[];
  groupIds?: number[];
  isTimeEntered?: boolean;
  // 以下、CSVダウンロード用
  keyword?: string;
  sort?: string;
  direction?: string;
}

export interface ScheduleSearchRequest {
  scheduleRangeStart?: string;
  scheduleRangeEnd?: string;
  keyword?: string;
  page?: number;
  rowsPerPage?: number;
  sort?: string;
  direction?: string;
}

export interface ScheduleCreateRequest {
  name: string;
  scheduleTypeId: ScheduleTypeId;
  siteManagerId: UserId;
  startTime: string;
  endTime: string;
  note: string;
  isConfirmed: boolean;
  isPrivate: boolean;
  projectId: ProjectId;
  project?: ProjectInfo;
  userIds: UserId[];
  users?: AccountReference[];
  recurrenceRule?: RequestRecurrenceRule;
}

export interface ProjectInfo {
  projectTypeId?: ProjectTypeId;
  name?: string;
  salesAmount?: number;
  postalCode?: string;
  address?: string;
  addressSecond?: string;
  buildingId?: BuildingId;
  clientId?: ClientId;
  client?: {
    name?: string;
  };
}

export interface ScheduleUpdateRequest {
  name?: string;
  projectId?: number | null;
  scheduleTypeId?: number;
  siteManagerId?: UserId | null;
  startTime?: string | null;
  endTime?: string | null;
  note?: string;
  isConfirmed?: boolean;
  isPrivate?: boolean;
  userIds?: UserId[];
  isNotify?: boolean;
  recurrenceRuleId?: number | null;
  recurrenceRule?: RequestRecurrenceRule;
}

export interface ScheduleSearchResponse {
  data: ScheduleList[];
  pagination: PageInfo;
}

export interface ScheduleConfirmRequest {
  schedule_ids: number[];
}

export interface ScheduleRightClickObject {
  id: ScheduleId;
  userId: number;
}

export const initialScheduleCreateRequest: ScheduleCreateRequest = {
  name: "",
  scheduleTypeId: 0 as ScheduleTypeId,
  siteManagerId: 0 as UserId,
  startTime: "",
  endTime: "",
  note: "",
  isConfirmed: false,
  isPrivate: false,
  projectId: 0 as ProjectId,
  userIds: [],
};

export const initialScheduleUpdateRequest: ScheduleUpdateRequest = {
  scheduleTypeId: 0,
  name: "",
  isConfirmed: false,
  isPrivate: false,
  startTime: "",
  endTime: "",
  note: "",
  userIds: [],
};

export const initialScheduleResponse: Schedule = {
  id: 0 as ScheduleId,
  name: "",
  startTime: "",
  endTime: "",
  note: "",
  isConfirmed: false,
  isPrivate: false,
  isAccessible: false,
  createdAt: "",
  updatedAt: "",
  createdBy: initialAccountReference,
  updatedBy: initialAccountReference,
  project: initialProjectResponse,
  siteManager: null,
  scheduleType: initialScheduleType,
  recurrenceRule: null,
  isInvited: false,
  participantHubs: [],
  participationType: "no_response",
};

export const initialScheduleRightClickObject: ScheduleRightClickObject = {
  id: 0 as ScheduleId,
  userId: 0,
};

export const initialRecurrenceRule: RequestRecurrenceRule = {
  interval: 1,
  frequencyType: "day",
  daysOfWeek: [],
  endType: "none_specify",
  endDate: null,
  count: 0,
};

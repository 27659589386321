import { useEffect, useState } from "react";
import { ContentCopy } from "@mui/icons-material";
import { Box, Button, Grid2, InputAdornment, Stack, TextField, Typography } from "@mui/material";
import { Avatar } from "components/avatar";
import { CustomFormLabel } from "components/molecules/custom-form-label";
import { Layout } from "components/templates/layout";
import { hubRepository } from "features/hub-settings/api/hub-info/hub_info.repository";
import { HubInfoData, initialHubInfoData } from "features/hub-settings/types/hub-info/hub_info.dto";
import { useAppDispatch } from "store/hooks";
import { mainOperations } from "store/main/operations";
import useSWR from "swr";
import { handleReduxError } from "utils/errorHandler";
import { styles } from "./styles";

export const HubInfo = () => {
  const dispatch = useAppDispatch();
  const { data, mutate } = useSWR("/api/v1/hub", hubRepository.index, {
    revalidateOnFocus: false,
  });
  const [formState, setFormState] = useState<HubInfoData>(initialHubInfoData);

  useEffect(() => {
    if (data) {
      setFormState(data);
    }
  }, [data]);

  const copyShareUrlToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(data?.uuid || "");
      dispatch(mainOperations.updateSuccessMessage("コピーしました"));
    } catch (error) {
      alert((error && error.message) || "コピーに失敗しました");
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormState({
      ...formState,
      [e.target.name]: e.target.value,
    });
  };

  const handleBlur = async (e: React.FocusEvent<HTMLInputElement>) => {
    const fieldName = e.target.name as keyof HubInfoData;
    if (!data || data[fieldName] === e.target.value) {
      return;
    }

    dispatch(mainOperations.updateIsLoading(true));
    try {
      const res = await hubRepository.update({
        [e.target.name]: e.target.value,
      });
      mutate(res, false);
      dispatch(mainOperations.updateSuccessMessage("保存しました"));
    } catch (error) {
      handleReduxError(error, dispatch, "保存に失敗しました");
    } finally {
      dispatch(mainOperations.updateIsLoading(false));
    }
  };

  const handleAvatarChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;
    dispatch(mainOperations.updateIsLoading(true));
    try {
      const res = await hubRepository.update({
        logoFile: file,
      });
      mutate(res, false);
      dispatch(mainOperations.updateSuccessMessage("画像を変更しました"));
    } catch (error) {
      handleReduxError(error, dispatch, "画像の変更に失敗しました");
    } finally {
      dispatch(mainOperations.updateIsLoading(false));
    }
  };

  const handleAvatarDelete = async () => {
    dispatch(mainOperations.updateIsLoading(true));
    try {
      const res = await hubRepository.update({
        deleteLogoFile: true,
      });
      mutate(res, false);
      dispatch(mainOperations.updateSuccessMessage("画像を削除しました"));
    } catch (error) {
      handleReduxError(error, dispatch, "画像の削除に失敗しました");
    } finally {
      dispatch(mainOperations.updateIsLoading(false));
    }
  };

  return (
    <Layout>
      <Box mb="20px">
        <Typography fontWeight="bold" fontSize="24px">
          事業者情報
        </Typography>
      </Box>

      <Stack spacing={3} width="420px">
        <Box sx={{ ...styles.avatar }}>
          <Avatar
            url={formState.logoUrl}
            name={formState.name}
            bgColor={formState.logoBackgroundColorNumber}
            size="medium"
          />
          <Box className="overlay" sx={{ ...styles.avatarOverlay }} />
          <Box className="avatarButtons" sx={{ ...styles.avatarButtons }}>
            <Button variant="contained" component="label" sx={{ ...styles.avatarChangeButton }}>
              変更
              <input type="file" accept="image/*" hidden onChange={handleAvatarChange} />
            </Button>
            {formState.logoUrl && (
              <Button
                variant="text"
                onClick={handleAvatarDelete}
                sx={{ ...styles.avatarDeleteButton }}
              >
                削除
              </Button>
            )}
          </Box>
        </Box>
        <div>
          <Typography sx={{ ...styles.label }}>会社名</Typography>
          <TextField
            name="name"
            value={formState.name}
            onChange={handleChange}
            onBlur={handleBlur}
            size="small"
            fullWidth
          />
        </div>
        <div>
          <Typography sx={{ ...styles.label }}>事業所名</Typography>
          <TextField
            name="officeName"
            value={formState.officeName}
            onChange={handleChange}
            onBlur={handleBlur}
            size="small"
            fullWidth
          />
        </div>
        <Grid2 container spacing={2} alignContent="center">
          <Grid2 size={4}>
            <Typography sx={{ ...styles.label }}>郵便番号</Typography>
            <TextField
              name="postalCode"
              value={formState.postalCode}
              onChange={handleChange}
              onBlur={handleBlur}
              size="small"
              fullWidth
              InputProps={{
                startAdornment: <InputAdornment position="start">〒</InputAdornment>,
              }}
            />
          </Grid2>
          <Grid2 size={8}>
            <CustomFormLabel
              labelName="都道府県・市区町村・番地"
              labelSize="12px"
              required
              requiredSize="11px"
            />
            <TextField
              name="address"
              value={formState.address}
              onChange={handleChange}
              onBlur={handleBlur}
              size="small"
              fullWidth
            />
          </Grid2>
        </Grid2>
        <div>
          <Typography sx={{ ...styles.label }}>建物名・階・号室</Typography>
          <TextField
            name="addressSecond"
            value={formState.addressSecond}
            onChange={handleChange}
            onBlur={handleBlur}
            size="small"
            fullWidth
          />
        </div>
        <div>
          <CustomFormLabel labelName="代表電話番号" labelSize="12px" required requiredSize="11px" />
          <TextField
            name="phoneNumber"
            value={formState.phoneNumber}
            onChange={handleChange}
            onBlur={handleBlur}
            size="small"
            fullWidth
          />
        </div>
        <div>
          <Typography sx={{ ...styles.label }}>現場Hub ID</Typography>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography>{formState.uuid}</Typography>
            <Button
              startIcon={<ContentCopy fontSize="small" />}
              onClick={copyShareUrlToClipboard}
              color="primary"
              sx={{ p: 0, justifyContent: "end", display: "block", lineHeight: "1" }}
            />
          </div>
        </div>
      </Stack>
    </Layout>
  );
};

import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Box, Button, Typography } from "@mui/material";
import {
  GRID_REORDER_COL_DEF,
  GridColDef,
  GridRenderCellParams,
  GridRowOrderChangeParams,
  GridRowParams,
} from "@mui/x-data-grid-pro";
import { ConnectTypeLabel } from "components/label/connect-type-label";
import { StatusTypeLabel } from "components/label/status-type-label";
import { CustomizedDataGridPro } from "components/templates/customized-data-grid-pro";
import { connectRepository } from "features/hub-settings/api/connect/connect.repository";
import { ConnectCollection } from "features/hub-settings/types/connect/connect.dto";
import { useAppDispatch } from "store/hooks";
import { mainOperations } from "store/main/operations";
import useSWR from "swr";
import { API_PATHS } from "utils/apiPaths";
import { handleReduxError } from "utils/errorHandler";
import { ConnectCreateModal } from "./create-request-modal";
import { ConnectDetailModal } from "./detail-modal";

export const HubConnect = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [isCreateModalOpen, setIsCreateModalOpen] = useState<boolean>(false);
  const [isDetailModalOpen, setIsDetailModalOpen] = useState<boolean>(false);

  const { data: connects, isValidating } = useSWR(API_PATHS.getConnects(), connectRepository.index);

  useEffect(() => {
    if (new RegExp(`^/hub-settings/connects/\\d+$`).test(location.pathname)) {
      setIsDetailModalOpen(true);
    }
  }, [location]);

  const handleRowClick = (params: GridRowParams<ConnectCollection>) => {
    if (params.row.statusType.value === "active") {
      navigate(`/hub-settings/connects/${params.row.id}`, {
        state: { id: params.row.id },
      });
    }
    if (params.row.statusType.value === "requesting") {
      navigate(`/hub-settings/connects/requesting/${params.row.requestingConnectRequestId}`, {
        state: { id: params.row.requestingConnectRequestId },
      });
    }
    if (params.row.statusType.value === "approvable") {
      navigate(`/hub-settings/connects/approvable/${params.row.approvableConnectRequestId}`, {
        state: { id: params.row.approvableConnectRequestId },
      });
    }
  };

  const handleCloseDetailModal = () => {
    navigate("/hub-settings/connects");
    setIsDetailModalOpen(false);
  };

  const handleRowOrderChange = async (params: GridRowOrderChangeParams) => {
    dispatch(mainOperations.updateIsLoading(true));
    try {
      await connectRepository.update(params.row.id, {
        displayOrderPosition: params.targetIndex,
      });
      dispatch(mainOperations.updateSuccessMessage("順番を変更しました"));
    } catch (error) {
      handleReduxError(error, dispatch, "順番の変更に失敗しました");
    } finally {
      dispatch(mainOperations.updateIsLoading(false));
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: "16px",
        }}
      >
        <Typography sx={{ fontSize: "14px", fontWeight: "bold" }}>コネクト中のHub</Typography>
        <Button
          variant="contained"
          startIcon={<AddCircleIcon />}
          onClick={() => setIsCreateModalOpen(true)}
        >
          コネクトを申請
        </Button>
      </Box>

      {isCreateModalOpen && (
        <ConnectCreateModal
          isOpen={isCreateModalOpen}
          onClose={() => setIsCreateModalOpen(false)}
        />
      )}
      {isDetailModalOpen && (
        <ConnectDetailModal isOpen={isDetailModalOpen} onClose={handleCloseDetailModal} />
      )}

      <CustomizedDataGridPro
        rows={connects || []}
        columns={columns()}
        loading={isValidating}
        hideFooter
        rowReordering
        onRowOrderChange={handleRowOrderChange}
        onRowClick={handleRowClick}
        pinnedColumns={{ left: [GRID_REORDER_COL_DEF.field, "name"] }}
        sx={{
          height: "60vh",
          "& .MuiDataGrid-virtualScroller": {
            overflow: "auto",
          },
        }}
      />
    </>
  );
};

const columns = () => {
  const headers: GridColDef[] = [];
  headers.push({
    field: "name",
    headerName: "会社名",
    minWidth: 240,
    sortable: false,
    disableColumnMenu: true,
    renderCell: (params: GridRenderCellParams<ConnectCollection>) => (
      <Typography>{params.row.partnerHub.name}</Typography>
    ),
  });
  headers.push({
    field: "officeName",
    headerName: "事業所名",
    minWidth: 160,
    sortable: false,
    disableColumnMenu: true,
    renderCell: (params: GridRenderCellParams<ConnectCollection>) => (
      <Typography>{params.row.partnerHub.officeName}</Typography>
    ),
  });
  headers.push({
    field: "address",
    headerName: "住所",
    minWidth: 300,
    sortable: false,
    disableColumnMenu: true,
    renderCell: (params: GridRenderCellParams<ConnectCollection>) => (
      <Typography>
        〒{params.row.partnerHub.postalCode}{" "}
        {params.row.partnerHub.address + params.row.partnerHub.addressSecond}
      </Typography>
    ),
  });
  headers.push({
    field: "connectType",
    headerName: "自社の立場",
    minWidth: 120,
    sortable: false,
    disableColumnMenu: true,
    renderCell: (params: GridRenderCellParams<ConnectCollection>) => (
      <ConnectTypeLabel name={params.row.ownHubConnectType.valueI18n} />
    ),
  });
  headers.push({
    field: "statusType",
    headerName: "ステータス",
    minWidth: 120,
    sortable: false,
    disableColumnMenu: true,
    renderCell: (params: GridRenderCellParams<ConnectCollection>) => (
      <StatusTypeLabel statusType={params.row.statusType.valueI18n} sx={{ width: "80px" }} />
    ),
  });
  return headers;
};

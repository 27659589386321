import { theme } from "extensions/theme";

export const styles = {
  hubContent: {
    width: "380px",
    height: "230px",
    display: "flex",
    gap: "24px",
    bgcolor: theme.palette.customPrimary[50],
    borderRadius: "8px",
    p: "16px",
  },
  hubName: {
    flex: 1,
    fontWeight: "bold",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  button: {
    height: "32px",
    width: "100px",
    borderRadius: "32px",
    fontWeight: "bold",
    fontSize: "12px",
  },
};

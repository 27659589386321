import { Box, Button, Typography } from "@mui/material";
import { Avatar } from "components/avatar";
import { ConnectTypeLabel } from "components/label/connect-type-label";
import { SignUpVerifyResponse } from "data-access/repositories/sign_up/sign_up.dto";
import { theme } from "extensions/theme";
import { WalkThrough } from "../walk-through";
import { PageState } from ".";

interface Props {
  data: SignUpVerifyResponse;
  setPageState: (pageState: PageState) => void;
}
export const First = (props: Props) => {
  const handleNext = () => {
    props.setPageState("profile");
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <WalkThrough />
      <Box sx={{ my: 3, display: "flex", flexDirection: "column", alignItems: "center" }}>
        <Typography
          sx={{
            fontSize: "16px",
            textAlign: "center",
            mb: "20px",
            whiteSpace: "pre-line",
            fontWeight: "bold",
          }}
        >
          {`以下のHubから招待が届いています\n 自社のHubを作成しましょう`}
        </Typography>
        <Box
          sx={{
            display: "flex",
            gap: "20px",
            bgcolor: theme.palette.customPrimary[50],
            p: "16px 24px",
            width: "80%",
            borderRadius: "8px",
            mb: "24px",
          }}
        >
          <Avatar
            url={props.data.hub.logoUrl}
            bgColor={props.data.hub.logoBackgroundColorNumber}
            name={props.data.hub.name}
            size="medium"
          />
          <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
            <Typography sx={{ fontWeight: "bold" }}>{props.data.hub.name}</Typography>
            <Typography sx={{ fontSize: "14px", color: theme.palette.grayScale[700] }}>
              {props.data.hub.officeName}
            </Typography>
            <Typography sx={{ fontSize: "14px", color: theme.palette.grayScale[700] }}>
              {props.data.hub.address}
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
              <Typography sx={{ fontSize: "14px", color: theme.palette.grayScale[700], flex: 1 }}>
                {props.data.hub.name}が
              </Typography>
              <ConnectTypeLabel
                name={props.data.connectType.valueI18n}
                sx={{ width: "fit-content" }}
              />
            </Box>
          </Box>
        </Box>
        <Button
          variant="contained"
          onClick={handleNext}
          sx={{ borderRadius: "24px", width: "240px" }}
        >
          Hub作成に進む
        </Button>
      </Box>
    </Box>
  );
};

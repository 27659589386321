import { ElementRef, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Close, ContentCopy } from "@mui/icons-material";
import { Box, Button, InputAdornment, MenuItem, Select, TextField, Tooltip } from "@mui/material";
import { CustomAutocomplete } from "components/atoms/custom-autocomplete";
import { DeleteIcon } from "components/icon/delete-icon";
import { DuplicateIcon } from "components/icon/duplicate-icon";
import { StatusTypeLabel } from "components/label/status-type-label";
import { CustomFormLabel } from "components/molecules/custom-form-label";
import { AsyncConfirmDialog } from "components/templates/async-confirm-dialog";
import { Building, BuildingId } from "data-access/repositories/building/building.dto";
import { Client, ClientId } from "data-access/repositories/client/client.dto";
import {
  Project,
  ProjectFormHeaderState,
  ProjectFormIdentificationState,
  ProjectFormNotesState,
  ProjectFormReportContentsState,
  ProjectFormSiteState,
  ProjectFormUpdateRequest,
  ProjectId,
} from "data-access/repositories/project/project.dto";
import { projectRepository } from "data-access/repositories/project/project.repository";
import { ProjectBilling } from "data-access/repositories/project/project_billing/project_billing.dto";
import { ProjectStatusTypeId } from "data-access/repositories/project_status_type/project_status_type.dto";
import { theme } from "extensions/theme";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { mainOperations } from "store/main/operations";
import { selectMain } from "store/main/slice";
import useSWR, { mutate } from "swr";
import { API_PATHS } from "utils/apiPaths";
import { ACTIVITY_PER_PAGE } from "utils/constant";
import { handleReduxError } from "utils/errorHandler";
import { openURLInNewTab } from "utils/openURLInNewTab";
import { styles } from "./styles";
import { SourceProject } from "../source-project";

interface Props {
  isCreateNew: boolean;
  isLoading: boolean;
  identificationState: ProjectFormIdentificationState;
  siteState: ProjectFormSiteState;
  reportContentState: ProjectFormReportContentsState;
  notesState: ProjectFormNotesState;
  headerState: ProjectFormHeaderState;
  setHeaderState: React.Dispatch<React.SetStateAction<ProjectFormHeaderState>>;
  lastSavedFormValue: React.MutableRefObject<ProjectFormUpdateRequest>;
  currentPage: number;
  projectId?: ProjectId;
  onCreateProject: (targetName: string, value: any) => void;
  onCreateBuilding: () => void;
  onChangeBuilding: (
    e: React.SyntheticEvent<Element, Event>,
    value: {
      id: BuildingId;
      name: string;
      createOption: boolean;
    } | null,
  ) => void;
  onClose: () => void;
  rawClientsData: Client[];
  rawBuildingsData: Building[];
  selectedClient: Client | undefined;
  selectedBuilding: Building | undefined;
  projectBillings: ProjectBilling[];
}
export const ProjectSidebarHeader = (props: Props) => {
  const dispatch = useAppDispatch();
  const mainState = useAppSelector(selectMain);
  const navigate = useNavigate();

  const [projectNameTip, setProjectNameTip] = useState<string>("案件名をコピーします");
  const [projectNameAndCodeTip, setProjectNameAndCodeTip] =
    useState<string>("案件名・案件番号をコピーします");
  const [projectInfoTip, setProjectInfoTip] = useState<string>("案件情報をコピーします");

  const { data: project } = useSWR<Project>(
    props.projectId ? API_PATHS.getProject(props.projectId) : null,
  );
  const buildings = props.rawBuildingsData.map((building) => {
    return {
      id: building.id,
      name: building.name,
      createOption: false,
    };
  });

  const clients = props.rawClientsData.map((client) => {
    return {
      id: client.id,
      name: client.name,
      phoneNumber: client.phoneNumber,
      phoneNumberSecond: client.phoneNumberSecond,
      createOption: false,
    };
  });

  const handleOpenNameTip = () => {
    setProjectNameTip("案件名をコピーします");
  };

  const handleOpenNameAndCodeTip = () => {
    setProjectNameAndCodeTip("案件名・案件番号をコピーします");
  };

  const handleOpenInfoTip = () => {
    setProjectInfoTip("案件情報をコピーします");
  };

  const copyProjectNameToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(`${props.lastSavedFormValue.current.name}`);
      setProjectNameTip("コピーしました");
    } catch (error) {
      alert((error && error.message) || "コピーに失敗しました");
    }
  };

  const copyProjectNameAndCodeToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(
        `${props.identificationState.code}：${props.headerState.name}`,
      );
      setProjectNameAndCodeTip("コピーしました");
    } catch (error) {
      alert((error && error.message) || "コピーに失敗しました");
    }
  };

  const copyProjectInfoToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(projectInfo());
      setProjectInfoTip("コピーしました");
    } catch (error) {
      alert((error && error.message) || "コピーに失敗しました");
    }
  };

  const handleDuplicate = async () => {
    if (!props.projectId) return;
    dispatch(mainOperations.updateIsLoading(true));
    try {
      const res = await projectRepository.duplicate(props.projectId);
      navigate(`/projects/${res.id}`);
      dispatch(mainOperations.updateSuccessMessage("案件を複製しました"));
      mutate([API_PATHS.getProjects(), props.currentPage]);
    } catch (error) {
      handleReduxError(error, dispatch, "案件の複製に失敗しました");
    } finally {
      dispatch(mainOperations.updateIsLoading(false));
    }
  };

  const deleteConfirmRef = useRef<ElementRef<typeof AsyncConfirmDialog>>(null);
  const handleDelete = async (id?: ProjectId) => {
    if (!deleteConfirmRef.current || !id) return;
    const res = await deleteConfirmRef.current.confirm();
    if (!res) return;
    dispatch(mainOperations.updateIsLoading(true));
    try {
      await projectRepository.destroy(id as ProjectId);
      dispatch(mainOperations.updateSuccessMessage("案件を削除しました"));
      mutate([API_PATHS.getProjects(), props.currentPage]);
      props.onClose();
    } catch (error) {
      handleReduxError(error, dispatch, "削除に失敗しました");
    } finally {
      dispatch(mainOperations.updateIsLoading(false));
    }
  };

  const projectInfo = () => {
    const manager = mainState.accounts.find(
      (account) => account.userId === props.identificationState.managerId,
    );
    const managerText = `現場責任者：${manager?.name}\n`;
    const addressText = `住所：${props.siteState.address} ${props.siteState.addressSecond}\n`;
    const phoneNumberText = `電話番号1：${props.siteState.phoneNumber}\n`;
    const phoneNumberSecondText = `電話番号2：${props.siteState.phoneNumberSecond}\n`;
    const faxNumberText = `FAX番号：${props.siteState.faxNumber}\n`;
    const emailText = `メールアドレス：${props.siteState.email}\n`;
    const requesterText = `依頼元：${props.reportContentState.requester}\n`;
    const noteText = `概要・メモ：${props.notesState.note}\n`;

    let text: string = "";
    if (props.identificationState.managerId) {
      text += managerText;
    }
    if (props.siteState.address) {
      text += addressText;
    }
    if (props.siteState.phoneNumber) {
      text += phoneNumberText;
    }
    if (props.siteState.phoneNumberSecond) {
      text += phoneNumberSecondText;
    }
    if (props.siteState.faxNumber) {
      text += faxNumberText;
    }
    if (props.siteState.email) {
      text += emailText;
    }
    if (mainState.hubSetting.is_printable_report_use && props.reportContentState.requester) {
      text += requesterText;
    }
    if (props.notesState.note) {
      text += noteText;
    }
    return text;
  };

  const customFilterOptions = (
    options: {
      id: ClientId;
      name: string;
      phoneNumber: string;
      phoneNumberSecond: string;
      createOption: boolean;
    }[],
    { inputValue }: { inputValue: string },
  ) => {
    return options.filter(
      (option) =>
        option.name.toLowerCase().includes(inputValue.toLowerCase()) ||
        option.phoneNumber?.toLowerCase().includes(inputValue.toLowerCase()) ||
        option.phoneNumberSecond?.toLowerCase().includes(inputValue.toLowerCase()),
    );
  };

  const handleCreateClient = () => {
    openURLInNewTab(`clients/new/project/${props.projectId}`);
  };

  const handleSubmitProject = async (
    currentValue: string | number | null,
    target: keyof ProjectFormHeaderState,
  ) => {
    if (props.isCreateNew) {
      props.onCreateProject(target, currentValue);
      return;
    }
    if (!props.projectId) return;
    if (currentValue !== props.lastSavedFormValue.current[target]) {
      dispatch(mainOperations.updateIsLoading(true));
      try {
        await projectRepository.update(props.projectId as unknown as ProjectId, {
          [target]: currentValue,
        });
        dispatch(mainOperations.updateSuccessMessage("更新しました"));
        props.lastSavedFormValue.current = {
          ...props.lastSavedFormValue.current,
          [target]: currentValue,
        };
        mutate([API_PATHS.getProjects(), props.currentPage]);
        mutate(API_PATHS.getProjectActivities(props.projectId, ACTIVITY_PER_PAGE));
      } catch (error) {
        handleReduxError(error, dispatch, "更新に失敗しました");
        props.setHeaderState((prev) => ({
          ...prev,
          [target]: props.lastSavedFormValue.current[target],
        }));
      } finally {
        dispatch(mainOperations.updateIsLoading(false));
      }
    }
  };

  return (
    <>
      <AsyncConfirmDialog ref={deleteConfirmRef} />
      <Box sx={styles.header}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            startIcon={<Close />}
            onClick={props.onClose}
            sx={{ color: theme.palette.grayScale[700] }}
          >
            とじる
          </Button>
          <div>
            {project &&
              ((project.isOrdering && !project.isOrdered) ||
                (!project.isOrdering && !project.isOrdered)) && (
                <>
                  {mainState.hubSetting.is_copy_project_name_and_code_use && (
                    <Tooltip
                      title={projectNameAndCodeTip}
                      onOpen={handleOpenNameAndCodeTip}
                      placement="top"
                      arrow
                    >
                      <span>
                        <Button
                          startIcon={<ContentCopy />}
                          onClick={copyProjectNameAndCodeToClipboard}
                          color="primary"
                          disabled={props.isCreateNew}
                          sx={{ mr: "0.7rem" }}
                        >
                          案件名と案件番号をコピー
                        </Button>
                      </span>
                    </Tooltip>
                  )}
                </>
              )}
            <Tooltip title={projectInfoTip} onOpen={handleOpenInfoTip} placement="top" arrow>
              <span>
                <Button
                  startIcon={<ContentCopy />}
                  onClick={copyProjectInfoToClipboard}
                  disabled={props.isCreateNew}
                  sx={{ mr: "0.7rem" }}
                >
                  案件情報をコピー
                </Button>
              </span>
            </Tooltip>
            {project &&
              ((project.isOrdering && !project.isOrdered) ||
                (!project.isOrdering && !project.isOrdered)) && (
                <>
                  <Button
                    startIcon={<DuplicateIcon size={18} />}
                    onClick={handleDuplicate}
                    color="primary"
                    disabled={props.isLoading || props.isCreateNew}
                    sx={{ mr: "0.7rem" }}
                  >
                    案件を複製
                  </Button>
                  <Button
                    startIcon={<DeleteIcon size={16} />}
                    onClick={() => handleDelete(props.projectId)}
                    color="error"
                    disabled={props.isLoading || props.isCreateNew}
                  >
                    案件を削除
                  </Button>
                </>
              )}
          </div>
        </Box>

        {project?.isOrdered && <SourceProject projectId={props.projectId} />}

        <Box sx={{ my: "16px", display: "flex", gap: "18px" }}>
          <div>
            <CustomFormLabel labelName="案件名" labelColor={theme.palette.grayScale[700]} />
            <TextField
              name="name"
              value={props.headerState.name}
              onChange={(e) => props.setHeaderState((prev) => ({ ...prev, name: e.target.value }))}
              onBlur={(e) => {
                const value = e.target.value;
                handleSubmitProject(value, "name");
              }}
              slotProps={{
                input: {
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip
                        title={projectNameTip}
                        onOpen={handleOpenNameTip}
                        placement="top"
                        arrow
                      >
                        <span>
                          <Button
                            startIcon={<ContentCopy />}
                            onClick={copyProjectNameToClipboard}
                            color="primary"
                            disabled={props.isLoading || props.isCreateNew}
                            sx={{ p: 0, justifyContent: "end" }}
                          />
                        </span>
                      </Tooltip>
                    </InputAdornment>
                  ),
                },
              }}
              sx={{ ...styles.textField }}
            />
          </div>
          <Box sx={{ ...styles.box }}>
            <CustomFormLabel labelName="案件ステータス" labelColor={theme.palette.grayScale[700]} />
            <Select
              name="projectStatusTypeId"
              variant="standard"
              value={props.headerState.projectStatusTypeId || 0}
              onChange={(e) => {
                const value = Number(e.target.value) as ProjectStatusTypeId;
                props.setHeaderState((prev) => ({ ...prev, projectStatusTypeId: value }));
                handleSubmitProject(value, "projectStatusTypeId");
              }}
              sx={{ "&.MuiInputBase-root:before": { borderBottom: "none" } }}
            >
              {mainState.projectStatusTypes.map((projectStatusType) => (
                <MenuItem key={projectStatusType.id} value={projectStatusType.id}>
                  <StatusTypeLabel
                    statusType={projectStatusType.name}
                    color={projectStatusType.colorNumber}
                    sx={{ height: "auto", p: "3px 0", width: "120px" }}
                  />
                </MenuItem>
              ))}
            </Select>
          </Box>

          <Box sx={{ ...styles.box }}>
            <CustomFormLabel labelName="物件" labelColor={theme.palette.grayScale[700]} />
            <CustomAutocomplete
              data={buildings}
              value={props.selectedBuilding || null}
              entityName="物件"
              entityLabel="入力して検索"
              onChange={async (_, value, reason) => {
                if (reason === "selectOption" && value) {
                  props.setHeaderState((prev) => ({ ...prev, buildingId: value.id }));
                  props.onChangeBuilding(_, value);
                }
                if (reason === "clear") {
                  props.setHeaderState((prev) => ({ ...prev, buildingId: null }));
                  props.onChangeBuilding(_, value);
                }
              }}
              onClick={props.onCreateBuilding}
              isCreate
              variant="standard"
              isClearable
              sx={{ width: "150px", mb: "20px", "&.MuiAutocomplete-root": { mb: 0 } }}
            />
          </Box>
          <Box sx={{ ...styles.box }}>
            <CustomFormLabel labelName="顧客" labelColor={theme.palette.grayScale[700]} />
            <CustomAutocomplete
              data={clients}
              value={props.selectedClient || null}
              entityName="顧客"
              entityLabel="入力して検索"
              onChange={(_, value) => {
                props.setHeaderState({ ...props.headerState, clientId: value?.id || null });
                handleSubmitProject(value?.id || null, "clientId");
              }}
              onClick={handleCreateClient}
              isCreate
              variant="standard"
              isClearable
              disabled={props.projectBillings.length > 0}
              filterOptions={customFilterOptions}
              sx={{ width: "170px", mb: "20px", "&.MuiAutocomplete-root": { mb: 0 } }}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import { PasswordInput } from "components/input/password-input";
import { LoadingOverlay } from "components/loadingOverlay";
import { CustomFormLabel } from "components/molecules/custom-form-label";
import { cookieRepository } from "data-access/cookie/cookie.repository";
import {
  initialSignUpAccountRequest,
  SignUpAccountRequest,
  SignUpVerifyResponse,
} from "data-access/repositories/sign_up/sign_up.dto";
import { signUpRepository } from "data-access/repositories/sign_up/sign_up.repository";
import { styles } from "./styles";
import { PageState } from ".";

interface Props {
  data: SignUpVerifyResponse;
  token: string;
  setPageState: (pageState: PageState) => void;
}
export const Profile = (props: Props) => {
  const [formState, setFormState] = useState<SignUpAccountRequest>(initialSignUpAccountRequest);
  const [isAgreed, setIsAgreed] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  useEffect(() => {
    setFormState({
      ...formState,
      signupToken: props.token,
    });
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;

    if (name === "name" || name === "phoneNumber") {
      setFormState({
        ...formState,
        me: {
          ...formState.me,
          [name]: value,
        },
      });
    } else {
      setFormState({
        ...formState,
        [name]: value,
      });
    }
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      await signUpRepository.validateAccount(formState);
      const res = await signUpRepository.createAccount(formState);

      if (res && res.data) {
        cookieRepository.set("artisan", {
          id: res.data.id,
          name: res.data.name,
          email: res.data.email,
          hub_id: res.data.hubId,
          hub_uuid: res.data.hubUuid,
          hub_name: res.data.hubName,
          permissions: {
            master_write: res.data.permissions.masterWrite,
          },
        });

        cookieRepository.set("access_token", res.data.accessToken as any);
        cookieRepository.set("refresh_token", res.data.refreshToken as any);

        window.location.href = "/schedules";
      }
    } catch (error) {
      setErrorMessage(error.response.data?.message || "アカウントの作成に失敗しました");
    } finally {
      setIsSubmitting(false);
    }
  };

  const openInNewTab = (url: string) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const isFormValid = () => {
    return (
      formState.password !== "" &&
      formState.me.name !== "" &&
      formState.me.phoneNumber !== "" &&
      isAgreed
    );
  };

  return (
    <>
      <LoadingOverlay isLoading={isSubmitting} />
      <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        <Typography sx={{ fontSize: "24px", fontWeight: "bold", textAlign: "center", mb: "40px" }}>
          現場Hubアカウントを作成
        </Typography>
        <Typography
          sx={{ fontSize: "14px", fontWeight: "bold", mb: "8px", textAlign: "left", width: "100%" }}
        >
          メールアドレス
        </Typography>
        <TextField
          name="destinationEmail"
          value={props.data.destinationEmail}
          disabled
          fullWidth
          sx={{
            "& .MuiOutlinedInput-root": {
              height: "48px",
            },
            mb: "24px",
          }}
        />
        <CustomFormLabel
          labelName="パスワード"
          required
          small
          requiredSize="14px"
          sx={{ justifyContent: "flex-start", width: "100%" }}
        />
        <PasswordInput password={formState.password} onChange={handleChange} width="100%" />
        <CustomFormLabel
          labelName="氏名"
          required
          small
          requiredSize="14px"
          sx={{ justifyContent: "flex-start", width: "100%", mt: "16px" }}
        />
        <TextField
          name="name"
          value={formState.me.name}
          onChange={handleChange}
          fullWidth
          sx={{
            "& .MuiOutlinedInput-root": {
              height: "48px",
            },
            mb: "24px",
          }}
        />
        <CustomFormLabel
          labelName="連絡のつきやすい電話番号"
          required
          small
          requiredSize="14px"
          sx={{ justifyContent: "flex-start", width: "100%" }}
        />
        <TextField
          name="phoneNumber"
          value={formState.me.phoneNumber}
          onChange={handleChange}
          fullWidth
          sx={{
            "& .MuiOutlinedInput-root": {
              height: "48px",
            },
            mb: "24px",
          }}
        />

        <FormControlLabel
          sx={{ mb: "8px" }}
          control={<Checkbox checked={isAgreed} onChange={(e) => setIsAgreed(e.target.checked)} />}
          label={
            <Box sx={{ display: "flex", alignItems: "center", fontSize: "14px" }}>
              <Link
                component="button"
                onClick={(e) => {
                  e.stopPropagation();
                  openInNewTab("https://lp.gembahub.jp/terms");
                }}
                sx={{ textDecoration: "underline", cursor: "pointer" }}
              >
                利用規約
              </Link>
              と
              <Link
                component="button"
                onClick={(e) => {
                  e.stopPropagation();
                  openInNewTab("https://corp.gembahub.jp/privacy");
                }}
                sx={{ textDecoration: "underline", cursor: "pointer" }}
              >
                プライバシーポリシー
              </Link>
              に同意する
            </Box>
          }
        />
        <Button
          variant="contained"
          onClick={handleSubmit}
          disabled={!isFormValid()}
          sx={{ borderRadius: "24px" }}
        >
          アカウント作成
        </Button>
      </Box>
      {errorMessage && (
        <Typography color="error" sx={{ ...styles.errorMessage }}>
          {errorMessage}
        </Typography>
      )}
    </>
  );
};

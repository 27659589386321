import { forwardRef, useState } from "react";
import { Box } from "@mui/material";
import { LoadingOverlay } from "components/loadingOverlay";
import { supplierRepository } from "features/cost/api/supplier.repository";
import useSWR from "swr";
import { SupplierFormModal } from "./form-modal";
import SupplierHeader from "./header";
import { SupplierTable } from "./table";

const Supplier = forwardRef<HTMLDivElement>((_, ref) => {
  const fetchIndexKey: string = "/api/v1/suppliers";
  const [isModalOpen, setModalOpen] = useState<boolean>(false);

  const { data: supplier, isLoading } = useSWR(fetchIndexKey, supplierRepository.index);

  if (!supplier) {
    return <LoadingOverlay isLoading={isLoading} />;
  }

  return (
    <>
      <SupplierFormModal
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
        fetchIndexKey={fetchIndexKey}
      />
      <SupplierHeader isOpen={isModalOpen} setOpen={setModalOpen} ref={ref} />
      <Box>
        <SupplierTable fetchIndexKey={fetchIndexKey} />
      </Box>
    </>
  );
});

Supplier.displayName = "Supplier";

export default Supplier;

import { useState } from "react";
import { Box, Typography, Collapse } from "@mui/material";
import { Avatar } from "components/avatar";
import { TitleBadge } from "components/badge/title-badge";
import { CustomDivider } from "components/divider";
import { AccordionToggle } from "components/molecules/accordion-toggle";
import { ScheduleParticipant } from "data-access/repositories/project/participant/participant.dto";
import { theme } from "extensions/theme";
import { styles } from "./styles";

interface Props {
  participantHubs: ScheduleParticipant[];
  isDefaultExpanded?: boolean;
}

export const ScheduleParticipantHubsBlock = (props: Props) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(props.isDefaultExpanded ?? false);

  const organizers = props.participantHubs.filter((hub) => hub.isOrganizer && !hub.isDeclined);
  const guests = props.participantHubs.filter((hub) => !hub.isOrganizer && !hub.isDeclined);
  const declined = props.participantHubs.filter((hub) => hub.isDeclined);

  const renderParticipantSection = (title: string, hubs: ScheduleParticipant[]) => {
    if (hubs.length === 0) return null;

    return (
      <Box sx={{ mb: 2 }}>
        <CustomDivider title={title} color={theme.palette.grayScale[700]} />
        {hubs.map((hub) => (
          <Box key={hub.id} sx={{ mb: 2 }}>
            <Box sx={styles.hubHeader}>
              <Avatar
                url={hub.logoUrl}
                bgColor={hub.logoBackgroundColorNumber}
                name={hub.name}
                size="small"
              />
              <Typography fontSize="14px">{hub.name}</Typography>
            </Box>
            <Box sx={styles.participantsContainer}>
              {/* 現場責任者を先に表示 */}
              {hub.participants
                .filter((participant) => participant.isSiteManager)
                .map((participant) => (
                  <Box key={participant.userId} sx={styles.siteManagerItem}>
                    <Avatar
                      url={participant.accountProfileImageUrl}
                      bgColor={participant.accountProfileImageBackgroundColorNumber}
                      name={participant.accountName}
                      size="small"
                      sx={{ borderRadius: "50%" }}
                      participationType={participant.participationType}
                      showStatus={true}
                    />
                    <Typography variant="caption" sx={{ textAlign: "center" }}>
                      {participant.accountName}
                    </Typography>
                    <TitleBadge title="当日現場責任者" />
                  </Box>
                ))}

              {/* 現場責任者以外を表示 */}
              <Box sx={styles.participantsGrid}>
                {hub.participants
                  .filter((participant) => !participant.isSiteManager)
                  .map((participant) => (
                    <Box key={participant.userId} sx={styles.participantItem}>
                      <Avatar
                        url={participant.accountProfileImageUrl}
                        bgColor={participant.accountProfileImageBackgroundColorNumber}
                        name={participant.accountName}
                        size="small"
                        sx={{ borderRadius: "50%" }}
                        participationType={participant.participationType}
                        showStatus={true}
                      />
                      <Typography variant="caption" sx={{ textAlign: "center" }}>
                        {participant.accountName}
                      </Typography>
                    </Box>
                  ))}
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
    );
  };

  return (
    <Box sx={styles.container}>
      <AccordionToggle
        title="この予定の参加Hub一覧"
        isExpanded={isExpanded}
        setIsExpanded={setIsExpanded}
        titleStyle={{ fontSize: "14px", fontWeight: "bold" }}
        boxStyle={{ display: "flex", justifyContent: "space-between" }}
      />

      <Collapse in={isExpanded} timeout="auto">
        {renderParticipantSection("主催者", organizers)}
        {renderParticipantSection("ゲスト", guests)}
        {renderParticipantSection("不参加", declined)}
      </Collapse>
    </Box>
  );
};

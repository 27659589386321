import { useState } from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import { CustomModal } from "components/molecules/custom-modal";
import {
  AccountCreateRequest,
  initialAccountRequest,
} from "data-access/repositories/account/account.dto";
import { accountRepository } from "data-access/repositories/account/account.repository";
import { theme } from "extensions/theme";
import { useAppDispatch } from "store/hooks";
import { mainOperations } from "store/main/operations";
import { handleReduxError } from "utils/errorHandler";

interface Props {
  onClose: () => void;
  isOpen: boolean;
}

export const LoginDisabledMemberModal = (props: Props) => {
  const dispatch = useAppDispatch();

  const [formState, setFormState] = useState<AccountCreateRequest>(initialAccountRequest);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFormState({ ...formState, [e.target.name]: e.target.value as string });
  };

  const handleSubmit = async () => {
    setIsSubmitted(true);
    try {
      await accountRepository.create({
        name: formState.name,
        email: "",
        permissionSetId: null,
        groupName: "",
      });
      dispatch(mainOperations.getAccounts());
      dispatch(mainOperations.updateSuccessMessage("ログイン不可ユーザーを作成しました"));
      handleClose();
    } catch (error) {
      handleReduxError(error, dispatch, "ログイン不可ユーザーの作成に失敗しました");
    } finally {
      setIsSubmitted(false);
    }
  };

  const isValid = (): boolean => {
    if (!formState.name) return false;
    return true;
  };

  const handleClose = () => {
    setFormState(initialAccountRequest);
    props.onClose();
    setIsSubmitted(false);
  };

  return (
    <CustomModal
      open={props.isOpen}
      onClose={handleClose}
      title="ログイン不可ユーザーの新規作成"
      maxWidth="sm"
      footer={
        <>
          <Button variant="outlined" onClick={handleClose} sx={{ width: "10rem" }}>
            キャンセル
          </Button>
          <Button
            disabled={!isValid() || isSubmitted}
            onClick={handleSubmit}
            variant="contained"
            sx={{ width: "10rem", ml: "1rem" }}
          >
            保存
          </Button>
        </>
      }
    >
      <Box sx={{ marginTop: "20px" }}>
        <div style={{ display: "flex", alignItems: "center", marginBottom: "8px", gap: "8px" }}>
          <Typography sx={{ fontWeight: "bold" }}>名前</Typography>
          <Typography sx={{ color: theme.palette.red[500], fontSize: "14px", fontWeight: "500" }}>
            必須
          </Typography>
        </div>
        <TextField
          name="name"
          value={formState.name}
          onChange={handleChange}
          sx={{ width: "75%" }}
          placeholder="名前を入力"
        />
      </Box>
    </CustomModal>
  );
};

import { Typography, Box, Button } from "@mui/material";
import { Avatar } from "components/avatar";
import { SignUpVerifyResponse } from "data-access/repositories/sign_up/sign_up.dto";
import { theme } from "extensions/theme";
import { WalkThrough } from "../walk-through";
import { PageState } from ".";

interface Props {
  data: SignUpVerifyResponse;
  setPageState: (pageState: PageState) => void;
}
export const First = (props: Props) => {
  const handleNext = () => {
    props.setPageState("profile");
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <WalkThrough />
      <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        <Typography sx={{ fontSize: "24px", fontWeight: "bold", textAlign: "center", mb: "40px" }}>
          現場Hubへようこそ
        </Typography>
        <Box mb="24px" textAlign="center">
          <Typography sx={{ fontWeight: "bold" }}>以下のHubから招待が届いています</Typography>
          <Typography sx={{ fontWeight: "bold" }}>自分のアカウントを作成しましょう</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: "20px",
            bgcolor: theme.palette.customPrimary[50],
            p: "16px 24px",
            width: "80%",
            borderRadius: "8px",
            mb: "24px",
          }}
        >
          <Avatar
            url={props.data.hub.logoUrl}
            bgColor={props.data.hub.logoBackgroundColorNumber}
            name={props.data.hub.name}
            size="medium"
          />
          <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
            <Typography sx={{ fontWeight: "bold" }}>{props.data.hub.name}</Typography>
            <Typography sx={{ fontSize: "14px", color: theme.palette.grayScale[700] }}>
              {props.data.hub.officeName}
            </Typography>
            <Typography sx={{ fontSize: "14px", color: theme.palette.grayScale[700] }}>
              {props.data.hub.address}
            </Typography>
          </Box>
        </Box>
        <Button variant="contained" onClick={handleNext} sx={{ borderRadius: "24px" }}>
          アカウント作成に進む
        </Button>
      </Box>
    </Box>
  );
};

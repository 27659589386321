import { ApiClient } from "data-access/ApiClient";
import { convertKeysToCamelCase } from "utils/convertObjectKeyCase";
import { ProjectWorkReport } from "./work_report.dto";
import { ProjectId } from "../project.dto";

export const projectWorkReportRepository = {
  index(id: ProjectId): Promise<ProjectWorkReport[]> {
    return ApiClient.get(`/api/v2/projects/${id}/work_reports`).then((res) =>
      convertKeysToCamelCase(res.data),
    );
  },
};

import { forwardRef } from "react";
import { Box, Typography } from "@mui/material";
import { OutsourcedMemberTable } from "./table";

const OutsourcedMember = forwardRef<HTMLDivElement>((_, ref) => {
  return (
    <>
      <Box
        ref={ref}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: "16px",
        }}
      >
        <Typography sx={{ fontSize: "14px", fontWeight: "bold" }}>協力会社</Typography>
      </Box>
      <Box>
        <OutsourcedMemberTable />
      </Box>
    </>
  );
});

OutsourcedMember.displayName = "OutsourcedMember";

export default OutsourcedMember;

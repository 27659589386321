import { useState } from "react";
import {
  initialSignUpAccountRequest,
  initialSignUpHubRequest,
  SignUpAccountRequest,
  SignUpHubRequest,
  SignUpResponse,
  SignUpVerifyResponse,
} from "data-access/repositories/sign_up/sign_up.dto";
import { Confirm } from "./confirm";
import { Created } from "../created";
import { First } from "./first";
import { Hub } from "./hub";
import { Profile } from "./profile";

export type PageState = "first" | "profile" | "hub" | "confirm" | "created";

interface Props {
  data: SignUpVerifyResponse;
  token: string;
}
export const NewHub = (props: Props) => {
  const [pageState, setPageState] = useState<PageState>("first");
  const [formStateAccount, setFormStateAccount] = useState<SignUpAccountRequest>(
    initialSignUpAccountRequest,
  );
  const [formStateHub, setFormStateHub] = useState<SignUpHubRequest>(initialSignUpHubRequest);
  const [responseData, setResponseData] = useState<SignUpResponse | null>(null);

  return (
    <>
      {pageState === "first" && <First data={props.data} setPageState={setPageState} />}
      {pageState === "profile" && (
        <Profile
          data={props.data}
          token={props.token}
          setPageState={setPageState}
          formStateAccount={formStateAccount}
          setFormStateAccount={setFormStateAccount}
          formStateHub={formStateHub}
          setFormStateHub={setFormStateHub}
        />
      )}
      {pageState === "hub" && (
        <Hub
          data={props.data}
          setPageState={setPageState}
          token={props.token}
          formStateHub={formStateHub}
          setFormStateHub={setFormStateHub}
        />
      )}
      {pageState === "confirm" && (
        <Confirm
          data={props.data}
          setPageState={setPageState}
          formStateHub={formStateHub}
          token={props.token}
          setResponseData={setResponseData}
        />
      )}
      {pageState === "created" && responseData && <Created data={responseData} flow="newHub" />}
    </>
  );
};

import { useState } from "react";
import * as React from "react";
import { FileDownloadOutlined, Link, Visibility } from "@mui/icons-material";
import { Box, Typography, Tooltip, IconButton, Divider, Switch } from "@mui/material";
import { DeleteIcon } from "components/icon/delete-icon";
import { FileIcon } from "components/icon/file-icon";
import { TitleLabel } from "components/label/title-label";
import { AccordionToggle } from "components/molecules/accordion-toggle";
import { FileDropzone } from "components/molecules/file-dropzone";
import { ImagePreviewModal } from "components/molecules/image-preview-modal";
import { AsyncConfirmDialog } from "components/templates/async-confirm-dialog";
import { AttachmentTypeId } from "data-access/repositories/attachment_type/attachment_type.dto";
import {
  AttachmentResponse,
  AttachmentTypes,
  ProjectAttachmentId,
} from "data-access/repositories/project/attachment/attachment.dto";
import { attachmentRepository } from "data-access/repositories/project/attachment/attachment.repository";
import { ProjectId } from "data-access/repositories/project/project.dto";
import { theme } from "extensions/theme";
import { HubId } from "features/hub-settings/types/hub-info/hub_info.dto";
import { useBlockOperationsWhileUploading } from "hooks/useBlockOperationsWhileUploading";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { mainOperations } from "store/main/operations";
import { selectMain } from "store/main/slice";
import { mutate } from "swr";
import { API_PATHS } from "utils/apiPaths";
import {
  PREVIEWABLE_EXCEL_EXTENSION,
  IMAGE_EXTENSIONS,
  PDF_EXTENSION,
  TIF_EXTENSION,
} from "utils/constant";
import { handleReduxError } from "utils/errorHandler";
import { fileDownload } from "utils/fileDownload";
import { formatDateUtil } from "utils/formatDateUtil";
import { isContainExtensions } from "utils/isContainExtensions";

interface MainProps {
  projectId?: ProjectId;
  selectedHubId: HubId;
  attachmentTypes: AttachmentTypes[];
}

export const ProjectSidebarAttachmentContent = (props: MainProps) => {
  const mainState = useAppSelector(selectMain);
  const dispatch = useAppDispatch();
  const [expandedPanels, setExpandedPanels] = useState<boolean[]>([]);

  const { blockOperationsWhileUpload, releaseBlockOperationsWhileUpload } =
    useBlockOperationsWhileUploading();

  const handleUpload = async (files: File[], attachmentTypeId?: AttachmentTypeId) => {
    if (!props.projectId || !attachmentTypeId) return;
    blockOperationsWhileUpload();
    dispatch(mainOperations.updateIsLoading(true));
    try {
      await attachmentRepository.create(props.projectId, {
        files,
        attachmentTypeId,
      });
      dispatch(mainOperations.updateSuccessMessage("ファイルをアップロードしました"));
      mutate(API_PATHS.getProjectAttachmentsByAttachmentType(props.projectId, props.selectedHubId));
    } catch (error) {
      handleReduxError(error, dispatch, "ファイルのアップロードに失敗しました");
    } finally {
      releaseBlockOperationsWhileUpload();
      dispatch(mainOperations.updateIsLoading(false));
    }
  };

  const handleToggle = (index: number) => {
    setExpandedPanels((prev) => {
      const newExpanded = [...prev];
      newExpanded[index] = !newExpanded[index];
      return newExpanded;
    });
  };

  return (
    <>
      {props.attachmentTypes &&
        props.attachmentTypes.map((attachmentType, index) => (
          <Box key={index} sx={{ mb: "24px" }}>
            <TitleLabel title={attachmentType.name} sx={{ mb: "8px" }} />
            {mainState.hub.id === props.selectedHubId && (
              <FileDropzone onDrop={handleUpload} attachmentTypeId={attachmentType.id} />
            )}
            <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: "20px" }}>
                {attachmentType.attachments.slice(0, 4).map((attachment) => (
                  <AttachmentBlock
                    key={attachment.id}
                    selectedHubId={props.selectedHubId}
                    projectId={props.projectId as ProjectId}
                    attachment={attachment}
                  />
                ))}
              </Box>
              {expandedPanels[index] && (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: "20px" }}>
                  {attachmentType.attachments.slice(4).map((attachment) => (
                    <AttachmentBlock
                      key={attachment.id}
                      projectId={props.projectId as ProjectId}
                      selectedHubId={props.selectedHubId}
                      attachment={attachment}
                    />
                  ))}
                </Box>
              )}
              {attachmentType.attachments.length > 4 && (
                <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
                  <AccordionToggle
                    title={expandedPanels[index] ? "少なく表示" : "すべて表示"}
                    isExpanded={expandedPanels[index] || false}
                    setIsExpanded={() => handleToggle(index)}
                    titleStyle={{ color: theme.palette.primary.main, fontWeight: "500" }}
                  />
                </Box>
              )}
            </Box>
          </Box>
        ))}
    </>
  );
};

interface Props {
  projectId: ProjectId;
  selectedHubId: HubId;
  attachment: AttachmentResponse;
}

const AttachmentBlock = (props: Props) => {
  const mainState = useAppSelector(selectMain);
  const dispatch = useAppDispatch();
  const [copyTip, setCopyTip] = useState<string>("ファイルのリンクをコピーします");
  const [isPreviewOpen, setIsPreviewOpen] = useState<boolean>(false);
  const [previewImage, setPreviewImage] = useState<{ name: string; url: string }>({
    name: "",
    url: "",
  });
  const deleteConfirmRef = React.useRef<React.ElementRef<typeof AsyncConfirmDialog>>(null);

  const handleClickPreview = async () => {
    if (isContainExtensions(props.attachment.fileName, [PDF_EXTENSION])) {
      window.open(props.attachment.fileUrl);
      return;
    }
    if (isContainExtensions(props.attachment.fileName, [...IMAGE_EXTENSIONS, ...TIF_EXTENSION])) {
      setIsPreviewOpen(true);
      setPreviewImage({ name: props.attachment.fileName, url: props.attachment.fileUrl });
      return;
    }
    if (isContainExtensions(props.attachment.fileName, PREVIEWABLE_EXCEL_EXTENSION)) {
      dispatch(mainOperations.updateIsLoading(true));
      try {
        const result = await attachmentRepository.excelFilePreview(
          props.projectId,
          props.attachment.id as ProjectAttachmentId,
        );
        window.open(result.url, "_blank");
      } catch (error) {
        handleReduxError(error, dispatch, "ファイルのプレビューに失敗しました");
      } finally {
        dispatch(mainOperations.updateIsLoading(false));
      }
    }
  };

  const handleClickCopyLink = async () => {
    try {
      await navigator.clipboard.writeText(props.attachment.fileUrl);
      setCopyTip("コピーしました");
    } catch (error: any) {
      alert((error && error.message) || "コピーに失敗しました");
    }
  };
  const handleOpenInfoTitleTip = () => {
    setCopyTip("ファイルのリンクをコピーします");
  };

  const handleCheck = async (
    e: React.ChangeEvent<HTMLInputElement>,
    attachmentId: ProjectAttachmentId,
  ) => {
    if (!props.projectId) return;
    dispatch(mainOperations.updateIsLoading(true));
    try {
      await attachmentRepository.update(props.projectId, attachmentId as ProjectAttachmentId, {
        [e.target.name]: e.target.checked,
      });
      mutate(API_PATHS.getProjectAttachmentsByAttachmentType(props.projectId, props.selectedHubId));
      dispatch(mainOperations.updateSuccessMessage("共有設定を変更しました"));
    } catch (error) {
      handleReduxError(error, dispatch, "共有設定の変更に失敗しました");
    } finally {
      dispatch(mainOperations.updateIsLoading(false));
    }
  };

  const handleDelete = async (attachmentId: number) => {
    if (!deleteConfirmRef.current || !props.projectId) return;
    const confirm = await deleteConfirmRef.current.confirm();
    if (!confirm) return;

    dispatch(mainOperations.updateIsLoading(true));
    try {
      await attachmentRepository.destroy(props.projectId, attachmentId as ProjectAttachmentId);
      mutate(API_PATHS.getProjectAttachmentsByAttachmentType(props.projectId, props.selectedHubId));
      dispatch(mainOperations.updateSuccessMessage("ファイルを削除しました"));
    } catch (error) {
      handleReduxError(error, dispatch, "ファイルの削除に失敗しました");
    } finally {
      dispatch(mainOperations.updateIsLoading(false));
    }
  };

  return (
    <>
      <AsyncConfirmDialog ref={deleteConfirmRef} />
      <ImagePreviewModal
        isOpen={isPreviewOpen}
        onClose={() => setIsPreviewOpen(false)}
        image={previewImage}
      />
      <Box
        sx={{
          border: `1px solid ${theme.palette.grayScale[300]}`,
          borderRadius: "5px",
          backgroundColor: theme.palette.customPrimary[50],
          p: "16px 16px 8px 16px",
          width: "230px",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <FileIcon fileName={props.attachment.fileName} sx={{ width: "1.8rem" }} />
          <div>
            {isContainExtensions(props.attachment.fileName, [
              PDF_EXTENSION,
              ...IMAGE_EXTENSIONS,
              ...TIF_EXTENSION,
              ...PREVIEWABLE_EXCEL_EXTENSION,
            ]) && (
              <Tooltip title="プレビュー" placement="top" arrow>
                <IconButton size="small" onClick={handleClickPreview}>
                  <Visibility />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title={copyTip} onOpen={handleOpenInfoTitleTip} placement="top" arrow>
              <IconButton size="small" onClick={handleClickCopyLink}>
                <Link />
              </IconButton>
            </Tooltip>
            <Tooltip title="ダウンロード" placement="top" arrow>
              <IconButton
                size="small"
                onClick={() => fileDownload(props.attachment.fileUrl, props.attachment.fileName)}
              >
                <FileDownloadOutlined />
              </IconButton>
            </Tooltip>
            {mainState.hub.id === props.selectedHubId && (
              <Tooltip title="削除" placement="top" arrow>
                <IconButton size="small" onClick={() => handleDelete(props.attachment.id)}>
                  <DeleteIcon color={theme.palette.grayScale[700]} size={22} />
                </IconButton>
              </Tooltip>
            )}
          </div>
        </Box>
        <Box sx={{ wordWrap: "break-word" }}>
          <Typography
            sx={{
              fontWeight: "500",
              wordBreak: "break-all",
              overflow: "hidden",
              display: "-webkit-box",
              WebkitLineClamp: 2,
              WebkitBoxOrient: "vertical",
              height: "54px",
            }}
          >
            {props.attachment.fileName}
          </Typography>
          <Typography fontSize="14px" sx={{ mt: "8px", color: theme.palette.grayScale[700] }}>
            {formatDateUtil(new Date(props.attachment.createdAt), "yyyy年MM月dd日")}
          </Typography>
        </Box>
        {mainState.hub.id === props.selectedHubId && (
          <>
            <Divider sx={{ my: "8px" }} />
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              <Typography component="span" fontSize="14px">
                自社の全員に共有
              </Typography>
              <Switch
                name="isSharedInOwnHub"
                onChange={(e) => handleCheck(e, props.attachment.id)}
                checked={props.attachment.isSharedInOwnHub}
              />
            </div>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              <Typography component="span" fontSize="14px">
                すべてのHubに共有
              </Typography>
              <Switch
                name="isSharedWithOtherHubs"
                onChange={(e) => handleCheck(e, props.attachment.id)}
                checked={props.attachment.isSharedWithOtherHubs}
              />
            </div>
          </>
        )}
      </Box>
    </>
  );
};

import { theme } from "extensions/theme";

export const styles = (activeStep?: number, index?: number) => ({
  leftContainer: {
    width: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    borderRight: `1px solid ${theme.palette.grayScale[200]}`,
  },
  stepContainer: {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor:
      index === activeStep ? theme.palette.primary.main : theme.palette.grayScale[300],
  },
  button: {
    mt: "40px",
    height: "32px",
    width: "100px",
  },
});

import { useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIos from "@mui/icons-material/ArrowForwardIos";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { Box, IconButton, Popover, Stack, Typography, styled } from "@mui/material";
import { LoadingOverlay } from "components/loadingOverlay";
import ja from "date-fns/locale/ja";
import { theme } from "extensions/theme";
import useSWR from "swr";
import { formatDateUtil } from "utils/formatDateUtil";
import { GenericChart } from "./generic_chart";
import { TotalChart } from "./total";
import { salesAggregationByClientRepository } from "../api/by_client.repository";
import { salesAggregationByProjectManagerRepository } from "../api/by_project_manager.repository";
import { salesAggregationTotalRepository } from "../api/total.repository";
import { SalesAggregation as SalesAggregationType } from "../types/common.dto";
registerLocale("ja", { ...ja, options: { ...ja.options, weekStartsOn: 1 } });

const STypography = styled(Typography)({
  fontSize: "32px",
  fontWeight: "bold",
});

export const SalesAggregation = () => {
  const [date, setDate] = useState<Date>(new Date());
  const yearMonth = formatDateUtil(date.toString(), "yyyy-MM");
  const [isDatePickerOpen, setIsDatePickerOpen] = useState<boolean>(false);

  const { data: salesAggregationTotal, isLoading: isLoadingTotal } = useSWR(
    `/api/v1/sales_aggregations/total?target_month=${yearMonth}`,
    () => salesAggregationTotalRepository.index(date),
    {
      revalidateOnFocus: false,
    },
  );
  const { data: salesAggregationByClients, isLoading: isLoadingByClient } = useSWR(
    `/api/v1/sales_aggregations/by_client?target_month=${yearMonth}`,
    () => salesAggregationByClientRepository.index(date),
    {
      revalidateOnFocus: false,
    },
  );
  const { data: salesAggregationByProjectManagers, isLoading: isLoadingByProjectManagers } = useSWR(
    `/api/v1/sales_aggregations/by_project_manager?target_month=${yearMonth}`,
    () => salesAggregationByProjectManagerRepository.index(date),
    {
      revalidateOnFocus: false,
    },
  );

  const currentMonthTotalData: SalesAggregationType | undefined =
    salesAggregationTotal && salesAggregationTotal[salesAggregationTotal.length - 1];

  const decrementDate = () => {
    setDate(new Date(date.setMonth(date.getMonth() - 1)));
  };

  const incrementDate = () => {
    setDate(new Date(date.setMonth(date.getMonth() + 1)));
  };

  return (
    <>
      <LoadingOverlay
        isLoading={isLoadingTotal || isLoadingByClient || isLoadingByProjectManagers}
      />

      <div>
        <Popover
          open={isDatePickerOpen}
          onClose={() => setIsDatePickerOpen(false)}
          sx={{ position: "fixed", left: "450px", top: "80px" }}
        >
          <DatePicker
            locale="ja"
            selected={date}
            onChange={(date: Date | null) => {
              if (date) {
                setDate(date);
                setIsDatePickerOpen(false);
              }
            }}
            inline
            showMonthYearPicker
          />
        </Popover>

        <div style={{ display: "flex", alignItems: "center", marginBottom: "24px" }}>
          <IconButton onClick={decrementDate}>
            <ArrowBackIosNewIcon fontSize="small" />
          </IconButton>
          <Box sx={{ m: "0 8px", cursor: "default" }}>
            <Typography sx={{ fontSize: "20px", fontWeight: "bold", userSelect: "none" }}>
              {formatDateUtil(date, "yyyy年MM月")}
            </Typography>
          </Box>
          <IconButton onClick={incrementDate}>
            <ArrowForwardIos fontSize="small" />
          </IconButton>

          <IconButton onClick={() => setIsDatePickerOpen(!isDatePickerOpen)}>
            <CalendarTodayIcon color="primary" />
          </IconButton>
        </div>
      </div>

      <div style={{ display: "flex", gap: "40px", marginBottom: "40px" }}>
        <div>
          <Typography fontWeight="bold">合計売上</Typography>
          <STypography sx={{ color: theme.palette.primary.main }}>
            ¥{currentMonthTotalData ? currentMonthTotalData.salesAmount.toLocaleString() : 0}
          </STypography>
        </div>
        <div>
          <Typography fontWeight="bold">合計粗利</Typography>
          <STypography sx={{ color: theme.palette.secondary.main }}>
            ¥{currentMonthTotalData ? currentMonthTotalData.grossProfit.toLocaleString() : 0}
          </STypography>
        </div>
        <div>
          <Typography fontWeight="bold">粗利率</Typography>
          <STypography sx={{ color: theme.palette.mustard[500] }}>
            {currentMonthTotalData ? currentMonthTotalData.grossProfitRate.toLocaleString() : 0}%
          </STypography>
        </div>
      </div>

      <Stack spacing="40px">
        <TotalChart data={salesAggregationTotal || []} />
        <GenericChart data={salesAggregationByClients || []} entityName="顧客" />
        <GenericChart data={salesAggregationByProjectManagers || []} entityName="現場責任者" />
      </Stack>
    </>
  );
};

import {
  InvitableAccountForSchedule,
  ScheduleOverlappingType,
} from "data-access/repositories/account/account.dto";
import { theme } from "extensions/theme";

export const styles = {
  optionGroupTitle: {
    px: 2,
    py: 1,
    fontWeight: "bold",
    fontSize: 12,
    color: theme.palette.grayScale[700],
  },
};

export const getOptionStyles = ({
  selected,
}: {
  option: InvitableAccountForSchedule;
  selected: boolean;
  isSiteManager: boolean;
}) => {
  return {
    alignItems: "center",
    py: 1,
    px: 2,
    backgroundColor: selected ? "action.selected" : "inherit",
  };
};

export const getSelectedMemberStyles = ({
  isSiteManager,
  isOverlapped,
  overlapType,
}: {
  isSiteManager: boolean;
  isOverlapped: boolean;
  overlapType: ScheduleOverlappingType;
}) => {
  const getStyles = () => {
    if (isOverlapped) {
      switch (overlapType.value) {
        case "forbidden":
          return {
            backgroundColor: theme.palette.red[100],
            borderColor: theme.palette.red[500],
          };
        case "warning":
          return {
            backgroundColor: theme.palette.mustard[100],
            borderColor: theme.palette.mustard[500],
          };
      }
    }

    return {
      backgroundColor: isSiteManager ? theme.palette.customPrimary[50] : "inherit",
      borderColor: theme.palette.grayScale[900],
    };
  };

  const { backgroundColor, borderColor } = getStyles();

  return {
    display: "flex",
    justifyContent: "space-between",
    gap: "4px",
    height: "25px",
    p: "2px 6px",
    borderRadius: "4px",
    border: `1px solid ${borderColor}`,
    backgroundColor,
  };
};

import { forwardRef, useState } from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { attachmentTypeRepository } from "data-access/repositories/attachment_type/attachment_type.repository";
import { AttachmentTypeFormModal } from "features/hub-settings/components/project-setting/attachment-type/form-modal";
import { AttachmentTypeTable } from "features/hub-settings/components/project-setting/attachment-type/table";
import useSWR from "swr";

const AttachmentType = forwardRef<HTMLDivElement>((_, ref) => {
  const [isModalOpen, setModalOpen] = useState<boolean>(false);

  const { data } = useSWR("/api/v1/attachment_types", () => attachmentTypeRepository.index());

  if (!data) {
    return <CircularProgress />;
  }

  return (
    <>
      <AttachmentTypeFormModal isOpen={isModalOpen} open={setModalOpen} />
      <Box
        ref={ref}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: "16px",
        }}
      >
        <Typography sx={{ fontSize: "14px", fontWeight: "700" }}>添付ファイルタイプ</Typography>
        <Button
          variant="contained"
          startIcon={<AddCircleIcon />}
          onClick={() => setModalOpen(true)}
          sx={{ minWidth: "12rem" }}
        >
          添付ファイルタイプを新規作成
        </Button>
      </Box>

      <Box>
        <AttachmentTypeTable />
      </Box>
    </>
  );
});

AttachmentType.displayName = "AttachmentType";

export default AttachmentType;

import { ApiClient } from "data-access/ApiClient";
import { AccountReference } from "data-access/repositories/account/account.dto";
import { MeUpdateRequest } from "features/user-settings/types/profile-setting/me.dto";
import { camelToSnakeCase, convertKeysToCamelCase } from "utils/convertObjectKeyCase";

export const meRepository = {
  index(): Promise<AccountReference> {
    return ApiClient.get("/api/v1/me").then((res) => convertKeysToCamelCase(res.data));
  },
  update(body: MeUpdateRequest): Promise<AccountReference> {
    const formData = convertFormData(body);

    return ApiClient.put("/api/v1/me", formData, {
      headers: { "Content-Type": "multipart/form-data" },
    }).then((res) => convertKeysToCamelCase(res.data));
  },
};

const convertFormData = (body: MeUpdateRequest): FormData => {
  const formData = new FormData();

  Object.entries(body).forEach(([key, value]) => {
    if (value === undefined) return;

    const snakeKey = camelToSnakeCase(key);
    const formValue = value instanceof File ? value : value.toString();

    formData.append(snakeKey, formValue);
  });

  return formData;
};

import React, { useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Close, Edit, OpenInNew } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Divider,
  Grid2,
  Link,
  List,
  ListItem,
  ListItemText,
  Popover,
  Typography,
} from "@mui/material";
import { Avatar } from "components/avatar";
import { LinkButton } from "components/button/link-button";
import { MoreOptionsButton } from "components/button/more-options-button";
import { DeleteIcon } from "components/icon/delete-icon";
import { LinkIcon } from "components/icon/link-icon";
import { GroupLabel } from "components/label/group-label";
import { StatusTypeLabel } from "components/label/status-type-label";
import { LoadingOverlay } from "components/loadingOverlay";
import { CustomFormLabel } from "components/molecules/custom-form-label";
import { ShareSettingDialog } from "components/molecules/share-setting-dialog";
import { PhotoPreviewModal } from "components/organisms/photo-preview-modal";
import { PhotoResponse } from "data-access/repositories/project/photo/photo.dto";
import { primaryCategoryRepository as projectPrimaryCategoryRepository } from "data-access/repositories/project/primary_category/primary_category.repository";
import { ProjectId } from "data-access/repositories/project/project.dto";
import { WorkReportPhotoId } from "data-access/repositories/work_report/photo/photo.dto";
import { workReportPhotoRepository } from "data-access/repositories/work_report/photo/photo.repository";
import { primaryCategoryRepository } from "data-access/repositories/work_report/primary_category/primary_category.repository";
import {
  WorkReport,
  WorkReportId,
  WorkReportState,
  WorkReportUpdateRequest,
} from "data-access/repositories/work_report/work_report.dto";
import { workReportRepository } from "data-access/repositories/work_report/work_report.repository";
import { theme } from "extensions/theme";
import { usePhotoPreview } from "hooks/usePhotoPreview";
import { usePhotoSelect } from "hooks/usePhotoSelect";
import FileDownload from "images/file_download_icon.svg";
import { useAppDispatch } from "store/hooks";
import { mainOperations } from "store/main/operations";
import useSWR, { mutate, useSWRConfig } from "swr";
import { downloadImagesV2 } from "utils/downloadImages";
import { handleReduxError } from "utils/errorHandler";
import { DATE_TIME_SLASH_FORMAT, formatDateUtil } from "utils/formatDateUtil";
import { openURLInNewTab } from "utils/openURLInNewTab";
import { timeRange } from "utils/timeRange";
import { usersName } from "utils/usersName";
import { WorkReportEditModal } from "../edit-modal";
import { styles } from "./styles";

const CustomTextForm = (props: { label: string; isTitle?: boolean }) => {
  const labelColor = props.isTitle ? theme.palette.grayScale[700] : theme.palette.grayScale[900];
  return (
    <CustomFormLabel
      small
      labelName={props.label}
      labelColor={labelColor}
      labelWeight={props.isTitle ? "bold" : 400}
      labelSize="14px"
    />
  );
};

interface Props {
  projectId?: ProjectId;
  show: { isOpen: boolean; id: WorkReportId | undefined };
  onClose: () => void;
  onDelete?: (id: WorkReportId) => void;
  fetchIndexKey?: string;
  isPartner?: boolean;
}

export const WorkReportDetailModal = (props: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { mutate: IndexMutate } = useSWRConfig();
  const isPartner = props.isPartner || false;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isOpenShareModal, setIsOpenShareModal] = useState<boolean>(false);

  const handleClose = () => {
    props.onClose();
    // 作業日報画面から開いている場合、作業日報画面に戻る（予定や案件からの動線もあるため）
    if (!isPartner && location.pathname.includes("work-reports")) {
      navigate("/work-reports");
    }
  };

  const handleClickDelete = (id: WorkReportId) => {
    if (props.onDelete) {
      props.onDelete(id);
    }
  };

  const handleUpdateSuccess = async (e: string) => {
    dispatch(mainOperations.updateSuccessMessage(e));
    try {
      if (props.fetchIndexKey) {
        await IndexMutate(props.fetchIndexKey);
      }
    } catch (error) {
      dispatch(mainOperations.updateErrorMessage(error.response.data.message));
    }
  };

  const fetchKey = `/api/v1/work_reports/${props.show.id}`;
  const fetchPhotosKey = `/api/v1/work_reports/${props.show.id}/photos`;
  const fetchPrimaryCategoriesKey = `/api/v1/work_reports/${props.show.id}/primary_categories`;
  const photoCountIndexKey = `${fetchPhotosKey}/count_info`;

  const { data: workReport, isLoading } = useSWR(props.show.id ? fetchKey : null, () =>
    props.show.id ? workReportRepository.show(props.show.id as WorkReportId) : null,
  );

  // 自社hubとそれ以外でprimaryCategoriesの取得方法を変える
  const { data: primaryCategories } = useSWR(
    props.show.id
      ? isPartner && props.projectId
        ? `/api/v1/projects/${props.projectId}/primary_categories`
        : fetchPrimaryCategoriesKey
      : null,
    () => {
      if (!props.show.id) return null;
      if (isPartner && props.projectId) {
        return projectPrimaryCategoryRepository.index(props.projectId);
      } else {
        return primaryCategoryRepository.index(props.show.id as WorkReportId);
      }
    },
    {
      revalidateOnFocus: false,
    },
  );

  const { data: photos, mutate: photosMutate } = useSWR(
    props.show.id && !isPartner ? fetchPhotosKey : null,
    () =>
      props.show.id && !isPartner
        ? workReportPhotoRepository.index(props.show.id as WorkReportId)
        : null,
  );

  // 自社hub以外の場合はworkReportから直接写真を取得
  const displayPhotos = isPartner ? workReport?.headerPhotos || [] : photos || [];

  const { photoPreviewState, setPhotoPreviewState, handleClickPhotoPreview } =
    usePhotoPreview(displayPhotos);

  const {
    selectedPhotoIds,
    setSelectedPhotoIds,
    handleSelectPhoto,
    handleSelectPhotoAll,
    handleDeselectPhotoAll,
  } = usePhotoSelect(displayPhotos);

  const handleUpdate = async (body: WorkReportState): Promise<WorkReport> => {
    try {
      const res: WorkReport = await workReportRepository.update(
        props.show.id as WorkReportId,
        body as WorkReportUpdateRequest,
      );
      handleUpdateSuccess("作業日報を更新しました");
      return res;
    } catch (error) {
      dispatch(mainOperations.updateErrorMessage(error.response.data.message));
      throw error;
    }
  };

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    openURLInNewTab(`projects/${workReport?.projectId}`);
  };

  const initialDefaultState = useMemo(() => {
    return {
      siteName: workReport?.siteName,
      userIds: workReport?.users.map((user) => user.userId),
      users: workReport?.users,
      siteManagerId: workReport?.siteManager ? workReport.siteManager.userId : 0,
      startTime: workReport?.startTime,
      endTime: workReport?.endTime,
      content: workReport?.content,
      workStatusType: workReport?.workStatusType,
      createdDate: workReport?.createdDate,
      createdBy: workReport?.createdBy,
    } as WorkReportState;
  }, [workReport]);

  /**
   * 写真の共有リンクタップ時の処理.
   * 自社hub以外では使用しない
   * @param photo 写真オブジェクト(画像ではないので注意).
   */
  const handleClickImageLinkButton = async (photo: PhotoResponse) => {
    if (isPartner) return;

    const photoId = photo.id;
    const isSharable = !photo.isSharable;
    dispatch(mainOperations.updateIsLoading(true));
    try {
      await workReportPhotoRepository.update(
        photo.workReportAttributes?.id as WorkReportId,
        photoId as WorkReportPhotoId,
        isSharable,
      );
      mutate(photoCountIndexKey);
      photosMutate();
    } catch (error) {
      handleReduxError(error, dispatch, "写真共有の切り替えに失敗しました");
    } finally {
      dispatch(mainOperations.updateIsLoading(false));
    }
  };

  const handleDownload = async () => {
    if (!displayPhotos || displayPhotos.length === 0) return;
    dispatch(mainOperations.updateSuccessMessage("写真をダウンロードしています"));
    try {
      const selectedPhotos = displayPhotos.filter((photo) => selectedPhotoIds.has(photo.id));
      await downloadImagesV2(selectedPhotos, `作業日報写真_(${workReport?.siteName})`);
      dispatch(mainOperations.updateSuccessMessage("写真をダウンロードしました"));
      setSelectedPhotoIds(new Set());
    } catch (error) {
      handleReduxError(error, dispatch, "写真のダウンロードに失敗しました");
    }
  };

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [selectedPhoto, setSelectedPhoto] = useState<PhotoResponse | null>(null);

  const handleMoreClick = (e: React.MouseEvent<HTMLButtonElement>, photo: PhotoResponse) => {
    setAnchorEl(e.currentTarget);
    setSelectedPhoto(photo);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setSelectedPhoto(null);
  };

  if (!props.show.id) {
    return null;
  }

  return (
    <>
      <PhotoPreviewModal state={photoPreviewState} setState={setPhotoPreviewState} />
      <Dialog
        open={props.show.isOpen}
        onClose={handleClose}
        fullWidth
        PaperProps={{
          style: {
            maxWidth: "600px",
          },
        }}
      >
        <DialogContent sx={{ p: 0 }}>
          <Box sx={{ p: "0 16px 16px 16px" }}>
            {isLoading || !workReport ? (
              <LoadingOverlay isLoading={true} />
            ) : (
              <>
                {!isPartner && (
                  <>
                    <WorkReportEditModal
                      id={props.show.id as WorkReportId}
                      isOpen={isOpen}
                      setIsOpen={setIsOpen}
                      handleSubmit={handleUpdate}
                      defaultValue={initialDefaultState}
                      projectId={workReport.projectId}
                      defaultPhotos={photos || []}
                      fetchIndexKeys={[
                        fetchKey,
                        fetchPhotosKey,
                        props.fetchIndexKey || "",
                        fetchPrimaryCategoriesKey,
                      ]}
                    />
                    {workReport && (
                      <ShareSettingDialog
                        isOpen={isOpenShareModal}
                        onClose={() => setIsOpenShareModal(false)}
                        id={workReport.id}
                        resourceType="work_reports"
                        photoUrlKey={workReport.urlKey || ""}
                      />
                    )}
                  </>
                )}

                <Box sx={{ ...styles.header }}>
                  <Box sx={{ display: "flex", justifyContent: "space-between", mb: "8px" }}>
                    <Button
                      startIcon={<Close />}
                      onClick={handleClose}
                      sx={{ color: theme.palette.grayScale[700] }}
                    >
                      とじる
                    </Button>
                    {!isPartner && (
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Button
                          onClick={() => setIsOpenShareModal(true)}
                          startIcon={
                            <LinkIcon
                              size={16}
                              color={
                                workReport?.urlKey
                                  ? theme.palette.blue[500]
                                  : theme.palette.primary.main
                              }
                            />
                          }
                        >
                          <Typography
                            sx={{
                              color: workReport?.urlKey
                                ? theme.palette.blue[500]
                                : theme.palette.primary.main,
                              mr: "8px",
                              fontSize: "14px",
                              fontWeight: "500",
                            }}
                          >
                            {workReport?.urlKey ? "リンクで共有中" : "リンクで共有"}
                          </Typography>
                        </Button>
                        <Button
                          startIcon={<Edit />}
                          color="primary"
                          onClick={() => setIsOpen(true)}
                          sx={{ mr: "8px" }}
                        >
                          内容を編集
                        </Button>
                        <Button
                          startIcon={<DeleteIcon size={20} />}
                          color="error"
                          onClick={() => handleClickDelete(props.show.id as WorkReportId)}
                        >
                          削除
                        </Button>
                      </Box>
                    )}
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center", p: "2px", width: "100%" }}>
                    <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
                      <Typography sx={{ ...styles.siteName }}>{workReport?.siteName}</Typography>
                      <StatusTypeLabel
                        statusType={workReport?.workStatusTypeI18n || ""}
                        sx={{ width: "80px", mr: "12px", p: "6px 20px" }}
                      />
                    </Box>
                  </Box>

                  <Divider sx={{ mt: "16px" }} />
                </Box>

                {/* 共通部分 - 詳細情報 */}
                <Grid2 container spacing={2} sx={{ mt: "20px" }}>
                  <Grid2 size={3}>
                    <CustomTextForm isTitle label="案件" />
                  </Grid2>
                  <Grid2 size={9}>
                    <Link href="#" onClick={handleClick} underline="none" sx={{ ...styles.link }}>
                      {workReport?.projectName}
                      <OpenInNew fontSize="small" color="primary" />
                    </Link>
                  </Grid2>
                  <Grid2 size={3}>
                    <CustomTextForm isTitle label="作業日時" />
                  </Grid2>
                  <Grid2 size={9}>
                    <CustomTextForm
                      label={timeRange(
                        "yyyy年MM月dd日 HH:mm",
                        workReport?.startTime.toString(),
                        workReport?.endTime.toString(),
                      )}
                    />
                  </Grid2>
                  <Grid2 size={3}>
                    <CustomTextForm isTitle label="現場責任者" />
                  </Grid2>
                  <Grid2 size={9}>
                    <CustomTextForm
                      label={workReport?.siteManager ? workReport?.siteManager.name : "未設定"}
                    />
                  </Grid2>
                  <Grid2 size={3}>
                    <CustomTextForm isTitle label="参加者" />
                  </Grid2>
                  <Grid2 size={9}>
                    <CustomTextForm
                      label={usersName(
                        workReport?.users.map((user) => ({
                          userId: user.userId,
                          name: user.name,
                        })) || [],
                      )}
                    />
                  </Grid2>
                  <Grid2 size={3}>
                    <CustomTextForm isTitle label="作業内容・連絡事項" />
                  </Grid2>
                  <Grid2 size={9}>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        color: theme.palette.grayScale[900],
                        wordBreak: "break-all",
                      }}
                    >
                      {workReport?.content.split("\n").map((line, index) => (
                        <React.Fragment key={index}>
                          {line}
                          <br />
                        </React.Fragment>
                      ))}
                    </Typography>
                  </Grid2>
                </Grid2>

                {/* 写真セクション */}
                <Box sx={{ ...styles.box }}>
                  <Box sx={{ display: "flex", alignItems: "center", mb: "12px" }}>
                    <CustomTextForm isTitle label="現場写真" />
                    {displayPhotos && displayPhotos.length > 0 && (
                      <Box
                        sx={{
                          display: "flex",
                          gap: "16px",
                          ml: "20px",
                        }}
                      >
                        <Typography
                          onClick={handleSelectPhotoAll}
                          color="primary"
                          sx={{ ...styles.selectAllButton }}
                        >
                          すべて選択
                        </Typography>
                        <Typography
                          onClick={handleDeselectPhotoAll}
                          sx={
                            isPartner ? { ...styles.deselectButton } : { ...styles.selectAllButton }
                          }
                        >
                          選択を解除
                        </Typography>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Button
                            variant="contained"
                            onClick={handleDownload}
                            disabled={selectedPhotoIds.size === 0}
                            sx={{
                              fontSize: "12px",
                              height: "30px",
                              borderRadius: "20px",
                              fontWeight: "500",
                            }}
                            startIcon={
                              <Box
                                sx={{ color: theme.palette.grayScale[0] }}
                                component="img"
                                src={FileDownload}
                              />
                            }
                          >
                            {selectedPhotoIds.size}件をダウンロード
                          </Button>
                        </Box>
                      </Box>
                    )}
                  </Box>

                  <Box>
                    {primaryCategories &&
                      primaryCategories.length > 0 &&
                      primaryCategories.map((primaryCategory, index) => (
                        <React.Fragment key={primaryCategory.id || index}>
                          <Typography fontSize={20} fontWeight="bold" sx={{ mb: "12px" }}>
                            {primaryCategory.name}
                          </Typography>
                          {primaryCategory.secondaryCategories.map((secondaryCategory, index) => (
                            <React.Fragment key={secondaryCategory.id || index}>
                              <Typography fontSize={16} fontWeight="bold" sx={{ mb: "14px" }}>
                                {secondaryCategory.name}
                              </Typography>
                              {secondaryCategory.tertiaryCategories.map((tertiaryCategory) => {
                                // 自社hubとそれ以外で写真のフィルタリング方法を変える
                                const filteredPhotos = isPartner
                                  ? displayPhotos?.filter((photo) => {
                                      return photo.tertiaryCategory.name === tertiaryCategory.name;
                                    })
                                  : displayPhotos?.filter((photo) => {
                                      return photo.tertiaryCategory.id === tertiaryCategory.id;
                                    });

                                if (!filteredPhotos || filteredPhotos.length === 0) return null;

                                return (
                                  <React.Fragment key={tertiaryCategory.id}>
                                    <GroupLabel
                                      name={tertiaryCategory.name}
                                      colorNumber={tertiaryCategory.colorNumber}
                                    />
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexWrap: "wrap",
                                        gap: "24px",
                                        mt: "12px",
                                        mb: "28px",
                                      }}
                                    >
                                      {filteredPhotos && filteredPhotos.length > 0 ? (
                                        filteredPhotos.map((photo) => (
                                          <Box
                                            key={photo.id}
                                            sx={{
                                              position: "relative",
                                              overflow: "hidden",
                                              borderRadius: "4px",
                                              border: `1px solid ${theme.palette.grayScale[300]}`,
                                            }}
                                          >
                                            <Box
                                              sx={{
                                                borderBottom: `1px solid ${theme.palette.grayScale[300]}`,
                                                height: "137px",
                                                cursor: "pointer",
                                              }}
                                            >
                                              <img
                                                alt={`photo-${photo.id}`}
                                                src={photo.thumbnailUrl}
                                                loading="lazy"
                                                style={{
                                                  width: "215px",
                                                  height: "137px",
                                                  objectFit: "cover",
                                                }}
                                                onClick={() => handleClickPhotoPreview(photo)}
                                              />
                                              {/* MUIのCheckBoxではレンダリングが間に合わないためinputを使用 */}
                                              <input
                                                type="checkbox"
                                                checked={selectedPhotoIds.has(photo.id)}
                                                onChange={() => handleSelectPhoto(photo.id)}
                                                style={{ ...styles.checkbox }}
                                              />
                                              {!isPartner && (
                                                <LinkButton
                                                  sx={{
                                                    top: "8px",
                                                    right: "40px",
                                                    position: "absolute",
                                                  }}
                                                  isEnabled={photo.isSharable}
                                                  onClick={() => handleClickImageLinkButton(photo)}
                                                />
                                              )}
                                              <MoreOptionsButton
                                                onClick={(e) => handleMoreClick(e, photo)}
                                              />
                                            </Box>
                                          </Box>
                                        ))
                                      ) : (
                                        <Typography>写真はありません</Typography>
                                      )}
                                    </Box>
                                  </React.Fragment>
                                );
                              })}
                            </React.Fragment>
                          ))}
                        </React.Fragment>
                      ))}
                  </Box>
                </Box>

                <Grid2 container spacing={2} sx={{ mt: "20px" }}>
                  <Grid2 size={3}>
                    <CustomTextForm isTitle label="作成日" />
                  </Grid2>
                  <Grid2 size={9}>
                    <CustomTextForm
                      label={formatDateUtil(
                        new Date(workReport?.createdDate as string),
                        "yyyy年MM月dd日",
                      )}
                    />
                  </Grid2>
                  <Grid2 size={3}>
                    <CustomTextForm isTitle label="作成者" />
                  </Grid2>
                  <Grid2 size={9}>
                    <CustomTextForm label={workReport?.createdBy.name || ""} />
                  </Grid2>
                </Grid2>
              </>
            )}
          </Box>

          <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleCloseMenu}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <List sx={{ p: 0 }}>
              <ListItem sx={{ flexDirection: "column", alignItems: "flex-start", padding: "12px" }}>
                <ListItemText primary={<Typography fontSize={12}>アップロード</Typography>} />
                {selectedPhoto && (
                  <>
                    <ListItemText
                      primary={
                        <Typography fontSize={14} fontWeight="bold">
                          {formatDateUtil(selectedPhoto.createdAt, DATE_TIME_SLASH_FORMAT)}
                        </Typography>
                      }
                    />
                    <ListItemText
                      primary={
                        <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
                          <Typography fontSize={12}>{selectedPhoto.createdBy.name}</Typography>
                          <Avatar
                            url={selectedPhoto.createdBy.hub.logoUrl}
                            name={selectedPhoto.createdBy.hub.name}
                            bgColor={selectedPhoto.createdBy.hub.logoBackgroundColorNumber}
                            size="small"
                          />
                        </Box>
                      }
                    />
                  </>
                )}
              </ListItem>
            </List>
          </Popover>
        </DialogContent>
      </Dialog>
    </>
  );
};

import { UserId } from "data-access/repositories/account/account.dto";
import { CsvImportStatus } from "features/hub-settings/types/csv-import/csv_import_project.dto";

// 個社対応の機能のために判別する定数(Company.id)を追加
export const MEIHOU_ID = "3fa98a58-541d-466b-9c4f-3c4a93cd1c14";

export const DEFAULT_ID = 0;

export const ALL_USERS_VALUE = 0 as UserId;

export const IMAGE_EXTENSIONS = [".jpg", ".jpeg", ".png", ".gif"];

export const PDF_EXTENSION = ".pdf";

export const TIF_EXTENSION = [".tif", ".tiff"];

export const PREVIEWABLE_EXCEL_EXTENSION = [".xlsx"];

export const DEFAULT_ROWS_PER_PAGE = 100;

export const ACTIVITY_PER_PAGE = 20;

export const DOW = {
  SATURDAY: 6,
  SUNDAY: 0,
};

export const DaysOfWeek = ["日", "月", "火", "水", "木", "金", "土"];

export const HOUR = {
  START_OF_ALL_DAY: 8,
  END_OF_ALL_DAY: 17,
};

export const ClientServiceReportItemsCount = 3;
export const ActivityInitialDisplayCount = 3;
export const ProjectSidebarWorkReportInitialDisplayNumber = 3;
export const ProjectBillingInitialDisplayNumber = 3;
export const BillingSidebarDepositHistoryInitialDisplayNumber = 3;

export const GroupColorPatterns = [
  "CCD2DF",
  "DBE2F2",
  "CEE6E4",
  "D8E9D0",
  "EDE0CC",
  "E5D8D1",
  "F5DAD6",
  "E0E0E0",
];

export const BaseColorPatterns = [
  "001C5F",
  "426DBF",
  "0B817A",
  "3C8F14",
  "61760D",
  "C7A021",
  "A76400",
  "7B3E19",
  "CE4432",
  "CF347F",
  "911094",
  "482FAB",
  "666666",
  "222222",
];

export const CSV_IMPORT_IN_PROGRESS_STATUS: CsvImportStatus[] = [
  "finalized_validation",
  "finalizing_validation",
  "importing",
  "pending",
  "prepared_import",
  "prepared_validation",
  "preparing_import",
  "preparing_validation",
  "validated",
  "validating",
];

export const CSV_IMPORT_IN_CANCELED_STATUS: CsvImportStatus[] = [
  "canceled_by_system",
  "canceled_by_user",
];

import React from "react";
import { Box, Typography } from "@mui/material";
import { Activity } from "data-access/repositories/activity/activity.dto";
import { theme } from "extensions/theme";
import { formatDateUtil } from "utils/formatDateUtil";

interface Props {
  sx?: object;
  activity: Activity;
}

export const ActivityItem = (props: Props) => {
  return (
    <Box sx={{ mb: "1rem" }}>
      <Box>
        <Typography component="span">{props.activity.createdBy.name}</Typography>
        {props.activity.activityType !== "commented" && (
          <Typography component="span" sx={{ ml: "1rem" }}>
            {props.activity.activityTypeI18n}
          </Typography>
        )}
        <Typography
          component="span"
          sx={{ fontSize: "0.75rem", color: theme.palette.grayScale[700], ml: "1rem" }}
        >
          {formatDateUtil(props.activity.createdAt, "yyyy年MM月dd日 HH:mm")}
        </Typography>
      </Box>
      {props.activity.activityType === "edited" && (
        <Box>
          {props.activity.activityDetails.map((detail) => {
            return (
              <Box key={detail.id}>
                {detail.changeType === "add" && (
                  <>
                    <Typography component="span" sx={{ fontSize: "0.75rem", mt: "0.5rem" }}>
                      {detail.childRecordName}(追加)：
                    </Typography>
                    <Typography component="span" sx={{ fontSize: "0.75rem", mt: "0.5rem" }}>
                      {detail.attachmentName}
                    </Typography>
                  </>
                )}
                {detail.changeType === "remove" && (
                  <>
                    <Typography component="span" sx={{ fontSize: "0.75rem", mt: "0.5rem" }}>
                      {detail.childRecordName}(削除)：
                    </Typography>
                    <Typography component="span" sx={{ fontSize: "0.75rem", mt: "0.5rem" }}>
                      {detail.attachmentName}
                    </Typography>
                  </>
                )}
                {detail.changeType === "edit" && (
                  <>
                    <Typography component="span" sx={{ fontSize: "0.75rem", mt: "0.5rem" }}>
                      {detail.childRecordName ? detail.childRecordName : detail.fieldName}
                      {detail.childRecordName &&
                        `(${detail.attachmentName}) ${detail.fieldName}`}：
                    </Typography>
                    <Typography
                      component="span"
                      sx={{ fontSize: "0.75rem", mt: "0.5rem", wordWrap: "break-word" }}
                    >
                      {detail.oldValue ? detail.oldValue : "空白"}→
                      {detail.newValue ? detail.newValue : "空白"}
                    </Typography>
                  </>
                )}
              </Box>
            );
          })}
        </Box>
      )}
      {props.activity.activityType === "commented" && (
        <Box
          sx={{
            border: `1px solid ${theme.palette.grayScale[700]}`,
            backgroundColor: theme.palette.grayScale[0],
            p: "1rem",
            mt: "0.5rem",
          }}
        >
          {props.activity.comment.split("\n").map((str: string, index: number) => {
            return (
              <React.Fragment key={index}>
                {str}
                <br />
              </React.Fragment>
            );
          })}
        </Box>
      )}
    </Box>
  );
};

import { Box, SelectChangeEvent } from "@mui/material";
import { accountNoticeSettingRepository } from "features/user-settings/api/notice-setting/notice_setting.repository";
import { AccountNoticeSetting } from "features/user-settings/types/notice-setting/notice_setting.dto";
import { useAppDispatch } from "store/hooks";
import { mainOperations } from "store/main/operations";
import { mutate } from "swr";
import { API_PATHS } from "utils/apiPaths";
import { handleReduxError } from "utils/errorHandler";
import { NotificationItem } from "../common/NotificationItem";

interface Props {
  data?: AccountNoticeSetting;
}

export const ProjectManagement = (props: Props) => {
  const dispatch = useAppDispatch();

  if (!props.data) return null;

  const handleChangeSubscriberType = async (
    event: SelectChangeEvent<string>,
    field: keyof AccountNoticeSetting,
  ) => {
    dispatch(mainOperations.updateIsLoading(true));
    try {
      await accountNoticeSettingRepository.update({
        [field]: event.target.value,
      });
      mutate(API_PATHS.getAccountNoticeSetting());
      dispatch(mainOperations.updateSuccessMessage("更新しました"));
    } catch (error) {
      handleReduxError(error, dispatch, "更新に失敗しました");
    } finally {
      dispatch(mainOperations.updateIsLoading(false));
    }
  };

  const handleChangeEnabled = async (field: keyof AccountNoticeSetting, checked: boolean) => {
    dispatch(mainOperations.updateIsLoading(true));
    try {
      await accountNoticeSettingRepository.update({
        [field]: checked,
      });
      mutate(API_PATHS.getAccountNoticeSetting());
      dispatch(mainOperations.updateSuccessMessage("更新しました"));
    } catch (error) {
      handleReduxError(error, dispatch, "更新に失敗しました");
    } finally {
      dispatch(mainOperations.updateIsLoading(false));
    }
  };

  return (
    <Box sx={{ mb: 4 }}>
      <NotificationItem
        title="他Hubから案件に招待されたとき"
        subscriberTypeValue={props.data.projectInvitedByPartnerHubSubscriberType}
        subscriberTypeField="projectInvitedByPartnerHubSubscriberType"
        enabledValue={props.data.projectInvitedByPartnerHubEnabled}
        enabledField="projectInvitedByPartnerHubEnabled"
        onChangeSubscriberType={handleChangeSubscriberType}
        onChangeEnabled={handleChangeEnabled}
      />

      <NotificationItem
        title="他Hubの案件から退出させられたとき"
        subscriberTypeValue={props.data.projectRemovedByPartnerHubSubscriberType}
        subscriberTypeField="projectRemovedByPartnerHubSubscriberType"
        enabledValue={props.data.projectRemovedByPartnerHubEnabled}
        enabledField="projectRemovedByPartnerHubEnabled"
        onChangeSubscriberType={handleChangeSubscriberType}
        onChangeEnabled={handleChangeEnabled}
      />

      <NotificationItem
        title="主催者が案件を削除したとき"
        subscriberTypeValue={props.data.projectDeletedByOrganizerSubscriberType}
        subscriberTypeField="projectDeletedByOrganizerSubscriberType"
        enabledValue={props.data.projectDeletedByOrganizerEnabled}
        enabledField="projectDeletedByOrganizerEnabled"
        onChangeSubscriberType={handleChangeSubscriberType}
        onChangeEnabled={handleChangeEnabled}
      />
    </Box>
  );
};

import { ApiClient } from "data-access/ApiClient";
import {
  ConnectCollection,
  ConnectData,
  ConnectId,
  ConnectUpdateRequest,
} from "features/hub-settings/types/connect/connect.dto";
import { convertKeysToCamelCase, convertKeysToSnakeCase } from "utils/convertObjectKeyCase";

export const connectRepository = {
  index(): Promise<Array<ConnectCollection[]>> {
    return ApiClient.get("/api/v1/connects").then((res) => {
      return convertKeysToCamelCase(res.data);
    });
  },
  show(id: ConnectId): Promise<ConnectData> {
    return ApiClient.get(`/api/v1/connects/${id}`).then((res) => {
      return convertKeysToCamelCase(res.data);
    });
  },
  update(id: ConnectId, body: ConnectUpdateRequest): Promise<void> {
    const config = { headers: { "content-type": "application/json" } };
    return ApiClient.put(`/api/v1/connects/${id}`, convertKeysToSnakeCase(body), config).then(
      (res) => {
        return res.data;
      },
    );
  },
  delete(id: ConnectId): Promise<void> {
    return ApiClient.delete(`/api/v1/connects/${id}`).then((res) => {
      return res.data;
    });
  },
};

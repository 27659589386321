import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { SelectChangeEvent, Box } from "@mui/material";
import { GridRowParams } from "@mui/x-data-grid";
import { CustomDataGrid } from "components/molecules/custom-data-grid";
import { ClientIndexResponse } from "data-access/repositories/client/client.dto";
import { initialPageInfo } from "data-access/repositories/page_info/page_info.dto";

interface Props {
  data: ClientIndexResponse | undefined;
  page: number;
  setPage: (e: number) => void;
  isLoading: boolean;
}

export const ClientTable = (props: Props) => {
  const navigate = useNavigate();

  // ページャーselect選択
  const handleSelect = (event: SelectChangeEvent<unknown>) => {
    props.setPage(Number(event.target.value));
  };

  // ページャー選択(next, prev)
  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    props.setPage(newPage + 1);
  };

  const handleRowClick = (params: GridRowParams) => {
    navigate(`/clients/${params.row.id}`);
  };

  const columns = useMemo(() => {
    const headers = [];
    headers.push({
      field: "name",
      headerName: "顧客名",
      minWidth: 300,
    });
    headers.push({
      field: "billingPersonSection",
      headerName: "部署",
      minWidth: 300,
    });
    headers.push({
      field: "code",
      headerName: "会計ソフトコード",
      minWidth: 150,
    });
    headers.push({
      field: "billingClosingDateTypeI18n",
      headerName: "請求締日",
      minWidth: 100,
    });
    headers.push({
      field: "depositMonthTypeI18n",
      headerName: "入金日",
      minWidth: 150,
    });
    headers.push({
      field: "depositMethodTypeI18n",
      headerName: "入金方法",
      minWidth: 100,
    });
    return headers;
  }, []);

  const rows = useMemo(() => {
    return (
      props.data?.data.map((v) => ({
        ...v,
        code: v.code || "-",
        billingPersonSection: v.billingPersonSection || "-",
      })) || []
    );
  }, [props.data]);

  return (
    <>
      <Box sx={{ mt: 2 }}>
        <CustomDataGrid
          columns={columns}
          rows={rows}
          loading={props.isLoading}
          pageInfo={props.data?.pagination || initialPageInfo}
          currentPage={props.page}
          handleSelect={handleSelect}
          handleChangePage={handleChangePage}
          handleRowClick={handleRowClick}
          tableHeight="80vh"
        />
      </Box>
    </>
  );
};

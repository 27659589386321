import AppleIcon from "@mui/icons-material/Apple";
import { Box, Button, Typography } from "@mui/material";
import { SignUpResponse } from "data-access/repositories/sign_up/sign_up.dto";
import GooglePlay from "images/google_play.svg";
import SignIn2 from "images/sign_in_2.svg";
import { styles } from "./styles";

interface Props {
  data: SignUpResponse;
  flow: "newHub" | "existingHub";
}
export const Created = (props: Props) => {
  const appStoreUrl = "https://apps.apple.com/jp/app/%E7%8F%BE%E5%A0%B4hub/id1632779340";
  const playStoreUrl =
    "https://play.google.com/store/apps/details?id=jp.artsn.artisan_app&hl=ja&gl=US&pli=1";

  const handleAppStoreClick = () => {
    window.open(appStoreUrl, "_blank", "noopener,noreferrer");
  };

  const handleGooglePlayClick = () => {
    window.open(playStoreUrl, "_blank", "noopener,noreferrer");
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", my: "32px" }}>
      <Typography fontSize="24px" fontWeight="bold" mb="32px">
        {props.flow === "newHub" ? "Hubが作成されました!" : "アカウントが作成されました!"}
      </Typography>
      <img src={SignIn2} alt="logo" style={{ width: 250, marginBottom: "40px" }} />
      <Typography sx={{ fontWeight: "bold", mb: "32px" }}>
        おめでとうございます。
        {props.flow === "newHub" ? (
          <>{props.data.data.hubName}のHubが作成されました。</>
        ) : (
          <>{props.data.data.name}さんのアカウントが作成されました。</>
        )}
        <br />
        早速、スマホアプリまたはPCからアクセスしてみましょう。
      </Typography>
      <Box sx={{ display: "flex", justifyContent: "space-evenly", width: "100%" }}>
        <Button variant="contained" sx={styles.downloadButton} onClick={handleAppStoreClick}>
          <AppleIcon fontSize="large" />
          <Box>
            <Typography sx={{ textTransform: "capitalize" }}>App Store</Typography>
            <Typography fontSize="11px">からダウンロード</Typography>
          </Box>
        </Button>
        <Button variant="contained" sx={styles.downloadButton} onClick={handleGooglePlayClick}>
          <img src={GooglePlay} alt="googlePlay" style={{ width: 40 }} />
          <Box>
            <Typography sx={{ textTransform: "capitalize" }}>Google Play</Typography>
            <Typography fontSize="11px">で手に入れよう</Typography>
          </Box>
        </Button>
      </Box>
    </Box>
  );
};

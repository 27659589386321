import { Brand } from "../brand";

export type ProjectStatusTypeId = Brand<number, "ProjectStatusTypeId">;

export interface ProjectStatusType {
  id: ProjectStatusTypeId;
  name: string;
  colorNumber: string;
  reservedType: "no_reserved" | "completed" | "order_failed";
}

export interface ProjectStatusTypeRequest {
  name?: string;
  colorNumber?: string;
  displayOrderPosition?: number;
}

export const initialProjectStatusType: ProjectStatusType = {
  id: 0 as ProjectStatusTypeId,
  name: "",
  colorNumber: "",
  reservedType: "no_reserved",
};

import { ApiClient } from "data-access/ApiClient";
import { UserId } from "data-access/repositories/account/account.dto";
import { convertKeysToCamelCase } from "utils/convertObjectKeyCase";
import { ProjectId } from "../project.dto";
import { ProjectParticipant } from "./participant.dto";

export const projectParticipantRepository = {
  index(projectId: ProjectId): Promise<ProjectParticipant[]> {
    return ApiClient.get(`/api/v1/projects/${projectId}/participants`).then((res) => {
      return convertKeysToCamelCase(res.data);
    });
  },
  invitation(projectId: ProjectId, body: { user_ids: UserId[] }): Promise<void> {
    const config = { headers: { "content-type": "application/json" } };
    return ApiClient.post(`/api/v1/projects/${projectId}/participants`, body, config).then(
      (res) => res.data,
    );
  },
  delete(projectId: ProjectId, userId: UserId): Promise<void> {
    return ApiClient.delete(`/api/v1/projects/${projectId}/participants/${userId}`).then(
      (res) => res.data,
    );
  },
};

import { useState } from "react";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import { Box, Button } from "@mui/material";
import { ConfirmPasswordReset } from "./password-reset/confirm-password-reset";
import { ResetPassword } from "./password-reset/reset-password";
import { SendResetCode } from "./password-reset/send-reset-code";
import { SignInForm } from "./sign-in-form";
import { styles } from "./styles";
import { WalkThrough } from "../sign-up/pc/walk-through";

export type PageState = "signInForm" | "sendResetCode" | "resetPassword" | "confirmPasswordReset";

export default function SignIn() {
  const [page, setPage] = useState<PageState>("signInForm");
  const [sendEmail, setSendEmail] = useState<string>("");

  return (
    <Box sx={{ display: "flex", width: "100vw", height: "100vh" }}>
      <WalkThrough />

      <div style={{ width: "50%", position: "relative" }}>
        {page !== "signInForm" && page !== "confirmPasswordReset" && (
          <Button onClick={() => setPage("signInForm")} sx={{ m: "8px 0 0 8px" }}>
            <ArrowCircleLeftOutlinedIcon fontSize="small" sx={{ mr: "4px" }} />
            ログインにもどる
          </Button>
        )}
        <Box sx={{ ...styles.rightContainer }}>
          {page === "signInForm" && <SignInForm setPage={setPage} />}
          {page === "sendResetCode" && (
            <SendResetCode setPage={setPage} setSendEmail={setSendEmail} />
          )}
          {page === "resetPassword" && <ResetPassword setPage={setPage} sendEmail={sendEmail} />}
          {page === "confirmPasswordReset" && <ConfirmPasswordReset setPage={setPage} />}
        </Box>
      </div>
    </Box>
  );
}

import React, { ChangeEvent, useEffect, useMemo, useState } from "react";
import { Range } from "react-date-range";
import { SelectChangeEvent, Typography } from "@mui/material";
import {
  GridColDef,
  GridColumnVisibilityModel,
  GridRenderCellParams,
  GridSortModel,
  GridValidRowModel,
} from "@mui/x-data-grid";
import { MultilineTooltip } from "components/atoms/multiline-tooltip";
import { OpenInNewButton } from "components/button/open-in-new-button";
import { TagLabel } from "components/label/tag-label";
import { CustomDataGridPro } from "components/molecules/custom-data-grid-pro";
import { Participant } from "data-access/repositories/project/participant/participant.dto";
import {
  ScheduleId,
  ScheduleList,
  ScheduleSearchRequest,
  ScheduleSearchResponse,
} from "data-access/repositories/schedule/schedule.dto";
import { scheduleRepository } from "data-access/repositories/schedule/schedule.repository";
import { useAppDispatch } from "store/hooks";
import { mainOperations } from "store/main/operations";
import { downloadDataGridRowsCsv } from "utils/downloadDataGridRowsCsv";
import { handleReduxError } from "utils/errorHandler";
import { DATE_TIME_SLASH_FORMAT, formatDateUtil } from "utils/formatDateUtil";
import { openURLInNewTab } from "utils/openURLInNewTab";

interface Props {
  scheduleDateRange: Range;
  currentPage: number;
  keyword: string;
  onUpdateScheduleDateRange: (range: Range) => void;
  setCurrentPage: (page: number) => void;
  setSortItem: (sortItem: { field: string; sort: string } | undefined) => void;
  setRowsPerPage: (rowsPerPage: number) => void;
  isLoading: boolean;
  schedulesObj?: ScheduleSearchResponse;
  searchQuery: ScheduleSearchRequest;
}

export const ScheduleSearchResultTable = (props: Props) => {
  const dispatch = useAppDispatch();

  const rowsPerPageLocalStorageKey = "scheduleSearchResultTableRowsPerPage";
  const sortStorageKeyName = "scheduleSearchResultTableSort";
  const orderStorageKeyName = "scheduleSearchResultTableColumnOrder";
  const visibilityStorageKeyName = "scheduleSearchResultTableColumnVisibility";
  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({});

  useEffect(() => {
    const localStorageSortItem = localStorage.getItem(sortStorageKeyName);
    if (localStorageSortItem) {
      props.setSortItem(JSON.parse(localStorageSortItem));
    }
  }, []);

  const defaultSchedulesColumns = useMemo(() => {
    const headers = [];
    headers.push({
      field: "schedule_type",
      headerName: "予定タイプ",
      minWidth: 150,
      sortingOrder: ["desc", "asc", null],
      renderCell: (params: GridRenderCellParams<ScheduleList>) => (
        <TagLabel
          tagName={params.row.scheduleType.name}
          colorNumber={params.row.scheduleType.colorNumber}
        />
      ),
      csvRender: (_: number, row: GridValidRowModel) => {
        return row.scheduleType.name;
      },
    });
    headers.push({
      field: "name",
      headerName: "予定名",
      minWidth: 240,
      sortingOrder: ["desc", "asc", null],
      renderCell: (params: GridRenderCellParams<ScheduleList>) => (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            lineHeight: "inherit",
            gap: "8px",
          }}
        >
          <OpenInNewButton onClick={() => handleClick(params.row.id)} />
          <Typography>{params.value}</Typography>
        </div>
      ),
      csvRender: (_: number, row: GridValidRowModel) => {
        return row.name;
      },
    });
    headers.push({
      field: "start_time",
      headerName: "開始日時",
      minWidth: 150,
      sortingOrder: ["desc", "asc", null],
      renderCell: (params: GridRenderCellParams<ScheduleList>) => (
        <Typography sx={{ fontSize: "0.875rem" }}>
          {params.row.startTime ? formatDateUtil(params.row.startTime, DATE_TIME_SLASH_FORMAT) : ""}
        </Typography>
      ),
      csvRender: (_: number, row: ScheduleList) => {
        return row.startTime ? formatDateUtil(row.startTime, DATE_TIME_SLASH_FORMAT) : "";
      },
    });
    headers.push({
      field: "end_time",
      headerName: "終了日時",
      minWidth: 150,
      sortingOrder: ["desc", "asc", null],
      renderCell: (params: GridRenderCellParams<ScheduleList>) => (
        <Typography sx={{ fontSize: "0.875rem" }}>
          {params.row.endTime ? formatDateUtil(params.row.endTime, DATE_TIME_SLASH_FORMAT) : ""}
        </Typography>
      ),
      csvRender: (_: number, row: ScheduleList) => {
        return row.endTime ? formatDateUtil(row.endTime, DATE_TIME_SLASH_FORMAT) : "";
      },
    });
    headers.push({
      field: "users",
      renderCell: (params: GridRenderCellParams<ScheduleList>) =>
        params.row.participants
          .map((participant: Participant) => participant.accountName)
          .join("、"),
      headerName: "参加者",
      minWidth: 200,
      sortable: false,
      csvRender: (_: number, row: GridValidRowModel) => {
        return row.participants
          .map((participant: Participant) => participant.accountName)
          .join("、")
          .toString();
      },
    });
    headers.push({
      field: "project_code",
      renderCell: (params: GridRenderCellParams<ScheduleList>) => params.row.project?.code,
      headerName: "案件番号",
      minWidth: 100,
      sortingOrder: ["desc", "asc", null],
      csvRender: (_: number, row: GridValidRowModel) => {
        return row.project?.code;
      },
    });
    headers.push({
      field: "project_name",
      renderCell: (params: GridRenderCellParams<ScheduleList>) => params.row.project?.name,
      headerName: "案件名",
      minWidth: 200,
      sortingOrder: ["desc", "asc", null],
      csvRender: (_: number, row: GridValidRowModel) => {
        return row.project?.name;
      },
    });
    headers.push({
      field: "note",
      headerName: "作業内容・メモ",
      minWidth: 370,
      sortable: false,
      renderCell: (params: GridRenderCellParams<ScheduleList>) => (
        <MultilineTooltip value={params.row.note} />
      ),
      csvRender: (_: number, row: GridValidRowModel) => {
        // テキストフィールド内にダブルクォートが含まれる場合改行されてしまうため
        // それを2つのダブルクォートに置き換えることでテキストの一部であることを示す
        return `"${row.note.replace(/"/g, '""')}"`;
      },
    });
    return headers;
  }, []);

  const columns = useMemo(() => {
    const localStorageItem = localStorage.getItem(orderStorageKeyName);
    const visibilityLocalStorageItem = localStorage.getItem(visibilityStorageKeyName);
    visibilityLocalStorageItem && setColumnVisibilityModel(JSON.parse(visibilityLocalStorageItem));
    let resultColumns;

    if (localStorageItem) {
      const savedColumns = JSON.parse(localStorageItem);
      resultColumns = savedColumns.map((column: { field: string }) => {
        const defaultColumn = defaultSchedulesColumns.find(
          (defaultColumn) => defaultColumn.field === column.field,
        );
        return {
          ...defaultColumn,
          hide: columnVisibilityModel[column.field] === false,
        };
      });
    } else {
      resultColumns = defaultSchedulesColumns;
    }
    return resultColumns;
  }, [defaultSchedulesColumns]);

  const handleSelectPage = (event: SelectChangeEvent<unknown>) => {
    props.setCurrentPage(event.target.value as number);
  };

  const handleChangePage = (_: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    props.setCurrentPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ): void => {
    props.setRowsPerPage(Number(event.target.value));
    localStorage.setItem(rowsPerPageLocalStorageKey, event.target.value);
  };

  const setColumnOrder = (allColumns: GridColDef[]) => {
    const columns = allColumns.map((column) => {
      return { field: column.field };
    });
    localStorage.setItem(orderStorageKeyName, JSON.stringify(columns));
  };

  const handleColumnOrderChange = (allColumns: GridColDef[]) => {
    setColumnOrder(allColumns);
  };
  const handleColumnVisibilityModelChange = (model: GridColumnVisibilityModel) => {
    setColumnVisibilityModel(model);
    localStorage.setItem(visibilityStorageKeyName, JSON.stringify(model));
  };

  const handleClick = (scheduleId: ScheduleId) => {
    openURLInNewTab(`schedules/${scheduleId}`);
  };

  const handleSortModelChange = (sortItems: GridSortModel) => {
    if (sortItems.length > 0) {
      localStorage.setItem(sortStorageKeyName, JSON.stringify(sortItems[0]));
      props.setSortItem(sortItems[0] as { field: string; sort: string });
    } else if (sortItems.length === 0) {
      localStorage.removeItem(sortStorageKeyName);
      props.setSortItem(undefined);
    }
  };

  const handleDownloadCSV = async () => {
    if (!props.schedulesObj) return;
    const forCsvColumns = () => {
      const csvColumns = [...columns];
      csvColumns.push({
        field: "is_confirmed",
        renderCell: (params: GridRenderCellParams<ScheduleList>) => params.row.project?.name,
        headerName: "ステータス",
        minWidth: 200,
        sortingOrder: ["desc", "asc", null],
        csvRender: (_: number, row: GridValidRowModel) => {
          return row.isConfirmed ? "確定" : "未確定";
        },
      });
      return csvColumns;
    };
    try {
      dispatch(mainOperations.updateIsLoading(true));
      const res = await scheduleRepository.index(props.searchQuery);
      downloadDataGridRowsCsv("scheduleSearchResult", res, forCsvColumns(), columnVisibilityModel);
    } catch (error) {
      handleReduxError(error, dispatch, "CSVのダウンロードに失敗しました");
    } finally {
      dispatch(mainOperations.updateIsLoading(false));
    }
  };

  const initialState = useMemo(() => {
    const defaultState = { sorting: {} };
    const localStorageItem = localStorage.getItem(sortStorageKeyName);
    if (localStorageItem !== null) {
      const sortItem: { field: string; sort: string } = JSON.parse(localStorageItem);
      defaultState.sorting = {
        sortModel: [{ field: sortItem.field, sort: sortItem.sort }],
      };
    }
    return defaultState;
  }, []);

  return (
    <CustomDataGridPro
      type="scheduleSearchResult"
      columns={columns}
      rows={props.schedulesObj?.data || []}
      loading={props.isLoading}
      pageInfo={props.schedulesObj?.pagination || { page: 0, pages: 1, items: 0, count: 0 }}
      currentPage={props.currentPage}
      tableHeight="75vh"
      initialState={initialState}
      handleDownloadButton={handleDownloadCSV}
      handleSortModelChange={handleSortModelChange}
      handleSelectPage={handleSelectPage}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      handleColumnOrderChange={handleColumnOrderChange}
      handleColumnVisibilityModelChange={handleColumnVisibilityModelChange}
      columnVisibilityModel={columnVisibilityModel}
    />
  );
};

import React, { ElementRef, useEffect, useMemo, useRef, useState } from "react";
import { LinkItEmail, LinkItUrl /**linkIt, UrlComponent*/ } from "react-linkify-it";
import { useNavigate, useParams } from "react-router-dom";
import { Close, Edit, Check } from "@mui/icons-material";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import LockIcon from "@mui/icons-material/LockOutlined";
import Person2OutlinedIcon from "@mui/icons-material/Person2Outlined";
import RepeatIcon from "@mui/icons-material/Repeat";
import ScheduleIcon from "@mui/icons-material/Schedule";
import { Dialog, Box, Divider, IconButton, Typography, Stack, Button } from "@mui/material";
import { Avatar } from "components/avatar";
import { TitleBadge } from "components/badge/title-badge";
import { CustomDivider } from "components/divider";
import { ConfirmSchedule } from "components/icon/confirm-schedule-icon";
import { DeleteIcon } from "components/icon/delete-icon";
import { LinkIcon } from "components/icon/link-icon";
import { UnconfirmSchedule } from "components/icon/unconfirm-schedule-icon";
import { TagLabel } from "components/label/tag-label";
import { LoadingOverlay } from "components/loadingOverlay";
import { CustomFormLabel } from "components/molecules/custom-form-label";
import { ProjectBlock } from "components/molecules/project-block";
import { ScheduleParticipantHubsBlock } from "components/molecules/schedule-participant-hubs-block";
import { WorkReportBlock } from "components/molecules/work-report-block";
import { AsyncConfirmDialog } from "components/templates/async-confirm-dialog";
import { ClientId } from "data-access/repositories/client/client.dto";
import { clientRepository } from "data-access/repositories/client/client.repository";
import { ParticipationType } from "data-access/repositories/project/participant/participant.dto";
import { ProjectCreateRequest, ProjectId } from "data-access/repositories/project/project.dto";
import { projectRepository } from "data-access/repositories/project/project.repository";
import {
  ProjectInfo,
  Schedule,
  ScheduleId,
  ScheduleList,
  initialScheduleResponse,
} from "data-access/repositories/schedule/schedule.dto";
import { scheduleRepository } from "data-access/repositories/schedule/schedule.repository";
import { sourceScheduleRepository } from "data-access/repositories/schedule/source_schedule/source_schedule.repository";
import { scheduleWorkReportRepository } from "data-access/repositories/schedule/work_report/work_report.repository";
import {
  WorkReport,
  WorkReportCreateRequest,
  WorkReportId,
  WorkReportState,
} from "data-access/repositories/work_report/work_report.dto";
import { workReportRepository } from "data-access/repositories/work_report/work_report.repository";
import { theme } from "extensions/theme";
import { SourceProjectModal } from "features/project/components/sidebar/source-project/modal";
import { useScrollToPosition } from "hooks/useScrollToPosition";
import { calendarOperations } from "store/calendar/operations";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { mainOperations } from "store/main/operations";
import { selectMain } from "store/main/slice";
import { scheduleDeleteConfirmDialogOperations } from "store/schedule-delete-confirm-dialog/operations";
import useSWR, { mutate } from "swr";
import Cookies from "universal-cookie";
import { DEFAULT_ID } from "utils/constant";
import { handleReduxError } from "utils/errorHandler";
import { openURLInNewTab } from "utils/openURLInNewTab";
import { timeRange } from "utils/timeRange";
import { styles } from "./styles";
import { formatDateUtil } from "../../../utils/formatDateUtil";
import { LinkProjectFormModal } from "../schedule-link-project-modal";
import { WorkReportCreateModal } from "../work-report/create-modal";
import { WorkReportDetailModal } from "../work-report/detail-modal";

interface Props {
  setIsLoading: (isLoading: boolean) => void;
  onDelete: (isOpen: boolean, id: ScheduleId) => void;
  noDatedEvents: ScheduleList[];
  fetchIndexKey: string;
  fetchNoDatedEventKey: string;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  setIsEdit: (isOpen: boolean) => void;
  setRecurrenceDialog: (
    isOpen: boolean,
    id: ScheduleId,
    type: "delete" | "update",
    onUpdateFunc?: (withFutures: boolean) => Promise<void>,
  ) => void;
}

export const ScheduleDetailModal = (props: Props) => {
  const dispatch = useAppDispatch();
  const mainState = useAppSelector(selectMain);
  const navigate = useNavigate();
  const { id } = useParams();
  const scheduleId: ScheduleId = Number(id) as ScheduleId;
  const { scrollToSavedPosition } = useScrollToPosition("calendar");
  const [state, setState] = useState<Schedule>(initialScheduleResponse);
  const [isOpenWorkReportModal, setIsOpenWorkReportModal] = useState<boolean>(false);
  const [isOpenLinkProjectModal, setIsOpenLinkProjectModal] = useState<boolean>(false);
  const [participationStatus, setParticipationStatus] = useState<ParticipationType | undefined>(
    undefined,
  );

  const {
    data,
    mutate: showMutate,
    isLoading,
  } = useSWR(props.isOpen && id ? `/api/v1/schedules/${scheduleId}` : null, () =>
    scheduleRepository.show(scheduleId),
  );

  const { data: sourceSchedule } = useSWR(
    props.isOpen && id ? `/api/v1/schedules/${scheduleId}/source_schedule` : null,
    () => sourceScheduleRepository.index(scheduleId),
    { revalidateOnFocus: false },
  );

  const {
    data: scheduleWorkReports,
    mutate: workReportMutate,
    isLoading: isScheduleWorkReportLoading,
  } = useSWR(props.isOpen && id ? `/api/v1/schedules/${scheduleId}/work_reports` : null, () =>
    scheduleWorkReportRepository.index(scheduleId),
  );

  const [workReportShow, setWorkReportShow] = useState<{
    isOpen: boolean;
    id: WorkReportId | undefined;
    ownerType?: "own" | "partner" | undefined;
  }>({
    isOpen: false,
    id: undefined,
    ownerType: undefined,
  });

  useEffect(() => {
    if (!props.isOpen || !data) return;
    setState(data);
    setParticipationStatus(data.participationType);
  }, [data]);

  const ownHub = state.participantHubs.find((hub) => hub.id === mainState.hub.id);
  const invitedHubs = state.participantHubs.filter((hub) => hub.isOwnHubInvitation);

  const isUserSelectedAsMember = () => {
    if (!ownHub) return false;
    return ownHub.participants.some((participant) => participant.userId === mainState.me.userId);
  };

  const handleClickDelete = () => {
    if (state.recurrenceRule) {
      props.setRecurrenceDialog(true, state.id as ScheduleId, "delete");
      return;
    }

    const cookies = new Cookies();
    const isDeleteConfirmNotShowAgain = cookies.get("isScheduleDeleteConfirmNotShowAgain");
    if (isDeleteConfirmNotShowAgain) {
      dispatch(calendarOperations.destroy(state.id as ScheduleId, !!state.recurrenceRule));
      mutate(
        props.fetchNoDatedEventKey,
        props.noDatedEvents?.filter((event) => event.id !== Number(state.id)),
        false,
      );
      props.setIsOpen(false);
      navigate("/schedules");
    } else {
      props.onDelete(true, state.id);
      dispatch(
        scheduleDeleteConfirmDialogOperations.setObject({
          id: state.id,
          name: state.name,
        }),
      );
      dispatch(scheduleDeleteConfirmDialogOperations.open());
    }
  };

  const handleClose = () => {
    navigate("/schedules");
    setState(initialScheduleResponse);
    props.setIsOpen(false);
  };

  const handleClickEdit = () => {
    navigate(`/schedules/${state.id}`);
    props.setIsEdit(true);
  };

  const handleIsConfirm = async (value: boolean) => {
    const updateSchedule = async (withFutures: boolean) => {
      props.setIsLoading(true);
      try {
        await scheduleRepository.update(scheduleId, withFutures, { isConfirmed: value });
        mutate(props.fetchIndexKey);
        dispatch(
          mainOperations.updateSuccessMessage(
            value ? "予定を確定しました" : "予定を未確定にしました",
          ),
        );
      } catch (error) {
        handleReduxError(error, dispatch, "予定を変更できませんでした");
      } finally {
        props.setIsLoading(false);
        scrollToSavedPosition();
        handleClose();
      }
    };

    if (state.recurrenceRule) {
      props.setRecurrenceDialog(true, state.id as ScheduleId, "update", updateSchedule);
    } else {
      await updateSchedule(false);
    }
  };

  const handleProjectUnlink = async () => {
    const updateSchedule = async (withFutures: boolean) => {
      try {
        await scheduleRepository.update(scheduleId, withFutures, { projectId: 0 });
        showMutate();
        dispatch(mainOperations.updateSuccessMessage("案件を解除しました"));
      } catch (error) {
        handleReduxError(error, dispatch, "案件の解除に失敗しました");
      }
    };

    if (state.recurrenceRule) {
      props.setRecurrenceDialog(true, state.id as ScheduleId, "update", updateSchedule);
    } else {
      await updateSchedule(false);
    }
  };

  const switchProjectLinkModal = (isOpen: boolean) => {
    setIsOpenLinkProjectModal(isOpen);
  };

  const handleClickProject = () => {
    if (!state.project) return;
    openURLInNewTab(`projects/${state.project.id}`);
  };

  const [isOpenSourceProjectModal, setIsOpenSourceProjectModal] = useState<boolean>(false);
  const handleClickSourceProject = () => {
    setIsOpenSourceProjectModal(true);
  };

  /**
   * ここで案件紐付けする
   * @param projectId あるときは既存のプロジェクト紐付け
   * @param projectInfo projectIdがないときはprojectInfoで新規作成
   */
  const handleLinkProject = async (projectId: ProjectId, projectInfo: ProjectInfo) => {
    const updateSchedule = async (withFutures: boolean) => {
      try {
        let linkProjectId: ProjectId;
        if (projectId != DEFAULT_ID) {
          linkProjectId = projectId;
        } else {
          // 顧客の新規作成があれば行う
          let clientId: ClientId | undefined;
          if (projectInfo.client && projectInfo.client.name) {
            const createClientRes = await clientRepository.create({
              name: projectInfo.client.name,
            });
            clientId = createClientRes.id;
          } else {
            clientId = projectInfo?.clientId;
          }
          // 案件の新規作成
          const projectCreateRes = await projectRepository.create({
            ...projectInfo,
            inquiredDate: new Date().toString(),
            projectStatusTypeId: mainState.projectStatusTypes[0].id,
            clientId: clientId,
          } as ProjectCreateRequest);
          linkProjectId = projectCreateRes.id;
        }
        await scheduleRepository.update(scheduleId, withFutures, {
          projectId: linkProjectId,
        });
        setIsOpenLinkProjectModal(false);
        await showMutate();
        dispatch(mainOperations.updateSuccessMessage("案件を紐づけました"));
      } catch (error) {
        handleReduxError(error, dispatch, "案件の紐づけに失敗しました");
      }
    };
    if (state.recurrenceRule) {
      props.setRecurrenceDialog(true, state.id as ScheduleId, "update", updateSchedule);
    } else {
      await updateSchedule(false);
    }
  };

  const workReportDeleteConfirmRef = useRef<ElementRef<typeof AsyncConfirmDialog>>(null);
  const absentConfirmRef = useRef<ElementRef<typeof AsyncConfirmDialog>>(null);

  const handleDeleteForWorkReport = async (id: WorkReportId) => {
    if (!workReportDeleteConfirmRef.current) return;
    const res = await workReportDeleteConfirmRef.current.confirm();
    if (!res) return;

    try {
      await workReportRepository.destroy(id as WorkReportId);
      workReportMutate();
      setWorkReportShow({ isOpen: false, id: undefined });
      dispatch(mainOperations.updateSuccessMessage("作業日報を削除しました"));
    } catch (error) {
      handleReduxError(error, dispatch, "作業日報の削除に失敗しました");
    }
  };

  const handleClickWorkReport = () => {
    setIsOpenWorkReportModal(true);
  };

  const handleSubmitWorkReport = async (body: WorkReportState): Promise<WorkReport> => {
    try {
      const res: WorkReport = await workReportRepository.create({
        ...body,
        scheduleId: scheduleId,
      } as WorkReportCreateRequest);
      showMutate();
      workReportMutate();
      dispatch(mainOperations.updateSuccessMessage("作業日報を作成しました"));
      return res;
    } catch (error) {
      handleReduxError(error, dispatch, "作業日報の作成に失敗しました");
      throw error;
    }
  };

  const handleParticipationChange = async (status: ParticipationType) => {
    if (status === "absent") {
      if (!absentConfirmRef.current) return;
      const confirmResult = await absentConfirmRef.current.confirm();
      if (!confirmResult) return;
    }

    dispatch(mainOperations.updateIsLoading(true));
    try {
      setParticipationStatus(status);
      await scheduleRepository.updateParticipationType(scheduleId, mainState.me.userId, status);
      dispatch(mainOperations.updateSuccessMessage("参加状態を変更しました"));
      showMutate();
      mutate(props.fetchIndexKey);
    } catch (error) {
      handleReduxError(error, dispatch, "参加状態の変更に失敗しました");
    } finally {
      dispatch(mainOperations.updateIsLoading(false));
    }
  };

  const siteManager = useMemo(() => {
    const ownHub = state.participantHubs.find((hub) => hub.id === mainState.hub.id);
    return ownHub?.participants.find((participant) => participant.isSiteManager);
  }, [state.participantHubs]);

  return (
    <>
      <AsyncConfirmDialog ref={workReportDeleteConfirmRef} />
      <AsyncConfirmDialog
        ref={absentConfirmRef}
        title="不参加の回答をしますか？"
        content="自社のメンバーが全員不参加の場合、自社の予定から削除されます"
        yesButtonText="不参加"
        yesButtonColor="error"
      />
      {sourceSchedule && sourceSchedule.project && (
        <SourceProjectModal
          isOpen={isOpenSourceProjectModal}
          onClose={() => setIsOpenSourceProjectModal(false)}
          sourceProject={sourceSchedule.project}
        />
      )}
      <Dialog open={props.isOpen} onClose={handleClose} maxWidth="lg">
        <LoadingOverlay isLoading={isLoading || isScheduleWorkReportLoading} />
        <Box display="flex">
          {sourceSchedule && (
            <Box
              sx={{
                width: "400px",
                bgcolor: theme.palette.customPrimary[25],
                maxHeight: "80vh",
                overflowY: "auto",
              }}
            >
              <Box
                sx={{ ...styles.headerFixed, bgcolor: theme.palette.customPrimary[25], p: "16px" }}
              >
                <Box sx={styles.sourceScheduleContainer}>
                  <Avatar
                    url={sourceSchedule.hub.logoUrl}
                    bgColor={sourceSchedule.hub.logoBackgroundColorNumber}
                    name={sourceSchedule.hub.name}
                    size="small"
                    sx={{ borderRadius: "50%" }}
                  />
                  <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>
                    {sourceSchedule.hub.name}の情報
                  </Typography>
                </Box>
                <Box sx={styles.flexRowCenter}>
                  <TagLabel
                    tagName={sourceSchedule.scheduleType.name}
                    colorNumber={sourceSchedule.scheduleType.colorNumber}
                  />
                  <Typography sx={styles.scheduleName}>{sourceSchedule.name}</Typography>
                </Box>
                <Divider sx={{ mt: 2 }} />
              </Box>
              <Box sx={{ p: "16px" }}>
                <Box sx={{ mb: "20px" }}>
                  <ScheduleParticipantHubsBlock
                    participantHubs={state.participantHubs}
                    isDefaultExpanded={true}
                  />
                </Box>
                <div style={styles.sectionContainer}>
                  <FormatAlignLeftIcon fontSize="small" />
                  <Typography sx={styles.textWrap}>
                    {sourceSchedule.note ? (
                      sourceSchedule.note.split("\n").map((str: string, index: number) => {
                        return (
                          <React.Fragment key={index}>
                            <LinkItUrl>
                              <LinkItEmail>
                                {str}
                                <br />
                              </LinkItEmail>
                            </LinkItUrl>
                          </React.Fragment>
                        );
                      })
                    ) : (
                      <Typography sx={{ lineHeight: "16px" }}>メモなし</Typography>
                    )}
                  </Typography>
                </div>
                <Box sx={{ mt: 2 }}>
                  <CustomFormLabel labelName="案件" labelColor={theme.palette.grayScale[700]} />
                  {sourceSchedule.project ? (
                    <ProjectBlock
                      project={sourceSchedule.project}
                      onClick={handleClickSourceProject}
                    />
                  ) : (
                    <Typography sx={{ fontSize: "14px" }}>案件はありません</Typography>
                  )}
                </Box>
              </Box>
            </Box>
          )}

          <Divider orientation="vertical" flexItem />

          <Box sx={styles.modalContainer}>
            <Box sx={styles.scrollableContent}>
              {state.isAccessible ? (
                <>
                  <Box sx={styles.headerFixed}>
                    <Box sx={{ display: "flex", alignItems: "center", mb: "20px" }}>
                      <Box sx={styles.buttonContainer}>
                        {!state.isInvited && (
                          <div>
                            <IconButton
                              onClick={handleClickDelete}
                              sx={{ color: theme.palette.red[500] }}
                            >
                              <DeleteIcon />
                            </IconButton>
                            <Typography
                              sx={{ color: theme.palette.red[500], fontSize: "12px" }}
                              align="center"
                            >
                              削除
                            </Typography>
                          </div>
                        )}
                        {state.isConfirmed ? (
                          <div>
                            <IconButton onClick={() => handleIsConfirm(false)}>
                              <UnconfirmSchedule size={24} />
                            </IconButton>
                            <Typography sx={{ fontSize: "12px" }} align="center">
                              未確定
                            </Typography>
                          </div>
                        ) : (
                          <div>
                            <IconButton onClick={() => handleIsConfirm(true)}>
                              <ConfirmSchedule size={24} />
                            </IconButton>
                            <Typography sx={{ fontSize: "12px" }} align="center">
                              確定
                            </Typography>
                          </div>
                        )}
                        <div>
                          <IconButton onClick={handleClickEdit}>
                            <Edit />
                          </IconButton>
                          <Typography sx={{ fontSize: "12px" }} align="center">
                            編集
                          </Typography>
                        </div>
                        <div>
                          <IconButton onClick={handleClose}>
                            <Close />
                          </IconButton>
                          <Typography sx={{ fontSize: "12px" }} align="center">
                            閉じる
                          </Typography>
                        </div>
                      </Box>
                    </Box>

                    <Box sx={styles.flexRowCenter}>
                      <TagLabel
                        tagName={state.scheduleType.name}
                        colorNumber={state.scheduleType.colorNumber}
                      />
                      <Typography sx={styles.scheduleName}>{state.name}</Typography>
                    </Box>
                    <Divider sx={{ mt: 2, mb: 3 }} />
                  </Box>

                  <div style={styles.sectionWithIcon}>
                    <ScheduleIcon fontSize="small" />
                    <Stack spacing="8px">
                      <Typography>
                        {timeRange(
                          "yyyy年MM月dd日 HH:mm",
                          state.startTime ?? undefined,
                          state.endTime ?? undefined,
                        )}
                      </Typography>
                      {state.recurrenceRule && (
                        <div style={styles.iconText}>
                          <RepeatIcon fontSize="small" />
                          <Typography sx={{ fontSize: "14px" }}>
                            {state.recurrenceRule.label}
                          </Typography>
                        </div>
                      )}
                    </Stack>
                  </div>

                  <div style={styles.sectionContainer}>
                    <Person2OutlinedIcon fontSize="small" />
                    <Box flex={1}>
                      <Stack spacing={2} mb="16px">
                        {siteManager && (
                          <div style={styles.flexRowCenter}>
                            <Avatar
                              url={siteManager.accountProfileImageUrl}
                              bgColor={siteManager.accountProfileImageBackgroundColorNumber}
                              name={siteManager.accountName}
                              participationType={siteManager.participationType}
                              showStatus={true}
                              size="small"
                              sx={{ borderRadius: "50%" }}
                            />
                            <Typography fontSize="14px">{siteManager.accountName}</Typography>
                            <TitleBadge title="当日現場責任者" />
                          </div>
                        )}
                        <Box sx={styles.flexWrap}>
                          {state.participantHubs.length > 0 ? (
                            <Box sx={styles.flexColumn}>
                              {/* 自hub表示 */}
                              {ownHub && (
                                <Box sx={styles.flexWrap}>
                                  {ownHub.participants.length > 0 ? (
                                    <>
                                      {ownHub.participants
                                        .filter((participant) => !participant.isSiteManager)
                                        .map((participant, participantIndex) => (
                                          <Box
                                            key={participantIndex}
                                            sx={styles.participantContainer}
                                          >
                                            <Avatar
                                              url={participant.accountProfileImageUrl}
                                              bgColor={
                                                participant.accountProfileImageBackgroundColorNumber
                                              }
                                              name={participant.accountName}
                                              size="small"
                                              sx={{ borderRadius: "50%" }}
                                              participationType={participant.participationType}
                                              showStatus={true}
                                            />
                                            <Box display="flex" alignItems="center" gap="4px">
                                              <Typography fontSize="14px">
                                                {participant.accountName}
                                              </Typography>
                                            </Box>
                                          </Box>
                                        ))}
                                    </>
                                  ) : (
                                    <Typography
                                      fontSize="12px"
                                      color="grayScale.600"
                                      sx={{ pl: 4 }}
                                    >
                                      メンバーがいません
                                    </Typography>
                                  )}
                                </Box>
                              )}

                              {/* 招待したhubs表示 */}
                              {invitedHubs.length > 0 && (
                                <>
                                  <CustomDivider
                                    title="自社が招待したHub"
                                    color={theme.palette.grayScale[700]}
                                  />
                                  {invitedHubs.map((hub, hubIndex) => (
                                    <Box key={hubIndex} sx={styles.hubSection}>
                                      <Box sx={styles.flexRowCenter}>
                                        <Avatar
                                          url={hub.logoUrl}
                                          bgColor={hub.logoBackgroundColorNumber}
                                          name={hub.name}
                                          size="small"
                                          sx={{ borderRadius: "50%" }}
                                        />
                                        <Typography
                                          sx={{
                                            fontSize: "14px",
                                            textDecoration: hub.isDeclined
                                              ? "line-through"
                                              : "none",
                                          }}
                                        >
                                          {hub.name}
                                        </Typography>
                                      </Box>

                                      {/* 招待hub内のメンバー表示 */}
                                      {hub.participants.length > 0 && (
                                        <Box sx={styles.participantsSection}>
                                          {/* 現場責任者を先に表示 */}
                                          {hub.participants
                                            .filter((participant) => participant.isSiteManager)
                                            .map((participant, participantIndex) => (
                                              <Box
                                                key={participantIndex}
                                                sx={styles.siteManagerItem}
                                              >
                                                <Avatar
                                                  url={participant.accountProfileImageUrl}
                                                  bgColor={
                                                    participant.accountProfileImageBackgroundColorNumber
                                                  }
                                                  name={participant.accountName}
                                                  size="small"
                                                  sx={{ borderRadius: "50%" }}
                                                  participationType={participant.participationType}
                                                  showStatus={true}
                                                />
                                                <Typography
                                                  variant="caption"
                                                  sx={{ textAlign: "center" }}
                                                >
                                                  {participant.accountName}
                                                </Typography>
                                                <TitleBadge title="当日現場責任者" />
                                              </Box>
                                            ))}

                                          {/* 現場責任者以外を表示 */}
                                          <Box sx={styles.participantsGrid}>
                                            {hub.participants
                                              .filter((participant) => !participant.isSiteManager)
                                              .map((participant, participantIndex) => (
                                                <Box
                                                  key={participantIndex}
                                                  sx={styles.participantItem}
                                                >
                                                  <Avatar
                                                    url={participant.accountProfileImageUrl}
                                                    bgColor={
                                                      participant.accountProfileImageBackgroundColorNumber
                                                    }
                                                    name={participant.accountName}
                                                    size="small"
                                                    sx={{ borderRadius: "50%" }}
                                                    participationType={
                                                      participant.participationType
                                                    }
                                                    showStatus={true}
                                                  />
                                                  <Typography
                                                    variant="caption"
                                                    sx={{ textAlign: "center" }}
                                                  >
                                                    {participant.accountName}
                                                  </Typography>
                                                </Box>
                                              ))}
                                          </Box>
                                        </Box>
                                      )}
                                    </Box>
                                  ))}
                                </>
                              )}
                            </Box>
                          ) : (
                            <Typography color={theme.palette.grayScale[700]}>
                              参加者がいません
                            </Typography>
                          )}
                        </Box>
                      </Stack>

                      {/* 自社以外のHubが招待されている場合は、招待されたHubを表示 */}
                      {state.participantHubs.length > 1 && !state.isInvited && (
                        <ScheduleParticipantHubsBlock participantHubs={state.participantHubs} />
                      )}
                    </Box>
                  </div>

                  <div style={styles.sectionContainer}>
                    <FormatAlignLeftIcon fontSize="small" />
                    <Typography sx={styles.textWrap}>
                      {state.note ? (
                        state.note.split("\n").map((str: string, index: number) => {
                          return (
                            <React.Fragment key={index}>
                              <LinkItUrl>
                                <LinkItEmail>
                                  {str}
                                  <br />
                                </LinkItEmail>
                              </LinkItUrl>
                            </React.Fragment>
                          );
                        })
                      ) : (
                        <Typography sx={{ lineHeight: "16px" }}>メモなし</Typography>
                      )}
                    </Typography>
                  </div>

                  {state.isPrivate && (
                    <div style={styles.sectionWithIcon}>
                      <LockIcon fontSize="small" />
                      <Typography sx={styles.textWrap}>非公開予定</Typography>
                    </div>
                  )}

                  <Box sx={{ mt: 2 }}>
                    <CustomFormLabel labelName="案件" labelColor={theme.palette.grayScale[700]} />
                    {!state.project ? (
                      <>
                        {!state.isInvited && (
                          <Button
                            variant="contained"
                            startIcon={<LinkIcon size={16} color="white" />}
                            onClick={() => switchProjectLinkModal(true)}
                            sx={{ minWidth: "12rem" }}
                          >
                            案件を紐づける
                          </Button>
                        )}
                      </>
                    ) : (
                      <ProjectBlock
                        project={state.project}
                        onClick={handleClickProject}
                        onClickUnlink={!state.isInvited ? handleProjectUnlink : undefined}
                      />
                    )}
                  </Box>

                  {state.project && (
                    <>
                      <Box sx={styles.infoSection}>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <CustomFormLabel
                            labelName="作業日報"
                            labelColor={theme.palette.grayScale[700]}
                          />
                          {scheduleWorkReports?.filter((hub) => hub.id === mainState.hub.id)
                            .length === 0 && (
                            <Button
                              variant="contained"
                              startIcon={<AssignmentOutlinedIcon />}
                              onClick={handleClickWorkReport}
                              sx={{ minWidth: "12rem" }}
                            >
                              作業日報を作成する
                            </Button>
                          )}
                        </Box>
                        {scheduleWorkReports?.length === 0 && (
                          <Typography>作業日報はありません</Typography>
                        )}
                        {scheduleWorkReports
                          ?.filter((hub) => hub.id === mainState.hub.id)
                          .map((hub) => (
                            <React.Fragment key={hub.id}>
                              {hub.workReports.map((workReport) => (
                                <WorkReportBlock
                                  key={workReport.id}
                                  workReport={workReport}
                                  ownerType="own"
                                  setShow={setWorkReportShow}
                                />
                              ))}
                            </React.Fragment>
                          ))}
                        {scheduleWorkReports
                          ?.filter((hub) => hub.id !== mainState.hub.id)
                          .map((hub) => (
                            <React.Fragment key={hub.id}>
                              {mainState.hub.id !== hub.id && hub.workReports.length > 0 && (
                                <Divider textAlign="left" sx={{ ...styles.dividerTitle }}>
                                  <Typography
                                    fontWeight="bold"
                                    fontSize="12px"
                                    color="textDisabled"
                                  >
                                    {hub.name}が作成
                                  </Typography>
                                </Divider>
                              )}
                              {hub.workReports.map((workReport) => (
                                <WorkReportBlock
                                  key={workReport.id}
                                  workReport={workReport}
                                  ownerType="partner"
                                  setShow={setWorkReportShow}
                                />
                              ))}
                            </React.Fragment>
                          ))}
                      </Box>

                      {workReportShow.ownerType === "own" && (
                        <WorkReportDetailModal
                          show={workReportShow}
                          onClose={() =>
                            setWorkReportShow({
                              isOpen: false,
                              id: undefined,
                              ownerType: undefined,
                            })
                          }
                          onDelete={handleDeleteForWorkReport}
                          fetchIndexKey={props.fetchIndexKey}
                          isPartner={false}
                        />
                      )}
                      {workReportShow.ownerType === "partner" && (
                        <WorkReportDetailModal
                          show={workReportShow}
                          onClose={() =>
                            setWorkReportShow({
                              isOpen: false,
                              id: undefined,
                              ownerType: undefined,
                            })
                          }
                          isPartner={true}
                        />
                      )}

                      <WorkReportCreateModal
                        isOpen={isOpenWorkReportModal}
                        setIsOpen={setIsOpenWorkReportModal}
                        handleSubmit={handleSubmitWorkReport}
                        projectId={state.project.id}
                        fetchIndexKeys={[props.fetchIndexKey]}
                      />
                    </>
                  )}
                  <LinkProjectFormModal
                    isOpen={isOpenLinkProjectModal}
                    scheduleName={state.name}
                    onClose={() => switchProjectLinkModal(false)}
                    onClickSubmit={handleLinkProject}
                  />

                  <Box sx={styles.infoSection}>
                    <Box>
                      <CustomFormLabel labelName="作成" labelColor={theme.palette.grayScale[700]} />
                      <Box sx={styles.infoLabel}>
                        <Typography component="span">{state.createdBy.name}</Typography>
                        <Typography component="span" sx={{ ml: "8px" }}>
                          {formatDateUtil(state.createdAt, "yyyy/MM/dd(E) HH:mm")}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Box sx={styles.infoSection}>
                    <Box>
                      <CustomFormLabel labelName="更新" labelColor={theme.palette.grayScale[700]} />
                      <Box sx={styles.infoLabel}>
                        <Typography component="span">{state.updatedBy.name}</Typography>
                        <Typography component="span" sx={{ ml: "0.5rem" }}>
                          {formatDateUtil(state.updatedAt, "yyyy/MM/dd(E) HH:mm")}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </>
              ) : (
                <PrivateScheduleContent onClose={handleClose} state={state} />
              )}
            </Box>

            {state.isAccessible && isUserSelectedAsMember() && (
              <Box sx={styles.footerContainer}>
                <Typography sx={styles.footerTitle}>予定に参加しますか？</Typography>
                <Box sx={styles.footerButtons}>
                  <Button
                    variant={participationStatus === "attend" ? "contained" : "outlined"}
                    onClick={() => handleParticipationChange("attend")}
                    sx={{
                      ...styles.participationButton,
                      backgroundColor:
                        participationStatus === "attend"
                          ? theme.palette.secondary.main
                          : theme.palette.grayScale[0],
                      color:
                        participationStatus === "attend" ? "white" : theme.palette.grayScale[700],
                    }}
                    startIcon={<Check fontSize="small" />}
                  >
                    参加
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={() => handleParticipationChange("pending")}
                    sx={{
                      ...styles.participationButton,
                      backgroundColor:
                        participationStatus === "pending"
                          ? theme.palette.grayScale[700]
                          : theme.palette.grayScale[0],
                      color:
                        participationStatus === "pending" ? "white" : theme.palette.grayScale[700],
                    }}
                    startIcon={<HelpOutlineOutlinedIcon fontSize="small" />}
                  >
                    未定
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={() => handleParticipationChange("absent")}
                    sx={{
                      ...styles.participationButton,
                      backgroundColor:
                        participationStatus === "absent"
                          ? theme.palette.error.main
                          : theme.palette.grayScale[0],
                      color:
                        participationStatus === "absent" ? "white" : theme.palette.grayScale[700],
                    }}
                    startIcon={<CancelOutlinedIcon fontSize="small" />}
                  >
                    不参加
                  </Button>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

interface PrivateScheduleProps {
  onClose: () => void;
  state: Schedule;
}

const PrivateScheduleContent = (props: PrivateScheduleProps) => {
  return (
    <Box>
      {/*ヘッダー*/}
      <Box sx={styles.headerFixed}>
        <Box sx={styles.buttonContainer}>
          <div>
            <IconButton onClick={props.onClose}>
              <Close />
            </IconButton>
            <Typography sx={{ fontSize: "12px" }} align="center">
              閉じる
            </Typography>
          </div>
        </Box>

        <Box sx={styles.flexRowCenter}>
          <Typography sx={styles.scheduleName}>{props.state.name}</Typography>
        </Box>
        <Divider sx={{ mt: 2, mb: 3 }} />
      </Box>

      <div style={styles.sectionWithIcon}>
        <ScheduleIcon fontSize="small" />
        <Stack spacing="8px">
          <Typography>
            {timeRange(
              "yyyy年MM月dd日 HH:mm",
              props.state.startTime ?? undefined,
              props.state.endTime ?? undefined,
            )}
          </Typography>
        </Stack>
      </div>

      <div style={styles.sectionWithIcon}>
        <LockIcon fontSize="small" />
        <Typography sx={styles.textWrap}>非公開予定</Typography>
      </div>

      <Box sx={styles.infoSection}>
        <CustomFormLabel labelName="予定作成者" labelColor={theme.palette.grayScale[700]} />
        <Box sx={styles.infoLabel}>
          <Typography fontSize={14} fontWeight="bold">
            {props.state.createdBy.name}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

import { useState } from "react";
import { PhotoResponse } from "data-access/repositories/project/photo/photo.dto";
import { TertiaryCategoryId } from "data-access/repositories/project/tertiary_category/tertiary_category.dto";

export const usePhotoSelect = (photos: PhotoResponse[] | undefined) => {
  const [selectedPhotoIds, setSelectedPhotoIds] = useState<Set<number>>(new Set());

  const handleSelectPhoto = (photoId: number) => {
    setSelectedPhotoIds((prevSelected) => {
      const newSelected = new Set(prevSelected);
      if (newSelected.has(photoId)) {
        newSelected.delete(photoId);
      } else {
        newSelected.add(photoId);
      }
      return newSelected;
    });
  };

  const handleSelectPhotoAll = () => {
    if (!photos) return;
    const allPhotoIds = photos.map((photo) => photo.id);
    setSelectedPhotoIds(new Set(allPhotoIds));
  };

  const handleDeselectPhotoAll = () => {
    setSelectedPhotoIds(new Set());
  };

  const handleSelectPhotoForTertiaryCategory = (tertiaryCategory: {
    id: TertiaryCategoryId;
    name: string;
  }) => {
    if (!photos) return;
    const photoIds = photos
      .filter((photo) => photo.tertiaryCategory.name === tertiaryCategory.name)
      .map((photo) => photo.id);

    setSelectedPhotoIds(() => new Set([...selectedPhotoIds, ...photoIds]));
  };

  const handleDeselectPhotoForTertiaryCategory = (tertiaryCategory: {
    id: TertiaryCategoryId;
    name: string;
  }) => {
    if (!photos) return;
    const photoIds = photos
      .filter((photo) => photo.tertiaryCategory.name === tertiaryCategory.name)
      .map((photo) => photo.id);
    setSelectedPhotoIds((prev) => {
      const newSelected = new Set(prev);
      photoIds.forEach((photoId) => {
        newSelected.delete(photoId);
      });
      return newSelected;
    });
  };

  return {
    selectedPhotoIds,
    setSelectedPhotoIds,
    handleSelectPhoto,
    handleSelectPhotoAll,
    handleDeselectPhotoAll,
    handleSelectPhotoForTertiaryCategory,
    handleDeselectPhotoForTertiaryCategory,
  };
};

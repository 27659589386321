import { useEffect, useMemo, useState } from "react";
import { Edit, OpenInNew } from "@mui/icons-material";
import { Divider, IconButton, Tooltip, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { AmountTextField } from "components/atoms/amount-text-field";
import { BillingId } from "data-access/repositories/billing/billing.dto";
import { ProjectBilling } from "data-access/repositories/billing/project_billing/project_billing.dto";
import { projectBillingRepository } from "data-access/repositories/billing/project_billing/project_billing.repository";
import { theme } from "extensions/theme";
import { billingSidebarOperations } from "store/billing-sidebar/operations";
import { useAppDispatch } from "store/hooks";
import { mainOperations } from "store/main/operations";
import { useSWRConfig } from "swr";
import { openURLInNewTab } from "utils/openURLInNewTab";
import { TaxChangeDialog } from "../tax-change-dialog";

interface Props {
  project: ProjectBilling;
  billingId: BillingId;
}

const CustomTypographyTitle = styled(Typography)`
  font-size: 12px;
  color: ${theme.palette.grayScale[700]};
`;

const CustomTypographyValue = styled(Typography)`
  font-size: 12px;
  font-weight: 500;
`;

export const ProjectDetailBlock = (props: Props) => {
  const dispatch = useAppDispatch();
  const { project, billingId } = props;
  const { mutate } = useSWRConfig();
  const [open, setOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [isTaxDisplayed, setIsTaxDisplayed] = useState<boolean>(true);

  const [amount, setAmount] = useState<number>(project.amount);
  const [tax, setTax] = useState<number>(project.tax);
  const [balanceStatus, setBalanceStatus] = useState<string>("");

  const balanceAmount = useMemo(() => {
    setTax(project.tax);
    return project.project.salesAmount - project.project.billedAmount;
  }, [project.project.billedAmount]);

  useEffect(() => {
    if (balanceAmount > 0) {
      setBalanceStatus("positive");
    } else if (balanceAmount < 0) {
      setBalanceStatus("negative");
    } else {
      setBalanceStatus("zero");
    }
  }, [balanceAmount]);

  const handleClick = () => {
    openURLInNewTab(`projects/${project.project.id}`);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = e.target.value;
    switch (e.target.name) {
      case "billingAmount":
        setIsTaxDisplayed(false);
        return setAmount(Number(value));
      case "tax":
        return setTax(Number(value));
    }
  };

  const handleBlurAmount = async (e: React.FocusEvent<HTMLInputElement>, amount: number) => {
    try {
      await projectBillingRepository.update(billingId, project.id, { amount });
      mutate(`/api/v1/billings/${billingId}/project_billings`);
      dispatch(mainOperations.updateSuccessMessage("請求金額を更新しました"));
      // 入金ステータスを更新するために必要
      dispatch(billingSidebarOperations.showBilling(billingId));
    } catch (error) {
      dispatch(mainOperations.updateErrorMessage(error.response.data.message));
    }
    setIsTaxDisplayed(true);
  };

  const handleBlurTax = async () => {
    try {
      await projectBillingRepository.update(billingId, project.id, { tax });
      mutate(`/api/v1/billings/${billingId}/project_billings`);
      dispatch(mainOperations.updateSuccessMessage("消費税を修正しました"));
      // 入金ステータスを更新するために必要
      dispatch(billingSidebarOperations.showBilling(billingId));
    } catch (error) {
      dispatch(mainOperations.updateErrorMessage(error.response.data.message));
    }
  };

  return (
    <>
      {/* 消費税変更ダイアログ */}
      <TaxChangeDialog
        onClose={() => setOpen(false)}
        isOpen={open}
        tax={tax}
        onChange={handleChange}
        onBlur={handleBlurTax}
        anchorEl={anchorEl}
      />
      <div
        style={{
          backgroundColor: theme.palette.customPrimary[50],
          border: `1px solid ${theme.palette.grayScale[300]}`,
          borderRadius: "5px",
          padding: "12px",
          width: "100%",
          marginBottom: "16px",
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography sx={{ fontSize: "14px", fontWeight: "bold" }}>
            {project.project.code}：{project.project.name}
          </Typography>
          <IconButton onClick={handleClick} sx={{ p: 0 }}>
            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: "bold",
                color: theme.palette.primary.main,
                mr: "4px",
              }}
            >
              案件詳細
            </Typography>
            <OpenInNew fontSize="small" color="primary" />
          </IconButton>
        </div>

        <Divider sx={{ my: "12px" }} />

        <div style={{ display: "flex" }}>
          <div
            style={{
              width: "30%",
            }}
          >
            <CustomTypographyTitle sx={{ mb: "8px" }}>請求金額</CustomTypographyTitle>
            <AmountTextField
              name="billingAmount"
              width="150px"
              value={amount}
              onChange={handleChange}
              blurFunc={handleBlurAmount}
            />
            <div style={{ display: "flex", alignItems: "center" }}>
              <p style={{ fontSize: "11px" }}>
                税込: ¥{isTaxDisplayed && ` ${(amount + project.tax).toLocaleString()}`}
              </p>
              <IconButton
                onClick={(e) => {
                  setAnchorEl(e.currentTarget);
                  setOpen(true);
                }}
              >
                <Edit color="primary" sx={{ width: "14px" }} />
              </IconButton>
            </div>
            {balanceStatus === "negative" && (
              <Typography sx={{ fontSize: "11px", color: theme.palette.red[500] }}>
                請求金額が請求残を上回っています
              </Typography>
            )}
          </div>

          <div
            style={{
              width: "25%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <div style={{ display: "flex", gap: "8px" }}>
              <CustomTypographyTitle sx={{ width: "48px" }}>売上</CustomTypographyTitle>
              <Typography sx={{ fontSize: "14px", fontWeight: "500" }}>
                ¥ {project.project.salesAmount.toLocaleString()}
              </Typography>
            </div>
            <Divider sx={{ my: "12px" }} />
            <div>
              <div style={{ display: "flex", gap: "8px", marginBottom: "8px" }}>
                <Typography
                  sx={{ width: "48px", fontSize: "11px", color: theme.palette.grayScale[700] }}
                >
                  請求済み
                </Typography>
                <Typography sx={{ fontSize: "11px", fontWeight: "500" }}>
                  ¥ {project.project.billedAmount.toLocaleString()}
                </Typography>
              </div>
              <div style={{ display: "flex", gap: "8px" }}>
                <Typography
                  sx={{ width: "48px", fontSize: "11px", color: theme.palette.grayScale[700] }}
                >
                  請求残
                </Typography>
                <Typography
                  sx={{
                    fontSize: "11px",
                    fontWeight: "500",
                    color:
                      balanceStatus === "zero"
                        ? theme.palette.secondary.main
                        : balanceStatus === "negative"
                          ? theme.palette.red[500]
                          : theme.palette.primary.main,
                  }}
                >
                  ¥ {balanceAmount.toLocaleString()}
                </Typography>
              </div>
            </div>
          </div>

          <Divider sx={{ mx: "12px" }} orientation="vertical" flexItem />

          <div style={{ width: "45%" }}>
            <div style={{ display: "flex", gap: "12px", marginBottom: "12px" }}>
              <div>
                <CustomTypographyTitle sx={{ mb: "4px" }}>受付担当者</CustomTypographyTitle>
                <CustomTypographyValue>{project.project.inquiredBy?.name}</CustomTypographyValue>
              </div>
              <div>
                <CustomTypographyTitle sx={{ mb: "4px" }}>現場責任者</CustomTypographyTitle>
                <CustomTypographyValue>{project.project.manager?.name}</CustomTypographyValue>
              </div>
              <div>
                <CustomTypographyTitle sx={{ mb: "4px" }}>完了日</CustomTypographyTitle>
                <CustomTypographyValue>
                  {project.project.completedDate?.toString()}
                </CustomTypographyValue>
              </div>
            </div>
            <div>
              <CustomTypographyTitle sx={{ mb: "4px" }}>請求に関する連絡事項</CustomTypographyTitle>
              <Tooltip title={project.project.billingPrecaution} arrow>
                <CustomTypographyValue
                  sx={{
                    width: "223px",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    display: "-webkit-box",
                    "-webkit-box-orient": "vertical",
                    "-webkit-line-clamp": "2",
                  }}
                >
                  {project.project.billingPrecaution}
                </CustomTypographyValue>
              </Tooltip>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

import { Brand } from "data-access/repositories/brand";

export type HubId = Brand<number, "HubId">;
export type HubUuId = Brand<string, "HubId">;

export interface HubInfoData {
  id: HubId;
  uuid: HubUuId;
  logoUrl: string;
  logoBackgroundColorNumber: string;
  name: string;
  officeName: string;
  postalCode: string;
  address: string;
  addressSecond: string;
  phoneNumber: string;
  faxNumber: string;
  statusType: HubStatusType;
  fiscalYear: number;
  nextSettlementDate: string | null;
}

export interface HubInfoUpdateRequest {
  logoFile?: File;
  deleteLogoFile?: boolean;
  name?: string;
  officeName?: string;
  postalCode?: string;
  address?: string;
  addressSecond?: string;
  phoneNumber?: string;
}

type HubStatusType =
  | "trial"
  | "standard_plan"
  | "canceled"
  | "trial_ended"
  | "free_plan"
  | "light_plan"
  | "enterprise_plan";

export const initialHubInfoData: HubInfoData = {
  id: 0 as HubId,
  uuid: "" as HubUuId,
  logoUrl: "",
  logoBackgroundColorNumber: "",
  name: "",
  officeName: "",
  postalCode: "",
  address: "",
  addressSecond: "",
  phoneNumber: "",
  faxNumber: "",
  statusType: "trial",
  fiscalYear: 0,
  nextSettlementDate: null,
};

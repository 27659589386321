import { ReactNode } from "react";
import { Box, Button, Typography } from "@mui/material";
import { CloseIcon } from "components/icon/close-icon";
import { ScheduleOverlappingType, UserId } from "data-access/repositories/account/account.dto";
import { theme } from "extensions/theme";

interface Props {
  userId: UserId;
  userName: string;
  requestType?: "unchanged" | "add" | "remove";
  onClear?: (userId: UserId) => void;
  isSiteManager?: boolean;
  isOverlapped?: boolean;
  overlapType?: ScheduleOverlappingType;
  customStyles?: any;
  icon?: ReactNode;
}

export const SelectedMemberLabel = ({
  requestType = "unchanged",
  isSiteManager = false,
  isOverlapped = false,
  overlapType,
  customStyles,
  icon,
  ...props
}: Props) => {
  const getColor = () => {
    if (requestType !== "unchanged") {
      switch (requestType) {
        case "add":
          return theme.palette.blue[500];
        case "remove":
          return theme.palette.grayScale[300];
      }
    }

    return theme.palette.grayScale[900];
  };

  const getStyles = () => {
    if (customStyles) {
      return customStyles;
    }

    if (isOverlapped && overlapType) {
      switch (overlapType.value) {
        case "forbidden":
          return {
            backgroundColor: theme.palette.red[100],
            borderColor: theme.palette.red[500],
          };
        case "warning":
          return {
            backgroundColor: theme.palette.mustard[100],
            borderColor: theme.palette.mustard[500],
          };
      }
    }

    if (isSiteManager) {
      return {
        backgroundColor: theme.palette.customPrimary[50],
        borderColor: theme.palette.grayScale[900],
      };
    }

    return {
      backgroundColor: "inherit",
      borderColor: getColor(),
    };
  };

  const { backgroundColor, borderColor } = getStyles();

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        gap: "4px",
        height: "25px",
        p: "2px 8px",
        border: `1px solid ${borderColor}`,
        borderRadius: "4px",
        backgroundColor,
        ...customStyles,
      }}
    >
      {icon}
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography
          sx={{
            fontSize: "12px",
            fontWeight: "bold",
            color: requestType !== "unchanged" ? getColor() : undefined,
          }}
        >
          {props.userName}
        </Typography>
      </Box>
      {props.onClear && !isSiteManager && requestType !== "remove" && (
        <Button
          startIcon={<CloseIcon size={18} />}
          onClick={() => props.onClear && props.onClear(props.userId)}
          color="primary"
          sx={{ p: 0, minWidth: "auto", "& .MuiButton-icon": { m: 0 } }}
        />
      )}
    </Box>
  );
};

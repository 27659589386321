import { ConnectType } from "features/hub-settings/types/connect/connect.dto";
import { HubId, HubInfoData, HubUuId } from "features/hub-settings/types/hub-info/hub_info.dto";
import { UserId } from "../account/account.dto";

export interface SignUpVerifyResponse {
  destinationEmail: string;
  tokenType: "account_invitation" | "connect_request";
  hub: HubInfoData;
  connectType: ConnectType;
}

export interface SignUpAccountRequest {
  signupToken: string;
  password: string;
  me: {
    name: string;
    phoneNumber: string;
  };
}

export interface SignUpHubRequest {
  signupToken: string;
  password: string;
  me: {
    name: string;
    phoneNumber: string;
  };
  hub: {
    name: string;
    officeName: string;
    postalCode: string;
    address: string;
    addressSecond: string;
  };
}

export interface SignUpResponse {
  data: {
    id: UserId;
    name: string;
    email: string;
    hubId: HubId;
    hubUuid: HubUuId;
    hubName: string;
    accessToken: string;
    refreshToken: string;
    permissions: {
      projectWrite: boolean;
      masterWrite: boolean;
    };
  };
}

export const initialSignUpAccountRequest: SignUpAccountRequest = {
  signupToken: "",
  password: "",
  me: {
    name: "",
    phoneNumber: "",
  },
};

export const initialSignUpHubRequest: SignUpHubRequest = {
  signupToken: "",
  password: "",
  me: {
    name: "",
    phoneNumber: "",
  },
  hub: {
    name: "",
    officeName: "",
    postalCode: "",
    address: "",
    addressSecond: "",
  },
};

import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { initialPermissions, Permissions } from "data-access/cookie/token.dto";
import {
  Account,
  AccountReference,
  initialAccountReference,
} from "data-access/repositories/account/account.dto";
import {
  HubSetting,
  initialHubSetting,
} from "data-access/repositories/hub_setting/hub_setting.dto";
import {
  initialPersonalSetting,
  PersonalSetting,
} from "data-access/repositories/personal_setting/personal_setting.dto";
import { ProjectStatusType } from "data-access/repositories/project_status_type/project_status_type.dto";
import { ProjectType } from "data-access/repositories/project_type/project_type.dto";
import { ScheduleType } from "data-access/repositories/schedule_type/schedule_type.dto";
import { TodoTagType } from "data-access/repositories/todo_tag_type/todo/todo_tag_type.dto";
import { HubInfoData, initialHubInfoData } from "features/hub-settings/types/hub-info/hub_info.dto";
import {
  getHub,
  getProjectTypes,
  getProjectStatusTypes,
  getProjectTodoTagTypes,
  getPersonalSetting,
  getAuth,
  getScheduleTypes,
  getAccounts as getAccounts,
  getHubSetting,
  getMe,
} from "./actions";
import type { RootState } from "../store";

export interface mainState {
  isLoading: boolean;
  isCompanySettingToNavBar: boolean;
  successMessage: string;
  errorMessage: string;
  permissions: Permissions;
  hub: HubInfoData;
  me: AccountReference;
  hubSetting: HubSetting;
  projectTypes: ProjectType[];
  projectStatusTypes: ProjectStatusType[];
  accounts: Account[];
  projectTodoTagTypes: TodoTagType[];
  scheduleTypes: ScheduleType[];
  personalSetting: PersonalSetting;
}

const initialState: mainState = {
  isLoading: false,
  isCompanySettingToNavBar: false,
  successMessage: "",
  errorMessage: "",
  permissions: initialPermissions,
  hub: initialHubInfoData,
  me: initialAccountReference,
  hubSetting: initialHubSetting,
  projectTypes: [],
  projectStatusTypes: [],
  accounts: [],
  projectTodoTagTypes: [],
  scheduleTypes: [],
  personalSetting: initialPersonalSetting,
};

export const mainSlice = createSlice({
  name: "mainSlice",
  initialState,
  reducers: {
    updateSuccessMessage: (state, action: PayloadAction<{ value: string }>) => ({
      ...state,
      successMessage: action.payload.value,
    }),
    updateErrorMessage: (state, action: PayloadAction<{ value: string }>) => ({
      ...state,
      errorMessage: action.payload.value,
    }),
    updateIsLoading: (state, action: PayloadAction<{ value: boolean }>) => ({
      ...state,
      isLoading: action.payload.value,
    }),
    updateIsCompanySettingToNavBar: (state, action: PayloadAction<{ value: boolean }>) => ({
      ...state,
      isCompanySettingToNavBar: action.payload.value,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(getAuth.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAuth.fulfilled, (state, action) => {
      state.permissions = action.payload.data.permissions;
      state.isLoading = false;
    });
    builder.addCase(getAuth.rejected, (state, action) => {
      state.isLoading = false;
      if (action.payload) {
        state.errorMessage = action.payload.message;
      }
    });
    builder.addCase(getHub.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getHub.fulfilled, (state, action) => {
      state.hub = action.payload;
      state.isLoading = false;
    });
    builder.addCase(getHub.rejected, (state, action) => {
      state.isLoading = false;
      if (action.payload) {
        state.errorMessage = action.payload.message;
      }
    });
    builder.addCase(getMe.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getMe.fulfilled, (state, action) => {
      state.me = action.payload;
      state.isLoading = false;
    });
    builder.addCase(getMe.rejected, (state, action) => {
      state.isLoading = false;
      if (action.payload) {
        state.errorMessage = action.payload.message;
      }
    });
    builder.addCase(getHubSetting.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getHubSetting.fulfilled, (state, action) => {
      state.hubSetting = action.payload;
      state.isLoading = false;
    });
    builder.addCase(getHubSetting.rejected, (state, action) => {
      state.isLoading = false;
      if (action.payload) {
        state.errorMessage = action.payload.message;
      }
    });
    builder.addCase(getProjectTypes.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getProjectTypes.fulfilled, (state, action) => {
      state.projectTypes = action.payload;
      state.isLoading = false;
    });
    builder.addCase(getProjectTypes.rejected, (state, action) => {
      state.isLoading = false;
      if (action.payload) {
        state.errorMessage = action.payload.message;
      }
    });
    builder.addCase(getProjectStatusTypes.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getProjectStatusTypes.fulfilled, (state, action) => {
      state.projectStatusTypes = action.payload;
      state.isLoading = false;
    });
    builder.addCase(getProjectStatusTypes.rejected, (state, action) => {
      state.isLoading = false;
      if (action.payload) {
        state.errorMessage = action.payload.message;
      }
    });
    builder.addCase(getAccounts.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAccounts.fulfilled, (state, action) => {
      state.accounts = action.payload;
      state.isLoading = false;
    });
    builder.addCase(getAccounts.rejected, (state, action) => {
      state.isLoading = false;
      if (action.payload) {
        state.errorMessage = action.payload.message;
      }
    });
    builder.addCase(getProjectTodoTagTypes.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getProjectTodoTagTypes.fulfilled, (state, action) => {
      state.isLoading = false;
      state.projectTodoTagTypes = action.payload;
    });
    builder.addCase(getProjectTodoTagTypes.rejected, (state, action) => {
      state.isLoading = false;
      if (action.payload) {
        state.errorMessage = action.payload.message;
      }
    });
    builder.addCase(getScheduleTypes.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getScheduleTypes.fulfilled, (state, action) => {
      state.isLoading = false;
      state.scheduleTypes = action.payload;
    });
    builder.addCase(getScheduleTypes.rejected, (state, action) => {
      state.isLoading = false;
      if (action.payload) {
        state.errorMessage = action.payload.message;
      }
    });
    builder.addCase(getPersonalSetting.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getPersonalSetting.fulfilled, (state, action) => {
      state.isLoading = false;
      state.personalSetting = action.payload;
    });
    builder.addCase(getPersonalSetting.rejected, (state, action) => {
      state.isLoading = false;
      if (action.payload) {
        state.errorMessage = action.payload.message;
      }
    });
  },
});

export const selectMain = (state: RootState) => state.main;
export default mainSlice.reducer;

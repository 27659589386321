import { ApiClient } from "data-access/ApiClient";
import { convertKeysToCamelCase, convertKeysToSnakeCase } from "utils/convertObjectKeyCase";
import {
  NoticeOvertimeRecordIndexResponse,
  NoticeOvertimeRecordUpdateRequest,
} from "./overtime_record.dto";

export const noticeOvertimeRecordRepository = {
  index(): Promise<NoticeOvertimeRecordIndexResponse> {
    return ApiClient.get("/api/v1/notice/overtime_records/setting").then((res) => {
      return convertKeysToCamelCase(res.data);
    });
  },
  update(body: NoticeOvertimeRecordUpdateRequest): Promise<void> {
    return ApiClient.put(
      "/api/v1/notice/overtime_records/setting",
      convertKeysToSnakeCase(body),
    ).then((res) => res.data);
  },
};

import { ObjectKeys } from "types/objectKeys";
import { Brand } from "../brand";
import { PageInfo } from "../page_info/page_info.dto";
import { ProjectId } from "../project/project.dto";

export type BillingId = Brand<number, "BillingId">;

export interface Billing extends ObjectKeys {
  id: BillingId;
  subject: string;
  code: string;
  mailing_name: string;
  mailing_address: string;
  billing_file_create_status_type: string;
  is_warranty_attach: boolean;
  warranty_create_status_type: string;
  deposit_status_type: string;
  deposit_amount: number;
  deposit_completed_date: string;
  billing_date: string;
  adjusted_amount: number;
  note: string;
  billing_amount: number;
  billing_tax: number;
  tax_included_billing_amount: number;
  accounts_receivable: number;
  client_name: string;
  created_by_name: string;
  project_type_name: string;
}

export interface BillingIndexRequest {
  startBillingDate?: string;
  endBillingDate?: string;
  createdById?: number;
  keyword?: string;
  statusType?: string;
  billingFileCreateStatusType?: string;
  warrantyCreateStatusType?: string;
  depositStatusTypes?: string[];
  sort?: string;
  direction?: "desc" | "asc";
  page?: number;
  rowsPerPage?: number;
}

export interface BillingIndexResponse {
  data: Billing[];
  pagination: PageInfo;
}

export interface BillingCreateRequest {
  projectBillings: {
    projectId: ProjectId;
    amount: number;
    tax: number;
  }[];
  statusType: "before_billed" | "billed";
  code: string;
  subject: string;
  mailingName: string;
  mailingAddress: string;
  billingDate: string;
  note: string;
  isWarrantyAttach: boolean;
}

export type BillingCreateResponse = Billing;

export const initialBillingCreateRequest: BillingCreateRequest = {
  projectBillings: [],
  statusType: "billed",
  code: "",
  subject: "",
  mailingName: "",
  mailingAddress: "",
  billingDate: "",
  note: "",
  isWarrantyAttach: false,
};

export type BillingShowResponse = Billing;

export interface BillingUpdateRequest extends ObjectKeys {
  subject?: string;
  code?: string;
  clientId?: number;
  mailingName?: string;
  mailingAddress?: string;
  billingDate?: string;
  note?: string;
  isWarrantyAttach?: boolean;
  adjustedAmount?: number;
  statusType?: string;
}

export type BillingUpdateResponse = Billing;

export const initialBilling: Billing = {
  id: 0 as BillingId,
  subject: "",
  code: "",
  mailing_name: "",
  mailing_address: "",
  billing_file_create_status_type: "",
  is_warranty_attach: false,
  warranty_create_status_type: "",
  deposit_status_type: "",
  deposit_amount: 0,
  deposit_completed_date: "",
  billing_date: "",
  adjusted_amount: 0,
  note: "",
  billing_amount: 0,
  billing_tax: 0,
  tax_included_billing_amount: 0,
  accounts_receivable: 0,
  client_name: "",
  created_by_name: "",
  project_type_name: "",
};

export const initialBillingUpdateRequest: BillingUpdateRequest = {
  subject: "",
  code: "",
  clientId: 0,
  mailingName: "",
  mailingAddress: "",
  billingDate: "",
  note: "",
  isWarrantyAttach: false,
  adjustedAmount: 0,
  statusType: "before_billed",
};

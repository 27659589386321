import { useState, useImperativeHandle, forwardRef } from "react";
import { Close } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { theme } from "extensions/theme";

export type Handle = {
  confirm: () => Promise<boolean>;
};

type Props = {
  title?: string;
  content?: string;
  yesButtonText?: string;
  yesButtonColor?: "primary" | "error";
};

export const AsyncConfirmDialog = forwardRef<Handle, Props>((props, ref) => {
  const [open, setOpen] = useState(false);

  const [handleAgree, setHandleAgree] = useState<any>(() => {});
  const [handleDisagree, setHandleDisagree] = useState<any>(() => {});
  const resetHandle = () => {
    setHandleAgree(() => {});
    setHandleDisagree(() => {});
    setOpen(false);
  };

  useImperativeHandle(ref, () => ({
    confirm: () =>
      new Promise((resolve) => {
        setHandleAgree(() => () => {
          resetHandle();
          resolve(true);
        });
        setHandleDisagree(() => () => {
          resetHandle();
          resolve(false);
        });
        setHandleDisagree(() => () => {
          resetHandle();
          resolve(false);
        });
        setOpen(true);
      }),
  }));

  return (
    <CustomDialog
      handleNo={handleDisagree}
      handleYes={handleAgree}
      isOpen={open}
      title={props.title}
      content={props.content}
      yesButtonText={props.yesButtonText}
      yesButtonColor={props.yesButtonColor}
    />
  );
});

AsyncConfirmDialog.displayName = "AsyncConfirmDialog";

interface CustomDialogProps {
  isOpen: boolean;
  handleYes: () => void;
  handleNo: () => void;
  title?: string;
  content?: string;
  yesButtonText?: string;
  yesButtonColor?: "primary" | "error";
}

const CustomDialog = ({
  title = "削除しますか？",
  content = "",
  yesButtonText = "削除",
  yesButtonColor = "error",
  ...props
}: CustomDialogProps) => {
  return (
    <Dialog
      open={props.isOpen}
      maxWidth="sm"
      sx={{
        "& .MuiPaper-root": {
          padding: "1rem",
        },
      }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          padding: 0,
          color: theme.palette.grayScale[900],
        }}
      >
        <IconButton onClick={props.handleNo}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id="dialog-description"
          sx={{
            color: theme.palette.grayScale[900],
            textAlign: "center",
            fontSize: "18px",
            whiteSpace: "pre-line",
            fontWeight: "bold",
            mb: "8px",
          }}
        >
          {title}
        </DialogContentText>
        <DialogContentText
          id="dialog-description"
          sx={{
            color: theme.palette.grayScale[900],
            textAlign: "center",
            fontSize: "14px",
            whiteSpace: "pre-line",
          }}
        >
          {content}
        </DialogContentText>
      </DialogContent>

      <DialogActions sx={{ justifyContent: "space-between", pt: "18px" }}>
        <Button onClick={props.handleNo} color="primary" variant="outlined" sx={{ width: "13rem" }}>
          いいえ
        </Button>
        <Button
          onClick={props.handleYes}
          color={yesButtonColor}
          variant="contained"
          sx={{ width: "13rem" }}
          disableElevation
        >
          {yesButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

import { useMemo, useState } from "react";
import { Typography } from "@mui/material";
import { ExpandToggleLabel } from "components/label/expand-toggle-label";
import { TitleLabel } from "components/label/title-label";
import { ProjectDetailBlock } from "components/molecules/project-detail-block";
import { BillingId } from "data-access/repositories/billing/billing.dto";
import { ProjectBilling } from "data-access/repositories/billing/project_billing/project_billing.dto";
import { theme } from "extensions/theme";
import { ProjectBillingInitialDisplayNumber } from "utils/constant";

interface Props {
  billingId: BillingId;
  projectBillings: ProjectBilling[];
  sumAmount: number;
}

export const BillingDetails = (props: Props) => {
  const { billingId, projectBillings, sumAmount } = props;
  const [expanded, setExpanded] = useState<boolean>(false);

  const displayNumber = useMemo(() => {
    if (expanded) {
      return projectBillings.length || 0;
    }
    return ProjectBillingInitialDisplayNumber;
  }, [expanded]);

  return (
    <>
      <div style={{ marginBottom: "16px" }}>
        <TitleLabel title="請求内訳" />
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "end",
          backgroundColor: theme.palette.customPrimary[50],
          padding: "12px",
          marginBottom: "16px",
          borderRadius: "4px",
          gap: "8px",
        }}
      >
        <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>請求金額合計</Typography>
        <Typography sx={{ fontSize: "18px", fontWeight: "bold" }}>
          ¥{sumAmount.toLocaleString()}
        </Typography>
        <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>（税込）</Typography>
      </div>
      {projectBillings.slice(0, displayNumber).map((projectBilling) => {
        return (
          <ProjectDetailBlock
            key={projectBilling.id}
            project={projectBilling}
            billingId={billingId}
          />
        );
      })}
      {(projectBillings.length || 0) > ProjectBillingInitialDisplayNumber && (
        <ExpandToggleLabel onClick={() => setExpanded(!expanded)} expanded={expanded} />
      )}
    </>
  );
};

import { theme } from "extensions/theme";

export const styles = {
  errorMessage: {
    fontSize: "14px",
    fontWeight: "bold",
    my: "16px",
    textAlign: "center",
    width: "100%",
  },
  textField: {
    "& .MuiOutlinedInput-root": {
      height: "48px",
    },
    mb: "24px",
  },
  contentTitle: {
    fontSize: "18px",
    fontWeight: "bold",
    color: theme.palette.primary.main,
    mb: "16px",
  },
  title: {
    fontSize: "12px",
    fontWeight: "bold",
    mb: "4px",
  },
  content: {
    fontSize: "14px",
    color: theme.palette.grayScale[700],
  },
};

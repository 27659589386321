import { ApiClient } from "data-access/ApiClient";
import { convertKeysToCamelCase } from "utils/convertObjectKeyCase";
import {
  SecondaryCategoryId,
  SecondaryCategoryIndexResponse,
  SecondaryCategoryUpdateRequest,
} from "./secondary_category.dto";
import { PhotoResponse } from "../photo/photo.dto";
import { PrimaryCategoryId } from "../primary_category/primary_category.dto";
import { ProjectId } from "../project.dto";

export const secondaryCategoryRepository = {
  show(
    projectId: ProjectId,
    primaryCategoryId: PrimaryCategoryId,
    secondaryCategoryId: SecondaryCategoryId,
  ): Promise<SecondaryCategoryIndexResponse> {
    return ApiClient.get(
      `/api/v1/projects/${projectId}/primary_categories/${primaryCategoryId}/secondary_categories/${secondaryCategoryId}`,
    ).then((res) => convertKeysToCamelCase(res.data));
  },
  create(projectId: ProjectId, primaryCategoryId: PrimaryCategoryId): Promise<void> {
    return ApiClient.post(
      `/api/v1/projects/${projectId}/primary_categories/${primaryCategoryId}/secondary_categories`,
    ).then((res) => res.data);
  },
  update(
    projectId: ProjectId,
    primaryCategoryId: PrimaryCategoryId,
    secondaryCategoryId: SecondaryCategoryId,
    body: SecondaryCategoryUpdateRequest,
  ): Promise<void> {
    return ApiClient.put(
      `/api/v1/projects/${projectId}/primary_categories/${primaryCategoryId}/secondary_categories/${secondaryCategoryId}`,
      updateBody(body),
    ).then((res) => res.data);
  },
  delete(
    projectId: ProjectId,
    primaryCategoryId: PrimaryCategoryId,
    secondaryCategoryId: SecondaryCategoryId,
  ): Promise<void> {
    return ApiClient.delete(
      `/api/v1/projects/${projectId}/primary_categories/${primaryCategoryId}/secondary_categories/${secondaryCategoryId}`,
    ).then((res) => res.data);
  },
  getPhotos(
    projectId: ProjectId,
    primaryCategoryId: PrimaryCategoryId,
    secondaryCategoryId: SecondaryCategoryId,
  ): Promise<Array<PhotoResponse>> {
    return ApiClient.get(
      `/api/v2/projects/${projectId}/primary_categories/${primaryCategoryId}/secondary_categories/${secondaryCategoryId}/photos`,
    ).then((res) => convertKeysToCamelCase(res.data));
  },
  sharedIndex(
    projectUuid: string,
    primaryCategoryId: PrimaryCategoryId,
    secondaryCategoryId: SecondaryCategoryId,
  ): Promise<SecondaryCategoryIndexResponse> {
    return ApiClient.get(
      `/api/v1/sharable_photos/${projectUuid}/primary_categories/${primaryCategoryId}/secondary_categories/${secondaryCategoryId}`,
    ).then((res) => convertKeysToCamelCase(res.data));
  },
  getSharedPhotos(
    projectUuid: string,
    primaryCategoryId: PrimaryCategoryId,
    secondaryCategoryId: SecondaryCategoryId,
  ): Promise<Array<PhotoResponse>> {
    return ApiClient.get(
      `/api/v1/sharable_photos/${projectUuid}/primary_categories/${primaryCategoryId}/secondary_categories/${secondaryCategoryId}/photos`,
    ).then((res) => convertKeysToCamelCase(res.data));
  },
};

const updateBody = (body: SecondaryCategoryUpdateRequest) => {
  return {
    name: body.name,
    display_order: body.displayOrder,
  };
};

import React from "react";
import { Autocomplete, Checkbox, Chip, TextField } from "@mui/material";
import { UserId } from "data-access/repositories/account/account.dto";
import { theme } from "extensions/theme";
import { styles } from "./styles";
import { SiteManagerIcon } from "../../icon/site-manager-icon";

interface Props {
  users: { userId: UserId; name: string }[];
  activeUsers: { userId: UserId; name: string }[];
  siteManagerId: UserId;
  onChange: (
    e: React.SyntheticEvent<Element, Event>,
    newValue: { userId: UserId; name: string }[],
  ) => void;
  label?: string;
}
export const MemberAutocomplete = ({ label = "参加者", ...props }: Props) => {
  const { users, activeUsers, siteManagerId, onChange } = props;
  const classes = styles();

  const siteManagerIcon = (option: { userId: UserId; name: string }) => {
    if (siteManagerId === option.userId) {
      return <SiteManagerIcon />;
    }
    return undefined;
  };

  return (
    <Autocomplete
      multiple
      options={activeUsers || []}
      value={users || []}
      onChange={(e, newValue) => {
        const updatedValue = newValue.filter((option) => option.userId !== siteManagerId);
        if (siteManagerId) {
          const siteManagerOption = activeUsers.find((user) => user.userId === siteManagerId);
          if (siteManagerOption) {
            updatedValue.unshift(siteManagerOption);
          }
        }
        onChange(e, updatedValue);
      }}
      disableCloseOnSelect
      disableClearable
      fullWidth
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={(option, value) => option.userId === value.userId}
      renderOption={(props, option, { selected }) => (
        <li {...props} key={option.userId} aria-disabled={siteManagerId === option.userId}>
          <Checkbox style={{ marginRight: 8 }} checked={selected} />
          {option.name}
        </li>
      )}
      renderInput={(params) => (
        <TextField {...params} label={`${label}を選択`} variant="standard" />
      )}
      renderTags={(tagValue, getTagProps) => {
        const reorderedTags = [
          ...tagValue.filter((option) => option.userId === siteManagerId),
          ...tagValue.filter((option) => option.userId !== siteManagerId),
        ];
        return reorderedTags.map((option, index) => {
          const tagProps = getTagProps({ index });
          // スプレッド演算子でkeyを渡すとReactの警告が発生するため、keyを明示的に指定し残りのプロパティを別途スプレッド
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { key, ...restTagProps } = tagProps;
          return (
            <Chip
              key={option.userId}
              label={option.name}
              icon={siteManagerIcon(option)}
              {...restTagProps}
              sx={{
                backgroundColor:
                  siteManagerId === option.userId ? theme.palette.customPrimary[50] : "inherit",
                "& .MuiChip-deleteIcon": {
                  display: siteManagerId === option.userId ? "none" : "block",
                },
                border: `1px solid ${theme.palette.primary.main}`,
              }}
            />
          );
        });
      }}
      className={classes.memberAutocomplete}
    />
  );
};

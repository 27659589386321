import { useState } from "react";
import ImageIcon from "@mui/icons-material/Image";
import { Box, MenuItem, FormControl, Typography, Select, TextField, styled } from "@mui/material";
import { CustomFormLabel } from "components/molecules/custom-form-label";
import { ProjectPhotosModal } from "components/molecules/project-photos-modal";
import { UserId } from "data-access/repositories/account/account.dto";
import { PhotoResponse } from "data-access/repositories/project/photo/photo.dto";
import {
  ProjectFormIdentificationState,
  ProjectFormUpdateRequest,
  ProjectId,
} from "data-access/repositories/project/project.dto";
import { projectRepository } from "data-access/repositories/project/project.repository";
import { ProjectType, ProjectTypeId } from "data-access/repositories/project_type/project_type.dto";
import { theme } from "extensions/theme";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { mainOperations } from "store/main/operations";
import { selectMain } from "store/main/slice";
import { mutate } from "swr";
import { API_PATHS } from "utils/apiPaths";
import { ACTIVITY_PER_PAGE } from "utils/constant";
import { handleReduxError } from "utils/errorHandler";
import { styles } from "./styles";

const SBox = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
interface Props {
  projectId?: ProjectId;
  identificationState: ProjectFormIdentificationState;
  setIdentificationState: React.Dispatch<React.SetStateAction<ProjectFormIdentificationState>>;
  lastSavedFormValue: React.MutableRefObject<ProjectFormUpdateRequest>;
  isCreateNew: boolean;
  photoInfo: { firstThreePhotos: PhotoResponse[]; totalCount: number };
  photoUrlKey: string;
  onCreateProject: (targetName: string, value: any) => void;
  currentPage: number;
}

export const ProjectSidebarIdentification = (props: Props) => {
  const dispatch = useAppDispatch();
  const mainState = useAppSelector(selectMain);
  const [open, setOpen] = useState(false);

  const handleClosePhotoModal = () => {
    if (!props.projectId) return;
    setOpen(false);
    mutate(API_PATHS.getProject(props.projectId));
  };

  const handleSubmitProject = async (
    currentValue: string | number | null,
    target: keyof ProjectFormIdentificationState,
  ) => {
    if (props.isCreateNew) {
      props.onCreateProject(target, currentValue);
      return;
    }
    if (!props.projectId) return;
    if (currentValue !== props.lastSavedFormValue.current[target]) {
      dispatch(mainOperations.updateIsLoading(true));
      try {
        await projectRepository.update(props.projectId, {
          [target]: currentValue,
        });
        dispatch(mainOperations.updateSuccessMessage("更新しました"));
        props.lastSavedFormValue.current = {
          ...props.lastSavedFormValue.current,
          [target]: currentValue,
        };
        mutate([API_PATHS.getProjects(), props.currentPage]);
        mutate(API_PATHS.getProjectActivities(props.projectId, ACTIVITY_PER_PAGE));
      } catch (error) {
        handleReduxError(error, dispatch, "更新に失敗しました");
        props.setIdentificationState((prev) => ({
          ...prev,
          [target]: props.lastSavedFormValue.current[target],
        }));
      } finally {
        dispatch(mainOperations.updateIsLoading(false));
      }
    }
  };
  return (
    <>
      <ProjectPhotosModal
        isOpen={open}
        onClose={handleClosePhotoModal}
        projectId={props.projectId}
        photoUrlKey={props.photoUrlKey}
      />

      <Box sx={{ display: "flex", height: "70px", gap: "24px", mt: "24px", mb: "24px" }}>
        <SBox>
          <CustomFormLabel
            labelName="案件番号"
            labelSize="14px"
            labelColor={theme.palette.grayScale[700]}
          />
          {/* 案件番号の書式が空欄の場合は手入力となる */}
          {mainState.hubSetting.project_code_format === "" ? (
            <TextField
              name="code"
              size="small"
              value={props.identificationState.code}
              onChange={(e) => {
                props.setIdentificationState((prev) => ({
                  ...prev,
                  code: e.target.value,
                }));
              }}
              onBlur={(e) => {
                const value = e.target.value;
                handleSubmitProject(value, "code");
              }}
              sx={{ width: "120px" }}
            />
          ) : (
            <Typography sx={{ fontWeight: "500" }}>{props.identificationState.code}</Typography>
          )}
        </SBox>

        <SBox>
          <CustomFormLabel
            labelName="先方注文番号"
            labelSize="14px"
            labelColor={theme.palette.grayScale[700]}
          />
          <TextField
            name="inquiryNumber"
            size="small"
            value={props.identificationState.inquiryNumber}
            onChange={(e) => {
              props.setIdentificationState((prev) => ({
                ...prev,
                inquiryNumber: e.target.value,
              }));
            }}
            onBlur={(e) => {
              const value = e.target.value;
              handleSubmitProject(value, "inquiryNumber");
            }}
            sx={{
              width: "120px",
            }}
          />
        </SBox>

        <SBox>
          <CustomFormLabel
            labelName="現場責任者"
            labelSize="14px"
            labelColor={theme.palette.grayScale[700]}
          />
          <Select
            name="managerId"
            variant="standard"
            value={props.identificationState.managerId || 0}
            onChange={(e) => {
              const value = Number(e.target.value) as UserId;
              props.setIdentificationState((prev) => ({
                ...prev,
                managerId: value,
              }));
              handleSubmitProject(value, "managerId");
            }}
            sx={{
              fontWeight: "bold",
              width: "120px",
              "&.MuiInputBase-root:before": {
                borderBottom: "none",
              },
            }}
          >
            {mainState.accounts.map(
              (account) =>
                !account.isDeactivate && (
                  <MenuItem key={account.userId} value={account.userId}>
                    {account.name}
                  </MenuItem>
                ),
            )}
          </Select>
        </SBox>

        <SBox>
          <CustomFormLabel
            labelName="受付担当者"
            labelSize="14px"
            labelColor={theme.palette.grayScale[700]}
          />
          <Select
            name="inquiredById"
            variant="standard"
            value={props.identificationState.inquiredById || 0}
            onChange={(e) => {
              const value = Number(e.target.value) as UserId;
              props.setIdentificationState((prev) => ({
                ...prev,
                inquiredById: value,
              }));
              handleSubmitProject(value, "inquiredById");
            }}
            sx={{
              fontWeight: "bold",
              width: "120px",
              "&.MuiInputBase-root:before": {
                borderBottom: "none",
              },
            }}
          >
            {mainState.accounts.map(
              (account) =>
                !account.isDeactivate && (
                  <MenuItem key={account.userId} value={account.userId}>
                    {account.name}
                  </MenuItem>
                ),
            )}
          </Select>
        </SBox>

        <SBox>
          <CustomFormLabel
            labelName="案件タイプ"
            labelSize="14px"
            labelColor={theme.palette.grayScale[700]}
          />
          <FormControl size="small">
            <Select
              name="projectTypeId"
              variant="standard"
              value={props.identificationState.projectTypeId || 0}
              onChange={(e) => {
                const value = Number(e.target.value) as ProjectTypeId;
                props.setIdentificationState((prev) => ({
                  ...prev,
                  projectTypeId: value,
                }));
                handleSubmitProject(value, "projectTypeId");
              }}
              sx={{
                fontWeight: "bold",
                width: "120px",
                "&.MuiInputBase-root:before": {
                  borderBottom: "none",
                },
              }}
            >
              {mainState.projectTypes.map((projectType: ProjectType, index) => (
                <MenuItem key={index} value={projectType.id}>
                  {projectType.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </SBox>

        {props.isCreateNew && (
          <Box sx={{ display: "flex", alignItems: "center", ml: "8px" }}>
            <ImageIcon sx={{ fontSize: "3.8rem", cursor: "not-allowed" }} />
          </Box>
        )}
        {!props.isCreateNew && (
          <Box
            onClick={() => setOpen(true)}
            sx={{ display: "flex", alignItems: "end", cursor: "pointer" }}
          >
            {props.photoInfo.totalCount === 0 ? (
              <ImageIcon sx={{ fontSize: "3.8rem", cursor: "pointer" }} />
            ) : (
              props.photoInfo.totalCount !== 0 && (
                <>
                  {props.photoInfo.firstThreePhotos.map((photo) => (
                    <Box
                      key={photo.id}
                      component="img"
                      sx={{
                        height: 44,
                        width: 44,
                        maxHeight: 44,
                        maxWidth: 44,
                        pr: 0.2,
                      }}
                      alt={photo.fileName}
                      src={photo.thumbnailUrl}
                    />
                  ))}
                </>
              )
            )}
            {props.photoInfo.totalCount > 3 && (
              <Box sx={{ ...styles.thumbnailArea }}>
                <Typography sx={{ m: 0, color: theme.palette.grayScale[0] }}>{`+${
                  props.photoInfo.totalCount - 3
                }`}</Typography>
              </Box>
            )}
          </Box>
        )}
      </Box>
    </>
  );
};

import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { SelectChangeEvent, Typography } from "@mui/material";
import { GridColumnVisibilityModel } from "@mui/x-data-grid/hooks/features/columns/gridColumnsInterfaces";
import {
  GridRenderCellParams,
  GridRowParams,
  GridColDef,
  GridValidRowModel,
  GridSortModel,
} from "@mui/x-data-grid-pro";
import { CheckCircledIcon } from "components/icon/check-circled-icon";
import { BillingTableColumnLabel } from "components/label/billing-table-column-label";
import { CustomDataGridPro } from "components/molecules/custom-data-grid-pro";
import { ResponseSnackbar } from "components/templates/response-snackbar";
import { getFilterItem } from "data-access/local_storage/table_filter/local_storage";
import { BillingIndexRequest, BillingId } from "data-access/repositories/billing/billing.dto";
import { billingRepository } from "data-access/repositories/billing/billing.repository";
import { CSVGridColDef } from "pages/projects";
import { billingSidebarOperations } from "store/billing-sidebar/operations";
import { selectBillingSidebar } from "store/billing-sidebar/slice";
import { billingTableOperations } from "store/billing-table/operations";
import { selectBillingTable } from "store/billing-table/slice";
import { selectBillingTableHeader } from "store/billing-table-header/slice";
import { useAppSelector, useAppDispatch } from "store/hooks";
import { selectMain } from "store/main/slice";
import { convertBilledStatusToJP } from "utils/convertToJP";
import { downloadDataGridRowsCsv } from "utils/downloadDataGridRowsCsv";
import { handleReduxError } from "utils/errorHandler";
import { DATE_SLASH_FORMAT, formatDateUtil } from "utils/formatDateUtil";

export const BillingTable = () => {
  const dispatch = useAppDispatch();
  const state = useAppSelector(selectBillingTable);
  const headerState = useAppSelector(selectBillingTableHeader);
  const detailState = useAppSelector(selectBillingSidebar);
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const mainState = useAppSelector(selectMain);

  const storageKeyName = "billingTableColumnOrder";
  const sortStorageKeyName = "billingTableColumnSort";
  const visibilityStorageKeyName = "billingTableColumnVisibility";
  const rowsPerPageLocalStorageKey = "billingTableRowsPerPage";
  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({});

  const openDetails = (id: number) => {
    dispatch(billingSidebarOperations.showBilling(id as BillingId));
    dispatch(billingSidebarOperations.open());
    const path = `/billings/${id}`;
    if (path !== location.pathname) {
      navigate(path);
    }
  };
  const handleRowClick = (params: GridRowParams) => {
    navigate(`/billings/${params.id}`);
  };

  const searchQuery = (page?: number) => {
    const filterItem = getFilterItem("billing");
    const query: BillingIndexRequest = {
      statusType: filterItem.statusType,
      billingFileCreateStatusType: filterItem.billingFileCreateStatusType,
      warrantyCreateStatusType: filterItem.warrantyCreateStatusType,
      depositStatusTypes: filterItem.depositStatusTypes,
      page: page ?? state.currentPage,
      rowsPerPage: state.rowsPerPage,
    };
    if (filterItem.createdById !== 0) {
      query.createdById = filterItem.createdById;
    }
    if (filterItem.keyword !== "") {
      query.keyword = filterItem.keyword;
    }
    if (filterItem.billingDateV2.range.startDate) {
      query.startBillingDate = formatDateUtil(
        filterItem.billingDateV2.range.startDate,
        "yyyy-MM-dd",
      );
    }
    if (filterItem.billingDateV2.range.endDate) {
      query.endBillingDate = formatDateUtil(filterItem.billingDateV2.range.endDate, "yyyy-MM-dd");
    }
    const localStorageSortItem = localStorage.getItem(sortStorageKeyName);
    if (localStorageSortItem !== null) {
      const sortItem: { field: string; sort: "desc" | "asc" } = JSON.parse(localStorageSortItem);
      query.sort = sortItem.field;
      query.direction = sortItem.sort;
    }
    return query;
  };

  useEffect(() => {
    if (params.id) {
      openDetails(Number(params.id));
    } else {
      dispatch(billingSidebarOperations.close());
    }
  }, [location.pathname]);

  useEffect(() => {
    dispatch(billingTableOperations.updateCurrentPage(1));
  }, []);

  useEffect(() => {
    if (headerState.countChanges > 0) {
      dispatch(billingTableOperations.index(searchQuery(1)));
    }
  }, [headerState.countChanges]);

  useEffect(() => {
    dispatch(billingTableOperations.index(searchQuery()));
  }, [state.currentPage, state.rowsPerPage]);

  useEffect(() => {
    if (state.isSubmitted) {
      dispatch(billingTableOperations.index(searchQuery()));
    }
    if (detailState.isSubmitted) {
      dispatch(billingTableOperations.index(searchQuery()));
    }
  }, [state.isSubmitted, detailState.isSubmitted]);

  useEffect(() => {
    if (state.isDestroyed) {
      dispatch(billingTableOperations.index(searchQuery()));
      dispatch(billingSidebarOperations.close());
      navigate("/billings");
    }
  }, [state.isDestroyed]);

  const defaultHeaders: CSVGridColDef[] = [];
  defaultHeaders.push({
    field: "code",
    headerName: "請求番号",
    minWidth: 120,
    sortingOrder: ["desc", "asc", null],
    disableReorder: true,
  });
  defaultHeaders.push({
    field: "subject",
    headerName: "請求書名",
    minWidth: 300,
    sortable: false,
    disableReorder: true,
    csvRender: (value: string) => {
      // テキストフィールド内にダブルクォートが含まれる場合改行されてしまうため
      // それを2つのダブルクォートに置き換えることでテキストの一部であることを示す
      return `"${value.replace(/"/g, '""')}"`;
    },
  });
  defaultHeaders.push({
    field: "client_name",
    headerName: "顧客",
    minWidth: 150,
    sortable: false,
  });
  defaultHeaders.push({
    field: "billing_file_create_status_type",
    headerName: "請求書アップロード",
    minWidth: 150,
    sortable: false,
    align: "center",
    csvRender: (_: number, row: GridValidRowModel) =>
      row.billing_file_create_status_type === "before_billing_file_created"
        ? ""
        : "アップロード済み",
    renderCell: (params: GridRenderCellParams) => <CheckCircledIcon value={params.value} />,
  });
  if (mainState.hubSetting.is_warranty_use) {
    defaultHeaders.push({
      field: "warranty_create_status_type",
      headerName: "保証書アップロード",
      minWidth: 150,
      sortable: false,
      align: "center",
      renderCell: (params: GridRenderCellParams) => (
        <BillingTableColumnLabel type={params.value} sx={{ minWidth: "100px" }} />
      ),
    });
  }
  if (mainState.hubSetting.is_billing_status_type_use) {
    defaultHeaders.push({
      field: "status_type",
      headerName: "送付ステータス",
      minWidth: 130,
      sortable: false,
      align: "center",
      renderCell: (params: GridRenderCellParams) => (
        <BillingTableColumnLabel type={params.value} sx={{ minWidth: "100px" }} />
      ),
    });
  }
  defaultHeaders.push({
    field: "deposit_status_type",
    headerName: "入金ステータス",
    minWidth: 130,
    sortable: false,
    align: "center",
    csvRender: (_: number, row: GridValidRowModel) =>
      convertBilledStatusToJP(row.deposit_status_type),
    renderCell: (params: GridRenderCellParams) => (
      <BillingTableColumnLabel type={params.value} sx={{ minWidth: "100px" }} />
    ),
  });
  defaultHeaders.push({
    field: "deposit_completed_date",
    headerName: "入金完了日",
    minWidth: 110,
    sortable: false,
    renderCell: (params: GridRenderCellParams) => (
      <Typography sx={{ fontSize: "0.875rem" }}>
        {params.value ? formatDateUtil(params.value, DATE_SLASH_FORMAT) : ""}
      </Typography>
    ),
  });
  defaultHeaders.push({
    field: "created_by_name",
    headerName: "請求作成者",
    minWidth: 150,
    sortable: false,
  });
  defaultHeaders.push({
    field: "billing_date",
    headerName: "請求日",
    minWidth: 110,
    sortable: false,
    renderCell: (params: GridRenderCellParams) => (
      <Typography sx={{ fontSize: "0.875rem" }}>
        {params.value ? formatDateUtil(params.value, DATE_SLASH_FORMAT) : ""}
      </Typography>
    ),
  });
  defaultHeaders.push({
    field: "billing_amount",
    headerName: "請求金額(税抜)",
    minWidth: 130,
    sortable: false,
    renderCell: (params: GridRenderCellParams) => (
      <Typography sx={{ width: "100%", textAlign: "right" }}>
        ¥ {params.value.toLocaleString()}
      </Typography>
    ),
  });
  defaultHeaders.push({
    field: "billing_tax",
    headerName: "請求金額(消費税)",
    minWidth: 130,
    sortable: false,
    renderCell: (params: GridRenderCellParams) => (
      <Typography sx={{ width: "100%", textAlign: "right" }}>
        ¥ {params.value.toLocaleString()}
      </Typography>
    ),
  });
  defaultHeaders.push({
    field: "tax_included_billing_amount",
    headerName: "請求金額(税込)",
    minWidth: 130,
    sortable: false,
    renderCell: (params: GridRenderCellParams) => (
      <Typography sx={{ width: "100%", textAlign: "right" }}>
        ¥ {params.value.toLocaleString()}
      </Typography>
    ),
  });
  defaultHeaders.push({
    field: "deposit_amount",
    headerName: "入金金額合計",
    minWidth: 110,
    sortable: false,
    renderCell: (params: GridRenderCellParams) => (
      <Typography sx={{ width: "100%", textAlign: "right" }}>
        ¥ {params.value.toLocaleString()}
      </Typography>
    ),
  });
  defaultHeaders.push({
    field: "adjusted_amount",
    headerName: "調整金額",
    minWidth: 80,
    sortable: false,
    renderCell: (params: GridRenderCellParams) => (
      <Typography sx={{ width: "100%", textAlign: "right" }}>
        ¥ {params.value.toLocaleString()}
      </Typography>
    ),
  });
  defaultHeaders.push({
    field: "accounts_receivable",
    headerName: "売掛残",
    minWidth: 130,
    sortable: false,
    renderCell: (params: GridRenderCellParams) => (
      <Typography sx={{ width: "100%", textAlign: "right" }}>
        ¥ {params.value.toLocaleString()}
      </Typography>
    ),
  });
  defaultHeaders.push({
    field: "project_type_name",
    headerName: "案件タイプ",
    minWidth: 120,
    sortable: false,
  });

  const columns = useMemo((): CSVGridColDef[] => {
    const localStorageItem = localStorage.getItem(storageKeyName);
    const visibilityLocalStorageItem = localStorage.getItem(visibilityStorageKeyName);
    visibilityLocalStorageItem && setColumnVisibilityModel(JSON.parse(visibilityLocalStorageItem));

    let resultHeaders: GridColDef[] = [];
    if (localStorageItem === null) {
      resultHeaders = defaultHeaders;
    } else {
      const orderColumns: [{ field: string; hide: boolean }] = JSON.parse(localStorageItem);
      orderColumns.forEach((column) => {
        const header = defaultHeaders.find((header) => header.field === column.field);
        if (header) {
          const updatedHeader = {
            ...header,
            hide: columnVisibilityModel[header.field] === false,
          };
          resultHeaders.push(updatedHeader);
        }
      });
      const remainingHeaders = defaultHeaders.filter(
        (header) => !resultHeaders.some((h) => h.field === header.field),
      );
      // 残りのヘッダーにも表示/非表示の状態を反映
      const updatedRemainingHeaders = remainingHeaders.map((header) => ({
        ...header,
        hide: columnVisibilityModel[header.field] === false,
      }));
      resultHeaders.push(...updatedRemainingHeaders);
      return resultHeaders;
    }
    return resultHeaders;
  }, [state.pageInfo.count]);

  const handleCloseSuccessSnackBar = () => {
    dispatch(billingTableOperations.updateSuccessMessage(""));
  };

  const handleCloseErrorSnackBar = () => {
    dispatch(billingTableOperations.updateErrorMessage(""));
  };

  const handleSelectPage = (event: SelectChangeEvent<unknown>) => {
    dispatch(billingTableOperations.updateCurrentPage(event.target.value as number));
  };

  const handleChangePage = (_: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    dispatch(billingTableOperations.updateCurrentPage(newPage + 1));
  };

  const handleChangeRowsPerPage = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ): void => {
    dispatch(billingTableOperations.updateRowsPerPage(Number(event.target.value)));
    localStorage.setItem(rowsPerPageLocalStorageKey, event.target.value);
  };

  const setColumnOrder = (allColumns: GridColDef[]) => {
    const columns = allColumns.map((column) => {
      return { field: column.field };
    });
    localStorage.setItem(storageKeyName, JSON.stringify(columns));
  };

  const handleColumnOrderChange = (allColumns: GridColDef[]) => {
    setColumnOrder(allColumns);
  };

  const handleColumnVisibilityModelChange = (model: GridColumnVisibilityModel) => {
    setColumnVisibilityModel(model);
    localStorage.setItem(visibilityStorageKeyName, JSON.stringify(model));
  };

  const handleSortModelChange = (sortItems: GridSortModel) => {
    // Does not currently allow sorting by multiple items
    if (sortItems.length > 0) {
      localStorage.setItem(sortStorageKeyName, JSON.stringify(sortItems[0]));
    } else if (sortItems.length === 0) {
      localStorage.removeItem(sortStorageKeyName);
    }
    dispatch(billingTableOperations.index(searchQuery()));
  };

  const initialState = useMemo(() => {
    const defaultState = {
      pinnedColumns: { left: ["code", "subject"] },
      sorting: {},
    };
    const localStorageItem = localStorage.getItem(sortStorageKeyName);
    if (localStorageItem !== null) {
      const sortItem: { field: string; sort: string } = JSON.parse(localStorageItem);
      defaultState.sorting = {
        sortModel: [{ field: sortItem.field, sort: sortItem.sort }],
      };
    }
    return defaultState;
  }, [state.isSubmitted]);

  const handleDownloadCSV = async () => {
    dispatch(billingTableOperations.setIsLoading(true));
    try {
      const csvColumnDefinitions = [...columns];
      csvColumnDefinitions.push({
        field: "note",
        headerName: "請求メモ",
        csvRender: (value) => {
          return `"${value}"`;
        },
      });
      const allBillings = await billingRepository.downloadAll(searchQuery());
      downloadDataGridRowsCsv("billing", allBillings, csvColumnDefinitions, columnVisibilityModel);
    } catch (error) {
      handleReduxError(error, dispatch, "csvのダウンロードに失敗しました");
    } finally {
      dispatch(billingTableOperations.setIsLoading(false));
    }
  };

  return (
    <>
      <CustomDataGridPro
        type="billing"
        columns={columns}
        rows={state.billings}
        loading={state.isLoading}
        pageInfo={state.pageInfo}
        currentPage={state.currentPage}
        tableHeight="70vh"
        handleDownloadButton={handleDownloadCSV}
        handleSelectPage={handleSelectPage}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleRowClick={handleRowClick}
        initialState={initialState}
        handleColumnOrderChange={handleColumnOrderChange}
        handleColumnVisibilityModelChange={handleColumnVisibilityModelChange}
        columnVisibilityModel={columnVisibilityModel}
        handleSortModelChange={handleSortModelChange}
      />
      <ResponseSnackbar
        successMessage={state.successMessage}
        handleCloseSuccess={handleCloseSuccessSnackBar}
        errorMessage={state.errorMessage}
        handleCloseError={handleCloseErrorSnackBar}
      />
    </>
  );
};

export const isNonNumericString = (value: string) => isNaN(Number(value));

export const getTimeFromSeconds = (seconds: number) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const secs = seconds % 60;
  return `${hours}時間${minutes}分${secs}秒`;
};

export const removeCorporateSuffix = (companyName: string): string => {
  const patterns = [
    "株式会社",
    "(株)",
    "（株）",
    "㈱",
    "有限会社",
    "(有)",
    "（有）",
    "㈲",
    "合同会社",
    "(同)",
    "（同）",
    "合名会社",
    "合資会社",
    "一般社団法人",
    "一般財団法人",
    "公益社団法人",
    "公益財団法人",
    "特定非営利活動法人",
    "NPO法人",
  ];

  const regex = new RegExp(
    `^(${patterns.join("|")})|([\\s(（]*(${patterns.join("|")})[）)]*)$`,
    "g",
  );

  return companyName.replace(regex, "").trim();
};

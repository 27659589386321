import { theme } from "extensions/theme";

export const styles = {
  optionCard: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: "16px",
    padding: "16px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "12px",
  },
  button: {
    borderRadius: "24px",
    height: "48px",
    width: "210px",
    fontWeight: "bold",
  },
  description: {
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
};

import { useState } from "react";
import { Box } from "@mui/material";
import { CustomTabs } from "components/molecules/custom-tabs";
import { Layout } from "components/templates/layout";
import { AccountNoticeSetting } from "features/user-settings/components/notice-setting";
import { ProfileSetting } from "features/user-settings/components/profile-setting";
import { Project } from "features/user-settings/components/project";
import { ResetPasswordTab } from "features/user-settings/components/reset-password";
import { Schedule } from "features/user-settings/components/schedule";

type TabType = "schedule" | "project" | "notice" | "changePassword" | "profileSetting";

const UserSettings = () => {
  const [tab, setTab] = useState<TabType>("schedule");

  return (
    <Layout>
      <Box mb={2}>
        <CustomTabs
          value={tab}
          onChange={(_: React.SyntheticEvent, newValue: TabType) => setTab(newValue)}
          tabs={[
            { value: "schedule", label: "予定" },
            { value: "project", label: "案件" },
            { value: "notice", label: "通知" },
            { value: "profileSetting", label: "プロフィール設定" },
            { value: "changePassword", label: "パスワード再設定" },
          ]}
        />
      </Box>

      {tab === "schedule" && <Schedule />}
      {tab === "project" && <Project />}
      {tab === "notice" && <AccountNoticeSetting />}
      {tab === "profileSetting" && <ProfileSetting />}
      {tab === "changePassword" && <ResetPasswordTab />}
    </Layout>
  );
};

export default UserSettings;

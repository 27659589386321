import { useMediaQuery } from "@mui/material";
import { theme } from "extensions/theme";
import { MobileSignUp } from "./mobile";
import { PcSignUp } from "./pc";

export default function SignUp() {
  const isMobile: boolean = useMediaQuery(() => theme.breakpoints.down("md"));
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      {isMobile ? <MobileSignUp /> : <PcSignUp />}
    </div>
  );
}

import { useEffect, useMemo, useState } from "react";
import { Close, Delete } from "@mui/icons-material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Box,
  Button,
  FormControl,
  Select,
  MenuItem,
  Typography,
  Grid2,
} from "@mui/material";
import { AdditionalChargeDialog } from "components/dialog/additional-charge";
import { CampaignDialog } from "components/dialog/campaign";
import { CustomFormLabel } from "components/molecules/custom-form-label";
import { AccountInvitationRequest } from "data-access/repositories/account_invitation/account_invitation.dto";
import { accountInvitationRepository } from "data-access/repositories/account_invitation/account_invitation.repository";
import { Group, GroupId } from "data-access/repositories/group/group.dto";
import { groupRepository } from "data-access/repositories/group/group.repository";
import {
  PermissionSet,
  PermissionSetId,
} from "features/hub-settings/api/permission/permission_set.dto";
import { permissionSetRepository } from "features/hub-settings/api/permission/permission_set.repository";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { mainOperations } from "store/main/operations";
import { selectMain } from "store/main/slice";
import useSWR from "swr";
import { API_PATHS } from "utils/apiPaths";
import { handleReduxError } from "utils/errorHandler";
import { styles } from "./styles";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

interface MemberEntry {
  email: string;
  groupId: GroupId;
  permissionSetId: PermissionSetId;
}

export const InvitationModal = (props: Props) => {
  const mainState = useAppSelector(selectMain);
  const dispatch = useAppDispatch();
  const [members, setMembers] = useState<MemberEntry[]>([]);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isChargeModalOpen, setIsChargeModalOpen] = useState<boolean>(false);
  const [isCampaignModalOpen, setIsCampaignModalOpen] = useState<boolean>(false);
  const [isChargeConfirmed, setIsChargeConfirmed] = useState<boolean>(false);
  const [nonFreeUsersCount, setNonFreeUsersCount] = useState<number>(0);

  const { data: permissionSets } = useSWR<PermissionSet[]>(
    props.isOpen ? API_PATHS.getPermissionSets() : null,
    permissionSetRepository.index,
    { revalidateOnFocus: false },
  );
  const { data: groups } = useSWR<Group[]>(
    props.isOpen ? API_PATHS.getGroups() : null,
    groupRepository.index,
    { revalidateOnFocus: false },
  );

  const permissionOptions = useMemo(() => {
    if (!permissionSets) return [];
    return permissionSets.filter((permissionSet) => permissionSet.roleType !== "maintainer");
  }, [permissionSets]);

  useEffect(() => {
    if (!groups || !permissionSets || permissionOptions.length === 0) return;
    setMembers([{ email: "", groupId: 0 as GroupId, permissionSetId: permissionOptions[0].id }]);
  }, [groups, permissionSets]);

  const handleAddMember = () => {
    if (!permissionOptions.length) return;
    setMembers([
      ...members,
      { email: "", groupId: 0 as GroupId, permissionSetId: permissionOptions[0].id },
    ]);
  };

  const handleRemoveMember = (index: number) => {
    const newMembers = [...members];
    newMembers.splice(index, 1);
    setMembers(newMembers);
  };

  const handleEmailChange = (index: number, value: string) => {
    const newMembers = [...members];
    newMembers[index].email = value;
    setMembers(newMembers);
  };

  const handleGroupChange = (index: number, value: any) => {
    const newMembers = [...members];
    newMembers[index].groupId = value as GroupId;
    setMembers(newMembers);
  };

  const handlePermissionChange = (index: number, value: any) => {
    const newMembers = [...members];
    newMembers[index].permissionSetId = value as PermissionSetId;
    setMembers(newMembers);
  };

  // 追加料金が発生するユーザー数をカウント
  const countNonFreeUsers = (): number => {
    if (!permissionSets) return 0;

    return members.reduce((count, member) => {
      const permissionSet = permissionSets.find((p) => p.id === member.permissionSetId);
      if (permissionSet && permissionSet.roleType !== "free") {
        return count + 1;
      }
      return count;
    }, 0);
  };

  // 追加料金確認ダイアログを表示するか判定
  const checkAdditionalCharge = () => {
    const count = countNonFreeUsers();
    setNonFreeUsersCount(count);

    if (count > 0 && !isChargeConfirmed) {
      if (mainState.hub.statusType === "free_plan" || mainState.hub.statusType === "trial") {
        setIsCampaignModalOpen(true);
        return false;
      } else {
        setIsChargeModalOpen(true);
        return true;
      }
    }
    return false;
  };

  const inviteMembers = async () => {
    dispatch(mainOperations.updateIsLoading(true));
    setIsSubmitting(true);

    try {
      const request: AccountInvitationRequest = {
        accounts: members.map((member) => ({
          email: member.email,
          groupId: member.groupId,
          permissionSetId: member.permissionSetId,
        })),
      };

      await accountInvitationRepository.invite(request);
      dispatch(mainOperations.updateSuccessMessage("招待メールを送信しました。"));
    } catch (error) {
      handleReduxError(error, dispatch, "招待メールの送信に失敗しました。");
    } finally {
      setIsSubmitting(false);
      setIsChargeConfirmed(false);
      dispatch(mainOperations.updateIsLoading(false));
    }
  };

  const handleSubmit = async () => {
    if (checkAdditionalCharge()) return;
    await inviteMembers();
  };

  const handleChargeDialogConfirm = () => {
    setIsChargeModalOpen(false);
    inviteMembers();
  };

  const isSubmitDisabled = useMemo(() => {
    if (isSubmitting) return true;

    return members.some((member) => !member.email || !member.permissionSetId);
  }, [members, isSubmitting]);

  if (!groups || !permissionSets || permissionOptions.length === 0) return null;

  return (
    <>
      <AdditionalChargeDialog
        isOpen={isChargeModalOpen}
        onClose={() => setIsChargeModalOpen(false)}
        onConfirm={handleChargeDialogConfirm}
        nonFreeUsersCount={nonFreeUsersCount}
      />
      <CampaignDialog isOpen={isCampaignModalOpen} onClose={() => setIsCampaignModalOpen(false)} />
      <Dialog open={props.isOpen} onClose={props.onClose} fullWidth maxWidth="md">
        <DialogTitle sx={styles.title}>
          自社メンバーを招待
          <IconButton onClick={props.onClose} sx={styles.closeButton}>
            <Close />
          </IconButton>
        </DialogTitle>

        <DialogContent dividers sx={styles.content}>
          <Box sx={styles.scrollableArea}>
            <Box>
              <Grid2 container spacing={2} alignItems="center">
                <Grid2 size={0.5}>
                  <Typography variant="caption"></Typography>
                </Grid2>
                <Grid2 size={4.5}>
                  <CustomFormLabel
                    labelName="メールアドレス"
                    required
                    labelSize="14px"
                    requiredSize="12px"
                  />
                </Grid2>
                <Grid2 size={3}>
                  <CustomFormLabel labelName="グループ" labelSize="14px" />
                </Grid2>
                <Grid2 size={3}>
                  <CustomFormLabel labelName="権限" labelSize="14px" required requiredSize="12px" />
                </Grid2>
              </Grid2>
            </Box>

            {members.map((member, index) => (
              <Box key={index} sx={{ ...styles.memberRow }}>
                <Grid2 container spacing={2} alignItems="center">
                  <Grid2 size={0.5}>
                    <Typography>{index + 1}.</Typography>
                  </Grid2>
                  <Grid2 size={4.5}>
                    <TextField
                      fullWidth
                      value={member.email}
                      onChange={(e) => handleEmailChange(index, e.target.value)}
                      placeholder="taro@gembahub.jp"
                      size="small"
                    />
                  </Grid2>
                  <Grid2 size={3}>
                    <FormControl fullWidth size="small">
                      <Select
                        value={member.groupId}
                        onChange={(e) => handleGroupChange(index, e.target.value)}
                      >
                        <MenuItem value={0}>未設定</MenuItem>
                        {groups?.map((group) => (
                          <MenuItem key={group.id} value={group.id}>
                            {group.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid2>
                  <Grid2 size={3}>
                    <FormControl fullWidth size="small">
                      <Select
                        value={member.permissionSetId}
                        onChange={(e) => handlePermissionChange(index, e.target.value)}
                      >
                        {permissionOptions.map((permission) => (
                          <MenuItem key={permission.id} value={permission.id}>
                            {permission.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid2>
                  <Grid2 size={1}>
                    {members.length > 1 && (
                      <IconButton onClick={() => handleRemoveMember(index)} size="small">
                        <Delete />
                      </IconButton>
                    )}
                  </Grid2>
                </Grid2>
              </Box>
            ))}

            <Button
              startIcon={<AddCircleIcon />}
              onClick={handleAddMember}
              variant="outlined"
              sx={styles.addButton}
            >
              メンバーを追加
            </Button>
          </Box>

          <Box sx={styles.footer}>
            <Typography sx={{ fontSize: "14px", textAlign: "center", mb: "16px" }}>
              ログインIDとパスワードを記載したメールが送信されます
            </Typography>

            <Box sx={styles.actionButtons}>
              <Button variant="outlined" onClick={props.onClose} sx={styles.actionButton}>
                キャンセル
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                disabled={isSubmitDisabled}
                sx={styles.actionButton}
              >
                招待
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

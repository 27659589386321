import { forwardRef, useState } from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import GroupsIcon from "@mui/icons-material/Groups";
import { Box, Button, Typography } from "@mui/material";
import { InvitationModal } from "./modal/invitation";
import { InvitedMemberModal } from "./modal/invited-member";
import { EmployeeTable } from "./table";

const Employee = forwardRef<HTMLDivElement>((_, ref) => {
  const [isInvitationModalOpen, setInvitationModalOpen] = useState<boolean>(false);
  const [isInvitedMemberModalOpen, setInvitedMemberModalOpen] = useState<boolean>(false);

  return (
    <>
      {isInvitationModalOpen && (
        <InvitationModal
          isOpen={isInvitationModalOpen}
          onClose={() => setInvitationModalOpen(false)}
        />
      )}
      {isInvitedMemberModalOpen && (
        <InvitedMemberModal
          isOpen={isInvitedMemberModalOpen}
          onClose={() => setInvitedMemberModalOpen(false)}
        />
      )}
      <Box
        ref={ref}
        sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: "16px" }}
      >
        <Typography sx={{ fontSize: "14px", fontWeight: "bold" }}>メンバー</Typography>
        <Box sx={{ display: "flex", gap: "8px" }}>
          <Button
            variant="text"
            startIcon={<GroupsIcon />}
            onClick={() => setInvitedMemberModalOpen(true)}
          >
            招待中のメンバー
          </Button>
          <Button
            variant="contained"
            startIcon={<AddCircleIcon />}
            onClick={() => setInvitationModalOpen(true)}
          >
            自社メンバーを招待
          </Button>
        </Box>
      </Box>
      <Box>
        <EmployeeTable />
      </Box>
    </>
  );
});

Employee.displayName = "Employee";

export default Employee;

import { convertKeysToCamelCase, convertKeysToSnakeCase } from "utils/convertObjectKeyCase";
import { AttachmentTypeRequest, AttachmentTypeId, AttachmentType } from "./attachment_type.dto";
import { ApiClient } from "../../ApiClient";

export const attachmentTypeRepository = {
  index(): Promise<AttachmentType[]> {
    return ApiClient.get("/api/v1/attachment_types").then((res) => {
      return convertKeysToCamelCase(res.data);
    });
  },
  create(body: AttachmentTypeRequest): Promise<void> {
    const config = { headers: { "content-type": "application/json" } };
    return ApiClient.post("/api/v1/attachment_types", convertKeysToSnakeCase(body), config).then(
      (res) => res.data,
    );
  },
  show(id: AttachmentTypeId): Promise<AttachmentType> {
    return ApiClient.get(`/api/v1/attachment_types/${id}`).then((res) => {
      return convertKeysToCamelCase(res.data);
    });
  },
  update(id: AttachmentTypeId, body: AttachmentTypeRequest): Promise<void> {
    const config = { headers: { "content-type": "application/json" } };
    return ApiClient.put(
      `/api/v1/attachment_types/${id}`,
      convertKeysToSnakeCase(body),
      config,
    ).then((res) => res.data);
  },
  destroy(id: AttachmentTypeId): Promise<void> {
    return ApiClient.delete(`/api/v1/attachment_types/${id}`).then((res) => res.data);
  },
};

import { useRef, ElementRef } from "react";
import { Close } from "@mui/icons-material";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  Button,
} from "@mui/material";
import { DataGrid, GridRenderCellParams } from "@mui/x-data-grid";
import { GroupLabel } from "components/label/group-label";
import { AsyncConfirmDialog } from "components/templates/async-confirm-dialog";
import { AccountInvitationId } from "data-access/repositories/account_invitation/account_invitation.dto";
import { accountInvitationRepository } from "data-access/repositories/account_invitation/account_invitation.repository";
import { theme } from "extensions/theme";
import { useAppDispatch } from "store/hooks";
import { mainOperations } from "store/main/operations";
import useSWR from "swr";
import { handleReduxError } from "utils/errorHandler";
import { styles } from "./styles";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}
export const InvitedMemberModal = (props: Props) => {
  const dispatch = useAppDispatch();
  const mailResendConfirmRef = useRef<ElementRef<typeof AsyncConfirmDialog>>(null);

  const { data } = useSWR("/api/v1/account_invitations", accountInvitationRepository.index, {
    revalidateOnFocus: false,
  });

  if (!data) return null;

  const handleClose = () => {
    props.onClose();
  };

  const handleMailResend = async (id: AccountInvitationId) => {
    if (!mailResendConfirmRef.current) return;
    const res = await mailResendConfirmRef.current.confirm();
    if (!res) return;

    dispatch(mainOperations.updateIsLoading(true));
    try {
      await accountInvitationRepository.resend(id);
      dispatch(mainOperations.updateSuccessMessage("招待メールを再送信しました。"));
    } catch (error) {
      handleReduxError(error, dispatch, "招待メールの再送信に失敗しました。");
    } finally {
      dispatch(mainOperations.updateIsLoading(false));
    }
  };

  return (
    <Dialog open={props.isOpen} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle sx={{ ...styles.title }}>
        招待中のメンバー
        <IconButton onClick={handleClose} sx={{ ...styles.closeButton }}>
          <Close />
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ mt: "32px", px: "20px" }}>
        <DataGrid
          columns={columns(handleMailResend)}
          rows={data.data}
          loading={false}
          hideFooter
          sx={{ "& .MuiDataGrid-cell": { display: "flex", alignItems: "center" } }}
        />
      </DialogContent>
      <AsyncConfirmDialog
        ref={mailResendConfirmRef}
        title="招待メールを再送信しますか？"
        yesButtonText="再送信"
        yesButtonColor="primary"
      />
    </Dialog>
  );
};

const columns = (handleMailResend: (id: AccountInvitationId) => Promise<void>) => {
  const headers = [];
  headers.push({
    field: "email",
    headerName: "メールアドレス",
    minWidth: 320,
    sortable: false,
  });
  headers.push({
    field: "group",
    headerName: "グループ",
    minWidth: 160,
    sortable: false,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <>
          {params.row.group ? (
            <GroupLabel name={params.row.group.name} colorNumber={params.row.group.colorNumber} />
          ) : (
            <Box
              sx={{
                width: "60px",
                height: "24px",
                lineHeight: "20px",
                fontWeight: "bold",
                textAlign: "center",
                border: `1px solid ${theme.palette.primary.main}`,
                borderRadius: "4px",
              }}
            >
              未設定
            </Box>
          )}
        </>
      );
    },
  });
  headers.push({
    field: "permissionSet",
    headerName: "権限",
    minWidth: 160,
    sortable: false,
    renderCell: (params: GridRenderCellParams) => {
      return <Typography>{params.row.permissionSet.name}</Typography>;
    },
  });
  headers.push({
    field: "action",
    headerName: "",
    minWidth: 120,
    sortable: false,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Button
          startIcon={<MailOutlineIcon />}
          variant="outlined"
          onClick={() => handleMailResend(params.row.id)}
          sx={{
            height: "32px",
            fontSize: "12px",
            fontWeight: "bold",
            p: "8px",
          }}
        >
          メール再送
        </Button>
      );
    },
  });
  return headers;
};

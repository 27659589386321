import { ChangeEvent, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SelectChangeEvent, Button, Select, MenuItem } from "@mui/material";
import { GridColumnVisibilityModel } from "@mui/x-data-grid/hooks/features/columns/gridColumnsInterfaces";
import {
  GridRowParams,
  GridColDef,
  GridSortModel,
  GridRowId,
  GridRenderCellParams,
  GridCellParams,
} from "@mui/x-data-grid-pro";
import { StatusTypeLabel } from "components/label/status-type-label";
import { CustomDataGridPro } from "components/molecules/custom-data-grid-pro";
import { initialPageInfo } from "data-access/repositories/page_info/page_info.dto";
import {
  ProjectCollection,
  ProjectId,
  ProjectIndexRequest,
  ProjectUpdateRequest,
} from "data-access/repositories/project/project.dto";
import { projectRepository } from "data-access/repositories/project/project.repository";
import {
  ProjectStatusType,
  ProjectStatusTypeId,
} from "data-access/repositories/project_status_type/project_status_type.dto";
import { theme } from "extensions/theme";
import { useAppSelector, useAppDispatch } from "store/hooks";
import { mainOperations } from "store/main/operations";
import { selectMain } from "store/main/slice";
import useSWR, { mutate } from "swr";
import { API_PATHS } from "utils/apiPaths";
import { convertKeysToCamelCase } from "utils/convertObjectKeyCase";
import { downloadDataGridRowsCsv } from "utils/downloadDataGridRowsCsv";
import { handleReduxError } from "utils/errorHandler";
import { TotalBillingFormModal } from "./total-billing-create-modal";

type ProjectTableProps = {
  defaultHeaders: GridColDef[];
  setIsSidebarOpen: (isOpen: boolean) => void;
  setCurrentPage: (page: number) => void;
  setRowsPerPage: (rowsPerPage: number) => void;
  searchQuery: (page?: number) => ProjectIndexRequest;
};

export const ProjectTable = (props: ProjectTableProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const mainState = useAppSelector(selectMain);
  const orderStorageKeyName = "projectTableColumnOrder";
  const sortStorageKeyName = "projectTableColumnSort";
  const visibilityStorageKeyName = "projectTableColumnVisibility";
  const rowsPerPageLocalStorageKey = "projectTableRowsPerPage";
  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({});

  const { data: projects, isValidating: isLoading } = useSWR(
    [API_PATHS.getProjects(), props.searchQuery().page],
    () => projectRepository.index(props.searchQuery()),
    {
      keepPreviousData: true,
    },
  );

  /**
   * セルのレンダリングが追加で必要な場合にここで定義する。
   *
   * @param headers ヘッダー群
   * @param projectStatuses 取得されている案件ステータス一覧.
   * @returns レンダリング後のヘッダー群
   *
   */
  const addCustomRenderCell = (
    headers: GridColDef[],
    projectStatuses: ProjectStatusType[] | undefined,
  ) => {
    const resultHeaders: GridColDef[] = [];
    headers.forEach((header) => {
      switch (header.field) {
        case "project_status_type_name":
          header.renderCell = (params: GridRenderCellParams) => {
            return (
              <ProjectStatusSelectBox
                params={params}
                projectStatuses={projectStatuses}
                searchQuery={props.searchQuery}
              />
            );
          };
      }
      resultHeaders.push(header);
    });
    return resultHeaders;
  };

  const columns = useMemo(() => {
    const localStorageItem = localStorage.getItem(orderStorageKeyName);
    const visibilityLocalStorageItem = localStorage.getItem(visibilityStorageKeyName);
    visibilityLocalStorageItem && setColumnVisibilityModel(JSON.parse(visibilityLocalStorageItem));

    let resultHeaders: GridColDef[] = [];
    if (localStorageItem === null) {
      resultHeaders = props.defaultHeaders;
    } else {
      const orderColumns: [{ field: string; hide: boolean }] = JSON.parse(localStorageItem);
      orderColumns.forEach((column) => {
        const header = props.defaultHeaders.find((header) => header.field === column.field);
        if (header) {
          const updatedHeader = {
            ...header,
            hide: columnVisibilityModel[header.field] === false,
          };
          resultHeaders.push(updatedHeader);
        }
      });
      const remainingHeaders = props.defaultHeaders.filter(
        (header) => !resultHeaders.some((h) => h.field === header.field),
      );
      // 残りのヘッダーにも表示/非表示の状態を反映
      const updatedRemainingHeaders = remainingHeaders.map((header) => ({
        ...header,
        hide: columnVisibilityModel[header.field] === false,
      }));
      resultHeaders.push(...updatedRemainingHeaders);
    }
    // 追加のカスタムレンダリングが必要な場合はここで追加
    resultHeaders = addCustomRenderCell(resultHeaders, mainState.projectStatusTypes);
    return resultHeaders;
  }, [localStorage, mainState.projectStatusTypes, props.defaultHeaders]);

  const initialState = useMemo(() => {
    const defaultState = {
      pinnedColumns: { left: ["__check__", "code", "name"] },
      sorting: {},
    };
    const localStorageItem = localStorage.getItem(sortStorageKeyName);
    if (localStorageItem !== null) {
      const sortItem: { field: string; sort: string } = JSON.parse(localStorageItem);
      defaultState.sorting = {
        sortModel: [{ field: sortItem.field, sort: sortItem.sort }],
      };
    }
    return defaultState;
  }, []);

  const handleRowClick = (params: GridRowParams) => {
    navigate(`/projects/${params.id}`);
    props.setIsSidebarOpen(true);
  };

  const handleSelectPage = (e: SelectChangeEvent<unknown>) => {
    props.setCurrentPage(e.target.value as number);
  };

  const handleChangePage = (_: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    props.setCurrentPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ): void => {
    props.setRowsPerPage(Number(e.target.value));
    props.setCurrentPage(1);
    localStorage.setItem(rowsPerPageLocalStorageKey, e.target.value);
  };

  const setColumnOrder = (allColumns: GridColDef[]) => {
    const columns = allColumns.map((column) => {
      return { field: column.field };
    });
    localStorage.setItem(orderStorageKeyName, JSON.stringify(columns));
  };

  const handleColumnOrderChange = (allColumns: GridColDef[]) => {
    setColumnOrder(allColumns);
  };
  const handleColumnVisibilityModelChange = (model: GridColumnVisibilityModel) => {
    setColumnVisibilityModel(model);
    localStorage.setItem(visibilityStorageKeyName, JSON.stringify(model));
  };

  const handleSortModelChange = (sortItems: GridSortModel) => {
    if (sortItems.length > 0) {
      localStorage.setItem(sortStorageKeyName, JSON.stringify(sortItems[0]));
    } else if (sortItems.length === 0) {
      localStorage.removeItem(sortStorageKeyName);
    }
    mutate([API_PATHS.getProjects(), props.searchQuery().page]);
  };

  // 最初にチェックされた案件を保持する(複数選択時に同じ顧客の案件のみ選択可能にするため)
  const firstCheckedProject = useRef<ProjectCollection | undefined>();
  const [isCreateButtonDisabled, setIsCreateButtonDisabled] = useState<boolean>(false);
  const [selectedRowCount, setSelectedRowCount] = useState<number>(0);
  const [selectedRowIds, setSelectedRowIds] = useState<GridRowId[]>([]);

  const [isBillingModalOpen, setIsBillingModalOpen] = useState<boolean>(false);
  const [selectedProjects, setSelectedProjects] = useState<ProjectCollection[]>([]);

  const handleCheck = (projects: ProjectCollection[]) => {
    setSelectedRowCount(projects.length);
    setSelectedProjects(projects);
    setSelectedRowIds(projects.map((project) => project.id));
    // 複数選択のチェックが入ったときに顧客が異なる案件があれば請求作成ボタンをdisabledにする
    if (projects.length > 1) {
      const firstClientId = projects[0].client?.id;
      for (let i = 1; i < projects.length; i++) {
        if (projects[i].client?.id !== firstClientId) {
          setIsCreateButtonDisabled(true);
          break;
        }
        setIsCreateButtonDisabled(false);
      }
      return;
    }
    // 全件選択のチェックを外したとき
    if (projects.length === 0) {
      setIsCreateButtonDisabled(false);
      firstCheckedProject.current = projects.length > 0 ? projects[0] : undefined;
      return;
    }
    // 通常のチェックのとき
    firstCheckedProject.current = projects.length > 0 ? projects[0] : undefined;
  };

  const rowSelectable = (params: GridRowParams<ProjectCollection>) => {
    let isClientDifferent: boolean = false;

    // 最初にチェックされた案件の顧客と異なる場合は選択不可
    if (firstCheckedProject.current) {
      isClientDifferent = params.row.client?.id !== firstCheckedProject.current?.client?.id;
    }
    // 顧客が未登録の場合は選択不可
    if (!params.row.client || isClientDifferent) return false;

    return true;
  };

  const handleCreateBilling = () => {
    setIsBillingModalOpen(true);
  };

  const handleCancel = () => {
    setSelectedRowCount(0);
    setSelectedRowIds([]);
    firstCheckedProject.current = undefined;
  };

  const handleClickDownloadCsv = async () => {
    dispatch(mainOperations.updateIsLoading(true));
    try {
      const res = await projectRepository.download({
        projectTypeIds: props.searchQuery().projectTypeIds,
        projectStatusTypeIds: props.searchQuery().projectStatusTypeIds,
        startInquiredDate: props.searchQuery().startInquiredDate,
        endInquiredDate: props.searchQuery().endInquiredDate,
        startOrderedDate: props.searchQuery().startOrderedDate,
        endOrderedDate: props.searchQuery().endOrderedDate,
        startCompletedDate: props.searchQuery().startCompletedDate,
        endCompletedDate: props.searchQuery().endCompletedDate,
        keyword: props.searchQuery().keyword,
        billingConditions: props.searchQuery().billingConditions,
      });
      const csvColumnDefinitions = [...columns];
      downloadDataGridRowsCsv("project", res, csvColumnDefinitions, columnVisibilityModel);
      dispatch(mainOperations.updateSuccessMessage("案件csvをダウンロードしました"));
    } catch (error) {
      handleReduxError(error, dispatch, "案件csvのダウンロードに失敗しました");
    } finally {
      dispatch(mainOperations.updateIsLoading(false));
    }
  };

  /**
   * セル編集時の処理.
   * @param id rowのid, ここではProjectId.
   * @param updatedRowParams 編集された項目のkey:value.
   */
  const handleCellEdited = async (id: number, updatedRowParams: { [key: string]: any }) => {
    if (Object.keys(updatedRowParams).length === 0) return;
    dispatch(mainOperations.updateIsLoading(true));
    try {
      await projectRepository.update(
        id as ProjectId,
        {
          ...convertKeysToCamelCase(updatedRowParams),
        } as ProjectUpdateRequest,
      );
      dispatch(mainOperations.updateSuccessMessage("案件を更新しました"));
      mutate([API_PATHS.getProjects(), props.searchQuery().page]);
    } catch (error) {
      handleReduxError(error, dispatch, "案件の更新に失敗しました");
    } finally {
      dispatch(mainOperations.updateIsLoading(false));
    }
  };

  // 案件ステータスのセルをクリックした時だけ、無反応（詳細画面に遷移しない）にする
  const handleCellClick = (params: GridCellParams, event: React.MouseEvent) => {
    if (params.field !== "project_status_type_name") return;
    event.stopPropagation();
  };

  return (
    <>
      {selectedProjects.length > 0 && (
        <TotalBillingFormModal
          isOpen={isBillingModalOpen}
          setIsOpen={setIsBillingModalOpen}
          projects={selectedProjects}
        />
      )}
      {selectedRowCount > 0 && (
        <div style={{ marginBottom: "12px" }}>
          <Button
            variant="contained"
            onClick={handleCreateBilling}
            sx={{ width: "190px", fontWeight: "bold" }}
            disabled={isCreateButtonDisabled || selectedRowCount === 0}
          >
            {selectedRowCount}件から請求を新規作成
          </Button>
          <Button sx={{ color: theme.palette.grayScale[700], ml: "12px" }} onClick={handleCancel}>
            キャンセル
          </Button>
        </div>
      )}
      <CustomDataGridPro
        type="project"
        editMode="cell"
        columns={columns}
        rows={projects?.data || []}
        loading={isLoading}
        pageInfo={projects?.pagination || initialPageInfo}
        currentPage={projects?.pagination.page || 0}
        tableHeight={selectedRowCount > 0 ? "70vh" : "75vh"}
        handleDownloadButton={handleClickDownloadCsv}
        handleSelectPage={handleSelectPage}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleRowClick={handleRowClick}
        handleCellClick={handleCellClick}
        initialState={initialState}
        handleColumnOrderChange={handleColumnOrderChange}
        handleColumnVisibilityModelChange={handleColumnVisibilityModelChange}
        handleSortModelChange={handleSortModelChange}
        handleCellEdited={handleCellEdited}
        columnVisibilityModel={columnVisibilityModel}
        isCheckbox
        rowSelectableFunc={(params: GridRowParams) => rowSelectable(params)}
        handleCheck={handleCheck}
        rowSelection={selectedRowIds}
      />
    </>
  );
};

interface ProjectStatusSelectBoxProps {
  params: GridRenderCellParams;
  projectStatuses: ProjectStatusType[] | undefined;
  searchQuery: (page?: number) => ProjectIndexRequest;
}

const ProjectStatusSelectBox = (props: ProjectStatusSelectBoxProps) => {
  const dispatch = useAppDispatch();

  const handleChangeProjectStatus = async (event: SelectChangeEvent<any>, projectId: ProjectId) => {
    event.stopPropagation();
    dispatch(mainOperations.updateIsLoading(true));
    try {
      const selectedProjectStatusTypeId = event.target.value as ProjectStatusTypeId;
      await projectRepository.update(projectId, {
        projectStatusTypeId: selectedProjectStatusTypeId,
      });
      dispatch(mainOperations.updateSuccessMessage("案件ステータスを変更しました。"));
      mutate([API_PATHS.getProjects(), props.searchQuery().page]);
    } catch (error) {
      handleReduxError(error, dispatch, "案件ステータスの変更に失敗しました");
    } finally {
      dispatch(mainOperations.updateIsLoading(false));
    }
  };

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Select
        value={props.params.row.projectStatusType.id}
        variant="standard"
        onChange={(e) => handleChangeProjectStatus(e, props.params.row.id)}
        displayEmpty
        sx={{
          "&.MuiInputBase-root:before": {
            borderBottom: "none",
          },
          width: "100%",
        }}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        }}
      >
        {props.projectStatuses &&
          props.projectStatuses.map((projectStatusType: ProjectStatusType, index: number) => (
            <MenuItem value={projectStatusType.id} key={index}>
              <StatusTypeLabel
                statusType={projectStatusType.name}
                color={projectStatusType.colorNumber}
                sx={{ height: "auto", p: "3px 0", minWidth: "90px" }}
              />
            </MenuItem>
          ))}
      </Select>
    </div>
  );
};

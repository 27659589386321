import { Typography } from "@mui/material";
import Logo from "images/logo_fill_navy.svg";
import SignIn1 from "images/sign_in_1.svg";
import SignIn2 from "images/sign_in_2.svg";
import SignIn3 from "images/sign_in_3.svg";
import "swiper/css";
import "swiper/css/pagination";
import "./swiper-styles.css";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

export const WalkThrough = () => {
  return (
    <Swiper pagination={true} modules={[Pagination]}>
      <SwiperSlide
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img src={Logo} alt="logo" style={{ width: "170px", marginBottom: "10px" }} />
        <Typography fontSize="16px" fontWeight="bold" mb="16px">
          誰でもどこでも
          <br />
          現場情報を一元管理！
        </Typography>
        <img
          src={SignIn1}
          alt="logo"
          style={{ width: "300px", objectFit: "cover", marginBottom: "12px" }}
        />
      </SwiperSlide>
      <SwiperSlide
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography fontSize="16px" fontWeight="bold" mb="16px">
          会社や事業所ごとに
          <br />
          Hub (ワークスペース) を持ちます
        </Typography>
        <img
          src={SignIn2}
          alt="logo"
          style={{ width: "280px", objectFit: "cover", marginBottom: "12px" }}
        />
      </SwiperSlide>
      <SwiperSlide
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography fontSize="16px" fontWeight="bold" mb="32px">
          Hub同士はコネクトでき、
          <br />
          情報を共有することができます
        </Typography>
        <img
          src={SignIn3}
          alt="logo"
          style={{ width: "280px", objectFit: "cover", marginBottom: "12px" }}
        />
      </SwiperSlide>
    </Swiper>
  );
};

interface ClientInfo {
  postalCode: string;
  address: string;
  addressSecond: string;
}

export const mailingAddress = (client: ClientInfo): string => {
  return `〒${client.postalCode}
${client.address}
${client.addressSecond}`;
};

import { camelToSnakeCase } from "utils/convertObjectKeyCase";
import {
  BillingIndexRequest,
  BillingIndexResponse,
  BillingCreateRequest,
  BillingCreateResponse,
  BillingShowResponse,
  BillingUpdateRequest,
  BillingUpdateResponse,
  BillingId,
  Billing,
} from "./billing.dto";
import { ApiClient } from "../../ApiClient";

export const billingRepository = {
  index(query: BillingIndexRequest): Promise<BillingIndexResponse> {
    const params = {
      start_billing_date: query.startBillingDate,
      end_billing_date: query.endBillingDate,
      created_by_id: query.createdById,
      keyword: query.keyword,
      status_type: query.statusType,
      billing_file_create_status_type: query.billingFileCreateStatusType,
      warranty_create_status_type: query.warrantyCreateStatusType,
      deposit_status_types: query.depositStatusTypes,
      sort: query.sort ? camelToSnakeCase(query.sort) : undefined,
      direction: query.direction,
      page: query.page || 1,
      items: query.rowsPerPage,
    };
    return ApiClient.get("/api/v1/billings", { params }).then((res) => res.data);
  },
  create(body: BillingCreateRequest): Promise<BillingCreateResponse> {
    const config = { headers: { "content-type": "application/json" } };
    const requestBody = {
      project_billings: body.projectBillings.map((projectBilling) => ({
        project_id: projectBilling.projectId,
        amount: projectBilling.amount,
        tax: projectBilling.tax,
      })),
      status_type: body.statusType,
      code: body.code,
      subject: body.subject,
      mailing_name: body.mailingName,
      mailing_address: body.mailingAddress,
      billing_date: body.billingDate,
      note: body.note,
      is_warranty_attach: body.isWarrantyAttach,
    };
    return ApiClient.post("/api/v1/billings", requestBody, config).then((res) => res.data);
  },
  show(id: BillingId): Promise<BillingShowResponse> {
    return ApiClient.get(`/api/v1/billings/${id}`).then((res) => res.data);
  },
  update(id: BillingId, body: BillingUpdateRequest): Promise<BillingUpdateResponse> {
    const config = { headers: { "content-type": "application/json" } };
    const requestBody = {
      subject: body.subject,
      client_id: body.clientId === 0 ? null : body.clientId,
      code: body.code,
      mailing_name: body.mailingName,
      mailing_address: body.mailingAddress,
      billing_date: body.billingDate,
      tax_included_billing_amount: body.taxIncludedBillingAmount,
      adjusted_amount: body.adjustedAmount,
      note: body.note,
      status_type: body.statusType,
      is_warranty_attach: body.isWarrantyAttach,
    };
    return ApiClient.put(`/api/v1/billings/${id}`, requestBody, config).then((res) => res.data);
  },
  downloadAll(query: BillingIndexRequest): Promise<Billing[]> {
    const params = {
      start_billing_date: query.startBillingDate,
      end_billing_date: query.endBillingDate,
      created_by_id: query.createdById,
      keyword: query.keyword,
      status_type: query.statusType,
      billing_file_create_status_type: query.billingFileCreateStatusType,
      warranty_create_status_type: query.warrantyCreateStatusType,
      deposit_status_types: query.depositStatusTypes,
    };
    return ApiClient.get("/api/v1/billings/all", { params }).then((res) => res.data);
  },
  downloadTemplate(id: BillingId): Promise<Blob> {
    return ApiClient.get(`/api/v1/billings/${id}/download_template`, {
      responseType: "blob",
    }).then((res) => res.data);
  },
  destroy(id: BillingId): Promise<void> {
    return ApiClient.delete(`/api/v1/billings/${id}`).then((res) => res.data);
  },
};

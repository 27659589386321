import { theme } from "extensions/theme";

export const styles = {
  block: {
    border: `1px solid ${theme.palette.grayScale[300]}`,
    borderRadius: "4px",
    padding: "16px",
    width: "100%",
    flexDirection: "column",
    display: "flex",
    cursor: "pointer",
    mb: "16px",
  },
  image: {
    width: "76px",
    height: "76px",
    maxWidth: "76px",
    maxHeight: "76px",
    borderRadius: "5px",
    ml: "16px",
  },
  member: {
    fontSize: "12px",
    mb: "8px",
    textOverflow: "ellipsis",
    overflow: "hidden",
    display: "-webkit-box",
    "-webkit-box-orient": "vertical",
    "-webkit-line-clamp": "2",
  },
  content: {
    fontSize: "12px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
  },
};

import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Alert, Box, SelectChangeEvent, Typography } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { CsvImportStatusButton } from "components/button/csv-import-status-button";
import { CsvImportStatusLabel } from "components/label/csv-import-status-label";
import { CustomDataGrid } from "components/molecules/custom-data-grid";
import { FileDropzone } from "components/molecules/file-dropzone";
import { initialPageInfo } from "data-access/repositories/page_info/page_info.dto";
import { theme } from "extensions/theme";
import { csvImportScheduleRepository } from "features/hub-settings/api/csv-import/csv_import_schedule.repository";
import { CsvImportStatus } from "features/hub-settings/types/csv-import/csv_import_project.dto";
import { CsvImportSchedule } from "features/hub-settings/types/csv-import/csv_import_schedule.dto";
import { useBlockOperationsWhileUploading } from "hooks/useBlockOperationsWhileUploading";
import { useAppDispatch } from "store/hooks";
import { mainOperations } from "store/main/operations";
import useSWR from "swr";
import { API_PATHS } from "utils/apiPaths";
import { CSV_IMPORT_IN_PROGRESS_STATUS } from "utils/constant";
import { handleReduxError } from "utils/errorHandler";
import { DATE_TIME_SLASH_FORMAT, formatDateUtil } from "utils/formatDateUtil";

export const Schedule = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [page, setPage] = useState<number>(1);

  const { blockOperationsWhileUpload, releaseBlockOperationsWhileUpload } =
    useBlockOperationsWhileUploading();

  const { data, isValidating } = useSWR(API_PATHS.getScheduleImportJobs(page), () =>
    csvImportScheduleRepository.index(page),
  );
  const { data: importLatest } = useSWR(
    API_PATHS.getScheduleImportJobsLatest(),
    csvImportScheduleRepository.latest,
    {
      revalidateOnFocus: false,
    },
  );

  const handleSelectPage = (e: SelectChangeEvent<unknown>) => {
    setPage(Number(e.target.value));
  };

  const handleChangePage = (_: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage + 1);
  };

  const handleUpload = async (files: File[]) => {
    blockOperationsWhileUpload();
    dispatch(mainOperations.updateIsLoading(true));
    try {
      const res = await csvImportScheduleRepository.putPresignedUrl(files[0]);
      navigate("/hub-settings/csv-imports/schedule/mapping", {
        state: { url: res.url, objectKey: res.objectKey, fileName: files[0].name },
      });
    } catch (error) {
      handleReduxError(error, dispatch, "csvのアップロードに失敗しました");
    } finally {
      releaseBlockOperationsWhileUpload();
      dispatch(mainOperations.updateIsLoading(false));
    }
  };
  return (
    <>
      <Alert severity="info" sx={{ "&.MuiPaper-rounded": { alignItems: "center" } }}>
        インポート中も予定の新規作成が可能です。
        <br />
        予定100件のインポートには、約10秒かかります。
      </Alert>

      <Box sx={{ my: "20px" }}>
        <Typography fontWeight="bold">CSVファイルのアップロード</Typography>
        <FileDropzone
          onDrop={handleUpload}
          allowMultiple={false}
          accept={{ "text/csv": [".csv"] }}
          validateEncoding={true}
          sx={{ my: "12px" }}
          isDisable={importLatest && CSV_IMPORT_IN_PROGRESS_STATUS.includes(importLatest.status)}
        />
        <Typography fontSize={11}>ファイル形式はcsv、文字コードはUTF-8のみ有効です</Typography>
      </Box>
      <Box>
        <Typography fontWeight="bold" mb="16px">
          インポート履歴
        </Typography>
        <CustomDataGrid
          columns={columns()}
          rows={data?.data || []}
          loading={isValidating}
          pageInfo={data?.pagination || initialPageInfo}
          currentPage={page}
          handleSelect={handleSelectPage}
          handleChangePage={handleChangePage}
          tableHeight="400px"
        />
      </Box>
    </>
  );
};

const columns = () => {
  const headers = [];
  headers.push({
    field: "filename",
    headerName: "ファイル名",
    minWidth: 240,
    sortable: false,
  });
  headers.push({
    field: "status",
    headerName: "ステータス",
    minWidth: 120,
    sortable: false,
    renderCell: (params: GridRenderCellParams<CsvImportSchedule>) => (
      <CsvImportStatusLabel status={params.row.status as CsvImportStatus} />
    ),
  });
  headers.push({
    field: "button",
    headerName: "",
    sortable: false,
    width: 180,
    disableClickEventBubbling: true,
    renderCell: (params: GridRenderCellParams<CsvImportSchedule>) => (
      <CsvImportStatusButton
        id={params.row.id}
        moduleName="schedule"
        fileName={params.row.filename}
        status={params.row.status as CsvImportStatus}
        failedCount={params.row.failedCount}
      />
    ),
  });
  headers.push({
    field: "totalCount",
    headerName: "総数",
    minWidth: 80,
    sortable: false,
    headerAlign: "left" as const,
    align: "right" as const,
    type: "number" as const,
    renderCell: (params: GridRenderCellParams<CsvImportSchedule>) => (
      <Typography
        sx={{
          fontSize: "14px",
          color: params.row.failedCount > 0 ? theme.palette.error.main : "inherit",
        }}
      >
        {params.row.totalCount}
      </Typography>
    ),
  });
  headers.push({
    field: "successCount",
    headerName: "完了",
    minWidth: 80,
    sortable: false,
    headerAlign: "left" as const,
    align: "right" as const,
    type: "number" as const,
    renderCell: (params: GridRenderCellParams<CsvImportSchedule>) => (
      <Typography
        sx={{
          fontSize: "14px",
          color: params.row.failedCount > 0 ? theme.palette.error.main : "inherit",
        }}
      >
        {params.row.createdCount}
      </Typography>
    ),
  });
  headers.push({
    field: "failedCount",
    headerName: "失敗",
    minWidth: 80,
    sortable: false,
    headerAlign: "left" as const,
    align: "right" as const,
    type: "number" as const,
    renderCell: (params: GridRenderCellParams<CsvImportSchedule>) => (
      <Typography
        sx={{
          fontSize: "14px",
          color: params.row.failedCount > 0 ? theme.palette.error.main : "inherit",
        }}
      >
        {params.row.failedCount}
      </Typography>
    ),
  });
  headers.push({
    field: "createdAt",
    headerName: "インポート開始",
    minWidth: 140,
    sortable: false,
    renderCell: (params: GridRenderCellParams<CsvImportSchedule>) => (
      <Typography sx={{ fontSize: "14px" }}>
        {formatDateUtil(params.row.createdAt, DATE_TIME_SLASH_FORMAT)}
      </Typography>
    ),
  });
  headers.push({
    field: "importedUserName",
    headerName: "実施者",
    minWidth: 150,
    sortable: false,
  });
  return headers;
};

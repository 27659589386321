import { theme } from "extensions/theme";

export const styles = {
  reportContent: {
    m: "16px",
    "& .MuiTable": {
      border: `1px solid ${theme.palette.grayScale[700]}`,
    },
    "& .MuiTableCell-root": {
      border: `1px solid ${theme.palette.grayScale[700]}`,
      fontSize: "1.1rem",
    },
    "& th": {
      width: "14rem",
    },
    "& .MuiSvgIcon-root.MuiSelect-icon": {
      display: "none",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "& .MuiSelect-select.MuiInputBase-input.MuiInput-input": {
      paddingRight: "5px",
    },
  },
  canInput: {
    backgroundColor: "yellow",
  },
};

import { ObjectKeys } from "types/objectKeys";
import { AccountReference, UserId } from "../account/account.dto";
import { Brand } from "../brand";
import { PageInfo } from "../page_info/page_info.dto";
import { PhotoResponse } from "../project/photo/photo.dto";
import { ProjectId } from "../project/project.dto";
import { ScheduleId } from "../schedule/schedule.dto";

export type WorkReportId = Brand<string, "WorkReportId">;

export interface WorkReport extends ObjectKeys {
  id: WorkReportId;
  projectId: ProjectId;
  projectCode: string;
  projectName: string;
  projectIsInvited: boolean;
  projectIsInviting: boolean;
  workStatusType: string;
  workStatusTypeI18n: string;
  startTime: string;
  endTime: string;
  content: string;
  siteName: string;
  workingDate: string;
  siteManager: AccountReference | null;
  createdBy: AccountReference;
  users: AccountReference[];
  createdDate: string;
  urlKey: string;
  headerPhotos: PhotoResponse[];
}

export interface WorkReportIndexRequest {
  startedDate?: string;
  endedDate?: string;
  userIds?: UserId[];
  keyword?: string;
  page?: number;
  rowsPerPage?: number;
}

export interface WorkReportIndexResponse {
  data: WorkReport[];
  pagination: PageInfo;
}

export interface WorkReportCreateRequest extends ObjectKeys {
  projectId?: ProjectId;
  scheduleId?: ScheduleId;
  startTime?: string;
  endTime?: string;
  content?: string;
  siteManagerId?: UserId;
  userIds?: UserId[];
  userNames?: string[];
  siteName?: string;
  workStatusType?: string;
}

export interface WorkReportUpdateRequest extends ObjectKeys {
  startTime?: string;
  endTime?: string;
  content?: string;
  siteManagerId?: UserId;
  userIds?: UserId[];
  userNames?: string[];
  siteName?: string;
  workStatusType?: string;
}

export interface WorkReportState {
  siteName: string;
  userIds: UserId[];
  users: { userId: UserId; name: string }[];
  siteManagerId: UserId;
  startTime: string;
  endTime: string;
  content: string;
  workStatusType: string;
  createdDate: string;
  createdBy: AccountReference | undefined;
}

export const initialState = (startTime: Date, endTime: Date): WorkReportState => {
  return {
    siteName: "",
    users: [],
    userIds: [],
    siteManagerId: 0 as UserId,
    startTime: startTime.toString(),
    endTime: endTime.toString(),
    content: "",
    workStatusType: "in_progress",
    createdDate: "",
    createdBy: undefined,
  };
};

import { useRef, useState } from "react";
import { RefObject } from "@fullcalendar/core/preact";
import { CircularProgress, Divider, Stack, styled, Switch, Typography } from "@mui/material";
import { LoadingOverlay } from "components/loadingOverlay";
import { noticeOvertimeRecordRepository } from "data-access/repositories/notice/overtime-record/overtime_record.repository";
import { theme } from "extensions/theme";
import { AlertType } from "features/attendance/types/overtime_record/attendance.employee.dto";
import { useAppDispatch } from "store/hooks";
import { mainOperations } from "store/main/operations";
import useSWR from "swr";
import { handleReduxError } from "utils/errorHandler";
import { styles } from "./styles";

const STypography = styled(Typography)({
  fontSize: "14px",
  fontWeight: "500",
});

export const OvertimeRecord = () => {
  const dispatch = useAppDispatch();
  const classes = styles();
  const [isFetchLoading, setIsFetchLoading] = useState<boolean>(false);
  const fetchIndexKey = "/api/v1/notice/overtime_records/setting";

  const monthlyOvertimeHoursHeaderRef = useRef<HTMLDivElement>(null);
  const specialClauseOccurrencesHeaderRef = useRef<HTMLDivElement>(null);
  const monthlyOvertimeAndHolidayHoursHeaderRef = useRef<HTMLDivElement>(null);
  const annualOvertimeHoursHeaderRef = useRef<HTMLDivElement>(null);
  const averageMonthlyOvertimeAndHolidayHoursHeaderRef = useRef<HTMLDivElement>(null);

  const { data, mutate, isValidating } = useSWR(
    fetchIndexKey,
    noticeOvertimeRecordRepository.index,
    {
      revalidateOnFocus: false,
    },
  );

  const handleToggle = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setIsFetchLoading(true);
    try {
      await noticeOvertimeRecordRepository.update({
        [name]: checked,
      });
      mutate();
      dispatch(mainOperations.updateSuccessMessage("通知ルールを更新しました"));
    } catch (error) {
      handleReduxError(error, dispatch, "通知ルールの更新に失敗しました");
    } finally {
      setIsFetchLoading(false);
    }
  };

  // スクロールを実行する関数
  const scrollToSection = (ref: RefObject<HTMLElement>) => {
    if (ref.current) {
      ref.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  if (!data) {
    return <CircularProgress />;
  }

  return (
    <>
      <LoadingOverlay isLoading={isFetchLoading || isValidating} />

      <div style={{ display: "flex" }}>
        <Stack spacing={2} sx={{ width: "160px", mr: "20px" }}>
          <Typography
            className={classes.menuItem}
            onClick={() => scrollToSection(monthlyOvertimeHoursHeaderRef)}
          >
            1ヶ月の法定外労働時間
          </Typography>
          <Typography
            className={classes.menuItem}
            onClick={() => scrollToSection(specialClauseOccurrencesHeaderRef)}
          >
            特別条項該当回数
          </Typography>
          <Typography
            className={classes.menuItem}
            onClick={() => scrollToSection(monthlyOvertimeAndHolidayHoursHeaderRef)}
          >
            1ヶ月の法定外・休日労働時間
          </Typography>
          <Typography
            className={classes.menuItem}
            onClick={() => scrollToSection(annualOvertimeHoursHeaderRef)}
          >
            1年の法定外労働時間
          </Typography>
          <Typography
            className={classes.menuItem}
            onClick={() => scrollToSection(averageMonthlyOvertimeAndHolidayHoursHeaderRef)}
          >
            法定外・休日労働時間の複数月平均
          </Typography>
        </Stack>

        <div style={{ overflow: "scroll", height: "70vh", flex: 1 }}>
          <div ref={monthlyOvertimeHoursHeaderRef} style={{ marginBottom: "48px" }}>
            <Typography sx={{ fontWeight: "bold", mb: "16px" }}>1ヶ月の法定外労働時間</Typography>
            <div className={classes.content}>
              <AlertBox alertType="principle_warning" title="35時間" />
              <STypography>に達すると</STypography>
              <AlertBox alertType="principle_warning" title="茶色いアラート" />
              <Switch
                name="isEnableMonthlyOvertimeHoursPrincipleWarning"
                checked={data?.isEnableMonthlyOvertimeHoursPrincipleWarning}
                onChange={handleToggle}
                color="primary"
              />
            </div>
            <Divider sx={{ my: "16px" }} />
            <div className={classes.content}>
              <AlertBox alertType="principle_violation" title="45時間" />
              <STypography>に超えると</STypography>
              <AlertBox alertType="principle_violation" title="オレンジのアラート" />
              <Switch
                name="isEnableMonthlyOvertimeHoursPrincipleViolation"
                checked={data?.isEnableMonthlyOvertimeHoursPrincipleViolation}
                onChange={handleToggle}
                color="primary"
              />
            </div>
            <Divider sx={{ mt: "16px" }} />
          </div>

          <div ref={specialClauseOccurrencesHeaderRef} style={{ marginBottom: "48px" }}>
            <Typography sx={{ fontWeight: "bold", mb: "16px" }}>特別条項該当回数</Typography>
            <div className={classes.content}>
              <AlertBox alertType="exception_warning" title="6回" />
              <STypography>に達すると</STypography>
              <AlertBox alertType="exception_warning" title="黄色いアラート" />
              <Switch
                name="isEnableSpecialClauseOccurrencesExceptionWarning"
                checked={data?.isEnableSpecialClauseOccurrencesExceptionWarning}
                onChange={handleToggle}
                color="primary"
              />
            </div>
            <Divider sx={{ my: "16px" }} />
            <div className={classes.content}>
              <AlertBox alertType="exception_violation" title="7回" />
              <STypography>に達すると</STypography>
              <AlertBox alertType="exception_violation" title="赤いアラート" />
              <Switch
                name="isEnableSpecialClauseOccurrencesExceptionViolation"
                checked={data?.isEnableSpecialClauseOccurrencesExceptionViolation}
                onChange={handleToggle}
                color="primary"
              />
            </div>
            <Divider sx={{ mt: "16px" }} />
          </div>

          <div ref={monthlyOvertimeAndHolidayHoursHeaderRef} style={{ marginBottom: "48px" }}>
            <Typography sx={{ fontWeight: "bold", mb: "16px" }}>
              1ヶ月の法定外・休日労働時間
            </Typography>
            <div className={classes.content}>
              <AlertBox alertType="exception_warning" title="80時間" />
              <STypography>に達すると</STypography>
              <AlertBox alertType="exception_warning" title="黄色いアラート" />
              <Switch
                name="isEnableOvertimeAndHolidayHoursExceptionWarning"
                checked={data?.isEnableOvertimeAndHolidayHoursExceptionWarning}
                onChange={handleToggle}
                color="primary"
              />
            </div>
            <Divider sx={{ my: "16px" }} />
            <div className={classes.content}>
              <AlertBox alertType="exception_violation" title="100時間" />
              <STypography>に達すると</STypography>
              <AlertBox alertType="exception_violation" title="赤いアラート" />
              <Switch
                name="isEnableOvertimeAndHolidayHoursExceptionViolation"
                checked={data?.isEnableOvertimeAndHolidayHoursExceptionViolation}
                onChange={handleToggle}
                color="primary"
              />
            </div>
            <Divider sx={{ mt: "16px" }} />
          </div>

          <div ref={annualOvertimeHoursHeaderRef} style={{ marginBottom: "48px" }}>
            <Typography sx={{ fontWeight: "bold", mb: "16px" }}>1年の法定外労働時間</Typography>
            <div className={classes.content}>
              <AlertBox alertType="principle_warning" title="330時間" />
              <STypography>に達すると</STypography>
              <AlertBox alertType="principle_warning" title="茶色いアラート" />
              <Switch
                name="isEnableAnnualOvertimeHoursPrincipleWarning"
                checked={data?.isEnableAnnualOvertimeHoursPrincipleWarning}
                onChange={handleToggle}
                color="primary"
              />
            </div>
            <Divider sx={{ my: "16px" }} />
            <div className={classes.content}>
              <AlertBox alertType="principle_violation" title="360時間" />
              <STypography>に達すると</STypography>
              <AlertBox alertType="principle_violation" title="オレンジのアラート" />
              <Switch
                name="isEnableAnnualOvertimeHoursPrincipleViolation"
                checked={data?.isEnableAnnualOvertimeHoursPrincipleViolation}
                onChange={handleToggle}
                color="primary"
              />
            </div>
            <Divider sx={{ my: "16px" }} />
            <div className={classes.content}>
              <AlertBox alertType="exception_warning" title="560時間" />
              <STypography>に達すると</STypography>
              <AlertBox alertType="exception_warning" title="黄色いアラート" />
              <Switch
                name="isEnableAnnualOvertimeHoursExceptionWarning"
                checked={data?.isEnableAnnualOvertimeHoursExceptionWarning}
                onChange={handleToggle}
                color="primary"
              />
            </div>
            <Divider sx={{ my: "16px" }} />
            <div className={classes.content}>
              <AlertBox alertType="exception_violation" title="720時間" />
              <STypography>を超えると</STypography>
              <AlertBox alertType="exception_violation" title="赤いアラート" />
              <Switch
                name="isEnableAnnualOvertimeHoursExceptionViolation"
                checked={data?.isEnableAnnualOvertimeHoursExceptionViolation}
                onChange={handleToggle}
                color="primary"
              />
            </div>
            <Divider sx={{ mt: "16px" }} />
          </div>

          <div
            ref={averageMonthlyOvertimeAndHolidayHoursHeaderRef}
            style={{ marginBottom: "48px" }}
          >
            <Typography sx={{ fontWeight: "bold", mb: "16px" }}>
              法定外・休日労働時間の複数月平均
            </Typography>
            <div className={classes.content}>
              <AlertBox alertType="exception_warning" title="60時間" />
              <STypography>に達すると</STypography>
              <AlertBox alertType="exception_warning" title="黄色いアラート" />
              <Switch
                name="isEnableSomeMonthsAverageExceptionWarning"
                checked={data?.isEnableSomeMonthsAverageExceptionWarning}
                onChange={handleToggle}
                color="primary"
              />
            </div>
            <Divider sx={{ my: "16px" }} />
            <div className={classes.content}>
              <AlertBox alertType="exception_violation" title="80時間" />
              <STypography>を超えると</STypography>
              <AlertBox alertType="exception_violation" title="赤いアラート" />
              <Switch
                name="isEnableSomeMonthsAverageExceptionViolation"
                checked={data?.isEnableSomeMonthsAverageExceptionViolation}
                onChange={handleToggle}
                color="primary"
              />
            </div>
            <Divider sx={{ mt: "16px" }} />
          </div>
        </div>
      </div>
    </>
  );
};

const AlertBox = (props: { alertType: AlertType; title: string }) => {
  const getColor = (alertType: AlertType) => {
    switch (alertType) {
      case "principle_warning":
        return {
          borderColor: theme.palette.brown[500],
          backgroundColor: theme.palette.brown[200],
        };
      case "principle_violation":
        return {
          borderColor: theme.palette.orange[500],
          backgroundColor: theme.palette.orange[200],
        };
      case "exception_warning":
        return {
          borderColor: theme.palette.mustard[500],
          backgroundColor: theme.palette.mustard[100],
        };
      case "exception_violation":
        return {
          borderColor: theme.palette.red[500],
          backgroundColor: theme.palette.red[200],
        };
      default:
        return {
          borderColor: "inherit",
          backgroundColor: "inherit",
        };
    }
  };
  return (
    <span
      style={{
        fontSize: "14px",
        fontWeight: "bold",
        backgroundColor: getColor(props.alertType).backgroundColor,
        padding: "4px 12px",
        border: `1px solid ${getColor(props.alertType).borderColor}`,
        borderRadius: "4px",
      }}
    >
      {props.title}
    </span>
  );
};

import { useState } from "react";
import { AutocompleteChangeReason, Button } from "@mui/material";
import { CustomAutocomplete } from "components/atoms/custom-autocomplete";
import { Flash } from "components/atoms/flash";
import { CustomModal } from "components/molecules/custom-modal";
import { Account } from "data-access/repositories/account/account.dto";
import { ProjectId } from "data-access/repositories/project/project.dto";
import { laborCostRepository } from "features/cost/api/labor_cost.repository";
import { useAppSelector } from "store/hooks";
import { selectMain } from "store/main/slice";
import { mutate } from "swr";
import { API_PATHS } from "utils/apiPaths";
import { handleStateError } from "utils/errorHandler";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  projectId: ProjectId;
}
export const LaborCostFormModal = (props: Props) => {
  const mainState = useAppSelector(selectMain);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [selectedAccount, setSelectedAccount] = useState<Account | null>(null);

  const employees = mainState.accounts.filter((account) => {
    return !account.isDeactivate && !account.isOutsourcedMember;
  });

  const handleChange = async (
    _: React.SyntheticEvent,
    value: Account,
    reason: AutocompleteChangeReason,
  ) => {
    if (reason !== "selectOption") return;
    setSelectedAccount(value);
  };

  const handleSubmit = async () => {
    if (!selectedAccount) return;
    try {
      await laborCostRepository.create(props.projectId, selectedAccount.userId);
      handleClose();
      mutate(API_PATHS.getProjectLaborCosts(props.projectId));
    } catch (error) {
      setErrorMessage(error.response.data.message);
      handleStateError(error, setErrorMessage, "自社メンバーの追加に失敗しました");
    }
  };

  const handleClose = () => {
    setSelectedAccount(null);
    setErrorMessage("");
    props.onClose();
  };

  const isValid = (): boolean => {
    if (!selectedAccount) return false;
    return true;
  };

  return (
    <CustomModal
      open={props.isOpen}
      onClose={handleClose}
      title="自社メンバーの追加"
      maxWidth="xs"
      footer={
        <>
          <Button variant="outlined" onClick={handleClose} sx={{ width: "10rem" }}>
            キャンセル
          </Button>
          <Button
            disabled={!isValid()}
            onClick={handleSubmit}
            variant="contained"
            sx={{ width: "10rem", ml: "1rem" }}
          >
            追加
          </Button>
        </>
      }
    >
      <CustomAutocomplete
        variant="standard"
        data={employees}
        value={selectedAccount}
        onChange={handleChange}
        entityName="メンバー"
        entityLabel="メンバーを検索または選択"
      />
      {errorMessage && (
        <Flash title="エラー" severity="error" message={errorMessage} sx={{ mt: "1rem" }} />
      )}
    </CustomModal>
  );
};

import { Backdrop, CircularProgress } from "@mui/material";
import { theme } from "extensions/theme";

interface Props {
  isLoading: boolean;
  zIndex?: number;
}
export const LoadingOverlay = (props: Props) => {
  return (
    <Backdrop
      sx={{ color: theme.palette.grayScale[0], zIndex: () => props.zIndex || 99 }}
      open={props.isLoading}
      invisible
    >
      <CircularProgress />
    </Backdrop>
  );
};

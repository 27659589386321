import { useState } from "react";
import { Button, TextField, Typography } from "@mui/material";
import { Flash } from "components/atoms/flash";
import { LoadingOverlay } from "components/loadingOverlay";
import { userPasswordRepository } from "data-access/repositories/user/password/password.repository";
import { PageState } from "..";

interface Props {
  setPage: (page: PageState) => void;
  setSendEmail: (email: string) => void;
}
export const SendResetCode = (props: Props) => {
  const { setPage, setSendEmail } = props;
  const [email, setEmail] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handleClick = async () => {
    setIsLoading(true);
    try {
      await userPasswordRepository.reset(email);
      setPage("resetPassword");
      setSendEmail(email);
    } catch (error) {
      console.error(error);
      setErrorMessage("パスワード再設定用コードの送信に失敗しました");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <LoadingOverlay isLoading={isLoading} />

      {errorMessage && <Flash message={errorMessage} severity="error" title="エラー" />}
      <Typography sx={{ fontSize: "28px", fontWeight: "bold", mb: "16px" }}>
        パスワード再設定用コードを送信
      </Typography>
      <Typography sx={{ fontSize: "14px", mb: "60px" }}>
        パスワードを忘れた方は、登録しているアカウントのメールアドレスを下の入力欄に記入してください。
        <br />
        入力されたメールアドレスにパスワード再設定用コードを送信します。
      </Typography>
      <TextField
        id="email"
        name="email"
        type="email"
        label="メールアドレス"
        variant="outlined"
        onChange={handleChange}
        value={email}
        InputLabelProps={{
          shrink: true,
        }}
        placeholder="***@gembahub.jp"
        sx={{ width: "100%", mb: "48px" }}
      />
      <Button
        onClick={handleClick}
        variant="contained"
        sx={{ width: "100%" }}
        disabled={email.length === 0}
      >
        パスワード再発行メールを送る
      </Button>
    </>
  );
};

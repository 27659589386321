import { theme } from "extensions/theme";

export const styles = {
  title: {
    height: "64px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.grayScale[0],
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(2),
    "& .MuiSvgIcon-root": {
      color: theme.palette.grayScale[0],
    },
  },
  orderAndReceiveMessage: {
    mb: "8px",
    "& .MuiDivider-wrapper": {
      pl: 0,
    },
    "&::before": {
      width: 0,
    },
  },
  button: {
    width: "160px",
    fontWeight: "bold",
  },
};

import { ApiClient } from "data-access/ApiClient";
import {
  HubUuId,
  HubInfoData,
  HubInfoUpdateRequest,
} from "features/hub-settings/types/hub-info/hub_info.dto";
import { camelToSnakeCase, convertKeysToCamelCase } from "utils/convertObjectKeyCase";

export const hubRepository = {
  index(): Promise<HubInfoData> {
    return ApiClient.get("/api/v1/hub").then((res) => {
      return convertKeysToCamelCase(res.data);
    });
  },
  show(id: HubUuId): Promise<HubInfoData> {
    return ApiClient.get(`/api/v1/hubs/${id}`).then((res) => {
      return convertKeysToCamelCase(res.data);
    });
  },
  update(body: HubInfoUpdateRequest): Promise<HubInfoData> {
    const formData = convertFormData(body);

    return ApiClient.put("/api/v1/hub", formData, {
      headers: { "Content-Type": "multipart/form-data" },
    }).then((res) => convertKeysToCamelCase(res.data));
  },
};

const convertFormData = (body: HubInfoUpdateRequest): FormData => {
  const formData = new FormData();

  Object.entries(body).forEach(([key, value]) => {
    if (value === undefined) return;

    const snakeKey = camelToSnakeCase(key);
    const formValue = value instanceof File ? value : value.toString();

    formData.append(snakeKey, formValue);
  });

  return formData;
};

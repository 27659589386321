import { RefObject, useRef } from "react";
import { Divider, Stack, Typography } from "@mui/material";
import { Layout } from "components/templates/layout";
import { accountRepository } from "data-access/repositories/account/account.repository";
import useSWR from "swr";
import { API_PATHS } from "utils/apiPaths";
import Employee from "./employee";
import Group from "./group";
import LoginDisabledMember from "./login-desabled-member";
import MemberOrder from "./member-order";
import OutsourcedMember from "./outsourced-member";
import { styles } from "./styles";

export const MemberSetting = () => {
  const classes = styles();

  const employeeHeaderRef = useRef<HTMLDivElement>(null);
  const outsourcedMemberHeaderRef = useRef<HTMLDivElement>(null);
  const loginDisabledMemberHeaderRef = useRef<HTMLDivElement>(null);
  const memberOrderHeaderRef = useRef<HTMLDivElement>(null);
  const groupHeaderRef = useRef<HTMLDivElement>(null);

  // TODO: Hubコネクト後のデータ移行が前者完了したら協力会社テーブルを削除
  // 一旦は協力会社フラグがtrueのメンバーが存在する場合のみ表示
  const { data: accounts } = useSWR(API_PATHS.getAccounts(), accountRepository.index, {
    revalidateOnFocus: false,
  });
  const isOutsourcedMember = accounts?.some((account) => account.isOutsourcedMember);

  // スクロールを実行する関数
  const scrollToSection = (ref: RefObject<HTMLElement>) => {
    if (ref.current) {
      ref.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  return (
    <Layout>
      <div style={{ display: "flex" }}>
        <div style={{ width: "120px" }}>
          <Stack spacing={2} sx={{ mr: "20px" }}>
            <Typography
              className={classes.menuItem}
              onClick={() => scrollToSection(employeeHeaderRef)}
              fontWeight="500"
            >
              メンバー
            </Typography>
            <Typography
              className={classes.menuItem}
              onClick={() => scrollToSection(outsourcedMemberHeaderRef)}
              fontWeight="500"
            >
              協力会社
            </Typography>
            {isOutsourcedMember && (
              <Typography
                className={classes.menuItem}
                onClick={() => scrollToSection(loginDisabledMemberHeaderRef)}
                fontWeight="500"
              >
                ログイン不可メンバー
              </Typography>
            )}
            <Typography
              className={classes.menuItem}
              onClick={() => scrollToSection(memberOrderHeaderRef)}
              fontWeight="500"
            >
              表示順
            </Typography>
            <Typography
              className={classes.menuItem}
              onClick={() => scrollToSection(groupHeaderRef)}
              fontWeight="500"
            >
              グループ
            </Typography>
          </Stack>
        </div>
        <div style={{ overflow: "scroll", height: "85vh", flex: 1 }}>
          <Employee ref={employeeHeaderRef} />
          <Divider sx={{ my: 6 }} />
          {isOutsourcedMember && (
            <>
              <OutsourcedMember ref={outsourcedMemberHeaderRef} />
              <Divider sx={{ my: 6 }} />
            </>
          )}
          <LoginDisabledMember ref={loginDisabledMemberHeaderRef} />
          <Divider sx={{ my: 6 }} />
          <MemberOrder ref={memberOrderHeaderRef} />
          <Divider sx={{ my: 6 }} />
          <Group ref={groupHeaderRef} />
        </div>
      </div>
    </Layout>
  );
};

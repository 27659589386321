import { useCallback, useRef } from "react";

const autocompleteScrollPositions: Record<string, number> = {};

// Autocompleteのスクロール位置を保持するためのヘルパー関数
export const useAutocompleteScroll = (key: string) => {
  const listboxRef = useRef<HTMLUListElement | null>(null);

  const saveScrollPosition = useCallback(
    (element: HTMLElement) => {
      autocompleteScrollPositions[key] = element.scrollTop;
    },
    [key],
  );

  const restoreScrollPosition = useCallback(() => {
    setTimeout(() => {
      const listbox = document.querySelector(".MuiAutocomplete-listbox") as HTMLElement;
      if (listbox && autocompleteScrollPositions[key]) {
        listbox.scrollTop = autocompleteScrollPositions[key];
      }
    }, 10);
  }, [key]);

  const getListboxProps = useCallback(
    () => ({
      ref: (el: HTMLUListElement | null) => {
        listboxRef.current = el;
      },
      onScroll: (e: React.UIEvent<HTMLElement>) => {
        saveScrollPosition(e.currentTarget);
      },
      onMouseDown: (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
      },
    }),
    [saveScrollPosition],
  );

  return { getListboxProps, restoreScrollPosition };
};

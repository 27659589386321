import { useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import Logo from "images/logo_fill_navy.svg";
import SignIn1 from "images/sign_in_1.svg";
import SignIn2 from "images/sign_in_2.svg";
import SignIn3 from "images/sign_in_3.svg";
import { styles } from "./styles";

export const WalkThrough = () => {
  const [activeStep, setActiveStep] = useState<number>(0);
  const totalSteps = 3;

  const handleNext = () => {
    setActiveStep((prevStep) => Math.min(prevStep + 1, totalSteps - 1));
  };

  const handleBack = () => {
    setActiveStep((prevStep) => Math.max(prevStep - 1, 0));
  };

  return (
    <Box sx={{ ...styles().leftContainer }}>
      <img src={Logo} alt="logo" style={{ width: 300, marginBottom: "80px" }} />
      {activeStep === 0 && (
        <>
          <img src={SignIn1} alt="logo" style={{ width: 400, marginBottom: "12px" }} />
          <Typography fontSize="20px" fontWeight="bold">
            現場Hubは、情報を一元管理可能な
          </Typography>
          <Typography fontSize="20px" fontWeight="bold">
            業務管理システムです
          </Typography>
        </>
      )}
      {activeStep === 1 && (
        <>
          <img src={SignIn2} alt="logo" style={{ width: 400, height: 354, marginBottom: "12px" }} />
          <Typography fontSize="20px" fontWeight="bold">
            会社や事業所ごとに
          </Typography>
          <Typography fontSize="20px" fontWeight="bold">
            Hub (ワークスペース) が必要になります
          </Typography>
        </>
      )}
      {activeStep === 2 && (
        <>
          <img src={SignIn3} alt="logo" style={{ width: 400, height: 354, marginBottom: "12px" }} />
          <Typography fontSize="20px" fontWeight="bold">
            Hub同士はコネクトでき、
          </Typography>
          <Typography fontSize="20px" fontWeight="bold">
            情報を共有できます
          </Typography>
        </>
      )}
      <Box sx={{ display: "flex", justifyContent: "center", mt: 2, mb: 2, gap: "24px" }}>
        {Array.from({ length: totalSteps }, (_, index) => (
          <Box key={index} sx={{ ...styles(activeStep, index).stepContainer }} />
        ))}
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-between", width: "80%" }}>
        {activeStep === 0 ? (
          <Box sx={{ width: "100px" }} />
        ) : (
          <Button variant="outlined" onClick={handleBack} sx={{ ...styles().button }}>
            もどる
          </Button>
        )}
        {activeStep !== totalSteps - 1 && (
          <Button variant="outlined" onClick={handleNext} sx={{ ...styles().button }}>
            次へ
          </Button>
        )}
      </Box>
    </Box>
  );
};

import { HubId, HubUuId } from "features/hub-settings/types/hub-info/hub_info.dto";

export interface Token {
  id: number;
  name: string;
  email: string;
  hub_id: HubId;
  hub_uuid: HubUuId;
  hub_name: string;
  permissions: Permissions;
}

export interface Permissions {
  master_write: boolean;
}

export const initialPermissions: Permissions = {
  master_write: false,
};

export type TokenCreateRequest = Token;

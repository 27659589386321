import { useState } from "react";
import { LoadingOverlay } from "components/loadingOverlay";
import { noticeProjectStatusChangeRepository } from "data-access/repositories/notice/project-status/project_status_change.repository";
import useSWR from "swr";
import { ProjectStatus } from "./project-status-change";

export const Project = () => {
  const [isFetchLoading, setIsFetchLoading] = useState<boolean>(false);
  const fetchIndexKey = "/api/v1/notice/project_status_types/settings";

  const { data, isValidating } = useSWR(fetchIndexKey, noticeProjectStatusChangeRepository.index, {
    revalidateOnFocus: false,
  });

  return (
    <>
      <LoadingOverlay isLoading={isFetchLoading || isValidating} zIndex={99} />
      <ProjectStatus
        data={data || []}
        fetchIndexKey={fetchIndexKey}
        setIsLoading={setIsFetchLoading}
      />
    </>
  );
};

import { useState } from "react";
import { Stack, TextField, Typography } from "@mui/material";
import { Layout } from "components/templates/layout";
import { HubSettingUpdateRequest } from "data-access/repositories/hub_setting/hub_setting.dto";
import { hubSettingRepository } from "data-access/repositories/hub_setting/hub_setting.repository";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { mainOperations } from "store/main/operations";
import { selectMain } from "store/main/slice";

export const TemplateSetting = () => {
  const dispatch = useAppDispatch();
  const mainState = useAppSelector(selectMain);
  const currentScheduleNote = mainState.hubSetting.schedule_note_template;
  const currentWorkReportContent = mainState.hubSetting.work_report_content_template;
  const currentProjectNote = mainState.hubSetting.project_note_template;

  const [state, setState] = useState<{
    scheduleNoteTemplate: string;
    workReportContentTemplate: string;
    projectNoteTemplate: string;
  }>({
    scheduleNoteTemplate: currentScheduleNote,
    workReportContentTemplate: currentWorkReportContent,
    projectNoteTemplate: currentProjectNote,
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  const handleBlur = async (e: any) => {
    const { name, value } = e.target;
    try {
      const updatePayload: Partial<HubSettingUpdateRequest> = { [name]: value };
      await hubSettingRepository.update(updatePayload as HubSettingUpdateRequest);
      dispatch(mainOperations.getHub());
      dispatch(mainOperations.updateSuccessMessage("テンプレートを変更しました"));
    } catch (error) {
      dispatch(mainOperations.updateErrorMessage(error.response.data.message));
    }
  };

  return (
    <Layout>
      <Stack spacing={4}>
        <div>
          <Typography sx={{ fontWeight: "bold", mb: "8px" }}>予定</Typography>
          <div style={{ marginLeft: "8px" }}>
            <Typography sx={{ fontSize: "14px" }}>作業内容・予定メモのテンプレート</Typography>
            <Typography sx={{ fontSize: "11px", mb: "8px" }}>
              設定すると、予定作成時に文章が自動で反映されます。
            </Typography>
            <TextField
              name="scheduleNoteTemplate"
              type="text"
              multiline
              onChange={handleChange}
              onBlur={handleBlur}
              value={state.scheduleNoteTemplate}
              rows={5}
              sx={{ width: "360px" }}
            />
          </div>
        </div>
        <div>
          <Typography sx={{ fontWeight: "bold", mb: "8px" }}>案件</Typography>
          <div style={{ marginLeft: "8px" }}>
            <Typography sx={{ fontWeight: "500", fontSize: "14px" }}>
              概要・メモのテンプレート
            </Typography>
            <Typography sx={{ fontSize: "11px", mb: "8px" }}>
              設定すると、案件作成時に文章が自動で反映されます。
            </Typography>
            <TextField
              name="projectNoteTemplate"
              type="text"
              multiline
              onChange={handleChange}
              onBlur={handleBlur}
              value={state.projectNoteTemplate}
              rows={5}
              sx={{ width: "360px" }}
            />
          </div>
        </div>
        <div>
          <Typography sx={{ fontWeight: "bold", mb: "8px" }}>作業日報</Typography>
          <div style={{ marginLeft: "8px" }}>
            <Typography sx={{ fontWeight: "500", fontSize: "14px" }}>
              作業内容・連絡事項のテンプレート
            </Typography>
            <Typography sx={{ fontSize: "11px", mb: "8px" }}>
              設定すると、作業日報作成時に文章が自動で反映されます。
            </Typography>
            <TextField
              name="workReportContentTemplate"
              type="text"
              multiline
              onChange={handleChange}
              onBlur={handleBlur}
              value={state.workReportContentTemplate}
              rows={5}
              sx={{ width: "360px" }}
            />
          </div>
        </div>
      </Stack>
    </Layout>
  );
};

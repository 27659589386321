import { ApiClient } from "data-access/ApiClient";
import { convertKeysToCamelCase } from "utils/convertObjectKeyCase";
import { ScheduleId } from "../schedule.dto";
import { SourceSchedule } from "./source_schedule.dto";

export const sourceScheduleRepository = {
  index(scheduleId: ScheduleId): Promise<SourceSchedule | null> {
    return ApiClient.get(`/api/v1/schedules/${scheduleId}/source_schedule`).then((res) => {
      if (res.status === 204 || !res.data) {
        return null;
      }
      return convertKeysToCamelCase(res.data);
    });
  },
};

import { createBrowserRouter } from "react-router-dom";
import { AggregationDownload } from "features/hub-settings/components/aggregation-download";
import { AttendanceSetting } from "features/hub-settings/components/attendance-setting";
import { CostSetting } from "features/hub-settings/components/cost-setting";
import { CsvImport } from "features/hub-settings/components/csv-import";
import { ProjectCsvImportMapping } from "features/hub-settings/components/csv-import/project/mapping";
import { ScheduleCsvImportMapping } from "features/hub-settings/components/csv-import/schedule/mapping";
import { MemberSetting } from "features/hub-settings/components/member-setting";
import { NoticeSetting } from "features/hub-settings/components/notice-setting";
import { PermissionSetting } from "features/hub-settings/components/permission-setting";
import { ProjectSetting } from "features/hub-settings/components/project-setting";
import { ScheduleSettings } from "features/hub-settings/components/schedule-settings";
import { TemplateSetting } from "features/hub-settings/components/template-setting";
import Attendances from "pages/attendances";
import Billings from "pages/billings";
import { Building } from "pages/building";
import { Client } from "pages/client";
import { Connect } from "pages/connect";
import { ErrorPage404 } from "pages/error/error-page-404";
import { ErrorPage500 } from "pages/error/error-page-500";
import { HubInfo } from "pages/hub-settings/hub-info";
import Projects from "pages/projects";
import SalesAggregations from "pages/sales-aggregations";
import ScheduleSearchResult from "pages/schedule-search-result";
import Schedule from "pages/schedules";
import UserSettings from "pages/user-settings";
import WorkReports from "pages/work-reports";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Schedule />,
    errorElement: <ErrorPage404 />,
  },
  {
    path: "/error",
    element: <ErrorPage500 />,
  },
  {
    path: "/not-found",
    element: <ErrorPage404 />,
  },
  {
    path: "/schedules",
    element: <Schedule />,
    errorElement: <ErrorPage500 />,
  },
  {
    path: "/schedules/:id",
    element: <Schedule />,
    errorElement: <ErrorPage500 />,
  },
  {
    path: "/billings",
    element: <Billings />,
    errorElement: <ErrorPage500 />,
  },
  {
    path: "/billings/:id",
    element: <Billings />,
    errorElement: <ErrorPage500 />,
  },
  {
    path: "/projects",
    element: <Projects />,
    errorElement: <ErrorPage500 />,
  },
  {
    path: "/projects/new",
    element: <Projects />,
    errorElement: <ErrorPage500 />,
  },
  {
    path: "/projects/:id",
    element: <Projects />,
    errorElement: <ErrorPage500 />,
  },
  {
    path: "/buildings",
    element: <Building />,
    errorElement: <ErrorPage500 />,
  },
  {
    path: "/buildings/new",
    element: <Building />,
    errorElement: <ErrorPage500 />,
  },
  {
    path: "/buildings/:id",
    element: <Building />,
    errorElement: <ErrorPage500 />,
  },
  {
    path: "/clients",
    element: <Client />,
    errorElement: <ErrorPage500 />,
  },
  {
    path: "/clients/:id",
    element: <Client />,
    errorElement: <ErrorPage500 />,
  },
  {
    path: "/clients/new",
    element: <Client />,
    errorElement: <ErrorPage500 />,
  },
  {
    path: "/clients/new/project/:id",
    element: <Client />,
    errorElement: <ErrorPage500 />,
  },
  {
    path: "/sales-aggregations",
    element: <SalesAggregations />,
    errorElement: <ErrorPage500 />,
  },
  {
    path: "/work-reports",
    element: <WorkReports />,
    errorElement: <ErrorPage500 />,
  },
  {
    path: "/work-reports/:id",
    element: <WorkReports />,
    errorElement: <ErrorPage500 />,
  },
  {
    path: "/attendances",
    element: <Attendances />,
    errorElement: <ErrorPage500 />,
  },
  {
    path: "schedule-search-result",
    element: <ScheduleSearchResult />,
    errorElement: <ErrorPage500 />,
  },
  {
    path: "/hub-settings/schedule-settings",
    element: <ScheduleSettings />,
    errorElement: <ErrorPage500 />,
  },
  {
    path: "/hub-settings/project-settings",
    element: <ProjectSetting />,
    errorElement: <ErrorPage500 />,
  },
  {
    path: "/hub-settings/member-settings",
    element: <MemberSetting />,
    errorElement: <ErrorPage500 />,
  },
  {
    path: "/hub-settings/attendance-settings",
    element: <AttendanceSetting />,
    errorElement: <ErrorPage500 />,
  },
  {
    path: "/hub-settings/cost-settings",
    element: <CostSetting />,
    errorElement: <ErrorPage500 />,
  },
  {
    path: "/hub-settings/notice-settings",
    element: <NoticeSetting />,
    errorElement: <ErrorPage500 />,
  },
  {
    path: "/hub-settings/template-settings",
    element: <TemplateSetting />,
    errorElement: <ErrorPage500 />,
  },
  {
    path: "/hub-settings/aggregation-output",
    element: <AggregationDownload />,
    errorElement: <ErrorPage500 />,
  },
  {
    path: "/hub-settings/permission-settings",
    element: <PermissionSetting />,
    errorElement: <ErrorPage500 />,
  },
  {
    path: "/hub-settings/permission-settings/:id",
    element: <PermissionSetting />,
    errorElement: <ErrorPage500 />,
  },
  {
    path: "/hub-settings/hub-info",
    element: <HubInfo />,
    errorElement: <ErrorPage500 />,
  },
  {
    path: "/hub-settings/connects",
    element: <Connect />,
    errorElement: <ErrorPage500 />,
  },
  {
    path: "/hub-settings/connects/:id",
    element: <Connect />,
    errorElement: <ErrorPage500 />,
  },
  {
    path: "/hub-settings/connects/requesting",
    element: <Connect />,
    errorElement: <ErrorPage500 />,
  },
  {
    path: "/hub-settings/connects/requesting/:id",
    element: <Connect />,
    errorElement: <ErrorPage500 />,
  },
  {
    path: "/hub-settings/connects/approvable",
    element: <Connect />,
    errorElement: <ErrorPage500 />,
  },
  {
    path: "/hub-settings/connects/approvable/:id",
    element: <Connect />,
    errorElement: <ErrorPage500 />,
  },
  {
    path: "/hub-settings/csv-imports/project",
    element: <CsvImport />,
    errorElement: <ErrorPage500 />,
  },
  {
    path: "/hub-settings/csv-imports/project/mapping",
    element: <ProjectCsvImportMapping />,
    errorElement: <ErrorPage500 />,
  },
  {
    path: "/hub-settings/csv-imports/schedule",
    element: <CsvImport />,
    errorElement: <ErrorPage500 />,
  },
  {
    path: "/hub-settings/csv-imports/schedule/mapping",
    element: <ScheduleCsvImportMapping />,
    errorElement: <ErrorPage500 />,
  },
  {
    path: "/user-settings",
    element: <UserSettings />,
    errorElement: <ErrorPage500 />,
  },
]);

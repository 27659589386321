import { Box } from "@mui/material";
import { theme } from "extensions/theme";

interface Props {
  statusType: string;
  color?: string;
  sx?: object;
}

export const StatusTypeLabel = ({ sx = {}, ...props }: Props) => {
  const statusTypeClassName = (statusType: string) => {
    if (props.color) {
      return { backgroundColor: `#${props.color}` };
    }
    switch (statusType) {
      case "継続":
      case "協力会社":
      case "休憩終了":
      case "勤務中":
      case "連携中":
      case "新規":
        return { backgroundColor: theme.palette.secondary.main };
      case "完了":
      case "自社":
      case "出勤":
        return { backgroundColor: theme.palette.primary.main };
      case "休憩開始":
      case "休憩中":
      case "申請中":
      case "承認待ち":
      case "更新":
        return { backgroundColor: theme.palette.blue[500] };
      case "退勤":
      case "退勤済み":
      case "勤務外":
      case "停止中":
        return { backgroundColor: theme.palette.grayScale[700] };
      default:
        return { backgroundColor: theme.palette.grayScale[900] };
    }
  };

  return (
    <Box
      sx={{
        fontSize: "12px",
        p: "6px 12px",
        borderRadius: "16px",
        verticalAlign: "text-bottom",
        fontWeight: "bold",
        height: "30px",
        textAlign: "center",
        color: theme.palette.grayScale[0],
        ...statusTypeClassName(props.statusType),
        ...sx,
      }}
    >
      {props.statusType}
    </Box>
  );
};

import {
  PermissionSet,
  PermissionSetId,
} from "features/hub-settings/api/permission/permission_set.dto";
import {
  AttendanceRule,
  AttendanceRuleId,
} from "features/hub-settings/types/attendance/rule/attendance_rule.dto";
import { HubInfoData, initialHubInfoData } from "features/hub-settings/types/hub-info/hub_info.dto";
import { Brand } from "../brand";
import { Group, GroupId } from "../group/group.dto";
import { ScheduleId } from "../schedule/schedule.dto";

export type UserId = Brand<number, "UserId">;
export type AccountId = Brand<number, "AccountId">;

export interface Account {
  name: string;
  userId: UserId;
  groupId: GroupId;
  isOutsourcedMember: boolean;
  isSuperUser: boolean;
  hasEmail: boolean;
  isDeactivate: boolean;
  permissionSet: PermissionSet;
  unitPricePerDay: number;
  calendarStartDayOfWeek: DayOfWeek;
  numberOfProjectNoteLinesToDisplay: number;
  attendanceRule: AttendanceRule;
  scheduleOverlappingType: ScheduleOverlappingType;
}

export type ScheduleOverlappingTypeValue = "forbidden" | "warning" | "can";
export interface ScheduleOverlappingType {
  value: ScheduleOverlappingTypeValue;
  valueI18n: string;
}

export const scheduleOverlappingTypes: ScheduleOverlappingType[] = [
  { value: "forbidden", valueI18n: "招待不可" },
  { value: "warning", valueI18n: "アラートあり" },
  { value: "can", valueI18n: "指定なし" },
];

export interface AccountCreateRequest {
  name: string;
  email: string;
  permissionSetId: PermissionSetId | null;
  groupName?: string;
}

export interface AccountIndexForScheduleRequest {
  startTime: string;
  endTime: string;
  excludeScheduleId?: ScheduleId;
}

export interface InvitableAccount {
  name: string;
  userId: UserId;
  group: Group | null;
  hub: HubInfoData;
  isScheduleViewable: boolean;
}

export interface InvitableAccountForSchedule {
  userId: UserId;
  name: string;
  hub: HubInfoData;
  group: Group | null;
  isScheduleOverlapped: boolean;
  scheduleOverlappingType: ScheduleOverlappingType;
}

export interface ConnectedHubAccount {
  name: string;
  userId: UserId;
  hub: HubInfoData;
}

export interface AccountUpdateRequest {
  name?: string;
  groupId?: GroupId;
  unitPricePerDay?: number;
  displayOrderPosition?: number;
  isDeactivate?: boolean;
  scheduleOverlappingType?: ScheduleOverlappingTypeValue;
  attendanceRuleId?: AttendanceRuleId;
  permissionSetId?: PermissionSetId;
}

export interface AccountReference {
  userId: UserId;
  name: string;
  profileImageBackgroundColorNumber: string;
  profileImageUrl: string;
  group: {
    id: GroupId;
    name: string;
    colorNumber: string;
  };
  email: string;
  phoneNumber: string;
  hub: HubInfoData;
}

// 月火水木金土日
export type DayOfWeek = 0 | 1 | 2 | 3 | 4 | 5 | 6;

export const initialAccountRequest: AccountCreateRequest = {
  name: "",
  email: "",
  permissionSetId: null,
};

export const initialAccountReference: AccountReference = {
  userId: 0 as UserId,
  name: "",
  profileImageBackgroundColorNumber: "",
  profileImageUrl: "",
  group: {
    id: 0 as GroupId,
    name: "",
    colorNumber: "",
  },
  email: "",
  phoneNumber: "",
  hub: initialHubInfoData,
};

import { ElementRef, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Divider, SelectChangeEvent } from "@mui/material";
import { ActivityBlock } from "components/InfoBlock/activity";
import { ProjectsInfoBlock } from "components/InfoBlock/projects";
import { LoadingOverlay } from "components/loadingOverlay";
import { ClientSidebarHeader } from "components/organisms/client-sidebar-header";
import { ClientSidebarInfo } from "components/organisms/client-sidebar-info";
import { AsyncConfirmDialog } from "components/templates/async-confirm-dialog";
import { DetailSidebar } from "components/templates/detail-sidebar";
import { clientActivityRepository } from "data-access/repositories/client/activity/client_activity.repository";
import { ClientId, ClientRequest } from "data-access/repositories/client/client.dto";
import { clientRepository } from "data-access/repositories/client/client.repository";
import { clientProjectRepository } from "data-access/repositories/client/project/project.repository";
import { ProjectId } from "data-access/repositories/project/project.dto";
import { projectRepository } from "data-access/repositories/project/project.repository";
import { useAppDispatch } from "store/hooks";
import { mainOperations } from "store/main/operations";
import useSWR, { mutate } from "swr";
import { ACTIVITY_PER_PAGE } from "utils/constant";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  isCreateNew: boolean;
  fetchIndexKey: string;
}

export const ClientSidebar = (props: Props) => {
  const { isOpen, onClose, isCreateNew, fetchIndexKey } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [formState, setFormState] = useState<ClientRequest>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isChangeValue, setIsChangeValue] = useState<boolean>(false);
  const deleteConfirmRef = useRef<ElementRef<typeof AsyncConfirmDialog>>(null);
  const [limit, setLimit] = useState<number>(ACTIVITY_PER_PAGE);

  const extractIdFromPath = (regex: RegExp): number | null => {
    const match = location.pathname.match(regex);
    const idStr = match ? match[1] : undefined;
    const numericId = parseInt(idStr ?? "", 10);
    return isNaN(numericId) ? null : numericId;
  };

  const clientId = extractIdFromPath(/^\/clients\/(\d+)$/);
  const projectId = extractIdFromPath(/^\/clients\/new\/project\/(\d+)$/);

  const { data: client } = useSWR(clientId ? `/api/v1/clients/${clientId}` : null, () => {
    return clientId ? clientRepository.show(clientId as ClientId) : null;
  });

  const { data: project } = useSWR(projectId ? `/api/v1/projects/${projectId}` : null, () => {
    return projectId ? projectRepository.show(projectId as ProjectId) : null;
  });

  const { data: projects, isValidating: isValidatingProject } = useSWR(
    clientId ? `/api/v1/clients/${clientId}/projects` : null,
    () => clientProjectRepository.index(clientId as ClientId),
  );

  const { data: activities, mutate: activityMutate } = useSWR(
    clientId ? `/api/v1/clients/${clientId}/activities?limit=${limit}` : null,
    () => {
      return clientId ? clientActivityRepository.index(clientId as ClientId, limit) : null;
    },
    {
      revalidateOnFocus: false,
    },
  );

  // 新規作成のとき
  useEffect(() => {
    if (isCreateNew && project) {
      setFormState({
        postalCode: project ? project.postalCode : client?.postalCode,
        address: project ? project.address : client?.address,
        addressSecond: project ? project.addressSecond : client?.addressSecond,
        phoneNumber: project ? project.phoneNumber : client?.phoneNumber,
        phoneNumberSecond: project ? project.phoneNumberSecond : client?.phoneNumberSecond,
        email: project ? project.email : client?.email,
        faxNumber: project ? project.faxNumber : client?.faxNumber,
      });
    }
  }, [project]);
  // 編集のとき
  useEffect(() => {
    setFormState({
      code: client?.code,
      name: client?.name,
      honorificTitle: client?.honorificTitle,
      nameKana: client?.nameKana,
      postalCode: client?.postalCode,
      address: client?.address,
      addressSecond: client?.addressSecond,
      billingPersonName: client?.billingPersonName,
      billingPersonPosition: client?.billingPersonPosition,
      billingPersonSection: client?.billingPersonSection,
      phoneNumber: client?.phoneNumber,
      phoneNumberSecond: client?.phoneNumberSecond,
      email: client?.email,
      faxNumber: client?.faxNumber,
      note: client?.note,
      billingClosingDateType: client?.billingClosingDateType,
      depositMethodType: client?.depositMethodType,
      depositMonthType: client?.depositMonthType,
      depositDateType: client?.depositDateType,
    });
  }, [client]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setIsChangeValue(true);
    setFormState({ ...formState, [e.target.name]: e.target.value });
  };

  const handleChangeSelect = async (e: SelectChangeEvent<string>) => {
    setIsLoading(true);
    setFormState({ ...formState, [e.target.name]: e.target.value });
    try {
      if (isCreateNew) {
        const res = await clientRepository.create(formState);
        navigate(`/clients/${res.id}`, { state: res });
        dispatch(mainOperations.updateSuccessMessage("顧客を作成しました"));
      } else {
        await clientRepository.update(clientId as ClientId, { [e.target.name]: e.target.value });
        dispatch(mainOperations.updateSuccessMessage("顧客を更新しました"));
      }
      mutate(fetchIndexKey);
    } catch (error) {
      dispatch(mainOperations.updateErrorMessage(error.response.data.message));
    } finally {
      setIsLoading(false);
      activityMutate();
    }
  };

  const handleBlur = async () => {
    if (!isChangeValue) return;
    setIsLoading(true);
    try {
      if (isCreateNew) {
        const res = await clientRepository.create(formState);
        navigate(`/clients/${res.id}`, { state: res });
        dispatch(mainOperations.updateSuccessMessage("顧客を作成しました"));
      } else {
        await clientRepository.update(clientId as ClientId, formState);
        dispatch(mainOperations.updateSuccessMessage("顧客を更新しました"));
      }
      mutate(fetchIndexKey);
    } catch (error) {
      dispatch(mainOperations.updateErrorMessage(error.response.data.message));
    } finally {
      setIsChangeValue(false);
      setIsLoading(false);
      activityMutate();
    }
  };

  const handleDelete = async (id: ClientId) => {
    if (!deleteConfirmRef.current) return;
    const res = await deleteConfirmRef.current.confirm();

    if (res) {
      try {
        await clientRepository.destroy(id);
        mutate(fetchIndexKey);
        onClose();
        setFormState({});
        dispatch(mainOperations.updateSuccessMessage("顧客を削除しました"));
      } catch (error) {
        dispatch(mainOperations.updateErrorMessage(error.response.data.message));
      }
    }
  };

  return (
    <DetailSidebar isOpen={isOpen}>
      <AsyncConfirmDialog ref={deleteConfirmRef} />
      <LoadingOverlay isLoading={isLoading || isValidatingProject} />

      <ClientSidebarHeader
        onClose={() => {
          onClose();
          setFormState({});
        }}
        onChange={handleChange}
        onSelect={handleChangeSelect}
        onBlur={handleBlur}
        onDelete={() => handleDelete(clientId as ClientId)}
        isCreateNew={isCreateNew}
        formState={formState}
        setFormState={setFormState}
        clientId={clientId as ClientId}
      />
      <ClientSidebarInfo
        onChange={handleChange}
        onSelect={handleChangeSelect}
        onBlur={handleBlur}
        formState={formState}
        setFormState={setFormState}
        clientId={clientId as ClientId}
      />
      <Divider sx={{ my: "24px" }} />
      <ProjectsInfoBlock projects={projects || []} />
      <Divider sx={{ my: "12px" }} />
      {!isCreateNew && (
        <ActivityBlock
          activities={activities?.data || []}
          totalCount={activities?.pagination.count || 0}
          limit={limit}
          setLimit={setLimit}
        />
      )}
    </DetailSidebar>
  );
};

import { forwardRef, useState } from "react";
import { Box } from "@mui/material";
import { LoadingOverlay } from "components/loadingOverlay";
import { expenseItemRepository } from "features/cost/api/expense_item.repository";
import useSWR from "swr";
import { ExpenseItemFormModal } from "./form-modal";
import ExpenseItemHeader from "./header";
import { ExpenseItemTable } from "./table";

const ExpenseItem = forwardRef<HTMLDivElement>((_, ref) => {
  const fetchIndexKey: string = "/api/v1/expense_items";
  const [isModalOpen, setModalOpen] = useState<boolean>(false);

  const { data: expenseItems, isLoading } = useSWR(fetchIndexKey, expenseItemRepository.index);

  if (!expenseItems) {
    return (
      <>
        <LoadingOverlay isLoading={isLoading} />
      </>
    );
  }
  return (
    <>
      <ExpenseItemFormModal
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
        fetchIndexKey={fetchIndexKey}
      />
      <ExpenseItemHeader isOpen={isModalOpen} setOpen={setModalOpen} ref={ref} />
      <Box>
        <ExpenseItemTable fetchIndexKey={fetchIndexKey} />
      </Box>
    </>
  );
});

ExpenseItem.displayName = "ExpenseItem";

export default ExpenseItem;

export const styles = {
  dividerTitle: {
    mb: "8px",
    "& .MuiDivider-wrapper": {
      pl: 0,
    },
    "&::before": {
      width: 0,
    },
  },
};

import CheckIcon from "@mui/icons-material/Check";
import { Box, MenuItem, Select, SelectChangeEvent, Typography } from "@mui/material";

type SubscriberTypes = "log_only" | "push" | "email" | "push_and_email";

interface Props {
  value: SubscriberTypes;
  onChange: (event: SelectChangeEvent<string>) => void;
}

export const NotificationSelect = ({ value, onChange }: Props) => {
  return (
    <Select
      size="small"
      value={value}
      onChange={onChange}
      sx={{ width: "180px", mr: 2 }}
      MenuProps={{
        PaperProps: {
          style: {
            maxHeight: 200,
          },
        },
      }}
      renderValue={(selected) => {
        const displayValue =
          selected === "log_only"
            ? "ログのみ"
            : selected === "push"
              ? "プッシュ"
              : selected === "email"
                ? "メール"
                : selected === "push_and_email"
                  ? "プッシュ、メール"
                  : "";
        return <Typography>{displayValue}</Typography>;
      }}
    >
      <MenuItem value="log_only">
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {value === "log_only" && <CheckIcon fontSize="small" sx={{ ml: 1 }} />}
          <Typography>ログのみ</Typography>
        </Box>
      </MenuItem>
      <MenuItem value="push">
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {value === "push" && <CheckIcon fontSize="small" sx={{ ml: 1 }} />}
          <Typography>プッシュ</Typography>
        </Box>
      </MenuItem>
      <MenuItem value="email">
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {value === "email" && <CheckIcon fontSize="small" sx={{ ml: 1 }} />}
          <Typography>メール</Typography>
        </Box>
      </MenuItem>
      <MenuItem value="push_and_email">
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {value === "push_and_email" && <CheckIcon fontSize="small" sx={{ ml: 1 }} />}
          <Typography>プッシュ、メール</Typography>
        </Box>
      </MenuItem>
    </Select>
  );
};

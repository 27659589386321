import { useEffect, useState } from "react";
import { ScheduleMain } from "components/organisms/schedule";
import { Layout } from "components/templates/layout";
import { WelcomeModal } from "components/templates/welcome-modal";
import { hubRepository } from "features/hub-settings/api/hub-info/hub_info.repository";
import { InvitationModal } from "features/hub-settings/components/member-setting/employee/modal/invitation";
import useSWR from "swr";

export default function Schedule() {
  const [isWelcomeModalOpen, setIsShowWelcomeModalOpen] = useState<boolean>(false);
  const [isInvitationModalOpen, setIsInvitationModalOpen] = useState<boolean>(false);

  const { data } = useSWR("/api/v1/hub", hubRepository.index, {
    revalidateOnFocus: false,
  });

  useEffect(() => {
    if (!data) return;
    const shouldShowModal = sessionStorage.getItem("showWelcomeModal") === "true";

    if (shouldShowModal) {
      setIsShowWelcomeModalOpen(true);
      sessionStorage.removeItem("showWelcomeModal");
    }
  }, [data]);

  const handleCloseWelcomeModal = () => {
    setIsShowWelcomeModalOpen(false);
  };

  return (
    <Layout isOverflowAllowed>
      {data && (
        <>
          <WelcomeModal
            hubInfo={data}
            isOpen={isWelcomeModalOpen}
            onClose={handleCloseWelcomeModal}
            onInvitationModalOpen={() => setIsInvitationModalOpen(true)}
          />
          <InvitationModal
            isOpen={isInvitationModalOpen}
            onClose={() => setIsInvitationModalOpen(false)}
          />
        </>
      )}
      <ScheduleMain />
    </Layout>
  );
}

import { useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Box, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import { theme } from "extensions/theme";

interface Props {
  password: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  label?: string;
  width?: string;
  showHelperText?: boolean;
  helperTextItems?: string[];
}

export const PasswordInput = ({
  password,
  onChange,
  label,
  width = "600px",
  showHelperText = true,
  helperTextItems = [
    "パスワードは6文字以上が必要です",
    "半角英数字記号が使用可能です",
    "英小文字を1字以上含める必要があります",
  ],
}: Props) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  return (
    <>
      {label && (
        <Typography
          sx={{ fontSize: "14px", fontWeight: "bold", mb: "8px", textAlign: "left", width: "100%" }}
        >
          {label}
        </Typography>
      )}
      <TextField
        id="password"
        name="password"
        variant="outlined"
        onChange={onChange}
        value={password}
        type={showPassword ? "text" : "password"}
        sx={{
          width,
          "& .MuiOutlinedInput-root": {
            height: "48px",
          },
        }}
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={() => setShowPassword((show) => !show)} edge="end">
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      {showHelperText && (
        <Box sx={{ width: "100%" }}>
          {helperTextItems.map((text, index) => (
            <Typography
              key={index}
              sx={{ fontSize: "14px", color: `${theme.palette.grayScale[700]}` }}
            >
              {text}
            </Typography>
          ))}
        </Box>
      )}
    </>
  );
};

import { ElementRef, useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { Close, EventOutlined } from "@mui/icons-material";
import Person2OutlinedIcon from "@mui/icons-material/Person2Outlined";
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  MenuItem,
  Popper,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Avatar } from "components/avatar";
import { ConnectTypeLabel } from "components/label/connect-type-label";
import { SelectedMemberLabel } from "components/label/selected-member-label";
import { CustomFormLabel } from "components/molecules/custom-form-label";
import { AsyncConfirmDialog } from "components/templates/async-confirm-dialog";
import { UserId } from "data-access/repositories/account/account.dto";
import { connectRepository } from "features/hub-settings/api/connect/connect.repository";
import { connectRequestRepository } from "features/hub-settings/api/connect/connect_request.repository";
import { ConnectTypeValue } from "features/hub-settings/types/connect/connect.dto";
import {
  ConnectRequest,
  initialConnectRequest,
} from "features/hub-settings/types/connect/connect_request.dto";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { mainOperations } from "store/main/operations";
import { selectMain } from "store/main/slice";
import useSWR, { mutate } from "swr";
import { API_PATHS } from "utils/apiPaths";
import { handleReduxError } from "utils/errorHandler";
import { styles } from "./styles";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}
export const ConnectDetailModal = (props: Props) => {
  const dispatch = useAppDispatch();
  const mainState = useAppSelector(selectMain);
  const location = useLocation();
  const id = location?.state?.id;
  const [formState, setFormState] = useState<ConnectRequest>(initialConnectRequest);
  const [currentInvitableAccountUserIds, setCurrentInvitableAccountUserIds] = useState<UserId[]>(
    [],
  );
  const [currentScheduleViewableAccountUserIds, setCurrentScheduleViewableAccountUserIds] =
    useState<UserId[]>([]);

  const { data: connectData } = useSWR(
    props.isOpen && id ? `/api/v1/connects/${id}` : null,
    () => connectRepository.show(id),
    { revalidateOnFocus: false },
  );

  useEffect(() => {
    if (props.isOpen && connectData) {
      setCurrentInvitableAccountUserIds(
        connectData.ownHubInvitableAccounts.map((account) => account.userId),
      );
      setCurrentScheduleViewableAccountUserIds(
        connectData.ownHubScheduleViewableAccounts.map((account) => account.userId),
      );

      setFormState((prev) => ({
        ...prev,
        connectId: id,
        connectType: connectData.ownHubConnectType.value,
        destinationHubUuid: connectData.partnerHub.uuid,
        addInvitableAccountUserIds: [],
        removeInvitableAccountUserIds: [],
        addScheduleViewableAccountUserIds: [],
        removeScheduleViewableAccountUserIds: [],
      }));
    }
  }, [connectData]);

  const filteredInvitableUsers = useMemo((): {
    selectedAccounts: { id: UserId; name: string }[];
    unselectedAccounts: { id: UserId; name: string }[];
  } => {
    const activeAccounts = mainState.accounts.filter(
      (account) => !account.isDeactivate && account.hasEmail,
    );
    const selectedAccounts = activeAccounts
      .filter((account) => currentInvitableAccountUserIds.includes(account.userId))
      .map((account) => ({ id: account.userId, name: account.name }));

    const unselectedAccounts = activeAccounts
      .filter((account) => !currentInvitableAccountUserIds.includes(account.userId))
      .map((account) => ({ id: account.userId, name: account.name }));
    return {
      selectedAccounts,
      unselectedAccounts,
    };
  }, [currentInvitableAccountUserIds]);

  const filteredScheduleViewableUsers = useMemo((): {
    selectedAccounts: { id: UserId; name: string }[];
    unselectedAccounts: { id: UserId; name: string }[];
  } => {
    const selectedAccounts = filteredInvitableUsers.selectedAccounts
      .filter((account) => currentScheduleViewableAccountUserIds.includes(account.id))
      .map((account) => ({ id: account.id, name: account.name }));
    const unselectedAccounts = filteredInvitableUsers.selectedAccounts
      .filter((account) => !currentScheduleViewableAccountUserIds.includes(account.id))
      .map((account) => ({ id: account.id, name: account.name }));
    return {
      selectedAccounts,
      unselectedAccounts,
    };
  }, [currentScheduleViewableAccountUserIds, filteredInvitableUsers]);

  const handleChangeConnectType = (e: SelectChangeEvent<ConnectTypeValue>) => {
    setFormState((prev) => ({
      ...prev,
      connectType: e.target.value as ConnectRequest["connectType"],
    }));
  };

  const handleSelectInvitableUser = (selectedHubUserIds: UserId[]) => {
    setCurrentInvitableAccountUserIds(selectedHubUserIds);
    setCurrentScheduleViewableAccountUserIds((prev) =>
      prev.filter((id) => selectedHubUserIds.includes(id)),
    );
  };

  const handleSelectScheduleViewableUser = (selectedHubUserIds: UserId[]) => {
    setCurrentScheduleViewableAccountUserIds(selectedHubUserIds);
  };

  const handleClearInvitableUser = (hubUserId: UserId) => {
    setCurrentInvitableAccountUserIds((prev) => prev.filter((id) => id !== hubUserId));
    setCurrentScheduleViewableAccountUserIds((prev) => prev.filter((id) => id !== hubUserId));
  };

  const handleClearScheduleViewableUser = (hubUserId: UserId) => {
    setCurrentScheduleViewableAccountUserIds((prev) => prev.filter((id) => id !== hubUserId));
  };

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const handleSubmit = async () => {
    if (!connectData) return;
    setIsSubmitting(true);
    dispatch(mainOperations.updateIsLoading(true));

    const initInvitableAccountUserIds = connectData.ownHubInvitableAccounts.map(
      (account) => account.userId,
    );
    const initScheduleViewableAccountUserIds = connectData.ownHubScheduleViewableAccounts.map(
      (account) => account.userId,
    );

    const addInvitableAccountUserIds = currentInvitableAccountUserIds.filter(
      (id) => !initInvitableAccountUserIds.includes(id),
    );
    const removeInvitableAccountUserIds = initInvitableAccountUserIds.filter(
      (id) => !currentInvitableAccountUserIds.includes(id),
    );
    const addScheduleViewableAccountUserIds = currentScheduleViewableAccountUserIds.filter(
      (id) => !initScheduleViewableAccountUserIds.includes(id),
    );
    const removeScheduleViewableAccountUserIds = initScheduleViewableAccountUserIds.filter(
      (id) => !currentScheduleViewableAccountUserIds.includes(id),
    );

    const requestData = {
      ...formState,
      addInvitableAccountUserIds,
      removeInvitableAccountUserIds,
      addScheduleViewableAccountUserIds,
      removeScheduleViewableAccountUserIds,
    };

    try {
      await connectRequestRepository.create(requestData);
      dispatch(mainOperations.updateSuccessMessage("更新の申請が完了しました"));
      mutate(API_PATHS.getConnects());
      mutate(API_PATHS.getConnectRequestsRequesting());
      handleClose();
    } catch (error) {
      handleReduxError(error, dispatch, "更新の申請に失敗しました");
    } finally {
      setIsSubmitting(false);
      dispatch(mainOperations.updateIsLoading(false));
    }
  };

  const stopConnectConfirmRef = useRef<ElementRef<typeof AsyncConfirmDialog>>(null);
  const handleStopConnect = async () => {
    if (!stopConnectConfirmRef.current) return;
    const res = await stopConnectConfirmRef.current.confirm();
    if (!res) return;

    dispatch(mainOperations.updateIsLoading(true));
    try {
      await connectRepository.delete(id);
      dispatch(mainOperations.updateSuccessMessage("コネクトを停止しました"));
      mutate(API_PATHS.getConnects());
      handleClose();
    } catch (error) {
      handleReduxError(error, dispatch, "コネクトの停止に失敗しました");
    } finally {
      dispatch(mainOperations.updateIsLoading(false));
    }
  };

  const handleClose = () => {
    setFormState(initialConnectRequest);
    props.onClose();
  };

  // 2つの配列が同じ要素を含んでいるかどうかを比較
  const areArraysEqual = (array1: UserId[], array2: UserId[]): boolean => {
    if (array1.length !== array2.length) return false;

    const sortedArray1 = [...array1].sort();
    const sortedArray2 = [...array2].sort();

    return sortedArray1.every((item, index) => item === sortedArray2[index]);
  };

  const isSubmitDisabled = useMemo(() => {
    if (!connectData || isSubmitting) return true;

    const hasConnectTypeChanged = formState.connectType !== connectData.ownHubConnectType.value;
    if (hasConnectTypeChanged && formState.connectType !== "order") {
      if (currentInvitableAccountUserIds.length === 0) return true;
    }

    const originalInvitableAccountUserIds = connectData.ownHubInvitableAccounts.map(
      (account) => account.userId,
    );
    const hasInvitableAccountsChanged = !areArraysEqual(
      currentInvitableAccountUserIds,
      originalInvitableAccountUserIds,
    );

    const originalScheduleViewableAccountUserIds = connectData.ownHubScheduleViewableAccounts.map(
      (account) => account.userId,
    );
    const hasScheduleViewableAccountsChanged = !areArraysEqual(
      currentScheduleViewableAccountUserIds,
      originalScheduleViewableAccountUserIds,
    );

    return !(
      hasConnectTypeChanged ||
      hasInvitableAccountsChanged ||
      hasScheduleViewableAccountsChanged
    );
  }, [
    isSubmitting,
    formState.connectType,
    currentInvitableAccountUserIds,
    currentScheduleViewableAccountUserIds,
    connectData,
  ]);

  if (!connectData) return null;

  return (
    <>
      <AsyncConfirmDialog
        ref={stopConnectConfirmRef}
        title="本当にコネクトを停止しますか？"
        content={`招待されたHubは全ての案件や予定から退出され、\n新規の招待ができなくなります。\nコネクト停止後も、受注や発注の再開は可能です。\nこの動作は取り消しできません。`}
        yesButtonText="停止"
      />
      <Dialog open={props.isOpen} onClose={handleClose} fullWidth>
        <DialogTitle sx={styles.title}>
          {connectData.partnerHub.name}とのコネクト
          <IconButton onClick={handleClose} sx={styles.closeButton}>
            <Close />
          </IconButton>
        </DialogTitle>

        <DialogContent dividers>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              mb: "32px",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: "12px" }}>
              <Avatar
                url={connectData.partnerHub.logoUrl}
                name={connectData.partnerHub.name}
                bgColor={connectData.partnerHub.logoBackgroundColorNumber}
                size="small"
              />
              <Typography sx={{ fontWeight: "bold", fontSize: "20px" }}>自社が</Typography>
              <Select
                variant="standard"
                value={formState.connectType}
                onChange={handleChangeConnectType}
              >
                <MenuItem value="order">
                  <ConnectTypeLabel name="発注" sx={{ height: "36px", width: "50px" }} />
                </MenuItem>
                <MenuItem value="receive">
                  <ConnectTypeLabel name="受注" sx={{ height: "36px", width: "50px" }} />
                </MenuItem>
                <MenuItem value="order_and_receive">
                  <ConnectTypeLabel name="受発注" sx={{ height: "36px", width: "50px" }} />
                </MenuItem>
              </Select>
            </Box>
          </Box>

          {formState.connectType === "order_and_receive" && (
            <Divider textAlign="left" sx={styles.orderAndReceiveMessage}>
              <Typography color="secondary" fontWeight="bold" fontSize="12px">
                自社が「受注」するとき
              </Typography>
            </Divider>
          )}
          {formState.connectType !== "order" && (
            <Stack spacing={3} mb={3}>
              <Box sx={{ display: "flex", alignItems: "start", gap: "16px" }}>
                <Person2OutlinedIcon sx={{ mt: "4px" }} />
                <Box sx={{ width: "100%" }}>
                  <CustomFormLabel
                    labelName="相手の案件と予定に招待を許可するメンバー"
                    required
                    labelSize="14px"
                    labelWeight="bold"
                    small
                    requiredSize="12px"
                  />
                  <Autocomplete
                    options={filteredInvitableUsers.unselectedAccounts}
                    value={filteredInvitableUsers.selectedAccounts}
                    onChange={(_, selectUsers) =>
                      handleSelectInvitableUser(selectUsers.map((selectUser) => selectUser.id))
                    }
                    renderInput={(params) => (
                      <TextField {...params} variant="standard" label="メンバーを選択" />
                    )}
                    getOptionLabel={(option) => option.name}
                    multiple
                    disableCloseOnSelect
                    disableClearable
                    renderTags={() => null}
                    slots={{
                      popper: (props) => (
                        <Popper {...props} placement="right-start" sx={{ maxWidth: "320px" }} />
                      ),
                    }}
                  />
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: "8px", mt: "12px" }}>
                    {filteredInvitableUsers.selectedAccounts.map((hubUser, index) => (
                      <SelectedMemberLabel
                        key={index}
                        userId={hubUser.id}
                        userName={hubUser.name}
                        onClear={handleClearInvitableUser}
                      />
                    ))}
                  </Box>
                </Box>
              </Box>
              <Box sx={{ display: "flex", alignItems: "start", gap: "16px" }}>
                <EventOutlined sx={{ mt: "4px" }} />
                <Box sx={{ width: "100%" }}>
                  <Typography sx={{ fontWeight: "bold", fontSize: "14px" }}>
                    相手のスケジュールに予定の時間帯を表示するメンバー
                  </Typography>
                  <Autocomplete
                    options={filteredScheduleViewableUsers.unselectedAccounts}
                    value={filteredScheduleViewableUsers.selectedAccounts}
                    onChange={(_, selectUsers) =>
                      handleSelectScheduleViewableUser(
                        selectUsers.map((selectUser) => selectUser.id),
                      )
                    }
                    renderInput={(params) => (
                      <TextField {...params} variant="standard" label="メンバーを選択" />
                    )}
                    getOptionLabel={(option) => option.name}
                    multiple
                    disableCloseOnSelect
                    disableClearable
                    renderTags={() => null}
                    slots={{
                      popper: (props) => (
                        <Popper {...props} placement="right-start" sx={{ maxWidth: "320px" }} />
                      ),
                    }}
                  />
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: "8px", mt: "12px" }}>
                    {filteredScheduleViewableUsers.selectedAccounts.map((hubUser, index) => (
                      <SelectedMemberLabel
                        key={index}
                        userId={hubUser.id}
                        userName={hubUser.name}
                        onClear={handleClearScheduleViewableUser}
                      />
                    ))}
                  </Box>
                </Box>
              </Box>
            </Stack>
          )}
          {formState.connectType === "order_and_receive" && (
            <Divider textAlign="left" sx={styles.orderAndReceiveMessage}>
              <Typography color="info" fontWeight="bold" fontSize="12px">
                自社が「発注」するとき
              </Typography>
            </Divider>
          )}
          {formState.connectType !== "receive" && (
            <Stack spacing={3}>
              <Box sx={{ display: "flex", alignItems: "start", gap: "16px" }}>
                <Person2OutlinedIcon sx={{ mt: "4px" }} />
                <Box sx={{ width: "100%" }}>
                  <CustomFormLabel
                    labelName="自社の案件と予定に招待が許可されているメンバー"
                    labelSize="14px"
                    labelWeight="bold"
                    small
                    requiredSize="12px"
                  />
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: "8px", mt: "12px" }}>
                    {connectData.partnerHubInvitableAccounts.map((partnerHubUser, index) => (
                      <SelectedMemberLabel
                        key={index}
                        userId={partnerHubUser.userId}
                        userName={partnerHubUser.name}
                      />
                    ))}
                  </Box>
                </Box>
              </Box>
              <Box sx={{ display: "flex", alignItems: "start", gap: "16px" }}>
                <EventOutlined sx={{ mt: "4px" }} />
                <Box sx={{ width: "100%" }}>
                  <Typography sx={{ fontWeight: "bold", fontSize: "14px" }}>
                    自社のスケジュールに予定の時間帯を表示するメンバー
                  </Typography>
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: "8px", mt: "12px" }}>
                    {connectData.partnerHubScheduleViewableAccounts.map((partnerHubUser, index) => (
                      <SelectedMemberLabel
                        key={index}
                        userId={partnerHubUser.userId}
                        userName={partnerHubUser.name}
                      />
                    ))}
                  </Box>
                </Box>
              </Box>
            </Stack>
          )}
        </DialogContent>

        <Box sx={{ display: "flex", justifyContent: "space-between", p: "16px", gap: "16px" }}>
          <Button color="error" onClick={handleStopConnect} sx={{ fontWeight: "bold" }}>
            コネクト停止
          </Button>
          <Box sx={{ display: "flex", gap: "16px" }}>
            <Button variant="outlined" onClick={handleClose} sx={styles.button}>
              キャンセル
            </Button>
            <Button
              onClick={handleSubmit}
              variant="contained"
              sx={styles.button}
              disabled={isSubmitDisabled}
            >
              更新を申請
            </Button>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

import { useState } from "react";
import { Button, Typography } from "@mui/material";
import { PasswordInput } from "components/input/password-input";
import { userPasswordRepository } from "data-access/repositories/user/password/password.repository";
import { useAppDispatch } from "store/hooks";
import { mainOperations } from "store/main/operations";

export const ResetPasswordTab = () => {
  const dispatch = useAppDispatch();
  const [password, setPassword] = useState<string>("");

  const handleSubmit = async () => {
    try {
      await userPasswordRepository.updateWithAuth(password);
      dispatch(mainOperations.updateSuccessMessage("パスワードを再設定しました。"));
    } catch (e) {
      dispatch(mainOperations.updateErrorMessage(e.response.data.message));
    }
  };

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPassword(e.target.value);
  };

  return (
    <>
      <h2>パスワードを再設定する</h2>
      <Typography sx={{ mb: "24px" }}>
        推測しにくいパスワードをお奨めします。また、パスワードの使い回しはご遠慮ください。
      </Typography>
      <PasswordInput
        label="新しいパスワード"
        password={password}
        onChange={handleChange}
        width="600px"
        showHelperText={true}
      />
      <Button
        onClick={handleSubmit}
        variant="contained"
        sx={{
          marginTop: "30px",
          height: "40px",
          width: "200px",
        }}
        disabled={password.length === 0}
      >
        パスワードを再設定する
      </Button>
    </>
  );
};

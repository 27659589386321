import { useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { ProjectId } from "data-access/repositories/project/project.dto";
import { sourceProjectRepository } from "data-access/repositories/project/source-project/source_project.repository";
import { theme } from "extensions/theme";
import useSWR from "swr";
import { API_PATHS } from "utils/apiPaths";
import { SourceProjectModal } from "./modal";

interface Props {
  projectId?: ProjectId;
}
export const SourceProject = (props: Props) => {
  const [isOpenSourceProjectModal, setIsOpenSourceProjectModal] = useState<boolean>(false);

  const { data: sourceProject } = useSWR(
    API_PATHS.getSourceProject(props.projectId as ProjectId),
    () => sourceProjectRepository.index(props.projectId as ProjectId),
    { revalidateOnFocus: false },
  );

  return (
    <>
      {isOpenSourceProjectModal && sourceProject && (
        <SourceProjectModal
          isOpen={isOpenSourceProjectModal}
          onClose={() => setIsOpenSourceProjectModal(false)}
          sourceProject={sourceProject}
        />
      )}

      <Box
        color={
          sourceProject?.deletedAt || sourceProject?.withdrawedAt
            ? theme.palette.red[500]
            : theme.palette.blue[500]
        }
        bgcolor={
          sourceProject?.deletedAt || sourceProject?.withdrawedAt
            ? theme.palette.red[200]
            : theme.palette.blue[200]
        }
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          p: "16px 24px",
          borderRadius: "4px",
          height: "64px",
          my: "16px",
        }}
      >
        {sourceProject?.deletedAt ? (
          <Typography fontWeight="bold">{sourceProject?.hubName}が案件を削除しました</Typography>
        ) : sourceProject?.withdrawedAt ? (
          <Typography fontWeight="bold">自Hubが案件から退出しました</Typography>
        ) : (
          <Typography fontWeight="bold">{sourceProject?.hubName}から招待された案件です</Typography>
        )}
        <Button
          variant="contained"
          onClick={() => setIsOpenSourceProjectModal(true)}
          sx={{
            bgcolor: theme.palette.grayScale[0],
            color:
              sourceProject?.deletedAt || sourceProject?.withdrawedAt
                ? theme.palette.red[500]
                : theme.palette.blue[500],
            fontWeight: "bold",
            height: "32px",
          }}
        >
          元情報を見る
        </Button>
      </Box>
    </>
  );
};

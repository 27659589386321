import { ApiClient } from "data-access/ApiClient";
import {
  CsvImportProject,
  CsvImportProjectAnalyzeRequest,
  CsvImportProjectAnalyzeResponse,
  CsvImportProjectId,
  CsvImportProjectIndexResponse,
  CsvImportProjectRequest,
} from "features/hub-settings/types/csv-import/csv_import_project.dto";
import { camelToSnakeCase, convertKeysToCamelCase } from "utils/convertObjectKeyCase";

export const csvImportProjectRepository = {
  index(page: number): Promise<CsvImportProjectIndexResponse> {
    return ApiClient.get(`/api/v1/project_import_jobs?limit=10&page=${page}`).then((res) => {
      return convertKeysToCamelCase(res.data);
    });
  },
  create(body: CsvImportProjectRequest): Promise<{ id: CsvImportProjectId }> {
    const config = { headers: { "content-type": "application/json" } };
    return ApiClient.post("/api/v1/project_import_jobs", createBody(body), config).then((res) => {
      return convertKeysToCamelCase(res.data);
    });
  },
  latest(): Promise<CsvImportProject> {
    return ApiClient.get("/api/v1/project_import_jobs/latest").then((res) => {
      return convertKeysToCamelCase(res.data);
    });
  },
  async putPresignedUrl(file: File): Promise<{ url: string; objectKey: string }> {
    try {
      const preSignedUrlResponse = await ApiClient.get(
        "/api/v1/project_import_jobs/put_presigned_url",
      );
      const preSignedUrlData = preSignedUrlResponse.data;

      if (preSignedUrlResponse.status !== 200 || !preSignedUrlData.url) {
        throw new Error("Failed to get presigned URL");
      }

      const uploadResponse = await fetch(preSignedUrlData.url, {
        method: "PUT",
        body: file,
        headers: {
          "Content-Type": file.type,
        },
      });

      if (uploadResponse.status !== 200) {
        throw new Error("Failed to upload file");
      }
      return { url: preSignedUrlData.url, objectKey: preSignedUrlData.object_key };
    } catch {
      throw new Error("Failed to upload file");
    }
  },
  analyze(body: CsvImportProjectAnalyzeRequest): Promise<CsvImportProjectAnalyzeResponse> {
    const config = { headers: { "content-type": "application/json" } };
    const bodyData = {
      object_key: body.objectKey,
      import_key: camelToSnakeCase(body.importKey),
      import_key_title: body.importKeyTitle,
    };
    return ApiClient.post("/api/v1/project_import_jobs/analyze_csv", bodyData, config).then(
      (res) => {
        return convertKeysToCamelCase(res.data);
      },
    );
  },
  importCancel(id: CsvImportProjectId): Promise<CsvImportProject> {
    return ApiClient.post(`/api/v1/project_import_jobs/${id}/cancel`).then((res) => {
      return convertKeysToCamelCase(res.data);
    });
  },
  resultUrl(id: CsvImportProjectId): Promise<{ url: string }> {
    return ApiClient.get(`/api/v1/project_import_jobs/${id}/result_url`).then((res) => {
      return res.data;
    });
  },
};

const createBody = (body: CsvImportProjectRequest) => {
  return {
    object_key: body.objectKey,
    import_key: camelToSnakeCase(body.importKey),
    filename: body.filename,
    column_map: {
      code: body.columnMap.code,
      external_id: body.columnMap.externalId,
      sales_amount: body.columnMap.salesAmount,
      project_status_type_name: body.columnMap.projectStatusTypeName,
      project_type_name: body.columnMap.projectTypeName,
      client_name: body.columnMap.clientName,
      inquiry_number: body.columnMap.inquiryNumber,
      building_name: body.columnMap.buildingName,
      inquired_by_name: body.columnMap.inquiredByName,
      manager_name: body.columnMap.managerName,
      address: body.columnMap.address,
      address_second: body.columnMap.addressSecond,
      billing_precaution: body.columnMap.billingPrecaution,
      completed_date: body.columnMap.completedDate,
      email: body.columnMap.email,
      expected_complete_date: body.columnMap.expectedCompleteDate,
      fax_number: body.columnMap.faxNumber,
      inquired_date: body.columnMap.inquiredDate,
      name: body.columnMap.name,
      note: body.columnMap.note,
      ordered_date: body.columnMap.orderedDate,
      person_name: body.columnMap.personName,
      phone_number: body.columnMap.phoneNumber,
      phone_number_second: body.columnMap.phoneNumberSecond,
      postal_code: body.columnMap.postalCode,
    },
  };
};

import { useState } from "react";
import * as React from "react";
import { FileDownloadOutlined, Visibility } from "@mui/icons-material";
import {
  Box,
  IconButton,
  Typography,
  Select,
  MenuItem,
  TextField,
  Tooltip,
  FormControlLabel,
  Checkbox,
  InputAdornment,
  Divider,
  SelectChangeEvent,
} from "@mui/material";
import { DeleteIcon } from "components/icon/delete-icon";
import { FileIcon } from "components/icon/file-icon";
import { AsyncConfirmDialog } from "components/templates/async-confirm-dialog";
import { UserId } from "data-access/repositories/account/account.dto";
import {
  Estimate,
  ProjectEstimateId,
} from "data-access/repositories/project/estimate/estimate.dto";
import { estimateRepository } from "data-access/repositories/project/estimate/estimate.repository";
import { ProjectId } from "data-access/repositories/project/project.dto";
import { theme } from "extensions/theme";
import { mainOperations } from "store/main/operations";
import { selectMain } from "store/main/slice";
import { mutate } from "swr";
import { API_PATHS } from "utils/apiPaths";
import { PREVIEWABLE_EXCEL_EXTENSION, IMAGE_EXTENSIONS, PDF_EXTENSION } from "utils/constant";
import { handleReduxError } from "utils/errorHandler";
import { fileDownload } from "utils/fileDownload";
import { formatDateUtil } from "utils/formatDateUtil";
import { halfWidthNumber } from "utils/halfWidthNumber";
import { isContainExtensions } from "utils/isContainExtensions";
import { styles } from "./styles";
import { ImagePreviewModal } from "../../../../../../components/molecules/image-preview-modal";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";

interface Props {
  sx?: object;
  projectId: ProjectId;
  estimate: Estimate;
}
export const EstimateCard = (props: Props) => {
  const dispatch = useAppDispatch();
  const mainState = useAppSelector(selectMain);
  // TextFieldにて表示の時のみtoLocaleString()を使う場合、Windowsの10,11のテンキー入力(IMEモード)で、二重入力されてしまう。
  // そのため、表示と入力の値を別で保持するようuseStateを使う。
  const [displayAmount, setDisplayAmount] = useState<string | number>(
    props.estimate.displayAmount.toLocaleString(),
  );
  const [isPreviewOpen, setIsPreviewOpen] = useState<boolean>(false);
  const [previewImage, setPreviewImage] = useState<{ name: string; url: string }>({
    name: "",
    url: "",
  });
  const deleteConfirmRef = React.useRef<React.ElementRef<typeof AsyncConfirmDialog>>(null);

  const handleClickPreview = async () => {
    if (isContainExtensions(props.estimate.fileName, [PDF_EXTENSION])) {
      window.open(props.estimate.fileUrl);
      return;
    }
    if (isContainExtensions(props.estimate.fileName, IMAGE_EXTENSIONS)) {
      setPreviewImage({
        name: props.estimate.fileName,
        url: props.estimate.fileUrl,
      });
      setIsPreviewOpen(true);
      return;
    }
    if (isContainExtensions(props.estimate.fileName, PREVIEWABLE_EXCEL_EXTENSION)) {
      dispatch(mainOperations.updateIsLoading(true));
      const result = await estimateRepository.excelFilePreview(
        props.projectId,
        props.estimate.id as ProjectEstimateId,
      );
      window.open(result.url, "_blank");
      dispatch(mainOperations.updateIsLoading(false));
    }
  };

  const handleCheck = async (
    estimateId: ProjectEstimateId,
    statusType: "decided" | "not_yet_decided",
  ) => {
    if (!props.projectId) return;
    dispatch(mainOperations.updateIsLoading(true));
    try {
      await estimateRepository.update(props.projectId, estimateId, {
        statusType,
      });
      // 売上金額に反映させるためにprojectを再取得
      mutate(API_PATHS.getProject(props.projectId));
      mutate(API_PATHS.getProjectEstimates(props.projectId));
      dispatch(mainOperations.updateSuccessMessage("見積書を更新しました"));
    } catch (error) {
      handleReduxError(error, dispatch, "見積書の更新に失敗しました");
    } finally {
      dispatch(mainOperations.updateIsLoading(false));
    }
  };

  const handleChange = async (
    e:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent<number | string>,
    id: number | null,
  ) => {
    if (!props.projectId) return;
    const { name, value } = e.target;
    dispatch(mainOperations.updateIsLoading(true));
    try {
      switch (name) {
        case "headerEstimateUserId": {
          await estimateRepository.update(props.projectId, id as ProjectEstimateId, {
            userId: value as UserId,
          });
          dispatch(mainOperations.updateSuccessMessage("見積書を更新しました"));
          mutate(API_PATHS.getProjectEstimates(props.projectId));
          break;
        }
        case "headerEstimateTaxType": {
          await estimateRepository.updateTaxType(props.projectId, id as ProjectEstimateId, {
            taxType: e.target.value as string,
          });
          dispatch(mainOperations.updateSuccessMessage("見積書を更新しました"));
          mutate(API_PATHS.getProjectEstimates(props.projectId));
          break;
        }
      }
    } catch (error) {
      handleReduxError(error, dispatch, "見積書の更新に失敗しました");
    } finally {
      dispatch(mainOperations.updateIsLoading(false));
    }
  };

  const handleBlur = async (e: React.FocusEvent<HTMLInputElement>, estimateId: number) => {
    if (!props.projectId) return;
    dispatch(mainOperations.updateIsLoading(true));
    try {
      const value = Number(e.target.value);
      if (isNaN(value)) return;

      await estimateRepository.updateAmount(props.projectId, estimateId as ProjectEstimateId, {
        displayAmount: value,
      });

      // 売上金額に反映させるためにprojectを再取得
      mutate(API_PATHS.getProject(props.projectId));
      mutate(API_PATHS.getProjectEstimates(props.projectId));
      setDisplayAmount(value.toLocaleString());
      dispatch(mainOperations.updateSuccessMessage("見積書を更新しました"));
    } catch (error) {
      handleReduxError(error, dispatch, "見積書の更新に失敗しました");
    } finally {
      dispatch(mainOperations.updateIsLoading(false));
    }
  };

  const handleDelete = async (id: number) => {
    if (!deleteConfirmRef.current || !props.projectId) return;
    const confirm = await deleteConfirmRef.current.confirm();
    if (!confirm) return;

    dispatch(mainOperations.updateIsLoading(true));
    try {
      await estimateRepository.destroy(props.projectId, id as ProjectEstimateId);

      mutate(API_PATHS.getProjectEstimates(props.projectId));
      dispatch(mainOperations.updateSuccessMessage("見積書を削除しました"));
    } catch (error) {
      handleReduxError(error, dispatch, "見積書の削除に失敗しました");
    } finally {
      dispatch(mainOperations.updateIsLoading(false));
    }
  };

  return (
    <>
      <AsyncConfirmDialog ref={deleteConfirmRef} />
      <ImagePreviewModal
        isOpen={isPreviewOpen}
        onClose={() => setIsPreviewOpen(false)}
        image={previewImage}
      />

      <Box
        sx={{
          border: `1px solid ${theme.palette.grayScale[300]}`,
          borderRadius: "5px",
          backgroundColor:
            props.estimate.statusType === "decided"
              ? theme.palette.customPrimary[100]
              : theme.palette.customPrimary[50],
          p: "16px",
          width: "230px",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between", mb: "4px" }}>
          <FileIcon fileName={props.estimate.fileName} sx={{ width: "1.8rem" }} />
          <Box
            sx={{
              ml: "8px",
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
            }}
          >
            {isContainExtensions(props.estimate.fileName, [
              PDF_EXTENSION,
              ...IMAGE_EXTENSIONS,
              ...PREVIEWABLE_EXCEL_EXTENSION,
            ]) && (
              <Tooltip title="プレビュー" placement="top" arrow>
                <IconButton size="small" onClick={handleClickPreview}>
                  <Visibility />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title="ダウンロード" placement="top" arrow>
              <IconButton
                size="small"
                onClick={() => fileDownload(props.estimate.fileUrl, props.estimate.fileName)}
              >
                <FileDownloadOutlined />
              </IconButton>
            </Tooltip>
            <Tooltip title="削除" placement="top" arrow>
              <IconButton size="small" onClick={() => handleDelete(props.estimate.id)}>
                <DeleteIcon color={theme.palette.grayScale[700]} size={22} />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
        <div>
          <Typography
            sx={{
              fontWeight: "500",
              wordBreak: "break-all",
              overflow: "hidden",
              display: "-webkit-box",
              WebkitLineClamp: 2,
              WebkitBoxOrient: "vertical",
              height: "54px",
            }}
          >
            {props.estimate.fileName}
          </Typography>
          <Typography fontSize="14px" sx={{ mt: "8px", color: theme.palette.grayScale[700] }}>
            {formatDateUtil(new Date(props.estimate.createdDate), "yyyy年MM月dd日")}
          </Typography>
        </div>
        <Divider sx={{ my: "16px" }} />
        <div>
          <div style={{ display: "flex" }}>
            <Typography sx={{ ...styles.typography }}>番号</Typography>
            <Typography sx={{ fontSize: "14px", fontWeight: "500" }}>
              {props.estimate.code}
            </Typography>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography sx={{ ...styles.typography }}>作成者</Typography>
            <Select
              id="headerEstimateUserId"
              name="headerEstimateUserId"
              onChange={(e) => handleChange(e, props.estimate.id)}
              value={props.estimate.userId}
              variant="standard"
              sx={{
                minWidth: "80px",
                fontSize: "14px",
                fontWeight: "500",
                "&.MuiInputBase-root:before": {
                  borderBottom: "none",
                },
              }}
            >
              {mainState.accounts.map((account) => (
                <MenuItem key={account.userId} value={account.userId}>
                  {account.name}
                </MenuItem>
              ))}
            </Select>
          </div>
        </div>
        <div style={{ display: "flex", alignItems: "center", marginTop: 0.5 }}>
          <Typography sx={{ ...styles.typography }}>金額</Typography>
          <TextField
            id="headerEstimateAmount"
            name="headerEstimateAmount"
            variant="standard"
            value={displayAmount}
            onFocus={() => setDisplayAmount(props.estimate.displayAmount)}
            onChange={(e) => {
              handleChange(
                {
                  ...e,
                  target: {
                    ...e.target,
                    value: isNaN(Number(halfWidthNumber(String(e.target.value))))
                      ? "0"
                      : halfWidthNumber(String(e.target.value)),
                    name: "headerEstimateAmount",
                  },
                },
                props.estimate.id,
              );
              setDisplayAmount(e.target.value as string);
            }}
            onBlur={(event) => {
              handleBlur(
                {
                  ...event,
                  target: {
                    ...event.target,
                    value: isNaN(Number(halfWidthNumber(String(event.target.value))))
                      ? "0"
                      : halfWidthNumber(String(event.target.value)),
                    name: "headerEstimateAmount",
                  },
                } as React.FocusEvent<HTMLInputElement>,
                props.estimate.id,
              );
              // 入力値が文字列であればNaNが表示されてしまうため、0を表示する
              setDisplayAmount(
                isNaN(Number(halfWidthNumber(String(props.estimate.displayAmount))))
                  ? 0
                  : Number(halfWidthNumber(String(props.estimate.displayAmount))).toLocaleString(),
              );
            }}
            onWheel={(event) => event.currentTarget.querySelector("input")?.blur()}
            slotProps={{
              input: {
                startAdornment: <InputAdornment position="start">¥</InputAdornment>,
              },
            }}
            sx={{
              maxWidth: "140px",
              "& .MuiInput-underline:before": {
                borderBottom: "none",
              },
            }}
          />
          <Select
            id="headerEstimateTaxType"
            name="headerEstimateTaxType"
            onChange={(e) => handleChange(e, props.estimate.id)}
            value={props.estimate.taxType}
            variant="standard"
            sx={{
              ml: "8px",
              fontSize: "14px",
              fontWeight: "500",
              "&.MuiInputBase-root:before": {
                borderBottom: "none",
              },
            }}
          >
            <MenuItem value="without_tax">税別</MenuItem>
            <MenuItem value="with_tax">税込</MenuItem>
          </Select>
        </div>
        <div>
          <FormControlLabel
            labelPlacement="end"
            label={
              <Typography fontSize="14px" fontWeight="500">
                この見積りで決定
              </Typography>
            }
            control={
              <Checkbox
                id="estimateStatusType"
                name="estimateStatusType"
                onChange={() =>
                  handleCheck(
                    props.estimate.id,
                    props.estimate.statusType === "decided" ? "not_yet_decided" : "decided",
                  )
                }
                checked={props.estimate.statusType === "decided"}
              />
            }
          />
        </div>
      </Box>
    </>
  );
};

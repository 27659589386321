import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HelpIcon from "@mui/icons-material/Help";
import { Box } from "@mui/material";
import { ParticipationType } from "data-access/repositories/project/participant/participant.dto";
import { theme } from "extensions/theme";
import { removeCorporateSuffix } from "utils/helpers";

interface Props {
  url: string;
  bgColor: string;
  name: string;
  sx?: object;
  size: "small" | "medium";
  participationType?: ParticipationType;
  showStatus?: boolean;
}

export const Avatar = (props: Props) => {
  const getStatusIcon = (participationType?: ParticipationType) => {
    if (!participationType) return null;

    switch (participationType) {
      case "attend":
        return <CheckCircleIcon sx={{ color: theme.palette.success.main, fontSize: 16 }} />;
      case "absent":
        return <CancelIcon sx={{ color: theme.palette.error.main, fontSize: 16 }} />;
      case "pending":
        return <HelpIcon sx={{ color: theme.palette.grey[500], fontSize: 16 }} />;
      case "no_response":
        return null;
      default:
        return null;
    }
  };

  const statusIcon = getStatusIcon(props.participationType);

  return (
    <Box sx={{ position: "relative", ...props.sx }}>
      {props.url ? (
        <Box
          sx={{
            border: `1px solid ${theme.palette.grayScale[300]}`,
            width: props.size === "small" ? "30px" : "66px",
            height: props.size === "small" ? "30px" : "66px",
            display: "flex",
            alignItems: "center",
            borderRadius: "4px",
          }}
        >
          <img
            src={props.url}
            style={{
              objectFit: "contain",
              maxHeight: props.size === "small" ? "28px" : "64px",
              maxWidth: props.size === "small" ? "28px" : "64px",
              borderRadius: "4px",
            }}
          />
        </Box>
      ) : (
        <Box
          sx={{
            width: props.size === "small" ? "28px" : "64px",
            height: props.size === "small" ? "28px" : "64px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: theme.palette.grayScale[0],
            borderRadius: "4px",
            fontWeight: "bold",
            fontSize: props.size === "small" ? "12px" : "24px",
            bgcolor: `#${props.bgColor}`,
            ...props.sx,
          }}
        >
          {removeCorporateSuffix(props.name).slice(0, 2)}
        </Box>
      )}

      {props.showStatus && statusIcon && (
        <Box
          sx={{
            position: "absolute",
            bottom: -2,
            right: -2,
            backgroundColor: "white",
            borderRadius: "50%",
            width: 16,
            height: 16,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {statusIcon}
        </Box>
      )}
    </Box>
  );
};

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { CsvImportAlert } from "components/molecules/csv-import-alert";
import { CustomTabs } from "components/molecules/custom-tabs";
import { Layout } from "components/templates/layout";
import useSWR from "swr";
import { API_PATHS } from "utils/apiPaths";
import { CSV_IMPORT_IN_PROGRESS_STATUS } from "utils/constant";
import { Project } from "./project";
import { Schedule } from "./schedule";
import { csvImportProjectRepository } from "../../api/csv-import/csv_import_project.repository";
import { csvImportScheduleRepository } from "../../api/csv-import/csv_import_schedule.repository";

type TabType = "project" | "schedule";

export const CsvImport = () => {
  const navigate = useNavigate();
  const [tab, setTab] = useState<TabType>("project");

  const { data: projectImportJobs } = useSWR(
    API_PATHS.getProjectImportJobsLatest(),
    csvImportProjectRepository.latest,
  );
  const { data: scheduleImportJobs } = useSWR(
    API_PATHS.getScheduleImportJobsLatest(),
    csvImportScheduleRepository.latest,
  );

  useEffect(() => {
    const path = window.location.pathname.split("/").pop();
    if (path === "schedule") {
      setTab("schedule");
    }
  }, []);

  const handleChangeTab = (newValue: TabType) => {
    setTab(newValue);
    navigate(`/hub-settings/csv-imports/${newValue}`);
  };

  return (
    <Layout>
      <Box mb="20px">
        {projectImportJobs && CSV_IMPORT_IN_PROGRESS_STATUS.includes(projectImportJobs.status) && (
          <CsvImportAlert
            totalCount={projectImportJobs.totalCount}
            createdCount={projectImportJobs.createdCount}
            estimatedTimeRemainingSeconds={projectImportJobs.estimatedTimeRemainingSeconds}
            status={projectImportJobs.status}
            moduleName="project"
          />
        )}
        {scheduleImportJobs &&
          CSV_IMPORT_IN_PROGRESS_STATUS.includes(scheduleImportJobs.status) && (
            <CsvImportAlert
              totalCount={scheduleImportJobs.totalCount}
              createdCount={scheduleImportJobs.createdCount}
              estimatedTimeRemainingSeconds={scheduleImportJobs.estimatedTimeRemainingSeconds}
              status={scheduleImportJobs.status}
              moduleName="schedule"
            />
          )}
        <Typography fontWeight="bold" fontSize="24px">
          CSVインポート
        </Typography>
        <Typography fontSize={12}>
          CSVデータをインポートすることで、案件や予定を一括作成することが可能です。
        </Typography>
      </Box>

      <Box sx={{ mb: 2 }}>
        <CustomTabs
          value={tab}
          onChange={(_: React.SyntheticEvent, newValue: TabType) => handleChangeTab(newValue)}
          tabs={[
            { value: "project", label: "案件" },
            { value: "schedule", label: "スケジュール" },
          ]}
        />
      </Box>

      {tab === "project" && <Project />}
      {tab === "schedule" && <Schedule />}
    </Layout>
  );
};

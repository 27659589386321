import { Box } from "@mui/material";
import { theme } from "extensions/theme";
import { CsvImportStatus } from "features/hub-settings/types/csv-import/csv_import_project.dto";

interface Props {
  status: CsvImportStatus;
  sx?: object;
}
export const CsvImportStatusLabel = (props: Props) => {
  const statusName = () => {
    switch (props.status) {
      case "succeeded":
        return "成功";
      case "failed":
        return "失敗";
      case "canceled_by_system":
      case "canceled_by_user":
        return "中止";
      default:
        return "処理中";
    }
  };
  const backgroundColor = () => {
    switch (props.status) {
      case "succeeded":
        return theme.palette.secondary.main;
      case "failed":
        return theme.palette.error.main;
      case "canceled_by_system":
      case "canceled_by_user":
        return theme.palette.grayScale[700];
      default:
        return theme.palette.primary.main;
    }
  };
  return (
    <Box
      component="span"
      sx={{
        fontSize: "14px",
        borderRadius: "32px",
        p: "6px 10px",
        fontWeight: "bold",
        textAlign: "center",
        backgroundColor: backgroundColor(),
        color: theme.palette.grayScale[0],
        ...props.sx,
      }}
    >
      {statusName()}
    </Box>
  );
};

import { ClientBlock } from "components/molecules/client-block";
import { CustomFormLabel } from "components/molecules/custom-form-label";
import { Client } from "data-access/repositories/client/client.dto";
import { theme } from "extensions/theme";

interface Props {
  client: Client;
}

export const BillingSidebarClientBlock = (props: Props) => {
  return (
    <>
      <CustomFormLabel labelName="顧客" labelColor={theme.palette.grayScale[700]} />
      <ClientBlock client={props.client} isDeselectButtonVisible={false} />
    </>
  );
};

import { Box, Typography, Grid2, Dialog, DialogTitle, DialogContent, Button } from "@mui/material";
import { Avatar } from "components/avatar";
import { theme } from "extensions/theme";
import { HubInfoData } from "features/hub-settings/types/hub-info/hub_info.dto";
import Company from "images/company.svg";
import SelfEmployed from "images/self_employed.svg";
import { styles } from "./styles";

interface Props {
  hubInfo: HubInfoData;
  isOpen: boolean;
  onClose: () => void;
  onInvitationModalOpen: () => void;
}

export const WelcomeModal = (props: Props) => {
  const handleIndividualClick = () => {
    props.onClose();
  };

  const handleCompanyClick = () => {
    props.onClose();
    props.onInvitationModalOpen();
  };

  return (
    <Dialog open={props.isOpen}>
      <DialogTitle
        color="primary"
        sx={{ textAlign: "center", fontSize: "24px", fontWeight: "bold" }}
      >
        Hubが作成されました！
      </DialogTitle>
      <DialogContent>
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", mb: 3 }}>
          <Box
            sx={{
              display: "flex",
              gap: "20px",
              bgcolor: theme.palette.customPrimary[50],
              p: "16px 24px",
              width: "100%",
              borderRadius: "8px",
              mb: "24px",
            }}
          >
            <Avatar
              url={props.hubInfo.logoUrl}
              bgColor={props.hubInfo.logoBackgroundColorNumber}
              name={props.hubInfo.name}
              size="medium"
            />
            <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
              <Typography sx={{ fontWeight: "bold" }}>{props.hubInfo.name}</Typography>
              <Typography sx={{ fontSize: "14px", color: theme.palette.grayScale[700] }}>
                {props.hubInfo.officeName}
              </Typography>
              <Typography sx={{ fontSize: "14px", color: theme.palette.grayScale[700] }}>
                {props.hubInfo.address}
              </Typography>
            </Box>
          </Box>
          <Typography sx={{ fontWeight: "bold", mb: "8px" }}>
            Hubに自社のメンバーを招待しましょう
          </Typography>
          <Typography
            fontSize="14px"
            color="textSecondary"
            textAlign="center"
          >{`情報を共有するには、社員一人一人が現場Hubアカウントを持つ必要があります。\n
          個人事業主の方などは、一人で使うことも可能です。`}</Typography>
        </Box>

        <Grid2 container spacing={2}>
          <Grid2 size={6}>
            <Box sx={styles.optionCard}>
              <img src={SelfEmployed} alt="self_employed" />
              <Button variant="contained" onClick={handleIndividualClick} sx={styles.button}>
                ひとりで使う
              </Button>
              <Typography variant="body2" sx={styles.description}>
                メンバーの招待は必要なくこのまま使うことができます
              </Typography>
            </Box>
          </Grid2>
          <Grid2 size={6}>
            <Box sx={styles.optionCard}>
              <img src={Company} alt="company" />
              <Button variant="contained" onClick={handleCompanyClick} sx={styles.button}>
                みんなで使う
              </Button>
              <Typography variant="body2" sx={styles.description}>
                会社で利用する場合はメンバーを招待しましょう
              </Typography>
            </Box>
          </Grid2>
        </Grid2>
      </DialogContent>
    </Dialog>
  );
};

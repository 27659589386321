import { ObjectKeys } from "types/objectKeys";
import { Brand } from "../brand";
import { PhotoResponse } from "./photo/photo.dto";
import { ProjectBillingId } from "./project_billing/project_billing.dto";
import { AccountReference, initialAccountReference, UserId } from "../account/account.dto";
import { Building, BuildingId } from "../building/building.dto";
import { Client, ClientId } from "../client/client.dto";
import { PageInfo } from "../page_info/page_info.dto";
import {
  ProjectStatusType,
  ProjectStatusTypeId,
  initialProjectStatusType,
} from "../project_status_type/project_status_type.dto";
import { ProjectType, initProjectType, ProjectTypeId } from "../project_type/project_type.dto";

export type ProjectId = Brand<number, "ProjectId">;

// 案件一覧で利用
export interface ProjectCollection {
  id: ProjectId;
  code: string;
  name: string;
  address: string;
  addressSecond: string;
  estimateUserNames: string;
  client: Client;
  inquiryNumber: string;
  inquiredBy?: AccountReference;
  manager?: AccountReference;
  inquiredDate?: Date;
  orderedDate?: Date;
  expectedCompleteDate?: Date;
  completedDate?: Date;
  salesAmount: number;
  salesTax: number;
  totalCostAmount: number;
  isOrdered: boolean;
  isNoBill: boolean;
  billingCondition: string;
  billingPrecaution: string;
  building: Building | null;
  isSupportedByMaker: boolean;
  note: string;
  personName: string;
  projectType: {
    id: ProjectTypeId;
    name: string;
  };
  projectStatusType: {
    id: ProjectStatusTypeId;
    name: string;
    colorNumber: string;
  };
  updatedAt: Date;
  todos: {
    tagType: {
      value: string;
      valueI18n: string;
    };
    content: string;
    isDone: boolean;
  }[];
  projectBillings: {
    id: ProjectBillingId;
    amount: number;
    tax: number;
  }[];
}

export interface Project extends ObjectKeys {
  // 共通
  id: ProjectId;
  client: Client | null;
  building: Building | null;
  billingPrecaution: string;
  projectType: ProjectType;
  projectStatusType: ProjectStatusType;
  requestedByPersonName: string;
  inquiryNumber: string;
  code: string;
  name: string;
  postalCode: string;
  address: string;
  addressSecond: string;
  email: string;
  phoneNumber: string;
  phoneNumberSecond: string;
  faxNumber: string;
  note: string;
  internalNote: string;
  reportContent: string;
  inquiredDate: string | null;
  orderedDate: string | null;
  expectedCompleteDate: string | null;
  completedDate: string | null;
  photoInfoV2: {
    firstThreePhotos: PhotoResponse[];
    totalCount: number;
  };
  manager: AccountReference | null;
  inquiredBy: AccountReference;
  isManageBillingOnAnotherSystem: boolean;
  isSupportedByMaker: boolean;
  isNoBill: boolean;
  isOrdered: boolean;
  isOrdering: boolean;
  salesAmount: number;
  salesTax: number;
  totalCostAmount: number;
  decidedEstimatesTotalAmount: number;
  photoUrlKey: string;
  personName: string;
}

export interface ProjectIndexRequest {
  projectTypeIds?: ProjectTypeId[];
  projectStatusTypeIds?: ProjectStatusTypeId[];
  startInquiredDate?: string;
  endInquiredDate?: string;
  startOrderedDate?: string;
  endOrderedDate?: string;
  startCompletedDate?: string;
  endCompletedDate?: string;
  keyword?: string;
  billingConditions?: string[];
  sort?: string;
  direction?: "desc" | "asc";
  page?: number;
  rowsPerPage?: number;
}

export interface ProjectIndexResponse {
  data: ProjectCollection[];
  pagination: PageInfo;
}

export interface ProjectCreateRequest extends ObjectKeys {
  clientId: ClientId | null;
  buildingId: BuildingId | null;
  managerId: UserId | null;
  inquiredById: UserId | null;
  inquiryNumber: string;
  name: string;
  code: string;
  projectTypeId: ProjectTypeId;
  projectStatusTypeId: ProjectStatusTypeId;
  requester: string;
  requestedByPersonName: string;
  postalCode: string;
  address: string;
  addressSecond: string;
  phoneNumber: string;
  phoneNumberSecond: string;
  faxNumber: string;
  email: string;
  billingPrecaution: string;
  internalNote: string;
  note: string;
  reportContent: string;
  inquiredDate: string;
  orderedDate: string;
  expectedCompleteDate: string;
  completedDate: string;
  isSupportedByMaker: boolean;
  isManageBillingOnAnotherSystem: boolean;
  isNoBill: boolean;
  salesAmount: number;
  personName: string;
}

export interface ProjectFormUpdateRequest {
  clientId: ClientId | null;
  buildingId: BuildingId | null;
  managerId: UserId | null;
  inquiredById: UserId | null;
  inquiryNumber: string;
  name: string;
  code: string;
  projectTypeId: ProjectTypeId;
  projectStatusTypeId: ProjectStatusTypeId;
  requester: string;
  requestedByPersonName: string;
  reportContent: string;
  postalCode: string;
  address: string;
  addressSecond: string;
  phoneNumber: string;
  phoneNumberSecond: string;
  faxNumber: string;
  email: string;
  billingPrecaution: string;
  note: string;
  internalNote: string;
  inquiredDate: string;
  orderedDate: string;
  expectedCompleteDate: string;
  completedDate: string;
  isSupportedByMaker: boolean;
  isManageBillingOnAnotherSystem: boolean;
  isNoBill: boolean;
  salesAmount: number;
  tax: number;
  totalCostAmount: number;
  personName: string;
}

export interface ProjectUpdateRequest extends ObjectKeys {
  clientId?: ClientId | null;
  buildingId?: BuildingId | null;
  managerId?: UserId;
  inquiredById?: UserId;
  inquiryNumber?: string;
  name?: string;
  code?: string;
  projectTypeId?: ProjectTypeId;
  requester?: string;
  requestedByPersonName?: string;
  projectStatusTypeId?: ProjectStatusTypeId;
  postalCode?: string;
  address?: string;
  addressSecond?: string;
  phoneNumber?: string;
  phoneNumberSecond?: string;
  faxNumber?: string;
  email?: string;
  billingPrecaution?: string;
  internalNote?: string;
  note?: string;
  reportContent?: string;
  inquiredDate?: string;
  orderedDate?: string;
  expectedCompleteDate?: string;
  completedDate?: string;
  isSupportedByMaker?: boolean;
  isManageBillingOnAnotherSystem?: boolean;
  isNoBill?: boolean;
  salesAmount?: number;
  tax?: number;
  totalCostAmount?: number;
  personName?: string;
}

export interface ProjectDownloadRequest {
  projectTypeIds?: number[];
  projectStatusTypeIds?: ProjectStatusTypeId[];
  startInquiredDate?: string;
  endInquiredDate?: string;
  startOrderedDate?: string;
  endOrderedDate?: string;
  startCompletedDate?: string;
  endCompletedDate?: string;
  keyword?: string;
  billingConditions?: string[];
}

export interface RelatedProject {
  id: ProjectId;
  code: string;
  name: string;
  salesAmount: number;
  projectStatusType: {
    id: ProjectStatusTypeId;
    name: string;
    colorNumber: string;
  };
  completedDate: string;
  manager: AccountReference;
  note: string;
}

export const initialProjectResponse: Project = {
  // 共通
  id: 0 as ProjectId,
  client: null,
  building: null,
  billingPrecaution: "",
  projectStatusType: initialProjectStatusType,
  requestedByPersonName: "",
  projectType: initProjectType,
  isNoBill: false,
  isOrdered: false,
  isOrdering: false,
  inquiryNumber: "",
  isManageBillingOnAnotherSystem: false,
  code: "",
  name: "",
  postalCode: "",
  address: "",
  addressSecond: "",
  email: "",
  phoneNumber: "",
  phoneNumberSecond: "",
  faxNumber: "",
  internalNote: "",
  note: "",
  reportContent: "",
  inquiredDate: null,
  orderedDate: null,
  expectedCompleteDate: null,
  completedDate: null,
  photoInfoV2: {
    firstThreePhotos: [],
    totalCount: 0,
  },
  manager: initialAccountReference,
  inquiredBy: initialAccountReference,
  isSupportedByMaker: false,
  salesAmount: 0,
  salesTax: 0,
  totalCostAmount: 0,
  decidedEstimatesTotalAmount: 0,
  photoUrlKey: "",
  personName: "",
};

export const initialProjectRequest: ProjectUpdateRequest = {
  clientId: 0 as ClientId,
  buildingId: 0 as BuildingId,
  managerId: 0 as UserId,
  inquiredById: 0 as UserId,
  inquiryNumber: "",
  isManageBillingOnAnotherSystem: false,
  isNoBill: false,
  name: "",
  code: "",
  projectTypeId: 0 as ProjectTypeId,
  requester: "",
  requestedByPersonName: "",
  projectStatusTypeId: 0 as ProjectStatusTypeId,
  postalCode: "",
  address: "",
  addressSecond: "",
  phoneNumber: "",
  phoneNumberSecond: "",
  faxNumber: "",
  email: "",
  billingPrecaution: "",
  note: "",
  reportContent: "",
  inquiredDate: new Date().toString(),
  isSupportedByMaker: false,
  salesAmount: 0,
};

export interface ProjectFormHeaderState {
  name: string;
  projectStatusTypeId: ProjectStatusTypeId;
  clientId: ClientId | null;
  buildingId: BuildingId | null;
}

export interface ProjectFormIdentificationState {
  code: string;
  inquiryNumber: string;
  managerId: UserId | null;
  inquiredById: UserId | null;
  projectTypeId: ProjectTypeId;
}

export interface ProjectFormDatesState {
  inquiredDate: string;
  orderedDate: string;
  expectedCompleteDate: string;
  completedDate: string;
}

export interface ProjectFormSalesState {
  salesAmount: number;
  tax: number;
  totalCostAmount: number;
}

export interface ProjectFormNotesState {
  note: string;
  internalNote: string;
  billingPrecaution: string;
}

export interface ProjectFormSiteState {
  personName: string;
  postalCode: string;
  address: string;
  addressSecond: string;
  phoneNumber: string;
  phoneNumberSecond: string;
  faxNumber: string;
  email: string;
}

export interface ProjectFormBillingState {
  isManageBillingOnAnotherSystem: boolean;
  isNoBill: boolean;
  isSupportedByMaker: boolean;
}

export interface ProjectFormReportContentsState {
  requester: string;
  requestedByPersonName: string;
  reportContent: string;
}

export const initialProjectFormHeaderState: ProjectFormHeaderState = {
  name: "",
  projectStatusTypeId: 0 as ProjectStatusTypeId,
  clientId: null,
  buildingId: null,
};

export const initialProjectFormIdentificationState: ProjectFormIdentificationState = {
  code: "",
  inquiryNumber: "",
  managerId: null,
  inquiredById: null,
  projectTypeId: 0 as ProjectTypeId,
};

export const initialProjectFormDatesState: ProjectFormDatesState = {
  inquiredDate: "",
  orderedDate: "",
  expectedCompleteDate: "",
  completedDate: "",
};

export const initialProjectFormSalesState: ProjectFormSalesState = {
  salesAmount: 0,
  tax: 0,
  totalCostAmount: 0,
};

export const initialProjectFormNotesState: ProjectFormNotesState = {
  note: "",
  internalNote: "",
  billingPrecaution: "",
};

export const initialProjectFormSiteState: ProjectFormSiteState = {
  personName: "",
  postalCode: "",
  address: "",
  addressSecond: "",
  phoneNumber: "",
  phoneNumberSecond: "",
  faxNumber: "",
  email: "",
};

export const initialProjectFormBillingState: ProjectFormBillingState = {
  isManageBillingOnAnotherSystem: false,
  isNoBill: false,
  isSupportedByMaker: false,
};

export const initialProjectFormReportContentsState: ProjectFormReportContentsState = {
  requester: "",
  requestedByPersonName: "",
  reportContent: "",
};

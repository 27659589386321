import { PrimaryCategoryId } from "data-access/repositories/project/primary_category/primary_category.dto";
import { ProjectId } from "data-access/repositories/project/project.dto";
import { SecondaryCategoryId } from "data-access/repositories/project/secondary_category/secondary_category.dto";
import { ConnectApprovableId } from "features/hub-settings/types/connect/connect_approvable.dto";
import { ConnectRequestingId } from "features/hub-settings/types/connect/connect_requesting.dto";
import { HubId } from "features/hub-settings/types/hub-info/hub_info.dto";

export const API_PATHS = {
  getAccounts: () => "/api/v1/accounts",
  getProjects: () => "/api/v1/projects",
  getProject: (projectId: ProjectId) => `/api/v1/projects/${projectId}`,
  getProjectPhotoCount: (projectId: ProjectId) => `/api/v1/projects/${projectId}/photos/count_info`,
  getProjectActivities: (projectId: ProjectId, limit?: number) =>
    `/api/v1/projects/${projectId}/activities?limit=${limit}`,
  getProjectLaborCosts: (projectId: ProjectId) => `/api/v1/projects/${projectId}/labor_costs`,
  getProjectOutsourcingCosts: (projectId: ProjectId) =>
    `/api/v1/projects/${projectId}/outsourcing_costs`,
  getProjectExpenseCosts: (projectId: ProjectId) => `/api/v1/projects/${projectId}/expense_costs`,
  getProjectMaterialCosts: (projectId: ProjectId) => `/api/v1/projects/${projectId}/material_costs`,
  getProjectPrimaryCategories: (projectId: ProjectId) =>
    `/api/v1/projects/${projectId}/primary_categories`,
  getProjectPrimaryCategorySecondaryCategory: (
    projectId: ProjectId,
    primaryCategoryId: PrimaryCategoryId,
    secondaryCategoryId: SecondaryCategoryId,
  ) =>
    `/api/v1/projects/${projectId}/primary_categories/${primaryCategoryId}/secondary_categories/${secondaryCategoryId}`,
  getProjectPrimaryCategorySecondaryCategoryPhotos: (
    projectId: ProjectId,
    primaryCategoryId: PrimaryCategoryId,
    secondaryCategoryId: SecondaryCategoryId,
  ) =>
    `/api/v1/projects/${projectId}/primary_categories/${primaryCategoryId}/secondary_categories/${secondaryCategoryId}/photos`,
  getProjectEstimates: (projectId: ProjectId) => `/api/v1/projects/${projectId}/estimates`,
  getProjectBillings: (projectId: ProjectId) => `/api/v1/projects/${projectId}/project_billings`,
  getProjectTodos: (projectId: ProjectId) => `/api/v1/projects/${projectId}/todos`,
  getProjectSchedules: (projectId: ProjectId) => `/api/v2/projects/${projectId}/schedules`,
  getProjectWorkReports: (projectId: ProjectId) => `/api/v1/projects/${projectId}/work_reports`,
  getProjectClientServiceReports: (projectId: ProjectId) =>
    `/api/v1/projects/${projectId}/client_service_reports`,
  getProjectAttachmentsByAttachmentType: (projectId: ProjectId, hubId: HubId) =>
    `/api/v2/projects/${projectId}/attachments?hub_id=${hubId}`,
  getPermissionSets: () => "/api/v1/permission_sets",
  getProjectImportJobs: (page?: number) => `/api/v1/project_import_jobs?page=${page || 1}`,
  getProjectImportJobsLatest: () => "/api/v1/project_import_jobs/latest",
  getScheduleImportJobs: (page?: number) => `/api/v1/schedule_import_jobs?page=${page || 1}`,
  getScheduleImportJobsLatest: () => "/api/v1/schedule_import_jobs/latest",
  getConnects: () => "/api/v1/connects",
  getConnectRequestsRequesting: () => "/api/v1/connect_requests/requesting",
  getConnectRequestRequesting: (id: ConnectRequestingId) =>
    `/api/v1/connect_requests/requesting/${id}`,
  getConnectRequestsApprovable: () => "/api/v1/connect_requests/approvable",
  getConnectRequestApprovable: (id: ConnectApprovableId) =>
    `/api/v1/connect_requests/approvable/${id}`,
  getGroups: () => "/api/v1/groups",
  getAccountNoticeSetting: () => "/api/v1/account_notice_setting",
  getProjectParticipants: (projectId: ProjectId) => `/api/v1/projects/${projectId}/participants`,
  getSourceProject: (projectId: ProjectId) => `/api/v1/projects/${projectId}/source_project`,
};
